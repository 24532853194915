import { Avatar, Box, Tooltip } from '@material-ui/core'
import { Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import * as FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import * as XLSX from 'xlsx'
import filterIcon from '../../assets/Customer/Filter.svg'
import forwardIcon from '../../assets/Customer/forward.svg'
import DataTable from '../../components/DataTable/DataTable'
import Search from '../../components/SearchTab/Search'
import Toast from '../../components/ToastContainer/CustomToast'
import BrowserLanguage from '../../components/Util/BrowserLanguage'
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import {
  getDealerInvoice,
  getDealers
} from '../../slices/Dealer/DealerSlice'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import CustomerIcon from '../Customer/CustomerIcon'
import DownloadInvoiceIcon from '../Customer/DownloadInvoiceIcon'
import DealerReportFilter from './DealerReportFilter'
import DealerTransactionAndReportExports from './DealerTransactionAndReportExports'

let language = BrowserLanguage.getDefaultLanguage()

function DealersReport() {
  const [clear, setClear] = React.useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageSize, setPageSize] = useState(0)
  const history = useHistory()
  const [openFilter, setOpenFilter] = useState(false)
  const [openDownload, setOpenDownload] = useState(false)
  const [rowDealer, setRowDealer] = useState({})
  const companyId = localStorage.getItem('companyId')
  const [date, setDate] = useState()
  const [loading, setLoading] = useState(true)

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = React.useState({
    status: '',
    name: '',
    email: '',
  })

  const defaultFilter = {
    status: '',
    name: '',
    email: '',
  }

  const handleChange = (newPage, size) => {
    setLoading(true)
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging])

  const loadData = (filter, paging) => {
    dispatch(getDealers(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'MSGS4007')) {
          setTotalRecords(data.data.totalCount)
          setRows(data.data.companies)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setLoading(false)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  useEffect(() => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          if (data.data.adminAndDealerDateFormat === 'MM/DD/YYYY') {
            setDate('MM/dd/yyyy HH:mm')
          }
        }
      })
  }, [])

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 20) + '...'
    } else {
      return text
    }
  }

  const handleViewCustomer = (params) => {
    history.push({
      pathname: '/vis/dealerCustomersReport',
      state: params,
    })
  }

  const downloadFile = (url) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'invoice.pdf')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleInvoice = (params) => {
    dispatch(getDealerInvoice(params))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4040') {
          setLoading(true)
          // downloadFile(res.data.receipt_url)
          window.open(res.data.receipt_url)
          Toast(t('RECEIPT_PDF'), 'success')
          setLoading(false)
        } else {
          setLoading(false)
          Toast(t('dataTableEdit1'), 'error')
        }
      })
      .catch((er) => { })
  }

  const handleDownloadInvoice = async (data) => {
    const modifieData = customizeData(data)
    const ws = XLSX.utils.json_to_sheet(modifieData)
    const columnWidths = [
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ]
    ws['!cols'] = columnWidths
    const wb = { Sheets: { Dealer: ws }, SheetNames: ['Dealer'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    const fileExtension = '.xlsx'
    const blobData = new Blob([excelBuffer], { type: fileType }) // Change variable name to blobData
    FileSaver.saveAs(blobData, 'Dealer Invoice' + fileExtension) // Use blobData instead of data
    Toast(t('INVOICEEXPORTSUCCESS'), 'success')
  }

  let customizeData = null
  if (language === 'en') {
    customizeData = (data) => {
      return data.map((item) => {
        let customerName = ''

        if (Array.isArray(item.customerName)) {
          customerName = item.customerName.filter(Boolean).join(', ')
        } else if (item.customerName) {
          customerName = item.customerName
        }

        return {
          'Company Name': item.name,
          'Admin Name': item.adminName,
          Email: item.email,
          Mobile: item.phone,
          'Transaction ID': item.transactionID,
          'Payment received date': item.paymentReceivedDate,
          'Payment Received Amount': item.paymentReceivedAmount,
          'Next Payment Due date': item.nextPaymentDuedate,
          'Next Payment Amount': item.nextPaymentAmount,
          'Customer Name': customerName,
        }
      })
    }
  } else {
    customizeData = (data) => {
      return data.map((item) => {
        let customerName = ''

        if (Array.isArray(item.customerName)) {
          customerName = item.customerName.filter(Boolean).join(', ')
        } else if (item.customerName) {
          customerName = item.customerName
        }

        return {
          'nombre de empresa': item.name,
          'Nombre del administrador': item.adminName,
          'Correo electrónico': item.email,
          Móvil: item.phone,
          'ID de transacción': item.transactionID,
          'fecha de pago recibido': item.paymentReceivedDate,
          'Pago recibido Monto': item.paymentReceivedAmount,
          'Próxima fecha de vencimiento del pago': item.nextPaymentDuedate,
          'Próximo monto del pago': item.nextPaymentAmount,
          'Nombre del cliente': customerName,
        }
      })
    }
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      const lowerCaseSearchedVal = searchedVal.toLowerCase().trim();

      return (
        (test.name
          ? test.name.toLowerCase().includes(lowerCaseSearchedVal)
          : '') ||
        (test.email
          ? test.email.toLowerCase().includes(lowerCaseSearchedVal)
          : '') ||
        (test.status
          ? test.status.trim().toLowerCase() === lowerCaseSearchedVal
          : '') ||
        (test.phone
          ? test.phone.toLowerCase().includes(lowerCaseSearchedVal)
          : '') ||
        (test.adminName
          ? test.adminName.toLowerCase().includes(lowerCaseSearchedVal)
          : '') ||
        (test.customerName
          ? String(test.customerName).toLowerCase().includes(lowerCaseSearchedVal)
          : '') ||
        `${test.name}`.toLowerCase().includes(lowerCaseSearchedVal)
      );
    });
    setRows(filteredRows);
  };

  const handleFilterIcon = () => {
    setOpenFilter(true)
  }

  const handleDownload = () => {
    if (rows.length === 0) {
      Toast(t('dataTableEmptyList'), 'error')
      return
    } else {
      setOpenDownload(true)
    }
  }

  const applyFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      status: data.status,
      name: data.name,
      email: data.email,
    })
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={
            params.row.imagePreSignedURL !== undefined
              ? params.row.imagePreSignedURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={params.row.imagePreSignedURL}
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(() => [
    {
      field: 'company.profile',
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },

    {
      field: 'company Name',
      headerName: t('CUSTDATABLE01'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'adminName',
      headerName: t('CUSTDATABLE04'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.adminName,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'email',
      headerName: t('CUSTDATABLE02'),
      flex: 1,
      minWidth: 180,
      valueGetter: (params) => params.row.email,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'mobile',
      headerName: t('CUSTDATABLE03'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.phone,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'TransactionID',
      headerName: t('CUSTDATABLE12'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.transactionID,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'Payment received date',
      headerName: t('CUSTDATABLE36'),
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => params.row.paymentReceivedDate,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'PaymentReceivedAmount',
      headerName: t('CUSTDATABLE37'),
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => params.row.PaymentReceivedAmount,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'nextPaymentDuedate',
      headerName: t('CUSTDATABLE38'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.nextPaymentDuedate,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'nextPaymentAmount',
      headerName: t('CUSTDATABLE39'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.nextPaymentAmount,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'customerName',
      headerName: t('CUSTDATABLE21'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.customerName,
      renderCell: (params) => (
        <Tooltip
          title={params.value ? `${params.value.join(', ')}` : ''}
          placement="bottom-start"
        >
          <span>
            {params.value ? handleTextOverflow(params.value.join(', ')) : ''}
          </span>
        </Tooltip>
      ),
    },

    {
      field: 'Actions',
      headerName: t('CUSTDATABLE16'),
      type: 'actions',
      flex: 1,
      minWidth: 150,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CustomerIcon />}
          label="View Customer"
          onClick={() => handleViewCustomer(params.row)}
        />,

        <GridActionsCellItem
          icon={<DownloadInvoiceIcon />}
          label={t('INVOICE')}
          onClick={() => handleInvoice(params.row)}
        />,
      ],
    },
  ])

  return (
    <>
      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}

      <Box style={{ margin: '16px, 0' }}>
        <Typography variant="h3">{t('DEALERDETAILS')}</Typography>
      </Box>

      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item style={{ margin: '0 8px' }}>
          <Tooltip title={t('FILTER')} onClick={handleFilterIcon}>
            <img src={filterIcon} />
          </Tooltip>
        </Box>

        <Box item style={{ margin: '0 8px' }}>
          <Tooltip title={t('EXPORT')} onClick={handleDownload}>
            <img src={forwardIcon} />
          </Tooltip>
        </Box>

        <Box item>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: '100%', marginTop: '15px' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openFilter && (
        <DealerReportFilter
          open={openFilter}
          onClose={() => {
            setOpenFilter(false)
          }}
          date={date}
          rowsPerPage={rowsPerPage}
          applyFilter={applyFilter}
        />
      )}
      {openDownload && (
        <DealerTransactionAndReportExports
          open={openDownload}
          handleClose={() => {
            setOpenDownload(false)
          }}
        />
      )}
    </>
  )
}

export default DealersReport
