import { Box, Grid, Typography } from '@mui/material';
import React, {useContext} from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CommonUtil from '../Util/CommonUtils';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { ThemeContext } from '../../theme/ThemeContext';

export default function TitleBar(props) {
  const history = useHistory();
  const { darkMode } = useContext(ThemeContext);
  return (
    <>
      <Box display='flex' alignItems='center' paddingTop={2} paddingBottom={2} >
        {props.title && (
          <Box item paddingRight={1} paddingBottom={0.5} alignSelf={'center'}>
                <Grid style={{display:'flex'}}>
                    {props.back_button ?
                        (
                          <Grid item onClick={props.back===true?props.onClick:history.goBack}>
                            <Grid style={{ cursor: 'pointer', paddingRight:'10px' }} >
                            <ArrowBackIosRoundedIcon sx={{ color: darkMode ? '#fff' : '#9AA1B9',marginLeft:darkMode? '21px' : '-10px'}} fontSize="small"/>
                            </Grid>
                        </Grid>)
                        : 
                        (
                        <></>
                        )
                    }
                    </Grid>
          </Box>
        )}
        <Box item flexGrow={1}>
          <Typography variant='title' gutterBottom component='div' style={{ fontWeight: '510', fontSize: '22px', lineHeight:'25px', color: darkMode ? '#fff' : '#3D4977',  }}>
            {CommonUtil.isEmptyString(props.title) ? props.header : props.title}
          </Typography>
        </Box>
        <Box item>{props.children}</Box>
      </Box>
    </>
  );
}
