import api from '../../config/http-common'

const generateAuthenticationCode = (payload) => {
  return api
    .unsecuredAxios()
    .post('/web/api/v4/users/loginAuthentication/code', payload)
}

const validateAuthenticationCode = (payload) => {
  console.log(payload)
  return api
    .unsecuredAxios()
    .put('/web/api/v4/users/loginAuthentication/codes/' + payload.code, payload)
}

const getLoginAuthenticationDetails = (payload) => {
  return api
    .unsecuredAxios()
    .get(
      '/web/api/v4/users/loginAuthentication/codes/' +
        payload.email +
        '?loginSecurityKey=' +
        payload.loginSecurityKey,
    )
}

const LoginAuthenticationService = {
  generateAuthenticationCode,
  validateAuthenticationCode,
  getLoginAuthenticationDetails,
}

export default LoginAuthenticationService
