import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const DeleteChecklist = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t("dataTableDelete")}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="18.31" height="18.31" viewBox="0 0 18.31 18.31">
            <g id="delete-hover-state" transform="translate(-1.875 -1.875)">
              <path id="Path_6234" data-name="Path 6234" d="M15.549,21.17H7.9a2.16,2.16,0,0,1-2.156-1.952L4.5,6.794A.721.721,0,0,1,5.222,6h13a.722.722,0,0,1,.719.794L17.705,19.217a2.161,2.161,0,0,1-2.156,1.953ZM6.02,7.445l1.16,11.63a.721.721,0,0,0,.719.651h7.65a.721.721,0,0,0,.719-.651l1.16-11.63Z" transform="translate(-0.694 -1.11)" fill="#3d4977"/>
              <path id="Path_6234_-_Outline" data-name="Path 6234 - Outline" d="M15.555,21.3H7.892A2.276,2.276,0,0,1,5.618,19.23L4.379,6.806a.846.846,0,0,1,.842-.931h13a.847.847,0,0,1,.843.931L17.83,19.23A2.277,2.277,0,0,1,15.555,21.3ZM7.9,21.045h7.656a2.028,2.028,0,0,0,2.026-1.84L18.82,6.782a.6.6,0,0,0-.594-.657h-13a.6.6,0,0,0-.594.656L5.867,19.206a2.027,2.027,0,0,0,2.025,1.839ZM15.55,19.85H7.9a.843.843,0,0,1-.842-.764L5.882,7.32H17.566L16.392,19.087A.843.843,0,0,1,15.55,19.85ZM6.158,7.57,7.3,19.062A.594.594,0,0,0,7.9,19.6h7.651a.594.594,0,0,0,.594-.538L17.29,7.57Z" transform="translate(-0.694 -1.11)" fill="#3d4977"/>
              <path id="Path_6235" data-name="Path 6235" d="M19.337,7.445H2.722A.722.722,0,1,1,2.722,6H19.337a.722.722,0,1,1,0,1.445Z" transform="translate(0 -1.11)" fill="#3d4977"/>
              <path id="Path_6235_-_Outline" data-name="Path 6235 - Outline" d="M19.337,7.57H2.722a.847.847,0,0,1,0-1.695H19.337a.847.847,0,1,1,0,1.695ZM2.722,6.125a.6.6,0,0,0,0,1.195H19.337a.6.6,0,1,0,0-1.195Z" transform="translate(0 -1.11)" fill="#3d4977"/>
              <path id="Path_6236" data-name="Path 6236" d="M14.222,19.391a.722.722,0,0,1-.722-.722V10.722a.722.722,0,0,1,1.445,0v7.946A.722.722,0,0,1,14.222,19.391Z" transform="translate(-3.193 -2.221)" fill="#3d4977"/>
              <path id="Path_6236_-_Outline" data-name="Path 6236 - Outline" d="M14.222,19.516a.848.848,0,0,1-.847-.847V10.722a.847.847,0,0,1,1.695,0v7.946A.848.848,0,0,1,14.222,19.516Zm0-9.391a.6.6,0,0,0-.6.6v7.946a.6.6,0,0,0,1.195,0V10.722A.6.6,0,0,0,14.222,10.125Z" transform="translate(-3.193 -2.221)" fill="#3d4977"/>
              <path id="Path_6237" data-name="Path 6237" d="M10.444,19.391a.722.722,0,0,1-.719-.657L9,10.787a.722.722,0,1,1,1.439-.13l.722,7.946a.722.722,0,0,1-.72.787Z" transform="translate(-1.943 -2.221)" fill="#3d4977"/>
              <path id="Path_6237_-_Outline" data-name="Path 6237 - Outline" d="M10.445,19.516h0a.843.843,0,0,1-.843-.771L8.878,10.8a.847.847,0,0,1,1.688-.153l.722,7.946a.847.847,0,0,1-.844.924Zm-.724-9.391-.054,0a.6.6,0,0,0-.54.649l.722,7.946a.6.6,0,0,0,.594.544v0a.6.6,0,0,0,.595-.651l-.722-7.946A.6.6,0,0,0,9.721,10.125Z" transform="translate(-1.943 -2.221)" fill="#3d4977"/>
              <path id="Path_6238" data-name="Path 6238" d="M17.723,19.391A.722.722,0,0,1,17,18.6l.722-7.946a.722.722,0,1,1,1.439.13l-.722,7.946A.722.722,0,0,1,17.723,19.391Z" transform="translate(-4.164 -2.221)" fill="#3d4977"/>
              <path id="Path_6238_-_Outline" data-name="Path 6238 - Outline" d="M17.723,19.516h0a.847.847,0,0,1-.844-.924l.722-7.946a.847.847,0,1,1,1.688.153l-.722,7.946A.843.843,0,0,1,17.723,19.516Zm.732-9.39a.6.6,0,0,0-.605.543l-.722,7.946a.6.6,0,0,0,.595.651v0a.6.6,0,0,0,.594-.544l.722-7.946a.6.6,0,0,0-.541-.649Z" transform="translate(-4.164 -2.221)" fill="#3d4977"/>
              <path id="Path_6239" data-name="Path 6239" d="M16.224,6.334h-6.5A.722.722,0,0,1,9,5.612V2.722A.722.722,0,0,1,9.722,2h6.5a.722.722,0,0,1,.722.722v2.89A.722.722,0,0,1,16.224,6.334ZM10.445,4.89H15.5V3.445H10.445Z" transform="translate(-1.943)" fill="#3d4977"/>
              <path id="Path_6239_-_Outline" data-name="Path 6239 - Outline" d="M16.224,6.459h-6.5a.848.848,0,0,1-.847-.847V2.722a.848.848,0,0,1,.847-.847h6.5a.848.848,0,0,1,.847.847v2.89A.848.848,0,0,1,16.224,6.459Zm-6.5-4.334a.6.6,0,0,0-.6.6v2.89a.6.6,0,0,0,.6.6h6.5a.6.6,0,0,0,.6-.6V2.722a.6.6,0,0,0-.6-.6Zm5.9,2.89H10.32V3.32h5.307Zm-5.057-.25h4.807V3.57H10.57Z" transform="translate(-1.943)" fill="#3d4977"/>
            </g>
          </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="18.31" height="18.31" viewBox="0 0 18.31 18.31">
            <g id="Delete" transform="translate(-1.875 -1.875)">
              <path id="Path_6234" data-name="Path 6234" d="M15.549,21.17H7.9a2.16,2.16,0,0,1-2.156-1.952L4.5,6.794A.721.721,0,0,1,5.222,6h13a.722.722,0,0,1,.719.794L17.705,19.217a2.161,2.161,0,0,1-2.156,1.953ZM6.02,7.445l1.16,11.63a.721.721,0,0,0,.719.651h7.65a.721.721,0,0,0,.719-.651l1.16-11.63Z" transform="translate(-0.694 -1.11)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
              <path id="Path_6235" data-name="Path 6235" d="M19.337,7.445H2.722A.722.722,0,1,1,2.722,6H19.337a.722.722,0,1,1,0,1.445Z" transform="translate(0 -1.11)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
              <path id="Path_6236" data-name="Path 6236" d="M14.222,19.391a.722.722,0,0,1-.722-.722V10.722a.722.722,0,0,1,1.445,0v7.946A.722.722,0,0,1,14.222,19.391Z" transform="translate(-3.193 -2.221)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
              <path id="Path_6237" data-name="Path 6237" d="M10.444,19.391a.722.722,0,0,1-.719-.657L9,10.787a.722.722,0,1,1,1.439-.13l.722,7.946a.722.722,0,0,1-.72.787Z" transform="translate(-1.943 -2.221)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
              <path id="Path_6238" data-name="Path 6238" d="M17.723,19.391A.722.722,0,0,1,17,18.6l.722-7.946a.722.722,0,1,1,1.439.13l-.722,7.946A.722.722,0,0,1,17.723,19.391Z" transform="translate(-4.164 -2.221)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
              <path id="Path_6239" data-name="Path 6239" d="M16.224,6.334h-6.5A.722.722,0,0,1,9,5.612V2.722A.722.722,0,0,1,9.722,2h6.5a.722.722,0,0,1,.722.722v2.89A.722.722,0,0,1,16.224,6.334ZM10.445,4.89H15.5V3.445H10.445Z" transform="translate(-1.943)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
            </g>
          </svg>
          
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default DeleteChecklist;
