import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const ApproveIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t("buttonVisitorRegistrationApprove")}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.408"
              height="26"
              viewBox="0 0 23.408 26"
            >
              <g
                id="Group_7142"
                data-name="Group 7142"
                transform="translate(18204 -7383.253)"
              >
                <circle
                  id="Ellipse_2984"
                  data-name="Ellipse 2984"
                  cx="5.5"
                  cy="5.5"
                  r="5.5"
                  transform="translate(-18199.592 7383.253)"
                  fill="#36c96d"
                />
                <path
                  id="Subtraction_1"
                  data-name="Subtraction 1"
                  d="M11.768,12.649H0V10.39A10.392,10.392,0,0,1,15.67,1.44,7.248,7.248,0,0,0,10.39,8.4a7.174,7.174,0,0,0,1.377,4.245Z"
                  transform="translate(-18204 7395.801)"
                  fill="#36c96d"
                />
                <circle
                  id="Ellipse_2986"
                  data-name="Ellipse 2986"
                  cx="5.5"
                  cy="5.5"
                  r="5.5"
                  transform="translate(-18191.592 7398.253)"
                  fill="#36c96d"
                />
                <path
                  id="Path_6837"
                  data-name="Path 6837"
                  d="M-18175.691,7421.381a.563.563,0,0,1-.363-.133l-1.861-1.564a.565.565,0,0,1-.07-.8.566.566,0,0,1,.8-.069l1.379,1.159,2.893-4.335a.565.565,0,0,1,.783-.156.565.565,0,0,1,.156.784l-3.242,4.859a.564.564,0,0,1-.391.246A.552.552,0,0,1-18175.691,7421.381Z"
                  transform="translate(-11.235 -14.333)"
                  fill="#fff"
                />
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.408"
              height="26"
              viewBox="0 0 23.408 26"
            >
              <g
                id="Group_7142"
                data-name="Group 7142"
                transform="translate(18204 -7383.253)"
              >
                <circle
                  id="Ellipse_2984"
                  data-name="Ellipse 2984"
                  cx="5.5"
                  cy="5.5"
                  r="5.5"
                  transform="translate(-18199.592 7383.253)"
                  fill="rgba(99,115,129,0.4)"
                />
                <path
                  id="Subtraction_1"
                  data-name="Subtraction 1"
                  d="M11.768,12.649H0V10.39A10.392,10.392,0,0,1,15.67,1.44,7.248,7.248,0,0,0,10.39,8.4a7.174,7.174,0,0,0,1.377,4.245Z"
                  transform="translate(-18204 7395.801)"
                  fill="rgba(99,115,129,0.4)"
                />
                <circle
                  id="Ellipse_2986"
                  data-name="Ellipse 2986"
                  cx="5.5"
                  cy="5.5"
                  r="5.5"
                  transform="translate(-18191.592 7398.253)"
                  fill="rgba(99,115,129,0.4)"
                />
                <path
                  id="Path_6837"
                  data-name="Path 6837"
                  d="M-18175.691,7421.381a.563.563,0,0,1-.363-.133l-1.861-1.564a.565.565,0,0,1-.07-.8.566.566,0,0,1,.8-.069l1.379,1.159,2.893-4.335a.565.565,0,0,1,.783-.156.565.565,0,0,1,.156.784l-3.242,4.859a.564.564,0,0,1-.391.246A.552.552,0,0,1-18175.691,7421.381Z"
                  transform="translate(-11.235 -14.333)"
                  fill="#fff"
                />
              </g>
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default ApproveIcon;
