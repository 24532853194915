import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllWatchlistTemplate } from "../../slices/WatchlistGlobal/WatchlistGlobalSlice";
import { Avatar, Box, IconButton } from "@mui/material";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import HeaderToolbar from "../../components/HeaderToolbar";
import WatchlistFilterForm from "./WatchlistFilterForm";
import DataTable from "../../components/DataTable/DataTable";
import WatchlistTemplateForm from "./WatchlistTemplateForm";
import Search from "../../components/SearchTab/Search";
import SendWatchlistIcon from "../../assets/USA_Images/WatchlistIcon.svg";
import { Link } from "react-router-dom";
import DeleteWatchlist from "./DeleteWatchlist";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditWatchlistIcon from "./EditWatchlistIcon";
import DeleteIcon from "./DeleteIcon";
import WatchlistViewProfile from "../../components/VisitorActionsComponents/WatchlistViewProfile";

export default function WatchlistTemplateList() {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [openDeleteVisitor, setOpenDeleteVisitor] = useState(false);
  const [watchlistId, setWatchlistId] = useState();

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    contactEmail: "",
    contactPhoneNumber: "",
    purposeTypeId: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    contactEmail: "",
    contactPhoneNumber: "",
    purposeTypeId: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadData = (filter, paging) => {
    dispatch(getAllWatchlistTemplate(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI1000" && data.data)) {
          setTotalRecords(data.data.totalCount);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
          setRows(data.data.watchLists);
        } else {
          setRows([]);
        }
      })
      .catch((er) => {});
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      contactEmail: data.contactEmail,
      contactPhoneNumber: data.contactPhoneNumber,
      purposeTypeId: data.purposeTypeId,
    });
  };

  const [selectedObject, setSelectedObject] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    contactEmail: "",
    contactPhoneNumber: "",
    visitorCompanyName: "",
    purposeTypeId: "",
  });

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        test.firstName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.lastName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.email.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.phoneNumber.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.contactEmail.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.contactPhoneNumber.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.firstName} ${test.lastName}`.toLowerCase().includes(searchedVal.toLowerCase())
      )
    });
    setRows(filteredRows);
  };

  const handleEditWatchlist = (params) => {
    setOpenEditDialog(!openEditDialog);
    setSelectedObject(params);
  };

  const handleDeleteWatchlist = (params) => {
    setOpenDeleteVisitor(!openDeleteVisitor);
    setWatchlistId(params.id);
  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          alt={params.row.visitor.firstName}
          src={`data:image/png;base64,${params.row.visitor.profile}`}
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: "300px",
            height: "300px",
            border: "1px solid #FFFF",
            fontSize: "100px",
            borderRadius: "50%",
          }}
          alt={params.row.visitor.firstName}
          src={`data:image/png;base64,${params.row.visitor.profile}`}
        ></Avatar>
      }
    />
  );

  const columns = React.useMemo(() => [
    {
      field: "profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params);
      },
    },

    {
      field: "name",
      headerName: t("COMMON014"),
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
    },

    {
      field: "email",
      headerName: t("COMMON042"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.email,
    },

    {
      field: "phoneNumber",
      headerName: t("COMMON063"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.phoneNumber,
    },

    {
      field: "contactEmail",
      headerName: t("WATCHLISTTEMP002"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.contactEmail,
    },

    {
      field: "contactPhoneNumber",
      headerName: t("WATCHLISTTEMP009"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.contactPhoneNumber,
    },

    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
        getActions: (params) => [
          <GridActionsCellItem
            icon={<WatchlistViewProfile rowData={params.row} />}
            label="View"
          />,

          <GridActionsCellItem
            icon={<EditWatchlistIcon />}
            label="Edit"
            onClick={() => handleEditWatchlist(params.row)}
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteWatchlist(params)}
          />,
        ],
    },
  ]);

  return (
    <div>
      <HeaderToolbar
        src={SendWatchlistIcon}
        onClick={handleOpen}
        title={t("WATCHLISTTEMP001")}
        tooltipTitle={t("WATCHLIST005")}
      />

      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <WatchlistFilterForm
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <WatchlistTemplateForm
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            handleClose(loadData(filter, paging));
          }}
        />
      )}

      {openEditDialog && (
        <WatchlistTemplateForm
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false);
            loadData(filter, paging);
            setSelectedObject({
              id: "",
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              contactEmail: "",
              contactPhoneNumber: "",
              visitorCompanyName: "",
              purposeTypeId: "",
            });
          }}
        />
      )}

      {openDeleteVisitor && (
        <DeleteWatchlist
          open={openDeleteVisitor}
          handleClose={(data) => {
            setOpenDeleteVisitor(data);
            loadData(filter, paging);
          }}
          DeleteId={watchlistId}
        />
      )}

    </div>
  );
}
