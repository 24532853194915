import { Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import CommonUtil from '../../Util/CommonUtils';
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import jwt_decode from "jwt-decode";
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import Toast from "../../components/ToastContainer/CustomToast";
import { addRestrictTypeV2, updateRestrictTypeV2 } from '../../slices/RestirctTypeSlice';

export default function AddRestrictType(props) {
    const { open, handleClose } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
  
    useEffect(() => {
        if (props.selectedObject.id) {
            setState({
                ...state,
                id: props.selectedObject.id,
                code: props.selectedObject.code,
                name: props.selectedObject.name,
                description: props.selectedObject.description,

            });
        }
    }, []);

    const [state, setState] = React.useState({
        id: '',
        code: '',
        name: '',
        description: '',
    });

    const [error, setError] = useState({
        name: false,
        code: false,
        description: false,

    });

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
        setError({
            [name]: false,
        });
    };

    const handleSumbit = () => {

        if (state.name === '' || state.name === undefined) {
            setError({
                name: 'error'
            })
            Toast('Please Enter Restrict Name', 'error')
        }
        else if (state.code === '' || state.code === undefined) {
            setError({
                code: 'error'
            })
            process.env.REACT_APP_ENVIRONMENT === 'USA' ?Toast(t("KEY"), "error"):Toast('Please Enter Code', "error")
        }

        else if (!CommonUtil.validateName(state.name)) {
            Toast(t('nameValidation'), 'error')
        }
        else if (CommonUtil.checkCharactersGreaterThan50(state.name)) {
            Toast(t("NAMELENGTH"), 'error')
        }
        else if ((state.description !== undefined || state.description !== '' ) &&
         CommonUtil.checkCharactersGreaterThan250(state.description)) {
            Toast(t("RESTRICTERROR003"), 'error')
        }
    
      else  if (CommonUtil.isEmptyString(props.selectedObject.id)) {
            dispatch(addRestrictTypeV2(state))
                .unwrap().then((data) => {
                    if (data.code === 'CVSI0041') {
                        Toast('Restrict Type Created Successfully', "success");
                        handleClose();
                    } else {
                        onError(data);
                    }
                });
        } else {
            dispatch(updateRestrictTypeV2(state))
                .unwrap().then((data) => {
                    if (data.code === 'CVSI0042') {
                        Toast('Restrict Type Updated Successfully', 'success');
                        handleClose();
                    } else {
                        onError(data);
                    }
                });
        }
    };

    const onError = (data) => {
        if (data)
            switch (data.code) {
                case "CVAE1015":
                    setError({ emailId: true });
                    break;
                default:
                    setError({ ...error });
            }
         Toast(data.message,"error");
       // process.env.REACT_APP_ENVIRONMENT === 'USA' ?Toast(t("Key already exist!!"), "error"):Toast(data.message,"error")
    };

    return (
        <DialogBox Header={state.id ? "Update Restrict Type" : "Add Restrict Type"}
            acceptText={'Submit'} cancelText={'Cancel'}
            style={{ color: '#3D4977', opacity: 1, minHeight: '' }}
            fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}
            cancel={handleClose} onSubmit={handleSumbit}>
            <Grid
                container
                justifyContent="center"
                style={{ padding: "16px 32px", width: '100%', }}
                spacing={2}
            >

                <Grid item xs={12} xm={10} >
                    <CustomTextfeild
                        error={error.name}
                        name='name'
                        label='Name *'
                        helperText=''
                        value={state.name}
                        handleChange={(e) => handleChange(e)}
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>

            {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                <Grid item xs={12} xm={10} >
                    <CustomTextfeild
                        error={error.code}
                        name='code'
                        label={t('COMMON067')}
                        helperText=''
                        value={state.code}
                        handleChange={(e) => handleChange(e)}
                        required={true}
                        // inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 50 }}
                    />
                </Grid> :

                <Grid item xs={12} xm={10} >
                    <CustomTextfeild
                        error={error.code}
                        name='code'
                        label={t('VISTYPE001')}
                        helperText=''
                        value={state.code}
                        handleChange={(e) => handleChange(e)}
                        required={true}
                        // inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 50 }}
                    />
                </Grid> }

                <Grid item xs={12} xm={10} >
                    <CustomTextfeild
                        error={error.description}
                        name='description'
                        label={t('RestrictTypeForm005')}
                        helperText=''
                        value={state.description}
                        handleChange={(e) => handleChange(e)}
                        inputProps={{ maxLength: 120 }}
                    />
                </Grid>
            </Grid>
        </DialogBox>
    )
}