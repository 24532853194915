import { Tooltip, Grid, Typography, Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import { useTranslation } from 'react-i18next'
import Toast from '../../components/ToastContainer/CustomToast'
import FilterIcons from '../../assets/USA_Images/dealer/filter.svg'
import CustomDropdown from '../../components/CustomInputs/CustomDropdown'
import CommonUtil from '../../Util/CommonUtils'

export default function UserFilter(props) {
  const [open, setOpen] = React.useState(props.open)
  const { t } = useTranslation()
  const status = [
    { label: t('STATUS_ACTIVE') },
    { label: t('STATUS_IN_ACTIVE') }
  ]

  const [payload, setPayload] = useState({
    status: '',
  })

  const handleClose = () => {
    setPayload({
      status: '',
    })
    setOpen(false)
  }

  const [error, setError] = useState({
    status: false,
  })

  const reset = () => {
    if (payload.status.trim() === '') {
      Toast(t('Nothing to Reset'), 'error')
      return
    }

    setPayload({
      status: false,
    })
  }


  const handleChangeStatus = (data) => {
    let status = ''
    switch (data) {
      case t('STATUS_ACTIVE'):
        status = 'ACTIVE'
        break;
      case t('STATUS_IN_ACTIVE'):
        status = 'INACTIVE'
        break;
      default:
        status = ''
    }
    return status;
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  const handleSubmit = () => {
    if (payload.status.trim() === '') {
      Toast(t('COMMON154'), 'error')
      return
    }
    props.applyFilter(payload)
    setPayload({
      status: '',
    })
    handleClose()
  }

  useEffect(() => { }, [])

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t('FILTER')}
          </Typography>
        }
      >
        <Tooltip title={t('FILTER')}>
          <img alt="Dealer" src={FilterIcons} onClick={() => setOpen(true)} />
        </Tooltip>
      </Tooltip>

      <DialogBox
        Header={t('FILTER')}
        acceptText={t('COMMON024')}
        cancelText={t('COMMON008')}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: '0px 32px', width: '100%', marginTop: '15px' }}
        >
          <Grid item xs={12} sm={10} style={{ marginTop: '30px' }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={status}
              sx={{ width: "100%" }}
              value={payload.status}
              error={error.status}
              renderInput={(params) => <TextField {...params} label={t('CUSTDATABLE07')} />}
              onChange={(event, value) => {
                if (!CommonUtil.isEmpty(value)) {
                  const selectedStatus = handleChangeStatus(value.label)
                  setPayload({
                    ...payload,
                    status: selectedStatus
                  })
                }
                else {
                  setPayload({
                    ...payload,
                    status: ''
                  })
                }
              }}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
