import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, Grid, Typography } from "@mui/material";
import ViewIcon from "../Invitations/ViewIcon";
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
},
'& .MuiDialogActions-root': {
    padding: theme.spacing(1),
},
'& .MuiDialog-paper': {
    overflowY: 'visible',
    borderRadius: '14px',
    minWidth: '850px',
},
}));

const BootstrapDialogTitle = ({ children, onClose, ...props }) => {

  return (
    <DialogTitle variant="h4" sx={{ m: 0, p: 2, fontSize: '18px', color: '#3D4977' }} {...props}>
    {children}
    {onClose ? (
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
    ) : null}

</DialogTitle>
  );
};


function PurposeTypeViewCard({ heading, description, children, ...props }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const handleClickOpen = () => {
      setOpen(true);
  };
  const handleClose = () => {
      setOpen(false);
  };
  useEffect(() => {
  }, []);

  return (
    <div>
      <Grid onClick={handleClickOpen}>
        <ViewIcon />
      </Grid>
      <Grid>
        <BootstrapDialog 
          onClose={handleClose}
          open={open}
        >
            <BootstrapDialogTitle onClose={handleClose}>
           {props.title}
                    </BootstrapDialogTitle>
          <Divider variant="middle" />
          <DialogContent>
            <Grid container>
              <Grid container spacing={3} p={1}>
                <Grid item xs={3} >Purpose</Grid>
                <Grid item xs={1}>:</Grid>
                <Grid item xs={8}>
                  {props.rowData.name}
                </Grid>
              </Grid>
              <Grid container spacing={3} p={1}>
              {process.env.REACT_APP_ENVIRONMENT === 'USA'?
              <Grid item xs={3}><Typography variant="body">{t("COMMON067")}</Typography></Grid>
              :
              <Grid item xs={3}><Typography variant="body">{t("VISTYPE001")}</Typography></Grid>}
                <Grid item xs={1}>:</Grid>
                <Grid  item xs={8}>
                  {props.rowData.code}
                </Grid>
              </Grid>
              <Grid container spacing={3} p={1}>
                <Grid item xs={3}>Checklist Template</Grid>
                <Grid item xs={1}>:</Grid>
                <Grid  item xs={8}>
                  {props.rowData.checklistTemplateName}
                </Grid>
              </Grid>
              <Grid container spacing={3} p={1}>
                <Grid item xs={3}> Description</Grid>
                <Grid item xs={1}>:</Grid>
                <Grid item xs={8} style={{overflowWrap:'break-word'}}>
                  {props.rowData.description}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
    </div>
  );
}

export default PurposeTypeViewCard;
