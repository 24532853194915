import { Avatar } from '@material-ui/core'
import { Box, CircularProgress, Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import '../../App.css'
import CommonUtil from '../../Util/CommonUtils'
import DataTable from '../../components/DataTable/DataTable'
import HeaderToolbar from '../../components/HeaderToolbar'
import InvitationFilterFromReport from '../../components/InvitationFilterFromReport'
import Search from '../../components/SearchTab/Search'
import Toast from '../../components/ToastContainer/CustomToast'
import CustomStyleStatus from '../../components/statusStyleFormat/statusStyleFormat'
import { exportWalkInRegistrationVisitors } from '../../services/Invitations/ExportService'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { listInvitations } from '../../slices/Invitations/InvitationsSlice'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import BlacklistVisitorForm from '../BlacklistVisitors/BlacklistVisitorForm'
import BlockVisitorIcon from '../Invitations/BlockVisitorIcon'
import FilterExportDialog from './FilterExportDialog'
import ReportPageViewProfile from './ReportPageViewProfile'
import TransactionHistory from './TransactionHistory'
import { ThemeContext } from '../../theme/ThemeContext';
import BlockVisitorIcon1 from '../../../src/assets/USA_Images/login_images_visitor/BlockVisitorIcon1.svg'

export default function WalkInRegistrationReport() {
  const [clear, setClear] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [totalRecords, setTotalRecords] = useState(1)
  const [rows, setRows] = useState([])
  const [openBlacklistVisitor, setOpenBlacklistVisitor] = useState(false)
  const [blacklistId, setBlacklistId] = useState()
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const { darkMode } = useContext(ThemeContext);

  const status = [
    'SCHEDULED',
    'CONFIRMED',
    'APPROVED',
    'CHECKED_IN',
    'CHECKED_OUT',
    'CANCELLED',
    'INITIATED_FOR_BLOCKED',
    'BLOCKED',
    'REJECTED',
    'MISSED',
    'COMPLETED',
    'LOGGED',
  ]

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  var today = new Date()
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T23:59:59'
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T00:00'

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const [filter, setFilter] = React.useState({
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'CANCELLED',
      'INITIATED_FOR_BLOCKED',
      'BLOCKED',
      'REJECTED',
      'MISSED',
      'COMPLETED',
      'LOGGED',
    ],
    visitorTypeId: '',
    siteId: '',
    scheduledStartDate: '',
    scheduledEndDate: '',
    firstName: '',
    lastName: '',
    visitMode: ['WALKIN_REGISTARTION', 'WALKIN_REGISTARTION_KIOSK'],
    isPagable: true,
  })

  const defaultFilter = {
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'CANCELLED',
      'INITIATED_FOR_BLOCKED',
      'BLOCKED',
      'REJECTED',
      'MISSED',
      'COMPLETED',
      'LOGGED',
    ],
    visitorTypeId: '',
    siteId: '',
    scheduledStartDate: '',
    scheduledEndDate: '',
    firstName: '',
    lastName: '',
    visitMode: ['WALKIN_REGISTARTION', 'WALKIN_REGISTARTION_KIOSK'],
    isPagable: true,
  }

  const handleChange = (newPage, size) => {
    setLoading(true)
    setPage(newPage)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging, clear])

  const handleOpen = () => {
    setOpen(true)
  }

  const resetFilter = () => {
    setLoading(true)
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const loadData = (filter, paging) => {
    dispatch(listInvitations(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          setLoading(false)
          setPageSize(data.data.pageSize)
          setTotalRecords(data.data.totalCount)
          setRows(data.data.visits)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  const handleFilter = (data) => {
    setLoading(true)
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      siteId: data.siteId,
      visitorTypeId: data.visitorTypeId,
      visitMode: data.visitMode,
      firstName: data.firstName,
      lastName: data.lastName,
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
    })
  }

  const handleDatePickerFilter = (sdate, edate) => {
    setPaging({ ...paging, page: 1 })
    setFilter({
      scheduledStartDate: sdate,
      scheduledEndDate: edate,
    })
  }

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(page, rowsPerPage)
      setDel(false)
    } else {
      if (del === true) {
        loadData(page - 1, rowsPerPage)
        setDel(false)
      }
    }
  }, [del])

  const globalsearch = (searchedVal) => {
    if (searchedVal === 'Check In') {
      searchedVal = 'CHECKED_IN'
    }
    if (searchedVal === 'Check Out') {
      searchedVal = 'CHECKED_OUT'
    }

    if (
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? searchedVal === 'Blacklist Initiated'
        : searchedVal === 'Block Initiated'
    ) {
      searchedVal = 'INITIATED_FOR_BLOCKED'
    }

    if (
      searchedVal === 'Blacklist' ||
      searchedVal.toLowerCase() === 'blacklist'
    ) {
      searchedVal = 'BLOCKED'
    }

    const filteredRows = rows.filter((test) => {
      if (test.purposeType !== null) {
        return (
          (test.visitor.firstName
            ? test.visitor.firstName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.lastName
            ? test.visitor.lastName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.hostName
            ? test.visitor.hostName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.scheduledStartDate
            ? test.visitor.scheduledStartDate
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
          (test.purposeType.name
            ? test.purposeType.name
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          `${test.visitor.firstName} ${test.visitor.lastName}`
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        )
      } else {
        return (
          (test.visitor.firstName
            ? test.visitor.firstName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.lastName
            ? test.visitor.lastName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.hostName
            ? test.visitor.hostName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.scheduledStartDate
            ? test.visitor.scheduledStartDate
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
          `${test.visitor.firstName} ${test.visitor.lastName}`
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        )
      }
    })
    setRows(filteredRows)
  }

  const totalduration = (params) => {
    let start = moment(params.row.checkIn)
    let end = moment(params.row.checkOut)
    let duration = moment.duration(end.diff(start))
    let hours = Math.floor(duration.asHours())
    let minutes = duration.minutes()
    let formattedDuration =
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0')
    return formattedDuration
  }

  const handleBlacklistVisit = (params) => {
    setOpenBlacklistVisitor(!openBlacklistVisitor)
    setBlacklistId(params.row.id)
  }

  const getStatus = (params) => {
    if (
      params.row.status === 'BLOCKED' ||
      params.row.status === 'INITIATED_FOR_BLOCKED'
    ) {
      return true
    }
  }

  const handleWalkInRegistrationExport = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      status: [
        'SCHEDULED',
        'CONFIRMED',
        'APPROVED',
        'CHECKED_IN',
        'CHECKED_OUT',
        'CANCELLED',
        'INITIATED_FOR_BLOCKED',
        'BLOCKED',
        'REJECTED',
        'MISSED',
        'COMPLETED',
        'LOGGED',
      ],
      visitMode: ['WALKIN_REGISTARTION', 'WALKIN_REGISTARTION_KIOSK'],
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
    })

    const stats = {
      page: 1,
      rowsPerPage: 1000,
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
      status: [
        'SCHEDULED',
        'CONFIRMED',
        'APPROVED',
        'CHECKED_IN',
        'CHECKED_OUT',
        'CANCELLED',
        'INITIATED_FOR_BLOCKED',
        'BLOCKED',
        'REJECTED',
        'MISSED',
        'COMPLETED',
        'LOGGED',
      ],
    }

    dispatch(exportWalkInRegistrationVisitors(onSuccess, onFailure, stats))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          onSuccess()
        } else {
          onFailure()
        }
      })
      .catch((er) => { })
    // return exportInvitationVisitors(onSuccess, onFailure, filter);
  }

  const onSuccess = () => {
    return Toast(t('EXPORTSUCC001'), 'success')
  }

  const onFailure = () => {
    Toast(t('COMMON000'), 'error')
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
    />
  )

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...'
    } else {
      return text
    }
  }

  const [date, setDate] = useState()

  useEffect(() => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setDate(formatDate(data.data.dateFormat));
        }
      })
  }, [])

  const formatDate = (dateFormat) => {
    switch (dateFormat) {
      case 'dd-MM-yyyy':
        return 'dd-MM-yyyy HH:mm';
      case 'MM-dd-yyyy':
        return 'MM-dd-yyyy HH:mm';
      case 'yyyy-MM-dd':
        return 'yyyy-MM-dd HH:mm';
      default:
        return 'yyyy-MM-dd HH:mm';
    }
  };

  const renderCellScheduledStartDate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const renderCellCheckIn = (date) => (params) => {
    const row = params.row || {};
    const checkIn = row.checkIn || null;
    if (!checkIn) return <span className="table-cell-trucate"></span>;
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(checkIn)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const renderCellCheckOut = (date) => (params) => {
    const row = params.row || {};
    const checkOut = row.checkOut || null;
    if (!checkOut) return <span className="table-cell-trucate"></span>;
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(checkOut)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };


  const columns = React.useMemo(() => [
    {
      field: 'visitor.profile',
      headerName: '',
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },
    {
      field: 'visitor.firstName',
      headerName: t('COMMON014'),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null
          ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
          : params.row.visitor.firstName,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.visitor.lastName !== null
            ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
            : params.row.visitor.firstName
            }`}
          placement="bottom-start"
        >
          <span>
            {handleTextOverflow(
              params.row.visitor.lastName !== null
                ? params.row.visitor.firstName +
                ' ' +
                params.row.visitor.lastName
                : params.row.visitor.firstName,
            )}
          </span>
        </Tooltip>
      ),
    },

    {
      field: 'purpose',
      headerName: t('Purpose'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.visitor.purposeType !== null
          ? params.row.purposeType?.name
          : '',
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.visitor.purposeType !== null
            ? params.row.purposeType?.name
            : ''
            }`}
          placement="bottom-start"
        >
          <span>
            {handleTextOverflow(
              params.row.visitor.purposeType !== null
                ? params.row.purposeType?.name
                : '',
            )}
          </span>
        </Tooltip>
      ),
    },

    {
      field: 'scheduledStartDate',
      headerName: t('INVITEDATELBL'),
      flex: 1,
      width: 10,
      renderCell: renderCellScheduledStartDate(date)
    },
    {
      field: 'checkIn',
      headerName: t('Check In'),
      flex: 1,
      width: 10,
      renderCell: renderCellCheckIn(date)
    },
    {
      field: 'checkOut',
      headerName: t('Check Out'),
      flex: 1,
      width: 10,
      renderCell: renderCellCheckOut(date)
    },

    {
      field: 'duration',
      headerName: 'Total Duration (HH:MM)',
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.checkOut && params.row.checkIn ? totalduration(params) : '',
    },

    {
      field: 'status',
      headerName: t('COMMON001'),
      flex: 1,
      minWidth: 10,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />
      },
    },
    {
      field: 'Transaction History',
      headerName: 'Transaction History',
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<TransactionHistory rowData={params.row} date={date} />}
          label="Transaction History"
        />,
      ],
    },

    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ReportPageViewProfile rowData={params.row} />}
          label="View"
        />,
        <Tooltip title="Block Visitor">
          <div>
        <GridActionsCellItem
          icon={darkMode ? <img src={BlockVisitorIcon1} alt="Block Visitor Icon" /> : <BlockVisitorIcon />}
          label="BlockVisitor"
          onClick={() => handleBlacklistVisit(params)}
          disabled={getStatus(params)}
        />
        </div>
        </Tooltip>
        ,
      ],
    },
  ], [date])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
    {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}
      <HeaderToolbar
        title= {t('WALKINREPORTLBL')}
         
      />

      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        sx={{marginLeft: darkMode ? '-16px' : ''}}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <InvitationFilterFromReport
            rowsPerPage={rowsPerPage}
            selectedObject={filter.visitMode}
            applyFilter={(data) => handleFilter(data)}
            date={date}
          />
        </Box>

        <Box item p={1}>
          <FilterExportDialog
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleWalkInRegistrationExport(data)}
            date={date}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openBlacklistVisitor && (
        <BlacklistVisitorForm
          open={openBlacklistVisitor}
          handleClose={(data) => {
            setOpenBlacklistVisitor(data)
            loadData(filter, paging)
          }}
          disable={() => setDel(true)}
          BlacklistVisitorId={blacklistId}
        />
      )}
    </>
  )
}
