import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
    let query = "?PageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
  
    if (!CommonUtil.isEmptyString(filter.deliveresEndDate)) {
      query = query + "&deliveresEndDate=" + filter.deliveresEndDate;
    }
    if (!CommonUtil.isEmptyString(filter.deliveresStartDate)) {
      query = query + "&deliveresStartDate=" + filter.deliveresStartDate;
    }
    if (!CommonUtil.isEmptyString(filter.deliveryCompanyName)){
      query = query + "&deliveryCompanyName=" + filter.deliveryCompanyName;
    }

  
    return query;
  };
  
const get = (filter) => {
    return api.securedAxios().get("/web/delivery_report" + buildQuery(filter));
};
const postDeliveryDetails = (payload) => {
    return api.securedAxios().post("/web/delivery_report/download", payload  );
};

const DeliveryReportService = {
    get,
    postDeliveryDetails
};

export default DeliveryReportService;
