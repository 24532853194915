import { Close } from '@material-ui/icons';
import { Box, Dialog, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DataTable from '../../components/DataTable/DataTable';
import Search from '../../components/SearchTab/Search';
import MoreOption from '../../components/v4/MoreOption';
import { apkUpdate, getListOfAndroidDevice } from '../../slices/AndroidDevices/AndroidDeviceSlices';
import AndroidDeviceListFilter from './AndroidDeviceListFilter';
import DeleteDevice from './DeleteDevice';
import SimpleDialogDemo from '../Approvals/ImageDailogBox';

const styleDialog = {
  '& .MuiDialog-paper': {
    padding: '12px 24px',
    position: 'absolute',
    top: '5em',
    minWidth: '60%',
  },
};

const defaultMenuData = [
  { label: 'Update the Device APK', index: 0 }
];

export const VisitorDevice = () => {
  const [open, setOpen] = useState(false);
  const [clear, setClear] = useState(false);
  const [openInformation, setOpenInformation] = useState(false);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [updateData, setUpdateData] = useState({});
  const [reload, setReload] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [filterPayload, setFilterPayload] = useState({
    deviceName: "",
    deviceNumber: ""
  });

  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [id, setId] = useState();

  const defaultFilter = {
    id: "",
    deviceName: "",
    status: "",
    companyId: "",
    deviceNumber: "",
    model: ""
  };

  const [filter, setFilter] = useState(defaultFilter);

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const [paging, setPaging] = useState(defaultPaging);

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size
    });
  };

  const handleClose = () => {
    loadData(filter, paging);
    setOpen(false);
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging]);

  const updateStatus = () => {
    const currentTime = new Date();
    const updatedRows = rows.map(device => {
      if (device.updateDateTime) {
        const updateDateTimeUTC = new Date(device.updateDateTime);
        const updateDateTimeLocal = new Date(updateDateTimeUTC.getTime() - (updateDateTimeUTC.getTimezoneOffset() * 60000));
        const timeDifference = Math.abs(currentTime - updateDateTimeLocal);
        const isUpdateDateTimeMatched = timeDifference <= (1 * 60 * 1000);
        return { ...device, statusUpdate: isUpdateDateTimeMatched };
      }
      return { ...device, statusUpdate: false };
    });
    setRows(updatedRows);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Logs every 1 sec check current min and update min');
      updateStatus();
    }, 1000);
    return () => clearInterval(interval);
  }, [rows]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("every 20 sec list is load data");
      loadData(filter, paging);
    }, 20000);
    return () => clearInterval(interval);
  }, [filter, paging]);

  const loadData = (filter, paging) => {
    const payload = {
      deviceName: filter.deviceName,
      deviceNumber: filter.deviceNumber,
      pageNumber: paging.page,
      pageSize: paging.rowsPerPage
    };
    dispatch(getListOfAndroidDevice(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAE3003" && data.data) {
          setTotalRecords(data.data.totalCount);
          setRows(data.data.deviceList);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      });
  };

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (test.deviceName !== undefined ? test.deviceName.toLowerCase().includes(searchedVal.toLowerCase()) :
        test.deviceName ? test.deviceName.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || (test.deviceNumber ? test.deviceNumber.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || (test.deviceType.deviceModel ? test.deviceType.deviceModel.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || (searchedVal.toLowerCase() === "online" ? test.status.toLowerCase().includes("true") : "")
        || (searchedVal.toLowerCase() === "offline" ? test.status.toLowerCase().includes("false") : "");
    });
    setRows(filteredRows);
  };

  const checkStatus = (data) => {
    if (data === true) {
      return 'Online';
    }
    if (data === false) {
      return 'Offline';
    }
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 30) + '...';
    } else {
      return text;
    }
  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo />
  );

  const columns = React.useMemo(() => [
    {
      field: 'company.profile',
      headerName: '',
      sortable: false,
      width: 50,
      renderCell: (params) => {
        return ZoomHandaler(params);
      },
    },
    {
      field: 'deviceName',
      headerName: t('VISACCDEV002'),
      flex: 1,
      minWidth: 190,
      valueGetter:(params) => params.row.deviceName,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'deviceNumber',
      headerName: t('ACCDEV002'),
      flex: 1,
      minWidth: 350,
      valueGetter: (params) => params.row.deviceNumber,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'deviceModel',
      headerName: t('tableColumnDeviceModel'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.deviceType?.deviceModel,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'status',
      headerName: t('commonsTextFieldLabelStatus'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.statusUpdate,
      renderCell: (params) => (
        <span style={{ color: params.row.statusUpdate ? '#36C96D' : '#f56a5f' }}>
          {checkStatus(params.row.statusUpdate)}
        </span>
      ),
    },
    {
      field: 'version',
      headerName: t('Version'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row?.andriodDeviceVersion,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },
  ], [rows]);

  const handleFilter = (data) => {
    setClear(true);
    setPaging(defaultPaging);
    setFilter({
      deviceName: data.deviceName,
      deviceNumber: data.deviceNumber
    });
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px" }}>
      </Box>
      <Box display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        marginTop={'20px'}
      >
        <Box item p={1}>
          {clear && (
            <Link href="#" underline="hover" className="line"
              style={{ opacity: "1", color: "#E3393C", fontSize: "14px", underline: "hover", display: "inline", }}
              onClick={() => resetFilter()}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>
        <Box item p={1}>
          <AndroidDeviceListFilter
            rowsPerPage={rowsPerPage}
            onSubmit={(data) => { handleFilter(data) }}
          />
        </Box>
        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={(data) => loadData(filter, paging)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      <Dialog sx={styleDialog} onClose={() => setOpenInformation(!openInformation)} open={openInformation}>
        <IconButton onClick={() => setOpenInformation(!openInformation)} style={{ alignSelf: 'end' }}>
          <Close />
        </IconButton>
        <Typography variant='h5' component='div' gutterBottom style={{ color: '#474A4E', fontWeight: 'bold' }}>
          {t("Device Center Info")}
        </Typography>
        <Typography variant='subtitle1' gutterBottom component='div'>
          {t("DEVICEINFOO")}
          {t("DEVICEINFOOO")}
        </Typography>
        <Typography variant='subtitle1' gutterBottom component='div'>
          {t("DEVICEINFOO1")}
          {t("DEVICEINFOO2")}
        </Typography>
      </Dialog>
      {deleteOpen && <DeleteDevice
        open={deleteOpen}
        onClose={handleClose}
        deleteData={deleteData} />}
    </>
  );
};