import { Paper } from '@material-ui/core'
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TitleBar from '../../components/v4/TitleBar'
import { useTranslation } from 'react-i18next'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import Toast from '../../components/ToastContainer/CustomToast'
import { useDispatch } from 'react-redux'
import jwt_decode from 'jwt-decode'
import {
  createSettings,
  getListSettings,
  getListSettingsById,
  updateSettings,
} from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import CommonUtil from '../../Util/CommonUtils'
import { useHistory } from "react-router-dom";
import { REACT_DASHBOARD_PANEL } from '../../actions/EndPoints'

function DateFormatForHqAndDealer() {
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )
  const companyId = localStorage.getItem('companyId')

  const { t } = useTranslation()
  let history = useHistory();
  const dispatch = useDispatch()
  const [close, setclose] = useState()
  const [payload, setPayload] = useState({
    id: '',
    adminAndDealerDateFormat: 'MM-DD-YYYY',
    companyId: decoded_jwtToken.companyId,
    dateFormat: 'MM-DD-YYYY',
    invitationCodeDigits: '',
    walkInCodeDigits: '',
    selfRegistartionCodeDigits: '',
    invitationScanLimit: '2',
    walkInScanLimit: '2',
    selfRegistartionScanLimit: '2',
    invitationIntervalTime: '30',
    walkInIntervalTime: '30',
    selfIntervalTime: '30',
    invitationEarlyCheckInTime: '30',
    invitationLateCheckOutTime: '30',
    walkInEarlyCheckInTime: '30',
    walkInLateCheckOutTime: '30',
    selfEarlyCheckInTime: '30',
    selfLateCheckOutTime: '30',
    printerDevice: 'Kiosk',
    invitationVerifyMode: 'QRCODE',
    walkInRegistartionVerifyMode: 'QRCODE',
    selfRegistartionVerifyMode: 'QRCODE',
    viewedVersion:'',
    defaultHostId:''
  })
  const [error, setError] = useState({ dateFormat: false })

  const dateformat = [{ label: 'DD-MM-YYYY' }, { label: 'MM-DD-YYYY' },{label:'YYYY-MM-DD'}]

  const handleDataFormat = (event, newValue) => {
    if (newValue === null) {
      setclose()
      setPayload({
        ...payload,
        adminAndDealerDateFormat: '',
      })
    } else {
      setPayload({
        ...payload,
        adminAndDealerDateFormat: newValue.label,
      })
    }
  }

  useEffect(() => {
    loadSetting()
  }, [])

  const loadSetting = () => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          debugger
          setPayload({
            ...payload,
            adminAndDealerDateFormat: data.data.adminAndDealerDateFormat,
            id: data.data.id,
            viewedVersion: data.data.viewedVersion,
            defaultHostId:data.data.defaultHostId
          })
        }
      })
  }

  const handleSubmit = () => {
    if (payload.adminAndDealerDateFormat === '') {
      setError({ adminAndDealerDateFormat: true })
      Toast(t('DATEFORMATSELECT'), 'error')
      return
    }
    if (CommonUtil.isEmptyString(payload.id)) {
      var requestBody = {
        adminAndDealerDateFormat: payload.adminAndDealerDateFormat,
        companyId: decoded_jwtToken.companyId,
        dateFormat: payload.dateFormat,
        invitationCodeDigits: payload.invitationCodeDigits,
        walkInCodeDigits: payload.walkInCodeDigits,
        selfRegistartionCodeDigits: payload.selfRegistartionCodeDigits,
        invitationScanLimit: payload.invitationScanLimit,
        walkInScanLimit: payload.walkInScanLimit,
        selfRegistartionScanLimit: payload.selfRegistartionScanLimit,
        invitationIntervalTime: payload.invitationIntervalTime,
        walkInIntervalTime: payload.walkInIntervalTime,
        selfIntervalTime: payload.selfIntervalTime,
        invitationEarlyCheckInTime: payload.invitationEarlyCheckInTime,
        invitationLateCheckOutTime: payload.invitationLateCheckOutTime,
        walkInEarlyCheckInTime: payload.walkInEarlyCheckInTime,
        walkInLateCheckOutTime: payload.walkInLateCheckOutTime,
        selfEarlyCheckInTime: payload.selfEarlyCheckInTime,
        selfLateCheckOutTime: payload.selfLateCheckOutTime,
        printerDevice: payload.printerDevice,
        invitationVerifyMode: payload.invitationVerifyMode,
        walkInRegistartionVerifyMode: payload.walkInRegistartionVerifyMode,
        selfRegistartionVerifyMode: payload.selfRegistartionVerifyMode,
      }
      dispatch(createSettings(requestBody))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0063') {
            Toast(t('SETTINGSUCCESS'), 'success')
          } else if (data.code === 'CVAW0064') {
            Toast('Settings already present', 'error')
          } else {
            Toast(data.message, 'error')
          }
        })
    }
    else {
      dispatch(updateSettings(payload))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0071') {
            Toast(t('SETTINGUPDATESUCCESS'), 'success')
          } else if (data.code === 'CVAW0064') {
            Toast(t('SETTINGALREADY'), 'error')
          } else {
            Toast(data.message, 'error')
          }

        })
        .catch((er) => { })

    }
  }

  const handleCancel =()=>{
    history.push({
      pathname:  REACT_DASHBOARD_PANEL
  })
  }

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ m: 2, marginTop: -3 }}>
          <TitleBar title={t('APRMODE2')} back_button={true} />
        </Box>
      </Grid>
      <Paper>
        <Grid
          sx={{ width: { xs: '100%' } }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px',
          }}
        >
          <Grid item xs={12} sm={9}>
            <Autocomplete
              sx={{ width: 600 }}
              noOptionsText={'No Options found'}
              onChange={handleDataFormat}
              options={dateformat}
              value={payload.adminAndDealerDateFormat}
              renderInput={(params) => (
                <TextField {...params} label={t('APRMODE3')} />
              )}
            />
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              marginTop: '50px',
              paddingBottom: '100px',
              marginLeft: '163px',
              marginBottom: '300px',
            }}
          >
            <Grid item sx={{ ml: 3 }}>
              <CancelButtons
                onClick={handleCancel}
                color="secondary"
                variant="contained"
              >
                <Typography>{t('COMMON008')}</Typography>
              </CancelButtons>
            </Grid>
            <Grid item sx={{ ml: 3 }}>
              <SubmitButtons onClick={handleSubmit} variant="contained">
                <Typography>{t('COMMON007')}</Typography>
              </SubmitButtons>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default DateFormatForHqAndDealer
