import { Grid, makeStyles } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import CommonUtil from '../../Util/CommonUtils';
import CustomerDialog from '../../components/DialogBoxComponent/CustomerDialog';
import Toast from "../../components/ToastContainer/CustomToast";
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import { fetchCustomersByDealerIdWithoutStatus } from '../../slices/Customer/CustomerSlice';


let language = BrowserLanguage.getDefaultLanguage()


const useStyles = makeStyles((theme) => ({
    dialogbox: {
        '& .MuiDialog-paper': {
            // minWidth: '110vh',
            minWidth: "600px",
            // marginLeft:"250px",
        },
        ".& .css-f3jnds-MuiDataGrid-columnHeaders": {
            backgroundColor: "#F3F9FB"
        },
    },
}))

function CustomerDetailsByDealerIdExports(props) {
    const classes = useStyles();
    const { t } = useTranslation()
    const [open, setopen] = useState(props.open)
    const { handleClose } = props
    const [payload, setPayload] = useState({
        downloadExcel: "",
    });
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const [error, setError] = useState({
        downloadExcel: false,
    });
    const parentIdValue = props.parentId
    const dateFormat = props.dateFormat

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        const dealerData = {
            dealerId: parentIdValue
        }
        dispatch(fetchCustomersByDealerIdWithoutStatus(dealerData))
            .unwrap()
            .then(res => {
                if (res.code === "MSGS4024") {
                    setRows(res.data.companies);
                }
            })
    }

    const handleSubmit = () => {
        if (payload.downloadExcel === "") {
            setError({ downloadExcel: true });
            Toast(t('EXPORTERROR'), "error");
            return;
        }
        handleDownload();
        handleClose();
    };

    const handleDownload = async () => {
        const modifieData = customizeData(dealerExports);
        const ws = XLSX.utils.json_to_sheet(modifieData);
        const columnWidths = [
            { wch: 20 },
            { wch: 15 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 25 },

        ];
        ws['!cols'] = columnWidths;
        const wb = { Sheets: { 'Customer': ws }, SheetNames: ['Customer'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
        const fileExtension = '.xlsx';
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Customer Records' + fileExtension);
        Toast(t('EXPORT_SUCCESS'), 'success');
    }

    let customizeData = null;
    if (language === 'en') {
        customizeData = (data) => {
            return data.map(item => ({
                'Company Name': item.name,
                Email: item.email,
                Mobile: item.phone,
                Subscription: item.subscriptionName,
                'Billing Cycle': item.billingCycle,
                'Plan Base Price': item.planBasePrice / 100,
                'Next Payment Due date': item.subscriptionEndDate
                    ? dateFormat === 'dd-MM-yyyy HH:mm'
                        ? moment(item.subscriptionEndDate).format('DD-MM-YYYY HH:mm')
                        : dateFormat === 'MM-dd-yyyy HH:mm'
                            ? moment(item.subscriptionEndDate).format('MM-DD-YYYY HH:mm')
                            : dateFormat === 'yyyy-MM-dd HH:mm'
                                ? moment(item.subscriptionEndDate).format('YYYY-MM-DD HH:mm')
                                : ''
                    : '',
                'Next Payment Amount': item.planBasePrice / 100,
                'Dealer Name': item.dealerName

            }));
        };
    } else {
        customizeData = (data) => {
            return data.map(item => ({
                'nombre de empresa': item.name,
                'Correo electrónico': item.email,
                Móvil: item.phone,
                'Suscripción': item.subscriptionName,
                'Ciclo de facturación': item.billingCycle,
                'Precio base del plan': item.planBasePrice / 100,
                'Próxima fecha de vencimiento del pago': item.subscriptionEndDate
                    ? dateFormat === 'dd-MM-yyyy HH:mm'
                        ? moment(item.subscriptionEndDate).format('DD-MM-YYYY HH:mm')
                        : dateFormat === 'MM-dd-yyyy HH:mm'
                            ? moment(item.subscriptionEndDate).format('MM-DD-YYYY HH:mm')
                            : dateFormat === 'yyyy-MM-dd HH:mm'
                                ? moment(item.subscriptionEndDate).format('YYYY-MM-DD HH:mm')
                                : ''
                    : '',
                'Próximo monto del pago': item.planBasePrice / 100,
                'Nombre del comerciante': item.dealerName

            }));
        };
    }

    const onselectDownload = (event) => {
        setPayload({
            ...payload,
            downloadExcel: event,
        });
    };

    const dealerExports = rows;

    const options = [
        { label: t('EXCEL_DROP_DOWN') }
    ]
    return (
        <>
            <CustomerDialog
                className={classes.dialogbox}
                Header={t('DOWNLOADHEADER')}
                acceptText={t('DOWNLOADHEADER')}
                cancelText={t('COMMON008')}
                onSubmit={handleSubmit}
                open={open}
                onClose={handleClose}
                cancel={handleClose}
            >

                <Grid container
                    style={{ padding: "20px", width: "100%" }}
                    spacing={2}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={options}
                            value={payload.downloadExcel}
                            onChange={(event, newValue) => {
                                if (!CommonUtil.isEmpty(newValue)) {
                                    onselectDownload(newValue);
                                } else {
                                    onselectDownload('');
                                }
                                setError({ downloadExcel: false })
                            }}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label={t('SELECTEXCELFORMAT')} />}
                        />
                    </Grid>
                </Grid>

            </CustomerDialog>

        </>
    )
}

export default CustomerDetailsByDealerIdExports