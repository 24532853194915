import React, { useEffect, useState, useContext } from 'react'
import { Grid, Typography, Tooltip } from '@material-ui/core'
import DashboardCards from '../DashBoard/DashboardCards'
import DataTable from '../../components/DataTable/DataTable'
import { Avatar, Box, CircularProgress, Link } from '@mui/material'
import CommonUtil from '../../Util/CommonUtils'
import { useDispatch } from 'react-redux'
import Search from '../../components/SearchTab/Search'
import FilterForm from '../../components/DialogBoxComponent/FilterForm'
import { getDashBoardStatics } from '../../slices/DashBoard/DashBoardSlice'
import CustomStyleStatus from '../../components/statusStyleFormat/statusStyleFormat'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import { useTranslation } from 'react-i18next'
import { getAllVisitors } from '../../slices/Approvals/selfRegistarationApprovalSlice'
import CustomDateFilter from '../DailyReports/CustomDateFilter'
import { format } from 'date-fns'
import WhatsNewDialog from '../../components/DialogBoxComponent/WhatsNewDialog'
import { getDealerSubscriptions } from '../../slices/SubscriptionDealerSlice'
import {
  getListSettingsById,
  getVersion,
} from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import FreeTrialDialog from '../../components/DialogBoxComponent/FreeTrialDialog'
import { ThemeContext } from '../../theme/ThemeContext';
import jwt_decode from 'jwt-decode'
export default function Dashboard(props) {
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const [rows, setRows] = useState([])
  const [todaysStatics, setTodaysStatics] = useState({})
  const [clear, setClear] = useState(false)
  const { t } = useTranslation()
  const [settings, setSettings] = useState()
  const [latestVersion, setLatestVersion] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const { darkMode } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true)
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const [openExpireDialog, setOpenExpireDialog] = useState(false)
  const [currentFilter, setCurrentFilter] = useState({
    hostId: '',
    purposeTypeId: '',
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'CANCELLED',
      'INITIATED_FOR_BLOCKED',
      'BLOCKED',
      'REJECTED',
      'MISSED',
      'COMPLETED',
      'LOGGED',
    ],
    visitMode: [
      'INVITATION',
      'WALKIN_REGISTARTION',
      'SELF_REGISTARTION',
      'WALKIN_REGISTARTION_KIOSK',
    ],
  })

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  var today = new Date()
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T23:59:59'
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T00:00'

  const [selectedDates, setSelectedDates] = useState({
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
  })

  useEffect(() => {
    setSelectedDates({
      scheduledStartDate: CommonUtil.formatToUtc(startDate),
      scheduledEndDate: CommonUtil.formatToUtc(endDate),
    })
  }, [])

  const defaultFilter = {
    hostId: '',
    purposeTypeId: '',
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'CANCELLED',
      'INITIATED_FOR_BLOCKED',
      'BLOCKED',
      'REJECTED',
      'MISSED',
      'COMPLETED',
      'LOGGED',
    ],
    hostEmail: '',
    createdBy: '',
    visitMode: [
      'INVITATION',
      'WALKIN_REGISTARTION',
      'SELF_REGISTARTION',
      'WALKIN_REGISTARTION_KIOSK',
    ],
  }

  const [invFilter, setInvFilter] = React.useState({
    hostId: '',
    purposeTypeId: '',
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'CANCELLED',
      'INITIATED_FOR_BLOCKED',
      'BLOCKED',
      'REJECTED',
      'MISSED',
      'COMPLETED',
      'LOGGED',
    ],
    visitMode: ['INVITATION'],
  })

  const [regFilter, setRegFilter] = React.useState({
    hostId: '',
    purposeTypeId: '',
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'CANCELLED',
      'INITIATED_FOR_BLOCKED',
      'BLOCKED',
      'REJECTED',
      'MISSED',
      'COMPLETED',
      'LOGGED',
    ],
    visitMode: ['WALKIN_REGISTARTION', 'WALKIN_REGISTARTION_KIOSK'],
  })

  const [selfRegFilter, setSelfRegFilter] = React.useState({
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'CANCELLED',
      'INITIATED_FOR_BLOCKED',
      'BLOCKED',
      'REJECTED',
      'MISSED',
      'COMPLETED',
      'LOGGED',
    ],
    purposeTypeId: '',
    hostId: '',
    visitMode: ['SELF_REGISTARTION'],
  })

  const [checkinFilter, setCheckinFilter] = React.useState({
    hostId: '',
    purposeTypeId: '',
    status: ['CHECKED_IN'],
    visitMode: [
      'INVITATION',
      'WALKIN_REGISTARTION',
      'SELF_REGISTARTION',
      'WALKIN_REGISTARTION_KIOSK',
    ],
  })

  const [checkOutFilter, setCheckOutFilter] = React.useState({
    hostId: '',
    purposeTypeId: '',
    status: ['CHECKED_OUT', 'COMPLETED'],
    visitMode: [
      'INVITATION',
      'WALKIN_REGISTARTION',
      'SELF_REGISTARTION',
      'WALKIN_REGISTARTION_KIOSK',
    ],
  })
  const [confirmedFilter, setConfirmedFilter] = React.useState({
    hostId: '',
    purposeTypeId: '',
    status: ['CONFIRMED'],
    isPagable: true,
    createdAt: CommonUtil.formatToUtc(startDate),
    visitMode: [
      'INVITATION',
      'WALKIN_REGISTARTION',
      'SELF_REGISTARTION',
      'WALKIN_REGISTARTION_KIOSK',
    ],
  })

  const applyFilter = (filterData) => {
    setLoading(true)
    setClear(true)
    const query = {
      purposeTypeId: filterData.purposeTypeId,
      status: filterData.status,
      hostId: filterData.hostId,
      visitMode: [filterData.visitMode],
    }
    setCurrentFilter({
      ...currentFilter,
      ...query,
    })
  }

  const resetFilter = () => {
    setLoading(true)
    setClear(false)
    setCurrentFilter({
      ...defaultFilter,
    })
  }

  const handleChange = (newPage, size) => {
    setLoading(true)
    setPage(newPage)
    loadData(newPage, size)
    setRowsPerPage(size)
  }

  useEffect(() => {
    if (localStorage.getItem('companyId') !== null) {
      dispatch(getDealerSubscriptions(localStorage.getItem('companyId')))
        .unwrap()
        .then((data) => {
          if (data.data !== undefined) {
            let date = new Date()
            const currrentDateTime = format(date, "yyyy-MM-dd'T'HH:mm:ss")
            if (
              data.data.currentVisitorsCount >= data.data.limitVisitCount ||
              currrentDateTime > data.data.subscriptionEndDate
            )
              setOpenExpireDialog(true)
          }
        })
    }
  }, [])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  useEffect(() => {
    loadSetting()
    loadData(1, rowsPerPage)
    loadDashBoard()
  }, [currentFilter, selectedDates])

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: { ...currentFilter, ...selectedDates },
      page: page,
      rowsPerPage: rowsPerPage,
    }

    dispatch(getAllVisitors(Object.assign(payload)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          setLoading(false)
          setTotalRecords(data.data.totalCount)
          setRows(data.data.visits)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  const loadDashBoard = () => {
    dispatch(getDashBoardStatics(selectedDates))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          setTodaysStatics(data.data)
        } else {
          setTodaysStatics({})
        }
      })
      .catch((er) => { })
  }

  const globalsearch = (searchedVal) => {
    if (searchedVal === 'Check In') {
      searchedVal = 'CHECKED_IN'
    }
    if (searchedVal === 'Check Out') {
      searchedVal = 'CHECKED_OUT'
    }
    if (
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? searchedVal === 'Blacklist Initiated'
        : searchedVal === 'Block Initiated'
    ) {
      searchedVal = 'INITIATED_FOR_BLOCKED'
    }
    if (
      searchedVal === 'Blacklist' ||
      searchedVal.toLowerCase() === 'blacklist'
    ) {
      searchedVal = 'BLOCKED'
    }

    const filteredRows = rows.filter((test) => {
      if (test.purposeType !== null) {
        return (
          (test.visitor.firstName
            ? test.visitor.firstName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.lastName
            ? test.visitor.lastName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.phone
            ? test.visitor.phone
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.email
            ? test.visitor.email
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.hostName
            ? test.hostName.toLowerCase().includes(searchedVal.toLowerCase())
            : '') ||
          test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
          test.purposeType.name
            .toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          `${test.visitor.firstName} ${test.visitor.lastName}`
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        )
      } else {
        return (
          (test.visitor.firstName
            ? test.visitor.firstName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.lastName
            ? test.visitor.lastName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.phone
            ? test.visitor.phone
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.visitor.email
            ? test.visitor.email
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
            : '') ||
          (test.hostName
            ? test.hostName.toLowerCase().includes(searchedVal.toLowerCase())
            : '') ||
          test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
          `${test.visitor.firstName} ${test.visitor.lastName}`
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        )
      }
    })
    setRows(filteredRows)
  }

  const loadSetting = () => {
    dispatch(getListSettingsById(decoded_jwtToken.companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setSettings(data.data)
          setLatestVersion(data.data.latestVersion)
          if (data.data.viewedVersion === data.data.latestVersion)
            setShowPopup(false)
          else setShowPopup(true)
        }
      })
  }

  const handalCardChange = (params) => {
    if (params === 'Checkin') {
      setCurrentFilter({ ...currentFilter, ...checkinFilter })
    }
    if (params === 'checkOut') {
      setCurrentFilter({ ...currentFilter, ...checkOutFilter })
    } else if (params === 'registration') {
      setCurrentFilter({ ...currentFilter, ...regFilter })
    }
    if (params === 'Invitation') {
      setCurrentFilter({ ...currentFilter, ...invFilter })
    }
    if (params === 'confirmed') {
      setCurrentFilter({ ...currentFilter, ...confirmedFilter })
    }
    if (params === 'selfRegistration') {
      setCurrentFilter({ ...currentFilter, ...selfRegFilter })
    }
  }

  const handleDatePickerFilter = (sdate, edate) => {
    setLoading(true)
    setSelectedDates({
      scheduledStartDate: CommonUtil.formatToUtc(sdate),
      scheduledEndDate: CommonUtil.formatToUtc(edate),
    })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 20) + '...'
    } else {
      return text
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(() => [
    {
      field: 'visitor.profile',
      headerName: '',
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },
    {
      field: 'visitor.firstName',
      headerName: t('COMMON014'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null
          ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
          : params.row.visitor.firstName,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.visitor.lastName !== null
            ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
            : params.row.visitor.firstName
            }`}
          placement="bottom-start"
        >
          <span>
            {handleTextOverflow(
              params.row.visitor.lastName !== null
                ? params.row.visitor.firstName +
                ' ' +
                params.row.visitor.lastName
                : params.row.visitor.firstName,
            )}
          </span>
        </Tooltip>
      ),
    },

    {
      field: 'visitor.phone',
      headerName: t('COMMON063'),
      type: 'singleSelect',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.phone,
    },
    {
      field: 'visitor.email',
      headerName: t('PERSON005'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
    },
    {
      field: 'hostName',
      headerName: t('commonsTextFeildHostValue'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.hostName,
    },
    {
      field: 'purpose',
      headerName: t('Purpose'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.visitor.purposeType !== null
          ? params.row.purposeType?.name
          : '',
    },

    {
      field: 'status',
      headerName: t('COMMON001'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <CustomStyleStatus
            row={params.row.status}
            visitMode={params.row.visitMode}
            icon={true}
          />
        )
      },
    },
  ])

  const status = [
    'SCHEDULED',
    'CONFIRMED',
    'APPROVED',
    'CHECKED_IN',
    'CANCELLED',
    'CHECKED_OUT',
    'MISSED',
    'INITIATED_FOR_BLOCKED',
    'BLOCKED',
    'REJECTED',
    'COMPLETED',
    'LOGGED',
  ]

  return (
    <Grid container spacing={3}>
      {loading && (
			<Box sx={{ display: 'flex' }} className="loader-container">
			  <CircularProgress className="loader" />
			</Box>
		  )}
      <Box sx={{ m: 2 }}>
        <Typography variant="h3" style={{ marginLeft: darkMode ? '33px' : '0px', marginRight: darkMode ? '18px' : '12px', color: darkMode ? '#fff' : '#3D4977', marginTop: darkMode ? '3px': '4px' }}>{t('labelNavItemsDashboard')}</Typography>
      </Box>

      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{ paddingRight: '20px', marginTop: '-60px' }}
      >
        <CustomDateFilter
          datePickerFilter={(sdate, edate) =>
            handleDatePickerFilter(sdate, edate)
          }
        />
      </Box>

      <Grid item xs={12} sm={12}>
        <DashboardCards
          getRowData={(params) => handalCardChange(params)}
          data={todaysStatics}
        />
      </Grid>
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
      >
        <Box item p={1}>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item p={1}>
          <FilterForm
            rowsPerPage={rowsPerPage}
            selectedObject={currentFilter.visitMode}
            status={status}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(page, rowsPerPage)}
          />
        </Box>
      </Box>
      <Grid item xs={12}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </Grid>
      {openExpireDialog ? (
        <FreeTrialDialog fullWidth={true} open={openExpireDialog} />
      ) : (
        ''
      )}

      {showPopup && (
        <WhatsNewDialog
          open={showPopup}
          Header={t('CUSTDATABLE34')}
          onClose={() => {
            setShowPopup(!showPopup)
          }}
          latestVersion={latestVersion}
          settings={settings}
        />
      )}
    </Grid>
  )
}
