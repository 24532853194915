import React from 'react'
const OrganizationWhite = () => {
  return (
    <svg className='MuiSvgIcon-root' xmlns="http://www.w3.org/2000/svg" width="33.153" height="27.807" viewBox="0 0 33.153 27.807">
      <g id="Organization-white" transform="translate(-20.988 -164.946)">
        <path id="Ellipse_4" data-name="Ellipse 4" d="M2.661,1A1.661,1.661,0,1,0,4.322,2.661,1.663,1.663,0,0,0,2.661,1m0-1A2.661,2.661,0,1,1,0,2.661,2.661,2.661,0,0,1,2.661,0Z" transform="translate(34.805 165.446)"  />
        <path id="Layer_51" data-name="Layer 51" d="M8.553,6.9A2.691,2.691,0,1,0,5.5,6.9,5.024,5.024,0,0,0,2,11.686a.359.359,0,0,0,.359.359h9.33a.359.359,0,0,0,.359-.359A5.024,5.024,0,0,0,8.553,6.9ZM5.05,4.689A1.974,1.974,0,1,1,7.024,6.663,1.974,1.974,0,0,1,5.05,4.689ZM2.718,11.328a4.306,4.306,0,0,1,8.613,0Z" transform="translate(30.541 163.449)"  />
        <path id="Layer_51_-_Outline" data-name="Layer 51 - Outline" d="M7.024,1.5A3.193,3.193,0,0,1,9.473,6.737a5.5,5.5,0,0,1,3.075,4.949.86.86,0,0,1-.859.859H2.359a.86.86,0,0,1-.859-.859,5.5,5.5,0,0,1,3.075-4.95A3.193,3.193,0,0,1,7.024,1.5Zm0,4.666A1.474,1.474,0,1,0,5.55,4.689,1.475,1.475,0,0,0,7.025,6.163ZM10.8,10.828a3.807,3.807,0,0,0-7.547,0Z" transform="translate(30.541 163.449)"  />
        <path id="Path_5" data-name="Path 5" d="M4.537-.125A4.617,4.617,0,0,1,9.043,4.522v.5H0v-.5A4.644,4.644,0,0,1,4.537-.125Z" transform="translate(44.108 185.541)" fill="none" />
        <path id="Layer_51-2" data-name="Layer 51" d="M8.553,6.9A2.691,2.691,0,1,0,5.5,6.9,5.024,5.024,0,0,0,2,11.686a.359.359,0,0,0,.359.359h9.33a.359.359,0,0,0,.359-.359A5.024,5.024,0,0,0,8.553,6.9ZM5.05,4.689A1.974,1.974,0,1,1,7.024,6.663,1.974,1.974,0,0,1,5.05,4.689ZM2.718,11.328a4.306,4.306,0,0,1,8.613,0Z" transform="translate(41.594 178.521)"  />
        <path id="Layer_51_-_Outline-2" data-name="Layer 51 - Outline" d="M7.024,1.5A3.193,3.193,0,0,1,9.473,6.737a5.5,5.5,0,0,1,3.075,4.949.86.86,0,0,1-.859.859H2.359a.86.86,0,0,1-.859-.859,5.5,5.5,0,0,1,3.075-4.95A3.193,3.193,0,0,1,7.024,1.5Zm0,4.666A1.474,1.474,0,1,0,5.55,4.689,1.475,1.475,0,0,0,7.025,6.163ZM10.8,10.828a3.807,3.807,0,0,0-7.547,0Z" transform="translate(41.594 178.521)"  />
        <circle id="Ellipse_6" data-name="Ellipse 6" cx="2.661" cy="2.661" r="2.661" transform="translate(23.852 180.518)" fill="none" />
        <path id="Path_6" data-name="Path 6" d="M4.537-.125A4.617,4.617,0,0,1,9.043,4.522v.5H0v-.5A4.644,4.644,0,0,1,4.537-.125Z" transform="translate(22.002 185.541)" fill="none" />
        <path id="Layer_51-3" data-name="Layer 51" d="M8.553,6.9A2.691,2.691,0,1,0,5.5,6.9,5.024,5.024,0,0,0,2,11.686a.359.359,0,0,0,.359.359h9.33a.359.359,0,0,0,.359-.359A5.024,5.024,0,0,0,8.553,6.9ZM5.05,4.689A1.974,1.974,0,1,1,7.024,6.663,1.974,1.974,0,0,1,5.05,4.689ZM2.718,11.328a4.306,4.306,0,0,1,8.613,0Z" transform="translate(19.488 178.521)"  />
        <path id="Layer_51_-_Outline-3" data-name="Layer 51 - Outline" d="M7.024,1.5A3.193,3.193,0,0,1,9.473,6.737a5.5,5.5,0,0,1,3.075,4.949.86.86,0,0,1-.859.859H2.359a.86.86,0,0,1-.859-.859,5.5,5.5,0,0,1,3.075-4.95A3.193,3.193,0,0,1,7.024,1.5Zm0,4.666A1.474,1.474,0,1,0,5.55,4.689,1.475,1.475,0,0,0,7.025,6.163ZM10.8,10.828a3.807,3.807,0,0,0-7.547,0Z" transform="translate(19.488 178.521)"  />
        <g id="Group_5" data-name="Group 5" transform="translate(27.124 167.389) rotate(10)" opacity="0.5">
          <path id="Path_3" data-name="Path 3" d="M.369,8.591a.5.5,0,0,1-.457-.3,6.187,6.187,0,0,1-.38-2.912A8.087,8.087,0,0,1,3.271-.406a.5.5,0,0,1,.7.115.5.5,0,0,1-.115.7A7.151,7.151,0,0,0,.527,5.481a5.2,5.2,0,0,0,.3,2.406.5.5,0,0,1-.456.7Z" transform="translate(0 3.004)"  />
          <path id="Polygon_1" data-name="Polygon 1" d="M2.421.354a.5.5,0,0,1,.707,0L4.7,1.921a.5.5,0,0,1-.354.854H1.207a.5.5,0,0,1-.354-.854Z" transform="translate(2.789 0) rotate(49)"  />
        </g>
        <g id="Group_6" data-name="Group 6" transform="translate(53.778 177.446) rotate(127)" opacity="0.5">
          <path id="Path_3-2" data-name="Path 3" d="M.368,8.591a.5.5,0,0,1-.456-.3,6.187,6.187,0,0,1-.38-2.912A8.087,8.087,0,0,1,3.271-.406a.5.5,0,0,1,.7.115.5.5,0,0,1-.115.7A7.175,7.175,0,0,0,.532,5.431,5.3,5.3,0,0,0,.826,7.888a.5.5,0,0,1-.458.7Z" transform="translate(0 3.004)"  />
          <path id="Polygon_1-2" data-name="Polygon 1" d="M2.421.354a.5.5,0,0,1,.707,0L4.7,1.921a.5.5,0,0,1-.354.854H1.207a.5.5,0,0,1-.354-.854Z" transform="translate(2.789 0) rotate(49)"  />
        </g>
        <g id="Group_7" data-name="Group 7" transform="translate(32.885 193.529) rotate(-119)" opacity="0.5">
          <path id="Path_3-3" data-name="Path 3" d="M.369,8.591a.5.5,0,0,1-.457-.3,6.187,6.187,0,0,1-.38-2.912A8.087,8.087,0,0,1,3.271-.406a.5.5,0,0,1,.7.115.5.5,0,0,1-.115.7A7.151,7.151,0,0,0,.527,5.481a5.2,5.2,0,0,0,.3,2.406.5.5,0,0,1-.456.7Z" transform="translate(0 3.004)"  />
          <path id="Polygon_1-3" data-name="Polygon 1" d="M2.421.354a.5.5,0,0,1,.707,0L4.7,1.921a.5.5,0,0,1-.354.854H1.207a.5.5,0,0,1-.354-.854Z" transform="translate(2.789 0) rotate(49)"  />
        </g>
      </g>
    </svg>
  )
}

export default OrganizationWhite
