import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CalenderIcon = () => {
  return (
    <SvgIcon>
      {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
        <svg id="Events" xmlns="http://www.w3.org/2000/svg" width="38.019" height="38.718" viewBox="0 0 48.019 48.718">
          <path id="Path_11" data-name="Path 11" d="M28.054,13.4H26.7a.7.7,0,0,1,0-1.4h1.367a.7.7,0,0,1,0,1.4Z" transform="translate(-13.382 -8.495)" fill="#3d4977" />
          <path id="Path_11_-_Outline" data-name="Path 11 - Outline" d="M27.949,13.413H26.582a.832.832,0,1,1,0-1.663h1.367a.832.832,0,1,1,0,1.663Zm-1.367-1.4a.57.57,0,0,0,0,1.141h1.367a.57.57,0,1,0,0-1.141Z" transform="translate(-13.262 -8.376)" fill="#3d4977" />
          <path id="Path_12" data-name="Path 12" d="M36.041,20.412H8V15.505A3.505,3.505,0,0,1,11.505,12a.7.7,0,1,1,0,1.4,2.1,2.1,0,0,0-2.1,2.1V19.01H34.639V15.505a2.1,2.1,0,0,0-2.1-2.1.7.7,0,0,1,0-1.4,3.505,3.505,0,0,1,3.505,3.505Z" transform="translate(-8 -8.495)" fill="#3d4977" />
          <rect id="Rectangle_6069" data-name="Rectangle 6069" width="4.706" height="4.183" transform="translate(3.919 14.018)" fill="#f2824c" />
          <rect id="Rectangle_6070" data-name="Rectangle 6070" width="4.706" height="4.183" transform="translate(19.606 14.018)" fill="#f2824c" />
          <rect id="Rectangle_6071" data-name="Rectangle 6071" width="4.706" height="4.183" transform="translate(3.919 20.815)" fill="#f2824c" />
          <rect id="Rectangle_6067" data-name="Rectangle 6067" width="4.183" height="6.798" transform="translate(6.534 0.945)" fill="#f2824c" />
          <rect id="Rectangle_6068" data-name="Rectangle 6068" width="4.183" height="6.798" transform="translate(17.515 0.945)" fill="#f2824c" />
          <path id="Path_13" data-name="Path 13" d="M23.422,35.742H11.505A3.505,3.505,0,0,1,8,32.237V17.515H36.041v7.01a.7.7,0,1,1-1.4,0V18.917H9.4V32.237a2.1,2.1,0,0,0,2.1,2.1H23.422a.7.7,0,1,1,0,1.4Zm-6.309-20.33h-1.4a2.1,2.1,0,0,1-2.1-2.1V9.1a2.1,2.1,0,0,1,2.1-2.1h1.4a2.1,2.1,0,0,1,2.1,2.1v4.206A2.1,2.1,0,0,1,17.113,15.412Zm-1.4-7.01a.7.7,0,0,0-.7.7v4.206a.7.7,0,0,0,.7.7h1.4a.7.7,0,0,0,.7-.7V9.1a.7.7,0,0,0-.7-.7Zm12.618,7.01h-1.4a2.1,2.1,0,0,1-2.1-2.1V9.1a2.1,2.1,0,0,1,2.1-2.1h1.4a2.1,2.1,0,0,1,2.1,2.1v4.206A2.1,2.1,0,0,1,28.33,15.412Zm-1.4-7.01a.7.7,0,0,0-.7.7v4.206a.7.7,0,0,0,.7.7h1.4a.7.7,0,0,0,.7-.7V9.1a.7.7,0,0,0-.7-.7Z" transform="translate(-8 -7)" fill="#3d4977" />
          <path id="Path_14" data-name="Path 14" d="M17.206,31.608H14.4a1.4,1.4,0,0,1-1.4-1.4V27.4A1.4,1.4,0,0,1,14.4,26h2.8a1.4,1.4,0,0,1,1.4,1.4v2.8A1.4,1.4,0,0,1,17.206,31.608ZM14.4,27.4v2.8h2.8V27.4Zm10.515,4.206h-2.8a1.4,1.4,0,0,1-1.4-1.4V27.4a1.4,1.4,0,0,1,1.4-1.4h2.8a1.4,1.4,0,0,1,1.4,1.4v2.8A1.4,1.4,0,0,1,24.917,31.608Zm-2.8-4.206v2.8h2.8V27.4ZM17.206,38.618H14.4a1.4,1.4,0,0,1-1.4-1.4v-2.8a1.4,1.4,0,0,1,1.4-1.4h2.8a1.4,1.4,0,0,1,1.4,1.4v2.8A1.4,1.4,0,0,1,17.206,38.618Zm-2.8-4.206v2.8h2.8v-2.8Zm10.515,4.206h-2.8a1.4,1.4,0,0,1-1.4-1.4v-2.8a1.4,1.4,0,0,1,1.4-1.4h2.8a1.4,1.4,0,0,1,1.4,1.4v2.8A1.4,1.4,0,0,1,24.917,38.618Zm-2.8-4.206v2.8h2.8v-2.8Zm10.515-2.8h-2.8a1.4,1.4,0,0,1-1.4-1.4V27.4a1.4,1.4,0,0,1,1.4-1.4h2.8a1.4,1.4,0,0,1,1.4,1.4v2.8A1.4,1.4,0,0,1,32.628,31.608Zm-2.8-4.206v2.8h2.8V27.4Z" transform="translate(-9.495 -12.681)" fill="#3d4977" />
          <rect id="Rectangle_259" data-name="Rectangle 259" width="5.876" height="5.876" rx="2.938" transform="translate(18.922 20.203)" fill="#f2824c" />
          <path id="Rectangle_259_-_Outline" data-name="Rectangle 259 - Outline" d="M1.569,1.307a.262.262,0,0,0-.261.261V4.307a.262.262,0,0,0,.261.261H4.307a.262.262,0,0,0,.261-.261V1.569a.262.262,0,0,0-.261-.261H1.569M1.569,0H4.307A1.569,1.569,0,0,1,5.876,1.569V4.307A1.569,1.569,0,0,1,4.307,5.876H1.569A1.569,1.569,0,0,1,0,4.307V1.569A1.569,1.569,0,0,1,1.569,0Z" transform="translate(18.922 20.203)" fill="#3d4977" />
          <path id="Path_3801" data-name="Path 3801" d="M359.569,40.388a.719.719,0,0,1-.719-.719V27.723a.719.719,0,1,1,1.438,0V39.669A.719.719,0,0,1,359.569,40.388Z" transform="translate(-332.231 -11.632)" fill="#3d4977" />
          <path id="Path_3802" data-name="Path 3802" d="M359.136,40.641h-13.02a.719.719,0,0,1,0-1.438h13.02a.719.719,0,0,1,0,1.438Z" transform="translate(-331.921 -11.885)" fill="#3d4977" />
        </svg>
      ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width="38.019" height="38.718" viewBox="0 0 48.019 48.718">
        <g id="Events" transform="translate(-8 -7)">
          <path id="Path_11" data-name="Path 11" d="M28.051,13.4H26.7a.7.7,0,1,1,0-1.4h1.365a.7.7,0,0,1,0,1.4Z" transform="translate(-5.399 -1.5)" fill="#3d4977" />
          <path id="Path_11_-_Outline" data-name="Path 11 - Outline" d="M27.946,13.411H26.581a.831.831,0,0,1,0-1.661h1.365a.831.831,0,1,1,0,1.661Zm-1.365-1.4a.57.57,0,0,0,0,1.139h1.365a.57.57,0,1,0,0-1.139Z" transform="translate(-5.279 -1.38)" fill="#3d4977" />
          <path id="Path_12" data-name="Path 12" d="M36,20.4H8V15.5A3.5,3.5,0,0,1,11.5,12a.7.7,0,0,1,0,1.4,2.1,2.1,0,0,0-2.1,2.1V19H34.6V15.5a2.1,2.1,0,0,0-2.1-2.1.7.7,0,1,1,0-1.4A3.5,3.5,0,0,1,36,15.5Z" transform="translate(0 -1.5)" fill="#3d4977" />
          <rect id="Rectangle_6069" data-name="Rectangle 6069" width="4.7" height="4.177" transform="translate(11.914 20.999)" fill="#36c96d" />
          <rect id="Rectangle_6070" data-name="Rectangle 6070" width="4.7" height="4.177" transform="translate(27.58 20.999)" fill="#36c96d" />
          <rect id="Rectangle_6071" data-name="Rectangle 6071" width="4.7" height="4.177" transform="translate(11.914 27.787)" fill="#36c96d" />
          <rect id="Rectangle_6067" data-name="Rectangle 6067" width="4.177" height="6.788" transform="translate(14.525 7.944)" fill="#36c96d" />
          <rect id="Rectangle_6068" data-name="Rectangle 6068" width="4.177" height="6.788" transform="translate(25.491 7.944)" fill="#36c96d" />
          <path id="Path_13" data-name="Path 13" d="M23.4,35.7H11.5A3.5,3.5,0,0,1,8,32.2V17.5H36v7a.7.7,0,1,1-1.4,0V18.9H9.4V32.2a2.1,2.1,0,0,0,2.1,2.1H23.4a.7.7,0,1,1,0,1.4ZM17.1,15.4H15.7a2.1,2.1,0,0,1-2.1-2.1V9.1A2.1,2.1,0,0,1,15.7,7h1.4a2.1,2.1,0,0,1,2.1,2.1v4.2A2.1,2.1,0,0,1,17.1,15.4Zm-1.4-7a.7.7,0,0,0-.7.7v4.2a.7.7,0,0,0,.7.7h1.4a.7.7,0,0,0,.7-.7V9.1a.7.7,0,0,0-.7-.7Zm12.6,7H26.9a2.1,2.1,0,0,1-2.1-2.1V9.1A2.1,2.1,0,0,1,26.9,7h1.4a2.1,2.1,0,0,1,2.1,2.1v4.2A2.1,2.1,0,0,1,28.3,15.4Zm-1.4-7a.7.7,0,0,0-.7.7v4.2a.7.7,0,0,0,.7.7h1.4a.7.7,0,0,0,.7-.7V9.1a.7.7,0,0,0-.7-.7Z" transform="translate(0 0)" fill="#3d4977" />
          <path id="Path_14" data-name="Path 14" d="M17.2,31.6H14.4A1.4,1.4,0,0,1,13,30.2V27.4A1.4,1.4,0,0,1,14.4,26h2.8a1.4,1.4,0,0,1,1.4,1.4v2.8A1.4,1.4,0,0,1,17.2,31.6Zm-2.8-4.2v2.8h2.8V27.4Zm10.5,4.2H22.1a1.4,1.4,0,0,1-1.4-1.4V27.4A1.4,1.4,0,0,1,22.1,26h2.8a1.4,1.4,0,0,1,1.4,1.4v2.8A1.4,1.4,0,0,1,24.9,31.6Zm-2.8-4.2v2.8h2.8V27.4ZM17.2,38.6H14.4A1.4,1.4,0,0,1,13,37.2V34.4A1.4,1.4,0,0,1,14.4,33h2.8a1.4,1.4,0,0,1,1.4,1.4v2.8A1.4,1.4,0,0,1,17.2,38.6Zm-2.8-4.2v2.8h2.8V34.4Zm10.5,4.2H22.1a1.4,1.4,0,0,1-1.4-1.4V34.4A1.4,1.4,0,0,1,22.1,33h2.8a1.4,1.4,0,0,1,1.4,1.4v2.8A1.4,1.4,0,0,1,24.9,38.6Zm-2.8-4.2v2.8h2.8V34.4Zm10.5-2.8H29.8a1.4,1.4,0,0,1-1.4-1.4V27.4A1.4,1.4,0,0,1,29.8,26h2.8A1.4,1.4,0,0,1,34,27.4v2.8A1.4,1.4,0,0,1,32.6,31.6Zm-2.8-4.2v2.8h2.8V27.4Z" transform="translate(-1.5 -5.699)" fill="#3d4977" />
          <rect id="Rectangle_259" data-name="Rectangle 259" width="5.868" height="5.868" rx="2.934" transform="translate(26.896 27.176)" fill="#36c96d" />
          <path id="Rectangle_259_-_Outline" data-name="Rectangle 259 - Outline" d="M1.567,1.305a.261.261,0,0,0-.261.261V4.3a.261.261,0,0,0,.261.261H4.3A.261.261,0,0,0,4.562,4.3V1.567A.261.261,0,0,0,4.3,1.305H1.567M1.567,0H4.3A1.567,1.567,0,0,1,5.868,1.567V4.3A1.567,1.567,0,0,1,4.3,5.868H1.567A1.567,1.567,0,0,1,0,4.3V1.567A1.567,1.567,0,0,1,1.567,0Z" transform="translate(26.896 27.176)" fill="#3d4977" />
          <path id="Path_3801" data-name="Path 3801" d="M359.568,40.37a.718.718,0,0,1-.718-.718V27.722a.718.718,0,0,1,1.436,0v11.93A.718.718,0,0,1,359.568,40.37Z" transform="translate(-324.267 -4.652)" fill="#3d4977" />
          <path id="Path_3802" data-name="Path 3802" d="M359.118,40.639h-13a.718.718,0,1,1,0-1.436h13a.718.718,0,0,1,0,1.436Z" transform="translate(-323.94 -4.922)" fill="#3d4977" />
        </g>
      </svg>
      )}
    </SvgIcon>
  )
}
export default CalenderIcon;

