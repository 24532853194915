import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';

const LandscapeIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();

  const handlePortrait = () => {
    setIsShown(true)
  };

  return (
    <>
      <Grid 
      // onClick={handlePortrait}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      >
        {isShown ? (
           <svg xmlns="http://www.w3.org/2000/svg" width="28" height="16" viewBox="0 0 28 16">
           <path id="Path_7248" data-name="Path 7248" d="M22,10.94a1.31,1.31,0,0,0-.06-.27v-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19h-.1A1.1,1.1,0,0,0,15.06,4H-3A3,3,0,0,0-6,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V10.94ZM16,7.41,18.59,10H17a1,1,0,0,1-1-1ZM20,17a1,1,0,0,1-1,1H-3a1,1,0,0,1-1-1V7A1,1,0,0,1-3,6H14V9a3,3,0,0,0,3,3h3Z" transform="translate(6 -4)" fill="#263238" />
         </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="16" viewBox="0 0 28 16">
          <path id="Path_7248" data-name="Path 7248" d="M22,10.94a1.31,1.31,0,0,0-.06-.27v-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19h-.1A1.1,1.1,0,0,0,15.06,4H-3A3,3,0,0,0-6,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V10.94ZM16,7.41,18.59,10H17a1,1,0,0,1-1-1ZM20,17a1,1,0,0,1-1,1H-3a1,1,0,0,1-1-1V7A1,1,0,0,1-3,6H14V9a3,3,0,0,0,3,3h3Z" transform="translate(6 -4)" fill="#0f5a9c" />
        </svg>
        )}
      </Grid>
    </>
  );
};
export default LandscapeIcon;
