import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import sucess_img1 from '../../assets/visitReject.svg'
import vis_sucess_img1 from '../../assets/USA_Images/login_images/visitReject.svg'

import { useDispatch } from 'react-redux'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  rejectVisitor,
  validateInvitation,
} from '../../slices/Invitations/InvitationsSlice'
import { useHistory } from 'react-router-dom'
import CommonUtil from '../../Util/CommonUtils'
import { rejectVisitorInvite } from '../../slices/Approvals/BlockRequestsRejectSlice'

const useStyles = makeStyles((theme) => ({
  submit: {
    width: '350px',
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  },
  success: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  footer: {
    bottom: '0px',
    padding: '40px',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
  },
}))

function SelfCancel() {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid className={classes.success}>
      <Grid container alignItems="center">
        <Grid item className="logo-center">
          {/* <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  vis_sucess_img1 : sucess_img1} alt="logo" style={{ width: "300px" }} /> */}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '10px' }}>
        <Typography
          variant="h3"
          align="center"
          style={{ color: '#464646', fontWeight: '500' }}
        >
          Visit Cancelled Successfully
        </Typography>
      </Grid>

      <Grid className={classes.footer}>
        <Typography style={{ fontSize: '10px' }} color="textSecondary">
          {t('COPY_RIGHT_2024')}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default SelfCancel
