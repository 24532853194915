import { Box, Button, Tooltip, Typography } from '@mui/material';
import React from 'react'
import { ReactComponent as ExportIcon } from '../../assets/images/logs/expoer-bg.svg'
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";

function ExportLogs(props) {

    const { handleClick } = props;

    return (
        <>
            <Tooltip title={<Typography fontSize={12} fontWeight={500}>Export</Typography>}>
                <ExportIcon onClick={handleClick}  style={{ cursor: 'pointer'}}>
                    <ExportIcon />
                </ExportIcon>
            </Tooltip>
        </>
    );
}
export default ExportLogs