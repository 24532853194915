import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import healthQuestionarieService from '../../services/HealthQuestionaries/HealthQuestionarieService';

const initialState = [];

export const getAllHealthQuestionarie = createAsyncThunk('healthQuestionarie/', async (payload) => {
    const res = await healthQuestionarieService.getAllHealthQuestionarie(payload);
    return res.data;
});

export const addHealthQuestionarie = createAsyncThunk('healthQuestionarie/create', async (payload) => {
    const res = await healthQuestionarieService.addHealthQuestionarie(payload);
    return res.data;
});

export const updateHealthQuestionarie = createAsyncThunk('healthQuestionarie/update', async (payload) => {
    const res = await healthQuestionarieService.updateHealthQuestionarie(payload);
    return res.data;
});

export const deleteHealthQuestionarie = createAsyncThunk('healthQuestionarie/delete', async (payload) => {
    const res = await healthQuestionarieService.deleteHealthQuestionarie(payload);
    return res.data;
});

const healthQuestionarieSlice = createSlice({
    name: 'healthQuestionarie',
    initialState,
    extraReducers: {
        [getAllHealthQuestionarie.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = healthQuestionarieSlice;
export default reducer;