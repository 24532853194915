import api from '../../config/http-common'

const getUserInfoPro = (userId) => {
  return api.securedAxios().get('/api/v3/users/' + userId)
}

const getUserByClientCredentials = (payload) => {
  return api
    .securedAxios()
    .get('/api/v3/getUser/' + payload.userId + '/' + payload.companyId)
}

const updateUserInfoPro = (user) => {
  return api.securedAxios().put('/api/v3/users/' + user.id, user)
}

const UserService = {
  getUserInfoPro,
  updateUserInfoPro,
  getUserByClientCredentials,
}

export default UserService
