import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddDeviceIcon } from "../../../assets/images/checkInAndOutDevice/AddDeviceIcon.svg";
import CustomTextfield from "../../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as AddDeviceIconUSA } from "../../../assets/USA_Images/adddeviceusa.svg";


export default function DeviceDialog(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    handleClose();
    props.handleDevice();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleOpen}>
        {/* <AddDeviceIcon /> */}
       { process.env.REACT_APP_ENVIRONMENT === 'USA' ?  <AddDeviceIconUSA />  : <AddDeviceIcon />}
      </div>
      <DialogBox
        Header="Device"
        acceptText="Submit"
        cancelText="Cancel"
        fullWidth={true}
        onClose={handleClose}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
           container
           justifyContent="center"
           alignItems={"center"}
        >
           <Grid item xs={12} sm={9} style={{ marginTop: "20px" }}>
            <CustomTextfield
              label="Device Name *"
              name="deviceName"
              disabled
              value={props.data.deviceName}
            />
          </Grid>

          <Grid item xs={12} sm={9} style={{ marginTop: "20px" }}>
            <CustomTextfield
              label="Serial Number *"
              name="sn"
              disabled
              value={props.data.sn}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </div>
  );
}
