import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (payload) => {
    let query = "?isPagable=true&pageNumber=" + payload.pageNumber + "&pageSize=" + payload.pageSize;

    if(!CommonUtil.isEmptyString(payload.deviceName)){
        query = query + "&deviceName=" + payload.deviceName ;
    }

    if(!CommonUtil.isEmptyString(payload.deviceNumber)){
        query = query + "&deviceNumber=" + payload.deviceNumber;
    }

   return query;
}



const addAndroidDevice = (payload)=>{
    return api.securedAxios().post("/web/androidDevice",payload);
}

const getListOfAndroidDevice =(payload)=>{
    return api.securedAxios().get("/web/androidDevices/list" + buildQuery(payload));
}

const upadteAndroidDeviceStatus = (payload)=>{
    return api.securedAxios().put("/web/androidDevices/" + payload.id, payload);
}

const  apkUpdate = (payload)=>{
    return api.securedAxios().put("/web/androidDevice/update/apk/" + payload.id);
}

const androidDeviceService ={
    addAndroidDevice,
    getListOfAndroidDevice,
    upadteAndroidDeviceStatus,
    apkUpdate,
}

export default androidDeviceService;