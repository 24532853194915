import React, {useContext} from "react";
import { Tooltip, Box, Typography, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { REACT_URL_PURPOSETYPE_USA } from "../actions/EndPoints";
import { ThemeContext } from '../theme/ThemeContext';

const HeaderToolbar = (props) => {
    let history = useHistory();
    const { darkMode } = useContext(ThemeContext);
    const handleClick = () =>{
        history.push({
            pathname:REACT_URL_PURPOSETYPE_USA
        })
    }
    return (
        <>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: darkMode ? '31px' : '',
                    
                }}
            >
                <Grid style={{display:'flex', cursor: 'pointer'}}>
                    {props.back_button ?
                        (
                        <Grid item onClick={handleClick}>
                            <Grid style={{ cursor: 'pointer', marginTop:'5px', marginRight:'5px' }} >
                            <ArrowBackIosRoundedIcon sx={{ color: darkMode ? '#fff' : '#9AA1B9'}} fontSize="small"/>
                            </Grid>
                        </Grid>)
                        : 
                        (
                        <></>
                        )
                    }
                     <Box style={{margin:'16px, 0'}}><Typography variant="h3" style={{color: darkMode ? '#fff' : ''}}>{props.title}</Typography></Box>
                </Grid>
                {props.src ?
                    <Box
                        style={{
                            textAlign: "center",
                            padding: '10px',
                            backgroundColor: process.env.REACT_APP_BG_ICON,
                            borderRadius: "50%",
                            cursor:'pointer',
                            marginRight: darkMode ? '18px' : ''
                        }}
                    >
                        <Tooltip title={props.tooltipTitle}>
                            <img
                                alt="..."
                                src={props.src}
                                onClick={props.onClick}
                                style={{width:'22px'}}
                            />
                        </Tooltip>
                    </Box>
                    : <></>}
            </Box>
        </>
    );
};
export default HeaderToolbar;
