import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import './Device.css';
import {AccessDevice}  from './AccessDevice'
import { VisitorDevice } from './VisitorDevice';
import { ThemeContext } from '../../theme/ThemeContext';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return <div {...other}>{value === index && <Box pt={2}>{children}</Box>}</div>;
}

export default function DeviceTabs() {
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <div>
            <Grid>
                <AppBar position='static' color='transparent' >
                    <Tabs
                        TabIndicatorProps={{ style: { background: process.env.REACT_APP_BG_ICON } }}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        style={{ position: 'absolute', top: '150px', display: 'flex', marginLeft: '12px', backgroundColor: darkMode ? '#0F273B' : '' , color: darkMode ? '#fff' : '' }}
                    >
                        {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                            <Tab label={t('ACCDEV008')} style={{ borderRight: 'solid #00000029', textTransform: "none" }} />
                            :
                            <Tab label={t('ACCDEV008')} style={{ borderRight: 'solid #00000029', textTransform: "none" }} />
                        }
                        {process.env.REACT_APP_ENVIRONMENT === 'USA' ? <Tab label={t('ACCDEV009')} style={{ textTransform: "none" }} /> 
                        :<Tab label={t('ACCDEV0089')} style={{ textTransform: "none" }} />}
                    </Tabs>
                </AppBar>
                {value === 0 && (
                    <TabPanel value={value} index={value} >
                        <AccessDevice />
                    </TabPanel>
                )}
                {value === 1 && (
                    <TabPanel value={value} index={value}>
                        <VisitorDevice />
                    </TabPanel>
                )}
            </Grid>
        </div >
    );
}