import { Grid, Tooltip, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import HeaderToolbar from '../../components/HeaderToolbar'
import { getUserDetails } from '../../slices/Dealer/DealerSlice'
import copyIcon from '../../assets/USA_Images/copyIcon.svg'
import { useTranslation } from "react-i18next";

function KeyConfigView(props) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const dispatch = useDispatch()
  const handleTextOverflow = (text) => {
    if (text && text.length > 35) {
      return text.substring(0, 25) + '...'
    } else {
      return text
    }
  }
  useEffect(() => {
    loadUserDetails()
  }, [])
  // const emailValue = props.location.state.email
  const loadUserDetails = () => {
    dispatch(getUserDetails('rgf'))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0033') {
          setFirstName(data.data.firstName)
          setLastName(data.data.lastName)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  
  const [copied, setCopied] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => setCopied(true))
      .catch((error) => console.error('Error copying text: ', error));
  };

  const generateActivationKeyText = (platform) => {
    if (platform === 'ios') {
      return `
        pdf Detector activationKey: ${props.location.state.pdfDetectorActivationKey}
        Mrz Detector activationKey: ${props.location.state.mrzDetectorActivationKey}
        pdf Parser activationKey: ${props.location.state.pdfParserActivationKey}
      `;
    } else if (platform === 'android') {
      return `
        private const val PARSER_KEY: ${props.location.state.privateConstValParserKey}
        private const val SCANNER_KEY: ${props.location.state.privateConstValScannerKey}
      `;
    }
    return '';
  };
  
  return (
    <>
      <HeaderToolbar back_button={true} title={t('KEYCONFIGURATIONVIEW')} />

      <Grid
        container
        lg={12}
        md={12}
        item
        sx={{ mt: '20px', bgcolor: 'white' }}
      >
        <Grid item padding={2}>
          <Avatar
            alt="Remy Sharp"
            src={
              ''
            }
            style={{ height: '180px', width: '180px', marginLeft: '40px' }}
          />
        </Grid>
        <Grid
          item
          xs
          zeroMinWidth
          style={{
            marginLeft: '20px',
            marginTop: '20px',
            borderRight: '3px solid #E1E9F5',
          }}
        >
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('DEALERFIRSTNAME')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip title={props.location.state.firstName} placement="bottom-start">
                <Typography noWrap>{props.location.state.firstName}</Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('DEALERLASTNAME')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip title={props.location.state.lastName} placement="bottom-start">
                <Typography noWrap>{handleTextOverflow(props.location.state.lastName)}</Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('DEALEREMAIL')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={props.location.state.customerEmail}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.customerEmail)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('VIEWPAGEMOBLIENUMBER')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={props.location.state.customerMobile}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.customerMobile)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('DEALERCOMPANYNAME')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={props.location.state.customerName}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.customerName)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs
          zeroMinWidth
          style={{
            marginLeft: '20px',
            marginTop: '20px',
            borderRight: '3px solid #E1E9F5',
          }}
        >
          <Grid container style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} columnSpacing={1}>
              <Typography style={{ opacity: '50%' }}>{t('CUSTDATABLE04')}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={props.location.state.firstName + " " + props.location.state.lastName}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.firstName + " " + props.location.state.lastName)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid
            container
            wrap="nowrap"
            style={{ marginTop: '10px' }}
            columnSpacing={1}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('LABELNAVITEMSSUBSCRIPTION')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={props.location.state.customerSubscriptionName}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.customerSubscriptionName)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            wrap="nowrap"
            style={{ marginTop: '10px' }}
            columnSpacing={1}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('CUSTDATABLE20')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={''}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow('')}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            wrap="nowrap"
            style={{ marginTop: '10px' }}
            columnSpacing={1}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('CUSTDATABLE23')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ alignContent: 'end', alignItems: 'end' }}
            >
              <Tooltip
                title={''}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow('')}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            wrap="nowrap"
            style={{ marginTop: '10px' }}
            columnSpacing={1}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('CUSTDATABLE17')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={''}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow('')}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs
          zeroMinWidth
          style={{ marginLeft: '20px', marginTop: '20px' }}
        >
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('CUSTDATABLE24')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={''}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow('')}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
              {t('CUSTDATABLE35')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={''}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow('')}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('CUSTDATABLE22')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={''}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow('')}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('CUSTDATABLE25')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={''}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow('')}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('CUSTDATABLE09')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={''}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow('')}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        item
        sx={{ mt: '20px', bgcolor: 'white', height:'160px' }}
      >
        <Grid item padding={2} sx={{marginLeft:"10px"}}>
         <Typography>
         <b>{t('CUSTDATABLE31')}:</b> &nbsp;
          <Tooltip title={t('COPY_TOOL_TIP')}>
                  <img
                    style={{
                      height: '20px',
                    }}
                    alt="Copy"
                    src={copyIcon}
                    onClick={() => handleCopy(generateActivationKeyText('ios'))}
                  />
                </Tooltip>
         </Typography>
         <Typography sx={{ marginTop: '14px' }}>
         {t('KEYCONFIGURATIONPDFDETECTOR')} : {props.location.state.pdfDetectorActivationKey}
         </Typography>
         <Typography sx={{ marginTop: '14px' }}>
        {t('KEYCONFIGURATIONMRZDETECTOR')}  : {props.location.state.mrzDetectorActivationKey}
         </Typography>
         <Typography sx={{ marginTop: '14px' }}>
        {t('KEYCONFIGURATIONPDFPARSER')}  : {props.location.state.pdfParserActivationKey}
         </Typography>
        </Grid>            
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        item
        sx={{ mt: '20px', bgcolor: 'white', height:'150px' }}
      >
        <Grid item padding={2} sx={{marginLeft:"10px"}}>
         <Typography>
         <b>{t('CUSTDATABLE32')}:</b> &nbsp;
          <Tooltip title={t('COPY_TOOL_TIP')}>
                  <img
                    style={{
                      height: '20px',
                    }}
                    alt="Copy"
                    src={copyIcon}
                    onClick={() => handleCopy(generateActivationKeyText('android'))}
                  />
                </Tooltip>
         </Typography>
         <Typography sx={{ marginTop: '14px' }}>
         {t('KEYCONFIGURATIONANDROIDKEYPRIVATEPARSER')} : {props.location.state.privateConstValParserKey}
         </Typography>
         <Typography sx={{ marginTop: '14px' }}>
        {t('KEYCONFIGURATIONANDROIDKEYPRIVATESCANNER')} : {props.location.state.privateConstValScannerKey}
         </Typography>
        </Grid>            
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        item
        sx={{ mt: '20px', bgcolor: 'white', height:'100px' }}
      >
        <Grid item padding={2} sx={{marginLeft:"10px"}}>
         <Typography>
         <b>{t('KEYCONFIGURATIONNUMBERNOT')}:</b>
         </Typography>
         <Typography sx={{ marginTop: '14px' }}>
          {props.location.state.numberOfDevices}
         </Typography>
        </Grid>            
      </Grid>

      <Grid
        container
        lg={12}
        md={12}
        item
        sx={{ mt: '20px', bgcolor: 'white', height:'350px' }}
      >
        {props.location.state.deviceId!==undefined && props.location.state.deviceId!==null? 
        <Grid item padding={2} sx={{ marginLeft: "10px", overflowY: 'auto', maxHeight: '200px' }}>
        <Typography>
         <b>{t('VIEWPAGEKEYCONFIGURATIONDEVICEHISTORY')}:</b>
        </Typography>
       <Typography sx={{ marginTop: '14px' }}>
        {props.location.state.deviceId.map((device, index) => (
        <div key={index}>
          <Typography>{device}</Typography>
        </div>
      ))}
    </Typography>
  </Grid>:
  <Grid item padding={2} sx={{ marginLeft: "10px", overflowY: 'auto', maxHeight: '200px' }}>
  <Typography>
   <b>Device History:</b>
  </Typography>
 {/* <Typography sx={{ marginTop: '14px' }}>
  {props.location.state.deviceId.map((device, index) => (
  <div key={index}>
    <Typography>{device}</Typography>
  </div>
))}
</Typography> */}
</Grid>
  }
      </Grid>
      
    </>
  )
}

export default KeyConfigView
