import React, { useEffect, useState, useContext } from "react";
import DataTable from "../../components/DataTable/DataTable";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HeaderToolbar from "../../components/HeaderToolbar";
import checklistIcon from "../../assets/checklist.svg";
import Search from "../../components/SearchTab/Search";
import { Box, Link } from "@mui/material";
import AddChecklistTemplate from "./AddChecklistTemplate";
import { getAllChecklisttemplate } from "../../slices/ChecklistTemplatesUSA/ChecklistTemplateSlice";
import { REACT_URL_HEALTHQUESTIONARIES } from "../../actions/EndPoints";
import ChecklistIcon from "../Checklist/ChecklistIcon";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditChecklistIcon from "../Checklist/EditChecklistIcon";
import ChecklistTemplateUSAView from "./ChecklistTemplateUSAView";
import FilterChecklistTemplate from "./FilterChecklistTemplate";
import { ThemeContext } from '../../theme/ThemeContext';

export default function ChecklistTemplateUSA(props) {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const { darkMode } = useContext(ThemeContext);

  const [selectedObject, setSelectedObject] = useState({
    id: "",
    name: "",
    companyId: "",
    description: "",
  });

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    id: "",
    name: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    id: "",
    name: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const loadData = (filter, paging) => {
    dispatch(getAllChecklisttemplate(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0000" && data.data)) {
          setTotalRecords(data.data.totalCount);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
          setRows(data.data.visitors);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      name: data.name,
    });
  };


  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog);
    setSelectedObject(params);
  };

  const handleChecklistItem = (params) => {
    history.push({
      pathname: REACT_URL_HEALTHQUESTIONARIES,
      state: { data: params.row },
    });
  };


  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (test.name ? test.name.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || test.description.toLowerCase().includes(searchedVal.toLowerCase())

    });
    setRows(filteredRows);
  };


  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "name",
      headerName: t("COMMON014"),
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.name,
    },

    {
      field: "description",
      headerName: t("ATYPE009"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
    },

    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [

        <GridActionsCellItem
          icon={<ChecklistTemplateUSAView
            title="Checklist Template Details"
            rowData={params.row} />}
          label={t("VIEW")}
        />,

        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label={t("COMMON005")}
          onClick={() => handleEditVisit(params.row)}
        />,

        <GridActionsCellItem
          icon={<ChecklistIcon />}
          label={t("COMMON146")}
          onClick={() => handleChecklistItem(params)}
        />,
      ],
    },



  ]);

  return (
    <>

      <HeaderToolbar
        src={checklistIcon}
        onClick={handleOpen}
        title={t("CHECKLIST001")}
       
        tooltipTitle={t("CHECKLIST004")}
      />



      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        sx={{marginLeft: darkMode ? '-16px' : ''}}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterChecklistTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>



      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <AddChecklistTemplate
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            handleClose(loadData(filter, paging));
          }}
        />
      )}

      {openEditDialog && (
        <AddChecklistTemplate
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false);
            loadData(filter, paging);
            setSelectedObject({
              id: "",
              name: "",
              description: "",
            });
          }}
        />
      )}



    </>
  )
}


