import { Box, Grid, Tooltip, Typography } from '@material-ui/core'
import { Stack } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { REACT_CREATE_DISCOUNT } from '../../actions/EndPoints'
import addDiscount from '../../assets/USA_Images/addDiscount.svg'
import DataTable from '../../components/DataTable/DataTable'
import Search from '../../components/SearchTab/Search'
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { getDiscountList } from '../../slices/Discount/DiscountSlice'
import CommonUtil from '../../Util/CommonUtils'

export default function Discount() {
  const { t } = useTranslation()
  const [clear, setClear] = React.useState(false)
  const [rows, setRows] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(0)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [openEditDiscount, setOpenEditDiscount] = useState(false)
  const [editRowData, setEditRowData] = useState({})
  const [searchRecord, setSearchRecord] = useState()
  const companyId = localStorage.getItem('companyId')
  const [date,setDate] = useState('')

  let history = useHistory()

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  useEffect(() => {
    loadDiscountData(paging)
    loadSetting()
  }, [paging])

  const loadDiscountData = (paging) => {
    dispatch(getDiscountList(Object.assign(paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4000' && !CommonUtil.isEmpty(data.data)) {
          setRows(data.data.discounts)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
  }

  const loadSetting = () => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          if (data.data.adminAndDealerDateFormat === 'DD-MM-YYYY') {
            setDate('dd-MM-yyyy')
          }
          if (data.data.adminAndDealerDateFormat === 'MM-DD-YYYY') {
            setDate('MM-dd-yyyy')
          }
          if (data.data.adminAndDealerDateFormat === 'YYYY-MM-DD') {
            setDate('yyyy-MM-dd')
          }
        }
      }
      )
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
    history.push({
      pathname: REACT_CREATE_DISCOUNT,
    })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 18) {
      return text.substring(0, 18) + '...'
    } else {
      return text
    }
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  const handleEditDiscount = (params) => {
    setOpenEditDiscount(true)
    setEditRowData(params)
    history.push({
      pathname: REACT_CREATE_DISCOUNT,
      state: params.row,
    })
  }

  const renderCellCreateAt= (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.createdAt)
    ).format(
      date === 'dd-MM-yyyy' ? 'DD-MM-YYYY' :
        date === 'MM-dd-yyyy' ? 'MM-DD-YYYY' :
          date === 'yyyy-MM-dd' ? 'YYYY-MM-DD' : ''
    );
    console.log(formattedDate)
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const columns = React.useMemo(() => [
    {
      field: 'user.profile',
      headerName: '',
      sortable: false,
      width: 5,
    },
    {
      field: 'name',
      headerName: t('CUSTDATABLE26'),
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'discount',
      headerName: t('CUSTDATABLE29'),
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.discountValue,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'date',
      headerName: t('CUSTDATABLE30'),
      flex: 1,
      minWidth: 100,
      renderCell: renderCellCreateAt(date)
    },

    {
      field: 'status',
      headerName: t('CUSTDATABLE07'),
      flex: 1,
      minWidth: 100,
      valueGetter: (params) =>
        params.row.status === true ? 'ACTIVE' : 'INACTIVE',
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },
  ],[date])

  const globalsearch = (searchedValue) => {
    setSearchRecord(searchedValue)
    const filteredRows = rows.filter((discount) => {
      return (
        (discount.name
          ? discount.name.toLowerCase().includes(searchedValue.toLowerCase())
          : '') ||
        (searchedValue.toLowerCase() === 'active'
          ? discount.status === true
          : '') ||
        (searchedValue.toLowerCase() === 'inactive'
          ? discount.status === false
          : '') ||
        `${discount.name}`.toLowerCase().includes(searchedValue.toLowerCase())
      )
    })
    setRows(filteredRows)
  }

  return (
    <>
      <Stack
        direction="row"
        style={{
          height: '80px',
        }}
      >
        <Box style={{ margin: '16px, 0' }}>
          <Typography variant="h3">{t('DISCOUNT')}</Typography>
        </Box>

        <Box
          display={'flex'}
          width={'100%'}
          flexDirection={'row-reverse'}
          alignItems={'center'}
        >
          <Box item style={{ margin: '0 8px' }}>
            <Tooltip title={t('ADDDISCOUNT')}>
              <img
                style={{
                  height: '50px',
                }}
                alt="Discount"
                src={addDiscount}
                onClick={handleOpen}
              />
            </Tooltip>
          </Box>

          <Box item>
            <Search
              onSearch={(e) => globalsearch(e)}
              clearSearch={() => loadDiscountData(paging)}
            />
          </Box>
        </Box>
      </Stack>
      <Grid item style={{ width: '100%', height: '-webkit-fill-available' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </Grid>
    </>
  )
}
