import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MobileScreenShareRoundedIcon from '@mui/icons-material/MobileScreenShareRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Paper } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import pic from '../../assets/images/CustomerTransactionDetails.svg';
import pic2 from '../../assets/USA_Images/dealer/download.svg'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Chart } from "react-google-charts";
import Pagination from '@mui/material/Pagination';
import TablePagination from '@mui/material/TablePagination';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  opacity:"1",
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
    
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity:"1"
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    opacity:"1",
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const pieData = [
  ["Task", "Profit"],
  ["MySmartGuardPlus Profit ", 20],
  ["Dealer", 16],
  ["Amount Received ", 10],
  ["Amount Given", 12],
];

export default function SearchAppBar() {

  const [expanded, setExpanded] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [value, setValue] = React.useState(dayjs('2022-04-07'));
  const [color,setColor]=useState(false);
  const [color2,setColor2]=useState(false);
  const [color3,setColor3]=useState(false);
  const [color4,setColor4]=useState(false);

  const handleAccordianChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

    const handleAccordianClick=()=>{
        setColor(!color)
    }

    const handleAccordianClick2=()=>{
      setColor2(!color2)
  }

  const handleAccordianClick3=()=>{
    setColor3(!color3)
}

const handleAccordianClick4=()=>{
  setColor4(!color4)
}
const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {  
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    const data = [{id:1 , customerName:"Customer A1" , billingCycle:"14-11-2021 - 13-11-2022" , paymentDate:"14-11-2021" , subscription:"Basic($ 50/1m)", pdealer:"-" , phq:"$30" , sdealer:"$10" , shq:"", profitdealer:"$10", profithq:"$20"},
    {id:2 , customerName:"Customer A2" , billingCycle:"14-11-2021 - 13-11-2022" , paymentDate:"14-11-2021" , subscription:"Standard($ 100/3m)", pdealer:"$100" , phq:"-" , sdealer:"-" , shq:"$80", profitdealer:"$10", profithq:"$20"},
    {id:3 , customerName:"Customer A3" , billingCycle:"14-11-2021 - 13-11-2022" , paymentDate:"14-11-2021" , subscription:"Premium($ 500/6m)", pdealer:"-" , phq:"$30" , sdealer:"$10" , shq:"-", profitdealer:"$10", profithq:"$20"},
    {id:4 , customerName:"Customer A4" , billingCycle:"14-11-2021 - 13-11-2022" , paymentDate:"14-11-2021" , subscription:"Enterprise($ 1000/6m)", pdealer:"-" , phq:"$30" , sdealer:"$10" , shq:"-", profitdealer:"$10", profithq:"$20"}];
  return (

    // <Box sx={{ flexGrow: 1 } }>
    <>
    <Paper sx={{bgcolor:"#F2F7FF" , height:"100%" , width:"100%"}}>
    <Box>
      <AppBar position="static" color="transparent" sx={{ml:"-48px"}} >
        <Toolbar>
         
          <Typography
            variant="h3"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } , color:"#0F5A9C"}}
          >
            Monthly Income
          </Typography>

          
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}/>
          </Search>
          

        </Toolbar>
      </AppBar>
    </Box>


  <Box sx={{mt:"40px"}}>
    <Accordion  expanded={expanded === 'panel1'} onChange={handleAccordianChange('panel1')}>
        <AccordionSummary  onClick={handleAccordianClick} style={{backgroundColor:color? "#0F5A9C":""}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={pic} />
              <Box><Typography sx={{marginTop: "10px" , opacity:"1"}}>Akshay</Typography></Box>
              </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row-reverse" spacing={3} sx={{mt:"30px"}}> 

            <Box sx={{bgcolor:"#E5E8EB", height:"60px" , width:"60px" , alignItems:"center", alignContent:"center", display:"flex", justifyContent:"center"}}>
              <img src={pic2}></img>
            </Box>

          <SubmitButtons
                    variant="contained">
                    <Typography >
                      {'Submit'}
                    </Typography>
                  </SubmitButtons>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
          disableFuture
          label="End Date"
          openTo="year"
          views={['year', 'month', 'day']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
         </LocalizationProvider>

         <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
          disableFuture
          label="Start Date"
          openTo="year"
          views={['year', 'month', 'day']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
         </LocalizationProvider>
          </Stack>


        
      


    <Table sx={{mt:"30px"}}>
      <TableHead sx={{border: "1px solid" ,borderCollapse:"collapse", mt:"30px", borderTop:"none" , borderLeft:"none"}}>
        <TableRow sx={{color:"white", width:"100%"}}>
        <TableCell rowSpan={1} colSpan={4} > </TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white" ,border: "1px solid grey" ,borderCollapse:"collapse"}}>Paid To</TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Split</TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Profit</TableCell>
        </TableRow>

        <TableRow >
            <TableCell  align='left' sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Customer Name</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Billing Cycle</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Payment Date</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Suscription</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>

          </TableRow>
      </TableHead>

      <TableBody >
      {
      data.map((e) => (
            <TableRow key={e.id}>
              <TableCell align="center"  sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}> 
               <Stack direction="row" spacing={1}>
              <Avatar alt="Remy Sharp" src={pic} />
              <Box> <Typography sx={{mt:"12px"}}>{e.customerName}</Typography></Box>
              </Stack>
              </TableCell>


              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.billingCycle}</TableCell>
             <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}} >{e.paymentDate}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.subscription}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.pdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.phq}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.sdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.shq}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.profitdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.profithq}</TableCell>
          </TableRow>

          ))}

          <TableRow>
          <TableCell rowSpan={1} colSpan={4} > </TableCell>
          <TableCell colSpan={4} sx={{fontWeight:"bold" ,fontSize:"16px"}} > Total Amount</TableCell>
          <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse",  fontSize:"16px"}} > $ 40</TableCell>
          <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse",fontSize:"16px"}} >$ 80</TableCell>
          </TableRow>
      </TableBody>
    </Table>

    <Stack direction="row" spacing={2} sx={{mt:"42px"}}>
          <Box sx={{height:"400px" , width:"50%" }}>
              <Typography variant="h3"  sx={{color:"#0F5A9C", ml:"50px"}}>Transaction Details (14-10-2021 - 13-11-2022)</Typography>
              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"72px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Total Trannsaction</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"72px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1630</Typography></Box>
              </Stack> 

              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>HQ profit</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1350</Typography></Box>
              </Stack> 
              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Dealer Profit</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>%280</Typography></Box>
              </Stack> 

              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}> Amount Received</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1530</Typography></Box>
              </Stack> 


              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Amount Given</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px"  ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$260</Typography></Box>
              </Stack> 



          </Box>
          <Box sx={{height:"400px" , width:"50%"}}>
          <Typography variant="h3"  sx={{color:"#0F5A9C"}}>Overview</Typography>
              <Chart
              chartType="PieChart"
              data={pieData}
         
              width={"100%"}
              height={"350px"}/>

          </Box>
        </Stack>
       </AccordionDetails>







      </Accordion>
      <Accordion sx={{mt:"10px"}}   expanded={expanded === 'panel2'} onChange={handleAccordianChange('panel2')}>
        <AccordionSummary onClick={handleAccordianClick2} style={{backgroundColor:color2?"#0F5A9C":""}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={pic} />
              <Box><Typography sx={{marginTop: "10px"}}>Ashis</Typography></Box>
              </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row-reverse" spacing={3} sx={{mt:"30px"}}> 

            <Box sx={{bgcolor:"#E5E8EB", height:"60px" , width:"60px" , alignItems:"center", alignContent:"center", display:"flex", justifyContent:"center"}}>
              <img src={pic2}></img>
            </Box>

          <SubmitButtons
                    variant="contained">
                    <Typography >
                      {'Submit'}
                    </Typography>
                  </SubmitButtons>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
          disableFuture
          label="End Date"
          openTo="year"
          views={['year', 'month', 'day']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
         </LocalizationProvider>

         <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
          disableFuture
          label="Start Date"
          openTo="year"
          views={['year', 'month', 'day']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
         </LocalizationProvider>
          </Stack>


        
      


    <Table sx={{mt:"30px"}}>
      <TableHead sx={{border: "1px solid" ,borderCollapse:"collapse", mt:"30px", borderTop:"none" , borderLeft:"none"}}>
        <TableRow sx={{color:"white", width:"100%"}}>
          <TableCell rowSpan={1} colSpan={4} > </TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white" ,border: "1px solid grey" ,borderCollapse:"collapse"}}>Paid To</TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Split</TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Profit</TableCell>
        </TableRow>

        <TableRow >
            <TableCell  align='left' sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Customer Name</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Billing Cycle</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Payment Date</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Suscription</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>

          </TableRow>
      </TableHead>

      <TableBody >
      {
      data.map((e) => (
            <TableRow key={e.id}>
              <TableCell align="center"  sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}> 
               <Stack direction="row" spacing={1}>
              <Avatar alt="Remy Sharp" src={pic} />
              <Box> <Typography sx={{mt:"12px"}}>{e.customerName}</Typography></Box>
              </Stack>
              </TableCell>


              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.billingCycle}</TableCell>
             <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}} >{e.paymentDate}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.subscription}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.pdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.phq}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.sdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.shq}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.profitdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.profithq}</TableCell>
          </TableRow>

          ))}

          <TableRow>
          <TableCell rowSpan={1} colSpan={4} > </TableCell>
          <TableCell colSpan={4} sx={{fontWeight:"bold" ,fontSize:"16px"}} > Total Amount</TableCell>
          <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse",  fontSize:"16px"}} > $ 40</TableCell>
          <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse",fontSize:"16px"}} >$ 80</TableCell>
          </TableRow>
      </TableBody>
    </Table>

    <Stack direction="row" spacing={2} sx={{mt:"42px"}}>
          <Box sx={{height:"400px" , width:"50%" }}>
              <Typography variant="h3"  sx={{color:"#0F5A9C", ml:"50px"}}>Transaction Details (14-10-2021 - 13-11-2022)</Typography>
              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"72px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Total Trannsaction</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"72px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1630</Typography></Box>
              </Stack> 

              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>HQ profit</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1350</Typography></Box>
              </Stack> 
              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Dealer Profit</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>%280</Typography></Box>
              </Stack> 

              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}> Amount Received</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1530</Typography></Box>
              </Stack> 


              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Amount Given</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px"  ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$260</Typography></Box>
              </Stack> 



          </Box>
          <Box sx={{height:"400px" , width:"50%"}}>
          <Typography variant="h3"  sx={{color:"#0F5A9C"}}>Overview</Typography>
              <Chart
              chartType="PieChart"
              data={pieData}
         
              width={"100%"}
              height={"350px"}/>

          </Box>
        </Stack>
       </AccordionDetails>
      </Accordion >

      <Accordion sx={{mt:"10px"}}   expanded={expanded === 'panel3'} onChange={handleAccordianChange('panel3')}>
        <AccordionSummary onClick={handleAccordianClick3} style={{backgroundColor:color3? "#0F5A9C" : ""}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel2a-header"
        >
         <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={pic} />
              <Box><Typography sx={{marginTop: "10px"}}>Angilique</Typography></Box>
              </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row-reverse" spacing={3} sx={{mt:"30px"}}> 

            <Box sx={{bgcolor:"#E5E8EB", height:"60px" , width:"60px" , alignItems:"center", alignContent:"center", display:"flex", justifyContent:"center"}}>
              <img src={pic2}></img>
            </Box>

          <SubmitButtons
                    variant="contained">
                    <Typography >
                      {'Submit'}
                    </Typography>
                  </SubmitButtons>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
          disableFuture
          label="End Date"
          openTo="year"
          views={['year', 'month', 'day']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
         </LocalizationProvider>

         <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
          disableFuture
          label="Start Date"
          openTo="year"
          views={['year', 'month', 'day']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
         </LocalizationProvider>
          </Stack>


        
      


    <Table sx={{mt:"30px"}}>
      <TableHead sx={{border: "1px solid" ,borderCollapse:"collapse", mt:"30px", borderTop:"none" , borderLeft:"none"}}>
        <TableRow sx={{color:"white", width:"100%"}}>
          <TableCell rowSpan={1} colSpan={4} > </TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white" ,border: "1px solid grey" ,borderCollapse:"collapse"}}>Paid To</TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Split</TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Profit</TableCell>
        </TableRow>

        <TableRow >
            <TableCell  align='left' sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Customer Name</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Billing Cycle</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Payment Date</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Suscription</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>

          </TableRow>
      </TableHead>

      <TableBody >
      {
      data.map((e) => (
            <TableRow key={e.id}>
              <TableCell align="center"  sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}> 
               <Stack direction="row" spacing={1}>
              <Avatar alt="Remy Sharp" src={pic} />
              <Box> <Typography sx={{mt:"12px"}}>{e.customerName}</Typography></Box>
              </Stack>
              </TableCell>


              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.billingCycle}</TableCell>
             <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}} >{e.paymentDate}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.subscription}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.pdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.phq}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.sdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.shq}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.profitdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.profithq}</TableCell>
          </TableRow>

          ))}

          <TableRow>
          <TableCell rowSpan={1} colSpan={4} > </TableCell>
          <TableCell colSpan={4} sx={{fontWeight:"bold" ,fontSize:"16px"}} > Total Amount</TableCell>
          <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse",  fontSize:"16px"}} > $ 40</TableCell>
          <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse",fontSize:"16px"}} >$ 80</TableCell>
          </TableRow>
      </TableBody>
    </Table>

    <Stack direction="row" spacing={2} sx={{mt:"42px"}}>
          <Box sx={{height:"400px" , width:"50%" }}>
              <Typography variant="h3"  sx={{color:"#0F5A9C", ml:"50px"}}>Transaction Details (14-10-2021 - 13-11-2022)</Typography>
              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"72px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Total Trannsaction</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"72px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1630</Typography></Box>
              </Stack> 

              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>HQ profit</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1350</Typography></Box>
              </Stack> 
              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Dealer Profit</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>%280</Typography></Box>
              </Stack> 

              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}> Amount Received</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1530</Typography></Box>
              </Stack> 


              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Amount Given</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px"  ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$260</Typography></Box>
              </Stack> 



          </Box>
          <Box sx={{height:"400px" , width:"50%"}}>
          <Typography variant="h3"  sx={{color:"#0F5A9C"}}>Overview</Typography>
              <Chart
              chartType="PieChart"
              data={pieData}
         
              width={"100%"}
              height={"350px"}/>

          </Box>
        </Stack>
       </AccordionDetails>
      </Accordion >

      <Accordion sx={{mt:"10px"}}   expanded={expanded === 'panel4'} onChange={handleAccordianChange('panel4')}>
        <AccordionSummary onClick={handleAccordianClick4} style={{backgroundColor:color4?"#0F5A9C":""}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header">


              <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src={pic} />
              <Box><Typography sx={{marginTop: "10px"}}>Kanish</Typography></Box>
              </Stack>


        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row-reverse" spacing={3} sx={{mt:"30px"}}> 

            <Box sx={{bgcolor:"#E5E8EB", height:"60px" , width:"60px" , alignItems:"center", alignContent:"center", display:"flex", justifyContent:"center"}}>
              <img src={pic2}></img>
            </Box>

          <SubmitButtons
                    variant="contained">
                    <Typography >
                      {'Submit'}
                    </Typography>
                  </SubmitButtons>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
          disableFuture
          label="End Date"
          openTo="year"
          views={['year', 'month', 'day']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
         </LocalizationProvider>

         <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
          disableFuture
          label="Start Date"
          openTo="year"
          views={['year', 'month', 'day']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
         </LocalizationProvider>
          </Stack>


        
      


    <Table sx={{mt:"30px"}}>
      <TableHead sx={{border: "1px solid" ,borderCollapse:"collapse", mt:"30px", borderTop:"none" , borderLeft:"none"}}>
        <TableRow sx={{color:"white", width:"100%"}}>
          <TableCell rowSpan={1} colSpan={4} > </TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white" ,border: "1px solid grey" ,borderCollapse:"collapse"}}>Paid To</TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Split</TableCell>
        <TableCell align="center" colSpan={2} sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Profit</TableCell>
        </TableRow>

        <TableRow >
            <TableCell  align='left' sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Customer Name</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Billing Cycle</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Payment Date</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Suscription</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>Dealer</TableCell>
            <TableCell align="center" sx={{ bgcolor:"#0F5A9C",color:"white",border: "1px solid grey" ,borderCollapse:"collapse"}}>HQ</TableCell>

          </TableRow>
      </TableHead>

      <TableBody >
      {
      data.map((e) => (
            <TableRow key={e.id}>
              <TableCell align="center"  sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}> 
               <Stack direction="row" spacing={1}>
              <Avatar alt="Remy Sharp" src={pic} />
              <Box> <Typography sx={{mt:"12px"}}>{e.customerName}</Typography></Box>
              </Stack>
              </TableCell>


              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.billingCycle}</TableCell>
             <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}} >{e.paymentDate}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.subscription}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.pdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.phq}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.sdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.shq}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.profitdealer}</TableCell>
              <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse"}}>{e.profithq}</TableCell>
          </TableRow>

          ))}

          <TableRow>
          <TableCell rowSpan={1} colSpan={4} > </TableCell>
          <TableCell colSpan={4} sx={{fontWeight:"bold" ,fontSize:"16px"}} > Total Amount</TableCell>
          <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse",  fontSize:"16px"}} > $ 40</TableCell>
          <TableCell align="center" sx={{border: "1px solid #F2F7FF" ,borderCollapse:"collapse",fontSize:"16px"}} >$ 80</TableCell>
          </TableRow>
      </TableBody>
    </Table>

    <Stack direction="row" spacing={2} sx={{mt:"42px"}}>
          <Box sx={{height:"400px" , width:"50%" }}>
              <Typography variant="h3"  sx={{color:"#0F5A9C", ml:"50px"}}>Transaction Details (14-10-2021 - 13-11-2022)</Typography>
              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"72px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Total Trannsaction</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"72px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1630</Typography></Box>
              </Stack> 

              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>HQ profit</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1350</Typography></Box>
              </Stack> 
              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Dealer Profit</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>%280</Typography></Box>
              </Stack> 

              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}> Amount Received</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$1530</Typography></Box>
              </Stack> 


              <Stack direction="row">
                <Box sx={{height:"19px" , width:"131px" ,mt:"40px" , ml:"50px" ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>Amount Given</Typography></Box>
                <Box sx={{height:"19px" , width:"50px", ml:"388px",mt:"40px"  ,color:"#242424"}}><Typography sx={{fontWeight:"bold" }}>$260</Typography></Box>
              </Stack> 



          </Box>
          <Box sx={{height:"400px" , width:"50%"}}>
          <Typography variant="h3"  sx={{color:"#0F5A9C"}}>Overview</Typography>
              <Chart
              chartType="PieChart"
              data={pieData}
         
              width={"100%"}
              height={"350px"}/>

          </Box>
        </Stack>
       </AccordionDetails>
      </Accordion >
      
      </Box>


        <Stack direction="row">
      <Box sx={{mt:"450px"}}>
      <TablePagination
      component="div"
      count={10}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
      </Box>


    <Box component="span"  spacing={2} sx={{mt:"460px" , ml:"1050px"}}   >
      <Pagination count={10} variant="outlined" shape="rounded" />
    </Box> 

    </Stack>  
     

    </Paper>
    
    </>
  );
}
