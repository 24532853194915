import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import zoneIcon from '../../assets/USA_Images/zoneIcon.svg'
import DataTable from '../../components/DataTable/DataTable'
import Search from '../../components/SearchTab/Search'
import { getAllZone } from '../../slices/Zone/ZoneSlice'
import DeleteChecklist from '../Checklist/DeleteChecklist'
import EditChecklistIcon from '../Checklist/EditChecklistIcon'
import FilterChecklistTemplate from '../ChecklistTemplateUSA/FilterChecklistTemplate'
import AddZone from './AddZone'
import DeleteZone from './DeleteZone'
import ZoneView from './ZoneView'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../../theme/ThemeContext';

function ZoneList() {
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [updateObject, setUpdateObject] = useState({})
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [clear, setClear] = useState(false)
  const [searchedRows, setSerchedRows] = useState([])
  const [controlRendering, setControlRendering] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDeleteZone, setOpenDeletZone] = useState(false)
  const [zoneId, setZoneId] = useState()
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const { t } = useTranslation()
  const { darkMode } = useContext(ThemeContext);
  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })
  const [filter, setFilter] = useState({
    name: '',
  })

  const [selectedObject, setSelectedObject] = useState({
    zoneId: '',
    name: '',
    siteId: '',
  })

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const defaultFilter = {
    name: '',
  }

  const handleChange = (newPage, size) => {
    setRowsPerPage(size)
    setPage(newPage)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.zoneName
          ? test.zoneName.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.siteName
          ? test.siteName.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.addressLine1
          ? test.addressLine1.toLowerCase().includes(searchedVal.toLowerCase())
          : '')
      )
    })
    setRows(filteredRows)
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging, clear])

  const handleOpen = () => {
    setOpenEditDialog(true)
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const loadData = (filter, paging) => {
    dispatch(getAllZone(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000' && data.data) {
          setRows(data.data.zone)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
      .catch((er) => {})
  }

  function handleTextOverflow(text) {
    if (text && text.length > 20) {
      return text.substring(0, 20) + '...'
    } else {
      return text
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData({ page: page, rowsPerPage: rowsPerPage }, filter)
      setDel(false)
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false)
      }
    }
  }, [del])

  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog)
    setSelectedObject(params)
  }

  const handleDeleteZone = (params) => {
    setOpenDeletZone(!openDeleteZone)
    setZoneId(params.id)
  }

  const handleFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      name: data.name,
    })
  }

  const columns = [
    {
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>
      },
    },

    {
      field: 'zoneName',
      headerName: t('COMMON143'),
      flex: 1,
      width: 180,
      valueGetter: (params) => params.row.zoneName,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.zoneName}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.zoneName)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'siteName',
      headerName: t('COMMON144'),
      flex: 1,
      width: 180,
      valueGetter: (params) => params.row.siteName,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.siteName}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.siteName)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'addressLine1',
      headerName: t('ADD012'),
      flex: 0,
      width: 180,
      valueGetter: (params) => params.row.addressLine1,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.addressLine1}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.addressLine1)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      width: 180,
      headerAlign: 'center',

      getActions: (params) => [
        <GridActionsCellItem
          icon={<ZoneView title={t('ADD034')} rowData={params.row} />}
          label="View"
        />,

        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label="Edit"
          onClick={() => handleEditVisit(params.row)}
        />,

        <GridActionsCellItem
          icon={<DeleteChecklist />}
          label={t('dataTableDelete')}
          onClick={() => handleDeleteZone(params)}
        />,
      ],
    },
  ]

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          
        }}
      >
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          <Box style={{ margin: '16px, 0' }}>
            <Typography variant="h3" style={{ marginLeft: darkMode ? '33px' : '0px', marginRight: darkMode ? '18px' : '12px', color: darkMode ? '#fff' : '#3D4977', marginTop: darkMode ? '3px': '4px' }}>{t('COMMON022')}</Typography>
          </Box>
        </Grid>
        <Box
          style={{
            textAlign: 'center',
            padding: '10px',
            backgroundColor: process.env.REACT_APP_BG_ICON,
            borderRadius: '50%',
            cursor: 'pointer',
            width: '45px',
            height: '45px',
            marginRight: darkMode ? '15px' : ''
          }}
        >
          <Tooltip title={t('COMMON145')}>
            <img
              alt="..."
              src={zoneIcon}
              onClick={handleOpen}
              style={{ width: '20px' }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{ marginLeft: darkMode ? '-15px' : '0' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '1',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {'Clear Filter'}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterChecklistTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {open && (
        <AddZone
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            setOpen(false)
            loadData(filter, paging)
          }}
        />
      )}
      ,
      {openEditDialog && (
        <AddZone
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false)
            loadData(filter, paging)
            setSelectedObject({
              zoneId: '',
              name: '',
              siteId: '',
            })
          }}
        />
      )}
      ,
      {openDeleteZone && (
        <DeleteZone
          open={openDeleteZone}
          handleClose={(data) => {
            setOpenDeletZone(false)
          }}
          delete={(a) => setDel(true)}
          DeleteId={zoneId}
        />
      )}
    </>
  )
}

export default ZoneList
