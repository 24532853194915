import React from "react";
import { Tooltip, Grid } from "@mui/material";

const PaymentIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <>
      <Grid sx={{ mr: 1 }}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        {isShown ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.274" height="27.802" viewBox="0 0 25.274 27.802">
    <path id="Path_7337" data-name="Path 7337" d="M3651.6,13672.9a2.482,2.482,0,0,1-1.863-.844l-4.176-4.739a2.5,2.5,0,0,1,3.191-3.774l.927.573v-4.849a2.543,2.543,0,1,1,5.085,0v3.3l3.356.408a2.869,2.869,0,0,1,2.405,3.638l-1.294,4.491a2.491,2.491,0,0,1-2.385,1.795Zm-4.715-7.659a.691.691,0,0,0,.027.886l4.173,4.742a.7.7,0,0,0,.515.229h5.247a.687.687,0,0,0,.656-.494l1.29-4.488a1.042,1.042,0,0,0-.134-.882,1.054,1.054,0,0,0-.758-.47l-4.145-.508a.9.9,0,0,1-.792-.893v-4.1a.743.743,0,1,0-1.486,0v6.468a.9.9,0,0,1-.463.786.888.888,0,0,1-.909-.024l-2.3-1.424a.675.675,0,0,0-.367-.1A.684.684,0,0,0,3646.887,13665.242Zm-5.257-.068a10.481,10.481,0,1,1,14.649-10.665.9.9,0,1,1-1.791.182,8.685,8.685,0,1,0-12.134,8.836.9.9,0,0,1-.724,1.647Zm3.315-5.055h0v-.22a1.86,1.86,0,0,1-1.266-.518,1.819,1.819,0,0,1-.559-1.321.9.9,0,1,1,1.8,0,.03.03,0,0,0,.014.027c.395,0,2.165-.1,2.186-.872,0-.044-.031-.429-1.476-.758-1.987-.449-2.951-1.338-2.862-2.639a2.569,2.569,0,0,1,2.162-2.306v-.471a.9.9,0,0,1,1.8,0v.227a1.752,1.752,0,0,1,1.256.512,1.844,1.844,0,0,1,.563,1.32.9.9,0,1,1-1.8,0c-.679-.017-2.138.113-2.186.841-.01.127.113.457,1.462.762,1.942.443,2.91,1.3,2.876,2.561a2.553,2.553,0,0,1-2.172,2.388v.467a.9.9,0,0,1-1.8,0Z" transform="translate(-3635.364 -13645.1)" fill="#242424"/>
  </svg>
  

        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="25.274" height="27.802" viewBox="0 0 25.274 27.802">
          <path id="Path_7337" data-name="Path 7337" d="M3651.6,13672.9a2.482,2.482,0,0,1-1.863-.844l-4.176-4.739a2.5,2.5,0,0,1,3.191-3.774l.927.573v-4.849a2.543,2.543,0,1,1,5.085,0v3.3l3.356.408a2.869,2.869,0,0,1,2.405,3.638l-1.294,4.491a2.491,2.491,0,0,1-2.385,1.795Zm-4.715-7.659a.691.691,0,0,0,.027.886l4.173,4.742a.7.7,0,0,0,.515.229h5.247a.687.687,0,0,0,.656-.494l1.29-4.488a1.042,1.042,0,0,0-.134-.882,1.054,1.054,0,0,0-.758-.47l-4.145-.508a.9.9,0,0,1-.792-.893v-4.1a.743.743,0,1,0-1.486,0v6.468a.9.9,0,0,1-.463.786.888.888,0,0,1-.909-.024l-2.3-1.424a.675.675,0,0,0-.367-.1A.684.684,0,0,0,3646.887,13665.242Zm-5.257-.068a10.481,10.481,0,1,1,14.649-10.665.9.9,0,1,1-1.791.182,8.685,8.685,0,1,0-12.134,8.836.9.9,0,0,1-.724,1.647Zm3.315-5.055h0v-.22a1.86,1.86,0,0,1-1.266-.518,1.819,1.819,0,0,1-.559-1.321.9.9,0,1,1,1.8,0,.03.03,0,0,0,.014.027c.395,0,2.165-.1,2.186-.872,0-.044-.031-.429-1.476-.758-1.987-.449-2.951-1.338-2.862-2.639a2.569,2.569,0,0,1,2.162-2.306v-.471a.9.9,0,0,1,1.8,0v.227a1.752,1.752,0,0,1,1.256.512,1.844,1.844,0,0,1,.563,1.32.9.9,0,1,1-1.8,0c-.679-.017-2.138.113-2.186.841-.01.127.113.457,1.462.762,1.942.443,2.91,1.3,2.876,2.561a2.553,2.553,0,0,1-2.172,2.388v.467a.9.9,0,0,1-1.8,0Z" transform="translate(-3635.364 -13645.1)" fill="#242424"/>
        </svg>
        
        )}
      </Grid>
    </>
  );
};
export default PaymentIcon;
