import { createTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Autocomplete,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ReactComponent as FilterIcon } from '../../assets/FilterIcon.svg'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import Toast from '../../components/ToastContainer/CustomToast'
import CommonUtil from '../../components/Util/CommonUtils'
import { listDoorsByAccessLevelId } from '../../slices/VisitorAccessLevels/VisitorAccessLevelSlice'
import { ThemeContext } from '../../theme/ThemeContext';

const MuiTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#36c96d',
    },
    secondary: {
      main: '#f50057',
    },
  },
})

const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  border: '1px solid green',
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: 'green',
    color: '#FFFF',
  },
}))

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  border: '1px solid green',
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: 'green',
    color: '#FFFF',
  },
}))

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    background: '#3d4977',
    width: '100%',
    padding: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    color: '#FFFFFF',
  },
}))

export default function AccessLevelFilter(props) {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [door, setDoor] = useState()
  const { t } = useTranslation()
  const [filterQuery, setFilterQuery] = useState({
    name: '',
  })
  const { darkMode } = useContext(ThemeContext);

  const paging = {
    page: 1,
    rowsPerPage: 10000,
  }

  const defaultFilter = {
    accessGroupid: props.accGroupId,
    name: '',
  }
  useEffect(() => {
    if (open === true)
      dispatch(listDoorsByAccessLevelId(Object.assign(defaultFilter, paging)))
        .unwrap()
        .then((data) => {
          if (data.code === 'DMSI0000') {
            setDoor(data.data.accessGroupDoors)
          }
        })
  }, [open])

  const handleClose = () => {
    setFilterQuery(defaultFilter)
    setOpen(false)
  }

  const handleSubmit = () => {
    if (filterQuery.name === '') {
      Toast(t('COMMON015'), 'error')
      return
    }
    props.onSubmit(filterQuery)
    handleClose()
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const reset = () => {
    setOpen(false)
  }
  const doorNameField = () => {
    return (
      <Autocomplete
        fullWidth
        noOptionsText={'No Options found'}
        name="name"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option && option.name}
        options={!CommonUtil.isEmpty(door) ? door : []}
        onChange={(event, newValue) => {
          if (newValue !== null) setFilterQuery(newValue)
        }}
        renderInput={(params) => (
          <TextField {...params} label={t('DOOR_NAME')}
            InputLabelProps={{
              style: {
                color: darkMode ? '#fff' : '#0F273B',
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                },
                '&:hover fieldset': {
                  borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                },
              },
            }}
          />
        )}
      />
    )
  }
  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t('COMMON006')}
          </Typography>
        }
      >
        <FilterIcon onClick={handleOpen} style={{ cursor: 'pointer' }}>
          <FilterIcon />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
            {t('COMMON006')}
          </span>
        }
        acceptText={t('COMMON024')}
        cancelText={t('COMMON008')}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={reset}
      >
        <Grid container justifyContent="center" alignItems={'center'} style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
          <Grid item xs={12} sm={9} style={{ marginTop: '10px' }}>
            {doorNameField()}
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
