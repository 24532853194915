import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
    let query = "?isPagable=true&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    if (!CommonUtil.isEmptyString(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    return query;
};

const getAllChecklisttemplate = (filter) => {
    return api.securedAxios().get('/web/getCheckListTemplates' + buildQuery(filter));
}

const addChecklistTemplate = (payload) => {
    return api.securedAxios().post('/web/createCheckListTemplate', payload);
}

const updateChecklistTemplate = (payload) => {
    return api.securedAxios().put('/web/updateCheckListTemplate/' + payload.id, payload);
}

const deleteChecklistTemplate = (payload) => {
    return api.securedAxios().delete('/web/deleteCheckListTemplate/' + payload.id);
}

const checklistTemplateService = {
    getAllChecklisttemplate,
    addChecklistTemplate,
    updateChecklistTemplate,
    deleteChecklistTemplate,
}

export default checklistTemplateService;