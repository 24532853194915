import React from 'react'
import CustomerDialog from '../../components/DialogBoxComponent/CustomerDialog'
import { Grid, makeStyles } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Padding } from '@mui/icons-material';
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import jwt_decode from 'jwt-decode'
import CommonUtil from '../../Util/CommonUtils';
import Toast from '../../components/ToastContainer/CustomToast';
import moment from "moment";
import { useTranslation } from 'react-i18next'
import { filterDealerSubscriptions } from '../../slices/SubscriptionDealerSlice';


const useStyles = makeStyles((theme) => ({
  dialogbox: {
    '& .MuiDialog-paper': {
      // minWidth: '110vh',
      minWidth: "600px",
      // marginLeft:"250px",
    },
    ".& .css-f3jnds-MuiDataGrid-columnHeaders": {
      backgroundColor: "#F3F9FB"
    },
  },
}))

function CustomerFilter(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(props.open);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const dispatch = useDispatch()
  const { handleClose } = props;
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )
  const { t } = useTranslation()
  const [date, setDate] = useState()
  const [changeDate, setDateChange] = useState(true)
  const [statusValue, SetstatusValue] = useState()


  const [payload, setPayload] = useState({
    status: '',
    name: '',
    email: '',
    subscriptionName: ''
  })

  const handleSubmit = () => {
    if (payload.name.trim() === '' &&
      payload.email.trim() === '' &&
      payload.status.trim() === '' &&
      payload.subscriptionName.trim() === '') {
      Toast(t("FILTERISEMPTY"), "error");
      return;
    }
    props.applyFilter(payload)
    handleClose();
  }

  useEffect(() => {
    const filterPayload = {
      companyId: decoded_jwtToken.companyId,
      pageNumber: 1,
      pageSize: 1000,

    }
    dispatch(filterDealerSubscriptions(filterPayload))
      .unwrap()
      .then((data) => {
        setSubscriptionOptions(data.data.results)
      })
  }, [])


  const options = [
    { label: t('STATUS_ACTIVE') },
    { label: t('STATUS_IN_ACTIVE') }
  ]

  const handleChangeLangauage = (data) => {
    let status = ''
    switch (data) {
      case t('STATUS_ACTIVE'):
        status = 'ACTIVE'
        break;
      case t('STATUS_IN_ACTIVE'):
        status = 'INACTIVE'
        break;
      default:
        status = ''
    }
    SetstatusValue(status)
    return status;
  }

  return (
    <>
      <CustomerDialog
        className={classes.dialogbox}
        Header={t('FILTER')}
        acceptText={t('COMMON024')}
        cancelText={t('COMMON008')}
        onSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
        cancel={handleClose}
      >

        <Grid container
          justifyContent="center"
          style={{ padding: "20px", width: "100%" }}
          spacing={3}>

          <Grid item xs={12} md={12} lg={12} xl={12}>
            <TextField
              id="combo-box-demo"
              label={t('FILTERCUSTOMERNAME')}
              value={payload.name}
              onChange={(e) => setPayload({ ...payload, name: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <TextField
              id="combo-box-demo"
              label={t('CUSTDATABLE02')}
              value={payload.email}
              onChange={(e) => setPayload({ ...payload, email: e.target.value })}
              fullWidth
            />

          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={subscriptionOptions}
              getOptionLabel={(option) => option.price_strategy_name}
              onChange={
                (event, newValue) => setPayload({
                  ...payload,
                  subscriptionName: newValue.price_strategy_name
                })}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label={t('DEALERPLACEHOLDER1')} />}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              sx={{ width: "100%" }}
              value={payload.status}
              renderInput={(params) => <TextField {...params} label={t('CUSTDATABLE07')} />}
              onChange={(event, value) => {
                if (!CommonUtil.isEmpty(value)) {
                  const selectedStatus = handleChangeLangauage(value.label)
                  setPayload({
                    ...payload,
                    status: selectedStatus
                  })
                }
                else {
                  setPayload({
                    ...payload,
                    status: ''
                  })
                }
              }}
            />
          </Grid>
        </Grid>
      </CustomerDialog>
    </>
  )
}

export default CustomerFilter