import api from '../config/http-common';
const getRestrictTypeList = (filter) => {
    if (filter === undefined || filter == '') {
        filter = {
            code: '',
            name: ''
        }
    }

    return api.securedAxios().get('/api/v2.0/restrict_type/search' + '?isPageable=' + true
        + '&page=' + filter.page + '&size=' + filter.rowsPerPage + '&name='
        + filter.filter.name + '&code=' + filter.filter.code);

};

const addRestrictTypeV2 = (payload) => {
    return api.securedAxios().post('/api/v2.0/restrict_type', payload);
}

const updateRestrictTypeV2 = (payload) => {
    return api.securedAxios().put('/api/v2.0/restrict_type/' + payload.id, payload);
}

const getRestrictTypeById = (id) => {
    return api.securedAxios().get('/web/restrict_type/' + id);
}

const RestrictTypeService = {
    getRestrictTypeList,
    updateRestrictTypeV2,
    addRestrictTypeV2,
    getRestrictTypeById,
};
export default RestrictTypeService;
