import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles, styled } from '@material-ui/core/styles'
import './custom.css'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import LoginLayoutV2 from './LoginLayoutV2'
import { useTranslation } from 'react-i18next'
import USA_visLogo from '../../assets/USA_Images/login_images/App-logo.svg'
import mysmgLogo  from '../../../src/assets/USA_Images/user_profile/mysmgLogo.svg'
import visSelectComapny from '../../assets/images/onboard/select_company.svg'
import company from '../../assets/USA_Images/login_images/selectCompany.svg'
import vis_company from '../../assets/images/onboard/company.svg'
import {
  REACT_URL_DASHBOARD,
  REACT_DASHBOARD_PANEL,
} from '../../actions/EndPoints'
import CustomAutocomplete from '../../components/CustomInputs/CustomAutocomplete'
import CommonUtil from '../../Util/CommonUtils'
import { getCompanies, switchCompany } from '../../slices/onboard/LoginSlice'
import jwt_decode from 'jwt-decode'
import Toast from '../../components/ToastContainer/CustomToast'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    marginTop: theme.spacing(3),
  },
  selectcompany: {
    '& .MuiInputBase-input .MuiOutlinedInput-input .MuiInputBase-inputSizeSmall .MuiInputBase-inputAdornedEnd .MuiAutocomplete-input .MuiAutocomplete-inputFocused .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
      paddingLeft: '30px !important',
    },
  },
  submit: {
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  },
}))

function SelectCompany(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const [companies, setCompanies] = useState([])
  const [selectedCompany, setCompany] = useState(null)

  const [error, setError] = useState({
    companies: false,
  })

  const handleChange = (event) => {
    const name = event.target.name
    setCompanies(event.target.value)
    setError({
      [name]: false,
    })
  }

  useEffect(() => {
    loadCompanies()
  }, [])

  const loadCompanies = () => {
    dispatch(getCompanies())
      .unwrap()
      .then((data) => {
        setCompanies(CommonUtil.isEmpty(data.data) ? [] : data.data.companies)
        const decoded = jwt_decode(
          localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN),
        )
        var lastCompId = decoded.lastLoginCompanyId
        if (!CommonUtil.isEmpty(data.data) && data.data.companies.length > 1) {
          const lastCompanyObj = data.data.companies.find((comp) => {
            if (comp.id === lastCompId) {
              return comp
            }
          })
          setCompany(lastCompanyObj)
        } else {
          setCompany(data.data.companies[0])
        }
      })
      .catch((er) => {})
  }

  const VerifyCompany = () => {
    if (CommonUtil.isEmptyString(selectedCompany.code)) {
      toast.error(t('VERIFYCOMP0001'))
      return
    }
    const payload = {
      companyId: selectedCompany.id,
      type: '',
      fcmToken: '',
    }

    dispatch(switchCompany(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0011') {
          localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN)
          localStorage.setItem(
            process.env.REACT_APP_ACCESS_TOKEN,
            'Bearer ' + data.data.access_token,
          )
          localStorage.setItem(
            process.env.REACT_APP_REFRESH_TOKEN,
            data.data.refresh_token,
          )
          const decoded = jwt_decode(data.data.access_token)
          localStorage.setItem('companyId', decoded.companyId)
          localStorage.setItem('companyName', decoded.companyName)
          localStorage.setItem('companyType', decoded.companyType)
          localStorage.setItem(
            'companyCode',
            decoded.companyCode,
          )(
            decoded.companyType !== 'NONE'
              ? decoded.companyType === 'PARTNER'
                ? history.push(REACT_DASHBOARD_PANEL)
                : history.push(REACT_URL_DASHBOARD)
              : history.push(REACT_DASHBOARD_PANEL),
          )
        } else {
          Toast(data.message, 'error')
        }
      })
      .catch((er) => {})
  }
  function onhandleBckButton(e) {
    e.preventDefault()
    history.push('/vis/login')
  }

  return (
    <>
      <LoginLayoutV2
        lang={true}
        backbutton={true}
        title={'Back to Sign In'}
        handleClick={onhandleBckButton}
        src={
          process.env.REACT_APP_ENVIRONMENT === 'USA' ? company : vis_company
        }
      >
        <Grid className="center_div">
          <Grid container alignItems="center">
            <Grid item className="logo-center">
              <img
                src={
                  process.env.REACT_APP_ENVIRONMENT === 'USA'
                    ? mysmgLogo
                    : visSelectComapny
                }
                alt="logo"
                style={{ width: '250px' }}
              />
            </Grid>
          </Grid>
          <form className={classes.form} noValidate autoComplete="off">
            <Grid
              item
              xs={12}
              style={{ marginBottom: '13px', marginTop: '50px' }}
            >
              <Typography
                variant="h2"
                align="left"
                style={{ color: '#fff', fontWeight: '500', opacity: '0.9' }}
              >
                {t('VISUPDATE0006')}
              </Typography>
              <Typography
                variant="subtitle2"
                align="left"
                style={{
                  color: '#fff',
                  marginBottom: '20px',
                  marginTop: '6px',
                  fontSize: '12px',
                  opacity: '0.8',
                }}
              >
                {t('VISUPDATE0009')}
              </Typography>
            </Grid>
            <Grid>
              <CustomAutocomplete
                className={classes.selectcompany}
                label={t('VERIFYCOMP0007')}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                    setCompany(newValue)
                  }
                }}
                value={selectedCompany}
                options={CommonUtil.isEmptyArray(companies) ? [] : companies}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                required={true}
                error={error.companies}
              />
            </Grid>
            <Grid container spacing={5} alignItems="center">
              <Grid item xs>
                <Box mt={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      VerifyCompany()
                    }}
                  >
                    {t('VERIFYCOMP0003')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </LoginLayoutV2>
    </>
  )
}
export default withRouter(SelectCompany)
