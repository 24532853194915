import React, { useEffect } from 'react'
import HeaderToolbar from '../../components/HeaderToolbar'
import { Grid, Paper, Stack, Tooltip, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import customerImage from '../../assets/Customer/CustomerImage.svg'
import { Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function CustomerReportViewDetails(props) {
    const history = useHistory();
    const handleTextOverflow = (text) => {
        if (text && text.length > 20) {
            return text.substring(0, 17) + '...';
        } else {
            return text;
        }
    }
    
    const handleDealerClick = () =>{
        history.push({
            pathname: "/vis/transactions",
            state: {
                value : 0
            },
          });
    }

    const handleCustomerClick = () =>{
        history.push({
            pathname: "/vis/transactions",
            state: {
                value : 1
            },
          });
    }


    return (
        <>
        {props.location.state.type === "PARTNER" ?
        <Stack direction='row'>
        <ArrowBackIosIcon onClick={handleDealerClick} sx={{ color: '#9AA1B9'}}/>
            <HeaderToolbar

                title={"View Dealer Report Details"}
            />
            </Stack>
            :
            <Stack direction='row'>
                <ArrowBackIosIcon onClick={handleCustomerClick} sx={{ color: '#9AA1B9'}}/>
                <HeaderToolbar
                    title={"View Customer Report Details"}
                />
            </Stack>
        }

<Grid container lg={12} md={12} item sx={{mt:"20px" , bgcolor:"white"}} >
<Grid item>
    <Avatar alt="Remy Sharp" src={props.location.state.imagePreSignedURL ? props.location.state.imagePreSignedURL : customerImage} style={{height:"180px" , width:"180px" , marginLeft:"40px"}}/>
    </Grid>
    <Grid item xs zeroMinWidth style={{marginLeft:"20px" ,  borderRight:"3px solid #E1E9F5"}}  >
    <Grid container wrap="nowrap" style={{marginTop:"10px"}} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography noWrap style={{opacity:"50%"}}>First Name</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                
            </Grid>
        </Grid>

        <Grid container wrap="nowrap" style={{marginTop:"10px"}} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography noWrap style={{opacity:"50%"}}>Last Name</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              
            </Grid>
        </Grid>
        <Grid container wrap="nowrap" style={{marginTop:"10px"}} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography noWrap style={{opacity:"50%"}}>Email</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Tooltip title={`${props.location.state.email}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.email)}</Typography>
                </Tooltip>
            </Grid>
        </Grid>
        <Grid container wrap="nowrap" style={{marginTop:"10px"}} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography noWrap style={{opacity:"50%"}}>Mobile Number</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Tooltip title={`${props.location.state.phone}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.phone)}</Typography>
                </Tooltip>
            </Grid>
        </Grid>
        <Grid container wrap="nowrap" style={{marginTop:"10px"}} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography noWrap style={{opacity:"50%"}}>Company Name</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Tooltip title={`${props.location.state.name}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.name)}</Typography>
            </Tooltip>
            </Grid>
        </Grid>
    </Grid>

    <Grid item xs zeroMinWidth style={{marginLeft:"20px" , borderRight:"3px solid #E1E9F5"}}>
      <Grid container wrap="nowrap" style={{marginTop:"10px"}} columnSpacing={1} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography noWrap style={{opacity:"50%"}}>Subscription Plan</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                
            </Grid>
        </Grid>
        <Grid container wrap="nowrap" style={{marginTop:"10px"}} columnSpacing={1}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography noWrap style={{opacity:"50%"}}>Subscription Type</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              
            </Grid>
        </Grid>
        <Grid container wrap="nowrap" style={{marginTop:"10px"}} columnSpacing={1}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography noWrap style={{opacity:"50%"}}>Price</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{alignContent:'end' , alignItems:'end'}}>
         
            </Grid>

        </Grid>
        
        <Grid container style={{marginTop:"10px"}}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} columnSpacing={1}>
                <Typography style={{opacity:"50%"}}>Due Date</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
               
            </Grid>
        </Grid>
        <Grid container wrap="nowrap" style={{marginTop:"10px"}} columnSpacing={1} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography noWrap style={{opacity:"50%"}}>Auto renewal</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
           
            </Grid>
        </Grid>
    </Grid>
</Grid>
      
 </>
    )
}

export default CustomerReportViewDetails