import api from "../config/http-common"


const getCustomerSubscriptionDetailsByCompanyId = (companyId) => {
    return api.securedAxios().get('/web/api/v4/customers/' + companyId + '/subscriptions')
}

const saveSubscriptionDetailsForCustomer = (payload) => {
    return api.securedAxios().post('/web/api/v4/customer/'+ payload.companyId + '/subscription' , payload)
}

const updateSubscriptionDetailsForCustomer = (payload) => {
    return api.securedAxios().put('/web/api/v4/customer/' + payload.companyId + '/subscription/' + payload.subscriptionId, payload)
}



const getSubscription=(companyId)=>{

    return api.securedAxios().get('/web/api/v4/getCustomers/' +companyId)
}

const getSubscriptionById=(id)=>{
    return api.securedAxios().get('/web/api/v4/products/' +id )
}


const SubscriptionCustomerService = {
    getCustomerSubscriptionDetailsByCompanyId,
    saveSubscriptionDetailsForCustomer,
    updateSubscriptionDetailsForCustomer,
    getSubscription,
    getSubscriptionById

}

export default SubscriptionCustomerService;