import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import additionalSettingsService from '../../services/AdditionalSettings/AdditionalSettingsService';

const initialState = [];

export const createSettings = createAsyncThunk('settings/create', async (payload) => {
    const res = await additionalSettingsService.createSetting(payload);
    return res.data;
}); 

export const updateSettings = createAsyncThunk('settings/update', async (payload) => {
    const res = await additionalSettingsService.updateSettings(payload);
    return res.data;
}); 

export const getListSettings = createAsyncThunk('settings/list', async (payload) => {
    const res = await additionalSettingsService.getListSetting(payload);
    return res.data;
}); 

export const getListSettingsById = createAsyncThunk('settingsById/list', async (id) => {
    const res = await additionalSettingsService.getListSettingById(id);
    return res.data;
});
export const getVersion = createAsyncThunk('version/get', async (payload) => {
    const res = await additionalSettingsService.getVersion(payload);
    return res.data;
}); 


const additionalSettingsSlice = createSlice({
    name: 'settings',
    initialState,
    extraReducers: {
        [createSettings.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = additionalSettingsSlice;
export default reducer;