import { Grid, Tooltip, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import CommonUtil from "../../Util/CommonUtils";
import CustomDateTimePicker from "../../components/CustomInputs/CustomDateTimePicker";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from '../../components/DialogBoxComponent/DialogBox';
import Toast from "../../components/ToastContainer/CustomToast";
import { ThemeContext } from '../../theme/ThemeContext';

export default function FilterDeliveryReport(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [changeDate, setDateChange] = useState(true);
  const [dateOpen, SetDateOpen] = useState('');
  const { darkMode } = useContext(ThemeContext);
  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };
  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [payload, setPayload] = useState({
    deliveresStartDate: CommonUtil.formatToUtc(startDate),
    deliveresEndDate: CommonUtil.formatToUtc(endDate),
    deliveryCompanyName: "",
  });

  const [error, setError] = useState({
    deliveresStartDate: false,
    deliveresStartDate: false,
    deliveryCompanyName: false,

  });

  const handleClose = () => {
    setPayload({
      deliveresStartDate: CommonUtil.formatToUtc(startDate),
      deliveresEndDate: CommonUtil.formatToUtc(endDate),
      deliveryCompanyName: "",

    });
    setOpen(false);
    SetDateOpen(props.open)
  };

  const reset = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (
      payload.deliveresStartDate === null &&
      payload.deliveresEndDate === null
    ) {
      Toast(t("COMMON015"), "error");
      return;
    }

    if (
      payload.deliveresStartDate === null ||
      payload.deliveresStartDate === undefined ||
      payload.deliveresStartDate === ""
    ) {
      setError({ deliveresStartDate: true });
      Toast(t("INVITESTARTDATEERROR"), "error");
      return;
    }

    if (
      payload.deliveresEndDate === null ||
      payload.deliveresEndDate === undefined ||
      payload.deliveresEndDate === ""
    ) {
      setError({ deliveresEndDate: true });
      Toast(t("INVITEENDDATEERROR"), "error");
      return;
    }

    if (
      new Date(payload.deliveresStartDate).toString() === "Invalid Date" ||
      new Date(payload.deliveresEndDate).toString() === "Invalid Date"
    ) {
      Toast(t("USERPROFSCREEN013"), "error");
      return;
    }

    // if (new Date(payload.fromDate) < new Date(payload.toDate)) {
    //   Toast(t("INVITATIONFORM010"), "error");
    //   return;
    // }

    props.applyFilter(payload);
    setPayload({
      deliveresStartDate: CommonUtil.formatToUtc(startDate),
      deliveresEndDate: CommonUtil.formatToUtc(endDate),
      deliveryCompanyName: "",
    });
    handleClose();
    setDateChange(true);
  };

  return (
    <>
      <Tooltip title={<Typography>{t('COMMON006')}</Typography>}>
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
          <FilterDeliveryReport />
        </FilterIcon>
      </Tooltip>

      <DialogBox
         Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
           {t("COMMON006")}
          </span>
        }
        acceptText={t('COMMON024')}
        cancelText={t('COMMON008')}
        style={{ color: "#3D4977", opacity: 1, minHeight: "", }}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid container
          justifyContent="center"
          style={{ padding: "20px", width: "100%", backgroundColor: darkMode ? '#0F273B' : '#fff', marginLeft: darkMode ? '0px' : '', marginTop: darkMode ? '-1px' : ''}}
          spacing={3}>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomTextfield
              label={t('DELIVERY_COMPANY')}
              name="deliveryCompanyName"
              value={payload.deliveryCompanyName}
              handleChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomDateTimePicker
              style={{ marginButtom: "20px" }}
              onChange={(deliveresStartDate) =>
                setPayload({
                  ...payload,
                  deliveresStartDate: deliveresStartDate,
                })
              }
              value={payload.deliveresStartDate}
              label={t('INVITESTARTDATEERROR1')}
              changeDate={changeDate}
              date={props.date}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomDateTimePicker
              onChange={(deliveresEndDate) =>
                setPayload({
                  ...payload,
                  deliveresEndDate: deliveresEndDate,
                })
              }
              value={payload.deliveresEndDate}
              // minDateTime={payload.deliveresStartDate}
              label={t('INVITESTARTDATEERROR2')}
              changeDate={changeDate}
              date={props.date}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
