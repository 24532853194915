import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userRoleService from '../../services/UserRole/UserRoleService';


const initialState = [];

export const getAllUserRoles = createAsyncThunk('getAllUserRoles/', async (payload) => {
    const res = await userRoleService.getAllUserRoles(payload);
    return res.data;
});

export const createUserRoles = createAsyncThunk('createUserRoles/create', async (payload) => {
    const res = await userRoleService.createUserRoles(payload);
    return res.data;
});

export const updateUserRoles = createAsyncThunk('updateUserRoles/update', async (payload) => {
    const res = await userRoleService.updateUserRoles(payload);
    return res.data;
});

export const deleteUserRoles = createAsyncThunk('deleteUserRoles/delete', async (roleId) => {
    const res = await userRoleService.deleteUserRoles(roleId);
    return res.data;
});

export const getPermissionsList = createAsyncThunk('getPermissionsList/', async () => {
    const res = await userRoleService.getPermissionsList();
    return res.data;
});

export const getPermissionsListByroleId = createAsyncThunk('getPermissionsList/by_role_Id', async (payload) => {
    const res = await userRoleService.getPermissionsListByroleId(payload);
    return res.data;
});

export const assignRole = createAsyncThunk('role/assign', async (payload) => {
    const res = await userRoleService.roleAssign(payload);
    return res.data;
});

export const userList = createAsyncThunk('user/list', async (payload) => {
    const res = await userRoleService.userList(payload);
    return res.data;
});

export const listofRoles = createAsyncThunk("listofRoles" , async (payload) => {
    const res = await userRoleService.listofRoles(payload);
    return res.data
});


const userRoleSlice = createSlice({
    name: 'getAllUserRoles',
    initialState,
    extraReducers: {
        [getAllUserRoles.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = userRoleSlice;
export default reducer;