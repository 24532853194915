import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter, page, rowsPerPage) => {
  let query = "?isPageable=true&pageNumber=" + page + "&pageSize=" + rowsPerPage;
  if (!CommonUtil.isEmptyString(filter.firstName)) {
    const fullName  =filter.firstName.split(" ");
    if(!CommonUtil.isEmpty(fullName[1])){
      query = query + "&firstName=" + fullName[0]+"&lastName="+ fullName[1];
    }else{
      query = query + "&firstName=" + filter.firstName;
    }
  }
  if (!CommonUtil.isEmptyString(filter.lastName)) {
    query = query + "&lastName=" + filter.lastName;
  }

  if (!CommonUtil.isEmptyString(filter.email)) {
    query = query + "&email=" + filter.email;
  }

  if (!CommonUtil.isEmptyString(filter.phoneNumber)) {
    query = query + "&phoneNumber=" + filter.phoneNumber;
  }

  if (!CommonUtil.isEmptyString(filter.contactEmail)) {
    query = query + "&contactEmail=" + filter.contactEmail;
  }

  if (!CommonUtil.isEmptyString(filter.contactPhoneNumber)) {
    query = query + "&contactPhoneNumber=" + filter.contactPhoneNumber;
  }

  if (!CommonUtil.isEmptyString(filter.visitTypeId)) {
    query = query + "&visitTypeId=" + filter.visitTypeId;
  }
  return query;
};

const getWatchlist = (payload) => {
    return api.securedAxios().get("/api/v2.0/visitor/watchlist" +
    buildQuery(payload.filter, payload.page, payload.rowsPerPage)
    );
};

const createWatchlist = (payload) => {
    return api.securedAxios().post("/api/v2.0/visitor/watchlist", payload);
};

const updateWatchlist = (payload) => {
  return api.securedAxios().put("/api/v2.0/visitor/watchlist/" + payload.id, payload);
}

const deleteWatchlist = (payload) => {
  return api.securedAxios().delete('/api/v2.0/visitor/watchlist?watchListId=' + payload);
};

const WatchlistService = {
    getWatchlist,
    createWatchlist,
    updateWatchlist,
    deleteWatchlist,
};

export default WatchlistService;