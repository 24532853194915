import React from "react";
import { Tooltip, Grid } from "@mui/material";

const ProfileIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <>
        <Grid sx={{mr:1}}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <g id="Page-1" transform="translate(0)">
                <g id="Dribbble-Light-Preview" transform="translate(0)">
                  <g id="icons">
                    <path id="profile_round-_1346_" data-name="profile_round-[#1346]" d="M336.674,1964.4A3.675,3.675,0,1,0,333,1968a3.641,3.641,0,0,0,3.675-3.6m3.477,12.6h-1.639a.9.9,0,1,1,0-1.8h.395a.9.9,0,0,0,.864-1.2,7.561,7.561,0,0,0-13.541,0,.9.9,0,0,0,.864,1.2h.394a.9.9,0,1,1,0,1.8h-1.64a1.8,1.8,0,0,1-1.809-2.128,8.567,8.567,0,0,1,5.508-6.266,5.346,5.346,0,0,1-2.06-4.206,5.523,5.523,0,0,1,10.961-.837,5.341,5.341,0,0,1-2,5.042,8.567,8.567,0,0,1,5.508,6.266,1.8,1.8,0,0,1-1.809,2.128m-4.4-.9a.91.91,0,0,1-.918.9h-3.675a.9.9,0,1,1,0-1.8h3.675a.91.91,0,0,1,.918.9" transform="translate(-324 -1959)" fill="#242424" fill-rule="evenodd" />
                  </g>
                </g>
              </g>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <g id="Page-1" transform="translate(0)">
                <g id="Dribbble-Light-Preview" transform="translate(0)">
                  <g id="icons">
                    <path id="profile_round-_1346_" data-name="profile_round-[#1346]" d="M336.674,1964.4A3.675,3.675,0,1,0,333,1968a3.641,3.641,0,0,0,3.675-3.6m3.477,12.6h-1.639a.9.9,0,1,1,0-1.8h.395a.9.9,0,0,0,.864-1.2,7.561,7.561,0,0,0-13.541,0,.9.9,0,0,0,.864,1.2h.394a.9.9,0,1,1,0,1.8h-1.64a1.8,1.8,0,0,1-1.809-2.128,8.567,8.567,0,0,1,5.508-6.266,5.346,5.346,0,0,1-2.06-4.206,5.523,5.523,0,0,1,10.961-.837,5.341,5.341,0,0,1-2,5.042,8.567,8.567,0,0,1,5.508,6.266,1.8,1.8,0,0,1-1.809,2.128m-4.4-.9a.91.91,0,0,1-.918.9h-3.675a.9.9,0,1,1,0-1.8h3.675a.91.91,0,0,1,.918.9" transform="translate(-324 -1959)" fill="#8998a3" fill-rule="evenodd" />
                  </g>
                </g>
              </g>
            </svg>
          )}
        </Grid>
    </>
  );
};
export default ProfileIcon;
