import { Box, Typography } from '@mui/material'
import React from 'react'

function UnAuthorizedAccess() {
  return (
    <Box>
      <Typography style={{
        fontSize : "xxx-large",
        fontWeight : "bold"
      }}>
        403 Forbidden
      </Typography>  
    </Box>
  )
}

export default UnAuthorizedAccess