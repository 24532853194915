import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const DeleteIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t("COMMON004")}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="18.31" height="18.31" viewBox="0 0 18.31 18.31">
            <g id="Delete" transform="translate(-1.875 -1.875)">
              <path id="Path_6234" data-name="Path 6234" d="M15.549,21.17H7.9a2.16,2.16,0,0,1-2.156-1.952L4.5,6.794A.721.721,0,0,1,5.222,6h13a.722.722,0,0,1,.719.794L17.705,19.217a2.161,2.161,0,0,1-2.156,1.953ZM6.02,7.445l1.16,11.63a.721.721,0,0,0,.719.651h7.65a.721.721,0,0,0,.719-.651l1.16-11.63Z" transform="translate(-0.694 -1.11)" fill="#3d4977" stroke="#3d4977" stroke-width="0.25"/>
              <path id="Path_6235" data-name="Path 6235" d="M19.337,7.445H2.722A.722.722,0,1,1,2.722,6H19.337a.722.722,0,1,1,0,1.445Z" transform="translate(0 -1.11)" fill="#3d4977" stroke="#3d4977" stroke-width="0.25"/>
              <path id="Path_6236" data-name="Path 6236" d="M14.222,19.391a.722.722,0,0,1-.722-.722V10.722a.722.722,0,0,1,1.445,0v7.946A.722.722,0,0,1,14.222,19.391Z" transform="translate(-3.193 -2.221)" fill="#3d4977" stroke="#3d4977" stroke-width="0.25"/>
              <path id="Path_6237" data-name="Path 6237" d="M10.444,19.391a.722.722,0,0,1-.719-.657L9,10.787a.722.722,0,1,1,1.439-.13l.722,7.946a.722.722,0,0,1-.72.787Z" transform="translate(-1.943 -2.221)" fill="#3d4977" stroke="#3d4977" stroke-width="0.25"/>
              <path id="Path_6238" data-name="Path 6238" d="M17.723,19.391A.722.722,0,0,1,17,18.6l.722-7.946a.722.722,0,1,1,1.439.13l-.722,7.946A.722.722,0,0,1,17.723,19.391Z" transform="translate(-4.164 -2.221)" fill="#3d4977" stroke="#3d4977" stroke-width="0.25"/>
              <path id="Path_6239" data-name="Path 6239" d="M16.224,6.334h-6.5A.722.722,0,0,1,9,5.612V2.722A.722.722,0,0,1,9.722,2h6.5a.722.722,0,0,1,.722.722v2.89A.722.722,0,0,1,16.224,6.334ZM10.445,4.89H15.5V3.445H10.445Z" transform="translate(-1.943)" fill="#3d4977" stroke="#3d4977" stroke-width="0.25"/>
            </g>
          </svg>
          ) : (
           
            <svg xmlns="http://www.w3.org/2000/svg" width="18.31" height="18.31" viewBox="0 0 18.31 18.31">
            <g id="Group_7286" data-name="Group 7286" transform="translate(-1.875 -1.875)">
              <path id="Path_6234" data-name="Path 6234" d="M15.549,21.17H7.9a2.16,2.16,0,0,1-2.156-1.952L4.5,6.794A.721.721,0,0,1,5.222,6h13a.722.722,0,0,1,.719.794L17.705,19.217a2.161,2.161,0,0,1-2.156,1.953ZM6.02,7.445l1.16,11.63a.721.721,0,0,0,.719.651h7.65a.721.721,0,0,0,.719-.651l1.16-11.63Z" transform="translate(-0.694 -1.11)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
              <path id="Path_6235" data-name="Path 6235" d="M19.337,7.445H2.722A.722.722,0,1,1,2.722,6H19.337a.722.722,0,1,1,0,1.445Z" transform="translate(0 -1.11)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
              <path id="Path_6236" data-name="Path 6236" d="M14.222,19.391a.722.722,0,0,1-.722-.722V10.722a.722.722,0,0,1,1.445,0v7.946A.722.722,0,0,1,14.222,19.391Z" transform="translate(-3.193 -2.221)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
              <path id="Path_6237" data-name="Path 6237" d="M10.444,19.391a.722.722,0,0,1-.719-.657L9,10.787a.722.722,0,1,1,1.439-.13l.722,7.946a.722.722,0,0,1-.72.787Z" transform="translate(-1.943 -2.221)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
              <path id="Path_6238" data-name="Path 6238" d="M17.723,19.391A.722.722,0,0,1,17,18.6l.722-7.946a.722.722,0,1,1,1.439.13l-.722,7.946A.722.722,0,0,1,17.723,19.391Z" transform="translate(-4.164 -2.221)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
              <path id="Path_6239" data-name="Path 6239" d="M16.224,6.334h-6.5A.722.722,0,0,1,9,5.612V2.722A.722.722,0,0,1,9.722,2h6.5a.722.722,0,0,1,.722.722v2.89A.722.722,0,0,1,16.224,6.334ZM10.445,4.89H15.5V3.445H10.445Z" transform="translate(-1.943)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.25"/>
            </g>
          </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default DeleteIcon;
