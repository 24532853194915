import React, { useRef, useState, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { revokeUser } from '../../slices/onboard/LoginSlice';
import SessionExpiredModal from '../VisitorActionsComponents/SessionExpiredModal';

function IdleTimerContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const IdleTimerRef = useRef(null);

  const notAllowedPaths = [
    '/vis/qr-code',
    '/vis/result-page',
    '/vis/link-expired',
    '/vis/confirm-page',
    '/vis/selfRegistration-confirm-page',
    '/vis/visit-success',
    '/vis/selfRegistartion',
    '/vis/visit-success',
    '/vis/activate-account',
    '/vis/forgot-password',
    '/vis/password-success',
    '/vis/reset-password',
    '/vis/login'
  ];

  const onIdle = () => {
    dispatch(revokeUser());
    const currentPath = window.location.pathname;
    if (!notAllowedPaths.includes(currentPath)) {
      setModalIsOpen(true);
      console.log("Time session login is working fine" + modalIsOpen)
    }
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <IdleTimer
        ref={IdleTimerRef}
        timeout={1000 * 60 * 10}
        onIdle={onIdle}
      />

      <SessionExpiredModal
        isOpen={modalIsOpen}
        onClose={handleModalClose}
      />
    </>
  );
}

export default IdleTimerContainer;
