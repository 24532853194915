import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next';
import HeaderToolbar from '../../components/HeaderToolbar'
import LogsTabs from './LogsTabs'

function VisitorLogs() {
  const { t } = useTranslation();

    return (
        <>
            <HeaderToolbar title= {process.env.REACT_APP_ENVIRONMENT === 'USA' ? t("DAILYREPORTLBL") : t("labelVisitorLogTitle")}/>
            <LogsTabs />
        </>
    )
}
export default VisitorLogs