import { Avatar, Box, Tooltip } from '@material-ui/core'
import { Typography } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import * as FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import * as XLSX from 'xlsx'
import filterIcon from '../../assets/Customer/Filter.svg'
import forwardIcon from '../../assets/Customer/forward.svg'
import DataTable from '../../components/DataTable/DataTable'
import Search from '../../components/SearchTab/Search'
import Toast from '../../components/ToastContainer/CustomToast'
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import {
  getDealerInvoice,
  getDealers,
  getdealerById,
} from '../../slices/Dealer/DealerSlice'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import ViewIcon from '../Invitations/ViewIcon'
import InvoiceIcon from './InvoiceIcon'
import CustomerAndDealerTranscationFilter from '../Customer/CustomerAndDealerTranscationFilter'
import CommonUtil from '../../Util/CommonUtils'
import DealerTransactionAndReportExports from './DealerTransactionAndReportExports'
import CircularProgress from '@mui/material/CircularProgress'
import BrowserLanguage from '../../components/Util/BrowserLanguage'

let language = BrowserLanguage.getDefaultLanguage()

function DealerTransaction() {
  const [clear, setClear] = React.useState(false)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pageSize, setPageSize] = useState(0)
  const history = useHistory()
  const [openFilter, setOpenFilter] = useState(false)
  const [openDownload, setOpenDownload] = useState(false)
  const [rowDealer, setRowDealer] = useState({})
  const companyId = localStorage.getItem('companyId')
  const [date, setDate] = useState()
  const [dealerData, setDealerData] = useState('dealer')
  const [dealerTransaction, setDealerTransaction] = useState(
    'dealerTransaction',
  )

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }
  var today = new Date()
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T23:59:59'
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T00:00'

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = React.useState({
    status: '',
    name: '',
    email: '',
    subscriptionStartDate: CommonUtil.formatToUtc(startDate),
    subscriptionEndDate: CommonUtil.formatToUtc(endDate),
  })

  const defaultFilter = {
    status: '',
    name: '',
    email: '',
    subscriptionStartDate: CommonUtil.formatToUtc(startDate),
    subscriptionEndDate: CommonUtil.formatToUtc(endDate),
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  const applyFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      name: data.name,
      email: data.email,
      status: data.status,
      subscriptionStartDate: CommonUtil.formatToUtc(data.subscriptionStartDate),
      subscriptionEndDate: CommonUtil.formatToUtc(data.subscriptionEndDate),
    })
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging])

  const loadData = (filter, paging) => {
    dispatch(getDealers(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'MSGS4007')) {
          setLoading(false)
          setTotalRecords(data.data.totalCount)
          setRows(data.data.companies)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
        } else {
          setRows([])
        }
      })
      .catch((er) => {})
  }

  useEffect(() => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          if (data.data.adminAndDealerDateFormat === 'MM/DD/YYYY') {
            setDate('MM/dd/yyyy HH:mm')
          } else {
            setDate('dd/MM/yyyy HH:mm')
          }
        }
      })
  }, [])

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 20) + '...'
    } else {
      return text
    }
  }

  const handleViewCustomer = (params) => {
    history.push({
      pathname: '/vis/customerTransactionView',
      state: params,
      dealerData: dealerData,
    })
  }

  const handleInvoice = (params) => {
    dispatch(getDealerInvoice(params))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4040') {
          setLoading(true)
          window.open(res.data.receipt_url)
          Toast(t('RECEIPT_PDF'), 'success')
          setLoading(false)
        } else {
          setLoading(false)
          Toast(t('dataTableEdit1'), 'error')
        }
      })
      .catch((er) => {})
  }

  const handleDownloadInvoice = async (data) => {
    const modifieData = customizeData(data)
    const ws = XLSX.utils.json_to_sheet(modifieData)
    const columnWidths = [{ wch: 20 }, { wch: 20 }, { wch: 15 }]
    ws['!cols'] = columnWidths
    const wb = { Sheets: { Dealer: ws }, SheetNames: ['Dealer'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    const fileExtension = '.xlsx'
    const blobData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(blobData, 'Dealer Invoice' + fileExtension)
    Toast(t('INVOICEEXPORTSUCCESS'), 'success')
  }
  let customizeData = null
  if (language === 'en') {
    customizeData = (data) => {
      return data.map((item) => ({
        'Company Name': item.name,
        Email: item.email,
        Mobile: item.phone,
      }))
    }
  } else {
    customizeData = (data) => {
      return data.map((item) => ({
        'nombre de empresa': item.name,
        'Correo electrónico': item.email,
        Móvil: item.phone,
      }))
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={
            params.row.imagePreSignedURL !== undefined
              ? params.row.imagePreSignedURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={params.row.imagePreSignedURL}
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(() => [
    {
      field: 'company.profile',
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },

    {
      field: 'companyName',
      headerName: t('CUSTDATABLE01'),
      flex: 1,
      minWidth: 230,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'email',
      headerName: t('CUSTDATABLE02'),
      flex: 1,
      minWidth: 230,
      valueGetter: (params) => params.row.email,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'mobile',
      headerName: t('CUSTDATABLE03'),
      flex: 1,
      minWidth: 230,
      valueGetter: (params) => params.row.phone,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Actions',
      headerName: t('CUSTDATABLE16'),
      type: 'actions',
      flex: 1,
      minWidth: 80,
      width: 80,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ViewIcon />}
          onClick={() => handleViewCustomer(params.row)}
        />,
        <GridActionsCellItem
          icon={<InvoiceIcon />}
          onClick={() => handleInvoice(params.row)}
        />,
      ],
    },
  ])

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.email ? test.email.includes(searchedVal.toLowerCase()) : '') ||
        (test.status
          ? test.status.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.phone
          ? test.phone.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        `${test.name}`.toLowerCase().includes(searchedVal.toLowerCase())
      )
    })
    setRows(filteredRows)
  }

  const handleFilterIcon = () => {
    setOpenFilter(true)
  }

  const handleDownload = () => {
    if (rows.length === 0) {
      Toast(t('dataTableEmptyList'), 'error')
      return
    } else {
      setOpenDownload(true)
    }
  }

  return (
    <>
      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}
      {/* <Box style={{ margin: '16px, 0' }}>
        <Typography variant="h3">Dealer Details</Typography>
      </Box> */}

      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item style={{ margin: '0 8px' }}>
          <Tooltip title={t('ADD036')} onClick={handleFilterIcon}>
            <img src={filterIcon} />
          </Tooltip>
        </Box>

        <Box item style={{ margin: '0 8px' }}>
          <Tooltip title={t('ADD037')} onClick={handleDownload}>
            <img src={forwardIcon} />
          </Tooltip>
        </Box>

        <Box item>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: '100%', marginTop: '15px' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openFilter && (
        <CustomerAndDealerTranscationFilter
          open={openFilter}
          onClose={() => {
            setOpenFilter(false)
          }}
          rowsPerPage={rowsPerPage}
          applyFilter={(data) => applyFilter(data)}
          date={date}
          dealerData={dealerData}
        />
      )}

      {openDownload && (
        <DealerTransactionAndReportExports
          open={openDownload}
          handleClose={() => {
            setOpenDownload(false)
          }}
          type={dealerTransaction}
        />
      )}
    </>
  )
}

export default DealerTransaction
