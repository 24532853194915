import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Typography } from 'antd'
import { Link } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

export default function StripeSetupDialog(props) {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backdropFilter: 'blur(8px)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust the opacity as needed
          },
        }}
      >
        <DialogContent sx={{ m: 0, p: 2, fontSize: '20px' }}>
          <Typography>Onboarding Process</Typography>
        </DialogContent>
        <DialogContent
          sx={{
            textAlign: 'center',
            fontSize: '14px',
          }}
        >
          <Typography>
            Reseller settlement is in yet set up. Please complete the onboarding
            process
          </Typography>
          <Typography>
            If this message still displays after you completed the onboarding
          </Typography>
          <Typography>Please contact to administrator</Typography>
        </DialogContent>
        <DialogActions>
          {window.location.origin === 'https://login.mysmartguardplus.com/' ? (
            <Link
              href="
            http://billing.stripe.com/p/login/7sI9CFd0S7xQ8HC8ww"
              target="_blank"
            >
              <Button
                variant="contained"
                sx={{
                  mr: '125px',
                }}
              >
                Complete Onboarding Process
              </Button>
            </Link>
          ) : (
            <Link
              href="
            https://billing.stripe.com/p/login/test_4gw29Z1PobHO4aA288"
              target="_blank"
            >
              <Button
                variant="contained"
                sx={{
                  mr: '125px',
                }}
              >
                Complete Onboarding Process
              </Button>
            </Link>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
