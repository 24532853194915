import React, { useState, useContext } from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from "prop-types";
import { Typography } from '@material-ui/core';
import { ReactComponent as DropUpIcon } from "../../../src/assets/DropUp.svg";
import { ThemeContext } from "../../theme/ThemeContext";

function CustomDropdown(props) {
    const { darkMode } = useContext(ThemeContext);
    const [selectedData, updateSelectedData] = useState(props.selectedData);

    function handleChange(event) {
        updateSelectedData(event.target.value);
    }

    const ITEM_HEIGHT = 50;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
            sx: {
                "& .MuiList-root": {
                  backgroundColor: darkMode ? "#4E606F" : "",
                },
              },
        },
    };

    return (
        <FormControl fullWidth>
            <InputLabel
                sx={{
                    color: darkMode ? "#fff" : "inherit",
                }}
            >
                {props.label}
            </InputLabel>
            <Select
                label={props.label}
                value={props.value}
                error={props.error}
                name={props.name}
                MenuProps={MenuProps}
                onChange={props.handleChange}
                disabled={props.disabled}
                sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: darkMode ? "#fff" : "inherit",
                    },
                    "& .MuiInputBase-input": {
                        color: darkMode ? "#fff" : "inherit",
                    },
                    "& .MuiSvgIcon-root": {
                        color: darkMode ? "#fff" : "inherit",
                    },
                }}
            >
                <MenuItem value="">
                    <em style={{ fontFamily: "inherit", fontStyle: "inherit" }}>
                        {props.defaultSelect}
                    </em>
                </MenuItem>
                {props.data
                    ? props.data.map((d, index) => (
                        <MenuItem key={index} value={d.id}>
                            <Typography variant="inherit" noWrap>
                                {d.name}
                            </Typography>
                        </MenuItem>
                    ))
                    : null}
            </Select>
        </FormControl>
    );
}

CustomDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    data: PropTypes.array,
    value: PropTypes.string,
    error: PropTypes.bool,
    name: PropTypes.string,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    defaultSelect: PropTypes.string,
    selectedData: PropTypes.string,
};

export default CustomDropdown;