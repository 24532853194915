import CommonUtil from '../../Util/CommonUtils'
import api from '../../config/http-common'

const createUser = (payload) => {
  return api.securedAxios().post('/api/v4/user', payload)
}

const updateUser = (payload) => {
  return api.securedAxios().put('/api/v4/users/' + payload.id, payload)
}

const getUserList = (payload) => {
  return api
    .securedAxios()
    .get(
      '/api/v4/users/' +
        localStorage.getItem('companyId') +
        '/list' +
        buildQuery(payload),
    )
}

const getUserById = (payload) => {
  return api.securedAxios().get('/api/v4/users/' + payload)
}

const resendUserActivationLink = (payload) => {
  return api.securedAxios().post('/api/v4/users/resendActivationLink', payload)
}

const deleteUser = (payload) => {
  return api.securedAxios().delete('/api/v4/users/' + payload)
}

const updatePassword = (payload) => {
  return api.securedAxios().put('/api/v4/users/' + payload.userId + '/password' , payload)
}

const buildQuery = (payload) => {
  let query =
    '?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=false&operator=AND&pageNumber=' +
    payload.page +
    '&pageSize=' +
    payload.rowsPerPage
  if (!CommonUtil.isEmptyString(payload.status)) {
    query = query + '&status=' + payload.status
  }
  return query
}

const userService = {
  createUser,
  updateUser,
  getUserList,
  deleteUser,
  resendUserActivationLink,
  getUserById,
  updatePassword
}

export default userService
