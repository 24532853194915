import { makeStyles } from '@material-ui/core';
import {
  Grid,
} from '@mui/material';
import isoCountries from 'i18n-iso-countries';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice';
import { getCompanyById } from '../../slices/Company/CompanySlice';
import CustomPhone from '../CustomInputs/CustomPhone';
import CustomTextfield from '../CustomInputs/CustomTextfield';
import Toast from '../ToastContainer/CustomToast';
import CommonUtil from '../Util/CommonUtils';
import DialogBox from './DialogBox';


const useStyles = makeStyles((theme) => ({
  dialogbox: {
    "& .css-hlj6pa-MuiDialogActions-root": {
      marginLeft: "290px !important",
    },
    '& .MuiDialog-paper': {
      minWidth: '110vh',
    },
  },
}));

function BulkInvitationEditDialog({ open, onClose, rowData, onSave }) {
  const classes = useStyles();
  const [firstName, setFirstName] = useState(rowData.firstName);
  const [lastName, setLastName] = useState(rowData.lastName);
  const [phone, setPhone] = useState(rowData.phone);
  const [companyName, setCompanyName] = useState(rowData.visitorCompanyName);
  const [emailId, setEmailId] = useState(rowData.emailId);
  const [phoneCountryCode, setPhoneCountryCode] = useState(rowData.phoneCountryCode);
  const [phoneNumber, setPhonenumber] = useState(rowData.phoneNumber)
  const [phoneInput, setPhoneInput] = useState(rowData.phone ? rowData.phone : "");
  const [companyNameStatus, setCompanyNameStatus] = useState('');
  const [companyNameMandatory, setCompanyNameMandatory] = useState('');
  const dispatch = useDispatch();
  const [country, setCountry] = useState('')
  const { t } = useTranslation()
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value);
    setPhoneCountryCode("+" + data.dialCode);
    setPhonenumber(value.toString().replace(data.dialCode, ""));
    setPhone(phoneCountryCode + "-" + phoneNumber);
  };

  const handleSave = () => {
    if (firstName === "") {
      Toast(t('USERPROFSCREEN007'), "error")
      return;
    }

    if (emailId === "" && phoneNumber === "") {
      Toast(t('ADD043'), "error");
      return;
    }

    if (!CommonUtil.isValidEmail(emailId)) {
      Toast(t('CORRECTEMAIL'), 'error')
      return;
    }
    if (companyNameMandatory && companyName === "") {
      Toast(t('DEALERCOMPANYERROR'), "error")
      return;
    }

    onSave({
      ...rowData,
      firstName,
      emailId,
      phoneNumber,
      phoneCountryCode,
      companyName,
      phone
    })
    Toast(t('ADD044'), "success")
    onClose();
  }

  useEffect(() => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0000") {
          setCompanyNameStatus(data.data.invitationCompanyName);
          setCompanyNameMandatory(data.data.checkInvitationCompanyName);
        }
      })
  }, [])

  useEffect(() => {
    if (phoneInput === '' || phoneInput === null) {
      loadCompany()
    }
  })

  const loadCompany = () => {
    dispatch(getCompanyById(decoded_jwtToken.companyId))
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setCountry(countryCode)
          } else {
            setCountry('us')
          }
        }
      })
      .catch((er) => { })
  }
  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }


  return (
    <>
      <DialogBox
        className={classes.dialogbox}
        Header={t('ADD042')}
        acceptText={t('COMMON007')}
        cancelText={t('COMMON008')}
        onSubmit={handleSave}
        open={open}
        onClose={onClose}
        cancel={onClose}
      >

        <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>


          <Grid sm={8} container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={"text"}
                name="FirstName"
                label={t('COMMON020')}
                value={firstName}
                required={true}
                handleChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={"text"}
                name="Email"
                label={t('COMMON042')}
                value={emailId}
                required={true}
                handleChange={(e) => setEmailId(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={(Number(!companyNameStatus)) % 2 === 2 ? 12 : 6} style={{ margin: '16px 0px' }}>
              <CustomPhone
                placeholder="Mobile"
                value={phoneInput}
                country={country}
                specialLabel={false}
                handleChange={(value, data, event, formattedValue) =>
                  handlePhoneChange(value, data, event, formattedValue)
                }
              />
            </Grid>
            {companyNameStatus ? (<Grid item xs={12} sm={6}>
              <CustomTextfield
                type={"text"}
                name="CompanyName"
                label={companyNameMandatory ? t('VISUPDATE0006') + '*'
                  : t('VISUPDATE0006')}
                value={companyName}
                handleChange={(e) => setCompanyName(e.target.value)}
                disabled={companyName ? true : false}
              />
            </Grid>) : ""}
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}

export default BulkInvitationEditDialog