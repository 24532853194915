import api from "../../config/http-common";

const buildQuery = (filter) => {
    let query = "&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    return query;
};

const getAllHealthQuestionarie = (filter) => {
    return api.securedAxios().get("/web/getHealthQuestionarie?templateId=" + filter.templateId + buildQuery(filter));
  }

const addHealthQuestionarie = (payload) => {
    return api.securedAxios().post("/web/createHealthQuestionarie", payload);
}

const updateHealthQuestionarie = (payload) => {
    return api.securedAxios().put("/web/updateHealthQuestionarie/" + payload.id, payload);
}

const deleteHealthQuestionarie = (payload) => {
    return api.securedAxios().delete("/web/deleteHealthQuestionarie?id=" + payload);
}

const healthQuestionarieService = {
    getAllHealthQuestionarie,
    addHealthQuestionarie,
    updateHealthQuestionarie,
    deleteHealthQuestionarie,
}

export default healthQuestionarieService;