import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import userService from '../../services/User/UserService'

const initialState = []

export const createUser = createAsyncThunk(
  'user/createUser',
  async (payload) => {
    const res = await userService.createUser(payload)
    return res.data
  },
)

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (payload) => {
    const res = await userService.updateUser(payload)
    return res.data
  },
)

export const getUserList = createAsyncThunk(
  'users/getUserList',
  async (payload) => {
    const res = await userService.getUserList(payload)
    return res.data
  },
)

export const getUserById = createAsyncThunk(
  'users/getUserById',
  async (payload) => {
    const res = await userService.getUserById(payload)
    return res.data
  },
)

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (payload) => {
    const res = await userService.deleteUser(payload)
    return res.data
  },
)

export const resendUserActivationLink = createAsyncThunk(
  'resendActivationLink',
  async (payload) => {
    const res = await userService.resendUserActivationLink(payload)
    return res.data
  },
)

export const updatePassword = createAsyncThunk('/updatePassword' , async(payload) => {
  const res = await userService.updatePassword(payload)
  return res.data;
})