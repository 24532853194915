import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import jwt_decode from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import iot from '../../assets/MIOTLogo/miotlogo.png'
import USA_visLogo from '../../assets/USA_Images/login_images/App-logo.svg'
import { ReactComponent as USAPassIcon } from '../../assets/USA_Images/login_images/pass_icon.svg'
import { ReactComponent as USAUserIcon } from '../../assets/USA_Images/login_images/userIcon.svg'
import { ReactComponent as UserIcon } from '../../assets/UserIcon.svg'
import visLogo from '../../assets/images/onboard/vis-logo.png'
import { ReactComponent as PassIcon } from '../../assets/passIcon.svg'
import USACustomTextfield from '../../components/CustomInputs/USACustomTextfield'
import Toast from '../../components/ToastContainer/CustomToast'
import CommonUtil from '../../components/Util/CommonUtils'
import httpCommon from '../../config/http-common'
import { authorizeUser, switchCompany } from '../../slices/onboard/LoginSlice'
import LoginLayoutV2 from './LoginLayoutV2'
import './custom.css'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    marginTop: theme.spacing(3),
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props
  return (
    <DialogTitle variant="h4" {...other}>
      {children}
      {onClose ? (
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 3,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

function LoginFormForHost(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const [values, setValues] = useState({
    email: '',
    password: '',
    companyId: '',
    showPassword: false,
  })
  const [code, setCode] = useState('')
  const [companyIdPresent, setCompanyIdPresent] = useState('')
  const [error, setError] = useState({
    email: false,
    password: false,
    companyId: false,
  })
  const [modes, setModes] = useState({
    invitation: '',
    walkInRegistration: '',
    selfRegistration: '',
    visitId: '',
  })

  const store = useSelector((state) => state)

  const query = new URLSearchParams(props.location.search)
  useEffect(() => {
    loadData()
  }, [history])

  const loadData = () => {
    var urlValue = window.location.href
    var parts = urlValue.split('/')
    var lastPart = parts[parts.length - 1]
    var code = lastPart.split('=')[1]
    setCode(code)
    httpCommon
      .unsecuredAxios()
      .get('/web/visit/validate/admin/' + code)
      .then((res) => {
        const data = res.data
        if (data.code === 'CVSI0000') {
          setCompanyIdPresent(data.data.companyId)
          setModes((prevModes) => ({ ...prevModes, visitId: data.data.id }))
          if (data.data.visitMode === 'INVITATION') {
            setModes((prevModes) => ({
              ...prevModes,
              invitation: 'INVITATION',
            }))
          } else if (data.data.visitMode === 'WALKIN_REGISTARTION') {
            setModes((prevModes) => ({
              ...prevModes,
              walkInRegistration: 'WALKIN_REGISTARTION',
            }))
          } else if (data.data.visitMode === 'SELF_REGISTARTION') {
            setModes((prevModes) => ({
              ...prevModes,
              selfRegistration: 'SELF_REGISTARTION',
            }))
          }
        }
        if (data.code === 'CVAE0074' || data.code === 'CVSE0061') {
          Toast(data.message, 'error')
          history.push('/vis/result-page')
        }
      })
  }

  const handleChange = (event) => {
    const value = event.target.value
    const name = event.target.name

    setValues({
      ...values,
      [name]: value,
    })
    setError({
      [name]: false,
    })
  }

  const handleClickShowPassword = (event) => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const passwordToHexa = (password) => {
    let hashPassword = ''
    for (let i = 0; i <= password.length; i++) {
      const passowrdHexaValue = password.charCodeAt(i).toString(16)
      // Pad with zero's to ensure two-digit representation
      hashPassword += passowrdHexaValue.padStart(2, '0')
    }
    return hashPassword
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (CommonUtil.isEmptyString(values.email)) {
      setError({ ...error, email: true })
      Toast(t('COMMON053'), 'error')
      return
    }

    if (CommonUtil.isEmptyString(values.password)) {
      setError({ ...error, password: true })
      Toast(t('REGISTERUSER0002'), 'error')
      return
    }

    const user = {
      userName: values.email,
      password: passwordToHexa(values.password).replace('NaN', ''),
    }

    dispatch(authorizeUser(user))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          localStorage.setItem(
            process.env.REACT_APP_TEMP_TOKEN,
            'Bearer ' + data.data.access_token,
          )
          const decoded = jwt_decode(data.data.access_token)
          localStorage.setItem('applicationId', decoded.client_id)
          VerifyCompany()
        } else {
          // Toast((data.message), 'error');
          onFail(data)
        }
      })

      .catch((er) => {})
  }

  const VerifyCompany = () => {
    const payload = {
      companyId: companyIdPresent,
    }
    dispatch(switchCompany(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0011') {
          localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN)
          localStorage.setItem(
            process.env.REACT_APP_ACCESS_TOKEN,
            'Bearer ' + data.data.access_token,
          )
          localStorage.setItem(
            process.env.REACT_APP_REFRESH_TOKEN,
            data.data.refresh_token,
          )
          const decoded = jwt_decode(data.data.access_token)
          localStorage.setItem('companyId', decoded.companyId)
          localStorage.setItem('companyName', decoded.companyName)
          localStorage.setItem('companyCode', decoded.companyCode)
          history.push({
            pathname: '/vis/approvals',
            state: { modes },
          })
        } else {
          toast.error(data.message)
        }
      })
      .catch((er) => {})
  }

  const onFail = (data) => {
    Toast(t(data.message), 'error')
  }

  const [userAgreement, setUserAgreement] = React.useState(false)
  const [termsCondition, setTermsCondition] = React.useState(false)
  const [init, setInit] = useState({
    userAgreement: true,
    termsCondition: true,
  })

  const handleContextMenu = (event) => {
    event.preventDefault()
  }

  const handleOnPasteEvent = (event) => {
    event.preventDefault()
  }

  const handleOnCopyEvent = (event) => {
    event.preventDefault()
  }

  const handleOnCutEvent = (event) => {
    event.preventDefault()
  }
  const maskValue = (value) => {
    return '*'.repeat(value.length)
  }
  return !CommonUtil.isEmptyString(query.get('code')) ? (
    <></>
  ) : (
    <>
      <LoginLayoutV2 lang={true}>
        <Grid className="center_div">
          <Grid container alignItems="center">
            <Grid item className="logo-center">
              <img
                src={
                  process.env.REACT_APP_ENVIRONMENT === 'USA'
                    ? USA_visLogo
                    : visLogo
                }
                alt="logo"
                style={{ width: '250px' }}
              />
            </Grid>
          </Grid>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid item xs={12}>
              <Typography
                variant="h2"
                align="left"
                style={{
                  color:
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? '#fff'
                      : '#464646',
                  fontWeight: '500',
                  marginTop: '20px',
                  opacity: '0.9',
                }}
              >
                {t('LOGINFORM0002')}
              </Typography>
              <Typography
                variant="subtitle2"
                align="left"
                style={{
                  color:
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? '#fff'
                      : '#7a7b97',
                  marginBottom: '20px',
                  marginTop: '6px',
                  fontSize: '12px',
                  opacity: '0.8',
                }}
              >
                {t('LOGINPAGE001')}
              </Typography>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs style={{ display: 'flex' }}>
                <Grid className="company_icon">
                  {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
                    <USAUserIcon />
                  ) : (
                    <UserIcon className="visibleIcons" />
                  )}
                </Grid>
                <USACustomTextfield
                  type={'text'}
                  error={error.email}
                  name="email"
                  label={t('LOGINPAGE002') + ' *'}
                  value={values.email}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.email}
                  validation="email"
                  InputLabelProps={{ shrink: true }}
                  onContextMenu={(e) => handleContextMenu(e)}
                  onPaste={(e) => handleOnPasteEvent(e)}
                  onCopy={(e) => handleOnCopyEvent(e)}
                  onCut={(e) => handleOnCutEvent(e)}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs style={{ display: 'flex' }}>
                <Grid className="company_icon">
                  {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
                    <USAPassIcon />
                  ) : (
                    <PassIcon className="visibleIcons" />
                  )}
                </Grid>
                <USACustomTextfield
                  type={values.showPassword ? 'text' : 'password'}
                  error={error.password}
                  name="password"
                  label={t('COMMON047') + ' *'}
                  value={values.password}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.password}
                  validation="password"
                  InputLabelProps={{ shrink: true }}
                  onContextMenu={(e) => handleContextMenu(e)}
                  onCopy={(e) => handleOnCopyEvent(e)}
                  onPaste={(e) => handleOnPasteEvent(e)}
                  onCut={(e) => handleOnCutEvent(e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          className="PasswordIcon"
                        >
                          {values.showPassword ? (
                            <Visibility className="visibleIcons" />
                          ) : (
                            <VisibilityOff className="visibleIcons" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs style={{ display: 'flex' }}>
                <Grid className="company_icon">
                  {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
                    <USAUserIcon />
                  ) : (
                    <UserIcon className="visibleIcons" />
                  )}
                </Grid>
                <USACustomTextfield
                  type={'text'}
                  label={'Company ID'}
                  value={companyIdPresent ? maskValue(companyIdPresent) : ''}
                  readOnly
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center"></Grid>
            <Grid container spacing={5} alignItems="center">
              <Grid item xs>
                <Button type="submit" fullWidth variant="contained">
                  {process.env.REACT_APP_ENVIRONMENT === 'USA'
                    ? t('LOGINFORM0002')
                    : t('COMMON038')}
                </Button>
              </Grid>
            </Grid>
          </form>
          {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
            <></>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs>
                <Box mt={3} className="signIn">
                  <hr className="hrline_Left" />
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    align="center"
                    style={{ fontSize: '12px' }}
                  >
                    {t('LOGINFORM0003')}
                  </Typography>
                  <hr className="hrline_Right" />
                </Box>
              </Grid>
            </Grid>
          )}
          {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
            <></>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs>
                <Box mt={1} align="center">
                  <IconButton
                    className="icon_button"
                    // onClick={() => dispatch(redirectLogin())}
                  >
                    <img src={iot} alt="iotlogo" className="social_icons" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          )}
          {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
            <></>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs>
                <Box align="center">
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    align="center"
                    style={{ fontSize: '12px', paddingBottom: '15px' }}
                  >
                    {t('LOGINFORM0004')}{' '}
                    <Link href="/vis/signup" className="forgot_password">
                      {' '}
                      {t('LOGINFORM0005')}
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </LoginLayoutV2>
    </>
  )
}
export default withRouter(LoginFormForHost)
