import { Grid, makeStyles } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import CommonUtil from '../../Util/CommonUtils';
import CustomerDialog from '../../components/DialogBoxComponent/CustomerDialog';
import Toast from "../../components/ToastContainer/CustomToast";
import { listOfDealerDetalisWithoutStatus } from '../../slices/Dealer/DealerSlice';
import { useTranslation } from 'react-i18next';
import BrowserLanguage from '../../components/Util/BrowserLanguage'


let language = BrowserLanguage.getDefaultLanguage()


const useStyles = makeStyles((theme) => ({
    dialogbox: {
        '& .MuiDialog-paper': {
            // minWidth: '110vh',
            minWidth: "600px",
            // marginLeft:"250px",
        },
        ".& .css-f3jnds-MuiDataGrid-columnHeaders": {
            backgroundColor: "#F3F9FB"
        },
    },
}))

function DealerTransactionAndReportExports(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setopen] = useState(props.open)
    const { handleClose } = props
    const [payload, setPayload] = useState({
        downloadExcel: "",
    });
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const [error, setError] = useState({
        downloadExcel: false,
    });
    const type = props.type;

    useEffect(() => {
        dispatch(listOfDealerDetalisWithoutStatus(''))
            .unwrap()
            .then((res) => {
                if (res.code === "MSGS4007") {
                    setRows(res.data.companies);
                }
            })
    }, [])


    const handleSubmit = () => {
        if (payload.downloadExcel === "") {
            setError({ downloadExcel: true });
            Toast(t('EXPORTERROR'), "error");
            return;
        }
        handleDownload();
        handleClose();
    };

    const handleDownload = async () => {
        const modifieData = customizeData(DealerTransactionReports, type);
        const ws = XLSX.utils.json_to_sheet(modifieData);
        let columnWidths = null;
        if (type === 'dealerTransaction') {
            columnWidths = [{ wch: 20 }, // CompanyName
            { wch: 20 }, // Email
            { wch: 15 }, // Phone
            ]
        } else {
            columnWidths = [
                { wch: 20 }, // CompanyName
                { wch: 20 }, // Email
                { wch: 15 }, // Phone
                { wch: 20 }, // AdminName
                { wch: 20 }, // transactionIdHq
                { wch: 20 }, // lastPaymentReceivedDate
                { wch: 20 }, // lastPaymentReceivedAmount
                { wch: 20 }, // planBasePrice
                { wch: 20 },//customerName
            ];

        }
        ws['!cols'] = columnWidths;
        const fileName = type === 'dealerTransaction' ? 'Dealer Transaction Details' : 'Dealer Report Details';
        let wb = null;
        if (type === 'dealerTransaction') {
            wb = { Sheets: { 'Dealer Transaction Report': ws }, SheetNames: ['Dealer Transaction Report'] };
        } else {
            wb = { Sheets: { 'Dealer Report': ws }, SheetNames: ['Dealer Report'] };
        }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
        const fileExtension = '.xlsx';
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        Toast(t('EXPORT_SUCCESS'), 'success');
    }

    let customizeData = null;
    if (language === 'es') {
        customizeData = (data, type) => {
            return data.map(item => {
                if (type === 'dealerTransaction') {
                    return {
                        'nombre de empresa': item.name,
                        'Correo electrónico': item.email,
                        Móvil: item.phone,
                    };
                } else {
                    let customerName = '';

                    if (Array.isArray(item.customerName)) {
                        customerName = item.customerName.filter(Boolean).join(', ');
                    } else if (item.customerName) {
                        customerName = item.customerName;
                    }
                    return {
                        'nombre de empresa': item.name,
                        'Nombre del administrador': item.adminName,
                        'Correo electrónico': item.email,
                        Móvil: item.phone,
                        'ID de transacción': item.transactionID,
                        'fecha de pago recibido': item.paymentReceivedDate,
                        'Pago recibido Monto': item.paymentReceivedAmount,
                        'Próxima fecha de vencimiento del pago': item.nextPaymentDuedate,
                        'Próximo monto del pago': item.nextPaymentAmount,
                        'Nombre del cliente': customerName,
                    };
                }
            });
        };

    } else {
        customizeData = (data, type) => {
            return data.map(item => {
                if (type === 'dealerTransaction') {
                    return {
                        'Company Name': item.name,
                        Email: item.email,
                        Mobile: item.phone,
                    };
                } else {
                    let customerName = '';

                    if (Array.isArray(item.customerName)) {
                        customerName = item.customerName.filter(Boolean).join(', ');
                    } else if (item.customerName) {
                        customerName = item.customerName;
                    }
                    return {
                        'Company Name': item.name,
                        'Admin Name': item.adminName,
                        Email: item.email,
                        Mobile: item.phone,
                        'Transaction ID': item.transactionID,
                        'Payment received date': item.paymentReceivedDate,
                        'Payment Received Amount': item.paymentReceivedAmount,
                        'Next Payment Due date': item.nextPaymentDuedate,
                        'Next Payment Amount': item.nextPaymentAmount,
                        'Customer Name': customerName,
                    };
                }
            });
        };

    }
    const onselectDownload = (event) => {
        setPayload({
            ...payload,
            downloadExcel: event,
        });
    };

    const DealerTransactionReports = rows;

    const options = [
        { label: t('EXCEL_DROP_DOWN') }
    ]
    return (
        <>
            <CustomerDialog
                className={classes.dialogbox}
                Header={t('DOWNLOADHEADER')}
                acceptText={t('DOWNLOADHEADER')}
                cancelText={t('COMMON008')}
                onSubmit={handleSubmit}
                open={open}
                onClose={handleClose}
                cancel={handleClose}
            >

                <Grid container
                    style={{ padding: "20px", width: "100%" }}
                    spacing={2}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={options}
                            value={payload.downloadExcel}
                            onChange={(event, newValue) => {
                                if (!CommonUtil.isEmpty(newValue)) {
                                    onselectDownload(newValue);
                                } else {
                                    onselectDownload('');
                                }
                                setError({ downloadExcel: false })
                            }}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label={t('SELECTEXCELFORMAT')} />}
                        />
                    </Grid>
                </Grid>

            </CustomerDialog>

        </>
    )
}

export default DealerTransactionAndReportExports