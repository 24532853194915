import { createAsyncThunk } from "@reduxjs/toolkit";
import SmsTemplateService from "../../services/SmsTemplate/SmsTemplateService";


export const getSmsTemplate = createAsyncThunk('SmsTemplate' , async(payload) => {
    const res = await SmsTemplateService.getSmsTemplate(payload);
    return res.data;
})

export const updateSmsTemplate = createAsyncThunk("upodate" , async(payload) =>{
    const res = await SmsTemplateService.updateSmsTemplate(payload);
    return res.data;
})