import { Box, Button, Grid, Link, Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as XLSX from 'xlsx'
import filterIcon from '../../assets/Customer/Filter.svg'
import forwardIcon from '../../assets/Customer/forward.svg'
import DataTable from '../../components/DataTable/DataTable'
import ExportDialog from '../../components/DialogBoxComponent/ExportDialog'
import PurchaseHistoryFilter from '../../components/DialogBoxComponent/PurchaseHistoryFilter'
import UpGradeDialogBox from '../../components/DialogBoxComponent/UpGradeDialogBox'
import HeaderToolbar from '../../components/HeaderToolbar'
import Search from '../../components/SearchTab/Search'
import Toast from '../../components/ToastContainer/CustomToast'
import BrowserLanguage from '../../components/Util/BrowserLanguage'
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { getPurchasedPlans } from '../../slices/Subscription/SubscriptionSlice'
import CommonUtil from '../../Util/CommonUtils'

let language = BrowserLanguage.getDefaultLanguage()

function PlanPurchased() {
  const { t } = useTranslation()
  const [clear, setClear] = React.useState(false)
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [openFilter, setOpenFilter] = useState(false)
  const [openexport, setOpenExport] = useState(false)
  const [openUpGrade, setOpenUpGrade] = useState(false)
  const [rowData, setRowData] = useState({})
  const [date, setDate] = useState()
  const companyId = localStorage.getItem('companyId')
  const [filter, setFilter] = useState({
    subscriptionName: '',
    planAssigned: '',
    startDate: '',
    endDate: '',
  })
  const [paging, setPaging] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  const defaultFilter = {
    subscriptionName: '',
    planAssigned: '',
    startDate: '',
    endDate: '',
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging])

  const loadData = (filter, paging) => {
    let payload = {
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
      subscriptionName: filter.subscriptionName,
      planAssigned: filter.planAssigned,
      startDate: filter.startDate,
      endDate: filter.endDate,
    }
    dispatch(getPurchasedPlans(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4035') {
          setLoading(false)
          setRows(res.data.purchasedPlans)
          setTotalPages(res.data.totalPages)
          setTotalRecords(res.data.totalCount)
          setPage(res.data.currentPage)
        } else {
          setRows([])
        }
      })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...'
    } else {
      return text
    }
  }

  useEffect(() => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setDate(formatDate(data.data.adminAndDealerDateFormat))
        }
      })
  }, [])
  const formatDate = (dateFormat) => {
    switch (dateFormat) {
      case 'DD-MM-YYYY':
        return 'dd-MM-yyyy HH:mm';
      case 'MM-DD-YYYY':
        return 'MM-dd-yyyy HH:mm';
      case 'YYYY-MM-DD':
        return 'yyyy-MM-dd HH:mm';
      default:
        return 'yyyy-MM-dd HH:mm';
    }
  };

  const handleSelect = (params) => {
    if (!params.planAssigned) {
      Toast(t('PLAN_NOT_ASSIGNED'), 'error')
      return
    }
    setRowData(params)
    setOpenUpGrade(!openUpGrade)
  }

  const renderCellStartDate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.startDate)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const renderCellEndDate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.endDate)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const columns = React.useMemo(() => [
    {
      field: 'subscriptionName',
      headerName: t('SUBSCRIPTIONPLANNAME'),
      minWidth: 190,
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.subscriptionName}`}
          placement="bottom-start"
        >
          <span>{handleTextOverflow(params.row.subscriptionName)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'startDate',
      headerName: t('FILTERSTARTDATE'),
      minWidth: 190,
      flex: 1,
      renderCell: renderCellStartDate(date)
    },
    {
      field: 'endDate',
      headerName: t('FILTERENDDATE'),
      minWidth: 190,
      flex: 1,
      renderCell: renderCellEndDate(date)
    },
    {
      field: 'price',
      headerName: t('CUSTDATABLE08'),
      minWidth: 150,
      flex: 1,
      renderCell: (params) => params.value.unit_amount_decimal / 100,
    },
    {
      field: 'discount',
      headerName: t('DISCOUNT'),
      minWidth: 150,
      flex: 1,
      renderCell: (params) =>
        params.row.discount ? params.row.discount.discountValue + '%' : '',
    },
    {
      field: 'subscriptionMode',
      headerName: t('SUBSCRIPTIONMODE'),
      minWidth: 190,
      flex: 1,
      renderCell: (params) =>
        params.row.price.recurring.interval === 'year'
          ? t('YEARLY_UPPER_CASE')
          : params.row.price.recurring.interval === 'month'
            ? t('MONTHLY')
            : '',
    },
    {
      field: 'maxVisitsCreation',
      headerName: t('VISIT_COUNT'),
      minWidth: 190,
      flex: 1,
    },
    {
      field: 'comapany',
      headerName: t('PLANASSIGNED'),
      minWidth: 170,
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.assignedCompanyName}`}
          placement="bottom-start"
        >
          <span>{handleTextOverflow(params.row.assignedCompanyName)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'planAssigned',
      headerName: t('CUSTDATABLE07'),
      minWidth: 150,
      flex: 1,
      renderCell: (params) =>
        params.value ? t('PLAN_ASSIGNED') : t('PLAN_UN_ASSIGNED'),
    },
    {
      field: 'Action',
      headerName: t('PLAN_ACTION'),
      type: 'action',
      flex: 1,
      minWidth: 100,
      width: 100,
      headerAlign: 'start',
      renderCell: (params) => (
        <Button
          style={{
            backgroundColor: '#0F5A9C26',
            height: '5px',
            color: '#0F5A9C',
          }}
          onClick={() => handleSelect(params.row)}
        >
          {t('UPGARDE')}
        </Button>
      ),
    },
  ], [date])

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setPaging({
      ...paging,
      pageNumber: newPage,
      pageSize: size,
    })
  }

  const globalsearch = (searchedValue) => {
    const lowerCasedSearchedValue = searchedValue.toLowerCase()

    const filteredRows = rows.filter((value) => {
      const subscriptionNameMatch = value.subscriptionName
        ? value.subscriptionName.toLowerCase().includes(lowerCasedSearchedValue)
        : false

      const priceAmountMatch =
        value.price && value.price.unit_amount_decimal
          ? value.price.unit_amount_decimal.toString().includes(searchedValue)
          : false

      const subscriptionModematch =
        value.price && value.price.recurring.interval
          ? (value.price.recurring.interval.toLowerCase() === 'month' &&
            lowerCasedSearchedValue.includes('month')) ||
          (value.price.recurring.interval.toLowerCase() === 'year' &&
            lowerCasedSearchedValue.includes('year'))
          : false

      const visitCountMatch = value.maxVisitsCreation
        ? value.maxVisitsCreation.toString().includes(searchedValue)
        : false

      const discountMatch =
        value.discount && value.discount.discountValue
          ? value.discount.discountValue.toString().includes(searchedValue)
          : false

      const assignedCompanyMatch = value.assignedCompanyName
        ? value.assignedCompanyName
          .toString()
          .toLowerCase()
          .includes(lowerCasedSearchedValue)
        : false

      const planAssignedMatch =
        lowerCasedSearchedValue === 'assigned'
          ? value.planAssigned === true
          : lowerCasedSearchedValue === 'unassigned'
            ? value.planAssigned === false
            : false

      return (
        subscriptionNameMatch ||
        planAssignedMatch ||
        assignedCompanyMatch ||
        priceAmountMatch ||
        subscriptionModematch ||
        visitCountMatch ||
        discountMatch
      )
    })

    setRows(filteredRows)
  }

  const handlePlanFilter = () => {
    setOpenFilter(!openFilter)
  }

  const handleFilter = (data) => {
    setLoading(true)
    setClear(true)
    setFilter({
      ...filter,
      planAssigned:
        data.status === 'Assigned'
          ? true
          : data.status === 'Unassigned'
            ? false
            : '',
      startDate: CommonUtil.formatToUtc(data.startDate),
      endDate: CommonUtil.formatToUtc(data.endDate),
    })
    console.log(filter)
  }

  const resetFilter = () => {
    setLoading(true)
    setFilter({
      ...defaultFilter,
    })
    setClear(false)
  }
  let headers = null
  if (language === 'en') {
    headers = [
      { header: 'Plan Name', key: 'subscriptionName', width: 20 },
      { header: 'Start Date', key: 'startDate', width: 30 },
      { header: 'End Date', key: 'endDate', width: 25 },
      { header: 'Price', key: 'price', width: 20 },
      { header: 'Discount', key: 'discount', width: 30 },
      { header: 'Subscription Mode', key: 'subscriptionMode', width: 30 },
      { header: 'Visit Count', key: 'maxVisitsCreation', width: 25 },
      { header: 'Plan Assigned', key: 'assignedCompanyName', width: 30 },
      { header: 'Status', key: 'planAssigned', width: 25 },
    ]
  } else {
    headers = [
      { header: 'Nombre del plan', key: 'subscriptionName', width: 20 },
      { header: 'Fecha de inicio', key: 'startDate', width: 30 },
      { header: 'Fecha final', key: 'endDate', width: 25 },
      { header: 'Precio', key: 'price', width: 20 },
      { header: 'Descuento', key: 'discount', width: 30 },
      { header: 'Modo de suscripción', key: 'subscriptionMode', width: 30 },
      { header: 'Conteo de visitas', key: 'maxVisitsCreation', width: 25 },
      { header: 'Plan asignado', key: 'assignedCompanyName', width: 30 },
      { header: 'Estado', key: 'planAssigned', width: 25 },
    ]
  }

  const handleExport = () => {
    if (rows.length === 0) {
      Toast(t('dataTableEmptyList'), 'error')
      return
    }
    setPaging({
      pageNumber: 1,
      pageSize: 1000,
    })
    setOpenExport(!openexport)
  }

  const exportToExcel = () => {
    if (rows.length === 0) {
      Toast(t('dataTableEmptyList'), 'error')
      return
    }
    const excelRows = rows.map((item) => ({
      subscriptionName: item.subscriptionName,
      startDate: date === 'dd-MM-yyyy HH:mm' ? moment(item.startDate).format('DD-MM-YYYY HH:mm') : date === 'MM-dd-yyyy HH:mm' ? moment(item.startDate).format('MM-DD-YYYY HH:mm') : date === 'yyyy-MM-dd HH:mm' ? moment(item.startDate).format('YYYY-MM-DD HH:mm') : '',
      endDate: date === 'dd-MM-yyyy HH:mm' ? moment(item.endDate).format('DD-MM-YYYY HH:mm') : date === 'MM-dd-yyyy HH:mm' ? moment(item.endDate).format('MM-DD-YYYY HH:mm') : date === 'yyyy-MM-dd HH:mm' ? moment(item.endDate).format('YYYY-MM-DD HH:mm') : '',
      price: item.price.unit_amount_decimal,
      discount: item.discount ? item.discount.discountValue.toString() : '',
      subscriptionMode:
        item.price.recurring.interval === 'month'
          ? 'Month'
          : item.price.recurring.interval === 'year'
            ? 'Year'
            : '',
      maxVisitsCreation: item.maxVisitsCreation,
      assignedCompanyName: item.assignedCompanyName,
      planAssigned: item.planAssigned ? 'Assigned' : 'Unassigned',
    }))

    const ws = XLSX.utils.json_to_sheet(excelRows)
    // Add headers manually with bold and center alignment
    const headerRow = headers.map((h) => h.header)
    XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' })

    // Set the column widths
    ws['!cols'] = headers.map((h) => ({ wch: h.width }))
    const wb = { Sheets: { 'Plan details': ws }, SheetNames: ['Plan details'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    const fileExtension = '.xlsx'
    const blobData = new Blob([excelBuffer], { type: fileType }) // Change variable name to blobData
    FileSaver.saveAs(blobData, 'Purchased plans' + fileExtension) // Use blobData instead of data
    Toast(t('PURCHASED_EXPORT'), 'success')
    setOpenExport(false)

    // // Create a new worksheet without headers
    // const worksheet = XLSX.utils.json_to_sheet(excelRows, { skipHeader: true })
    // // Add headers manually with bold and center alignment
    // const headerRow = headers.map((h) => h.header)
    // XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: 'A1' })
    // // Set the column widths
    // worksheet['!cols'] = headers.map((h) => ({ wch: h.width }))

    // // Apply styles to the header row
    // const headerRange = XLSX.utils.decode_range(
    //   'A1:' + XLSX.utils.encode_col(headers.length - 1) + '1',
    // )
    // for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
    //   const cell_address = XLSX.utils.encode_cell({ r: 0, c: C })
    //   if (!worksheet[cell_address]) continue
    //   worksheet[cell_address].s = {
    //     font: { bold: true },
    //     alignment: { horizontal: 'center' },
    //   }
    // }

    // // Apply center alignment to all data cells
    // const dataRange = XLSX.utils.decode_range(worksheet['!ref'])
    // for (let R = 1; R <= excelRows.length; ++R) {
    //   for (let C = 0; C < headers.length; ++C) {
    //     const cell_address = XLSX.utils.encode_cell({ r: R, c: C })
    //     if (!worksheet[cell_address]) continue
    //     if (!worksheet[cell_address].s) worksheet[cell_address].s = {}
    //     worksheet[cell_address].s.alignment = { horizontal: 'center' }
    //   }
    // }

    // // Create a new workbook and append the worksheet
    // const workbook = XLSX.utils.book_new()
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Plan details')

    // // Generate the Excel file buffer
    // const excelBuffer = XLSX.write(workbook, {
    //   bookType: 'xlsx',
    //   type: 'array',
    // })

    // // Create a Blob from the buffer and save the file
    // const blob = new Blob([excelBuffer], {
    //   type:
    //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    // })
    // saveAs(blob, 'Purchased plans.xlsx')

    // Toast(t('PURCHASED_EXPORT'), 'success');
    // setOpenExport(false)
  }

  return (
    <>
      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}
      <HeaderToolbar
        title={t('PURCHASED_PLAN_HEADER')}
        style={{ width: '100%' }}
      />

      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item style={{ margin: '0 8px' }} onClick={handlePlanFilter}>
          <Tooltip title={t('ADD036')}>
            <img src={filterIcon} />
          </Tooltip>
        </Box>

        <Box item style={{ margin: '0 8px' }} onClick={handleExport}>
          <Tooltip title={t('ADD037')}>
            <img src={forwardIcon} />
          </Tooltip>
        </Box>

        <Box item>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: '100%', marginTop: '15px' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          pageSize={rowsPerPage}
          count={totalRecords}
          pageCount={totalPages}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openFilter && (
        <PurchaseHistoryFilter
          open={openFilter}
          handleClose={() => {
            setOpenFilter(false)
          }}
          onSave={handleFilter}
          date={date}
        />
      )}

      {openexport && (
        <ExportDialog
          open={openexport}
          handleClose={() => {
            setOpenExport(false)
          }}
          handleSubmit={exportToExcel}
        />
      )}

      {openUpGrade && (
        <UpGradeDialogBox
          open={openUpGrade}
          handleClose={() => {
            setOpenUpGrade(false)
            loadData(filter, paging)
          }}
          data={rowData}
        />
      )}
    </>
  )
}

export default PlanPurchased
