import React, { Fragment, useState, useEffect, useContext } from 'react'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Hidden, Drawer, Paper, Box, Link } from '@material-ui/core'
import { connect } from 'react-redux'
import SidebarHeader from '../SidebarHeader'
import navItems from './navItems'
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions'
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import '@trendmicro/react-sidenav/dist/react-sidenav.css'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import jwt_decode from 'jwt-decode'
import navItemUSA from './navItemUSA'
import { IconButton } from '@mui/material'
import MenuIcon from '@material-ui/icons/Menu'
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints'
import logo_smartgurd from '../../assets/USA_Images/login_images/App-logo.svg'
import logo_mbl from '../../assets/USA_Images/login_images/logo_mbl.svg'
import '../../assets/layout-components/Sidebar/custom_sidebar.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import navItemDealer from './navItemDealer'
import navItemZlink from './navItemZlink'
import CheckInwithpinIcon from '../../../src/assets/USA_Images/dashboard_icons/CheckInWithPin.svg'
import doorIcon from '../../../src/assets/USA_Images/dashboard_icons/doorImage.svg'
import PinDialogBox from '../../components/DialogBoxComponent/PinDialogBox'
import Tooltip from '@mui/material/Tooltip'
import navItemEmployeelogin from './navItemEmployeelogin'
import navItemFrontEnd from './navItemFrontEnd'
import { useTranslation } from 'react-i18next'
import navItemCustomer from './navItemCustomer'
import EmergencyDoor from '../../components/DialogBoxComponent/EmergencyDoor'
import { useDispatch } from 'react-redux'
import { ThemeContext } from '../../theme/ThemeContext';
import {
  customerSubscription,
  getSubscriptionById,
  listPaymentMethods,
} from '../../slices/Subscription/SubscriptionSlice'
import { getDealerSubscriptions } from '../../slices/SubscriptionDealerSlice'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function Sidebar({
  setSidebarToggleMobile,
  sidebarToggleMobile,
  sidebarShadow,
  ...props
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile)
  const [expand, setExpand] = useState(true)
  const [active, setActive] = React.useState('none')
  const [activeMenu, setActiveMenu] = React.useState('none')
  const [toggle, setToggle] = useState(true)
  const [open, setOpen] = useState(false)
  const [openDoor, setOpenDoor] = useState(false)
  const [position, setPosition] = useState(24)
  const [doorPosition, setDoorPosition] = useState(17)
  const [bottom, setBottom] = useState(3.5)
  const [doorBottom, setDoorBottom] = useState(3.5)
  const { t } = useTranslation()
  const [filterData, setFilterData] = useState([navItemUSA])
  var perfectSideBar;
  const features = [
    'Walk-In Registration',
    'Offender',
    'Checklist',
    'Watchlist',
    'Card Template',
    'Atlas Integration',
  ]
  const [featureList, setFeatureList] = useState([])
  const [paymentmethod, setPaymentmethod] = useState(false)
  const { darkMode } = useContext(ThemeContext);
  const toggleIcon = (parent) => {
    if (!parent.items) {
      history.push({
        pathname: parent.link,
      })
    } else {
      setIcon(!icon)
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleOpenDoor = () => {
    setOpenDoor(true)
  }

  const handlenav = (child) => {
    history.push({
      pathname: child.link,
    })
  }


  useEffect(() => {
    if (localStorage.getItem('companyType') === 'PARTNER') {
      dispatch(listPaymentMethods(localStorage.getItem('companyId')))
        .unwrap()
        .then((data) => {
          setPaymentmethod(!data ? true : false)
        })
        .catch((er) => { })
    }
  })

  useEffect(() => {
    let payload = {
      companyId: decoded_jwtToken.companyId,
    }
    if (decoded_jwtToken.companyType === 'CUSTOMER') {
      perfectSideBar = (
        dispatch(customerSubscription(payload))
          .unwrap()
          .then((res) => {
            if (res.code === 'MSGS4032' && res.data) {
              const featureNames = res.data.data.feature.map(
                (feature) => feature.name,
              )
              setFeatureList(featureNames)
            }
          })
      )
    }
  }, [])

  useEffect(() => {
    if (decoded_jwtToken.companyType === 'CUSTOMER') {
      const normalizedFeatureList = featureList.map((feature) => {
        if (feature === 'Checklist') {
          return 'Checklist'
        }
        if (feature === 'Watchlist') {
          return 'Watchlist'
        }
        if (feature === 'Offender Verification') {
          return 'Offender'
        }
        if (
          feature ===
          'Walk-In Registration through DL & Passport Scanner on Web'
        ) {
          return 'Walk-In Registration'
        }
        return feature
      })
      if (normalizedFeatureList.length !== 0) {
        const filteredData = navItemUSA
          .map((item) => {
            if (
              item.name === 'Offender' &&
              !normalizedFeatureList.includes('Offender')
            ) {
              return null
            }
            if (item.items) {
              const filteredItems = item.items.filter((subItem) => {
                if (
                  subItem.name === 'Offender' ||
                  subItem.name === 'Delivery Report' ||
                  subItem.name === 'Checklist' ||
                  subItem.name === 'Watchlist' ||
                  subItem.name === 'Card Template' ||
                  subItem.name === 'Atlas Integration'
                ) {
                  return normalizedFeatureList.includes(subItem.name)
                } else {
                  return true
                }
              })
              return { ...item, items: filteredItems }
            }
            return item
          })
          .filter(Boolean)
        setFilterData(filteredData)
      } else {
        setFilterData(navItemUSA)
      }
    }
  }, [featureList])

  const handleToggle = () => {
    setToggle(!toggle)
    if (!toggle) {
      setExpand(true)
    } else {
      setExpand(false)
      setBottom(120)
      setDoorBottom(60)
      setPosition(2.75)
      setDoorPosition(4)
    }
    if (expand === true) {
      setExpand(false)

      props.onchange(false)
    } else {
      setExpand(true)
      setBottom(0)
      setDoorBottom(0)
      setPosition(24)
      setDoorPosition(17)
      props.onchange(true)
    }
  }

  const onClickMenu = () => {
    if (expand === true) {
      setExpand(false)
      props.onchange(false)
    } else {
      setExpand(true)
      props.onchange(true)
    }
  }
  const [userType, setUserType] = useState('')

  useEffect(() => {
    setUserType(localStorage.getItem('companyType'))
  }, [])

  const zlink = localStorage.getItem('redirectFrom')

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const [icon, setIcon] = React.useState(false)

  const isActiveMenu = (items) => {
    if (items instanceof Array) {
      var isActive = false
      items.map((item) => {
        if (window.location.pathname === item.link) {
          isActive = true
        }
      })
      return isActive
    } else {
      return false
    }
  }

  const isActiveLink = (link) => {
    console.warn('link : ' + link)
    var isActive = false
    if (window.location.pathname === link) {
      isActive = true
    }
    return isActive
  }

  const sidebarMenuContent = (
    <div>
      <PerfectScrollbar>
        <Router>
          <Route
            render={({ location, history }) => (
              <React.Fragment>
                <Box
                  className={
                    darkMode && process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? 'USA_SideNav1'
                      : process.env.REACT_APP_ENVIRONMENT === 'USA'
                        ? 'USA_SideNav'
                        : 'Vis_SideNav'
                  }
                >
                  <SideNav
                    id="style-1"
                    style={{
                      // backgroundColor: '#2281AB',
                      backgroundColor: darkMode ? '#0F273B' : '#2281AB',
                      position: 'fixed',
                      boxShadow: '0px 1px 4px #00000029',
                      width: '100px',
                      zIndex: '10000',
                      marginTop:
                        decoded_jwtToken.companyType !== 'CUSTOMER'
                          ? '50px'
                          : '0px',

                      height: '96%',

                    }}

                    expanded={expand || toggle}
                    onSelect={(selected) => {
                      const to = selected
                      if (
                        location.pathname !== to &&
                        decoded_jwtToken.companyType !== 'CUSTOMER' &&
                        to === 'Dashboard'
                      ) {
                        history.push('/vis/dashboardPanel')
                      }
                      if (
                        location.pathname !== to &&
                        decoded_jwtToken.companyType === 'CUSTOMER' &&
                        to === 'Dashboard'
                      ) {
                        history.push('/vis/dashboard')
                      }
                      if (location.pathname !== to && to !== 'Dashboard') {
                        history.push(to)
                      }
                    }}
                  >
                    {decoded_jwtToken.companyType !== 'CUSTOMER' ? (
                      <Box style={{ height: '50px', width: '100%' }}></Box>
                    ) : (
                      <Box
                        style={{ display: 'flex', justifyContent: 'center' }}
                        py={3}
                        px={3}
                      >
                        {toggle ? (
                          <Link href={REACT_URL_DASHBOARD}>
                            <img
                              src={logo_smartgurd}
                              width="100%"
                              height=""
                              alt="mysmartguard"
                            />
                          </Link>
                        ) : (
                          <Link href={REACT_URL_DASHBOARD}>
                            <img
                              src={logo_mbl}
                              width="100%"
                              height=""
                              alt="mysmartguard"
                            />
                          </Link>
                        )}
                      </Box>
                    )}
                    <Box></Box>
                    {!paymentmethod && (
                      <SideNav.Nav>
                        {(decoded_jwtToken.roleName === 'Employee'
                          ? navItemEmployeelogin
                          : navItems &&
                            process.env.REACT_APP_ENVIRONMENT === 'USA' &&
                            decoded_jwtToken.companyType === 'NONE' &&
                            decoded_jwtToken.roleCode === 'ADMIN'
                            ? navItemDealer
                            : navItems &&
                              process.env.REACT_APP_ENVIRONMENT === 'USA' &&
                              decoded_jwtToken.companyType === 'PARTNER' &&
                              decoded_jwtToken.roleCode === 'ADMIN'
                              ? navItemCustomer
                              : navItems &&
                                process.env.REACT_APP_ENVIRONMENT === 'USA' &&
                                decoded_jwtToken.roleName === 'Admin'
                                ? filterData
                                : navItems &&
                                  process.env.REACT_APP_ENVIRONMENT === 'USA' &&
                                  decoded_jwtToken.companyType === 'NONE'
                                  ? navItemDealer
                                  : navItems &&
                                    process.env.REACT_APP_ENVIRONMENT === 'USA' &&
                                    decoded_jwtToken.companyType === 'PARTNER'
                                    ? navItemCustomer
                                    : navItems &&
                                      process.env.REACT_APP_ENVIRONMENT === 'USA' &&
                                      decoded_jwtToken.roleName === 'Front Desk'
                                      ? navItemFrontEnd
                                      : navItems &&
                                        process.env.REACT_APP_ENVIRONMENT === 'USA' &&
                                        decoded_jwtToken.roleName === 'Owner'
                                        ? filterData
                                        : navItems
                        ).map((parent, index) => {
                          var eventKey =
                            parent.items && parent.items.length > 0
                              ? parent.id
                              : parent.link
                          return (
                            <NavItem
                              expanded={
                                activeMenu === 'none'
                                  ? isActiveMenu(parent.items)
                                  : activeMenu === parent.name
                                    ? true
                                    : false
                              }
                              active={isActiveLink(parent.link)}
                              eventKey={parent.name}
                              key={index}
                              className="navItemBg"
                              onClick={() => toggleIcon(parent)}
                            >
                              <NavIcon
                                style={{
                                  width: '100px',
                                  marginRight: '-20px',
                                  justifyContent: 'center',
                                }}
                              >
                                <parent.Icon />
                              </NavIcon>
                              <NavText>
                                <Box
                                  className="navTextTitle"
                                  component="span"
                                  display="flex"
                                  alignItems="center"
                                >
                                  {parent.name}{' '}
                                  {!parent.items ? null : (
                                    <>
                                      {active === parent.name ? (
                                        <ExpandMoreIcon
                                          style={{
                                            position: 'absolute',
                                            right: 3,
                                            fontSize: '20px',
                                          }}
                                          onClick={() => {
                                            toggleIcon(!setActive)
                                          }}
                                        />
                                      ) : (
                                        <ArrowDropDownIcon
                                          style={{
                                            position: 'absolute',
                                            right: 3,
                                            fontSize: '25px',                                          
                                          }}
                                          onClick={() => {
                                            toggleIcon(setActive)
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                </Box>
                              </NavText>
                              {parent.items && parent.items.length > 0
                                ? parent.items.map((child, index) => {
                                  return (
                                    <NavItem
                                      key={index}
                                      eventKey={`${child.link}`}
                                      onClick={() => handlenav(child)}
                                    >
                                      <NavText>
                                        <Box
                                          className="navTextSubTitle"
                                          component="span"
                                          display="flex"
                                          alignItems="center"
                                          style={{ marginLeft: '10px' }}
                                        >
                                          <ul>
                                            {decoded_jwtToken.companyType !==
                                              'CUSTOMER' ? (
                                              <li
                                                style={{
                                                  color:
                                                    location.pathname ===
                                                      child.link
                                                      ? '#F2824C'
                                                      : '#8998A3',
                                                }}
                                              >
                                                {child.name}
                                              </li>
                                            ) : (
                                              <li
                                                style={{
                                                  color:
                                                    location.pathname ===
                                                      child.link
                                                      ? '#F2824C'
                                                      : darkMode ? '#fff' : '#2281AB',
                                                }}
                                              >
                                                {child.name}
                                              </li>
                                            )}
                                          </ul>
                                        </Box>
                                      </NavText>
                                    </NavItem>
                                  )
                                })
                                : null}
                            </NavItem>
                          )
                        })}
                      </SideNav.Nav>
                    )}

                    <div >
                      <PerfectScrollbar>{perfectSideBar}</PerfectScrollbar>
                      <div
                        // component="div"
                        style={{
                          // width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          position: 'fixed',
                          bottom: '11px',
                          height: '6%',
                          width: '40px',
                          // marginLeft: '22px',
                          // width: '270px', 
                          width: expand ? '270px' : '100px',
                          // borderTop: '0.5px solid #ccc',
                          zIndex: 12000,
                          backgroundColor: darkMode ? "#0F273B" : "rgb(34, 129, 171)",
                          marginBottom: '-10px'

                        }}
                        sx={{ ml: 3.5 }}
                      >
                        <IconButton onClick={handleToggle} style={{marginLeft : "30px"}}>
                          <MenuIcon
                            style={{
                              color:
                                decoded_jwtToken.companyType !== 'CUSTOMER'
                                  ? '#8998A3'
                                  : '#fff',
                            }}
                          />
                        </IconButton>
                      </div>
                      {/* </div> */}
                      {decoded_jwtToken.companyType !== 'NONE' &&
                        decoded_jwtToken.partnerType !== 'DEALER' ? (
                        <>
                          <Tooltip
                            title={t('COMMON149')}
                            arrow
                            placement="right-start"
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              position: 'fixed',
                              height: '6%',
                              width: '80px',
                              boxShadow: 'none',
                              backgroundColor: 'transparent',
                              zIndex: 12000
                            }}
                            sx={{ bottom: bottom, ml: position }}
                          >
                            <IconButton>
                              <img
                                alt="..."
                                src={CheckInwithpinIcon}
                                onClick={handleOpen}
                                style={{ width: '35px', left: '100px' }}
                              />
                            </IconButton>
                          </Tooltip>
                          {decoded_jwtToken.companyType === 'CUSTOMER' &&
                            (decoded_jwtToken.roleName !== 'Employee') ? (
                            <>
                              {doorBottom === 3.5 || doorPosition === 17 ? (
                                <Tooltip
                                  title={
                                    <div style={{ marginLeft: '2.75rem' }}>
                                       <span>{t('EMERGENCY_DOOR')}</span>
                                    </div>
                                  }
                                  arrow
                                  placement="right-start"
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    position: 'fixed',
                                    height: '6%',
                                    width: '80px',
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent',
                                    zIndex: 12000
                                  }}
                                  sx={{ bottom: doorBottom, ml: doorPosition }}
                                >
                                  <IconButton>
                                    <img
                                      alt="..."
                                      src={doorIcon}
                                      onClick={handleOpenDoor}
                                      style={{ width: '28px' }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={'Emergency Door'}
                                  arrow
                                  placement="right-start"
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    position: 'fixed',
                                    height: '40px',
                                    width: '80px',
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent',

                                  }}
                                  sx={{ bottom: doorBottom, ml: doorPosition }}
                                >
                                  <IconButton>
                                    <img
                                      alt="..."
                                      src={doorIcon}
                                      onClick={handleOpenDoor}
                                      style={{ width: '28px' }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          ) : null}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </SideNav>
                </Box>
              </React.Fragment>
            )}
          />
        </Router>
      </PerfectScrollbar>
    </div>
  )

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg"
        >
          <SidebarHeader />
          <PerfectScrollbar options={{ wheelPropagation: true }}>
            {sidebarMenuContent}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': '',
          })}
          square
          elevation={sidebarShadow ? 11 : 3}
        >
          <SidebarHeader />
          <div className={clsx({ 'app-sidebar-menu': '' })}>
            <PerfectScrollbar
              options={{ wheelPropagation: true }}
              onScrollY={(container) =>
                console.log(`scrolled to: ${container.scrollTop}.`)
              }
            >
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
      {open && (
        <PinDialogBox
          open={open}
          handleClose={() => {
            setOpen(false)
          }}
        />
      )}
      {openDoor && (
        <EmergencyDoor
          open={openDoor}
          handleClose={() => {
            setOpenDoor(false)
          }}
        />
      )}
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    sidebarFixed: state.ThemeOptions.sidebarFixed,
    headerFixed: state.ThemeOptions.headerFixed,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSidebarToggleMobile: (e) => dispatch(setSidebarToggleMobile(e)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
