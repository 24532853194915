import React, { createContext } from "react";

const ProfileContext = createContext({
    userProfile: {},
    userProfilePic: "",
    companyProfile: {},
    companyProfilePic : "",
    visitorStatus: "",
    currentTotalVisitCount:"",
    resendTimer : false,

    setUserProfile: () => {},
    setUserProfilePic: () => {},
    setCompanyProfile: () => {},
    setCompanyProfilePic: () => {},
    setVisitorStatus: () => {},
    setCurrentTotalVisitCount: ()=> {},
    setResendTimer : () => {}
 });

 export default ProfileContext;