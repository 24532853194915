import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import InvitationFlow from "../../assets/images/InvitationImage.svg";
import RegistrationFlow from "../../assets/images/RegistrationImage.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const tabsStyle = {
  borderRight: "solid #00000029",
  minWidth: 400,
  textTransform: "none",
};

export default function FlowchartTabs(props) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container style={{ display: "flex", paddingLeft: "15px" }}>
        <Grid
          item
          xl={12}
          md={12}
          sm={12}
          style={{ marginTop: "35px" }}
          xs={12}
        >
          <Tabs
            TabIndicatorProps={{ style: { background: "#36C96D", height: 3 } }}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={t("INVITATION001")} style={tabsStyle}></Tab>
            <Tab label={t("REGISTRATIONFORM001")} style={tabsStyle}></Tab>
          </Tabs>

          <Grid md={12} lg={12} sm={12}>
            {value === 0 && (
              <TabPanel value={value} index={value}>
                <img
                  className="header-logo"
                  alt="Invite"
                  src={InvitationFlow}
                />
              </TabPanel>
            )}

            {value === 1 && (
              <TabPanel value={value} index={value}>
                <img
                  className="header-logo"
                  alt="Register"
                  src={RegistrationFlow}
                />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
