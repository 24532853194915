import { Box, Button, Grid, Switch, Typography, styled } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import AutocompleteComponent from '../../components/CustomInputs/AutocompleteComponent';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { padding } from '@mui/system';
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons';
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons';
import CommonUtil from '../../Util/CommonUtils';
import { useDispatch } from 'react-redux';
import { getSmsTemplate, updateSmsTemplate } from '../../slices/SmsTemplate/SmsTemplateSlice';
import Toast from '../../components/ToastContainer/CustomToast';
import { useHistory } from "react-router-dom";
import { ThemeContext } from '../../theme/ThemeContext';
import CustomTextfield from '../../components/CustomInputs/CustomTextfield';

const useStyles = makeStyles((theme) => ({
  text: {
    "& .css-672ulr-MuiTypography-root": {
      marginTop: "-6px",
    }
  }

}))

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#fff",
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#0F5A9C !important",
    opacity: 1,
  },
}));


function EditSmsTemplate(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);
  const [sendSms, setSendSms] = useState(true)
  const [subject, SetSubject] = useState(props.location.state.message)
  const [employeeNameCard, setEmployeeNameCard] = useState(false);
  const [visitorNameCard, setVisitorNameCard] = useState(false);
  const [startTimeCard, setStartTimeCard] = useState(false);
  const [endTimeCard, setEndTimeCard] = useState(false);
  const [companyNameCard, setCompanyNameCard] = useState(false);
  const [acceptLinkCard, setAcceptLinkCard] = useState(false);
  const [qrCodeCard, setQrCodeCard] = useState(false);
  const [firstNameCard, setFirstNameCard] = useState(false);
  const [pinCard, setPinCard] = useState(false);
  const [qrCodeOrPin, setQrCodeOrPin] = useState(false);
  const [linkcard, setLinkCard] = useState(false);
  const [timeCard, setTimeCard] = useState(false);
  const [messageCard, setMessageCard] = useState(false);
  const [deliveryCompanyName, setDeliveryCompanyName] = useState(false);
  const [recipient, setRecipient] = useState('');
  const dispatch = useDispatch();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [category, setCategory] = useState('')
  const history = useHistory();
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = useState({
    id: "",
    message: "",
    status: ""
  })

  const recepientOptions = [
    { label: 'Host' },
    { label: 'Visitor' },
    { label: 'Employee' }
  ]

  useEffect(() => {
    if (props.location.state.recipient) {
      setRecipient(props.location.state.recipient)
      setCategory(convertString(props.location.state.code))
      setPayload({
        ...payload,
        id: props.location.state.id,
        status: props.location.state.status
      })
      setSendSms(props.location.state.status)
    }
    else {
      setRecipient('')
    }
  }, [props.location.state.recipient, props.location.state.code])

  useEffect(() => {
    loadData();
  }, [recipient])

  const loadData = () => {
    const value = recipient
    let payload = {
      pageNumber: 1,
      pageSize: 20,
      recipient: value
    }
    dispatch(getSmsTemplate(payload))
      .unwrap()
      .then((res) => {
        if (res.code === "CVAE4001") {
          const options = res.data.templateList.map((item) => convertString(item.code))
          setCategoryOptions(options)
        }
      })
  }

  const convertString = (inputString) => {
    // Split the input string by underscores
    const parts = inputString.split('_');

    // Convert each part to lowercase and capitalize the first letter
    const convertedParts = parts.map(part => {
      // Convert the part to lowercase
      const lowercasePart = part.toLowerCase();

      // Capitalize the first letter of the part
      if (lowercasePart.length > 0) {
        return lowercasePart.charAt(0).toUpperCase() + lowercasePart.slice(1);
      }

      return lowercasePart;
    });

    // Join the converted parts with spaces
    const result = convertedParts.join(' ');

    return result;
  }

  const handleEmployeeNameClick = (event) => {
    setEmployeeNameCard(!employeeNameCard)
    const value = "${employeeFirstName}";
    if (!employeeNameCard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  };

  const handleVisitorNameCard = (event) => {
    setVisitorNameCard(!visitorNameCard)
    const value = "${visitorFirstName}";
    if (!visitorNameCard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  };

  const handleStartTimeClick = (event) => {
    setStartTimeCard(!startTimeCard)
    const value = "${startTime}";
    if (!startTimeCard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  };

  const handleEndTimeClick = (event) => {
    setEndTimeCard(!endTimeCard)
    const value = "${endTime}";
    if (!endTimeCard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  };

  const handleCompanyNameClick = (event) => {
    setCompanyNameCard(!companyNameCard)
    const value = "${companyName}";
    if (!companyNameCard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  };

  const handleAcceptLinkClick = (event) => {
    setAcceptLinkCard(!acceptLinkCard)
    const value = "${acceptLink}";
    if (!acceptLinkCard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  }

  const handleQrcodeClick = (event) => {
    setFirstNameCard(!firstNameCard);
    const value = "${FirstName}";
    if (!firstNameCard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  }

  const handlePinClick = (event) => {
    setPinCard(!pinCard)
    const value = "${PIN}";
    if (!pinCard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  }

  const handleQrOrPinClick = (event) => {
    setQrCodeOrPin(!qrCodeOrPin)
    const value = "${QR-Code/Pin Link}";
    if (!qrCodeOrPin) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  }

  const handleLinkClick = (event) => {
    setLinkCard(!linkcard)
    const value = "${link}";
    if (!linkcard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  }

  const handleTimeClick = (event) => {
    setTimeCard(!timeCard)
    const value = "${Time}";
    if (!timeCard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  }


  const handleMessageClick = (event) => {
    setMessageCard(!messageCard)
    const value = "${message}";
    if (!messageCard) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  }

  const handleDeliveryCompanyNameClick = (event) => {
    setDeliveryCompanyName(!deliveryCompanyName)
    const value = "${deliveryCompanyName}";
    if (!deliveryCompanyName) {
      const input = document.getElementById('myTextField');
      const { selectionStart, selectionEnd } = input;
      const newText = subject.substring(0, selectionStart) + " " + value + " " + subject.substring(selectionEnd);
      SetSubject(newText);
      const newCursorPosition = selectionStart + value.length;
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    else {
      const newText = subject.replace(value, "");
      SetSubject(newText)
    }
  }


  const handleChange = (event) => {
    setSendSms(!sendSms);
    setPayload({
      ...payload,
      status: event.target.checked
    })
  };

  const cardBackgroundColor = isClicked ? '#2281AB' : 'white';
  const cardTextColour = isClicked ? '#FFFFFF' : '#242424';


  const handleSubjectChange = (event) => {
    SetSubject(event.target.value)
  }

  useEffect(() => {
    if (!subject.includes("${employeeFirstName}")) {
      setEmployeeNameCard(false)
    }
    if (subject.includes("${employeeFirstName}")) {
      setEmployeeNameCard(true)
    }
    if (!subject.includes("${visitorFirstName}")) {
      setVisitorNameCard(false)
    }
    if (subject.includes("${visitorFirstName}")) {
      setVisitorNameCard(true)
    }
    if (!subject.includes("${startTime}")) {
      setStartTimeCard(false)
    }
    if (subject.includes("${startTime}")) {
      setStartTimeCard(true)
    }
    if (!subject.includes("${endTime}")) {
      setEndTimeCard(false)
    }
    if (subject.includes("${endTime}")) {
      setEndTimeCard(true)
    }
    if (!subject.includes("${companyName}")) {
      setCompanyNameCard(false)
    }
    if (subject.includes("${companyName}")) {
      setCompanyNameCard(true)
    }
    if (!subject.includes("${acceptLink}")) {
      setAcceptLinkCard(false)
    }
    if (subject.includes("${acceptLink}")) {
      setAcceptLinkCard(true)
    }
    if (!subject.includes("${FirstName}")) {
      setFirstNameCard(false)
    }
    if (subject.includes("${FirstName}")) {
      setFirstNameCard(true)
    }
    if (!subject.includes("${PIN}")) {
      setPinCard(false)
    }
    if (subject.includes("${PIN}")) {
      setPinCard(true)
    }
    if (!subject.includes("${QR-Code/Pin Link}")) {
      setQrCodeOrPin(false)
    }
    if (subject.includes("${QR-Code/Pin Link}")) {
      setQrCodeOrPin(true)
    }
    if (!subject.includes("${link}")) {
      setLinkCard(false)
    }
    if (subject.includes("${link}")) {
      setLinkCard(true)
    }
    if (!subject.includes("${Time}")) {
      setTimeCard(false)
    }
    if (subject.includes("${Time}")) {
      setTimeCard(true)
    }
    if (!subject.includes("${message}")) {
      setMessageCard(false)
    }
    if (subject.includes("${message}")) {
      setMessageCard(true)
    }
    if (subject.includes("${deliveryCompanyName}")) {
      setDeliveryCompanyName(true)
    }
    if (!subject.includes("${deliveryCompanyName}")) {
      setDeliveryCompanyName(false)
    }
  }, [subject])

  const handleSubmit = () => {
    let dto = {
      id: payload.id,
      message: subject,
      status: payload.status
    }
    dispatch((updateSmsTemplate(dto)))
      .unwrap()
      .then((res) => {
        if (res.code === "CVAE4002") {
          Toast(res.message, "success");
          history.goBack();
        }
      })

  }

  const handleCancel = () => {
    history.goBack();
  }

  const handleNottoChange = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          <Box style={{ margin: '16px, 0' }}><Typography variant="h3" style={{
            marginLeft: darkMode ? '33px' : '0px',
            marginRight: darkMode ? '18px' : '12px',
            color: darkMode ? '#fff' : '#3D4977',
            marginTop: darkMode ? '3px' : '4px'
          }}>{t("COMMON077")}</Typography></Box>
        </Grid>
      </Box>

      <Paper elevation={3} sx={{ width: "100%", height: "95%", mt: "20px", padding: "5px" }} style={{backgroundColor: darkMode ? '#0F273B' : '' , marginLeft: darkMode ? '32px' : '' , width: darkMode ? '95%' : ''}}>
        <Grid container >
          <Grid item xs={12} lg={6} md={6} sm={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={recepientOptions}
              value={recipient}
              onChange={(event, value) => {
                if (!CommonUtil.isEmpty(value))
                  setRecipient(value.label)
                else
                  setRecipient('')
              }}
              sx={{ width: "100%", padding: "20px" }}
              renderInput={(params) => <CustomTextfield {...params} label="Select Recepient *" />}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} sm={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={categoryOptions}
              value={category}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue))
                  setCategory(newValue)
                else
                  setCategory('')
              }}
              sx={{ width: "100%", padding: "20px" }}
              renderInput={(params) => <CustomTextfield {...params} label="Category" />}
            />
          </Grid>
        </Grid>

        <Grid container sx={{ padding: "15px" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomTextfield
              // id="outlined-multiline-static"
              id="myTextField"
              label="Subject"
              multiline
              rows={6}
              variant="outlined"
              style={{ width: "100%" }}
              value={subject.trim()}
              spellCheck={false}
              InputProps={{ disableUnderline: true}}
              handleChange={handleSubjectChange}
              onCopy={handleNottoChange}
              onPaste={handleNottoChange}
              onCut={handleNottoChange}
            />
          </Grid>
        </Grid>


        <Grid container sx={{ padding: "10px" }} spacing={2}>
          <Grid item>
            <Card sx={{ backgroundColor: employeeNameCard ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleEmployeeNameClick}>
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: employeeNameCard ? '#FFFFFF' : '#242424' }} className={classes.text} color="text.secondary" gutterBottom>
                  {'${employee FirstName}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ backgroundColor: visitorNameCard ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleVisitorNameCard} >
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: visitorNameCard ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${visitor FirstName}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ backgroundColor: startTimeCard ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleStartTimeClick}>
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: startTimeCard ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${startTime}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ backgroundColor: endTimeCard ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleEndTimeClick}>
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: endTimeCard ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${endTime}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ backgroundColor: companyNameCard ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleCompanyNameClick}>
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: companyNameCard ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${companyName}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ backgroundColor: acceptLinkCard ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleAcceptLinkClick}>
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: acceptLinkCard ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${acceptLink}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ backgroundColor: firstNameCard ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleQrcodeClick} >
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: firstNameCard ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${FirstName}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item>
        <Card sx={{backgroundColor : pinCard ? '#2281AB' : 'white'  , borderRadius:"20px" ,  border: '1px solid #707070' , height:"40px"}} onClick={handlePinClick}>
      <CardContent  sx={{marginTop:"-6px"}}>
        <Typography sx={{ fontSize: 14 , color : pinCard ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
            {'${PIN}'}
        </Typography>
      </CardContent>
        </Card>
        </Grid> */}

          <Grid item>
            <Card sx={{ backgroundColor: qrCodeOrPin ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleQrOrPinClick}>
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: qrCodeOrPin ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${QR-Code/Pin Link}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ backgroundColor: linkcard ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleLinkClick}>
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: linkcard ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${Link}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ backgroundColor: timeCard ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleTimeClick} >
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: timeCard ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${Time}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ backgroundColor: messageCard ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleMessageClick} >
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: messageCard ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${message}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ backgroundColor: deliveryCompanyName ? '#2281AB' : 'white', borderRadius: "20px", border: '1px solid #707070', height: "40px" }} onClick={handleDeliveryCompanyNameClick} >
              <CardContent sx={{ marginTop: "-6px" }}>
                <Typography sx={{ fontSize: 14, color: deliveryCompanyName ? '#FFFFFF' : '#242424' }} color="text.secondary" gutterBottom>
                  {'${deliveryCompanyName}'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container sx={{ padding: "20px", color: darkMode ? '#fff' : '' }} spacing={1}>
          <Grid item>
            <Typography sx={{ fontWeight: "bold" }}>
              Note :
            </Typography>
          </Grid>

          <Grid item>
            <Typography>
              Please use this keywords for SMS Template
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={0.5}>
          <Grid item>
            <Android12Switch
              checked={sendSms}
              onChange={handleChange}
              name=""
            />
          </Grid>

          <Grid item sx={{ mt: "10px", color: darkMode ? '#fff' : '' }}>
            <Typography>
              SMS Template Notification
            </Typography>
          </Grid>
        </Grid>

        <Grid container lg={8} style={{ marginLeft: '30%' }} justifyContent="flex-end" >
          <Grid pr={2} sx={{ marginTop: 5 }}>
            <CancelButtons
              variant="contained"
              color="secondary"
              onClick={handleCancel}
            >
              <Typography variant="body1" style={{ textTransform: "none" }}>
                {t("INVITATION003")}
              </Typography>
            </CancelButtons>
          </Grid>
          <Grid sx={{ marginTop: 5, marginRight: '40px' }}>
            <SubmitButtons variant="contained" onClick={handleSubmit}>
              <Typography variant="body1" style={{ textTransform: "none" }}>
                {t("COMMON035")}
              </Typography>
            </SubmitButtons>
          </Grid>
        </Grid>


      </Paper>

    </>
  )
}

export default EditSmsTemplate