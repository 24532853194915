import { Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Grid, Stack, Tooltip } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import HeaderToolbar from '../../components/HeaderToolbar'
import { getUserDetails } from '../../slices/Dealer/DealerSlice'
import { getSubscriptionById } from '../../slices/Subscription/SubscriptionSlice'
import { getDealerSubscriptions } from '../../slices/SubscriptionDealerSlice'

function CustomerTransactionView(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const dispatch = useDispatch()
  const [planName, setPlanName] = useState('')
  const [planMode, setPlanMode] = useState('')

  const handleTextOverflow = (text) => {
    if (text && text.length > 35) {
      return text.substring(0, 25) + '...'
    } else {
      return text
    }
  }

  const handleCustomerClick = () => {
    history.push({
      pathname: '/vis/transactions',
      state: {
        value: 1,
      },
    })
  }

  const handleDealerClick = () => {
    history.push({
      pathname: '/vis/transactions',
      state: {
        value: 0,
      },
    })
  }

  useEffect(() => {
    loadUserDetails()
  }, [])
  const emailValue = props.location.state.email
  const loadUserDetails = () => {
    dispatch(getUserDetails(emailValue))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0033') {
          setFirstName(data.data.firstName)
          setLastName(data.data.lastName)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    dispatch(getDealerSubscriptions(props.location.state.id))
      .unwrap()
      .then((data) => {
        dispatch(getSubscriptionById(data.data.subscriptionIds[0]))
          .unwrap()
          .then((data) => {
            if (data.code === '200' && data.data) {
              setPlanName(data.data.price_strategy_name)
              if (data.data.type_name === 'Pay annual') setPlanMode('Yearly')
              else setPlanMode('Monthly')
            }
          })
      })
  }, [])

  return (
    <>
      {props.location.dealerData === 'dealer' ? (
        <>
          <Stack direction="row">
            <ArrowBackIosIcon
              onClick={handleDealerClick}
              sx={{ color: '#9AA1B9' }}
            />
            <HeaderToolbar title={t('VIEWPAGEDEALERTRANSACTION')} />
          </Stack>

          <Grid
            container
            lg={12}
            md={12}
            item
            sx={{ mt: '20px', bgcolor: 'white' }}
          >
            <Grid item padding={2}>
              <Avatar
                alt="Remy Sharp"
                src={
                  props.location.state.imagePreSignedURL
                    ? props.location.state.imagePreSignedURL
                    : ''
                }
                style={{ height: '180px', width: '180px', marginLeft: '40px' }}
              />
            </Grid>
            <Grid
              item
              xs
              zeroMinWidth
              style={{
                marginLeft: '20px',
                marginTop: '20px',
                borderRight: '3px solid #E1E9F5',
              }}
            >
              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('DEALERFIRSTNAME')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${firstName}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(firstName)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('DEALERLASTNAME')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${lastName}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(lastName)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('DEALEREMAIL')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip
                    title={`${props.location.state.email}`}
                    placement="bottom-start"
                  >
                    <Typography noWrap>
                      {handleTextOverflow(props.location.state.email)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('VIEWPAGEMOBLIENUMBER')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip
                    title={`${props.location.state.phone}`}
                    placement="bottom-start"
                  >
                    <Typography noWrap>
                      {handleTextOverflow(props.location.state.phone)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('DEALERCOMPANYNAME')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip
                    title={`${props.location.state.name}`}
                    placement="bottom-start"
                  >
                    <Typography noWrap>
                      {handleTextOverflow(props.location.state.name)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs
              zeroMinWidth
              style={{ marginLeft: '20px', marginTop: '20px' }}
            ></Grid>
          </Grid>
          {/*transcation details*/}
          <div>
            <h4 style={{ marginTop: '30px', color: '#0F5A9C' }}>{t('VIEWPAGEDEALERTRANSACTIONHISTORY')}</h4>

            <Grid
              container
              lg={12}
              md={12}
              item
              sx={{ mt: '20px', bgcolor: 'white' }}
            >
              <Grid
                item
                xs
                zeroMinWidth
                style={{
                  marginLeft: '20px',
                  marginTop: '20px',
                  borderRight: '3px solid #E1E9F5',
                }}
              >
                <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography noWrap style={{ opacity: '50%', marginBottom: '20px' }}>
                      {t('VIEWPAGEDEALERTRANSACTIONHISTORYDATE')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Tooltip title={`${props.location.state.date}`} placement="bottom-start">
                      <Typography noWrap>{handleTextOverflow(props.location.state.date)}</Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs
                zeroMinWidth
                style={{
                  marginLeft: '20px',
                  marginTop: '20px',
                  borderRight: '3px solid #E1E9F5',
                }}
              >
                <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography noWrap style={{ opacity: '50%' }}>
                      {t('VIEWPAGEDEALERTRANSACTIONHISTORYAMOUNT')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Tooltip title={`${props.location.state.amount}`} placement="bottom-start">
                      <Typography noWrap>{handleTextOverflow(props.location.state.amount)}</Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs
                style={{
                  marginLeft: '20px',
                  marginTop: '20px',
                }}
              >
                <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography noWrap style={{ opacity: '50%' }}>
                      {t('VIEWPAGEDEALERTRANSACTIONHISTORY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Tooltip title={`${props.location.state.amount}`} placement="bottom-start">
                      <Typography noWrap>{handleTextOverflow(props.location.state.amount)}</Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <Stack direction="row">
            <ArrowBackIosIcon
              onClick={handleCustomerClick}
              sx={{ color: '#9AA1B9' }}
            />
            <HeaderToolbar title={t('VIEWPAGECUSTOMERTRANSACTION')} />
          </Stack>
          <Grid
            container
            lg={12}
            md={12}
            item
            sx={{ mt: '20px', bgcolor: 'white' }}
          >
            <Grid item padding={2}>
              <Avatar
                alt="Remy Sharp"
                src={
                  props.location.state.imagePreSignedURL
                    ? props.location.state.imagePreSignedURL
                    : ''
                }
                style={{ height: '180px', width: '180px', marginLeft: '40px' }}
              />
            </Grid>
            <Grid
              item
              xs
              zeroMinWidth
              style={{
                marginLeft: '20px',
                marginTop: '20px',
                borderRight: '3px solid #E1E9F5',
              }}
            >
              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('DEALERFIRSTNAME')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${firstName}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(firstName)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('DEALERLASTNAME')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${lastName}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(lastName)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('DEALEREMAIL')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip
                    title={`${props.location.state.email}`}
                    placement="bottom-start"
                  >
                    <Typography noWrap>
                      {handleTextOverflow(props.location.state.email)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('VIEWPAGEMOBLIENUMBER')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip
                    title={`${props.location.state.phone}`}
                    placement="bottom-start"
                  >
                    <Typography noWrap>
                      {handleTextOverflow(props.location.state.phone)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('DEALERCOMPANYNAME')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip
                    title={`${props.location.state.name}`}
                    placement="bottom-start"
                  >
                    <Typography noWrap>
                      {handleTextOverflow(props.location.state.name)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs
              zeroMinWidth
              style={{
                marginLeft: '20px',
                marginTop: '20px',
                borderRight: '3px solid #E1E9F5',
              }}
            >
              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('LABELNAVITEMSSUBSCRIPTION')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${props.location.state.subscriptionName}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.subscriptionName)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('SELECTSUBSCRIPTIONTYPE')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${props.location.state.billingCycle}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.billingCycle)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('CUSTDATABLE20')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${props.location.state.planBasePrice}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.planBasePrice)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>

              {/* <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('CUSTDATABLE23')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${props.location.state.planDiscount}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.planDiscount)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid> */}

              {/* <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('CUSTDATABLE17')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${props.location.state.transactionIDofHQ}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.transactionIDofHQ)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid> */}
              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('CUSTDATABLE24')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${props.location.state.hQeceivedamount}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.hQReceivedAmount)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('CUSTDATABLE35')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${props.location.state.dealerReceivedAmount}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.dealerReceivedAmount)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs
              zeroMinWidth
              style={{
                marginLeft: '20px',
                marginTop: '20px',
                borderRight: '3px solid #E1E9F5',
              }}
            >
              

              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('CUSTDATABLE22')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${props.location.state.dealerName}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.dealerName)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${'Amount Received Date to HQ'}`} placement="bottom-start">
                    <Typography noWrap style={{ opacity: '50%' }}>{t('CUSTDATABLE25')}</Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${props.location.state.amountRaeceivedDatetoHQ}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.amountRaeceivedDatetoHQ)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid>

              {/* <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography noWrap style={{ opacity: '50%' }}>
                    {t('CUSTDATABLE09')}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Tooltip title={`${props.location.state.autorenewal}`} placement="bottom-start">
                    <Typography noWrap>{handleTextOverflow(props.location.state.autorenewal)}</Typography>
                  </Tooltip>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>

        </>)}


    </>
  )
}

export default CustomerTransactionView
