import React from "react";
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Grid } from '@material-ui/core';
import CommonUtil from '../../Util/CommonUtils';
import { ReactComponent as CompanyIcons } from "../../assets/CompanyIcons.svg";
import { TextField } from "@mui/material";


export default function CustomAutocomplete({ options, onSelect, error, InputProps, label, ...props }) {
    const filterOptions = createFilterOptions({ stringify: ({ name, code }) => `${name} ${code}`, });

    return (
        <Grid xs={{ display: 'flex' }}>
            <Grid className="company_icon">
                <CompanyIcons className='visibleIcons' />
            </Grid>
            <Autocomplete
                size='small'
                filterOptions={filterOptions}
                getOptionLabel={(option) => option.name}
                options={options}
                onChange={props.onChange}
                value={props.value}
                renderInput={(params) =>
                    <TextField
                        sx={[
                            () => ({
                                "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall ": {
                                    paddingTop: '14px !important',
                                    paddingBottom: '14px !important',
                                },
                                "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-required.Mui-focused.Mui-required": {
                                    marginLeft: '1px !important'
                                },
                                "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                                    transform: 'translate(-2px, -9px) scale(0.75) !important'
                                },

                                "& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                    color: '#fff !important',
                                    opacity: ' 0.9',
                                },
                                "& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator": {
                                    color: '#fff !important',
                                    opacity: '0.9',
                                },
                                "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary": {
                                    color: '#fff !important',
                                    opacity: '0.9',
                                    paddingLeft: '25px !important',
                                    paddingTop: '5px !important',
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: '#fff !important',
                                },

                                "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled": {
                                    color: '#fff !important',
                                },
                                "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                                    color: '#fff !important',
                                },
                                "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused": {
                                    color: '#fff !important',
                                },

                            })]}
                        {...params}
                        required={props.required}
                        variant='outlined'
                        fullWidth
                        helperText={error}
                        label={label}
                        error={error}
                        name={props.name}
                        handleChange={props.handleChange}
                    />
                }
            />
        </Grid>
    );
}

CustomAutocomplete.defaultProps = {
    label: ''
};

CustomAutocomplete.propType = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
}
