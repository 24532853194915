import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DealerService from '../../services/Dealer/DealerService'

export const createDealer = createAsyncThunk('/dealer', async (payload) => {
  const res = await DealerService.createDealer(payload)
  return res.data
})

export const updateDealer = createAsyncThunk('/dealer', async (payload) => {
  const res = await DealerService.updateDealer(payload)
  return res.data
})

export const getDealers = createAsyncThunk('/dealer', async (payload) => {
  const res = await DealerService.getDealers(payload)
  return res.data
})

export const deleteDealers = createAsyncThunk(
  '/dealer/deleteDealers',
  async (payload) => {
    const res = await DealerService.deleteDealer(payload)
    return res.data
  },
)

export const resendActivationLinkForDealer = createAsyncThunk(
  '/dealer/resendActivationLink',
  async (payload) => {
    const res = await DealerService.resendActivationLinkForDealer(payload)
    return res.data
  },
)

export const getDealersAll = createAsyncThunk(
  '/dealer/listOfDealer',
  async (payload) => {
    const res = await DealerService.getDealersAll(payload)
    return res.data
  },
)

export const getUserDetails = createAsyncThunk('/users', async (payload) => {
  const res = await DealerService.getUserDetails(payload)
  return res.data
})

export const listOfDealerDetalisWithoutStatus = createAsyncThunk(
  '/dealer/listOfDealer',
  async (payload) => {
    const res = await DealerService.listOfDealerDetalisWithoutStatus(payload)
    return res.data
  },
)

export const getdealerById = createAsyncThunk(
  '/dealer/listOfDealer',
  async (payload) => {
    const res = await DealerService.getdealerById(payload)
    return res.data
  },
)

export const getDealerBillingInfo = createAsyncThunk(
  '/dealer/billingInfo',
  async (companyId) => {
    const res = await DealerService.getDealerBillingInfo(companyId)
    return res.data
  },
)

export const getDealerInvoice = createAsyncThunk('/dealer/invoice' , async(payload) => {
  const res = await DealerService.getDealerInvoice(payload);
  return res.data;
})
