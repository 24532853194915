import React from "react";

function CustomersIcon(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="30"
        viewBox="0 0 30 30"
      >
        <g
          id="Group_7835"
          data-name="Group 7835"
          transform="translate(-334 -103)"
        >
          <rect
            id="Rectangle_6430"
            data-name="Rectangle 6430"
            width="30"
            height="30"
            transform="translate(334 103)"
            fill="none"
          />
          <path
            id="Path_7297"
            data-name="Path 7297"
            d="M6336.124-2123a.816.816,0,0,1-.817-.815v-2.493h-5.492a.817.817,0,0,1-.815-.815v-3.774a5.475,5.475,0,0,1,5.47-5.469,5.514,5.514,0,0,1,1.216.135,5.443,5.443,0,0,1,1.121.39,5.457,5.457,0,0,1,1,.611,5.336,5.336,0,0,1,.648.588,7.859,7.859,0,0,1,4.7-1.569,7.768,7.768,0,0,1,4.552,1.458,5.366,5.366,0,0,1,.644-.554,5.408,5.408,0,0,1,.972-.571,5.5,5.5,0,0,1,1.085-.363,5.54,5.54,0,0,1,1.174-.126,5.475,5.475,0,0,1,5.469,5.469v3.774a.818.818,0,0,1-.817.815H6351v2.493a.816.816,0,0,1-.817.815Zm11.619-4.486v2.854h1.625v-3.732a6.222,6.222,0,0,0-6.215-6.215,6.222,6.222,0,0,0-6.214,6.215v3.732h1.883v-2.854a.818.818,0,0,1,.817-.817.818.818,0,0,1,.817.817v2.854h5.655v-2.854a.818.818,0,0,1,.818-.817A.817.817,0,0,1,6347.743-2127.486Zm7.237-2.687v2.234h.436v-2.957a3.839,3.839,0,0,0-3.835-3.837,3.843,3.843,0,0,0-2.649,1.065,7.79,7.79,0,0,1,2.069,5.3v.424h2.348v-2.234a.818.818,0,0,1,.817-.817A.816.816,0,0,1,6354.979-2130.174Zm-21.815,0v2.234h2.142v-.424a7.814,7.814,0,0,1,1.947-5.169,3.827,3.827,0,0,0-2.784-1.2,3.842,3.842,0,0,0-3.838,3.837v2.957h.9v-2.234a.817.817,0,0,1,.815-.817A.818.818,0,0,1,6333.165-2130.174Zm5.709-10.52a4.309,4.309,0,0,1,4.3-4.306,4.311,4.311,0,0,1,4.307,4.306,4.311,4.311,0,0,1-4.307,4.305A4.309,4.309,0,0,1,6338.874-2140.694Zm1.632,0a2.675,2.675,0,0,0,2.672,2.673,2.675,2.675,0,0,0,2.673-2.673,2.677,2.677,0,0,0-2.673-2.674A2.677,2.677,0,0,0,6340.506-2140.694Zm-9.232,1.111a3.145,3.145,0,0,1,3.143-3.142,3.145,3.145,0,0,1,3.141,3.142,3.144,3.144,0,0,1-3.141,3.142A3.145,3.145,0,0,1,6331.274-2139.583Zm1.632,0a1.512,1.512,0,0,0,1.511,1.51,1.512,1.512,0,0,0,1.509-1.51,1.512,1.512,0,0,0-1.509-1.51A1.512,1.512,0,0,0,6332.907-2139.583Zm15.582-.052a3.145,3.145,0,0,1,3.143-3.142,3.145,3.145,0,0,1,3.142,3.142,3.145,3.145,0,0,1-3.142,3.142A3.145,3.145,0,0,1,6348.489-2139.634Zm1.632,0a1.512,1.512,0,0,0,1.51,1.51,1.512,1.512,0,0,0,1.509-1.51,1.512,1.512,0,0,0-1.509-1.51A1.512,1.512,0,0,0,6350.121-2139.634Z"
            transform="translate(-5994 2252)"
            fill={props.fill}
          />
        </g>
      </svg>
    </>
  );
}

export default CustomersIcon;
