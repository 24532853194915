import React from 'react'
import { Tooltip, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ResendIcon = () => {
  const [isShown, setIsShown] = React.useState(false)
  const { t } = useTranslation()
  return (
    <>
      <Tooltip title={t('ADD028')} style={{color: "red"}}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.55"
              height="15.466"
              viewBox="0 0 16.55 15.466"
            >
              <path
                id="Path_6232"
                data-name="Path 6232"
                d="M17.045,2.259a.805.805,0,0,0-1.135-.673L.952,8.671a.807.807,0,0,0-.095,1.395l3.506,2.292v3.824a.806.806,0,0,0,1.368.567l2.114-2.114L10.8,16.569a.8.8,0,0,0,1.191-.393l5.01-13.589A.7.7,0,0,0,17.045,2.259ZM12.416,5.015,5.949,11.481,2.931,9.507Zm-6.45,9.233v-.842l.509.333Zm4.874.43-3.52-2.3,6.919-6.919Z"
                transform="translate(-0.5 -1.515)"
                fill="#3d4977"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.55"
              height="15.466"
              viewBox="0 0 16.55 15.466"
            >
              <path
                className="test-hover-resend"
                id="Path_6232"
                data-name="Path 6232"
                d="M17.045,2.259a.805.805,0,0,0-1.135-.673L.952,8.671a.807.807,0,0,0-.095,1.395l3.506,2.292v3.824a.806.806,0,0,0,1.368.567l2.114-2.114L10.8,16.569a.8.8,0,0,0,1.191-.393l5.01-13.589A.7.7,0,0,0,17.045,2.259ZM12.416,5.015,5.949,11.481,2.931,9.507Zm-6.45,9.233v-.842l.509.333Zm4.874.43-3.52-2.3,6.919-6.919Z"
                transform="translate(-0.5 -1.515)"
                fill="rgba(99,115,129,0.4)"
              />
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  )
}
export default ResendIcon
