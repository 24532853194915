import PropTypes from "prop-types";
import React, { useContext } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Grid } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { ThemeContext } from '../../theme/ThemeContext';

export default function CustomPhone({
  isValid,
  value,
  handleChange,
  specialLabel,
  disabled,
  placeholder,
  country
}) {
  const zlink = localStorage.getItem("redirectFrom");
  const { darkMode } = useContext(ThemeContext);
  const dispatch = useDispatch();

  return !zlink ? (
    <>
      <Grid>
        <PhoneInput
          fullWidth
          placeholder={placeholder}
          specialLabel={specialLabel}
          searchPlaceholder=""
          countryCodeEditable={true}
          enableSearch="true"
          disabled={disabled}
          disableCountryCode={false}
          enableAreaCodes={false}
          isValid={isValid}
          country={country}
          value={value}
          onChange={handleChange}
          inputStyle={{
            width: "100%",
            height: "56px",
            backgroundColor: darkMode ? '#0F273B' : '#fff',
            color: darkMode ? '#fff' : '#000', // White text in dark mode, black in light mode
          }}
        />
      </Grid>
    </>
  ) : (
    <>
      <Grid>
        <PhoneInput
          fullWidth
          placeholder="Visitor Mobile"
          specialLabel={specialLabel}
          searchPlaceholder=""
          countryCodeEditable={true}
          enableSearch="true"
          disabled={disabled}
          disableCountryCode={false}
          enableAreaCodes={false}
          isValid={isValid}
          value={value}
          onChange={handleChange}
          inputStyle={{
            width: "100%",
            height: "56px",
            color: darkMode ? '#fff' : '#000', // White text in dark mode, black in light mode
          }}
        />
      </Grid>
    </>
  );
}

CustomPhone.propTypes = {
  value: PropTypes.any,
  isValid: PropTypes.bool,
  handleChange: PropTypes.func,
  specialLabel: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  country: PropTypes.string,
};
