import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../services/UserService';

let initialState = {
  validToken: false,
  user: {},
  logged: {},
};

export const createUser = createAsyncThunk('user/create', async (payload) => {
  const res = await UserService.create(payload);
  return res.data;
});

export const authorizeUser = createAsyncThunk('user/authorize', async (payload) => {
  const res = await UserService.autorize(payload);
  return res.data;
});

export const logout = createAsyncThunk('user/logout', async () => {
  await UserService.revokeUserToken();
  localStorage.removeItem('USER_TOKEN');
  localStorage.removeItem('REFRESH_TOKEN');
  localStorage.removeItem('USER');
});

export const reinit = createAsyncThunk('user/reinit', async (payload) => {
  return payload;
});

export const forgotPassword = createAsyncThunk('user/reinit', async (userName) => {
  const res = await UserService.forgotPassword(userName);
  return res.data;
});

export const resetPassword = createAsyncThunk('user/reinit', async (payload) => {
  const res = await UserService.resetPassword(payload);
  return res.data;
});
export const updateUserProfile = createAsyncThunk('user/', async (payload) => {
  const res = await UserService.updateProfile(payload);
  return res.data;
});
export const filterUser = createAsyncThunk('user/', async (payload) => {
  const res = await UserService.findUser(payload);
  return res.data;
});
export const getCompanyProfile = createAsyncThunk('user/', async (payload) => {
  const res = await UserService.findCompanyProfile(payload);
  return res.data;
});
export const updateCompanyProfile = createAsyncThunk('user/', async (payload) => {
  const res = await UserService.updateCompanyProfile(payload);
  return res.data;
});
export const loggedUser = createAsyncThunk('user/', async (payload) => {
  return JSON.parse(localStorage.getItem('USER'));
});
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reinit: (state, action) => {
      state.validToken = true;
    },
  },
  extraReducers: {
    [authorizeUser.fulfilled]: (state, action) => {
      state.validToken = true;
      state.user = action.payload;
    },
    [logout.fulfilled]: (state, action) => {
      state.validToken = false;
      state.user = {};
    },
    [reinit.fulfilled]: (state, action) => {
      state.validToken = true;
      state.user = action.payload;
    },
    [loggedUser.fulfilled]: (state, action) => {
      state.logged = action.payload;
    },
  },
});

const { reducer } = userSlice;
export default reducer;
