import { Avatar, Grid, IconButton, Tooltip } from '@mui/material'
import { useSnackbar } from 'notistack'
import React from 'react'
import FileBase64 from 'react-file-base64'
import { useTranslation } from 'react-i18next'
import Toast from '../../components/ToastContainer/CustomToast'
import ClearIcon from '@mui/icons-material/Clear'

export default function UploadCompanyProfile(props) {
  const { t } = useTranslation()
  const [profile, setProfile] = React.useState('cameraplus2')
  const [file, setFile] = React.useState({})
  const { enqueueSnackbar } = useSnackbar()

  const [state, setState] = React.useState({
    companyLogo: '',
  })
  const handleProfile = (file) => {
    debugger
    if (
      file.type === 'image/png' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' 
    ) {
      let fileSize = file.size.split(' ')[0]
      let size = parseInt(fileSize)
      if (size <= 2048) {
        setFile(file)
        setState({
          ...state,
          companyLogo: file.base64.split(',')[1],
        })
        props.onsetProfile(file)
        setProfile(file.base64)
      } else {
        setFile({}) // Reset file state
        Toast(t('IMAGE_SIZE_LESS_ERROR'), 'error')
      }
    } else {
      setFile({}) // Reset file state
      Toast(t('VALID_IMAGE_ERROR'), 'error')
    }
  }

  const handleClearProfile = () => {
    setProfile('')
    setFile(null)
    props.onsetProfile('')
  }

  return (
    <>
      <Grid
        conatiner
        display={'flex'}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <div
          style={{
            borderStyle: props.style ? props.style : 'dashed',
            borderRadius: '50%',
            padding: '5px ',
            color: '#D2D2D2',
          }}
        >
          <Tooltip title={props.companyLogo ? t('PROFILE_UPLOAD') : ''}>
            <IconButton
              variant="contained"
              component="label"
              style={{
                position: 'relative',
                width: '210px',
                height: '210px',
                font: 'normal normal normal 18px/24px Roboto',
                letterSpacing: '0px',
              }}
              disabled={props.disabled}
            >
              {props.companyLogo ? (
                <Avatar
                  className="ImageContent"
                  alt=""
                  src={props.companyLogo}
                  style={{
                    height: '200px',
                    width: '200px',
                    zIndex: '99',
                    borderRadius: '50%',
                  }}
                />
              ) : (
                <>
                  <Avatar
                    className="ImageContent"
                    alt=""
                    src={profile}
                    style={{
                      height: '100%',
                      width: '100%',
                      borderRadius: '50%',
                      backgroundColor: '#F4F6F8',
                    }}
                    sx={[
                      () => ({
                        '& .MuiAvatar-img': {
                          width: '49px',
                          height: '42px',
                          textAlign: 'center',
                          objectFit: 'cover',
                          color: 'transparent',
                          textIndent: '10000px',
                          borderStyle: 'none !important',
                        },
                      }),
                    ]}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: '68%',
                      left: '30%',
                      zIndex: '1',
                      color: '#C3C5C7',
                      justifyContent: 'center',
                    }}
                  >
                    {' '}
                    {t('PURPOSEPIC')}{' '}
                  </div>
                  <Grid style={{ display: 'none' }}>
                    <FileBase64
                      multiple={false}
                      onDone={(file) => handleProfile(file)}
                    />
                  </Grid>
                </>
              )}
              <Grid style={{ display: 'none' }}>
                <FileBase64
                  multiple={false}
                  onDone={(file) => handleProfile(file)}
                />
              </Grid>
            </IconButton>
          </Tooltip>
          {props.companyLogo && props.disabled === false && (
            <Tooltip
              title={t('CLEAREPROFILEPHOTO')}
              style={{ left: '300%', top: '100%' }}
            >
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <ClearIcon
                  style={{
                    color: 'white',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    position: 'absolute',
                    marginTop: '50px',
                    marginLeft: '-22px',
                    transform: 'translate(-50%, 50%)',
                    zIndex: 1,
                  }}
                  onClick={handleClearProfile}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <Grid
          direction="row"
          display="flex"
          alignItems="flex-end"
          style={{
            color: '#C3C5C7',
            opacity: 1,
            fontSize: '12px',
            paddingTop: '34px',
            justifyContent: 'center',
          }}
        >
          {t('PURPOSECON')}
        </Grid>
      </Grid>
    </>
  )
}

UploadCompanyProfile.defaultProps = {
  disabled: false,
}
