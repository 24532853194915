import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import VisitTypeService from '../services/VisitTypeService';

const initialState = [];
export const listVisitorType = createAsyncThunk('visitType/', async (payload) => {
    const res = await VisitTypeService.getAll(payload);
    return res.data;
});

export const addPurposeType = createAsyncThunk('visitType/add', async (payload) => {
    const res = await VisitTypeService.addPurposeType(payload);
    return res.data;
});

export const updateVisitorType = createAsyncThunk('visitType/update', async (payload) => {
    const res = await VisitTypeService.updateVisitorType(payload);
    return res.data;
});

const visitType = createSlice({
    name: 'visitType',
    initialState,
    extraReducers: {
        [listVisitorType.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = visitType;
export default reducer;