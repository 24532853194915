import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import DialogBox from "./DialogBox";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { ReactComponent as DropDownIcon } from "../../../src/assets/DropDown.svg";
import { ReactComponent as DropUpIcon } from "../../../src/assets/DropUp.svg";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import {
  getRestrictTypeListV2,
  restrictVisitorV2,
} from "../../slices/BlockVisitor/BlockVisitorSlice";
import Toast from "../ToastContainer/CustomToast";
import CustomDropdown from "../CustomInputs/CustomDropdown";
import CustomTextfield from "../CustomInputs/CustomTextfield";
import AutocompleteComponent from "../CustomInputs/AutocompleteComponent";

export default function BlockVisitorForm(props) {
  const [checked, setChecked] = React.useState(false);
  const dispatch = useDispatch();
  // const { open, handleClose } = props;
  const [open, setOpen] = React.useState(false);
  const [icon, setIcon] = React.useState(false);
  const [restrictTypes, setRestrictTypes] = React.useState([]);

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );

  const [payload, setPayload] = useState({
    id: props.BlockVisitorId,
    restrictedBy: decoded_jwtToken.firstName + " " + decoded_jwtToken.lastName,
    restrictReason: "",
    restrictReasonType: "",
    visitorRegistrationId: props.BlockRegistrationId,
    isRemoveAccess: false,
  });

  const [error, setError] = useState({
    restrictedBy: false,
    restrictReason: false,
    restrictReasonType: false,
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name);
    if (name === "isRemoveAccess") {
      setPayload({
        ...payload,
        [name]:
          name === "isRemoveAccess" ? event.target.checked : event.target.checked,
      });

    } else {
      setPayload({
        ...payload,
        [name]: value,
      });
    }

    setError({
      [name]: false,
    });
  };


  const ITEM_HEIGHT = 70;
  const ITEM_PADDING_TOP = 2;

  const MenuProps = {
    PaperProps: {
      style: {
        opacity: "1",
        color: "#242424",
        fontSize: "17px",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        marginLeft: "9.5px",

        width: 440,
      },
    },
  };

  const onFail = (data) => {
    switch (data.code) {
      case "CVAE0071":
        setError({ restrictReasonType: true });
        break;
      case "CVAE0070":
        setError({ restrictReason: true });
        break;
      default:
        break;
    }
    Toast((data.message), 'error');
  };

  const handleSumbit = () => {
    const newPayload = {
      id: props.BlockVisitorId,
      restrictedBy: payload.restrictedBy,
      restrictReason: payload.restrictReason,
      restrictReasonType: payload.restrictReasonType,
      visitorRegistrationId: payload.visitorRegistrationId,
      removeAccess: payload.isRemoveAccess ? "Yes" : "No",
    };

    dispatch(restrictVisitorV2(newPayload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0062") {
          props.blockvisit();
          {process.env.REACT_APP_ENVIRONMENT === 'USA'?Toast('Visitor blacklisted successfully', 'success'):Toast('Visitor Blocked Successfully', 'success')}
          props.handleClose();
        } else {
          onFail(data);
        }
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const payload = {
      isPageable: false,
      pageNumber: "",
      pageSize: "",
      code: "",
      name: "",
    };

    dispatch(getRestrictTypeListV2(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVSI0000") {
          setRestrictTypes(data.data.item);
        } else {
          setRestrictTypes([]);
        }
      });
  };

  		
  const onSelectChange = (event) => {
    setPayload({
      ...payload,
      restrictReasonType: event.id,
    });
  };

  return (
    <>
      <DialogBox
        Header={process.env.REACT_APP_ENVIRONMENT === 'USA' ? "Blacklist Visitor" : "Block Visitor"}
        acceptText="Block"
        cancelText="Cancel"
        fullWidth={true}
        open={props.open}
        onClose={props.handleClose}
        cancel={props.handleClose}
        onSubmit={handleSumbit}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          style={{ marginTop: "35px" }}
        >
          <Grid item xs={8.8} sm={8.8} style={{ marginBottom: '10px' }}>
            <AutocompleteComponent
              defaultSelect={'Select Restrict Type'}
              label="Restrict Type *"
              name="restrictReasonType"
              value={payload.restrictReasonType}
              error={error.restrictReasonType}
              options={restrictTypes}
              onSelect={(e) => onSelectChange(e)}
               />
          </Grid>

          <Grid item xs={8.8} sm={8.8}>
            <CustomTextfield
              label="Reason *"
              name="restrictReason"
              handleChange={(e) => handleChange(e)}
              value={payload.restrictReason}
              error={error.restrictReason}
            />
          </Grid>

          <Grid item xs={10} sm={10}>
            <Box display="flex" justifyContent="flex-end">
              <FormControlLabel
                control={
                  <Checkbox
                  style={{ color: process.env.REACT_APP_BG_ICON }}
                    checked={payload.isRemoveAccess}
                    onChange={handleChange}
                    name="isRemoveAccess"
                  />
                }
                style={{
                  marginRight: "255px",
                  marginTop: "10px",
                  fontSize: "17px",
                }}
                label="Remove access immediately"
              />
            </Box>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
