import React, { useEffect, useState, useContext } from "react";
import DataTable from "../../components/DataTable/DataTable";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HeaderToolbar from "../../components/HeaderToolbar";
import Search from "../../components/SearchTab/Search";
import { Box, Grid, Link, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditChecklistIcon from "../Checklist/EditChecklistIcon";
import FilterChecklistTemplate from "../ChecklistTemplateUSA/FilterChecklistTemplate";
import DeleteChecklist from "../Checklist/DeleteChecklist";
import AddUserRole from "./AddUserRole";
import { getAllUserRoles, listofRoles } from "../../slices/UserRole/UserRoleSlice";
import DeleteUserRole from "./DeleteUserRole";
import RoelIcon from "../../assets/USA_Images/roleIcon.svg";
import AddUserIcon from "./AddUserIcon";
import AddUserForm from "./AddUserForm";
import { getEmployeeID, getUserById, listAllEmployees, viewEmployeeById } from "../../slices/Employee/EmployeeSlice";
import UpdateUserRole from "./UpdateUserRole";
import Toast from "../../components/ToastContainer/CustomToast";
import { ThemeContext } from '../../theme/ThemeContext';

export default function UserRole(props) {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [openDeleteUserRole, setOpenDeleteUserRole] = useState(false);
  const [userRoleId, setUserRoleId] = useState();
  const [roleName, setRoleName] = useState();
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [data, setData] = useState([]);
  const [userId, setuserId] = useState("");
  const [del,setDel]=useState(false);
  const[pageSize,setPageSize]=useState(0);
  const { darkMode } = useContext(ThemeContext);

  const [selectedObject, setSelectedObject] = useState({
    roleId: "",
    name: "",
    user: "",
    scope: 'CUSTOM',
    description: "",
  });

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    name: "",
    roleScope:"APPLICATION"
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    name: "",
    roleScope:"APPLICATION"
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setRowsPerPage(size);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  useEffect(() => {
    loadEmployee();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const loadData = (filter, paging) => {
  //   dispatch(getAllUserRoles(Object.assign(filter, paging)))
  //     .unwrap()
  //     .then((data) => {
  //       if ((data.code = "UASI0000" && data.data)) {
  //         setTotalRecords(data.data.totalCount);
  //         setPage(data.data.currentPage);
  //         setTotalPages(data.data.totalPages);
  //         setRows(data.data.roles);
  //         setPageSize(data.data.pageSize)
  //       } else {
  //         setRows([]);
  //       }
  //     })
  //     .catch((er) => { });
  // };

  const loadData = (filter ,paging) => {
   dispatch(listofRoles(Object.assign(filter,paging)))
    .unwrap()
    .then(data => {
      if((data.code === "UASI0000" && data.data)){
        setTotalRecords(data.data.totalCount);
                setPage(data.data.currentPage);
                setTotalPages(data.data.totalPages);
                setRows(data.data.roles);
                setPageSize(data.data.pageSize)
      }
    })
  }

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      name: data.name,
    });
  };

  useEffect(() => {
    
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter,{ page: page,rowsPerPage: rowsPerPage});
      setDel(false);
    } else {
      if (del === true) {
        setPaging({ page: page-1,rowsPerPage: rowsPerPage})
        setDel(false);
      }
    }
  }, [del]);

  const handleEditVisit = (params) => {
    // setOpenEditDialog(!openEditDialog);
    // setSelectedObject(params);
    Toast("Predefined role can’t modified" , "error")
  };

  const handleAddUser = (params) => {
    setOpenAddUserDialog(!openAddUserDialog);
    setSelectedObject(params);
  }

  const handleDeleteUserRole = (params) => {
    // setOpenDeleteUserRole(!openDeleteUserRole);
    // setSelectedObject(params);
    // setUserRoleId(params.id);
    // setRoleName(params.row.name);
    Toast("Predefined role can’t deleted" , "error")
  };

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (test.name ? test.name.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || test.description.toLowerCase().includes(searchedVal.toLowerCase())

    });
    setRows(filteredRows);
  };

  const loadEmployee = () => {
    dispatch(getUserById(userId))
      .unwrap()
      .then((data) => {
        if ((data.code = "UASI0000" && data.data)) {
          setData(data.data.firstName);
        } else {
          setData([]);
        }
      })
      .catch((er) => { });
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
        return text.substring(0, 20) + '...';
    } else {
    return text;
}
}

  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "name",
      headerName: t("ROLE"),
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.name}`} placement="bottom-start">
           <span>{handleTextOverflow(params.row.name)}</span>
           </Tooltip>
            ),
    },

    {
      field: "description",
      headerName: t("ATYPE009"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.description}`} placement="bottom-start">
           <span>{handleTextOverflow(params.row.description)}</span>
           </Tooltip>
           ),
    },

    // {
    //   field: "Actions",
    //   headerName: "Action",
    //   type: "actions",
    //   flex: 1,
    //   minWidth: 190,
    //   width: 150,
    //   headerAlign: "center",
    //   getActions: (params) => [

    //     <GridActionsCellItem
    //       icon={<EditChecklistIcon />}
    //       label="Edit"
    //       onClick={() => handleEditVisit(params.row)}
    //     />,

    //     // <GridActionsCellItem
    //     //   icon={<AddUserIcon />}
    //     //   label="Add"
    //     //   onClick={() => handleAddUser(params.row)}
    //     // />,

    //     <GridActionsCellItem
    //       icon={<DeleteChecklist />}
    //       label="Delete"
    //       onClick={() => handleDeleteUserRole(params)}
    //     />,

    //   ],
    // },

  ]);

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          <Box style={{ margin: '16px, 0' }}><Typography variant="h3" style={{ marginLeft: darkMode ? '33px' : '0px', marginRight: darkMode ? '18px' : '12px', color: darkMode ? '#fff' : '#3D4977', marginTop: darkMode ? '3px': '4px' }}>{t('USERROLE')}</Typography></Box>
        </Grid>
        {/* <Box style={{
          textAlign: "center",
          padding: '10px',
          backgroundColor: process.env.REACT_APP_BG_ICON,
          borderRadius: "50%",
          cursor: 'pointer',
          width: '45px', height: '45px'
        }}
        > */}
          {/* <Tooltip title='Add Role'>
            <img
              alt="..."
              src={RoelIcon}
              onClick={handleOpen}
              style={{ width: '20px' }}
            />
          </Tooltip> */}
        {/* </Box> */}
      </Box>

      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        style={{ marginLeft: darkMode ? '-15px' : '0' }}
      >
        {/* <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box> */}

        {/* <Box item>
          <FilterChecklistTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box> */}

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <AddUserRole
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            handleClose();
            loadData(filter, paging);
          }}
        />
      )}

      {openEditDialog && (
        <UpdateUserRole
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false);
            loadData(filter, paging);
            setSelectedObject({
              roleId: "",
              name: "",
              user: "",
              scope: 'CUSTOM',
              description: "",
            });
          }}
        />
      )}

      {openDeleteUserRole && (
        <DeleteUserRole
          open={openDeleteUserRole}
          handleClose={(data) => {
            setOpenDeleteUserRole(data);
          }}
          delete={()=>setDel(true)}
          DeleteId={userRoleId}
          DeleteRoleName={roleName}
        />
      )},

      {openAddUserDialog && (
        <AddUserForm
          selectedObject={selectedObject}
          open={openAddUserDialog}
          handleClose={(data) => {
            setOpenAddUserDialog(data);
            handleClose(loadData(filter, paging));
          }}
        />
      )},





    </>
  )
}


