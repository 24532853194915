import React from 'react';
import { Paper, Typography } from '@mui/material'
import {Grid , Box} from '@mui/material'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield';
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons';


function CreateLevel() {

   
  return (
    <>
      
      <Paper  sx={{backgroundColor:"#F2F7FF"}}>

      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginLeft:"121px",
          // marginTop:"103px"
         
        }}
      >
        <Box sx={{ m: 2 ,}}>
          <Typography
            gutterBottom
            variant="h3"
            sx={{opacity:1,}}>
           &lt; Create Level
          </Typography>
        </Box>
      </Grid>

        <Paper sx={{bgcolor:"#FFFFFF" , width:"100%" , height:"890px"}}>

        <Stack   direction="column">
       

                <CustomTextfeild
                    label={'Create Level'}
                    
                    required={true}

                    sx={{width:"570px",borderColor: '#707070' , borderWidth:"1" , borderStyle:"solid", ml:"40px", borderRadius:"8px", mt:"40px"}}/>

                        <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={10}
                        variant="outlined"
                        
                        sx={{width:"570px" , mt:"31px" , ml:"40px",borderRadius:"8px",  borderColor:"#707070"}}/>
        </Stack> 



        <Grid container lg={4} ></Grid>
                <Grid container lg={8} justifyContent='flex-end' sx={{ mt: '449px',ml:"512px" }} >
                    
                <Grid  pr={2}>
                  <CancelButtons
                    variant="contained"
                    color="secondary">
                    <Typography style={{ textTransform: "none" }}>
                      {'Cancel'}
                    </Typography>
                  </CancelButtons>
                </Grid>

                <Grid pr={2} >
                  <SubmitButtons
                    variant="contained">
                    <Typography >
                      {'Submit'}
                    </Typography>
                  </SubmitButtons>
                </Grid>
                
              </Grid>
        </Paper>

      </Paper>


    </>
  )
}

export default CreateLevel
