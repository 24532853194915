import React from 'react'

const settingWhite = () => {
  return (
    <svg className='MuiSvgIcon-root' xmlns="http://www.w3.org/2000/svg" width="19.284" height="20.722" viewBox="0 0 19.284 20.722">
      <g id="setting-white" transform="translate(-0.03 -0.028)">
        <path id="Path_3856" data-name="Path 3856" d="M9.043.028H10.3A2.663,2.663,0,0,1,12.961,2.75a1.083,1.083,0,0,0,.151.529,1.149,1.149,0,0,0,.707.536,1.163,1.163,0,0,0,.876-.123l.008,0a2.664,2.664,0,0,1,1.322-.353h0a2.672,2.672,0,0,1,2.309,1.334l.622,1.08A.75.75,0,0,1,19,5.836,2.672,2.672,0,0,1,17.99,9.38l-.006,0a1.127,1.127,0,0,0-.418.418l0,0a1.154,1.154,0,0,0,.422,1.581,2.654,2.654,0,0,1,.97,3.636l0,.008-.657,1.094a2.662,2.662,0,0,1-3.629.964,1.2,1.2,0,0,0-.553-.156,1.146,1.146,0,0,0-.813.338,1.162,1.162,0,0,0-.338.822s0,0,0,.007A2.669,2.669,0,0,1,10.3,20.75H9.043A2.666,2.666,0,0,1,6.38,18.092a1.082,1.082,0,0,0-.151-.537l-.006-.01a1.145,1.145,0,0,0-1.571-.42l-.011.006A2.663,2.663,0,0,1,1.014,16.1L.386,15.021A2.658,2.658,0,0,1,1.354,11.4a1.163,1.163,0,0,0,0-2.015,2.669,2.669,0,0,1-.97-3.633l.009-.015.664-1.091A2.662,2.662,0,0,1,4.684,3.67l.01.006a1.082,1.082,0,0,0,.537.151A1.168,1.168,0,0,0,6.39,2.686,2.654,2.654,0,0,1,9.043.028Zm5.066,5.324A2.654,2.654,0,0,1,11.82,4.041a2.581,2.581,0,0,1-.359-1.287q0-.015,0-.029a1.163,1.163,0,0,0-1.163-1.2v0H9.043A1.154,1.154,0,0,0,7.89,2.687s0,.01,0,.014A2.674,2.674,0,0,1,5.227,5.327H5.219a2.584,2.584,0,0,1-1.284-.358A1.162,1.162,0,0,0,2.352,5.4l-.009.015L1.68,6.5a1.165,1.165,0,0,0,.427,1.579,2.663,2.663,0,0,1,0,4.611,1.155,1.155,0,0,0-.421,1.575l.631,1.088.007.013a1.163,1.163,0,0,0,1.581.453,2.645,2.645,0,0,1,3.619.97,2.584,2.584,0,0,1,.358,1.285s0,.005,0,.008A1.164,1.164,0,0,0,9.043,19.25H10.3A1.166,1.166,0,0,0,11.46,18.1a2.671,2.671,0,0,1,.777-1.885,2.636,2.636,0,0,1,1.876-.777h.029a2.708,2.708,0,0,1,1.268.355A1.162,1.162,0,0,0,17,15.367L17,15.358l.656-1.093a1.154,1.154,0,0,0-.424-1.578,2.654,2.654,0,0,1-.971-3.634,2.629,2.629,0,0,1,.969-.969,1.167,1.167,0,0,0,.42-1.579.75.75,0,0,1-.036-.071l-.585-1.015a1.168,1.168,0,0,0-1.01-.583h0a1.162,1.162,0,0,0-.573.152A2.656,2.656,0,0,1,14.109,5.352Z"  />
        <path id="Ellipse_2786" data-name="Ellipse 2786" d="M2.636-.75A3.386,3.386,0,1,1-.75,2.636,3.39,3.39,0,0,1,2.636-.75Zm0,5.272A1.886,1.886,0,1,0,.75,2.636,1.888,1.888,0,0,0,2.636,4.522Z" transform="translate(7.039 7.753)"  />
      </g>
    </svg>
  )
}

export default settingWhite
