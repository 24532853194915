import { Grid } from '@material-ui/core'

import jwt_decode from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { Stack } from '@mui/material'

import { useState, useEffect } from 'react'
import HeaderToolbar from '../../components/HeaderToolbar'
import DashBoardData from './DashBoardData'
import GoogleMapReact from 'google-map-react'
import WhatsNewDialog from '../../components/DialogBoxComponent/WhatsNewDialog'
import {
  getListSettingsById,
  getVersion,
} from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import StripeSetupDialog from '../../components/DialogBoxComponent/StripeSetupDialog'
import { listPaymentMethods } from '../../slices/Subscription/SubscriptionSlice'
import { useTranslation } from 'react-i18next'

const AnyReactComponent = ({ text }) => <div>{text}</div>

export default function AdminDashboard() {
  const [count, setCount] = useState(4)
  const [openDialog, setOpenDialog] = useState(false)
  const [viewedVersion, setViewedVersion] = useState()
  const [setting, setSetting] = useState()
  const [latestVersion, setLatestVersion] = useState()
  const [showPopup, setShowPopup] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const repeatedItems = Array.from({ length: count }, (_, index) => ({
    id: index + 1,
    name: 'ABC Infotech',
    description: 'Subscription Expiry',
  }))

  useEffect(() => {
    verifyDealer()
  }, [])

  setTimeout(function () {
    if (openDialog) {
      window.location.reload()
    }
  }, 10000)

  const verifyDealer = () => {
    if (localStorage.getItem('companyType') === 'PARTNER') {
      dispatch(listPaymentMethods(localStorage.getItem('companyId')))
        .unwrap()
        .then((data) => {
          setOpenDialog(!data ? true : false)
        })
        .catch((er) => {})
    }
  }

  const handleCount = (params) => {
    setCount(params)
  }

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 1,
  }

  const defaultCenter = { lat: 0, lng: 0 }
  const defaultZoom = 2

  const mapOptions = {
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  }

  useEffect(() => {
    loadSetting()
  }, [])

  const loadSetting = () => {
    dispatch(getListSettingsById(decoded_jwtToken.companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setSetting(data.data)
          setLatestVersion(data.data.latestVersion)
          if (data.data.viewedVersion === data.data.latestVersion)
            setShowPopup(false)
          else setShowPopup(true)
        }
      })
  }

  const handleMapHover = () => {}

  return (
    <>
      <HeaderToolbar title={t('LABELNAVITEMSDASHBOARD')} />
      <Stack direction="row" spacing={2} style={{ marginTop: '10px' }}>
        <DashBoardData count={handleCount} />

        <Grid xl={3} lg={3} md={3} sm={3} className="mapcheck">
          <div
            style={{ height: '300px', width: '100%', cursor: 'pointer' }}
            onMouseEnter={() => handleMapHover()}
            className="Soma"
          >
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyAjq4ciG3JCDMYQgkr-Pjlz4_mowoucrmc',
              }}
              defaultCenter={defaultCenter}
              defaultZoom={defaultZoom}
              yesIWantToUseGoogleMapApiInternals
              options={{
                mapTypeControl: true,
                mapTypeId: 'roadmap',
                ...mapOptions,
              }}
            >
              <AnyReactComponent lat={0} lng={0} text="Center" />
            </GoogleMapReact>
          </div>

          {showPopup && (
            <WhatsNewDialog
              open={showPopup}
              Header={t('CUSTDATABLE34')}
              onClose={() => {
                setShowPopup(!showPopup)
              }}
              latestVersion={latestVersion}
              settings={setting}
            />
          )}
        </Grid>
      </Stack>

      {openDialog && <StripeSetupDialog open={openDialog} />}
    </>
  )
}
