import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';

const PortraitIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();

  const handlePortrait = () => {
    setIsShown(true)
  };

  return (
    <>
      <Grid
        // onClick={handlePortrait}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        {isShown ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="26" viewBox="0 0 18 26">
            <path id="Path_7249" data-name="Path 7249" d="M28,9.06a1.31,1.31,0,0,1-.06.27v.09a1.07,1.07,0,0,1-.19.28h0l-6,6h0a1.07,1.07,0,0,1-.28.19h-.1a1.1,1.1,0,0,1-.31.11H5a3,3,0,0,1-3-3V1A3,3,0,0,1,5-2H25a3,3,0,0,1,3,3V9.06Zm-6,3.53L24.59,10H23a1,1,0,0,0-1,1ZM26,1a1,1,0,0,0-1-1H5A1,1,0,0,0,4,1V13a1,1,0,0,0,1,1H20V11a3,3,0,0,1,3-3h3Z" transform="translate(2 28) rotate(-90)" fill="#263238" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="26" viewBox="0 0 18 26">
            <path id="Path_7249" data-name="Path 7249" d="M28,9.06a1.31,1.31,0,0,1-.06.27v.09a1.07,1.07,0,0,1-.19.28h0l-6,6h0a1.07,1.07,0,0,1-.28.19h-.1a1.1,1.1,0,0,1-.31.11H5a3,3,0,0,1-3-3V1A3,3,0,0,1,5-2H25a3,3,0,0,1,3,3V9.06Zm-6,3.53L24.59,10H23a1,1,0,0,0-1,1ZM26,1a1,1,0,0,0-1-1H5A1,1,0,0,0,4,1V13a1,1,0,0,0,1,1H20V11a3,3,0,0,1,3-3h3Z" transform="translate(2 28) rotate(-90)" fill="#0f5a9c" />
          </svg>
        )}
      </Grid>
    </>
  );
};
export default PortraitIcon;
