import { Grid } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import { useDispatch } from 'react-redux'
import CommonUtil from '../../Util/CommonUtils'
import Toast from '../../components/ToastContainer/CustomToast'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../../theme/ThemeContext';
import {
  createVisitorType,
  updateVisitorType,
} from '../../slices/VisitorType/VisitorTypeSlice'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}))

export default function AddVisitorType(props) {
  const classes = useStyles()
  const { open, handleClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [str, setStr] = useState('')
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = useState({
    id: '',
    name: '',
    description: '',
  })

  const [error, setError] = React.useState({
    id: false,
    name: false,
    description: false,
  })

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  const handleSubmit = () => {
    if (CommonUtil.isEmpty(payload.name)) {
      Toast(t('ENTER_NAME'), 'error')
      setError({ name: true })
      return
    }

    if (CommonUtil.checkCharactersGreaterThan250(payload.description)) {
      Toast(t('PURPOSE1'), 'error')
      setError({ description: true })
      return
    }

    const newTemplate = {
      id: props.selectedObject.id,
      name: payload.name,
      description: payload.description,
    }

    if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      dispatch(createVisitorType(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0038') {
            Toast(t('VISITORADD'), 'success')
            handleClose()
          } else {
            Toast(data.message, 'error')
          }
        })
    } else {
      dispatch(updateVisitorType(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0039') {
            Toast(t('VISITORADD1'), 'success')
            handleClose()
          } else {
            Toast(data.message, 'error')
          }
        })
    }
  }

  useEffect(() => {
    if (props.selectedObject.id) {
      setPayload({
        ...payload,
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        description: props.selectedObject.description,
      })
    }
  }, [])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <DialogBox
        // Header={payload.id ? t('ADD031') : t('ADD032')}
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
           {payload.id ? t('ADD031') : t('ADD032')}
          </span>
        }
        acceptText={t('COMMON035')}
        cancelText={t('dataTableCancel')}
        style={{ color: '#3D4977', opacity: 1, minHeight: '' }}
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: '16px 32px', width: '100%', backgroundColor: darkMode ? '#0F273B' : '#fff' }}
          spacing={2}
        >
          <Grid item xs={12}>
            <CustomTextfield
              type={'text'}
              label={t('COMMON014')}
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
              required={true}
              inputProps={{ className: classes.input, maxLength: 30 }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              type={'text'}
              label={t('ATYPE009')}
              error={error.description}
              name="description"
              value={payload.description}
              handleChange={(e) => handleChange(e)}
              helperText={error.description}
              inputProps={{ className: classes.input, maxLength: 120 }}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
