import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
    let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
  
    if (!CommonUtil.isEmptyString(filter.firstName)) {
      query = query + "&firstName=" + filter.firstName;
    }
    if (!CommonUtil.isEmptyString(filter.lastName)) {
      query = query + "&lastName=" + filter.lastName;
    }
    if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
      query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
    }
    if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
      query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
    }
    if (!CommonUtil.isEmptyString(filter.email)) {
      query = query + "&email=" + filter.email;
    }
    if (!CommonUtil.isEmptyString(filter.phone)) {
      query = query + "&phone=" + filter.phone;
    }
    if (!CommonUtil.isEmptyString(filter.purposeTypeId)) {
      query = query + "&purposeTypeId=" + filter.purposeTypeId;
    }
    if (!CommonUtil.isEmpty(filter.isRestricted)) {
      query = query + "&isRestricted=" + filter.isRestricted;
    }
    if (!CommonUtil.isEmptyString(filter.siteId)) {
      query = query + "&siteId=" + filter.siteId;
    }
    if (!CommonUtil.isEmptyString(filter.hostId)) {
      query = query + "&hostId=" + filter.hostId;
    }
    if (!CommonUtil.isEmptyString(filter.visitMode)) {
      query = query + "&visitMode=" + filter.visitMode;
    }
    if (!CommonUtil.isEmpty(filter.status)) {
      query = query + "&status=" + filter.status;
    }
  
    return query;
  };
  
  const listAllBlacklistVisitors = (filter) => {
    return api.securedAxios().get("/web/visit/blacklist" + buildQuery(filter));
  };

const blacklistVisitor = (payload) => {
   return api.securedAxios().post("/web/visit/blacklist/" + payload.visitId, payload);
}

const unblacklistVisitor = (payload) => {
    return api.securedAxios().put("/web/visit/unblacklist/" + payload.visitId, payload);
}

const blacklistVisitorService = {
    listAllBlacklistVisitors,
    blacklistVisitor,
    unblacklistVisitor,
}

export default blacklistVisitorService;