import { Avatar, Box, Grid, Link, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable";
import Search from "../../components/SearchTab/Search";
import { getListSettings } from "../../slices/AdditionalSettings/AdditionalSettingsSlice";
import { getAllDeliveryReports } from "../../slices/Reports/DeliveryReportSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import CommonUtil from "../../Util/CommonUtils";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import DeliveryReportView from "./DeliveryReportView";
import FilterDeliveryReport from "./FilterDeliveryReport";

export default function DeliveryReport() {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const { darkMode } = useContext(ThemeContext);

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";


  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    deliveresStartDate: "",
    deliveresEndDate: "",
    deliveryCompanyName: ""
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    deliveresStartDate: "",
    deliveresEndDate: "",
    deliveryCompanyName: ""
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadData = (filter, paging) => {
    dispatch(getAllDeliveryReports(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0000" && data.data)) {
          setTotalRecords(data.data.totalCount);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
          setRows(data.data.deliveryReports);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      deliveresStartDate: CommonUtil.formatToUtc(data.deliveresStartDate),
      deliveresEndDate: CommonUtil.formatToUtc(data.deliveresEndDate),
      deliveryCompanyName: data.deliveryCompanyName
    });
  };

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.deliveryPersonName ? test.deliveryPersonName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.deliveryCompanyName ? test.deliveryCompanyName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.remarks ? test.remarks.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.createdAt ? test.createdAt.toLowerCase().includes(searchedVal.toLowerCase()) : "")
      );
    });
    setRows(filteredRows);
  };

  const [date, setDate] = useState()

  useEffect(() => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setDate(formatDate(data.data.dateFormat));
        }
      })
  }, [])

  const formatDate = (dateFormat) => {
    switch (dateFormat) {
      case 'dd-MM-yyyy':
        return 'dd-MM-yyyy HH:mm';
      case 'MM-dd-yyyy':
        return 'MM-dd-yyyy HH:mm';
      case 'yyyy-MM-dd':
        return 'yyyy-MM-dd HH:mm';
      default:
        return 'yyyy-MM-dd HH:mm';
    }
  };

  const renderCell = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.createdAt)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 25) {
      return text.substring(0, 25) + '...'
    } else {
      return text
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          alt={params.row.personImageObjectKey}
          src={params.row.personImageObjectKey !== undefined ? params.row.personImageObjectKey : null}
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: "300px",
            height: "300px",
            border: "1px solid #FFFF",
            fontSize: "100px",
            borderRadius: "50%",
          }}
          alt={params.row.personImageObjectKey}
          src={params.row.personImageObjectKey !== undefined ? params.row.personImageObjectKey : null}
        ></Avatar>
      }
    />
  );

  const columns = React.useMemo(() => [
    {
      field: "Person Image",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        // return ZoomHandaler(params);
      },
    },
    {
      field: "deliveryPersonName",
      headerName: t('PERSON_NAME'),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.deliveryPersonName,
    },

    {
      field: "deliveryCompanyName",
      headerName: t('DELIVERY_COMPANY'),
      flex: 1,
      minWidth: 290,
      valueGetter: (params) => params.row.deliveryCompanyName,
    },

    {
      field: "createdAt",
      headerName: t('DELIVERY_TIME'),
      flex: 1,
      width: 10,
      renderCell: renderCell(date),
    },

    {
      field: "remarks",
      headerName: t('COMMON016'),
      flex: 1,
      minWidth: 290,
      valueGetter: (params) => params.row.remarks,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.remarks}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.remarks)}</span>
        </Tooltip>
      ),
    },

    {
      field: "Actions",
      headerName: t('CUSTDATABLE16'),
      type: "actions",
      flex: 1,
      minWidth: 150,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeliveryReportView
            title="Delivery Report Details"
            rowData={params.row} />}
          label="View"
        />,
      ],
    },
  ], [date]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid style={{ display: "flex", cursor: "pointer" }}>
          <Box style={{ margin: "16px, 0" }}>
            <Typography
              variant="h3"
              style={{
                marginLeft: darkMode ? '33px' : '0px',
                marginRight: darkMode ? '18px' : '12px',
                color: darkMode ? '#fff' : '#3D4977',
                marginTop: darkMode ? '3px' : '4px',
              }}
            >
              {t('SUBSCRIPTIONDELIVERREPORT')}
            </Typography>

          </Box>
        </Grid>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        sx={{ marginLeft: darkMode ? '-16px' : '' }}
      >
        <Box item >
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterDeliveryReport
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
            date={date}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
    </>
  );
}
