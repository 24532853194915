import { makeStyles } from '@material-ui/core'
import {
  Box,
  Button,
  Grid,
  TextField,
  TextareaAutosize,
  Typography
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { DataGrid } from '@mui/x-data-grid'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as XLSX from 'xlsx'
import xlslogo from '../../../src/assets/USA_Images/dashboard_icons/xls.svg'
import httpCommon from '../../config/http-common'
import EmployeeEditIcon from '../../Pages/Employee/EmployeeEditIcon'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { listHost } from '../../slices/HostSlice'
import { createMultipleInvitation } from '../../slices/Invitations/InvitationsSlice'
import { listVisitorTypeZlink } from '../../slices/PurposeTypeUSA/PurposeTypeUSASlice'
import { getAllSites } from '../../slices/Site/SiteSlice'
import { getAllVisitorType } from '../../slices/VisitorType/VisitorTypeSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import CommonUtil from '../../Util/CommonUtils'
import CustomDateTimePicker from '../CustomInputs/CustomDateTimePicker'
import CustomTextfield from '../CustomInputs/CustomTextfield'
import ProfileContext from '../ProfileContext'
import Toast from '../ToastContainer/CustomToast'
import BulkInvitationDialog from './BulkInvitationDialog'
import BulkInvitationEditDialog from './BulkInvitationEditDialog'

const useStyles = makeStyles((theme) => ({
  dialogbox: {
    '& .css-hlj6pa-MuiDialogActions-root': {
      marginLeft: '700px !important',
    },
    '& .MuiDialog-paper': {
      // minWidth: '110vh',
      minWidth: '1000px',
      marginLeft: '250px',
      backgroundColor: (darkMode) => darkMode ? '#0F273B' : '#fff',
    },
    '.& .css-f3jnds-MuiDataGrid-columnHeaders': {
      backgroundColor: '#F3F9FB',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '35px',
  },
  headerItem: {
    backgroundColor: '#226BAB',
    color: 'white',
  },
  datagrid: {
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: 'aliceblue',
      // color: '#fff',
    },
    // '& .css-17jjc08-MuiDataGrid-footerContainer': {
    //   display: 'none',
    // },
  },
  errorListStyles: {
    color: "red"
  },
  successListStyles: {
    color: "green"
  }
}));

function BulkInvitation(props) {
  const { darkMode } = useContext(ThemeContext);
  const classes = useStyles(darkMode);
  const [open, setOpen] = React.useState(props.open)
  const [purposeTypeData, setPurposeTypeData] = useState()
  const [visitorData, setVisitorData] = useState([])
  const [siteData, setSiteData] = useState([])
  const [data, setData] = useState()
  const { t } = useTranslation()
  const [hostList, setHostList] = useState([])
  const { handleClose } = props
  const dispatch = useDispatch()
  const [selectedHost, setSelectHost] = useState({})
  const [errorList, setErrorList] = useState([])
  const [successList, setSuccessList] = useState([])
  const [file, setFile] = useState(null)
  const [excelFile, setExcelFile] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [editRowData, setEditRowData] = useState(null)
  const [importError, setImportError] = useState(false)
  const { setCurrentTotalVisitCount } = useContext(ProfileContext)
  const [key, setKey] = useState(0);
  const [customLocaleText, setCustomLocaleText] = useState({
    noRowsLabel: t('NOROWS'),
  })

  const [datatableValue, setDatatableValue] = useState({
    purposeTypeName: '',
    visitorTypeName: '',
    siteName: '',
    hostName: '',
    visitorCompanyName: '',
  })
  const [success, setSuccess] = useState(false)
  const [changeDate, setDateChange] = useState(true)

  var today = new Date()

  var minutes =
    props.invitationIntervalTime === ''
      ? 30
      : Number(props.invitationIntervalTime)
  const [invitationIntervalTime, setInvitationIntervalTime] = useState(minutes)

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T' +
    (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())

  const [payload, setPayload] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    phoneCountryCode: '',
    purposeTypeId: '',
    visitorTypeId: '',
    hostId: '',
    visitorCompanyName: '',
    siteId: '',
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(
      moment(startDate).add(invitationIntervalTime, 'minutes'),
    ),
    allowLateCheckOut: false,
    visitMode: 'INVITATION',
    earlyCheckin: false,
    approvalReason: '',
    verifyTypeIn: '',
    walkInScan: '',
    invitationScan: '',
    selfScan: '',
    profilePhoto: 'str.jpg',
  })

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    emailId: false,
    phoneNumber: false,
    purposeTypeId: false,
    visitorTypeId: false,
    hostId: false,
    visitorCompanyName: false,
    siteId: false,
    selectedHost: false,
    profilePhoto: false,
    approvalReason: false,
  })

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const config = {
    headers: { companyId: localStorage.getItem('companyId') },
  }

  useEffect(() => {
    if (file === null) {
      setKey(prevKey => prevKey + 1);
    }
  }, [file]);

  useEffect(() => {
    loadData(props.open)
  }, [])

  useEffect(() => {
    console.log('Success List:', successList)
  }, [successList])

  useEffect(() => {
    console.log('Error List:', errorList)
  }, [errorList])

  const loadData = () => {
    loadVisitType()
    loadVisitorType()
    loadSites()
  }

  const loadVisitType = () => {
    const query = {
      id: '',
      name: '',
      pageNumber: 0,
      pageSize: 0,
      isPageable: true,
      status: 'ACTIVE',
    }

    dispatch(listVisitorTypeZlink(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000' && data.data) {
          setPurposeTypeData(data.data.purposeTypes)
        } else {
          setPurposeTypeData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadVisitorType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getAllVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setVisitorData(data.data.visitorTypes)
          setDateChange(true)
        } else {
          setVisitorData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadSites = () => {
    const query = {
      page: 1,
      rowsPerPage: 10000,
      name: '',
    }
    dispatch(getAllSites(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000') {
          setSiteData(data.data.site)
        } else {
          setSiteData([])
        }
      })
  }

  const loadHosts = () => {
    const payload = {
      pageNumber: 1,
      pageSize: 20,
      email: decoded_jwtToken.email,
    }
    dispatch(listHost(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'OMSI0000') {
          //   getSelectedItem(data.data.employees[0]);
          setHostList(data.data.employees)
        } else {
          //   getSelectedItem();
          setHostList([])
        }
      })
  }

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    })
    setDatatableValue({
      ...datatableValue,
      purposeTypeName: event,
    })
  }

  const onselectVisitorType = (event) => {
    setPayload({
      ...payload,
      visitorTypeId: event,
    })
    setDatatableValue({
      ...datatableValue,
      visitorTypeName: event,
    })
  }

  const filterOptions = createFilterOptions({
    stringify: ({ firstName, email, lastName }) =>
      `${firstName} ${email} ${lastName}`,
    limit: 1,
  })

  const handleText = (event) => {
    httpCommon
      .unsecuredAxios()
      .get(
        '/web/selfRegistartion/employees/list?sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=1&pageSize=0&firstName=' +
        event.target.value,
        config,
      )
      .then((data) => {
        if (data.data.code === 'OMSI0000') {
          // getSelectedItem(data.data.data.employees[0]);
          setHostList(data.data.data.employees)
        } else {
          //   getSelectedItem();
          setHostList([])
        }
      })
  }

  const getSelectedItem = (newValue) => {
    if (newValue != null) {
      dispatch(getListSettings())
        .unwrap()
        .then((data) => {
          setPayload({
            ...payload,
            verifyTypeIn: data.data.invitationVerifyMode,
            invitationScan: data.data.invitationScanLimit,
            hostId: newValue.id,
          })
        })
      setPayload({
        ...payload,
        hostId: newValue.id,
      })
      setSelectHost(newValue)
    } else {
      setPayload({
        ...payload,
        hostId: '',
      })
      setSelectHost({})
    }
  }

  const handleDownloadTemplate = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new()

    // Define the header row
    const header = [
      'First Name (mandatory)',
      'Last Name',
      'Email (Either Email or Mobile is mandatory)',
      'Mobile (Either Email or Mobile is mandatory) Countrycode followed by number Ex:1-2294003759',
    ];


    // Add a worksheet to the workbook
    const ws = XLSX.utils.aoa_to_sheet([header])

    const columnWidths = [30, 30, 30, 60, 30] // Adjust the values as needed (in Excel's "characters" units)
    const range = XLSX.utils.decode_range(ws['!ref'])
    for (let columnIndex = range.s.c; columnIndex <= range.e.c; columnIndex++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: columnIndex })
      ws[cellAddress].w = header[columnIndex]
      ws['!cols'] = ws['!cols'] || []
      ws['!cols'][columnIndex] = { wch: columnWidths[columnIndex] }
    }

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    // Generate the Excel file
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

    // Convert the Excel file to a Blob
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })

    // Create a download link and simulate a click
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'Invitation.xlsx'
    link.click()

    // Clean up the URL object
    URL.revokeObjectURL(link.href)
  }

  // Utility function to convert a string to an ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  }

  const columns = [
    {
      field: 'firstName',
      headerName: t('COMMON020'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'lastName',
      headerName: t('COMMON021'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'emailId',
      headerName: t('COMMON042'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'phone',
      headerName: t('COMMON063'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'visitorCompanyName',
      headerName: t('VISUPDATE0006'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'purposeTypeName',
      headerName: t('Purpose'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'visitorTypeName',
      headerName: t('COMMON068'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'siteName',
      headerName: t('VISACCDEV005'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'scheduledStartDate',
      headerName: t('START_DATE'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'scheduledEndDate',
      headerName: t('END_DATE'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EmployeeEditIcon />}
          label="Edit"
          disabled={getStatus(params)}
          onClick={() => handleOpenEditPerson(params.row)}
        />,
      ],
    },
  ]

  const errorColumns = [
    {
      field: 'firstName',
      headerName: t('COMMON020'),
      flex: 1,
      minWidth: 190,
      width: 150,
      valueGetter: (params) => params.row.data.firstName,
    },
    {
      field: 'lastName',
      headerName: t('COMMON021'),
      flex: 1,
      minWidth: 190,
      width: 150,
      valueGetter: (params) => params.row.data.lastName,
    },
    {
      field: 'emailId',
      headerName: t('COMMON042'),
      flex: 1,
      minWidth: 190,
      width: 150,
      valueGetter: (params) => params.row.data.emailId,
    },
    {
      field: 'phone',
      headerName: t('COMMON063'),
      flex: 1,
      minWidth: 190,
      width: 150,
      valueGetter: (params) =>
        params.row.data.phoneCountryCode + '-' + params.row.data.phoneNumber,
    },
    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EmployeeEditIcon />}
          label="Edit"
          disabled={true}
          onClick={() => handleOpenEditPerson(params.row)}
        />,
      ],
    },
  ]

  const getStatus = (params) => {
    if (params.row.status === "success") {
      return true;
    }
    return false;
  }

  const handleOpenEditPerson = (params) => {
    if (importError) {
      setEditRowData(params.data)
    }
    setOpenEditDialog(!openEditDialog)
    setEditRowData(params)
  }

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0]
    setFile(uploadedFile)
    setExcelFile(uploadedFile);
  }

  const handleImport = (event) => {
    event.preventDefault()
    if (!success) {
      Toast(t('PERSONINFO19'), 'error')
      return
    }
    if (
      (purposeTypeMandatory && datatableValue.purposeTypeName == '') ||
      (visitorTypeMandatory && datatableValue.visitorTypeName == '') ||
      (siteTypeMandatory && datatableValue.siteName == '') ||
      (companyNameMandatory && datatableValue.visitorCompanyName == '') ||
      datatableValue.hostName == ''
    ) {
      Toast(t('PERSONINFO20'), 'error')
      return
    }
    if (file == null) {
      Toast(t('PERSONINFO21'), 'error')
      return
    }
    try {
      const formData = new FormData()
      formData.append('file', file)

      axios
        .post('/web/bulkInvitation/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.data.code == 'CVAE1002') {
            const newSuccessList = res.data.data.successList.map((item) => {
              return {
                ...item,
                purposeTypeId: datatableValue.purposeTypeName.id,
                purposeTypeName: datatableValue.purposeTypeName.name,
                visitorTypeId: datatableValue.visitorTypeName.id,
                visitorTypeName: datatableValue.visitorTypeName.name,
                siteName: datatableValue.siteName.name,
                hostId: datatableValue.hostName.id,
                visitMode: 'INVITATION',
                siteId: datatableValue.siteName.id,
                scheduledStartDate: payload.scheduledStartDate,
                scheduledEndDate: payload.scheduledEndDate,
                visitorCompanyName: datatableValue.visitorCompanyName,
              }
            })
            const newErrorList = res.data.data.errorList.map((item) => {
              return {
                ...item,
                purposeTypeId: datatableValue.purposeTypeName.id,
                purposeTypeName: datatableValue.purposeTypeName.name,
                visitorTypeId: datatableValue.visitorTypeName.id,
                visitorTypeName: datatableValue.visitorTypeName.name,
                hostId: datatableValue.hostName.id,
                siteId: datatableValue.siteName.id,
                siteName: datatableValue.siteName.name,
                visitMode: 'INVITATION',
                scheduledStartDate: payload.scheduledStartDate,
                scheduledEndDate: payload.scheduledEndDate,
                visitorCompanyName: datatableValue.visitorCompanyName,
              }
            })
            setSuccessList(newSuccessList)
            setErrorList(newErrorList)
            setFile(null)
            Toast(t('EMP1'), 'success')
          } else {
            Toast(res.data.message, 'error')
          }
        })

      //  alert('File uploaded successfully.');
    } catch (error) {
      alert('Failed to upload file: ' + error.message)
    }
  }

  const handleSave = (updatedRowData) => {
    const updatedErrorList = errorList.filter((row) => row.id !== updatedRowData.id);
    setErrorList(updatedErrorList);
    const updatedSuccessList = {
      ...updatedRowData,
      scheduledStartDate: payload.scheduledStartDate,
      scheduledEndDate: payload.scheduledEndDate,
      status: "success"
    };

    setSuccessList((prevList) => [...prevList, updatedSuccessList]);
    console.log(successList);
    console.log(errorList);
  };

  const handleMoreDetails = () => {
    if (purposeTypeMandatory && datatableValue.purposeTypeName === '') {
      Toast(t('PERSONINFO22'), 'error')
      return
    }
    if (visitorTypeMandatory && datatableValue.visitorTypeName === '') {
      Toast(t('PERSONINFO24'), 'error')
      return
    }
    if (siteTypeMandatory && datatableValue.siteName === '') {
      Toast(t('SITE2'), 'error')
      return
    }
    if (datatableValue.hostName === '') {
      Toast(t('PERSONINFO23'), 'error')
      return
    }

    if (companyNameMandatory && datatableValue.visitorCompanyName === '') {
      Toast(t('PERSONINFO25'), 'error')
      return
    }

    // if(payload.scheduledStartDate > payload.scheduledEndDate){
    //   Toast("Scheduled Start data cannot be grater than scheduled End data" , "error");
    //   return;
    // }
    if (
      new Date(payload.scheduledEndDate) < new Date(payload.scheduledStartDate)
    ) {
      Toast(t('INVITATIONFORM003'), 'error')
      return
    }
    setSuccess(true)
    Toast(t('PERSONINFO26'), 'success')
  }

  const emptyParams = []

  const rows = errorList.map((error, index) => {
    const row = {
      id: index + 1,
      rowNumber: index + 1,
      firstName: error.firstName,
      emailId: error.emailId,
      // phoneNumber : error.phoneNumber,
      visitorCompanyName: error.visitorCompanyName,
    }

    if (!importError) {
      if (!error.firstName || error.firstName.trim() === '') {
        emptyParams.push(`Row ${index + 1}: FirstName`)
      }
      if ((error.emailId.trim() === '' && error.phoneNumber === '')) {
        emptyParams.push(`Row ${index + 1}: Either Email or Phone Number is mandatory`)
      }
      // if (!error.phoneNumber || error.phoneNumber.trim() === "") {
      // emptyParams.push(`Row ${index + 1}: Phone Number`);
      // }
      if (companyNameMandatory) {
        if (
          !error.visitorCompanyName ||
          error.visitorCompanyName.trim() === ''
        ) {
          emptyParams.push(`Row ${index + 1}: CompanyName`)
        }
      }
    } else {
      if (error.message != '') {
        emptyParams.push(`Row ${index + 1}: ${error.message}`)
      }
    }
    return row
  })

  const emptyParamsText = emptyParams.join('\n')

  const handleSubmit = () => {
    if (emptyParamsText.length > 0) {
      Toast(t('PERSONINFO7'), 'error');
      return;
    }
    if (excelFile == null) {
      Toast(t('PERSONINFO6'), 'error');
      return;
    }
    dispatch(createMultipleInvitation(successList))
      .unwrap()
      .then((res) => {
        if (res.code === 'CVAE1006') {
          setImportError(true);
          setSuccessList([]);
          setErrorList(res.data);
          Toast(res.message, 'error');
          return;
        }
        if (res.code === 'CVAI0067' || res.code === 'CVAE0082') {
          Toast(t('PERSONINFO27'), 'success');
          setCurrentTotalVisitCount(res.data.currentVisitCount);
          handleClose();
          return;
        }
        if (res.code === 'CVAE1004') {
          Toast(res.message, 'error');
          return;
        }
        else {
          Toast(res.message, "error");
          return;
        }
      });
  };

  const handleChange = (event) => {
    const name = event.target.name
    let value = event.target.value
    if (name === 'visitorCompanyName') {
      value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    }
    setPayload({
      ...payload,
      [name]: value,
    })
    setDatatableValue({
      ...datatableValue,
      [name]: value,
    })
    setError({
      [name]: false,
    })
  }

  const purposeTypeToggle = props.purposeTypeToggle
  const visitorTypeToggle = props.visitorTypeToggle
  const companyNameToggle = props.companyNameToggle
  const siteTypeToggle = props.siteTypeToggle
  const purposeTypeMandatory = props.purposeTypeMandatory
  const visitorTypeMandatory = props.visitorTypeMandatory
  const companyNameMandatory = props.companyNameMandatory
  const siteTypeMandatory = props.siteTypeMandatory
  const dateFormat = props.date

  const formatDate = (date, dateFormat) => {
    const formattedDate = moment(CommonUtil.getLocalDateTimeInYearFormat(date)).format(
      dateFormat === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        dateFormat === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          dateFormat === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return formattedDate;
  };

  const formattedRows = errorList.concat(successList).map(row => ({
    ...row,
    scheduledStartDate: formatDate(row.scheduledStartDate, dateFormat),
    scheduledEndDate: formatDate(row.scheduledEndDate, dateFormat),
  }));

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <BulkInvitationDialog
        className={classes.dialogbox}
        Header={<span style={{ color: darkMode ? '#fff' : 'black' }}>{t('VISACCLEVEL003')}</span>}
        acceptText={t('VISACCLEVEL004')}
        cancelText={t('COMMON008')}
        onSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
        cancel={handleClose}
        style={{ overflowX: 'auto', }}
      >
        <Grid container style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
          <Grid container padding={2} spacing={2}>
            <Grid item sm={12} xs={12} md={3} lg={3}>
              <Typography>
                <Box sx={{ fontWeight: 'bold', m: 1, width: 'max-content', color: darkMode ? '#fff' : 'black' }}>
                  {t('PERSONINFO8')}{' '}
                </Box>
              </Typography>

              <Box
                sx={{
                  height: '370px',
                  border: '2px dashed black',
                  borderRadius: '9px',
                  background: darkMode ? ' #1A364E 0% 0% no-repeat padding-box' : ' #F3F9FB 0% 0% no-repeat padding-box',
                }}
              >
                <div className={classes.container}>
                  <img src={xlslogo}></img>
                </div>
                <Typography className={classes.container} style={{ color: darkMode ? '#fff' : 'black' }}>
                  {t('buttonSampleTemplate')}
                </Typography>
                {/* <p className={classes.container}>Download the template format and fill the basic details</p> */}
                <Typography variant="h7" sx={{ ml: '38px', mt: '20px', color: darkMode ? '#fff' : 'black' }}>
                  {t('DOWNLOAD_TEMPLATE')} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; {t('FORMAT_FILL_DETAILS')}
                </Typography>

                <Box className={classes.container}>
                  <Button
                    sx={{
                      backgroundColor: '#F2824C',
                      color: '#FFFFFF',
                      height: '40px',
                      width: '140px',
                      mt: '140px',
                    }}
                    onClick={handleDownloadTemplate}
                  >
                    {t('EXPORTSUCCESS008')}
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item sm={12} xs={12} md={6} lg={6}>
              <Typography>
                <Box sx={{ fontWeight: 'bold', m: 1, width: 'max-content', color: darkMode ? '#fff' : 'black' }}>
                  {t('PERSONINFO13')}
                </Box>
              </Typography>

              <Box
                sx={{
                  border: '2px dashed black',
                  height: '370px',
                  borderRadius: '9px',
                  background: darkMode ? ' #1A364E 0% 0% no-repeat padding-box' : ' #F3F9FB 0% 0% no-repeat padding-box',
                }}
              >
                <Grid container padding={2} spacing={2}>
                  {purposeTypeToggle && (
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Autocomplete
                        style={{ width: '100%' }}
                        noOptionsText={'No Options found'}
                        name="purposeTypeId"
                        // value={payload.purposeTypeId}
                        options={
                          CommonUtil.isEmptyArray(purposeTypeData)
                            ? []
                            : purposeTypeData
                        }
                        getOptionLabel={(option) =>
                          option.name ? option.name : ''
                        }
                        onChange={(event, newValue) => {
                          if (!CommonUtil.isEmpty(newValue)) {
                            // eslint-disable-next-line
                            onselectPurposeType(newValue)
                          } else {
                            onselectPurposeType('')
                          }
                          setError({ purposeTypeId: false })
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="purposeTypeId"
                            // value={payload.purposeTypeId}
                            // error={error.purposeTypeId}
                            variant="outlined"
                            fullWidth
                            label={
                              purposeTypeMandatory
                                ? t('PURPOSELABEL1') + ' *'
                                : t('PURPOSELABEL1')
                            }
                            InputLabelProps={{
                              style: { color: darkMode ? '#fff' : '#707070' },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { color: darkMode ? '#fff' : '#787c82', borderColor: darkMode ? '#fff' : '#787c82' },
                              sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: darkMode ? '#fff' : '#a5a7a8',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: darkMode ? '#fff' : '#707070',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: darkMode ? '#fff' : '#707070',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {visitorTypeToggle && (
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Autocomplete
                        style={{ width: '100%' }}
                        noOptionsText={'No Options found'}
                        name="visitorTypeId"
                        // value={payload.visitorTypeId}
                        options={
                          CommonUtil.isEmptyArray(visitorData)
                            ? []
                            : visitorData
                        }
                        getOptionLabel={(option) =>
                          option.name ? option.name : ''
                        }
                        onChange={(event, newValue) => {
                          if (!CommonUtil.isEmpty(newValue)) {
                            // eslint-disable-next-line
                            onselectVisitorType(newValue)
                          } else {
                            onselectVisitorType('')
                          }
                          // setError({ visitorTypeId: false });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="visitorTypeId"
                            // value={payload.visitorTypeId}
                            // error={error.visitorTypeId}
                            variant="outlined"
                            fullWidth
                            label={visitorTypeMandatory ? t('COMMON068') + ' *'
                              : t('COMMON068')}
                            InputLabelProps={{
                              style: { color: darkMode ? '#fff' : '#707070' },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                color: darkMode ? '#fff' : '#787c82', // Input text color
                                borderColor: darkMode ? '#fff' : '#787c82', // Input border color
                              },
                              sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: darkMode ? '#fff' : '#a5a7a8', // Initial border color
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: darkMode ? '#fff' : '#707070', // Border color on hover
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: darkMode ? '#fff' : '#707070', // Border color when focused
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}


                  {siteTypeToggle && (
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Autocomplete
                        style={{ width: '100%' }}
                        noOptionsText={'No Options found'}
                        name="siteId"
                        //   value={payload.siteId}
                        options={
                          CommonUtil.isEmptyArray(siteData) ? [] : siteData
                        }
                        getOptionLabel={(option) =>
                          option.name ? option.name : ''
                        }
                        onChange={(event, newValue) => {
                          if (!CommonUtil.isEmpty(newValue)) {
                            // eslint-disable-next-line
                            setPayload({ ...payload, siteId: newValue })
                            setDatatableValue({
                              ...datatableValue,
                              siteName: newValue,
                            })
                          } else {
                            setDatatableValue({
                              ...datatableValue,
                              siteName: '',
                            })
                          }
                          // setError({ siteId: false });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="siteId"
                            //   value={payload.siteId}
                            //   error={error.siteId}
                            variant="outlined"
                            fullWidth
                            label={siteTypeMandatory ? t('SITE013') + ' *' : t('SITE013')}
                            InputLabelProps={{
                              style: { color: darkMode ? '#fff' : '#707070' },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { color: darkMode ? '#fff' : '#787c82', borderColor: darkMode ? '#fff' : '#787c82' },
                              sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: darkMode ? '#fff' : '#a5a7a8',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: darkMode ? '#fff' : '#707070',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: darkMode ? '#fff' : '#707070',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {companyNameToggle && (
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      style={{ margin: '-15px 0px' }}
                    >
                      <CustomTextfield
                        style={{ width: '100%' }}
                        label={
                          companyNameMandatory
                            ? t('VISUPDATE0006') + '*'
                            : t('VISUPDATE0006')
                        }
                        error={error.visitorCompanyName}
                        name="visitorCompanyName"
                        value={datatableValue.visitorCompanyName}
                        handleChange={(e) => handleChange(e)}
                        helperText={error.visitorCompanyName}
                      // validation="alphabets"
                      />
                    </Grid>
                  )}

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <CustomDateTimePicker
                      onChange={(scheduledStartDate) =>
                        setPayload({
                          ...payload,
                          scheduledStartDate: scheduledStartDate,
                          scheduledEndDate: CommonUtil.formatToUtc(
                            moment(scheduledStartDate).add(
                              invitationIntervalTime,
                              'minutes',
                            ),
                          ),
                        })
                      }
                      disablePast
                      value={payload.scheduledStartDate}
                      label={t('INVITESTARTDATEERROR1')}
                      changeDate={changeDate}
                      date={props.date}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <CustomDateTimePicker
                      onChange={(scheduledEndDate) =>
                        setPayload({
                          ...payload,
                          scheduledEndDate: scheduledEndDate,
                        })
                      }
                      disablePast
                      value={payload.scheduledEndDate}
                      minDateTime={payload.scheduledStartDate}
                      label={t('INVITESTARTDATEERROR2')}
                      changeDate={changeDate}
                      date={props.date}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={
                      (Number(!purposeTypeToggle) +
                        Number(!visitorTypeToggle) +
                        Number(!companyNameToggle) +
                        Number(!siteTypeToggle)) %
                        2 ===
                        1
                        ? 6
                        : 12
                    }
                    md={6}
                    sm={6}
                    xs={6}
                  >
                    <Autocomplete
                      style={{
                        margin: '-15px',
                        marginLeft: '1px',
                        color: '#242424',
                      }}
                      id="free-solo-demo"
                      freeSolo
                      noOptionsText={'No Options found'}
                      fullWidth
                      filterOptions={filterOptions}
                      options={
                        CommonUtil.isEmptyArray(hostList) ? [] : hostList
                      }
                      getOptionLabel={(option) =>
                        option.lastName
                          ? option.firstName + ' ' + option.lastName
                          : option.firstName
                            ? option.firstName
                            : ''
                      }
                      value={selectedHost}
                      onChange={(event, newValue) => {
                        if (!CommonUtil.isEmpty(newValue)) {
                          setPayload({
                            ...payload,
                            hostId: newValue,
                          })
                          setDatatableValue({
                            ...datatableValue,
                            hostName: newValue,
                          })
                        } else {
                          setDatatableValue({ ...datatableValue, hostName: '' })
                        }
                      }}
                      renderInput={(params) => (
                        <CustomTextfield
                          {...params}
                          label={t('commonsTextFeildHost')}
                          required={true}
                          placeholder="Host"
                          name="hostId"
                          key="Confirmation Code"
                          id="hostId"
                          handleChange={(e) => handleText(e)}
                          // error={error.selectedHost}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              color: darkMode ? '#fff' : 'black', // Value color set to red
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid
                container
                padding={2}
                justifyContent="center"
                spacing={2}
                mt={-11}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button
                    sx={{
                      bgcolor: '#F2824C',
                      color: 'white',
                      width: '130px',
                      height: '45px',
                      ml: '140px',
                    }}
                    onClick={handleMoreDetails}
                  >
                    {t('COMMON007')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} xs={12} md={3} lg={3}>
              <Typography>
                <Box sx={{ fontWeight: 'bold', m: 1, width: 'max-content', color: darkMode ? '#fff' : 'black' }}>
                  {t('PERSONINFO14')}
                </Box>
              </Typography>

              <Box
                sx={{
                  border: '2px dashed black',
                  height: '370px',
                  borderRadius: '9px',
                  background: darkMode ? ' #1A364E 0% 0% no-repeat padding-box' : ' #F3F9FB 0% 0% no-repeat padding-box',
                }}
              >
                <div className={classes.container}>
                  <img src={xlslogo}></img>
                </div>
                <Typography className={classes.container} style={{ color: darkMode ? '#fff' : 'black' }}>
                  {t('PERSONINFO15')}
                </Typography>
                {/* <p className={classes.container}>Download the template format and fill the basic details</p> */}
                <Typography variant="h7" sx={{ ml: '100px', mt: '30px', color: darkMode ? '#fff' : 'black' }}>
                  {t('PERSONINFO16')}
                </Typography>

                <Box className={classes.container} sx={{ ml: '100px', color: darkMode ? '#fff' : 'black' }}>
                  <input
                    key={key}
                    type="file"
                    onChange={handleFileUpload}
                    accept=".xlsx, .xls"
                  />
                </Box>

                <Box className={classes.container}>
                  <Button
                    sx={{
                      backgroundColor: '#F2824C',
                      color: '#FFFFFF',
                      height: '40px',
                      width: '140px',
                      mt: '100px',
                    }}
                    onClick={handleImport}
                  >
                    {t('VISACCLEVEL003')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Typography
              variant="h4"
              style={{
                fontWeight: 'bold',
                ml: '25px',
                color: 'black',
                mt: '25px',
                padding: '16px',
                color: darkMode ? '#fff' : 'black'
              }}
            >
              {t('PERSONINFO17')}
            </Typography>
            <Typography style={{ padding: '16px', color: darkMode ? '#fff' : 'black' }}>
              {t('PERSONINFO18')}
            </Typography>
            {/* </Box> */}

            <Box>
              <TextareaAutosize
                className="textareaStyle"
                minRows={3}
                rows={emptyParams.length + 1}
                value={emptyParamsText}
                readOnly
                style={{ color: 'red', marginLeft: '15px', background: darkMode ? ' #1A364E 0% 0% no-repeat padding-box' : ' #F3F9FB 0% 0% no-repeat padding-box', }}

              />
            </Box>
          </Box>

          <div
            style={{
              height: 300,
              width: '1500px',
              marginTop: '10px',
              overflowX: 'auto',
              backgroundColor: darkMode ? '#0F273B' : '#fff'
            }}
          >
            {importError ? (
              <DataGrid
                className={classes.datagrid}
                columns={errorColumns}
                rows={errorList.concat(successList)}
                headerClassName={classes.headerItem}
                // hideFooterPagination
                sx={{
                  ml: '15px',
                  borderRadius: '5px',
                  mr: '13px',
                  overflowX: 'auto',
                }}
                localeText={customLocaleText}
                getRowClassName={params => {
                  if (params.row.status === "error") {
                    return classes.errorListStyles
                  }
                  else {
                    return classes.successListStyles
                  }
                }}
              />
            ) : (
              <DataGrid
                className={classes.datagrid}
                columns={columns}
                rows={formattedRows}
                headerClassName={classes.headerItem}
                // hideFooterPagination
                sx={{ ml: "15px", borderRadius: "5px", mr: "13px", overflowX: "auto", backgroundColor: darkMode ? '#1A364E' : '#fff' }}
                localeText={customLocaleText}
                getRowClassName={params => {
                  if (params.row.status === "error") {
                    return classes.errorListStyles
                  }
                  else {
                    return classes.successListStyles
                  }
                }}
              />
              )}
          </div>
        </Grid>
      </BulkInvitationDialog>
      {openEditDialog && (
        <BulkInvitationEditDialog
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false)
          }}
          rowData={editRowData}
          onSave={handleSave}
        />
      )}
    </>
  )
}

export default BulkInvitation
