import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SiteService from '../../services/SiteService';
import site from '../../services/Site/SiteService'

const initialState = [];

export const getSitesList = createAsyncThunk('sites/', async (payload) => {
    const res = await SiteService.get(payload);
    return res.data;
});

export const getAllSites = createAsyncThunk('sites/', async (payload) => {
    const res = await site.getSites(payload);
    return res.data;
});

export const createSite = createAsyncThunk('site', async (payload) => {
    const res = await site.createSite(payload);
    return res.data;
});

export const updateSite = createAsyncThunk('sites/', async (payload) => {
    const res = await site.updateSite(payload.id, payload);
    return res.data;
});

export const deleteSite = createAsyncThunk('sites/', async (payload) => {
    const res = await site.deleteSite(payload);
    return res.data;
});

export const getSitesWithoutPaging = createAsyncThunk('site/', async () => {
    const res = await site.getSitesWithoutPaging();
    return res.data;
});

export const getSiteById = createAsyncThunk('sites/{id}', async (payload) => {
    const res = await SiteService.getSiteById(payload);
    return res.data;
});

const siteSlice = createSlice({
    name: 'site',
    initialState,
    extraReducers: {
        [getSitesList.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})


const { reducer } = siteSlice;
export default reducer;