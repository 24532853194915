import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import { addPurposeType, listVisitorType, updateVisitorType } from '../../slices/VisitTypeSlice';
import CommonUtil from "../../Util/CommonUtils";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import jwt_decode from "jwt-decode";
import CustomTextfeild from "../../components/CustomInputs/CustomTextfield";
import {
  listChecklistTemplates,
  listChecklistTemplates1,
} from "../../slices/CheckListSlice/CheckListSlice";
import Toast from "../../components/ToastContainer/CustomToast";
import CustomDropdown from "../../components/CustomInputs/CustomDropdown";
import AutocompleteComponent from "../../components/CustomInputs/AutocompleteComponent";
import {
  addPurposeTypeZlink,
  listVisitorTypeZlink,
  updateVisitorType,
} from "../../slices/PurposeTypeUSA/PurposeTypeUSASlice";
import { getAllChecklisttemplate } from "../../slices/ChecklistTemplatesUSA/ChecklistTemplateSlice";
import { makeStyles } from '@material-ui/core'
import { ThemeContext } from '../../theme/ThemeContext';

const useStyles = makeStyles((theme) => ({
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}))

export default function AddPurposeType(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [checklistTemplates, setChecklistTemplates] = useState([]);
  const { darkMode } = useContext(ThemeContext);
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );

  useEffect(() => {
    if (props.selectedObject.id) {
      setState({
        ...state,
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        description: props.selectedObject.description,
        templateId: props.selectedObject.checklistTemplates,
      });
    }
  }, []);

  const [state, setState] = useState({
    id: "",
    name: "",
    description: "",
    templateId: ""
  });

  const [error, setError] = useState({
    name: false,
    templateId: false,
    description: false
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadVisitType();
    loadChecklistTemplates();
  };

  const loadVisitType = () => {
    const query = {
      name: "",
      pageNumber: 0,
      pageSize: 0,
      isPageable: false,
    };
    dispatch(listVisitorTypeZlink(query))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI1000") {
          setData(data.data.purposeTypes);
        } else {
          setData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const loadChecklistTemplates = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };
    dispatch(getAllChecklisttemplate(query))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0000") {
          setChecklistTemplates(data.data.visitors);
        } else {
          setData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSumbit = () => {
    if (state.name === "" || state.name === undefined) {
      setError({
        name: "error",
      });
      Toast(t("VISTYPE007"), "error");
    } else if (!CommonUtil.validateName(state.name)) {
      Toast(t("VISITYPENAMEVAL"), "error");
    } else if (CommonUtil.checkCharactersGreaterThan50(state.name)) {
      toast.error(t("NAMELENGTH"));
    } else if (
      (state.description !== undefined || state.description !== "") &&
      CommonUtil.checkCharactersGreaterThan250(state.description)
    ) {
      toast.error(t("RESTRICTERROR003"));
    } else if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      dispatch(addPurposeTypeZlink(state))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI0043") {
            Toast(t("VISPUPCRESUC001"), "success");
            handleClose();
          } else {
            onError(data);
          }
        });
    } else {
      dispatch(updateVisitorType(state))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI1002") {
            Toast(t("VISPUPUPDSUC001"), "success");
            handleClose();
          } else {
            onError(data);
          }
        });
    }
  };

  const onError = (data) => {
    if (data)
      switch (data.code) {
        case "CVAE1015":
          setError({ emailId: true });
          break;

        default:
          setError({ ...error });
      }
    toast.error(data.message);
  };

  return (
    <DialogBox
      // Header={state.id ? t("VISPURPOSE002") : t("VISPURPOSE001")}
      Header={
        <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
         {state.id ? t("VISPURPOSE002") : t("VISPURPOSE001")}
        </span>
      }
      acceptText={t("COMMON007")}
      cancelText={t("COMMON008")}
      style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      cancel={handleClose}
      onSubmit={handleSumbit}
    >
      <Grid
        container
        justifyContent="center"
        style={{ padding: "16px 32px", width: "100%"  }}
        spacing={2}
      >
        <Grid item xs={12} xm={10}>
          <CustomTextfeild
            error={error.name}
            name="name"
            label={t("PURPOSELABEL") + " *"}
            helperText=""
            value={state.name}
            handleChange={(e) => handleChange(e)}
            inputProps={{className : classes.input , maxLength: 30}}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
          <AutocompleteComponent
            label={t("VISTYPE002")}
            name="templateId"
            error={error.templateId}
            value={state.templateId}
            // isOptionEqualToValue={(option, value) => option.id === value}
            onSelect={(e) =>
              setState({
                ...state,
                templateId: e.name,
              })
            }
            options={checklistTemplates}
          />
          {/* <CustomDropdown
            defaultSelect={'Select Checklist Template'}
            label={t('VISTYPE002')}
            value={state.templateId}
            error={error.templateId}
            name="templateId"
            data={checklistTemplates}
            handleChange={(e) => handleChange(e)}
          /> */}
        </Grid>

        <Grid item xs={12} xm={10}>
          <CustomTextfeild
            error={error.description}
            name="description"
            label={t("RestrictTypeForm005")}
            value={state.description}
            handleChange={(e) => handleChange(e)}
            inputProps={{className : classes.input , maxLength: 120}}
          />
        </Grid>
      </Grid>
    </DialogBox>
  );
}
