import React, { useState } from "react";
import Webcam from "react-webcam";
import { useDispatch } from "react-redux";
import { Grid, Tooltip } from "@mui/material";
import b64toBlob from "b64-to-blob";
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'

// Webcamera access and take snapshot using third party library react-webcam

const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: "user",
};

// Taking snapshot on capture and dispatch Image source to Redux Store
export const WebcamCapture = (props) => {
  const { t } = useTranslation()
  const [image, setImage] = useState(props.profilePhoto);
  const webcamRef = React.useRef(null);
  // const capture = React.useCallback(() => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   setImage(imageSrc);
  //   var contentType = 'image/jpeg';
  //   var b64Data = imageSrc.replace("data:image/jpeg;base64,", "");
  //   props.base64Image(b64Data);
  //   var blob = b64toBlob(b64Data, contentType);
  //   props.onsetProfile(blob);
  // });

  const capture = React.useCallback(() => {
    if (webcamRef.current && webcamRef.current.stream) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
      var contentType = 'image/jpeg';
      var b64Data = imageSrc.replace("data:image/jpeg;base64,", "");
      props.base64Image(b64Data);
      var blob = b64toBlob(b64Data, contentType);
      props.onsetProfile(blob);
    } else {
      console.error("Webcam stream is not available");
      // Optionally, inform the user
      alert("Unable to access the webcam. Please make sure it is connected and enabled.");
    }
  }, [webcamRef, props]);

  const handleClearProfile = () => {
    props.base64Image(""); 
    setImage(null); 
    props.onsetProfile("");
    props.webCam();
  };

  return (
    <div className="webcam-container">
      <Grid conatiner display={'flex'} direction='column' justifyContent='center' alignItems='center'>
      <div className="webcam-img" style={{ position: 'relative' }}>
          {image ?
            <img src={image}  style={{ borderStyle: 'dashed ', borderRadius: '50%', padding: '5px', color: '#D2D2D2', height: '200px', width: '220px' }} onClick={(event) => {setImage("") ;
            props.onsetProfile("")}}
            />
            :
            <Webcam
               key={image ? 'captured' : 'new'}
              audio={false}
              height={200}
              style={{ borderStyle: 'dashed ', borderRadius: '50%', padding: '5px', color: '#D2D2D2' }}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={220}
              videoConstraints={videoConstraints}
              alt=""
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
            />
          }
          {image && (
            <Tooltip title={t('CLEAREPROFILEPHOTO')}>
            <div style={{ position: 'absolute', bottom: '8.5px', right: '28px', borderRadius: '60%', backgroundColor: 'red' }}>
              <ClearIcon style={{ color: 'white' }} onClick={handleClearProfile}/> {/* Clear icon */}
            </div>
            </Tooltip>
          )}
        </div>
      </Grid>
    </div>
  );
};
