import React from 'react';
import { useEffect } from 'react';
import CommonUtil from '../../components/Util/CommonUtils';
import jwt_decode from 'jwt-decode';
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import { useHistory } from 'react-router-dom';

export default function Zlink() {
  const history = useHistory();
  useEffect(() => {
    var token = CommonUtil.getCookie('zlink_token');
    if (!CommonUtil.isEmptyString(token)) {
      localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
      localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, token);
      localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, CommonUtil.getCookie('zlink_refresh_token'));
      const decoded = jwt_decode(token);
      localStorage.setItem('companyId', decoded.companyId);
      localStorage.setItem('companyName', decoded.companyName);
      localStorage.setItem('companyCode', decoded.companyCode);
      localStorage.setItem('redirectFrom', 'zlink');
      history.push(REACT_URL_DASHBOARD);
    }
  }, []);
  return <></>;
}
