import api from "../../config/http-common";

const generateAccessKey = () => {
    return api.securedAxios().post("/api/v4/client/apiKey");
}

const saveClientRecord = (payload) => {
    return api.securedAxios().post("/api/v4/client", payload);
}

const getClientData = (companyId) => {
    return api.securedAxios().get("/api/v4/clients/" + companyId);
}

const atlasIntegrationService = {
    getClientData,
    generateAccessKey,
    saveClientRecord
}

export default atlasIntegrationService;