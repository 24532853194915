import React, { useEffect, useState, useContext } from 'react'
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TextareaAutosize, makeStyles } from '@material-ui/core'
import BulkEmployeeDialog from './BulkEmployeeDialog'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { Stack, Typography } from '@mui/material'
import xlslogo from '../../../src/assets/USA_Images/dashboard_icons/xls.svg'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { DataGrid } from '@mui/x-data-grid'
import DataTable from '../../components/DataTable/DataTable'
import { read, utils } from 'xlsx'
import axios from 'axios'
// import * as XLSX from 'xlsx';
import * as xlsx from 'xlsx'
import Textarea from '@mui/joy/Textarea'
import GridOverlay from '@mui/x-data-grid'
import EmployeeEditIcon from '../../Pages/Employee/EmployeeEditIcon'
import BulkEmployeeEditDialog from './BulkEmployeeEditDialog'
import * as XLSX from 'xlsx'
import Toast from '../../components/ToastContainer/CustomToast'
import { createMultipleEmployees } from '../../slices/Employee/EmployeeSlice'
import { ThemeContext } from '../../theme/ThemeContext';

const useStyles = makeStyles((theme) => ({
  dialogbox: {
    '& .css-hlj6pa-MuiDialogActions-root': {
      marginLeft: '700px !important',
    },
    '& .MuiDialog-paper': {
      // minWidth: '110vh',
      minWidth: '1000px',
      marginLeft: '250px',
      backgroundColor: (darkMode) => darkMode ? '#0F273B' : '#fff',
    },
    '.& .css-f3jnds-MuiDataGrid-columnHeaders': {
      backgroundColor: '#F3F9FB',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '35px',
  },
  headerItem: {
    backgroundColor: '#226BAB',
    color: 'white',
  },
  datagrid: {
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: 'aliceblue',
      // color: '#fff',
    },
    // "& .css-17jjc08-MuiDataGrid-footerContainer": {
    //   display: "none",
    // },
  },
  errorListStyles: {
    color: 'red',
  },
  successStyles: {
    color: 'green',
  },
}))

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

export default function BulkEmployee(props, { onUpload }) {
  const classes = useStyles()
  const { darkMode } = useContext(ThemeContext);
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(props.open)
  const [file, setFile] = useState(null)
  const [excelFile , setExcelFile] = useState(null);
  const [excelData, setExcelData] = useState([])
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [editRowData, setEditRowData] = useState(null)
  const nonEmptyRows = []
  const emptyRows = []
  const [selectedFile, setSelectedFile] = useState(null)
  const [error, setError] = useState(false)
  const [key, setKey] = useState(0);
  const [customLocaleText, setCustomLocaleText] = useState({
    noRowsLabel: t('NOROWS'),
  })

  const { handleClose } = props

  const Errorcolumns = [
    {
      field: 'code',
      headerName: t('COMMON140'),
      flex: 1,
      minWidth: 190,
      width: 150,
      valueGetter: (params) => params.row.data.code,
    },
    {
      field: 'firstName',
      headerName: t('USER0001'),
      flex: 1,
      minWidth: 190,
      width: 150,
      valueGetter: (params) => params.row.data.firstName,
    },
    {
      field: 'lastName',
      headerName: t('USER0002'),
      flex: 1,
      minWidth: 190,
      width: 150,
      valueGetter: (params) => params.row.data.lastName,
    },
    {
      field: 'email',
      headerName: t('VISUPDATE0001'),
      flex: 1,
      minWidth: 190,
      width: 150,
      valueGetter: (params) => params.row.data.email,
    },
    {
      field: 'phone',
      headerName: t('VISUPDATE0002'),
      flex: 1,
      minWidth: 190,
      width: 150,
      valueGetter: (params) => params.row.data.phoneNumber,
    },
    {
      field: 'Actions',
      headerName: t('Action'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EmployeeEditIcon />}
          label="Edit"
          disabled={getStatus(params)}
          onClick={() => handleOpenEditPerson(params.row)}
        />,
      ],
    },
  ]

  const columns = [
    {
      field: 'code',
      headerName: t('COMMON140'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'firstName',
      headerName: t('USER0001'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'lastName',
      headerName: t('USER0002'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'email',
      headerName: t('VISUPDATE0001'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'phoneNumber',
      headerName: t('VISUPDATE0002'),
      flex: 1,
      minWidth: 190,
      width: 150,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      flex: 1,
      minWidth: 190,
      width: 150,
      renderCell: (params) => {
        let genderDisplay
        switch (params.value) {
          case 'M':
            genderDisplay = 'Male'
            break
          case 'F':
            genderDisplay = 'Female'
            break
          case 'O':
            genderDisplay = 'Other'
            break
          default:
            genderDisplay = params.value
        }
        return <span>{genderDisplay}</span>
      },
    },
    {
      field: 'roleName',
      headerName: 'Role',
      flex: 1,
      minWidth: 190,
      width: 150,
      renderCell: (params) => {
        let capitalized = params.value.charAt(0).toUpperCase()
        capitalized += params.value.slice(1).toLowerCase()
        return capitalized
      },
    },

    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EmployeeEditIcon />}
          label="Edit"
          disabled={getStatus(params)}
          onClick={() => handleOpenEditPerson(params.row)}
        />,
      ],
    },
  ]


  useEffect(() => {
    if (file === null) {
      setKey(prevKey => prevKey + 1); 
    }
  }, [file]);

  const getStatus = (params) => {
    if (params.row.read === 'success') {
      return true
    }
    return false;
  }

  const handleOpenEditPerson = (params) => {
    if (error) {
      setEditRowData(params.data)
    } else {
      setEditRowData(params)
    }
    setOpenEditDialog(true)
  }

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    setExcelFile(uploadedFile)
  }

  const [successList, setSuccessList] = useState([])

  const [errorList, setErrorList] = useState([])

  useEffect(() => {
    console.log('Success List:', successList)
  }, [successList])

  const handleSave = (updatedRowData) => {
    if (error) {
      console.log(successList)
      const updatedErrorList = errorList.filter(
        (row) =>
          row.data.code !== updatedRowData.code &&
          row.data.email !== updatedRowData.email,
      )

      setErrorList(updatedErrorList)
    } else {
      const updatedErrorList = errorList.filter(
        (row) => row.id !== updatedRowData.id,
      )
      setErrorList(updatedErrorList)
    }
    const newSuccessList = {
      ...updatedRowData,
      read: 'success',
    }
    setSuccessList((prevList) => [...prevList, newSuccessList])
    console.log(successList)
    console.log(errorList)
  }


 
  const handleImportFile = async () => {
    if (!file) {
      Toast(t('PERSONINFO6'), 'error');
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('file', file);
  
      const response = await axios.post('/web/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      const data = response.data;
  
      if (data.code === 'CVAE1001' || data.code === 'CVAE1005') {
        Toast(data.message, 'error');
      } else if (data.code === 'CVAE1002') {
        setSuccessList(data.data.successList);
        setErrorList(data.data.errorList);
        Toast(t('EMP1'), 'success');
  
        // Reset file state after successful upload
        setFile(null);
      } else {
        // Handle other response codes or messages
        Toast(data.message, 'error');
      }
    } catch (error) {
      console.error('Failed to upload file:', error.message);
      alert('Failed to upload file: ' + error.message);
      // Handle error state or notify user
    }
  }

  const emptyParams = []

  const rows = errorList.map((error1, index) => {
    const row = {
      id: index + 1,
      rowNumber: index + 1,
      code: error1.code,
      firstName: error1.firstName,
      lastName: error1.lastName,
      email: error1.email,
      AddressLine1: error1.AddressLine1,
      Country: error1.Country,
      // Timezone: error.Timezone
    }
    if (!error) {
      if (!error1.code || error1.code.trim() === '') {
        emptyParams.push(`Row ${index + 1}: PersonID`)
      }
      if (!error1.firstName || error1.firstName.trim() === '') {
        emptyParams.push(`Row ${index + 1}: FirstName`)
      }
      if (!error1.lastName || error1.lastName.trim() === '') {
        emptyParams.push(`Row ${index + 1}: LastName`)
      }
      if (!error1.email || error1.email.trim() === '') {
        emptyParams.push(`Row ${index + 1}: Email`)
      }
      // if (!error1.AddressLine1 || error1.AddressLine1.trim() === '') {
      //   emptyParams.push(`Row ${index + 1}: AddressLine1`)
      // }
      // if (!error1.Country || error1.Country.trim() === '') {
      //   emptyParams.push(`Row ${index + 1}: Country`)
      // }
      // if(!error1.gender  || !error1.gender.trim() === ''){
      //   emptyParams.push(`Row ${index + 1}: Gender is mandatory`)
      // }
      if (
        error1.gender.trim() != '' &&
        (!error1.gender ||
          (error1.gender.toLowerCase().trim() != 'm' &&
            error1.gender.toLowerCase().trim() != 'f' &&
            error1.gender.toLowerCase().trim() != 'o'))
      ) {
        emptyParams.push(
          `Row ${index + 1}: Gender Should be Male , Female or Other`,
        )
      }
      if (!error1.roleName || error1.roleName.trim() === '') {
        emptyParams.push(`Row ${index + 1}: Role is mandatory`)
      }

      if (
        error1.roleName.trim() != '' &&
        (!error1.roleName ||
          (error1.roleName.toLowerCase().trim() != 'admin' &&
            error1.roleName.toLowerCase().trim() != 'frontdesk' &&
            error1.roleName.toLowerCase().trim() != 'employee'))
      ) {
        emptyParams.push(
          `Row ${index + 1}: Role Should be Admin , FrontDesk or Employee`,
        )
      }
    }
    if (error) {
      if (error1.message != '') {
        emptyParams.push(`Row ${index + 1} : ${error1.message} `)
      }
      return row
    }
  })

  const emptyParamsText = emptyParams.join('\n')

  const handleDownloadTemplate = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new()

    // Define the header row
    const header = [
      // 'id',
      'Person ID(mandatory)',
      'First Name(mandatory)',
      'Last Name(mandatory)',
      'Email(mandatory)',
      'Mobile Countrycode followed by number Ex : 1-2294003759',
      // 'Address Line 1(mandatory)',
      // 'Address Line 2',
      // 'Country(mandatory)',
      // 'City',
      // 'State',
      // 'Zip Code',
      'Role(mandatory) ADMIN/FRONTDESK/EMPLOYEE',
      'Gender Male/Female/Other',
    ]

    // Add a worksheet to the workbook
    const ws = XLSX.utils.aoa_to_sheet([header])

    const columnWidths = [20, 30, 30, 40, 60, 50, 30] // Adjust the values as needed (in Excel's "characters" units)
    const range = XLSX.utils.decode_range(ws['!ref'])
    for (let columnIndex = range.s.c; columnIndex <= range.e.c; columnIndex++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: columnIndex })
      ws[cellAddress].w = header[columnIndex]
      ws['!cols'] = ws['!cols'] || []
      ws['!cols'][columnIndex] = { wch: columnWidths[columnIndex] }
    }

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    // Generate the Excel file
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

    // Convert the Excel file to a Blob
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })

    // Create a download link and simulate a click
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'Employee.xlsx'
    link.click()

    // Clean up the URL object
    URL.revokeObjectURL(link.href)
  }

  // Utility function to convert a string to an ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  }

  const handleSubmit = () => {
    if (emptyParamsText.length > 0) {
      Toast(t('PERSONINFO7'), 'error')
      return
    }

    if (excelFile == null) {
      Toast(t('PERSONINFO6'), 'error')
      return
    }
    // successList.shift();
    const payload = successList.map((item) => ({
      ...item,
      status: 'INACTIVE',
      id: '',
    }))

    const dto = payload.map((item) => {
      let phoneWithoutHyphen = item.phone
      if (phoneWithoutHyphen) {
        phoneWithoutHyphen = phoneWithoutHyphen.replace(/-/g, '')
      }
      return { ...item, phone: phoneWithoutHyphen }
    })

    dispatch(createMultipleEmployees(dto))
      .unwrap()
      .then((res) => {
        if (res.code === 'CVAE1003') {
          Toast(res.message, 'error')
        }
        if (res.code == 'OMSI0222') {
          Toast(t('PERSONINFO11'), 'success')
          // setError(false);
          props.handleClose()
        }
        if (res.code == 'OMSW0012') {
          setError(true)
          Toast(t('PERSONINFO12'), 'error')
          setSuccessList([])
          const updatedErrorList = (res.data.error).map((item) => ({
            ...item,
              read : "error"
          }))
          console.log(updatedErrorList);
          setErrorList(updatedErrorList)
        }
        //  else{
        //   Toast(res.message , "error")
        //   }
      })
  }

  return (
    <>
      <BulkEmployeeDialog
        className={classes.dialogbox}
        Header={<span style={{ color: darkMode ? '#fff' : 'black' }}>{t('VISACCLEVEL003')}</span>}
        acceptText={t('VISACCLEVEL004')}
        cancelText={t('INVITATION003')}
        onSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
        cancel={handleClose}
      >
        <Grid container style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                <Box sx={{ fontWeight: 'bold', m: 1, color: darkMode ? '#fff' : 'black' }}>{t('PERSONINFO8')} </Box>
              </Typography>

              <Box
                sx={{
                  height: '248px',
                  border: '2px dashed black',
                  borderRadius: '9px',
                  background: darkMode ? ' #1A364E 0% 0% no-repeat padding-box' : ' #F3F9FB 0% 0% no-repeat padding-box',
                }}
              >
                <div className={classes.container}>
                  <img src={xlslogo}></img>
                </div>
                <Typography className={classes.container} style={{ color: darkMode ? '#fff' : 'black' }}>
                  {t('buttonSampleTemplate')}
                </Typography>
                <Box className={classes.container}>
                  <Button
                    sx={{
                      backgroundColor: '#F2824C',
                      color: '#FFFFFF',
                      height: '40px',
                      width: '140px',
                      mt: '33px',
                    }}
                    onClick={handleDownloadTemplate}
                  >
                    {t('EXPORTSUCCESS008')}
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography>
                <Box sx={{ fontWeight: 'bold', m: 1, color: darkMode ? '#fff' : 'black' }}>{t('PERSONINFO9')}</Box>
              </Typography>
              <Box
                sx={{
                  height: '248px',
                  border: '2px dashed black',
                  borderRadius: '9px',
                  background: darkMode ? ' #1A364E 0% 0% no-repeat padding-box' : ' #F3F9FB 0% 0% no-repeat padding-box',
                }}
              >
                <Box className={classes.container}>
                  <img src={xlslogo}></img>
                </Box>
                <Typography className={classes.container} style={{ color: darkMode ? '#fff' : 'black' }}>
                  {t('PERSONINF10')}
                </Typography>
                <Box className={classes.container}>
                  {/* <Button sx={{backgroundColor:"#F2824C" , color:"  #FFFFFF" , height:"40px" , width:"140px"}} 
                onClick={handleFileChange}> Import
                </Button> */}
                  {/* <input type="file" onClick={handleFileChange}/> */}
                  {/* <input type="file" accept=".xlsx, .xls" onChange={(e) => readExcel(e)} /> */}
                  {/* <button onClick={handleUpload}>Upload</button> */}

                  <input
                  key={key}
                    type="file"
                    onChange={handleFileUpload}
                    accept=".xlsx, .xls"
                  />
                </Box>

                <Box
                  className={classes.container}
                  sx={{ ml: '50px', mt: '10px' }}
                >
                  <Button
                    sx={{
                      backgroundColor: '#F2824C',
                      color: 'white',
                      height: '40px',
                      width: '140px',
                    }}
                    onClick={handleImportFile}
                  >
                    {t('labelUploadPhoto1')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Typography variant="h4">
              <Box
                sx={{
                  fontWeight: 'bold',
                  ml: '25px',
                  color: 'black',
                  mt: '25px',
                  color: darkMode ? '#fff' : 'black'
                }}
              >
                {t('PERSONFIELD')}
              </Box>
            </Typography>
            <Typography>
              <Box
                sx={{ ml: '25px', color: 'black', opacity: 0.7, mt: '20px', color: darkMode ? '#fff' : 'black' }}
              >
                {t('PERSONFIELD2')}
                {t('PERSONFIELD1')}
              </Box>
            </Typography>

            <Box>
              <TextareaAutosize
                className="textareaStyle"
                minRows={3}
                maxlength="300"
                rows={emptyParams.length + 1}
                value={emptyParamsText}
                readOnly
                style={{ color: 'red', marginLeft: '15px', background: darkMode ? ' #1A364E 0% 0% no-repeat padding-box' : ' #F3F9FB 0% 0% no-repeat padding-box' }}
              />
            </Box>
          </Box>

          <div style={{ height: 300, width: '100%', backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
            {error ? (
              <DataGrid
                className={classes.datagrid}
                //  key={errorList.length}
                columns={Errorcolumns}
                rows={errorList}
                getRowId={(row) => row.data.code}
                headerClassName={classes.headerItem}
                // hideFooterPagination
                sx={{ ml: '15px', borderRadius: '5px', mr: '13px', backgroundColor: darkMode ? '#1A364E' : '#fff' }}
                localeText={customLocaleText}
                getRowClassName={(params) => {
                  if (params.row.read === 'error') {
                    return classes.errorListStyles
                  }
                  return classes.successStyles
                }}
              />
            ) : (
              <DataGrid
                className={classes.datagrid}
                //  key={errorList.length}
                columns={columns}
                rows={errorList.concat(successList)}
                // getRowId={row => row.code}
                headerClassName={classes.headerItem}
                // hideFooterPagination
                sx={{ ml: '15px', borderRadius: '5px', mr: '13px', backgroundColor: darkMode ? '#1A364E' : '#fff' }}
                localeText={customLocaleText}
                getRowClassName={(params) => {
                  if (params.row.read === 'error') {
                    return classes.errorListStyles
                  }
                  return classes.successStyles
                }}
              />
            )}
          </div>
        </Grid>
      </BulkEmployeeDialog>

      {openEditDialog && (
        <BulkEmployeeEditDialog
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false)
          }}
          rowData={editRowData}
          onSave={handleSave}
        />
      )}
    </>
  )
}
