import {
    Box,
    Grid,
    Paper,
    Typography,
  } from '@material-ui/core'
  import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
  import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
  import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
  import { useTranslation } from 'react-i18next'
  import { useHistory } from 'react-router-dom'
  import { useDispatch } from 'react-redux'
  import { REACT_KEY_CONFIGURATION } from '../../actions/EndPoints'
  import React, { useEffect, useState } from 'react'
  import CommonUtil from '../../Util/CommonUtils'
  import Toast from '../../components/ToastContainer/CustomToast'
  import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
  import TitleBar from '../../components/v4/TitleBar'
  import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
  import {getAccountList} from '../../slices/DashboardPanel/DashboardPanelSlice'
  import {createMobileKeyConfigurations} from '../../slices/MobileKeyConfiguration/MobileKeyConfigurationSlice'
  
  export default function CreateKeyConfiguration(props) {
    let history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [customerList,setCustomersList] = useState([]);
    const [paging, setPaging] = useState({
      pageNumber: 1,
      pageSize: 10,
    })

    const [configurationPayload, setConfigurationPayload] = React.useState({
      customerId:'',
      pdfDetectorActivationKey : '',
      mrzDetectorActivationKey:'',
      pdfParserActivationKey:'',
      privateConstValParserKey:'',
      privateConstValScannerKey:'',
      numberOfDevices:0,
    })

    const [error, setError] = React.useState({
      customerId:false,
      pdfDetectorActivationKey: false,
      mrzDetectorActivationKey: false,
      pdfParserActivationKey:false,
      privateConstValParserKey:false,
      privateConstValScannerKey:false,
      numberOfDevices:false,
    })

    const [accountState, setAccountState] = useState({
      type: 'CUSTOMER',
      name:'',
    })
  
    const handleKeyFieldChange = (event) => {
      const name = event.target.name
      if(name==="numberOfDevices"){
        const numericValue = parseInt(event.target.value, 10);
        setConfigurationPayload({
          ...configurationPayload,
          [name]: numericValue,
        })
      }
      else{
      setConfigurationPayload({
        ...configurationPayload,
        [name]: event.target.value,
      })
      setError({
        [name]: false,
      })
    }
    }
 
    useEffect(() => {
      loadAccountList()
    }, [])

    const loadAccountList = () => {
      let payload
        payload = {
          cascadeLimit: 2,
          accountDTO: accountState,
          page: paging,
        }
        dispatch(getAccountList(payload))
        .unwrap()
        .then((data) => {
          if (data.code === 'MSGS4009' && data.data) {
            setCustomersList(data.data.companies)
          }
        })
      } 
  
    const handleCancel = () => {
      history.push({
        pathname: REACT_KEY_CONFIGURATION,
      })
    }

    const handleText = (event) => {
      setAccountState({
        ...accountState,
        name: event.target.value
      });
      let payload
      payload = {
        cascadeLimit: 2,
        accountDTO: accountState,
        page: paging,
      }
      dispatch(getAccountList(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4009' && data.data) {
          setCustomersList(data.data.companies)
        }
      })
    }

    const filterOptions = createFilterOptions({
      stringify: ({ name }) =>
        `${name}`,
      limit: 1,
    })

    const handleSubmit = () => {
      dispatch(createMobileKeyConfigurations(configurationPayload))
        .unwrap()
        .then((data) => {
          switch (data.code) {
            case "MSGS4027":
              Toast(t('KEYCONFIGURATIONCREATESUCCESS'), 'success');
              history.push(REACT_KEY_CONFIGURATION)
              break;
            case "MSGE4036":
              setError({
                ...error,
                customerId: true,
              });
              Toast(t('KEYCONFIGURATIONCUSTOMERNAMEERROR'), 'error');
              break;
            case "MSGE4037":
              setError({
                ...error,
                pdfDetectorActivationKey: true,
              });
              Toast(t('KEYCONFIGURATIONPDFERROR'), 'error');
              break;
            case "MSGE4038":
              setError({
                ...error,
                mrzDetectorActivationKey: true,
              });
              Toast(t('KEYCONFIGURATIONMRZERROR'), 'error');
              break;
            case "MSGE4039":
              setError({
                ...error,
                pdfParserActivationKey: true,
              });
              Toast(t('KEYCONFIGURATIONPDF1ERROR'), 'error');
              break;
            case "MSGE4040":
              setError({
                ...error,
                privateConstValParserKey: true,
              });
              Toast(t('KEYCONFIGURATIONANDIRODERROR'), 'error');
              break;
            case "MSGE4041":
              setError({
                ...error,
                privateConstValScannerKey: true,
              });
              Toast(t('KEYCONFIGURATIONANDROID1ERROR'), 'error');
              break;
            case "MSGE4042":
              setError({
                ...error,
                numberOfDevices: true,
              });
              Toast(t('KEYCONFIGURATIONNUMBERDEVIEERROR'), 'error');
              break;
              case "MSGE4049":
                Toast(t('KEYCONFIGURATIONSUBSCRIPTIONEMPTY'), 'error');
                break;
            default:
              Toast(data.message, 'error');
              break;
          }
        })
        .catch((error) => {
          Toast(t('KEYCONFIGURATIONERROR'), 'error');
        });
    };
  
    return (
      <>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ m: 2, marginTop: -3.5 }}>
            <TitleBar
              title={t('LABELNAVITEMSCREATEKEY')}
              back_button={true}
            />
          </Box>
        </Grid>
        <Paper>
          <Grid container>
            <Grid container md={8} sm={12} spacing={3}>
            <Grid item lg={6} sm={12} style={{ margin: '30px 40px' }}>
            <Autocomplete
              style={{
                margin: '-15px',
                marginLeft: '1px',
                color: '#242424',
              }}
              id="free-solo-demo"
              freeSolo
              noOptionsText={'No Options found'}
              fullWidth
              filterOptions={filterOptions}
              options={CommonUtil.isEmptyArray(customerList) ? [] : customerList}
              getOptionLabel={(option) =>
                option.name               
              }
              // value={selectedHost}

              
              onChange={(event, newValue) => {

                if(newValue === null)
                  {
                    setConfigurationPayload({
                      ...configurationPayload,
                      customerId:'',
                     })
                  }
                  else{
               setConfigurationPayload({
                ...configurationPayload,
                customerId:newValue.id,
               })
              }
                setError({ customerId: false })
              }}
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label={t('KEYCONFIGURATIONSEARCHCUSTOMER')}
                  required={true}
                  placeholder={'Search Customer'}
                  name="customerId"
                  key="Confirmation Code"
                  id="customerId"
                  handleChange={(e) => handleText(e)}
                  error={error.customerId}
                />
              )}
            />
          </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                style={{
                  marginTop: '-10px',
                  marginLeft: '40px',
                }}
              >
              <Box style={{ marginTop:'10px'}}>
              <Typography variant="h3" style={{ whiteSpace: 'nowrap' }}>{t('KEYCONFIGURATIONIOSKEYHEADER')}</Typography>
             </Box>
                <CustomTextfeild
                  type={'text'}
                  error={error.pdfDetectorActivationKey}
                  label={t('KEYCONFIGURATIONIOSKEYPDF')}
                  name={'pdfDetectorActivationKey'}
                  required={true}
                  handleChange={(e) => handleKeyFieldChange(e)}
                />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                style={{
                  marginTop: '0px',
                  marginLeft: '40px',
                }}
              >
                <CustomTextfeild
                  type={'text'}
                  error={error.mrzDetectorActivationKey}
                  label={t('KEYCONFIGURATIONIOSKEYMRZ')}
                  name={'mrzDetectorActivationKey'}
                  required={true}
                  handleChange={(e) => handleKeyFieldChange(e)}
                />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                style={{
                  marginTop: '0px',
                  marginLeft: '40px',
                }}
              >
                <CustomTextfeild
                  type={'text'}
                  error={error.pdfParserActivationKey}
                  label={t('KEYCONFIGURATIONIOSKEYPDFPARSER')}
                  name={'pdfParserActivationKey'}
                  required={true}
                  handleChange={(e) => handleKeyFieldChange(e)}
                  helperText={error.name}
                  // inputProps={{ maxLength: 99 }}
                />
                 <Box style={{ marginTop:'33px'}}>
              <Typography variant="h3" style={{ whiteSpace: 'nowrap' }}>{t('KEYCONFIGURATIONANDROIDKEYHEADER')}</Typography>
             </Box>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                style={{
                  marginTop: '0px',
                  marginLeft: '40px',
                }}
              >
                <CustomTextfeild
                  type={'text'}
                  error={error.privateConstValParserKey}
                  label={t('KEYCONFIGURATIONANDROIDKEYPRIVATEPARSER')}
                  name={'privateConstValParserKey'}
                  required={true}
                  handleChange={(e) => handleKeyFieldChange(e)}
                  // inputProps={{ maxLength: 99 }}
                />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                style={{
                  marginTop: '0px',
                  marginLeft: '40px',
                }}
              >
                <CustomTextfeild
                  type={'text'}
                  error={error.privateConstValScannerKey}
                  label={t('KEYCONFIGURATIONANDROIDKEYPRIVATESCANNER')}
                  name={'privateConstValScannerKey'}
                  required={true}
                  // value={discountPayload.name}
                  handleChange={(e) => handleKeyFieldChange(e)}
                />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                style={{
                  marginTop: '0px',
                  marginLeft: '40px',
                }}
              >
                <CustomTextfeild
                  type={'text'}
                  error={error.numberOfDevices}
                  label={t('CUSTDATABLE33')}
                  name={'numberOfDevices'}
                  required={true}
                  // value={discountPayload.name}
                  validation={'restrictNumericValueToTenDigits'}
                  handleChange={(e) => handleKeyFieldChange(e)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              md={8}
              sm={12}
              justifyContent="center"
              spacing={3}
              style={{
                marginTop: '30px',
                marginLeft: '-175px',
                marginBottom: '100px',
              }}
            >
              <Grid item>
                <CancelButtons
                  onClick={handleCancel}
                  color="secondary"
                  variant="contained"
                >
                  <Typography>{t('COMMON008')}</Typography>
                </CancelButtons>
              </Grid>
              <Grid item>
                <SubmitButtons onClick={handleSubmit} variant="contained">
                  <Typography>{t('COMMON007')}</Typography>
                </SubmitButtons>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    )
  }
  