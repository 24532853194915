import PropTypes from "prop-types";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Grid } from "@material-ui/core";
import { useDispatch } from 'react-redux';

export default function CustomPhone1({
  isValid,
  value,
  handleChange,
  specialLabel,
  disabled,
  placeholder,
  country
}) {
  const zlink = localStorage.getItem("redirectFrom");

  const dispatch = useDispatch();

  return !zlink ? (
    <>
      <Grid>
        <PhoneInput
          fullWidth
          placeholder={placeholder}
          specialLabel={specialLabel}
          searchPlaceholder=""
          countryCodeEditable={true}
          enableSearch="true"
          disabled={disabled}
          disableCountryCode={false}
          enableAreaCodes={false}
          isValid={isValid}
          country={country}
          value={value}
          onChange={handleChange}
          inputStyle={{
            width: "100%",
            height: "56px",
           
           
          }}
        />
      </Grid>
    </>
  ) : (
    <>
      <Grid>
        <PhoneInput
          fullWidth
          placeholder="Visitor Mobile"
          specialLabel={specialLabel}
          searchPlaceholder=""
          countryCodeEditable={true}
          enableSearch="true"
          disabled={disabled}
          disableCountryCode={false}
          enableAreaCodes={false}
          isValid={isValid}
          value={value}
          onChange={handleChange}
          inputStyle={{
            width: "100%",
            height: "56px",
           
          }}
        />
      </Grid>
    </>
  );
}

CustomPhone1.propTypes = {
  value: PropTypes.any,
  isValid: PropTypes.bool,
  handleChange: PropTypes.func,
  specialLabel: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  country: PropTypes.string,
};
