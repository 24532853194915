import userReducer from '../slices/onboard/LoginSlice'
import ThemeOptions from '../reducers/ThemeOptions'
import { configureStore } from '@reduxjs/toolkit'
import loaderReducer from '../slices/Loader/LoaderSlice'
import profileReducer from '../slices/User/UserSlice1'
import AccessLevelReducer from '../slices/VisitorAccessLevels/VisitorAccessLevelSlice'

const reducer = {
  user: userReducer,
  loader: loaderReducer,
  ThemeOptions: ThemeOptions,
  profile: profileReducer,
  accessLevel: AccessLevelReducer,
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store
