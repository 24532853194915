import {
  Grid,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { ThemeContext } from '../../theme/ThemeContext';

export default function FilterChecklistTemplate(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = useState({
    name: "",
  });

  const handleClose = () => {
    setPayload({
      name: "",
    });
    setOpen(false);
  };

  const [error, setError] = useState({
    name: false,
  });

  const reset = () => {
    // if (
    //   payload.name.trim() === ""
    // ) {
    //   toast.error(t("Nothing to Reset"), {
    //     closeButton: false,
    //     hideProgressBar: true,
    //   });
    //   return;
    // }

    // setPayload({
    // name: "",
    // });
    setOpen(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (
      payload.name.trim() === ""
    ) {
      Toast(t('COMMON154'), 'error')
      return;
    }



    props.applyFilter(payload);
    setPayload({
      name: "",
    });
    handleClose();
  };
  useEffect(() => { }, []);

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t("COMMON006")}
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
          <FilterIcon />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
            {t('COMMON006')}
          </span>
        }
        acceptText={t("COMMON024")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid container justifyContent="center" alignItems={"center"} style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
          <Grid item xs={12} sm={9} style={{ marginTop: "10px" }}>
            <CustomTextfield
              label={t("COMMON044")}
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
            />
          </Grid>

        </Grid>
      </DialogBox>
    </>
  );
}
