import { Grid } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { Typography } from "@mui/material";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as DeleteVisitIcon } from "../../../src/assets/DeleteVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Toast from "../../components/ToastContainer/CustomToast";
import { deleteBlackListType } from "../../slices/BlacklistType/BlacklistTypeSlice";
import { deleteTimeSlot } from "../../slices/TimeSlot/TimeSlotSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import { ReactComponent as DeleteIcon }  from '../../../src/assets/DeleteIcon.svg'

export default function DeleteTimeSlot(props) {
    const { open, handleClose } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);

    const handleSubmit = () => {

        const payload = {
         id : props.DeleteId,
         name : props.name
        }
        dispatch(deleteTimeSlot(payload))
        .unwrap()
        .then((data) => {
          if (data.code === "DMSI0009") {
            Toast(t("TIMESLOT7"), "success");
            props.delete();
            handleClose();
          } 
          else if(data.code==="DMSE00608")
          Toast(t("TIMESLOT6"), "error");
          
          else 
          Toast(data.message, "error");
          handleClose();
        })
        .catch((er) => {});
      }

    return (
        <>
            <DialogBox
                Header={
                    <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
                      {t("COMMON004")}
                    </span>
                  }
                acceptText={t("confirmVisitorButton")}
                cancelText={t("COMMON008")}
                fullWidth={true}
                onClose={() => handleClose(false)}
                cancel={handleClose}
                onSubmit={handleSubmit}
                open={open}
            >
                <Grid
                    container
                    justifyContent="center"
                    direction="column"
                    alignItems={"center"}
                    style={{ padding: "20px", backgroundColor: darkMode ? '#0F273B' : '' }}
                >
                    <Grid>
                        <DeleteIcon width="60px" height={'50px'}/>
                    </Grid>
                    <Grid>
                        <Grid
                            style={{
                                wordWrap: "break-word",
                                opacity: 1,
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "row",
                                fontSize: "17px",
                                marginTop: "15px",
                                color: darkMode ? '#fff' : '',
                            }}
                        >
                            <Typography style={{ color: "#242424", marginRight: "4px", fontSize: '17px' ,color: darkMode ? '#fff' : ''}}>
                                {t("COMMON018")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogBox>
        </>
    );
}
