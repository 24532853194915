import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'
import { getDiscount } from '../../slices/Discount/DiscountSlice'
import CommonUtil from '../Util/CommonUtils'
import SubmitButtons from './SubmitButtons'
import Toast from '../ToastContainer/CustomToast'
import { purchasePlan } from '../../slices/Subscription/SubscriptionSlice'
import jwt_decode from 'jwt-decode'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  purchaseDialog: {
    '& .MuiDialog-paper': {
      minWidth: '800px',
      marginLeft: '250px',
    },
  },
}))

function PurchaseDialog(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [yearlyPrice, setYearlyPrice] = useState()
  const [yearlyChecked, setYearlyChecked] = useState(false)
  const dispatch = useDispatch()
  const [discount, setDiscount] = useState()
  const [monthlyPrice, setMonthlyPrice] = useState(props.data.price)
  const [discountAmount, setDiscountAmount] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const [discountObject , setDiscountObject] = useState({

  })
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )
  const [payload, setPayload] = useState({
    subscriptionName: '',
    totalPrice: monthlyPrice,
    discountId: '',
    payementFrequency: 'month',
    companyId: '',
    productId: '',
    subscriptionId: '',
  })
  const [productId, setProductId] = useState(props.data.productId)
  const [subscriptionId, setSubscriptionId] = useState(props.subscriptionId)
  // alert(props.data.productId)

  useEffect(() => {
    const discountPayload = {
      status: true,
    }
    dispatch(getDiscount(discountPayload))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4000' && !CommonUtil.isEmpty(data.data))
          setDiscount(data.data.discountValue)
        setPayload({
          ...payload,
          discountId: data.data.id,
        })
        setDiscountObject(data.data)
      })
  }, [])

  useEffect(() => {
    if (props.yearlyPlan) {
      let totalAmountPerYear = monthlyPrice * 12
      setYearlyPrice(totalAmountPerYear)
      let discountRate = discount / 100
      let discountAmount = totalAmountPerYear * discountRate
      setDiscountAmount(discountAmount)
      let finalAmount = totalAmountPerYear - discountAmount
      setTotalAmount(finalAmount)
      setPayload({
        ...payload,
        totalPrice: finalAmount,
        payementFrequency: 'year',
      })
    }
  }, [discount])

  const handleCheckBox = (event) => {
    setYearlyChecked(event.target.checked)
    if (event.target.checked === true) {
      let totalAmountPerYear = monthlyPrice * 12
      setYearlyPrice(totalAmountPerYear)
      let discountRate = discount / 100
      let discountAmount = totalAmountPerYear * discountRate
      setDiscountAmount(discountAmount)
      let finalAmount = totalAmountPerYear - discountAmount
      setTotalAmount(finalAmount)
      setPayload({
        ...payload,
        totalPrice: finalAmount,
        payementFrequency: 'year',
      })
    }
    if (event.target.checked === false) {
      setYearlyPrice()
      setDiscountAmount()
      setTotalAmount()
      setPayload({
        ...payload,
        payementFrequency: 'month',
        totalPrice: monthlyPrice,
      })
    }
  }

  const handleChange = (event) => {
    setPayload({
      ...payload,
      subscriptionName: event.target.value,
    })
  }

  const handleSubmit = () => {
    setPayload({
      ...payload,
      discountId: props.yearlyPlan ? discountObject.id : '',
      companyId: decoded_jwtToken.companyId,
      productId: productId,
      subscriptionId: subscriptionId,
    })
    let dto = {
      ...payload,
      discountId: props.yearlyPlan ?  discountObject.id : '',
      companyId: decoded_jwtToken.companyId,
      productId: productId,
      subscriptionId: subscriptionId,
      subscriptionName: props.data.planName,
      payementFrequency: props.yearlyPlan ? 'year' : 'month',
    }
    dispatch(purchasePlan(dto))
      .unwrap()
      .then((res) => {
        switch (res.code) {
          case "MSGS4031":
            Toast(t('SUCCESSMESSAGE'), 'success')
            props.handleClose()
            props.handlePurchasedPlan(res.data)
            break;
          default:
            break;
        }
      })
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={true}
        disable
        PaperProps={{
          style: {
            borderRadius: '20px !important',
          },
        }}
        className={classes.purchaseDialog}
      >
        <DialogTitle variant="h4" onClick={props.handleClose}>
          <IconButton
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {t('PURCHASESUMMARY')}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <Grid container padding={2}>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                {' '}
               {t('PURCHASEPLANNAME')}{' '}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                {props.data.planName}{' '}
              </Typography>
            </Grid>
          </Grid>
          <Grid container padding={2}>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                {' '}
                {t('PURCHASEPREICE')}  {' '}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                {' '}
                {'$ ' + props.data.price + t('MONTHLY_VALUE')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container padding={2}>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                {' '}
                {t('PURCHASETOTALYEARLY')} {' '}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              {props.yearlyPlan ? (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {'$ ' + yearlyPrice + t('YERALY_VALUE')}
                </Typography>
              ) : (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {yearlyPrice}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container padding={2}>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                {' '}
               {t('PURCHASEDISCOUNT')} {' '}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              {props.yearlyPlan ? (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {'$ ' + discountAmount}
                </Typography>
              ) : (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {discountAmount}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container padding={2}>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                {t('PURCHASEAMOUNTAFTERDISCOUNT')}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              {props.yearlyPlan ? (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {' '}
                  {'$ ' + totalAmount + t('YERALY_VALUE')}{' '}
                </Typography>
              ) : (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {' '}
                  {totalAmount}{' '}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <SubmitButtons variant="contained" onClick={handleSubmit}>
            <Typography variant="body1" style={{ textTransform: 'none' }}>
              {t('COMMON007')}
            </Typography>
          </SubmitButtons>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PurchaseDialog
