
import { styled, Typography } from "@material-ui/core";
import { Box, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Tooltip, tooltipClasses } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CommonUtil from "../../Util/CommonUtils";
import info from "../../assets/images/onboard/info.svg";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { addHealthQuestionarie, updateHealthQuestionarie } from "../../slices/HealthQuestionaries/HealthQuestionariesSlice";
import { ThemeContext } from '../../theme/ThemeContext';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#36C96D0D',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #007CE8',
    marginRight: '250px'
  },
}));

export default function AddHealthQuestionaries(props) {
  const { open, handleClose } = props;
  const [selectedObject, setSelectedObject] = React.useState(props.selectedObject);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [str, setStr] = useState("");
  const [iconHovered, setIconHovered] = useState(false);
  const { darkMode } = useContext(ThemeContext);
  const handleMouseEnter = () => setIconHovered(true);
  const handleMouseLeave = () => setIconHovered(false);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: name === 'isMandatory' ? event.target.checked : event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
      setState({
        ...state,
        options: state.options + ' ',
      });
    } else if (event.key === 'Backspace') {
      event.preventDefault();
      const options = state.options;
      if (options.length > 0) {
        setState({
          ...state,
          options: options.slice(0, -1),
        });
      }
    }
  };


  const [state, setState] = React.useState({
    question: '',
    options: [],
    expertedAnswer: '',
    isMandatory: true,
    // isDisabled:false,
  });

  const [error, setError] = React.useState({
    question: false,
    options: false,
    expertedAnswer: false,
    isMandatory: false,
    // isDisabled:false
  });

  const handleSubmit = () => {

    if (state.question === '' || state.question === undefined) {
      setError({ question: 'error', });
      Toast(t('CHECKLISTFORM001'), "error");
      return false;
    }

    if (/[,";'"]/.test(state.question)) {
      Toast("Check list item cannot contain commas, semicolons, single quotes or double quotes", "error");
      setError({ question: 'error' });
      return false;
    }

    if (state.options === '' || state.options === undefined) {
      setError({ options: 'error', });
      Toast(t('CHECKLISTFORM002'), "error");
      return false;
    }

    if (CommonUtil.isEmptyString(state.expertedAnswer)) {
      Toast(t('ENTER_EXPECTED_ANSWER'), "error");
      setError({ expertedAnswer: true });
      return false;
    }

    if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      const newTemplate = {
        question: state.question,
        options: [state.options],
        expertedAnswer: state.expertedAnswer,
        mandatory: state.isMandatory ? 'Yes' : 'No',
        templateId: props.templateId,
        isDisabled: false,
      };

      dispatch(addHealthQuestionarie(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI0055") {
            Toast((data.message), 'success')
            handleClose();
          } else {
            Toast((data.message), 'error')
          }
        });

    }

    else {
      const updateTemplate = {
        id: CommonUtil.isEmpty(props.selectedObject) ? null : props.selectedObject.id,
        question: state.question,
        options: [state.options],
        expertedAnswer: state.expertedAnswer,
        mandatory: state.isMandatory ? 'Yes' : 'No',
        templateId: props.templateId,
        isDisabled: false
      };

      dispatch(updateHealthQuestionarie(updateTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI0056") {
            Toast((data.message), 'success')
            handleClose();
          } else {
            Toast((data.message), 'error')
          }
        });
    }
  }

  useEffect(() => {
    if (props.selectedObject) {
      setState({
        ...state,
        id: props.selectedObject.id,
        question: props.selectedObject.question,
        options: props.selectedObject.options ? props.selectedObject.options[0] : '',
        //    options: props.selectedObject.options,
        expertedAnswer: props.selectedObject.expertedAnswer,
        isMandatory: props.selectedObject.mandatory === 'Yes' || props.selectedObject.mandatory === 'yes',
      });
    }

  }, []);

  return (
    <>

      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
           {state.id ? t('EDIT_CHECKLIST') : t('CREATE_CHECKLIST')}
          </span>
        }
        acceptText={t('COMMON007')}
        cancelText={t('COMMON008')}
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" , backgroundColor: darkMode ? '#0F273B' : '' }}
          spacing={2}
        >
          <Grid item xs={12}>
            <CustomTextfield
              type={'text'}
              label={t('tableColumnHeaderItem')}
              error={error.question}
              name="question"
              value={state.question}
              handleChange={(e) => handleChange(e)}
              required={true}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label={t('slectLabeloptions')}
              error={error.options}
              name="options"
              value={state.options}
              handleChange={(e) => handleChange(e)}
              onKeyDown={handleKeyDown}
              required={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography color='inherit'>{t('CHECKLISTOPTIONSINFO001')}</Typography>
                          <b>{t('CHECKLISTOPTIONSINFO003') + ': '}</b> <em>{t('CHECKLISTOPTIONSINFO002')}</em>
                        </React.Fragment>
                      }
                    >
                      <IconButton
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <img src={info} alt='logo' className='left_img' />
                        {/* <InfoIcon style={{fontSize:"40px", color:"#00000029"}}/> */}
                      </IconButton>
                    </HtmlTooltip>
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: iconHovered ? 30 : 0 }}>
            <CustomTextfield
              type={'text'}
              label={t('EXPECTED_ANSWER')}
              error={error.expertedAnswer}
              name="expertedAnswer"
              value={state.expertedAnswer}
              handleChange={(e) => handleChange(e)}
              required={true}
            />
          </Grid>

          <Grid item xs={12} >
            <Box>
              <FormControlLabel
                control={<Checkbox checked={state.isMandatory} style={{ color: process.env.REACT_APP_BG_ICON }}
                  onChange={handleChange}
                  name='isMandatory' />}
                  label={<span style={{ color: darkMode ? '#fff' : '' }}>{t('CHECKLISTFORM006')}</span>}
                />
                
            </Box>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
