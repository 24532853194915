import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import HeaderToolbar from '../../components/HeaderToolbar'
import FlowchartTabs from './FlowchartTabs'



function VisitingFlowchart() {
    return (
        <>
            <HeaderToolbar title=" Visiting Flowchart" />
               <FlowchartTabs />
        </>
    )
}

export default VisitingFlowchart