import React, { useState } from 'react'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import visLogo from '../../assets/images/onboard/vis-logo.png'
import USA_visLogo from '../../assets/USA_Images/login_images/App-logo.svg'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import { InputAdornment, TextField } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import USACustomTextfield from '../../components/CustomInputs/USACustomTextfield'
import LoginLayoutV2 from '../OnBoardV2/LoginLayoutV2'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  generateAuthenticationCode,
  validateAuthenticationCode,
} from '../../slices/LoginAuthentication/LoginAuthenticationSlice'
import CommonUtil from '../../components/Util/CommonUtils'
import keyIcon from '../../assets/LoginKeyAuthenticate.svg';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    marginTop: theme.spacing(3),
  },
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#fff !important',
    },
  },
 textField : {
  '& .MuiInputLabel-root' :{
    color : "white"
  },
  '& .MuiInputLabel-root.Mui-focused' : {
    color : "white"
  },
  '& .MuiOutlinedInput-root' : {
     color : "white",
    '& fieldset': {
      borderColor: 'white', // Default border color
    },
    '&:hover fieldset': {
      borderColor: 'white', // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white', // Border color when focused
    },
  }
 }
}))


function LoginAuthentication(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const rememberMe = false
  const open = true

  const [payload, setPayload] = useState({
    code: '',
    email: props.location.state.data.email,
    rememberMe: rememberMe,
  })

  const [error, setError] = useState({
    code: false,
  })

  const setRememberMeCookie = (data) => {
    CommonUtil.setCookie('loginSecurityKey', data.loginSecretKey, 30)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (CommonUtil.isEmpty(payload.code)) {
      setError({ ...error, code: true })
      Toast(t('ENTER_OTP'), 'error')
      return
    }

    dispatch(validateAuthenticationCode(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4046') {
          Toast(data.message, 'success')
          history.push('/vis/verify-company')
          if (data.data.loginSecretKey !== null) setRememberMeCookie(data.data)
        } else Toast(data.message, 'error')
      })
      .catch((er) => {})
  }

  const handleChange = (event) => {
    const value = event.target.value
    const name = event.target.name

    setPayload({
      ...payload,
      [name]: value,
    })
    setError({
      [name]: false,
    })
  }

  const handleResendOTP = () => {
    const authenticationDTO = {
      email: props.location.state.data.email,
      userId: props.location.state.data.userId,
    }
    dispatch(generateAuthenticationCode(authenticationDTO)).unwrap()
  }

  const handleRememberMe = () => {
    setPayload({
      ...payload,
      rememberMe: !rememberMe,
    })
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
  }

  const handleOnPasteEvent = (event) => {
    event.preventDefault()
  }

  const handleOnCopyEvent = (event) => {
    event.preventDefault()
  }

  const handleOnCutEvent = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <LoginLayoutV2 lang={true}>
        <Grid className="center_div">
          <Grid container alignItems="center">
            <Grid item className="logo-center">
              <img
                src={
                  process.env.REACT_APP_ENVIRONMENT === 'USA'
                    ? USA_visLogo
                    : visLogo
                }
                alt="logo"
                style={{ width: '250px' }}
              />
            </Grid>
          </Grid>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid item xs={12}>
              <Typography
                variant="h2"
                align="left"
                style={{
                  color:
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? '#fff'
                      : '#464646',
                  fontWeight: '500',
                  marginTop: '180px',
                  opacity: '0.9',
                }}
              >
                {t('EMAIL_VERIFICATION')}
              </Typography>
              <Typography
                variant="subtitle2"
                align="left"
                style={{
                  color:
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? '#fff'
                      : '#7a7b97',
                  marginBottom: '20px',
                  marginTop: '6px',
                  fontSize: '12px',
                  opacity: '0.8',
                }}
              >
                {t('DIGIT_INFO_TEXT')}
              </Typography>
            </Grid>
            <Grid container>
              {/* <Grid item xs style={{ display: 'flex' , width:"100%" }}> */}
                <TextField
                className={classes.textField}
                  type={'text'}
                  error={error.code}
                  name="code"
                  label={t('ENTER_CODE') + ' *'}
                  value={payload.code}
                  onChange={(e) => handleChange(e)}
                  helperText={error.code}
                  validation="numeric"
                  inputProps={{ maxLength: 6 }}
                  onContextMenu={(e) => handleContextMenu(e)}
                  onPaste={(e) => handleOnPasteEvent(e)}
                  onCopy={(e) => handleOnCopyEvent(e)}
                  onCut={(e) => handleOnCutEvent(e)}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton  edge='end'>
                              <img  src= {keyIcon} alt="loginAuthenticate"/>

                            </IconButton>
                        </InputAdornment>
                    ),
                    maxLength: 25
                }}
                style={{
                  width : "100%",
                }}
                />
              {/* </Grid> */}
            </Grid>
            <Grid container style={{ color: 'white' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#F2824C',
                      '&.Mui-checked': {
                        color: '#F2824C',
                      },
                    }}
                  />
                }
                label={t('REMEMBER_ME')}
                onClick={handleRememberMe}
              />
            </Grid>
            <Grid container>
              <Grid item xs>
                <Button type="submit" fullWidth variant="contained">
                  {process.env.REACT_APP_ENVIRONMENT === 'USA'
                    ? t('VERIFY')
                    : t('COMMON038')}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid item xs>
            <Typography
              variant="h5"
              align="center"
              style={{
                color: '#fff',
                marginTop: '40px',
                opacity: '0.9',
              }}
            >
              {t('CODE_NOT_RECEIVE') + ' '}
              <Link
                component="button"
                style={{
                  color: '#F2824C',
                  opacity: '0.9',
                }}
                onClick={handleResendOTP}
              >
                {t('RESEND')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </LoginLayoutV2>
    </>
  )
}
export default LoginAuthentication
