import { Avatar, Box } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import filterIcon from "../../assets/Customer/Filter.svg";
import forwardIcon from "../../assets/Customer/forward.svg";
import DataTable from "../../components/DataTable/DataTable";
import HeaderToolbar from '../../components/HeaderToolbar';
import Search from '../../components/SearchTab/Search';
import { getCustomers } from '../../slices/Customer/CustomerSlice';
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import ViewIcon from '../Invitations/ViewIcon';
import CustomerTransactionAndReportExports from './CustomerTransactionAndReportExports';
import DownloadInvoiceIcon from './DownloadInvoiceIcon';
import TransactionReportFilter from './TransactionReportFilter';

function TransactionReportForCustomer() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const [clear, setClear] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, pageNumber: 1 })
    setfilter(defaultFilter)
  };

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return ((test.name.toLowerCase().includes(searchedVal.toLowerCase())) ||
        (test.email.toLowerCase().includes(searchedVal.toLowerCase())) ||
        (test.status.toLowerCase().includes(searchedVal.toLowerCase())) ||
        (test.phone ? test.phone.toLowerCase().includes(searchedVal.toLowerCase()) : "")
      )
    })
    setRows(filteredRows);
  };
  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      pageNumber: newPage,
      pageSize: size
    })
  };

  const [defaultFilter, setDefaultFilter] = useState({
    status: ''
  })

  const [filter, setfilter] = useState({
    status: ''
  })

  const [paging, setPaging] = useState({
    pageNumber: "1",
    pageSize: "10"
  })


  const handleFilter = (data) => {
    setClear(true)
    setfilter({
      ...filter,
      status: data.status,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging])

  const loadData = (filter, paging) => {
    const payload = {
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
      status: filter.status
    }
    dispatch(getCustomers(payload))
      .unwrap()
      .then((res) => {
        if (res.code === "UASI0029") {
          setRows(res.data.companies);
          setTotalRecords(res.data.totalCount);
          setPage(res.data.currentPage);
          setTotalPages(res.data.totalPages);

        }

      })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 30) + '...';
    } else {
      return text;
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={params.row.imagePreSignedURL !== undefined ? params.row.imagePreSignedURL : null}
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: "300px",
            height: "300px",
            border: "1px solid #FFFF",
            fontSize: "100px",
            borderRadius: "50%",
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={params.row.imagePreSignedURL}
        ></Avatar>
      }
    />
  );

  const handleView = (params) => {
    history.push({
      pathname: '/vis/customerTransaction/viewpage',
      state: params
    })
  }

  const handleInvoice = (params) => {

  }


  const columns = React.useMemo(() => [
    {
      field: "company.profile",
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return ZoomHandaler(params);
      },
    },

    {
      field: "companyName",
      headerName: t("CUSTDATABLE01"),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: "email",
      headerName: t("CUSTDATABLE02"),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.email,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: "mobile",
      headerName: t("CUSTDATABLE03"),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.phone,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: "transactionId",
      headerName: t("CUSTDATABLE12"),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.transactionId,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: "amountPaid",
      headerName: t("CUSTDATABLE13"),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.amountpaid,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: "billingCycle",
      headerName: t("CUSTDATABLE14"),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.billingcycle,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: "paymentDate",
      headerName: t("CUSTDATABLE15"),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.paymentdate,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 150,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ViewIcon />}
          label={t('VIEW')}
          onClick={() => handleView(params.row)}
        />,
        <GridActionsCellItem
          icon={<DownloadInvoiceIcon />}
          label={t('INVOICE')}
          onClick={() => handleInvoice(params.row)}
        />

      ],
    },
  ]);

  const handleFilterIcon = () => {
    setOpenFilter(true);
  }

  const handleDownload = () => {
    setOpenDownload(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <HeaderToolbar
        title={t("CUSTDATABLE11")}
      />

      <Box display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item style={{ margin: "0 8px" }}>
          <Tooltip title={t("ADD036")} onClick={handleFilterIcon}>
            <img src={filterIcon} />
          </Tooltip>
        </Box>

        <Box item style={{ margin: "0 8px" }}>
          <Tooltip title={t("ADD037")} onClick={handleDownload}>
            <img src={forwardIcon} />
          </Tooltip>
        </Box>

        <Box item>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%", marginTop: "15px" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openFilter && (
        <TransactionReportFilter
          open={openFilter}
          applyFilter={(data) => handleFilter(data)}
          handleClose={() => {
            setOpenFilter(false)
          }}
        />
      )}

      {openDownload && (
        <CustomerTransactionAndReportExports
          open={openDownload}
          handleClose={() => {
            setOpenDownload(false)
          }}
        />
      )}

    </>
  )
}
export default TransactionReportForCustomer