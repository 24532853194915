import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

let initialState = {
    loader: false
};

export const startLoader = createAsyncThunk('loader/start', async (payload) => {
    return;
});

export const stopLoader = createAsyncThunk('loader/stop', async (payload) => {
    return;
});

const LoaderSlice = createSlice({
    name: 'loader',
    initialState,
    extraReducers: {
        [startLoader.fulfilled]: (state, action) => {
            state.loader = true;
        },
        [stopLoader.fulfilled]: (state, action) => {
            state.loader = false;
        },
    }
})

const { reducer } = LoaderSlice;
export default reducer;