import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { logout, reinit } from '../slices/User';

export default function SecuredRoute({ component: Component, ...otherProps }) {
  const dispatch = useDispatch();
  const [logoutUser, setLogout] = useState(false);
  const [allowedCompanyTypes , setAllowedCompanyTypes] = useState(otherProps.allowedCompanyType)
  const [allowedRoles , setAllowedRoles] = useState(otherProps.allowedRoles)

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )
  
const [roleName , setRoleName] = useState(decoded_jwtToken.roleName)
const [companyType , setCompanyType] = useState(decoded_jwtToken.companyType)
  useEffect(() => {
    const jwtToken = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);

    if (jwtToken) {
      const decoded_jwtToken = jwt_decode(jwtToken);
      const currentTime = Date.now() / 1000;
      if (decoded_jwtToken.exp < currentTime) {
        dispatch(logout());
        window.location.href = '/';
        setLogout(true);
      } else {
        setLogout(false);
        dispatch(reinit(decoded_jwtToken));
      }
    } else {
      dispatch(logout());
      window.location.href = '/';
      setLogout(true);
    }
  }, []);

  const hasRequiredRole = Array.isArray(allowedRoles) && allowedRoles.length > 0
    ? allowedRoles.includes(roleName)
    : true;

    const hasValidCompanyType = Array.isArray(allowedCompanyTypes) && allowedCompanyTypes.length > 0
    ? allowedCompanyTypes.includes(companyType)
    : true;

    return (
      <Route
        {...otherProps}
        render={(props) => 
          logoutUser ? (
            <Redirect to='/' />
          ) : !hasValidCompanyType || !hasRequiredRole ? (
            <Redirect to='/vis/unauthorized' />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
}
