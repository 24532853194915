
export const options = [
  {
    parent: {
      title: 'Dashboard',
      codes: [
        'DASHBOARD',
        'DASHBOARD',
      ]
    },
    child: [
      {
        title: 'Dashboard',
        code: 'DASHBOARD',
      },
    ]
  },
  {
    parent: {
      title: 'Visitor',
      codes: [
        'VISITOR',
        'INVITATION',
        'WALK-IN_REGISTRATION',
        'APPROVALS',
        'BLACKLIST_VISITOR'
      ]
    },
    child: [
      {
        title: 'Invitation',
        code: 'INVITATION',
      },
      {
        title: 'Walk-In Registration',
        code: 'WALK-IN_REGISTRATION',
      },
      {
        title: 'Approvals',
        code: 'APPROVALS',
      },
      {
        title: 'Blacklist Visitor',
        code: 'BLACKLIST_VISITOR',
      },
    ],
  },
  {
    parent: {
      title: 'Employees',
      codes: [
        'EMPLOYEES',
        'PERSON',
        'ROLE'
      ]
    },
    child: [
      {
        title: 'Person',
        code: 'PERSON'
      },
      {
        title: 'Role',
        code: 'ROLE'
      },
    ]
  },
  {
    parent: {
      title: 'Site Management',
      codes: [
        'SITE_MANAGEMENT',
        'ADDRESS_TYPE',
        'SITE_TYPE',
        'SITE',
        'ZONE',
        'TIMES_LOTS',
        'VISITING_ACCESS_LEVEL'
      ]
    },
    child: [
      {
        title: 'Address Type',
        code: 'ADDRESS_TYPE'
      },
      {
        title: 'Site Type',
        code: 'SITE_TYPE'
      },
      {
        title: 'Site',
        code: 'SITE'
      },
      {
        title: 'Zone',
        code: 'ZONE'
      },
      {
        title: 'Time Slots',
        code: 'TIMES_LOTS'
      },
      {
        title: 'Visiting Access Level',
        code: 'VISITING_ACCESS_LEVEL'
      }
    ]
  },
  {
    parent: {
      title: 'Device Management',
      codes: [
        'DEVICE_MANAGEMENT',
        'DEVICE_LIST'
      ]
    },
    child: [
      {
        title: 'Device List',
        code: 'DEVICE_LIST'
      }
    ]
  },
  {
    parent: {
      title: 'Report',
      codes: [
        'REPORT',
        'DAILY_REPORT',
        'INVITATION',
        'WALK-IN_REGISTRATION',
        'SELF_REGISTRATION'
      ]
    },
    child: [
      {
        title: 'Daily Report',
        code: 'DAILY_REPORT'
      },
      {
        title: 'Invitation',
        code: 'INVITATION'
      },
      {
        title: 'Walk-In Registration',
        code: 'WALK-IN_REGISTRATION'
      },
      {
        title: 'Self Registration',
        code: 'SELF_REGISTRATION'
      }
    ]
  },
  {
    parent: {
      title: 'Settings',
      codes: [
        'SETTINGS',
        'VISITOR_TYPE',
        'PURPOSE_TYPE',
        'BLACKLIST_TYPE',
        'CHECKLIST',
        'WATCHLIST',
        'URGENT_MESSAGES',
        'ADDITIONAL_SETTINGS',
        'CARD_TEMPLATE'
      ]
    },
    child: [
      {
        title: 'Visitor type',
        code: 'VISITOR_TYPE',
      },
      {
        title: 'Purpose type',
        code: 'PURPOSE_TYPE',
      },
      {
        title: 'Blacklist type',
        code: 'BLACKLIST_TYPE',
      },
      {
        title: 'Checklist',
        code: 'CHECKLIST',
      },
      {
        title: 'Watchlist',
        code: 'WATCHLIST',
      },
      {
        title: 'Urgent Messages',
        code: 'URGENT_MESSAGES',
      },
      {
        title: 'Additional Setting',
        code: 'ADDITIONAL_SETTING',
      },
      {
        title: 'Card template',
        code: 'CARD_TEMPLATE',
      },
    ],
  },
]