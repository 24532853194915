import api from "../../config/http-common"
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (payload) => {
    let query = "?isPagable=true&pageNumber=" + payload.pageNumber + "&pageSize=" + payload.pageSize;
    if(!CommonUtil.isEmptyString(payload.recipient)){
        query = query +  "&recipient=" +payload.recipient
    }

    return query;
}

const getSmsTemplate = (payload) => {
    return api.securedAxios().get('/web/SmsTemplate/list' + buildQuery(payload))
}

const updateSmsTemplate = (payload) => {
    return api.securedAxios().put('/web/UpdateTemplate/' + payload.id , payload)
}

const SmsTemplateService = {
    getSmsTemplate,
    updateSmsTemplate
}

export default SmsTemplateService;