import React, { useEffect, useState } from 'react'
import { Link, Grid, Box, Typography } from '@material-ui/core'
import Button from '@mui/material/Button'
import { makeStyles } from '@material-ui/core/styles'
import './custom.css'
import { useHistory, withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import LoginLayoutV2 from './LoginLayoutV2'
import { useTranslation } from 'react-i18next'
import forgot_pass from '../../assets/USA_Images/login_images/forgotpassword.svg'
import vis_forgot_pass from '../../assets/images/onboard/forgot_pass_img.svg'
import forgot_password from '../../assets/USA_Images/login_images/forgotPass.svg'
import vis_forgot_password from '../../assets/images/onboard/forgot_password.svg'
import CommonUtil from '../../Util/CommonUtils'
import Stack from '@mui/material/Stack'
import { InputAdornment } from '@mui/material'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import info from '../../assets/images/onboard/info.svg'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { resetPassword } from '../../slices/User'
import Toast from '../../components/ToastContainer/CustomToast'
import { ReactComponent as PassIcon } from '../../assets/passIcon.svg'
import { ReactComponent as USAPassIcon } from '../../assets/USA_Images/login_images/pass_icon.svg'
import USACustomTextfield from '../../components/CustomInputs/USACustomTextfield'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    marginTop: theme.spacing(3),
  },
  submit: {
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  },
}))

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9d6',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #2281AB',
    marginRight: '180px',
  },
}))

function ChangePassword(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
    confirmPassword: '',
    confirmShowPassword: false,
  })

  const [code, setCode] = useState('')

  useEffect(() => {
    var urlValue = window.location.href
    var url = new URL(urlValue)
    var code = url.searchParams.get('code')
    setCode(code)
  }, [])

  const [error, setError] = useState({
    showPassword: false,
    confirmPassword: false,
  })

  const handleChange = (event) => {
    const value = event.target.value
    const name = event.target.name

    setValues({
      ...values,
      [name]: value,
    })
    setError({
      [name]: false,
    })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleClickConfirmShowPassword = () => {
    setValues({ ...values, confirmShowPassword: !values.confirmShowPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const user = {
      password: values.password,
      confirmPassword: values.confirmPassword,
      code: code,
    }

    if (CommonUtil.isEmptyString(values.password)) {
      setError({ ...error, password: true })
      Toast(t('REGISTERUSER0002'), 'error')
      return
    }

    if (
      !values.password.match(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/,
      )
    ) {
      setError({ ...error, password: true })
      Toast(t('PASSWORDERROR'), 'error')
      return
    }
    if (CommonUtil.isEmptyString(values.confirmPassword)) {
      setError({ ...error, confirmPassword: true })
      Toast(t('REGISTERUSER0004'), 'error')
      return
    }

    if (
      !values.confirmPassword.match(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/,
      )
    ) {
      setError({ ...error, confirmPassword: true })
      Toast(t('PASSWORDERROR'), 'error')
      return
    }

    if (values.password !== values.confirmPassword) {
      setError({ ...error, confirmPassword: true })
      Toast(t('REGISTERUSER0007'), 'error')
      return false
    }
    dispatch(resetPassword(user))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0601') {
          history.push('/vis/success-screen')
        } else {
          onFail(data)
        }
      })
      .catch((er) => {})
  }

  const onFail = (data) => {
    switch (data.code) {
      case 'CVAE0607':
        Toast('REGISTERUSER0007', 'error')
        break
      case 'CVAE0603':
        Toast('Password is required', 'error')
        break
      case 'CVAE0605':
        Toast('Confirm Password is required', 'error')
        break
      case 'CVAE0612':
        history.push('/vis/result-page')
        break
      case 'CVAE0606':
        Toast(t('REGISTERUSER0007'), 'error')
        break
      default:
        break
    }
  }

  return (
    <LoginLayoutV2
      lang={true}
      src={
        process.env.REACT_APP_ENVIRONMENT === 'USA'
          ? forgot_pass
          : vis_forgot_pass
      }
    >
      <Grid className="center_div">
        <Grid container alignItems="center">
          <Grid item className="logo-center">
            <img
              src={
                process.env.REACT_APP_ENVIRONMENT === 'USA'
                  ? forgot_password
                  : vis_forgot_password
              }
              alt="logo"
              style={{ width: '180px' }}
            />
          </Grid>
        </Grid>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              align="left"
              style={{
                color: '#fff',
                fontWeight: '500',
                marginTop: '30px',
                opacity: '0.9',
                marginBottom: '20px',
              }}
            >
              Change Password
            </Typography>
          </Grid>
          <Grid container alignItems="center" style={{ display: 'flex' }}>
            <Grid container alignItems="center" style={{ display: 'flex' }}>
              <Grid item xs style={{ display: 'flex' }}>
                <Grid className="company_icon">
                  <USAPassIcon className="visibleIcons" />
                </Grid>
                <USACustomTextfield
                  type={values.showPassword ? 'text' : 'password'}
                  error={error.password}
                  name="password"
                  label="New Password *"
                  value={values.password}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.password}
                  inputProps={{ maxLength: 25 }}
                  //  validation='password'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="PasswordIcon"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility className="visibleIcons" />
                          ) : (
                            <VisibilityOff className="visibleIcons" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    maxLength: 25,
                  }}
                />
              </Grid>
              <Grid>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      {
                        'Password must be at least 8 characters long and contains at least 1 number, 1 uppercase character, 1 lowercase character and 1 special character'
                      }
                    </React.Fragment>
                  }
                >
                  <img src={info} alt="logo" className="info_img" />
                </HtmlTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ display: 'flex' }}>
            <Grid item xs style={{ display: 'flex' }}>
              <Grid className="company_icon">
                <USAPassIcon className="visibleIcons" />
              </Grid>
              <USACustomTextfield
                type={values.confirmShowPassword ? 'text' : 'password'}
                error={error.confirmPassword}
                name="confirmPassword"
                label="Confirm Password *"
                value={values.confirmPassword}
                handleChange={(e) => handleChange(e)}
                helperText={error.confirmPassword}
                inputProps={{ maxLength: 25 }}
                //  validation='password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="PasswordIcon"
                        onClick={handleClickConfirmShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.confirmShowPassword ? (
                          <Visibility className="visibleIcons" />
                        ) : (
                          <VisibilityOff className="visibleIcons" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  maxLength: 25,
                }}
              />
            </Grid>
            <Grid>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    {
                      'Password must be at least 8 characters long and contains at least 1 number, 1 uppercase character, 1 lowercase character and 1 special character'
                    }
                  </React.Fragment>
                }
              >
                <img src={info} alt="logo" className="info_img" />
              </HtmlTooltip>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={5}
            alignItems="center"
            style={{ marginTop: '20px' }}
          >
            <Grid item xs>
              <Button fullWidth variant="contained" type="submit">
                Reset Password
              </Button>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </form>

        <Grid container alignItems="center">
          <Grid item xs>
            <Box mt={2} mb={2} align="center">
              <Typography
                variant="body2"
                align="center"
                style={{ fontSize: '12px', color: '#fff' }}
              >
                Back to{' '}
                <Link href="/vis/login" className="link_green">
                  {' '}
                  {t('LOGINFORM0002')}
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </LoginLayoutV2>
  )
}

export default withRouter(ChangePassword)
