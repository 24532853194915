import React from "react";
import { Tooltip, Grid } from "@mui/material";

const AccountActivateIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <>
      <Tooltip title="Account activate">
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
           <svg xmlns="http://www.w3.org/2000/svg" width="17.465" height="18.85" viewBox="0 0 17.465 18.85">
           <path id="Path_7569" data-name="Path 7569" d="M-1355.749-4033.135a4.237,4.237,0,0,1,4.233-4.232,4.237,4.237,0,0,1,4.231,4.232,4.238,4.238,0,0,1-4.231,4.234A4.238,4.238,0,0,1-1355.749-4033.135Zm1.541,0a2.694,2.694,0,0,0,2.692,2.69,2.693,2.693,0,0,0,2.69-2.69,2.693,2.693,0,0,0-2.69-2.689A2.693,2.693,0,0,0-1354.208-4033.135Zm-10.542,3.8v-2.01a7.731,7.731,0,0,1,7.722-7.721A7.71,7.71,0,0,1-1353.1-4038l.862.506-.963.268a4.667,4.667,0,0,0-3.4,4.48,4.612,4.612,0,0,0,.867,2.7l.7.7Zm1.542-2.01v.469h5.345a6.179,6.179,0,0,1-.282-1.865,6.225,6.225,0,0,1,2.156-4.7,6.376,6.376,0,0,0-1.039-.086A6.186,6.186,0,0,0-1363.208-4031.348Zm10.924-.265-1.021-.826a.6.6,0,0,1-.09-.843.6.6,0,0,1,.844-.091l.565.456,1.429-1.693a.6.6,0,0,1,.845-.072.6.6,0,0,1,.071.846l-1.807,2.141a.6.6,0,0,1-.459.213A.608.608,0,0,1-1352.283-4031.613Zm-9.3-11.906a4.237,4.237,0,0,1,4.232-4.232,4.238,4.238,0,0,1,4.234,4.232,4.239,4.239,0,0,1-4.234,4.234A4.237,4.237,0,0,1-1361.582-4043.519Zm1.541,0a2.694,2.694,0,0,0,2.691,2.691,2.693,2.693,0,0,0,2.69-2.691,2.693,2.693,0,0,0-2.69-2.69A2.693,2.693,0,0,0-1360.041-4043.519Z" transform="translate(1364.75 4047.751)" fill="#637381"/>
         </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="17.465" height="18.85" viewBox="0 0 17.465 18.85">
            <path id="Path_7569" data-name="Path 7569" d="M-1355.749-4033.135a4.237,4.237,0,0,1,4.233-4.232,4.237,4.237,0,0,1,4.231,4.232,4.238,4.238,0,0,1-4.231,4.234A4.238,4.238,0,0,1-1355.749-4033.135Zm1.541,0a2.694,2.694,0,0,0,2.692,2.69,2.693,2.693,0,0,0,2.69-2.69,2.693,2.693,0,0,0-2.69-2.689A2.693,2.693,0,0,0-1354.208-4033.135Zm-10.542,3.8v-2.01a7.731,7.731,0,0,1,7.722-7.721A7.71,7.71,0,0,1-1353.1-4038l.862.506-.963.268a4.667,4.667,0,0,0-3.4,4.48,4.612,4.612,0,0,0,.867,2.7l.7.7Zm1.542-2.01v.469h5.345a6.179,6.179,0,0,1-.282-1.865,6.225,6.225,0,0,1,2.156-4.7,6.376,6.376,0,0,0-1.039-.086A6.186,6.186,0,0,0-1363.208-4031.348Zm10.924-.265-1.021-.826a.6.6,0,0,1-.09-.843.6.6,0,0,1,.844-.091l.565.456,1.429-1.693a.6.6,0,0,1,.845-.072.6.6,0,0,1,.071.846l-1.807,2.141a.6.6,0,0,1-.459.213A.608.608,0,0,1-1352.283-4031.613Zm-9.3-11.906a4.237,4.237,0,0,1,4.232-4.232,4.238,4.238,0,0,1,4.234,4.232,4.239,4.239,0,0,1-4.234,4.234A4.237,4.237,0,0,1-1361.582-4043.519Zm1.541,0a2.694,2.694,0,0,0,2.691,2.691,2.693,2.693,0,0,0,2.69-2.691,2.693,2.693,0,0,0-2.69-2.69A2.693,2.693,0,0,0-1360.041-4043.519Z" transform="translate(1364.75 4047.751)" fill="#c0c7cc"/>
          </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default AccountActivateIcon;
