import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import SubscriptionDealerService from '../services/SubscriptionDealerService'

const initialState = []

export const getDealerSubscriptions = createAsyncThunk(
  'subscription/dealer',
  async (companyId) => {
    const res = await SubscriptionDealerService.getDealerSubscriptions(
      companyId,
    )
    return res.data
  },
)

export const getDealerSubscriptionListById = createAsyncThunk(
  'dealer/subscription/list',
  async (companyId) => {
    const res = await SubscriptionDealerService.getDealerSubscriptionListById(
      companyId,
    )
    return res.data
  },
)

export const filterDealerSubscriptions = createAsyncThunk(
  'subscription/dealer',
  async (payload) => {
    const res = await SubscriptionDealerService.filterDealerSubscriptions(
      payload,
    )
    return res.data
  },
)

export const saveSubscriptionsForDealer = createAsyncThunk(
  'subscription/dealer',
  async (payload) => {
    const res = await SubscriptionDealerService.saveSubscriptionsForDealer(
      payload,
    )
    return res.data
  },
)

export const updateSubscriptionsForDealer = createAsyncThunk(
  'subscription/dealer',
  async (payload) => {
    const res = await SubscriptionDealerService.updateSubscriptionsForDealer(
      payload,
    )
    return res.data
  },
)

export const updateSubscriptionForCustomer = createAsyncThunk(
  'subscription/dealer',
  async (payload) => {
    const res = await SubscriptionDealerService.updateSubscriptionForCustomer(
      payload,
    )
    return res.data
  },
)

export const upgradeSubscriptionForDealer = createAsyncThunk(
  'subscription/dealer',
  async (payload) => {
    const res = await SubscriptionDealerService.upgradeSubscriptionForDealer(
      payload,
    )
    return res.data
  },
)

const SubscriptionDealerSlice = createSlice({
  name: 'subscriptionDealer',
  initialState,
  extraReducers: {},
})

const { reducer } = SubscriptionDealerSlice
export default reducer
