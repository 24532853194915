import { Box } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../App.css";
import HeaderToolbar from "../../components/AccessLevelHeaderToolbar";
import DataTable from "../../components/DataTable/DataTable";
import Search from "../../components/SearchTab/Search";
import Toast from "../../components/ToastContainer/CustomToast";

import {
  getVisitorAccessLevelsByPurposeId
} from "../../slices/VisitorAccessLevels/VisitorAccessLevelSlice";
//AddAccessLevel
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import AddAccessLevelIcon from "../../../src/assets/access_level.svg";
import CommonUtil from "../../Util/CommonUtils";
import { REACT_VISITOR_ACCESS_LEVEL_LIST } from "../../actions/EndPoints";
import DeleteDialog from "../../components/DialogBoxComponent/DeleteDialog";
import {
  deleteAccessLevel
} from "../../slices/PurposeTypeUSA/PurposeTypeAccesslevelUSASlice";
import { ThemeContext } from '../../theme/ThemeContext';
import PurposeTypeAccessLevelDelete from "./PurposeTypeAccessLevelDelete";
import VisitorAddAccessLevelDialog from "./VisitorAddAccessLevelDialog";


export default function VisitorAccessLevelsPage(props) {
  const [rows, setRows] = useState([]);
  const { darkMode } = useContext(ThemeContext);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [idToDelet, setIdToDelet] = useState();
  const [openAccessLevelDelete, setOpenAccessLevelDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [accessIds, setAccessIds] = useState(
    !CommonUtil.isEmpty(location.state.data)
      ? location.state.data.accessLevelIds
      : []
  );
  const [purposeId, setPurposeId] = useState(
    !CommonUtil.isEmpty(location.state.data) ? location.state.data.id : []
  );
  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });
  const [filter, setFilter] = useState({
    name: "",
    id: "",
  });
  useEffect(() => {
    loadAccessLevels();
  }, [filter, paging, purposeId]);

  const loadAccessLevels = () => {
    const payload = {
      page: paging.page,
      rowsPerPage: paging.rowsPerPage,
      id: purposeId,
    };

    if (!CommonUtil.isEmptyArray(payload.id)) {
      dispatch(getVisitorAccessLevelsByPurposeId(Object.assign(payload)))
        .unwrap()
        .then((data) => {
          if ((data.code = "CVAI0000" && data.data)) {
            if (location.state.data.id) {
              setTotalRecords(data.data.totalCount);
              setPage(data.data.currentPage);
              setRows(data.data.accessGroups);
              setTotalPages(data.data.totalPages);
            } else {
              toast.error("no data");
            }
          } else {
            setRows([]);
          }
        })
        .catch((er) => { });
    } else {
      setRows([]);
    }
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };


  const handleDelete = (data) => {
    let delPayLoad = {
      id: location.state.data.id,
      ids: [data[0].id],
    }
    dispatch(deleteAccessLevel(delPayLoad))
      .unwrap()
      .then((data) => {
        if ((data.code === "CVAI0047")) {
          // loadData(filter, paging);
          Toast(t('ACCESS_LEVELS_DELETE'), 'success')
          loadAccessLevels(paging);
        } else {
          Toast(data.msg, "error");
        }
      })
      .catch((er) => { });
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return test.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const handleOpen = () => {
    history.push({
      pathname: REACT_VISITOR_ACCESS_LEVEL_LIST,
      state: { state: location.state.data, accessIds: rows },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      flex: 0.2,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "name",
      headerName: t('VISACCLEVELS002'),
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.name,
    },

    {
      field: "Actions",
      headerName: t('CUSTDATABLE16'),
      type: "actions",
      flex: 1,
      minWidth: 1,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        // <GridActionsCellItem
        //   icon={<DeleteIcon />}
        //   label="Delete"
        //   onClick={() => handleDelete([params.row])}
        // />,
        <GridActionsCellItem
          icon={
            <DeleteDialog
              handleDelete={() => handleDelete([params.row])}
              deviceName={params.row.name}
              name={"Access Level"}
              text={"Are you sure you want to delete this "}
            />
          }
          label="Delete"
        />,
      ],
    },
  ]);

  return (
    <>
      <HeaderToolbar
        src={AddAccessLevelIcon}
        title={t('VISACCLEVEL001')}
        tooltipTitle={t('ADD_ACCESS')}
        onClick={handleOpen}
        back_button={true}
      />
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        sx={{ marginLeft: darkMode ? '-8px' : '' }}
      >
        {" "}
        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadAccessLevels(page, rowsPerPage)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {openAccessLevelDelete && (
        <PurposeTypeAccessLevelDelete
          open={openAccessLevelDelete}
          handleClose={(data) => {
            setOpenAccessLevelDelete(data);
            handleClose(loadAccessLevels(page, rowsPerPage));
          }}
          DeleteId={idToDelet}
        />
      )}
      ,
      {open && (
        <VisitorAddAccessLevelDialog
          open={open}
          handleClose={(data) => {
            handleClose(loadAccessLevels(page, rowsPerPage));
          }}
          id={location.state.data}
        />
      )}
    </>
  );
}
