import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
    let query = '?pageNumber=' + filter.pageNumber + '&pageSize=' + filter.pageSize;
  
    return query;
}

const createUrgentMessage = (payload) => {
    return api.securedAxios().post('/web/urgentMessage/create', payload);
}

const listUrgentMessages = (filter) => {
    return api.securedAxios().get('/web/urgentMessage/list' + buildQuery(filter));
}

export const UrgentMessagesService = {
    createUrgentMessage,
    listUrgentMessages
}