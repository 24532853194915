import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService1 from '../../services/User/UserService1'

let initialState = {
    profileUpdated: false
};

export const getUserInfoPro = createAsyncThunk('profile/get', async (userId) => {
    const res = await UserService1.getUserInfoPro(userId);
    return res.data;
})

export const getUserByClientCredentials = createAsyncThunk('profile/get', async (payload) => {
    const res = await UserService1.getUserByClientCredentials(payload);
    return res.data;
})

export const updateUserInfoPro = createAsyncThunk('profile/update', async (user) => {
    const res = await UserService1.updateUserInfoPro(user);
    return res.data;
})

const UserSlice = createSlice({
    name: 'profile',
    initialState,
    extraReducers: {
        [getUserInfoPro.fulfilled]: (state, action) => {
            if (action.payload.code === 'UASI0002') {
                state.profileUpdated = state.profileUpdated ? false : true;
            }
        },
    },
})

const { reducer } = UserSlice;
export default reducer;

