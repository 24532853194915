import { Autocomplete, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as DoorIcon } from "../../assets/DeviceIcon/DoorPic.svg";
import DialogBox from '../../components/DialogBoxComponent/DialogBox';
import Toast from '../../components/ToastContainer/CustomToast';
import { updateDoor } from '../../slices/VisitorAccessLevels/VisitorAccessLevelSlice';

export const SetCheckInCheckOutDoor = (props) => {
  const [open, setOpen] = useState(props.open);
  const [payload, setPayload] = useState({ parameters: { doorStatus: '' } });
  const [selectedValue, setSelectedValue] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation()

  useEffect(() => {
    if (props.updateData.deviceProperty) {
      setSelectedValue(doorProperty.find(prop => prop.value === props.updateData.deviceProperty));
    }
  }, [props.updateData.deviceProperty]);

  const handleClose = () => {
    props.onClose(!open);
    setOpen(!open);
  };

  const handleChange = (event, newValue) => {
    if (newValue) {
      setPayload({ parameters: { doorStatus: newValue.value } });
      setSelectedValue(newValue);
    } else {
      setPayload({ parameters: { doorStatus: null } });
      setSelectedValue(null);
    }
  };

  const handleUpdate = () => {
    if (payload.parameters.doorStatus === "") {
      Toast(t('ENTER_DOOR_PROPERTY'),'error');
      return;
    }

    const state = {
      payload: payload,
      doorId: props.updateData.doorId,
    };

    dispatch(updateDoor(state))
      .unwrap()
      .then((data) => {
        if (data.code === "DMSI0000") {
          Toast(t('UPDATE_SUCCESSFULLY'),'success')
          props.reloadlist(true);
          handleClose();
        } else {
          Toast(t('THERE_SOME_ERROR'),'error')
        }
      });
  };

  const doorProperty = [
    { id: "1", value: "checkIn", name: "Check-In" },
    { id: "2", value: "checkOut", name: "Check-Out" },
  ];

  return (
    <div style={{ width: '500px !important' }}>
      <DialogBox
        Header="Set Check-In/Check-Out door"
        acceptText={t('COMMON007')}
        cancelText={t('COMMON008')}
        fullWidth={true}
        onClose={handleClose}
        cancel={handleClose}
        onSubmit={handleUpdate}
        open={open}
      >
        <Grid container>
          <Grid item lg={11} style={{ marginLeft: "22px", marginTop: "10px" }}>
            <List
              sx={{
                bgcolor: "#EFF5F3",
                paddingLeft: "30px",
                borderRadius: "8px",
              }}
            >
              <ListItem>
                <ListItemAvatar sx={{ marginRight: "1rem" }}>
                  <DoorIcon height="75px" width="33px" />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    props.updateData.name
                      ? props.updateData.name
                      : t('DOOR_NAME')
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item lg={11}>
            <Typography padding={"20px 30px"} fontSize="14px">
              {t('DEVICEINFO20')}
            </Typography>
          </Grid>
          <Grid item lg={11}>
            <Typography
              style={{
                fontSize: "14px",
                padding: "0px 30px",
                color: "#2281ab",
              }}
            >
              {t('DEVICEINFO21')}
            </Typography>
          </Grid>
          <Grid item lg={11} style={{ margin: "15px 10px" }}>
            <Autocomplete
              fullWidth
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              id="door property"
              value={selectedValue}
              getOptionLabel={(option) => option.name}
              options={doorProperty}
              sx={{ margin: "14px" }}
              renderInput={(params) => (
                <TextField {...params} label={t('Door_properties')} />
              )}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </div>
  );
}