import { Grid } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { timeSlotList } from "../../slices/TimeSlot/TimeSlotSlice";
import { createVisitorAccessLevels, updateVisitorAccessLevels } from "../../slices/VisitorAccessLevels/VisitorAccessLevelSlice";
import CommonUtil from "../../Util/CommonUtils";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core'
import { ThemeContext } from '../../theme/ThemeContext';

const useStyles = makeStyles((theme) => ({
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}))

function AddAccessLevelDialog(props) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [timeSlots, setTimeSlots] = useState([]);
  const [schedulerTimeSlotId, setSchedulerTimeSlotId] = useState('');
  const [str, setStr] = useState("");
const { darkMode } = useContext(ThemeContext);
  const [error, setError] = useState({
    name: '',
    schedulerId: ''
  });

  const [payload, setPayload] = useState({
    id: '',
    name: '',
    schedulerId: ''
  });

  useEffect(() => {
    setSchedulerTimeSlotId(timeSlots.find((slot) => {
      return slot.id === props.selectedObject.schedulerId;
    }))
  }, [timeSlots?.length > 0])

  useEffect(() => {
    if (props.selectedObject.id !== undefined) {
      setPayload({
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        schedulerId: schedulerTimeSlotId
      })
    }
  }, [!CommonUtil.isEmptyString(schedulerTimeSlotId)])

  const handleSubmit = () => {
    if (payload.name === '') {
      setError({ ...error, name: true });
      Toast(t('ENTER_ACCESS_LEVEL'), 'error')
    } else if (payload.schedulerId === '') {
      setError({ ...error, schedulerId: true });
      Toast(t('ENTER_TIME_SLOT'), 'error')
    } else {
      props.selectedObject.id === undefined ? handleCreate() : handleUpdate()
    }
  };

  const handleClose = () => {
    props.handleDialogClose()
    props.updateSelectedObject()
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  }

  useEffect(() => {
    loadTimeSlots({ pageNumber: 1, pageSize: 10000 });
  }, [])

  const loadTimeSlots = (paging) => {
    dispatch(timeSlotList(paging)).unwrap().then((data) => {
      if (data.code === 'DMSI0000') {
        setTimeSlots(data.data.timeZone);
      }
    })
  }

  const handleCreate = () => {
    const newPayload = {
      id: payload.id,
      name: payload.name,
      schedulerId: payload.schedulerId.id
    }
    dispatch(createVisitorAccessLevels(newPayload)).unwrap().then((data) => {
      switch (data.code) {
        case 'DMSI0000':
          Toast(t('ACCESS_LEVEL_CREATE'), 'success')
          props.loadData();
          handleClose()
          break;
          case 'DMSE00520':
          Toast(t('ACCESS_LEVEL_ALREADY'), 'error')
          break;
        default:
          Toast(data.msg, 'error')
      }
    })
  }

  const handleUpdate = () => {
    const newPayload = {
      id: payload.id,
      name: payload.name,
      schedulerId: payload.schedulerId.id
    }
    dispatch(updateVisitorAccessLevels(newPayload)).unwrap().then((data) => {
      if (data.code === 'DMSI0000') {
        Toast(t('ACCESS_LEVEL_UPDATE'), 'success')
        props.loadData();
        props.updateSelectedObject();
        handleClose()
      } else {
        Toast(data.msg, 'error')
      }
    })
  }

  return (
    <div>
      <DialogBox
        // Header={props.selectedObject.id === undefined ? t('Add_Visiting_Access_Level') : t('EDIT_ACCESS_LEVEL')}

        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
          {props.selectedObject.id === undefined ? t('Add_Visiting_Access_Level') : t('EDIT_ACCESS_LEVEL')}
          </span>
        }
        acceptText={t('COMMON007')}
        cancelText={t('COMMON008')}
        fullWidth={true}
        onClose={handleClose}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          style={{backgroundColor: darkMode ? '#0F273B' : '#fff'}}
        >
          <Grid item xs={12} sm={9} style={{ marginTop: "20px" }}>
            <CustomTextfield
              label={t("COMMON014")}
              name="name"
              error={error.name}
              required
              handleChange={(e) => handleChange(e)}
              value={payload.name}
              inputProps={{ className: classes.input, maxLength: 30 }}
            />
          </Grid>
          <Grid item xs={12} sm={9} style={{ marginTop: "20px" }}>
            <Autocomplete
              style={{ width: '100%' }}
              noOptionsText={'No Options found'}
              name='schedulerId'
              value={payload.schedulerId}
              options={CommonUtil.isEmptyArray(timeSlots) ? [] : timeSlots}
              getOptionLabel={(option) => option.operationName ? option.operationName : ''}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                  setPayload({ ...payload, schedulerId: newValue });
                  setError({ country: false })
                } else {
                  setPayload({ ...payload, schedulerId: '' });
                  setError({ country: false })
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name='schedulerId' value={payload.schedulerId} error={error.schedulerId} variant='outlined' fullWidth label={t('TIME_SLOT')} 
                InputLabelProps={{
                  style: {
                    color: darkMode ? '#fff' : '#0F273B',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                    },
                    '&:hover fieldset': {
                      borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                    },
                  },
                }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </div>
  );
}


export default AddAccessLevelDialog