import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter, page, rowsPerPage) => {
  let query = "?isPageable=true&page=" + page + "&size=" + rowsPerPage;
  if (!CommonUtil.isEmptyString(filter.firstName)) {

    const fullName  =filter.firstName.split(" ");
    if(!CommonUtil.isEmpty(fullName[1])){
      query = query + "&firstName=" + fullName[0]+"&lastName="+ fullName[1];
    }else{
      query = query + "&firstName=" + filter.firstName;
    }
  }
  if (!CommonUtil.isEmptyString(filter.lastName)) {
    query = query + "&lastName=" + filter.lastName;
  }
  if (!CommonUtil.isEmptyString(filter.emailId)) {
    query = query + "&emailId=" + filter.emailId;
  }
  if (!CommonUtil.isEmptyString(filter.phoneNumber)) {
    query = query + "&phoneNumber=" + filter.phoneNumber;
  }
  if (!CommonUtil.isEmpty(filter.status)) {
    query = query + "&status=" + filter.status;
  }
  if (!CommonUtil.isEmpty(filter.isRestricted)) {
    query = query + "&isRestricted=" + filter.isRestricted;
  }
  if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
    query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
  }
  if (!CommonUtil.isEmptyString(filter.toDate)) {
    query = query + "&toDate=" + filter.toDate;
  }
  if (!CommonUtil.isEmptyString(filter.fromDate)) {
    query = query + "&fromDate=" + filter.fromDate;
  }
  if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
    query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
  }
  return query;
};

const getAll = (payload) => {
  return api
    .securedAxios()
    .get(
      "/api/v2.0/restrict_visitor/filter" +
        buildQuery(payload.filter, payload.page, payload.rowsPerPage)
    );
};

const get = (payload) => {
  return api
    .securedAxios()
    .get(
      "/api/v2.0/restrict_type/search" +
        "?isPageable=" +
        payload.isPageable +
        "&page=" +
        payload.pageNumber +
        "&size=" +
        payload.pageSize +
        "&name=" +
        payload.name +
        "&code=" +
        payload.code
    );
};

const create = (payload) => {
  return api
    .securedAxios()
    .post("/api/v2.0/restrict_visitor/" + payload.id, payload);
};

const approve = (payload) => {
  return api
    .securedAxios()
    .put("/api/v2.0/approve/"+ payload.id);
};

const approveGet = (payload) => {
  return api.securedAxios.get('/api/v2.0/restrict_visitor/' + payload.id);
}

const unblock = (payload) => {
  return api
    .securedAxios()
    .put("/api/v2.0/unrestrict_visitor/" + payload.id, payload);
};
const BlockVisitorService = {
  getAll,
  get,
  create,
  approve,
  approveGet,
  unblock,
};

export default BlockVisitorService;
