import api from '../config/http-common';

const getAll = (query) => {
    return api.securedAxios().get('/api/v2.0/visit_type?isPageable=' + query.isPageable + '&pageNumber=' + query.pageNumber
        + '&pageSize=' + query.pageSize + '&id=' + query.id + '&code=' + query.code + '&name=' + query.name);

};

const addPurposeType = (payload) => {
    return api.securedAxios().post('/api/v2.0/visit_type', payload);
};

const updateVisitorType = (payload) => {
    return api.securedAxios().put('/api/v2.0/visit_type/' + payload.id, payload);
};

const VisitTypeService = {
    getAll,
    addPurposeType,
    updateVisitorType
};

export default VisitTypeService;
