import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const RejectIcon = () => {
  const { t } = useTranslation();
  const [isShown, setIsShown] = React.useState(false);
  return (
    <>
      <Tooltip title={t("APPROVE003")}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.598"
              height="26"
              viewBox="0 0 23.598 26"
            >
              <g
                id="Group_7142"
                data-name="Group 7142"
                transform="translate(18204 -7383.463)"
              >
                <circle
                  id="Ellipse_2984"
                  data-name="Ellipse 2984"
                  cx="5.5"
                  cy="5.5"
                  r="5.5"
                  transform="translate(-18199.402 7383.463)"
                  fill="#e3393c"
                />
                <path
                  id="Subtraction_1"
                  data-name="Subtraction 1"
                  d="M11.958,12.853H0v-2.3A10.56,10.56,0,0,1,15.923,1.463a7.365,7.365,0,0,0-5.365,7.075,7.29,7.29,0,0,0,1.4,4.313Z"
                  transform="translate(-18204 7396.004)"
                  fill="#e3393c"
                />
                <circle
                  id="Ellipse_2986"
                  data-name="Ellipse 2986"
                  cx="5.5"
                  cy="5.5"
                  r="5.5"
                  transform="translate(-18191.402 7398.463)"
                  fill="#e3393c"
                />
                <rect
                  id="Rectangle_6425"
                  data-name="Rectangle 6425"
                  width="1.149"
                  height="6.318"
                  rx="0.574"
                  transform="translate(-18188.248 7402.527) rotate(-45)"
                  fill="#fff"
                />
                <rect
                  id="Rectangle_6426"
                  data-name="Rectangle 6426"
                  width="1.149"
                  height="6.318"
                  rx="0.574"
                  transform="translate(-18183.789 7401.632) rotate(45)"
                  fill="#fff"
                />
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.598"
              height="26"
              viewBox="0 0 23.598 26"
            >
              <g
                id="Group_7142"
                data-name="Group 7142"
                transform="translate(18204 -7383.463)"
              >
                <circle
                  id="Ellipse_2984"
                  data-name="Ellipse 2984"
                  cx="5.5"
                  cy="5.5"
                  r="5.5"
                  transform="translate(-18199.402 7383.463)"
                  fill="rgba(99,115,129,0.4)"
                />
                <path
                  id="Subtraction_1"
                  data-name="Subtraction 1"
                  d="M11.958,12.853H0v-2.3A10.56,10.56,0,0,1,15.923,1.463a7.365,7.365,0,0,0-5.365,7.075,7.29,7.29,0,0,0,1.4,4.313Z"
                  transform="translate(-18204 7396.004)"
                  fill="rgba(99,115,129,0.4)"
                />
                <circle
                  id="Ellipse_2986"
                  data-name="Ellipse 2986"
                  cx="5.5"
                  cy="5.5"
                  r="5.5"
                  transform="translate(-18191.402 7398.463)"
                  fill="rgba(99,115,129,0.4)"
                />
                <rect
                  id="Rectangle_6425"
                  data-name="Rectangle 6425"
                  width="1.149"
                  height="6.318"
                  rx="0.574"
                  transform="translate(-18188.248 7402.527) rotate(-45)"
                  fill="#fff"
                />
                <rect
                  id="Rectangle_6426"
                  data-name="Rectangle 6426"
                  width="1.149"
                  height="6.318"
                  rx="0.574"
                  transform="translate(-18183.789 7401.632) rotate(45)"
                  fill="#fff"
                />
              </g>
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default RejectIcon;
