import api from '../../config/http-common';



const getAllZone = (payload) => {
    let url=`/api/v2.0/zlink/zones/filter?pageNumber=${payload.page}&pageSize=${payload.rowsPerPage}`;
     if(payload.name!==''){
        let name = encodeURIComponent(payload.name);
        url+='&name='+name;
     }
    return api.securedAxios().get(url);
};


const getZoneList = (payload) => {
    return api.securedAxios().post(`/web/zones/list` +  buildQuery(payload.filter, payload.page, payload.rowsPerPage));
};

const buildQuery = (filter, page, rowsPerPage) => {
    let query =
      "?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=false&operator=AND&pageNumber=" +
      page +
      "&pageSize=" +
      rowsPerPage;
      return query;
}

const getZoneById = (payload) => {
    return api.securedAxios().get('/web/zones/' + payload);
};

const createZone = (payload) => {
    return api.securedAxios().post("/web/zone", payload);
}

const updateZone = (payload) => {
    return api.securedAxios().put("/web/zones/" + payload.zoneId, payload);
}

const deleteZone = (id) => {
    return api.securedAxios().delete("/web/zones/" + id);
}


const ZoneService = {
    getAllZone, 
    getZoneById, 
    createZone, 
    getZoneList,
    updateZone,
    deleteZone,  
};

export default ZoneService;