import api from "../../config/http-common";


const getVisitordetailsByCardNumber = (payload) => {
  return api.securedAxios().get('/web/visit/card_number/'+ payload.pinNumber);
  };
 
const updateStatus = (payload) => {
    return api.securedAxios().put('/web/visit/updateStatus/'+payload.pinNumber+"?status=" +payload.status);
}
 

const PinNumberService = {
    getVisitordetailsByCardNumber,
    updateStatus
  };
  
  export default PinNumberService;