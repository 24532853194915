import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const DoorIcon = () => {
    const [isShown, setIsShown] = React.useState(false);
    const { t } = useTranslation();
    return (
        <>
            <Tooltip title={t('Manage_access_Level')}>
                <Grid
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                >
                    {isShown ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.064" height="21" viewBox="0 0 16.064 21">
                        <g id="Door" transform="translate(-497.465 -193.748)">
                          <path id="Union_237" data-name="Union 237" d="M.454,21v-.051a.68.68,0,0,1-.172-.1l-.009-.005a.072.072,0,0,1-.016-.014c-.035-.031-.07-.071-.112-.116a.366.366,0,0,1-.034-.046.7.7,0,0,1-.066-.147.171.171,0,0,1-.025-.075A.179.179,0,0,1,.01,20.4L0,20.374v-.016s0-.016,0-.02V.707A.361.361,0,0,1,0,.656v0L.01.6A.052.052,0,0,1,.015.576V.555l.029-.1A.014.014,0,0,0,.051.444c0-.005,0-.011.009-.021L.07.4.1.358A.243.243,0,0,1,.137.293.426.426,0,0,1,.161.258l0,0L.186.232C.2.223.206.212.216.2A.2.2,0,0,1,.257.167L.262.161l.01-.01L.306.131l.008,0A.3.3,0,0,1,.383.081.474.474,0,0,1,.459.045.161.161,0,0,1,.52.031.388.388,0,0,1,.58.015.365.365,0,0,1,.646,0a.289.289,0,0,1,.06,0H15.358a.707.707,0,0,1,.706.707V20.293a.707.707,0,0,1-.706.707ZM11.314,2.727a.716.716,0,0,1,.536.686V17.354a.714.714,0,0,1-.521.682l-5.5,1.545h8.827V1.418H6.264ZM8.552,9.569V11.2a.458.458,0,1,0,.917,0V9.569a.458.458,0,0,0-.917,0Z" transform="translate(497.465 193.748)" fill="#3d4977"/>
                        </g>
                      </svg>
                    ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.064" height="21" viewBox="0 0 16.064 21" opacity="1">
                                <g id="Door" transform="translate(-497.465 -193.748)">
                                    <path id="Union_237" data-name="Union 237" d="M.454,21v-.051a.68.68,0,0,1-.172-.1l-.009-.005a.072.072,0,0,1-.016-.014c-.035-.031-.07-.071-.112-.116a.366.366,0,0,1-.034-.046.7.7,0,0,1-.066-.147.171.171,0,0,1-.025-.075A.179.179,0,0,1,.01,20.4L0,20.374v-.016s0-.016,0-.02V.707A.361.361,0,0,1,0,.656v0L.01.6A.052.052,0,0,1,.015.576V.555l.029-.1A.014.014,0,0,0,.051.444c0-.005,0-.011.009-.021L.07.4.1.358A.243.243,0,0,1,.137.293.426.426,0,0,1,.161.258l0,0L.186.232C.2.223.206.212.216.2A.2.2,0,0,1,.257.167L.262.161l.01-.01L.306.131l.008,0A.3.3,0,0,1,.383.081.474.474,0,0,1,.459.045.161.161,0,0,1,.52.031.388.388,0,0,1,.58.015.365.365,0,0,1,.646,0a.289.289,0,0,1,.06,0H15.358a.707.707,0,0,1,.706.707V20.293a.707.707,0,0,1-.706.707ZM11.314,2.727a.716.716,0,0,1,.536.686V17.354a.714.714,0,0,1-.521.682l-5.5,1.545h8.827V1.418H6.264ZM8.552,9.569V11.2a.458.458,0,1,0,.917,0V9.569a.458.458,0,0,0-.917,0Z" transform="translate(497.465 193.748)" fill="#c0c7cc" />
                                </g>
                            </svg>
                    )}
                </Grid>
            </Tooltip>
        </>
    );
};
export default DoorIcon;
