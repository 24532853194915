import { Autocomplete, Avatar, Box, Divider, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import EditIcon from '../Person/EditIcon';
import SampleDevice from '../../assets/DeviceIcon/SampleDevice.svg';
import TitleBar from '../../components/v4/TitleBar';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { ReactComponent as DeviceEdit } from '../../assets/DeviceIcon/DeviceEditOrange.svg';
import { toast } from 'react-toastify';
import { updateDevice, getDevice } from '../../slices/ZlinkDeviceManagement/DeviceSlice';
import { timeZoneList } from '../../components/v4/TimeZoneList/timeZoneData';
import Toast from '../../components/ToastContainer/CustomToast';
import { getAllSites } from '../../slices/Site/SiteSlice';
import CommonUtil from '../../Util/CommonUtils';
import { getZoneList, getZoneById } from '../../slices/Zone/ZoneSlice';
import { getSiteById } from "../../slices/Site/SiteSlice";
import { timeZoneData } from '../Person/AddressFormData';
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons';
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons';
import { REACT_URL_DEVICELIST } from '../../actions/EndPoints';

export const ViewDevice = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({});
  const [sites, setSites] = React.useState([]);
  const [deviceSite, setDeviceSite] = useState({})
  const [zones, setZones] = React.useState([]);
  const [zoneId, setZoneId] = React.useState([]);
  const [zoneName, setZoneName] = React.useState([]);
  const [sitee, setSitee] = useState([]);
  const [zonee, setZonee] = useState([]);
  const [siteIdd, setSiteIdd] = useState([]);
  const [zoneIdd, setZoneIdd] = useState([]);
  const [timeZones, setTimeZones] = React.useState([]);
  const [deviceAlias, setDevicealias] = useState({ deviceAlias: '' });
  const [deviceAliass, setDevicealiass] = useState([]);
  const [error, setError] = useState({ deviceAlias: '' });
  const [selectedTimeZone, setSelectedTimeZone] = React.useState('');
  const [siteTimeZone, setSiteTimeZone] = useState("");

  const [payload, setPayload] = useState({
    deviceAlias: "",
    zoneId: "",
    name: "",
    siteId: "",
    sitee: "",
    zonee: "",
    siteName: "",
    zoneName: "",
  });

  useEffect(() => {
    if (location.state !== null) {
      setState({ ...state, ...location.state });
      setDevicealias({ deviceAlias: location.state.deviceName });
    }
    setTimeZones(timeZoneData);
  }, []);

  const payloadSite = {
    page: 1,
    rowsPerPage: 10000,
    filter: {
      code: '',
      name: ''
    }
  };



  const handleDeviceName = (e) => {
    const name = e.target.value;
    const value = e.target.value;
    setDevicealiass(name);
    setError({ deviceAlias: '' });
  };

  useEffect(() => {
    dispatch(getDevice(location.state.id)).unwrap()
      .then((data) => {
        if (data.code === 'DMSI0000')
          setSiteIdd(data.data.siteId);
        setZoneIdd(data.data.zoneId);
        setDevicealiass(data.data.deviceAlias);
      });
  }, []);

  useEffect(() => {
    dispatch(getSiteById(siteIdd)).unwrap()
      .then((data) => {
        if (data.code === 'LMSI6000')
          setSitee(data.data.name);
      });
  }, [siteIdd]);

  useEffect(() => {
    dispatch(getAllSites(payloadSite)).unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000')
          setSites(data.data.site);
        let filteredSite = (data.data.site).filter((site) => {
          return site.id === siteIdd
        })
        setDeviceSite(filteredSite)
        let timeZoneData = timeZones.find((zone) => {
          return zone.id === filteredSite[0].address.timeZone;
        })
        setSiteTimeZone(timeZoneData.name)
        console.log(timeZoneData)
      });
  }, [siteIdd]);

  useEffect(() => {

  })

  useEffect(() => {
    dispatch(getZoneById(zoneIdd)).unwrap()
      .then((data) => {
        if (data.code === 'LMSI6000')
          setZonee(data.data.name);
      });
  }, [zoneIdd]);

  const handleUpdate = () => {
    if (deviceAlias.deviceAlias === '') {
      setError({
        ...error,
        deviceAlias: "This field is required",
      });
      toast('Please enter device name', {
        variant: 'error',
      });
      return;
    }

    const newPayload = {
      ...deviceAlias,
      ...payload,
      id: state.id,
      // deviceAlias:deviceAlias.deviceAlias,
      deviceAlias: deviceAliass,
      siteId: payload.siteId,
      sitee: payload.sitee,
      zonee: payload.zonee,
      siteName: payload.siteName,
      zoneId: payload.zoneId,
      zoneName: payload.zoneName,
    };


    dispatch(updateDevice(newPayload)).unwrap().then((data) => {
      if (data.code === 'DMSI0000') {
        Toast("Updated successfully", "success");
        setDevicealiass(data.data.deviceAlias);
        setSiteIdd(data.data.siteId);
        setZoneIdd(data.data.zoneId);
        setState({
          ...state,
          deviceName: deviceAlias.deviceAlias,
          siteName: payload.siteName,
          zoneName: payload.zoneName,
        });
        setOpen(false);
      } else {
        Toast(" There is some issue ", "error");
      }
    });
  };
  const getTimezoneName = (value) => {
    var name = '';
    if (value.toString().includes('%')) {
      timeZoneList.map((t) => {
        if (value === t.value) {
          name = t.name;
        }
      })
      return name;
    } else {
      let encoded = encodeURIComponent(value);
      timeZoneList.map((t) => {
        if (encoded === t.value) {
          name = t.name;
        }
      })
      return name;
    }
  }

const onCancel = ()=>{
  history.push({ pathname: REACT_URL_DEVICELIST});
}


  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ m: 2 }}>
          <TitleBar title={'View device details'} back_button={true} />
        </Box>
        <Grid>
          <IconButton onClick={() => setOpen(!open)}> <EditIcon /></IconButton>
        </Grid>
      </Grid>

      <Paper elevation={0} style={{ minHeight: '200px', backgroundColor: '#ffffff', borderRadius: '8px', paddingTop: '16px', paddingBottom: '16px' }}>
        <Grid container direction='row' justifyContent='space-evenly' alignItems='flex-start'>
          <Grid item lg={2}>
            <IconButton
              variant='contained'
              component='label'
              style={{ font: 'normal normal normal 18px/24px Roboto', letterSpacing: '0px', backgroundColor: '#EFF3F8', width: '100%', height: '100%', flexDirection: 'column' }}
              disabled={true}
            >
              <Avatar alt='Device'
                src={SampleDevice}
                style={{ height: '100%', width: '100%' }} sx={[() => ({ '& .MuiAvatar-img': { width: '50%' } })]} />
            </IconButton>
          </Grid>
          <Grid item lg={4}>
            <Box display='flex' alignItems='center' height='52px' paddingBottom={1}>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>Device alias name</Typography>
              </Box>
              <Box item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                {open === true ? (
                  <>
                    <TextField
                      label="Device name"
                      style={{ width: '80%' }}
                      sx={{ '& .MuiOutlinedInput-input': { paddingTop: '0px !important', paddingBottom: '0px !important', height: '40px' } }}
                      // value={deviceAlias.deviceAlias}
                      value={deviceAliass}
                      name='deviceAlias'
                      onChange={handleDeviceName}
                      error={error.deviceAlias}
                      variant="outlined" />
                    {/* <DeviceEdit style={{ width: '40px', height: '40px', marginLeft: '8px' }}
                      onClick={handleUpdate}
                    /> */}
                  </>
                ) : (
                  <>
                    <Typography variant='fieldValue'>
                      {/* {state.deviceName} */}
                      {deviceAliass}
                    </Typography>
                    {/* <IconButton onClick={() => setOpen(!open)}> </IconButton> */}
                    {' '}
                  </>
                )}
              </Box>
            </Box>

            <Box display='flex' alignItems='center' height={open === true ? '100px' : '52px'} paddingBottom={1}>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>Site name</Typography>
              </Box>
              <Box item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                {open === true ? (
                  <>
                    <Autocomplete
                      style={{ width: '80%' }}
                      noOptionsText={'No Options found'}
                      name='siteName'
                      value={payload.siteName.name}
                      options={CommonUtil.isEmptyArray(sites) ? [] : sites}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      onChange={(event, newValue) => {

                        console.log('newValue', JSON.stringify(newValue));

                        const payloadZone = {
                          page: 1,
                          siteId: newValue.id,
                          rowsPerPage: 10000,
                          filter: {
                            code: '',
                            name: ''
                          }
                        };
                        dispatch(getZoneList(payloadZone)).unwrap()
                          .then((data) => {
                            if (data.code === 'LMSI6000')
                              setZones(data.data.zones);
                          });
                        setSitee({ sitee: newValue.name });
                        if (!CommonUtil.isEmpty(newValue)) {
                          setPayload({ ...payload, sitee: newValue.name, siteId: newValue.id });
                        } else {
                          setPayload({ ...payload, siteName: '', siteId: '' });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params}
                          name='siteName'
                          // value={payload.siteName}
                          value={deviceAliass}
                          error={error.siteName}
                          variant='outlined'
                          fullWidth
                          label="Site name" />
                      )}
                    />
                    {/* <DeviceEdit style={{ width: '40px', height: '40px', marginLeft: '8px' }}
                      onClick={handleUpdate}
                    /> */}

                  </>
                ) : (
                  <>
                    <Typography variant='fieldValue'>
                      {sitee}
                    </Typography>
                    {/* <IconButton onClick={() => setOpen(!open)}> </IconButton> */}
                    {' '}
                  </>
                )}
              </Box>
            </Box>

            <Box display='flex' alignItems='center' height={open === true ? '65px' : '52px'}>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>Zone name</Typography>
              </Box>
              <Box item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                {open === true ? (
                  <>
                    <Autocomplete
                      style={{ width: '80%' }}
                      noOptionsText={'No Options found'}
                      name='zoneName'
                      value={payload.zoneName.name}
                      options={CommonUtil.isEmptyArray(zones) ? [] : zones}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      onChange={(event, newValuee) => {
                        console.log('newValue', JSON.stringify(newValuee));
                        setZonee({ zonee: newValuee.name });
                        if (!CommonUtil.isEmpty(newValuee)) {// eslint-disable-next-line
                          setPayload({ ...payload, zonee: newValuee.name, zoneId: newValuee.id });
                        } else {
                          setPayload({ ...payload, zoneName: '', zoneId: '' });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params}
                          name='zoneName'
                          value={payload.zoneName}
                          error={error.zoneName}
                          variant='outlined'
                          fullWidth
                          label="Zone name" />
                      )}
                    />
                    {/* <DeviceEdit style={{ width: '40px', height: '40px', marginLeft: '8px' }}
                      onClick={handleUpdate}
                    /> */}

                  </>
                ) : (
                  <>
                    <Typography variant='fieldValue'>
                      {/* {state.zoneName} */}
                      {zonee}
                    </Typography>
                    {/* <IconButton onClick={() => setOpen(!open)}> </IconButton> */}
                    {' '}
                  </>
                )}
              </Box>
            </Box>

            <Box display='flex' alignItems='center' height={open === true ? '100px' : '70px'} >
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>Site Time Zone</Typography>
              </Box>
              <Box item>
                <Typography variant='fieldValue'>
                  {siteTimeZone}
                </Typography>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' height='52px'>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>IP Address</Typography>
              </Box>
              <Box item>
                <Typography variant='fieldValue'>
                  {state.ipAddress}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Divider orientation='vertical' flexItem />
          <Grid item lg={5} paddingLeft='32px'>
            <Box display='flex' alignItems='center' height='52px'>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>Firmware Version</Typography>
              </Box>
              <Box item>
                <Typography variant='fieldValue'>
                  {state.fwVersion}
                </Typography>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' height='52px'>
              <Box item paddingRight={1} minWidth='150px'>
                {/* <Typography variant='fieldLabel'>Status</Typography> */}
              </Box>
              <Box item>
                {/* <Typography variant='fieldValue'>
                  <span style={{color:state.status==='0'?'#4caf50':'#f44336'}}><b>{state.status==='0'?'Online':'Offline'}</b></span>
                  </Typography> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {open ? (
      <Grid container lg={8} marginLeft={125}>
              <Grid pr={2} pt={4}>
                <SubmitButtons
                  variant="contained"
                  disable={open}
                  onClick={handleUpdate}
                >
                  <Typography>{'Submit'}</Typography>
                </SubmitButtons>
              </Grid>
              <Grid pt={4}>
                <CancelButtons
                  disable={open}
                  variant="contained"
                  color="secondary"
                  onClick={onCancel}
                >
                  <Typography style={{ textTransform: 'none' }}>
                    {'Cancel'}
                  </Typography>
                </CancelButtons>
              </Grid>
              </Grid>
      ):''
      }
    </>
  );
}
