import api from '../../config/http-common'

const createMobileKeyConfigurations = (payload) => {
  return api.securedAxios().post('/web/api/v4/mobileKeyConfiguration', payload)
}

const buildQuery = (payload) => {
  let query =
    '?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=false&operator=AND&pageNumber=' +
    payload.page +
    '&pageSize=' +
    payload.rowsPerPage
  return query
}

const getMobileConfigurations = (payload) => {
  return api
    .securedAxios()
    .get(
      '/web/api/v4/mobileKeyConfigurations/' +
        localStorage.getItem('companyId') +
        '/list' +
        buildQuery(payload),
    )
}

const deleteMobileConfiguration = (customerId) => {
  return api
    .securedAxios()
    .delete('/web/api/v4/mobileKeyConfigurations/' + customerId)
}

const MobileKeyConfigurationService = {
  createMobileKeyConfigurations,
  getMobileConfigurations,
  deleteMobileConfiguration,
}

export default MobileKeyConfigurationService
