import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DeliveryReportService from '../../services/Reports/DeliveryReportService';
const initialState = [];

export const getAllDeliveryReports = createAsyncThunk('deliveryReport/retrieve', async (payload) => {
    const res = await DeliveryReportService.get(payload);
    return res.data;
});

export const ViewDeliveryReportsDetails = createAsyncThunk('deliveryReport/post', async (payload) => {
    const res = await DeliveryReportService.postDeliveryDetails(payload);
    return res.data;
});

const DeliveryReportSlice = createSlice({
    name: 'DeliveryReport',
    ...initialState,
    extraReducers: {
        [getAllDeliveryReports.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        },
    }

})

const { reducer } = DeliveryReportSlice;
export default reducer;