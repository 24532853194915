import { Tooltip, Typography } from '@material-ui/core'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
  styled,
} from '@mui/material'
import Tab from '@mui/material/Tab'
import axios from 'axios'
import isoCountries from 'i18n-iso-countries'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CommonUtil from '../../Util/CommonUtils'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import Toast from '../../components/ToastContainer/CustomToast'
import TitleBar from '../../components/v4/TitleBar'
import { getCompanyInfo } from '../../slices/Company/CompanySlice'
import { createDealer, updateDealer } from '../../slices/Dealer/DealerSlice'
import { getAllProducts } from '../../slices/Subscription/SubscriptionSlice'
import {
  getDealerSubscriptions,
  saveSubscriptionsForDealer,
  updateSubscriptionsForDealer,
} from '../../slices/SubscriptionDealerSlice'
import { getUserInfoPro } from '../../slices/User/UserSlice1'
import ProfileUplod from '../Employee/UploadImg'
import SelfRegistrationCustomPhone from '../OnBoardV2/SelfRegistrationCustomPhone'
import { countryData } from '../Person/AddressFormData'
import PaymentIcon from './PaymentIcon'
import ProfileIcon from './ProfileIcon'

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#0F5A9C !important',
    opacity: 1,
  },
}))

function CreateDealer(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const [profile, setProfile] = useState('')
  const [profileImage, setProfileImage] = React.useState()
  const [subscriptionList, setSubscriptionList] = useState([])
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([])
  const [country] = useState(props.location.country)
  const [phoneInput, setPhoneInput] = useState('')
  const [companyIdReq, setCompanyIdReq] = useState()
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedBillingCountry, setSelectedBillingCountry] = useState(null)
  const [dealerProfile, setDealerProfile] = useState('dealerProfile')
  const [isEditable, setIsEditable] = useState(false)
  const [dealerSubscriptionIds, setDealerSubscriptionIds] = useState([])
  const [parentCompanyCountry, setParentCompanyCountry] = useState('')
  const [companyCountry] = useState(props.location.country)
  const [checked, setChecked] = React.useState(false)
  const [isBillingInfoExists, setIsBillingInfoExists] = React.useState(true)
  const [billingCountryRequired, setBillingCountryRequired] = useState()
  var today = new Date()
  const [state, setState] = useState({
    accountState: false,
  })
  const [billingPhoneInput, setBillingPhoneInput] = useState('')
  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T' +
    (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())

  const [payload, setPayload] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    phoneCountryCode: '',
    name: '',
    address: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    companyLogo: '',
    companyImageData: '',
    status: '',
    billingUserName: '',
    billingUserCountryCode: '',
    billingUserPhone: '',
    billingUserEmail: '',
    billingAddress: '',
    billingCountry: '',
    billingState: '',
    billingCity: '',
    billingZipcode: '',
    code: '',
  })

  const [error, setError] = useState({
    firstName: false,
    LastName: false,
    email: false,
    phone: false,
    name: false,
    address: false,
    country: false,
    state: false,
    city: false,
    pincode: false,
    companyLogo: false,
    subscription: false,
    billingUserName: false,
    billingUserCountryCode: false,
    billingUserPhone: false,
    billingUserEmail: false,
    billingAddress: false,
    billingCountry: false,
    billingState: false,
    billingCity: false,
    billingZipcode: false,
    code: false,
  })

  useEffect(() => {
    if (props.location.state != null) {
      setIsEditable(true)
      subscription(props.location.state.params.id)
      setPayload({
        ...payload,
        email: props.location.state.params.email,
        phoneCountryCode: props.location.state.params.phone
          ? props.location.state.params.phone.split('-')[0]
          : '',
        phone: props.location.state.params.phone
          ? props.location.state.params.phone.split('-')[1]
          : '',
        name: props.location.state.params.name,
        address: props.location.state.params.address,
        country: props.location.state.params.country,
        state: props.location.state.params.state,
        city: props.location.state.params.city,
        pincode: props.location.state.params.pincode,
        companyId: props.location.state.params.id,
        code: props.location.state.params.code,
        billingUserName: props.location.state.billingInfo.firstName,
        billingUserEmail: props.location.state.billingInfo.email,
        billingUserCountryCode: props.location.state.billingInfo.phone
          ? props.location.state.billingInfo.phone.split('-')[0]
          : '',
        billingUserPhone: props.location.state.billingInfo.phone
          ? props.location.state.billingInfo.phone.split('-')[1]
          : '',
        billingAddress: props.location.state.billingInfo.addressLine1,
        billingCountry: props.location.state.billingInfo.country,
        billingState: props.location.state.billingInfo.stateOrProvince,
        billingCity: props.location.state.billingInfo.city,
        billingZipcode: props.location.state.billingInfo.areaCode,
      })
      setBillingPhoneInput(props.location.state.billingInfo.phone)
      setState({
        ...state,
        accountState:
          props.location.state.params.status === 'ACTIVE' ? true : false,
      })
      setProfile(props.location.state.params.imagePreSignedURL)
      setPhoneInput(props.location.state.params.phone)
      setCompanyIdReq(props.location.state.params.id)
      const selectedCountry = countryData.find(
        (country) => country.name === props.location.state.params.country,
      )
      setSelectedCountry(selectedCountry)
      setBillingCountryRequired(props.location.state.billingInfo.country)
      const selectedBillingCountry = countryData.find(
        (country) => country.name === props.location.state.billingInfo.country,
      )
      setSelectedBillingCountry(selectedBillingCountry)
    }
  }, [!payload.firstName && !payload.lastName])

  useEffect(() => {
    if (
      props.location.state === undefined ||
      props.location.state.params.phone === ''
    ) {
      loadParentCompany()
    }
  }, [props.location.state])

  const loadParentCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setParentCompanyCountry(countryCode)
          }
        }
      })
      .catch((er) => {})
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  useEffect(() => {
    if (props.location.state != null) {
      dispatch(getUserInfoPro(props.location.state.params.email))
        .unwrap()
        .then((res) => {
          if (res.code === 'UASI0033') {
            setPayload({
              ...payload,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
            })
          }
        })
    }
  }, [])

  const subscription = (companyIdReq) => {
    dispatch(getDealerSubscriptions(companyIdReq))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000' && data.data) {
          setDealerSubscriptionIds(data.data.subscriptionIds)
          setSelectedSubscriptions(data.data.subscriptionIds)
        } else setDealerSubscriptionIds([])
      })
  }
  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value)
    setPayload({
      ...payload,
      phoneCountryCode: '+' + data.dialCode,
      phone: value.toString().replace(data.dialCode, ''),
    })
    setError({ phone: false })
  }

  const handleCopyBillingData = () => {
    setChecked(!checked)
    if (!checked) {
      setBillingPhoneInput(phoneInput)
      setPayload({
        ...payload,
        billingUserName: payload.name,
        billingUserEmail: payload.email,
        billingAddress: payload.address,
        billingUserCountryCode: payload.phoneCountryCode,
        billingUserPhone: payload.phone,
        billingCountry: payload.country,
        billingState: payload.state,
        billingCity: payload.city,
        billingZipcode: payload.pincode,
      })
      setSelectedBillingCountry(selectedCountry)
    } else {
      setBillingPhoneInput('')
      setPayload({
        ...payload,
        billingUserName: '',
        billingUserEmail: '',
        billingAddress: '',
        billingUserCountryCode: '',
        billingUserPhone: '',
        billingCountry: '',
        billingState: '',
        billingCity: '',
        billingZipcode: '',
      })
      setSelectedBillingCountry(null)
    }
  }

  const handleBillingPhoneChange = (value, data, event, formattedValue) => {
    setBillingPhoneInput(value)
    setPayload({
      ...payload,
      billingUserCountryCode: '+' + data.dialCode,
      billingUserPhone: value.toString().replace(data.dialCode, ''),
    })
    setError({ phone: false })
  }

  const [InavalidprofilePhoto, setInavalidprofilePhoto] = useState(false)
  const [countries, setCountries] = React.useState([])

  useEffect(() => {
    setCountries(countryData)
    setInavalidprofilePhoto(false)
  }, [])

  useEffect(() => {
    const query = {
      pageNumber: 1,
      pageSize: 1000,
    }
    dispatch(getAllProducts(query))
      .unwrap()
      .then((data) => {
        if (data.code === '200') {
          setSubscriptionList(data.data.results)
        }
      })
  }, [])

  const updateProfilePhoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.data.imagePreSignedURL)
    bodyFormData.append('file', profileImage)
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {})
      .catch(function (response) {})
  }

  const handleProfilePicture = (file) => {
    setPayload({
      ...payload,
      companyLogo: payload.companyName,
      companyImageData: file.base64,
    })
    setProfile(file.base64)
    setProfileImage(file.file)
  }

  const handleCountryChange = (event, value) => {
    setSelectedCountry(value)
    setPayload({
      ...payload,
      country: value.name,
    })
  }

  const handleBillingCountryChange = (event, value) => {
    setSelectedBillingCountry(value)
    setPayload({
      ...payload,
      billingCountry: value.name,
    })
  }

  const onNext = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      Toast(t('USERPROFSCREEN007'), 'error')
      setError({ ...error, firstName: true })
      return false
    }

    if (CommonUtil.isValidName(payload.email)) {
      Toast(t('DEALEREMAILERROR'), 'error')
      setError({ ...error, email: true })
      return
    }

    if (CommonUtil.isEmptyString(payload.name)) {
      Toast(t('DEALERCOMPANYERROR'), 'error')
      setError({ ...error, companyName: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.address)) {
      Toast(t('DEALERADDRESSERROR'), 'error')
      setError({ ...error, address: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.code)) {
      Toast(t('USERPROFSCREEN015'), 'error')
      setError({ ...error, code: true })
      return false
    }

    if (payload.code.length > 36) {
      Toast(t('USERPROFSCREEN016'), 'error')
      setError({ ...error, code: true })
      return false
    }

    const validatePayloadCode = (code) => {
      const pattern = /^[a-zA-Z0-9]([-a-zA-Z0-9_]*[a-zA-Z0-9])?$/
      return pattern.test(code)
    }

    if (!validatePayloadCode(payload.code)) {
      Toast(t('USERPROFSCREEN017'), 'error') // Add appropriate message in translation file
      setError({ ...error, code: true })
      return false
    }

    setSelectedOption('2')
  }

  const onSubmit = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      Toast(t('USERPROFSCREEN007'), 'error')
      setError({ ...error, firstName: true })
      return false
    }

    if (CommonUtil.isValidName(payload.email)) {
      Toast(t('DEALEREMAILERROR'), 'error')
      setError({ ...error, email: true })
      return
    }

    if (CommonUtil.isEmptyString(payload.name)) {
      Toast(t('DEALERCOMPANYERROR'), 'error')
      setError({ ...error, name: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.address)) {
      Toast(t('DEALERADDRESSERROR'), 'error')
      setError({ ...error, address: true })
      return false
    }

    if (CommonUtil.isEmptyArray(selectedSubscriptions)) {
      Toast(t('DEALERSUBSCRIPTIONERROR'), 'error')
      setError({ ...error, subscription: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.billingUserName)) {
      Toast(t('DEALERBILLINGNAME'), 'error')
      setError({ ...error, billingUserName: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.billingUserEmail)) {
      Toast(t('DEALERBILLINGEMAIL'), 'error')
      setError({ ...error, billingUserEmail: true })
      return false
    }

    if (CommonUtil.isValidName(payload.billingUserEmail)) {
      Toast(t('DEALERBILLINGVALIDEMAIL'), 'error')
      setError({ ...error, billingUserEmail: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.billingUserPhone)) {
      Toast(t('DEALERBILLINGPHONENUMBER'), 'error')
      setError({ ...error, billingUserPhone: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.billingAddress)) {
      Toast(t('DEALERBILLINGADDRESS'), 'error')
      setError({ ...error, billingAddress: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.billingCountry)) {
      Toast(t('DEALERBILLINGCOUNTRY'), 'error')
      setError({ ...error, billingCountry: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.billingState)) {
      Toast(t('DEALERBILLINGSTATEERROR'), 'error')
      setError({ ...error, billingState: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.billingCity)) {
      Toast(t('DEALERBILLINGCITYERROR'), 'error')
      setError({ ...error, billingCity: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.billingZipcode)) {
      Toast(t('DEALERBILLINGZIPCODE'), 'error')
      setError({ ...error, billingZipcode: true })
      return false
    }

    if (!InavalidprofilePhoto) {
      // updateDealer()
    } else {
      Toast('Image size should be less than 2MB', 'error')
    }
    const dto = {
      ...payload,
      status: state.accountState ? 'ACTIVE' : 'INACTIVE',
      companyLogo: payload.companyImageData ? payload.name : '',
    }
    if (props.location.state === undefined) {
      dispatch(createDealer(dto))
        .unwrap()
        .then((data) => {
          if (data.code === 'MSGS4006') {
            const savePayload = {
              subscriptionIds: selectedSubscriptions,
              companyId: data.data.id,
            }
            dispatch(saveSubscriptionsForDealer(savePayload))
              .unwrap()
              .then((savedata) => {
                if (savedata.code === 'CVAI0000') {
                  Toast(t('DEALERCREATESUCCESS'), 'success')
                  updateProfilePhoto(data)
                }
              })
          } else {
            switch (data.code) {
              case 'MSGE4007':
                Toast(t('COMPPROFSCREEN027'), 'error')
                break
              default:
                Toast(data.message, 'error')
                break
            }
          }
        })
    } else {
      dispatch(updateDealer(dto))
        .unwrap()
        .then((data) => {
          if (data.code === 'MSGS4019') {
            const savePayload = {
              subscriptionIds: selectedSubscriptions,
              companyId: data.data.id,
            }
            dispatch(updateSubscriptionsForDealer(savePayload))
              .unwrap()
              .then((savedata) => {
                if (savedata.code === 'CVAI0000') {
                  Toast(t('DEALERUPDATESUCCESS'), 'success')
                  updateProfilePhoto(data)
                }
              })
            history.push('/vis/dealer')
          } else {
            switch (data.code) {
              case 'MSGE4007':
                Toast(t('COMPPROFSCREEN027'), 'error')
                break
              default:
                Toast(data.message, 'error')
                break
            }
          }
        })
    }
  }
  const onselectSubscription = (event) => {
    setSelectedSubscriptions(event)
    setError({
      ...error,
      subscription: false,
    })
  }

  const onCancel = () => {
    history.push('/vis/dealer')
  }

  const [selectedOption, setSelectedOption] = React.useState('1')
  const handleChanges = (event, newValue) => {
    setSelectedOption(newValue)
  }

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ m: 2, marginTop: -3 }}>
          <TitleBar
            title={
              isEditable
                ? t('LABELNAVITEMSEDITDEALER')
                : t('LABELNAVITEMSCREATEDEALER')
            }
            back_button={true}
          />
        </Box>
      </Grid>
      <Paper sx={{ marginTop: -2 }}>
        <TabContext value={selectedOption}>
          <Box sx={{ ml: 3 }}>
            <TabList
              onChange={handleChanges}
              aria-label="tab"
              style={{ boxShadow: 'none' }}
              indicatorColor="primary"
            >
              <Tab
                sx={{ ml: 3 }}
                icon={<ProfileIcon />}
                iconPosition="start"
                label={t('USERPROFCARD002')}
                value="1"
                style={{ textTransform: 'capitalize' }}
              />
              <Tab
                sx={{ ml: 8 }}
                icon={<PaymentIcon />}
                iconPosition="start"
                label={t('DEALERTABSUBSCRIPTION')}
                value="2"
                style={{ textTransform: 'capitalize' }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container p={5}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                md={4}
                sm={12}
              >
                <Grid sm={7} sx={{ marginLeft: -12 }}>
                  <ProfileUplod
                    onsetProfile={(profile) => handleProfilePicture(profile)}
                    profilePhoto={profile}
                    uploadPhoto={dealerProfile}
                  />
                </Grid>
              </Grid>
              <Grid container md={8} sm={12} spacing={3}>
                <Grid item xl={6} lg={6} md={6} sm={12} sx={{ marginTop: -7 }}>
                  <CustomTextfeild
                    label={t('DEALERFIRSTNAME')}
                    name={'firstName'}
                    error={error.firstName}
                    value={payload.firstName}
                    required={true}
                    handleChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} sx={{ marginTop: -7 }}>
                  <CustomTextfeild
                    label={t('DEALERLASTNAME')}
                    name={'lastName'}
                    value={payload.lastName}
                    inputProps={{ maxLength: 100 }}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfeild
                    label={t('DEALEREMAIL')}
                    name={'email'}
                    value={payload.email}
                    error={error.email}
                    required={true}
                    validation="email"
                    inputProps={{ maxLength: 100 }}
                    handleChange={handleChange}
                    disabled={isEditable}
                  />
                </Grid>
                <Grid item lg={6} xs={12} style={{ margin: '16px 0px' }}>
                  <SelfRegistrationCustomPhone
                    placeholder={t('CUSTDATABLE03')}
                    value={phoneInput}
                    country={
                      parentCompanyCountry === '' ? 'us' : parentCompanyCountry
                    }
                    specialLabel={false}
                    handleChange={(value, data, event, formattedValue) =>
                      handlePhoneChange(value, data, event, formattedValue)
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfeild
                    label={t('DEALERCOMPANYNAME')}
                    name={'name'}
                    value={payload.name}
                    inputProps={{ maxLength: 100 }}
                    error={error.companyName}
                    required={true}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfeild
                    label={t('DEALERADDRESSLINE')}
                    name={'address'}
                    value={payload.address}
                    inputProps={{ maxLength: 100 }}
                    error={error.address}
                    required={true}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfeild
                    label={t('DEALERCITY')}
                    name={'city'}
                    value={payload.city}
                    inputProps={{ maxLength: 100 }}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfeild
                    label={t('DEALERSTATE')}
                    name={'state'}
                    value={payload.state}
                    inputProps={{ maxLength: 100 }}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
                  <Autocomplete
                    options={countries}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label={t('DEALERCOUNTRY')} />
                    )}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfeild
                    label={t('DEALERZIPCODE')}
                    name={'pincode'}
                    value={payload.pincode}
                    validation="alphaNumericWithSpace"
                    handleChange={handleChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfeild
                    label={t('COMMON036')}
                    name={'code'}
                    value={payload.code}
                    error={error.code}
                    handleChange={handleChange}
                    required={true}
                    inputProps={{ maxLength: 36 }}
                    disabled={isEditable}
                    validation="alpha-numeric"
                  />
                </Grid>
                <Grid
                  sx={{ display: 'center', ml: 3, mt: 3 }}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                >
                  <Grid sx={{ marginLeft: 47 }}></Grid>
                </Grid>
              </Grid>
              <Grid container lg={4}></Grid>
              <Grid container lg={8} justifyContent="flex-end">
                <Grid pr={2} sx={{ marginTop: 5 }}>
                  <CancelButtons
                    variant="contained"
                    color="secondary"
                    onClick={onCancel}
                  >
                    <Typography style={{ textTransform: 'none' }}>
                      {t('COMMON008')}
                    </Typography>
                  </CancelButtons>
                </Grid>
                <Grid sx={{ marginTop: 5, marginRight: '40px' }}>
                  <SubmitButtons variant="contained" onClick={onNext}>
                    <Typography>{t('DEALERNEXT')}</Typography>
                  </SubmitButtons>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography
                variant="h5"
                color="inherit"
                style={{ fontWeight: '500' }}
              >
                {t('DEALERPLANDETAILS')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{ marginTop: 4 }}
            >
              {props.location.state === undefined ? (
                <Autocomplete
                  style={{
                    width: '64%',
                    marginTop: '-20px',
                  }}
                  multiple
                  limitTags={2}
                  error={error.subscription}
                  options={subscriptionList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.price_strategy_name}
                  onChange={(event, newValue) => {
                    const ids = newValue.map((newValue) => newValue.id)
                    onselectSubscription(ids)
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        style={{ color: '#F2824C' }}
                        checked={selected}
                      />
                      {option.price_strategy_name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('DEALERPLACEHOLDER1') + ' *'}
                      error={error.subscription}
                      value={selectedSubscriptions}
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  style={{
                    width: '64%',
                    marginTop: '-20px',
                  }}
                  multiple
                  limitTags={2}
                  clearOnBlur={true}
                  error={error.subscription}
                  options={subscriptionList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.price_strategy_name}
                  getOptionDisabled={(option) =>
                    dealerSubscriptionIds.includes(option.id)
                  }
                  onChange={(event, newValue) => {
                    const ids = newValue.map((newValue) => newValue.id)
                    onselectSubscription(ids)
                  }}
                  defaultValue={subscriptionList.filter((option) =>
                    dealerSubscriptionIds.includes(option.id),
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        style={{ color: '#F2824C' }}
                        checked={selected}
                        disabled
                      />
                      {option.price_strategy_name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('DEALERPLACEHOLDER1') + ' *'}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <span key={index} {...getTagProps({ index })}>
                        <Checkbox
                          style={{ color: '#F2824C' }}
                          checked
                          disabled
                        />
                        {option.price_strategy_name}
                      </span>
                    ))
                  }
                />
              )}
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              md={4}
              sm={12}
            ></Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{
                marginTop: '30px',
              }}
            >
              <Stack direction="row" spacing={2}>
                <Typography variant="h4">{t('DEALERBILLINGINFO')}</Typography>
                {isBillingInfoExists ? (
                  <Grid
                    style={{
                      marginTop: '-8px',
                    }}
                  >
                    {t('DEALERBILLINGCOPY')}
                    <Tooltip title={t('COPY_TOOL_TIP')}>
                      <Checkbox
                        checked={checked}
                        onChange={handleCopyBillingData}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Tooltip>
                  </Grid>
                ) : (
                  ''
                )}
              </Stack>
            </Grid>

            <Grid container md={8} sm={12} spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                <CustomTextfeild
                  label={t('COMMON014')}
                  name={'billingUserName'}
                  value={payload.billingUserName}
                  error={error.billingUserName}
                  disabled={payload.billingUserName ? checked : false}
                  handleChange={handleChange}
                  required={true}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomTextfeild
                  label={t('DEALEREMAIL')}
                  name={'billingUserEmail'}
                  value={payload.billingUserEmail}
                  error={error.billingUserEmail}
                  disabled={payload.billingUserEmail ? checked : false}
                  required={true}
                  validation="email"
                  handleChange={handleChange}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ margin: '16px 0px' }}
              >
                <SelfRegistrationCustomPhone
                  placeholder={t('CUSTDATABLE03')}
                  name={'billingUserPhone'}
                  value={billingPhoneInput}
                  error={error.billingUserPhone}
                  // disabled={phoneInput ? true : false}
                  disabled={phoneInput ? checked : false}
                  country={
                    parentCompanyCountry === '' ? 'us' : parentCompanyCountry
                  }
                  specialLabel={false}
                  handleChange={(value, data, event, formattedValue) =>
                    handleBillingPhoneChange(value, data, event, formattedValue)
                  }
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomTextfeild
                  label={t('COMPPROFSCREEN020')}
                  name={'billingAddress'}
                  value={payload.billingAddress}
                  error={error.billingAddress}
                  disabled={payload.address ? checked : false}
                  handleChange={handleChange}
                  required={true}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
                <Autocomplete
                  options={countries}
                  value={selectedBillingCountry}
                  required={true}
                  error={error.billingCountry}
                  disabled={selectedBillingCountry ? checked : false}
                  onChange={handleBillingCountryChange}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label={t('DEALERCOUNTRY') + ' *'} />
                  )}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomTextfeild
                  label={t('DEALERSTATE')}
                  name={'billingState'}
                  value={payload.billingState}
                  error={error.billingState}
                  disabled={payload.state ? checked : false}
                  handleChange={handleChange}
                  required={true}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomTextfeild
                  label={t('DEALERCITY')}
                  name={'billingCity'}
                  value={payload.billingCity}
                  error={error.billingCity}
                  // disabled={payload.city ? true : false}
                  disabled={payload.city ? checked : false}
                  handleChange={handleChange}
                  required={true}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomTextfeild
                  label={t('DEALERZIPCODE')}
                  name={'billingZipcode'}
                  validation="alphaNumericWithSpace"
                  value={payload.billingZipcode}
                  error={error.billingZipcode}
                  disabled={payload.pincode ? checked : false}
                  handleChange={handleChange}
                  required={true}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  marginTop: 5,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <SubmitButtons variant="contained" onClick={onSubmit}>
                  <Typography>{t('confirmVisitorButton')}</Typography>
                </SubmitButtons>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Paper>
    </>
  )
}

export default CreateDealer
