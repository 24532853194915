import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import { Grid } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useTranslation } from 'react-i18next'
import CheckInLogs from './CheckInLogs'
import CheckOut from './CheckOut'
import CancelledLogs from './CancelledLogs'
import MissedLogs from './MissedLogs'
import RejectedLogs from './RejectedLogs'
import AllLogs from './AllLogs'
import { Link } from "@mui/material"
import InvitationFilterForm from '../../components/InvitationFilterForm'
import Search from '../../components/SearchTab/Search'
import ExportLogs from './ExportLogs'
import { exportAllVistorsLog, exportCancelledMissedCheckinVistorsLog, exportCheckoutVistorsLog } from '../../services/Export/VisitorLogExportService'
import CommonUtil from "../../Util/CommonUtils";
import { toast } from 'react-toastify'
import Toast from '../../components/ToastContainer/CustomToast'
import { listInvitations } from '../../slices/Invitations/InvitationsSlice';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return <div {...other}>{value === index && <Box >{children}</Box>}</div>;
}

const tabsStyle = {
    borderRight: 'solid #00000029', minWidth: 150,
    textTransform: "none"
}

export default function LogsTabs(props) {
    const formatDayAndMonth = (numValue) => {
        if (numValue <= 0) {
            return "-" + (numValue + 12);
        }
        if (numValue < 10) {
            return "-0" + numValue;
        } else {
            return "-" + numValue;
        }
    };
    const { t } = useTranslation();


    var today = new Date();
    var endDate =
        today.getFullYear() +
        formatDayAndMonth(today.getMonth() + 1) +
        formatDayAndMonth(today.getDate()) +
        "T23:59";
    var startDate =
        today.getFullYear() +
        formatDayAndMonth(today.getMonth() + 1) +
        formatDayAndMonth(today.getDate()) +
        "T00:00";
    const [value, setValue] = useState(0);
    const [svalue, setsValue] = useState('');

    const [filterValue, setsFilterValue] = useState({
        visitorTypeCode: "",
        siteId: "",
        scheduledStartDate: "",
        scheduledEndDate: "",
    });
    const [clear, setClear] = useState(false);
    const [defaultFilter, setDefaultFilter] = useState({});
    const [clearSearch, setClearSearch] = useState(false);
    const [newState, setNewState] = useState(false);
    const [filterState, setFilterState] = useState(false);

    const TestdefaultFilter = {
        firstName: "",
        lastName: "",
        emailId: "",
        mobile: "",
        status: ['Missed', 'APPROVED', 'BLOCKED', 'CONFIRMED', 'CHECKED_IN', 'CHECKED_OUT',
            'CANCELLED', 'REJECTED', 'INITIATED_FOR_BLOCKED', 'WAIT_FOR_VISITOR'],
        visitorType: "",
        siteId: "",
        scheduledStartDate: CommonUtil.formatToUtc(startDate),
        scheduledEndDate: CommonUtil.formatToUtc(endDate),
        fromDate: null,
        toDate: null,
        hostId: "",
        hostEmail: "",
        createdBy: "",
        registrationMode: [],
        isPagable: true,
    };
    const handleSetDefaultFilter = (data) => {
        setDefaultFilter(data);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleExport = () => {
        switch (value) {
            case 0:
                return exportCancelledMissedCheckinVistorsLog('CHECKED_IN', onSuccess, onFailure);
            case 1:
                return exportCheckoutVistorsLog(onSuccess, onFailure);
            case 2:
                return exportCancelledMissedCheckinVistorsLog('CANCELLED', onSuccess, onFailure);
            case 3:
                return exportCancelledMissedCheckinVistorsLog('Missed', onSuccess, onFailure);
            case 4:
                return exportCancelledMissedCheckinVistorsLog('REJECTED', onSuccess, onFailure);
            case 5:
                return exportAllVistorsLog(onSuccess, onFailure);
        }
    }

    const onSuccess = () => {
        // switch (value) {
        //     case 0:
        //         return toast.success(t('EXPORTSUCCESS003'));
        //     case 1:
        //         return toast.success(t('EXPORTSUCCESS002'));
        //     case 2:
        //         return toast.success(t('EXPORTSUCCESS001'));
        //     case 3:
        //         return toast.success(t('EXPORTSUCCESS004'));
        //     case 4:
        //         return toast.success(t('EXPORTSUCCESS005'));
        //     case 5:
        //         return toast.success(t('EXPORTSUCCESS006'));
        // }

        return Toast('File Exported Successfully', 'success');

    }

    const onFailure = () => {
        toast.error(t('COMMON000'));
    }

    const onSearchSetValue = (data) => {
        setsValue(data)
    }
    const handleClear = (data) => {
        setClear(data)
        exportAllVistorsLog(onSuccess, onFailure);
    }
    const applyFilterSetData = (filterData) => {
        setFilterState(true)
        const query = {
            visitorTypeCode: filterData.visitorTypeCode,
            siteId: filterData.siteId,
            scheduledStartDate: CommonUtil.formatToUtc(filterData.scheduledStartDate),
            scheduledEndDate: CommonUtil.formatToUtc(filterData.scheduledEndDate),
        }
        setsFilterValue(query)
        setClear(true)
        // setsFilterValue(data)
    }
    const handleLoadData = () => {
        setNewState(true)
    }

    return (
        <>
            <Grid container >
                <Grid item style={{ marginTop: '20px' }} xs={12}
                // xl={12}  md={12} sm={12}  xs={12}
                >
                    <Tabs
                        TabIndicatorProps={{ style: { background: process.env.REACT_APP_BG_ICON, height: 3 } }}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label={t('STATUSTABS002')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS003')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS004')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS005')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS006')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS001')} style={{ textTransform: "none" }} />
                    </Tabs>
                </Grid>
                <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Box
                        display={"flex"}
                        width={"100%"}
                        flexDirection={"row-reverse"}
                        alignItems={"center"}
                    >
                        <Box item>
                            {clear && (
                                <Link
                                    href="#"
                                    underline="hover"
                                    className="line"
                                    style={{
                                        opacity: "0.8",
                                        color: "#E3393C",
                                        fontSize: "14px",
                                        underline: "hover",
                                        display: "inline",
                                    }}
                                    onClick={() => { setsFilterValue(''); setClear(false) ;setFilterState(false)}}
                                >
                                    {"Clear Filter"}
                                </Link>
                            )}
                        </Box>
                        <Box item>
                            <InvitationFilterForm
                                applyFilter={(data) => applyFilterSetData(data)}
                            />
                        </Box>
                        <Box item p={1}>
                            <ExportLogs handleClick={handleExport()} />
                        </Box>
                        <Box item p={1}>
                            <Search
                                onSearch={(e) => onSearchSetValue(e)}
                                clearSearch={(e) => handleClear(e)}
                                loadData={(e) => handleLoadData(e)}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={12}>
                    {
                        value === 0 && (
                            <TabPanel value={value} index={value} >
                                <CheckInLogs
                                    clear={clear}
                                    svalue={svalue}
                                    filterValue={filterValue}
                                    filterState={filterState}
                                    newState={newState}

                                />
                            </TabPanel>
                        )
                    }
                    {
                        value === 1 && (
                            <TabPanel value={value} index={value}>
                                <CheckOut
                                    clear={clear}
                                    svalue={svalue}
                                    filterValue={filterValue}
                                    filterState={filterState}
                                    newState={newState}

                                />
                            </TabPanel>
                        )
                    }
                    {
                        value === 2 && (
                            <TabPanel value={value} index={value}>
                                <CancelledLogs
                                    clear={clear}
                                    svalue={svalue}
                                    filterValue={filterValue}
                                    filterState={filterState}
                                    newState={newState}

                                />
                            </TabPanel>
                        )
                    }
                    {
                        value === 3 && (
                            <TabPanel value={value} index={value}>
                                <MissedLogs
                                    clear={clear}
                                    svalue={svalue}
                                    filterValue={filterValue}
                                    filterState={filterState}
                                    newState={newState}

                                />
                            </TabPanel>
                        )
                    }
                    {
                        value === 4 && (
                            <TabPanel value={value} index={value}>
                                <RejectedLogs
                                    clear={clear}
                                    svalue={svalue}
                                    filterValue={filterValue}
                                    filterState={filterState}
                                    newState={newState}

                                />
                            </TabPanel>
                        )
                    }
                    {
                        value === 5 && (
                            <TabPanel value={value} index={value}>
                                <AllLogs
                                    filterState={filterState}
                                    newState={newState}
                                    svalue={svalue}
                                    filterValue={filterValue === {} ? TestdefaultFilter : filterValue}
                                    handleSetDefaultFilter={(data) => { handleSetDefaultFilter(data) }}
                                />
                            </TabPanel>
                        )
                    }
                </Grid>
            </Grid>
        </>
    )
}

