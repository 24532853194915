import React from 'react'

const SiteManagementWhite = () => {
  return (
    <svg className='MuiSvgIcon-root' xmlns="http://www.w3.org/2000/svg" width="21.146" height="25.541" viewBox="0 0 21.146 25.541">
      <g id="Site_Management-white" data-name="Site Management-white" transform="translate(-28.132 -13)">
        <path id="Path_3853" data-name="Path 3853" d="M29,61.149s1.8-2.7,4.049-3.149h.45v3.149H29" transform="translate(-0.477 -24.63)" opacity="0.5" />
        <path id="Path_3854" data-name="Path 3854" d="M47,57a6.2,6.2,0,0,0,4.5,3.6H47V57" transform="translate(-10.379 -24.08)" opacity="0.5" />
        <path id="Path_3855" data-name="Path 3855" d="M65,57a7.7,7.7,0,0,1,4.049,3.6H65Z" transform="translate(-20.281 -24.08)" opacity="0.5" />
        <path id="Path_3845" data-name="Path 3845" d="M48.913,60.908H44.538a.365.365,0,0,1-.364-.364V56.9a.365.365,0,0,1,.364-.364h4.375a.365.365,0,0,1,.364.364v3.644A.364.364,0,0,1,48.913,60.908Zm-4.01-.729h3.644V57.261H44.9Zm-4.01.729H36.517a.365.365,0,0,1-.364-.364V56.9a.365.365,0,0,1,.364-.364h4.375a.365.365,0,0,1,.364.364v3.644A.364.364,0,0,1,40.892,60.908Zm-4.01-.729h3.644V57.261H36.882Zm12.031,2.186H44.538a.365.365,0,0,1,0-.729h4.375a.365.365,0,1,1,0,.729Zm-8.021,0H36.517a.365.365,0,0,1,0-.729h4.375a.365.365,0,1,1,0,.729Zm-8.021,0H28.5a.365.365,0,0,1,0-.729h4.375a.365.365,0,1,1,0,.729Zm0-1.458H28.5a.365.365,0,0,1-.364-.364V56.9a.365.365,0,0,1,.364-.364h4.375a.365.365,0,0,1,.364.364v3.644a.365.365,0,0,1-.364.366h0Zm-4.01-.729h3.644V57.261H28.861Z" transform="translate(0 -23.824)" />
        <rect id="Rectangle_272" data-name="Rectangle 272" width="16.646" height="9.448" rx="1.353" transform="translate(30.322 16.723)" />
        <path id="Path_3846" data-name="Path 3846" d="M51.17,43.107a.348.348,0,0,1-.348-.348V40.671a.348.348,0,0,1,.7,0h0v2.088A.348.348,0,0,1,51.17,43.107Zm0,3.478a.348.348,0,0,1-.348-.348V44.151a.348.348,0,0,1,.7,0v2.088a.348.348,0,0,1-.348.348Z" transform="translate(-12.465 -14.607)" />
        <path id="Path_3846_-_Outline" data-name="Path 3846 - Outline" d="M51.17,40.073a.6.6,0,0,1,.6.6v2.088a.6.6,0,0,1-1.2,0V40.671A.6.6,0,0,1,51.17,40.073Zm0,2.784a.1.1,0,0,0,.1-.1V40.671a.1.1,0,0,0-.2,0v2.088A.1.1,0,0,0,51.17,42.857Zm0,.7a.6.6,0,0,1,.6.6v2.088a.6.6,0,0,1-.6.6h-.25V46.78a.6.6,0,0,1-.348-.542V44.151A.6.6,0,0,1,51.17,43.553Zm.016,2.782a.1.1,0,0,0,.082-.1V44.151a.1.1,0,0,0-.2,0v2.087a.1.1,0,0,0,.1.1Z" transform="translate(-12.465 -14.607)" />
        <path id="Path_3847" data-name="Path 3847" d="M49.4,51.343a.365.365,0,0,1-.364-.364V49.155H33.723v1.823a.365.365,0,1,1-.729,0V48.79a.365.365,0,0,1,.364-.364H49.4a.365.365,0,0,1,.364.364v2.187A.364.364,0,0,1,49.4,51.343Z" transform="translate(-2.675 -19.364)" />
        <path id="Path_3847_-_Outline" data-name="Path 3847 - Outline" d="M33.359,51.593a.616.616,0,0,1-.615-.615V48.79a.617.617,0,0,1,.614-.614H49.4a.616.616,0,0,1,.614.614v2.187a.614.614,0,0,1-1.229,0V49.405H33.973v1.573A.615.615,0,0,1,33.359,51.593Zm0-2.917a.115.115,0,0,0-.114.115v2.187a.115.115,0,1,0,.229,0V48.905H49.286v2.073a.114.114,0,1,0,.229,0V48.79a.115.115,0,0,0-.115-.114Z" transform="translate(-2.675 -19.364)" />
        <path id="Rectangle_273" data-name="Rectangle 273" d="M-.625-.625h17.9V6.944a3.132,3.132,0,0,1-3.129,3.129H2.5A3.132,3.132,0,0,1-.625,6.944ZM16.021.625H.625V6.944A1.881,1.881,0,0,0,2.5,8.823H14.143a1.881,1.881,0,0,0,1.879-1.879Z" transform="translate(30.322 16.723)" />
        <path id="Rectangle_274" data-name="Rectangle 274" d="M1,0H16.546a1,1,0,0,1,1,1V2.25a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z" transform="translate(29.872 13)" />
        <circle id="Ellipse_2783" data-name="Ellipse 2783" cx="0.45" cy="0.45" r="0.45" transform="translate(32.122 14)" />
        <circle id="Ellipse_2784" data-name="Ellipse 2784" cx="0.45" cy="0.45" r="0.45" transform="translate(33.921 14)" />
        <circle id="Ellipse_2785" data-name="Ellipse 2785" cx="0.45" cy="0.45" r="0.45" transform="translate(35.721 14)" />
        <g id="Group_494" data-name="Group 494" transform="translate(33.236 22.329)">
          <path id="Path_3848" data-name="Path 3848" d="M50.05,36.585H39.841c-.2,0-.364-.251-.364-.562s.163-.563.364-.563H50.05c.2,0,.365.252.365.563S50.251,36.585,50.05,36.585Z" transform="translate(-39.477 -35.46)" />
        </g>
        <g id="Group_495" data-name="Group 495" transform="translate(33.236 19.071)">
          <path id="Path_3849" data-name="Path 3849" d="M50.05,27.344H39.841c-.2,0-.364-.251-.364-.562s.163-.563.364-.563H50.05c.2,0,.365.252.365.563S50.251,27.344,50.05,27.344Z" transform="translate(-39.477 -26.219)" />
        </g>
      </g>
    </svg>
  )
}

export default SiteManagementWhite
