import React, { useEffect, useState } from 'react'
import { MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import CommonUtil from '../Util/CommonUtils'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { styled } from '@mui/system'

const CssTextField = styled((props) => <TextField {...props} />)(
  ({ theme }) => ({
    textStyle: {
      '& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root': {
        fontSize: '0.875rem',
      },
      '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
        fontSize: '14px',
      },
    },
  }),
)

export default function SubscriptionTextfield(props) {
  const [helperText, setHelperText] = useState(props.helperText)
  const [error, setError] = useState(props.error)
  const [value, setValue] = useState(props.value)
  const [cusrrencyValue, setCurrencyValue] = useState('USD')
  const edit = props.edit !== undefined ? true : false
  const { t } = useTranslation()
  const [currency, setCurrency] = useState('$')

  useEffect(() => {
    setError(props.error)
    setHelperText(props.helperText)
  }, [props.error])

  const handleChange = (e) => {
    if (props.periodValue === '') {
      setHelperText(t('Please select Subscription Type'))
      setError(true)
      return
    }
    var val = e.target.value
    if (val.trim() === '') {
      val = val.trim()
    }
    if (e.target.value.length > 0 && val.length == 0) {
      return
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return
      }
      if (val[0] === ' ') {
        return
      }
    }
    if (props.regex === 'none' && props.validation) {
      switch (props.validation) {
        case 'alpha-numeric':
          handleCode(e)
          break
        case 'numeric':
          handleNumeric(e)
          break
        case 'restrictLength':
          handleRestrictLength(e)
          break
        case 'email':
          handleEmail(e)
          break
        case 'password':
          handlePassword(e)
          break
        case 'code':
          handleCode(e)
          break
        case 'alphabets':
          handleAlphabets(e)
        default:
          props.handleChange(e)
      }
    }
  }

  useEffect(() => {
    setValue(props.value)
  }, [currency + props.value])

  const handleAlphabets = (e) => {
    if (CommonUtil.isAlphabets(e.target.value)) {
      props.handleChange(e)
      setHelperText(props.helperText)
      setError(false)
    } else {
      setError(true)
      setHelperText(t('Only accepts alphabets'))
    }
  }

  const handleCode = (e) => {
    if (CommonUtil.isAlphaNumeric(e.target.value)) {
      props.handleChange(e)
      setHelperText(props.helperText)
      setError(false)
    } else {
      setError(true)
      setHelperText(t('Only accepts alphanumeric characters'))
    }
  }

  const handleNumeric = (e) => {
    if (CommonUtil.isValidNumeric(e.target.value)) {
      props.handleChange(e)
      setHelperText(props.helperText)
      setError(false)
    } else {
      setError(true)
      setHelperText(t('INVITATIONFORM019'))
    }
    if (e.target.value.length <= 6) {
      props.handleChange(e)
      setHelperText(props.helperText)
      setError(false)
    } else {
      setError(true)
      setHelperText(t('NUMBER_VALIDATION_SUBSCRIPTION_PRICE'))
    }
  }

  const handleRestrictLength = (e) => {
    if (e.target.value.length <= 6) {
      props.handleChange(e)
      setHelperText(props.helperText)
      setError(false)
    } else {
      setError(true)
      setHelperText(t('Characters length should be less than 100'))
    }
  }

  const handleEmail = (e) => {
    props.handleChange(e)
    if (CommonUtil.isValidEmail(e.target.value)) {
      setHelperText(props.helperText)
      setError(false)
    } else {
      setError(true)
      setHelperText(t('INVITATIONFORM014'))
    }
  }

  const handlePassword = (e) => {
    props.handleChange(e)
    if (CommonUtil.isValidPassword(e.target.value)) {
      setHelperText(props.helperText)
      setError(false)
    } else {
      setError(true)
      setHelperText(t('Only accepts Password'))
    }
  }

  const handleCurrencyChange = (e) => {
    props.handleCurrencyChange(e)
    setCurrencyValue(e.target.value)
    //   if(e.target.value === "USD"){
    //   setCurrency("$");
    //   }
    // else if(e.target.value === "EURO"){
    //   setCurrency("€");
    // }
  }

  return (
    <CssTextField
      style={{ background: 'transparent' }}
      autoComplete="off"
      {...props}
      required={props.required}
      disabled={props.disabled}
      value={value}
      helperText={helperText}
      error={error}
      onChange={(e) => handleChange(e)}
      margin="normal"
      variant="outlined"
      fullWidth
      size="medium"
      type={props.type}
      label={props.label}
      name={props.name}
      // className={classes.textStyle}
      onBlur={props.onBlur}
      className="login_input"
      InputLabelProps={
        props.InputLabelProps ? { shrink: props.InputLabelProps } : undefined
      }
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <React.Fragment>
            {props.InputProps && props.InputProps.endAdornment}
            {edit === true ? (
              <TextField
                select
                value={'USD'}
                // onChange={(e) => handleCurrencyChange(e)}
                style={{ minWidth: '80px', marginRight: '-13px' }}
                variant="outlined"
              >
                <MenuItem value="USD">USD</MenuItem>
                {/* <MenuItem value="Euro">Euro</MenuItem> */}
              </TextField>
            ) : (
              <TextField
                select
                value={cusrrencyValue}
                onChange={(e) => handleCurrencyChange(e)}
                style={{ minWidth: '80px', marginRight: '-13px' }}
                variant="outlined"
              >
                <MenuItem value="USD">USD</MenuItem>
                {/* <MenuItem value="Euro">Euro</MenuItem> */}
              </TextField>
            )}
          </React.Fragment>
        ),
      }}
    />
  )
}

SubscriptionTextfield.defaultProps = {
  validation: 'none',
  regex: 'none',
  required: false,
  disabled: false,
}

SubscriptionTextfield.propType = {
  validation: PropTypes.oneOf([
    'alpha-numeric',
    'alphabets',
    'numeric',
    'restrictLength',
    'email',
    'password',
    'code',
  ]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
}
