import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { Autocomplete, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import isoCountries from 'i18n-iso-countries'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CommonUtil from '../../Util/CommonUtils'
import { REACT_URL_USERS } from '../../actions/EndPoints'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import Toast from '../../components/ToastContainer/CustomToast'
import TitleBar from '../../components/v4/TitleBar'
import { getCompanyInfo } from '../../slices/Company/CompanySlice'
import {
  createUser,
  updateUser
} from '../../slices/User/UserSlice'
import { listofRoles } from '../../slices/UserRole/UserRoleSlice'
import ProfileUplod from '../Employee/UploadImg'
import SelfRegistrationCustomPhone from '../OnBoardV2/SelfRegistrationCustomPhone'

export default function CreateUser(props) {
  let history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [phoneInput, setPhoneInput] = useState('')
  const [selectedRoleName, setSelectedRoleName] = useState('')
  const [selectedRole, setSelectedRole] = useState(null)
  const [selectedValue, setSelectedValue] = useState(null)
  const [roleType, setRoleType] = useState([])
  const [profile, setProfile] = useState('')
  const [profileImage, setProfileImage] = useState()
  const [isEditable, setIsEditable] = useState(false)
  const [country, setCountry] = useState(props.location.country)
  const [userProfile, setUserProfile] = useState('userProfile')
  const [parentCompanyCountry, setParentCompanyCountry] = useState('')

  const handleCancel = () => {
    history.push({
      pathname: REACT_URL_USERS,
    })
  }

  const [userPayload, setUserPayload] = React.useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    profilePhoto: '',
    profilePhotoData: '',
    role: '',
    status: '',
  })

  const [error, setError] = React.useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneCountryCode: false,
    phone: false,
    profilePhoto: false,
    role: false,
  })
  const [filter] = React.useState({
    name: 'Admin',
    roleScope: 'APPLICATION',
  })

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  useEffect(() => {
    loadRoleData(filter, paging)
    if (props.location.state === undefined || props.location.state.user.phone === '') {
      loadParentCompany()
    }
  }, [])

  const loadParentCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setParentCompanyCountry(countryCode)
          }
        }
      })
      .catch((er) => { })
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  useEffect(() => {
    if (props.location.state != null) {
      setIsEditable(true)
      setSelectedValue(props.location.state.roleName)
      setUserPayload({
        ...userPayload,
        id: props.location.state.user.id,
        email: props.location.state.user.email,
        phoneCountryCode: props.location.state.user.phone
          ? props.location.state.user.phone.split('-')[0]
          : '',
        phone: props.location.state.user.phone
          ? props.location.state.user.phone.split('-')[1]
          : '',
        firstName: props.location.state.user.firstName,
        lastName: props.location.state.user.lastName,
        role: props.location.state.roleName,
        status: props.location.state.user.verified,
        profilePhotoData: props.location.state.user.imagePreSignedURL
      })
      setProfile(props.location.state.user.imagePreSignedURL)
      setPhoneInput(props.location.state.user.phone)
    }
  }, [])

  const loadRoleData = (filter, paging) => {
    dispatch(listofRoles(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0000') {
          const role = data.data.roles.filter((row) => row.name === 'Admin')
          setRoleType(role)
          const selectedRoleName = role.find(
            (roleName) => roleName.name === props.location.state.roleName,
          )
          setSelectedValue(selectedRoleName)
        } else {
          setRoleType([])
        }
      })
  }

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value)
    setUserPayload({
      ...userPayload,
      phoneCountryCode: '+' + data.dialCode,
      phone: value.toString().replace(data.dialCode, ''),
    })
    setError({ phone: false })
  }
  const handleUserProfilePhoto = (file) => {
    setUserPayload({
      ...userPayload,
      profilePhotoData: file.base64,
    })
    setProfile(file.base64)
    setProfileImage(file.file)
  }
  const handleUserFieldChange = (event) => {
    const name = event.target.name
    setUserPayload({
      ...userPayload,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handleRoleSelection = (event, value) => {
    const name = event.target.name
    if (value) {
      setSelectedRoleName(value.name)
      setSelectedRole(value)
      setSelectedValue(value)
      setUserPayload({
        ...userPayload,
        role: value.id,
      })
    } else {
      setSelectedRoleName('')
      setSelectedRole()
    }
  }

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.data.imagePreSignedURL)
    bodyFormData.append('file', profileImage)
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) { })
      .catch(function (response) { })
  }

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(userPayload.firstName)) {
      Toast(t('USERPROFSCREEN007'), 'error')
      setError({ firstName: true })
      return
    }
    if (CommonUtil.isEmptyString(userPayload.lastName)) {
      Toast(t('USERPROFSCREEN008'), 'error')
      setError({ lastName: true })
      return
    }

    if (CommonUtil.isEmptyString(userPayload.email)) {
      Toast(t('EMAILVALIDATE'), 'error')
      setError({ email: true })
      return false
    }

    if (!CommonUtil.isValidEmail(userPayload.email)) {
      setError({ ...error, email: true })
      Toast(t('CORRECTEMAIL'), 'error')
      return
    }

    if (CommonUtil.isEmptyString(userPayload.role)) {
      Toast(t('ROLEEMPTYERROR'), 'error')
      setError({ role: true })
      return false
    }

    let payload = {
      ...userPayload,
      profilePhoto: userPayload.firstName,
      phoneCountryCode: userPayload.phone ? userPayload.phoneCountryCode : '',
    }
    if (props.location.state === undefined) {
      dispatch(createUser(payload))
        .unwrap()
        .then((data) => {
          if (data.code === 'MSGS4010' || data.code === 'MSGS4011') {
            if (!CommonUtil.isEmptyString(data.data.imagePreSignedURL))
              uploadProfilePhoto(data)
            Toast(t('USER_SUCCESS_CREATE'), 'success')
            history.push(REACT_URL_USERS)
          } else if (data.code === 'UASE0029') {
            Toast(t('USER_ERROR_EMAIL_EXISTS'), 'error')
          } else Toast(data.message, 'error')
        })
        .catch((er) => { })
    } else {
      dispatch(updateUser(payload))
        .unwrap()
        .then((data) => {
          if (data.code === 'MSGS4011') {
            if (!CommonUtil.isEmptyString(data.data.imagePreSignedURL))
              uploadProfilePhoto(data)
            Toast(t('USER_SUCCESS_UPDATE'), 'success')
            history.push(REACT_URL_USERS)
          } else Toast(data.message, 'error')
        })
        .catch((er) => { })
    }
  }

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ m: 2, marginTop: -3 }}>
          <TitleBar
            title={isEditable ? t('LABELNAVITEMSEDITUSER') : t('LABELNAVITEMSCREATEUSER')}
            back_button={true}
          />
        </Box>
      </Grid>
      <Paper>
        <Stack direction="row">
          <Grid container p={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              md={4}
              sm={12}
            >
              <Grid sm={7} style={{ marginTop: '100px' }}>
                <ProfileUplod
                  onsetProfile={(profile) => handleUserProfilePhoto(profile)}
                  profilePhoto={profile}
                  uploadPhoto={userProfile}
                />
              </Grid>
            </Grid>
            <Grid container md={8} sm={12} spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ marginTop: '100px' }}>
                <CustomTextfeild
                  type={'text'}
                  error={error.firstName}
                  label={t('USER0001')}
                  name={'firstName'}
                  required={true}
                  value={userPayload.firstName}
                  handleChange={(e) => handleUserFieldChange(e)}
                  helperText={error.firstName}
                  inputProps={{ maxLength: 99 }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ marginTop: '100px' }}>
                <CustomTextfeild
                  type={'text'}
                  error={error.lastName}
                  label={t('USER0002')}
                  required={true}
                  name={'lastName'}
                  value={userPayload.lastName}
                  handleChange={(e) => handleUserFieldChange(e)}
                  helperText={error.lastName}
                  inputProps={{ maxLength: 99 }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <CustomTextfeild
                  type={'text'}
                  error={error.email}
                  label={t('COMMON042')}
                  name={'email'}
                  required={true}
                  value={userPayload.email}
                  handleChange={(e) => handleUserFieldChange(e)}
                  helperText={error.email}
                  inputProps={{ maxLength: 150 }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ marginTop: '12px' }}>
                <SelfRegistrationCustomPhone
                  placeholder={t('COMMON063')}
                  value={phoneInput}
                  country={parentCompanyCountry === "" ? 'us' : parentCompanyCountry}
                  specialLabel={false}
                  handleChange={(value, data, event, formattedValue) =>
                    handlePhoneChange(value, data, event, formattedValue)
                  }
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ marginTop: '12px' }}>
                <Autocomplete
                  options={roleType}
                  getOptionLabel={(option) => option.name || ''}
                  value={selectedValue}
                  onChange={handleRoleSelection}
                  renderInput={(params) => (
                    <TextField {...params} label="Role *" />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <Grid
          container
          xs={12}
          justifyContent='flex-end'
          mt={2}
          spacing={3}
          style={{
            marginTop: '100px',
            paddingBottom: '50px',
          }}
        >
          <Grid item sx={{ ml: 3 }}>
            <CancelButtons
              onClick={handleCancel}
              color="secondary"
              variant="contained"
            >
              <Typography>{t('COMMON008')}</Typography>
            </CancelButtons>
          </Grid>
          <Grid item sx={{ ml: 3 }}>
            <SubmitButtons onClick={handleSubmit} variant="contained">
              <Typography>{t('COMMON007')}</Typography>
            </SubmitButtons>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
