import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DoorService from '../../services/DoorService/DoorService';


const initialState = [];

export const DoorList = createAsyncThunk('Doors/list', async (payload) => {
    const res = await DoorService.DoorList(payload);
    return res.data;
});

export const getDoors = createAsyncThunk('Doors/getDoors', async (payload) => {
    const res = await DoorService.getDoors(payload);
    return res.data;
});

export const doorControl = createAsyncThunk('Doors/doorControl', async (payload) => {
    const res = await DoorService.doorControl(payload);
    return res.data;
});

export const AddDoorToAccessLevel = createAsyncThunk('Doors/add', async (payload) => {
    const res = await DoorService.addDoorToAccessLevel(payload);
    return res.data;
});

const DoorSlice = createSlice({
    name: 'Doors',
    initialState,
    extraReducers: {
    
    }
})

const { reducer } = DoorSlice;
export default reducer;