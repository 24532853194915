import {
  Avatar,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Switch,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HeaderToolbar from '../../components/HeaderToolbar'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import SubscriptionTextfield from '../../components/CustomInputs/SubscriptionTextfield'
import { makeStyles } from '@material-ui/core'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import { REACT_SUBSCRIPTION_MANAGEMENT } from '../../actions/EndPoints'
import CommonUtil from '../../Util/CommonUtils'
import Toast from '../../components/ToastContainer/CustomToast'
import { useEffect } from 'react'
import {
  createSubscription,
  getFeatures,
  getProductById,
  getSubscriptionById,
  updatePriceStrategy,
  updateProduct,
} from '../../slices/Subscription/SubscriptionSlice'
import SubscriptionDropdown from '../../components/CustomInputs/SubscriptionDropdown'
import DoneIcon from '@mui/icons-material/Done'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { createPriceStrategy } from '../../slices/Subscription/SubscriptionSlice'
import { getDiscounts } from '../../slices/Subscription/SubscriptionSlice'
import ViewSubscription from './ViewSubscription'
import CardTwo from '../../assets/CardTwo.svg'
import CardOne from '../../assets/CardOne.svg'
import CardThree from '../../assets/CardThree.svg'
import CardFour from '../../assets/CardFour.svg'
import CardFive from '../../assets/CardFive.svg'
import { Tooltip } from '@mui/material'
import { getVisitLimit } from '../../slices/Subscription/SubscriptionSlice'

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.light),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      // left: 12,
      fontSize: '12px',
    },
  },

  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#0F5A9C !important',
    opacity: 1,
  },
}))

const StyledBadge = styled(FormGroup)(({ theme }) => ({
  '& .MuiTypography-root': {
    left: '0px !important',
    position: 'absolute',
  },
}))
const useStyles = makeStyles((theme) => ({
  title: {
    color: '#c4c4c4 !important',
    fontSize: '20px !important',
    fontWeight: '500 !important',
  },
}))

function EditSubscription(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const subscriptionId = props.history.location.state
  const [openView, setOpenView] = useState(false)
  const { t } = useTranslation()
  const [features, setFeatures] = useState([])
  const [allFeatures, setAllFeatures] = useState([])
  const [selectedFeature, setSelectedFeature] = useState([])
  const [featureNameList, setFeatureNameList] = useState([])
  const [periodValue, setPeriodValue] = useState('')
  const [currency, setCurrency] = useState('$')
  const [feature1, setFeature1] = useState('')
  const [feature2, setFeature2] = useState('')
  const [feature3, setFeature3] = useState('')
  const [feature4, setFeature4] = useState('')
  const [priceState, setPriceState] = useState(false)
  const [feature5, setFeature5] = useState('')
  const [type, setType] = useState(0)
  const [period, setperiod] = useState(0)
  const [toggle, setToggle] = useState({})
  const [priceStrategyId, setPriceStrategyId] = useState('')
  const [featuresForEdit, setFeaturesForEdit] = useState([])
  const [discountId, setDiscountId] = useState('')
  const [featureNamesForEdit, setFeatureNamesForEdit] = useState([])
  const [visitorCount, setVisitorCount] = useState(0)
  const [productId, setProductId] = useState('')
  const [state, setState] = React.useState({
    name: '',
    feature: featuresForEdit,
    country: 'US',
    currency: 'USD',
    validity: true,
    subscriptionType: '',
    monthly_price: 0,
    annual_price: 0,
    visitLimit: visitorCount,
    discount: discountId,
  })

  const [error, setError] = useState({
    name: false,
    price: false,
    annual_price: false,
    monthly_price: false,
    country: false,
    currency: false,
    periodValue: false,
    visitorCount: false,
  })

  useEffect(() => {
    loadProduct(subscriptionId)
  }, [])

  useEffect(() => {
    loadFeatures()
  }, [])

  useEffect(() => {
    const query = {
      pageNumber: 1,
      pageSize: 100,
    }

    dispatch(getDiscounts(query))
      .unwrap()
      .then((data) => {
        if (data.code === '200' && data.data) {
          const discountList = data.data.results
          if (discountList.length > 0) {
            setDiscountId(discountList[0].id)
          }
        }
      })
  }, [])

  const loadFeatures = () => {
    const query = {
      pageNumber: 1,
      pageSize: 100,
    }

    dispatch(getFeatures(query))
      .unwrap()
      .then((data) => {
        if (data.code === '200' && data.data) {
          setAllFeatures(data.data.results)
        } else {
          setAllFeatures([])
        }
      })
  }

  const handleChangeDropdown = (event) => {
    if (event.target.value === 'Yearly') {
      setPeriodValue('Yearly')
      setType(2)
      setperiod(1)
    } else {
      setPeriodValue('Monthly')
      setType(1)
      setperiod(1)
    }
    setError({
      ...error,
      periodValue: false,
    })
  }

  const cancel = (e) => {
    history.push(REACT_SUBSCRIPTION_MANAGEMENT)
  }

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.name)) {
      Toast(t('Please Enter Plan Name'), 'error')
      setError({ ...error, name: true })
      return
    }

    if (CommonUtil.isEmptyString(state.subscriptionType)) {
      Toast(t('Please select Subscription Type'), 'error')
      setError({ ...error, periodValue: true })
      return
    }

    if (CommonUtil.isEmptyArray(featuresForEdit)) {
      Toast(t('Please select atleast one Subscription Information'), 'error')
      return
    }

    dispatch(
      updateProduct({
        ...state,
        feature: featuresForEdit,
        discount: discountId,
        productId,
      }),
    )
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4039' && data.data) {
          Toast(data.message, 'success')
          history.push('/vis/subscription-management')
        } else {
          Toast(data.message, 'error')
          return
        }
      })

    const visitorCountPayload = {
      subscriptionId: subscriptionId,
      visitorCount: visitorCount,
    }
  }

  const handleChange1 = (event) => {
    setState({
      ...state,
      name: event.target.value,
    })
    setError({
      name: false,
    })
  }

  const handleCurrencyChange = (event) => {
    if (event.target.value === 'USD') {
      setCurrency('$')
      setState({
        ...state,
        currency: 'USD',
      })
    } else if (event.target.value === 'Euro') {
      setCurrency('€')
      setState({
        ...state,
        currency: 'EUR',
      })
    }
  }

  const handleChange2 = (event) => {
    if (periodValue === 'Yearly') {
      setState({
        ...state,
        annual_price: event.target.value,
      })
    } else {
      setState({
        ...state,
        monthly_price: event.target.value,
      })
    }
  }

  const handleVisitorCount = (event) => {
    setVisitorCount(event.target.value)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 17) {
      return text.substring(0, 26) + '...'
    } else {
      return text
    }
  }

  const handleTextPrice = (text) => {
    if (text && text.length > 9) {
      return text.substring(0, 9) + '...'
    } else {
      return text
    }
  }

  const handleToggleChange = (event, featureId, featureName) => {
    const defaultToggleState = { ...toggle }

    if (event.target.checked === true && !featuresForEdit.includes(featureId)) {
      featuresForEdit.push(featureId)
      featureNamesForEdit.push(featureName)
    } else {
      const featureIdIndex = featuresForEdit.indexOf(featureId)
      if (featureIdIndex !== -1) {
        featuresForEdit.splice(featureIdIndex, 1)
      }

      const indexToRemove = featureNamesForEdit.indexOf(featureName)
      if (indexToRemove !== -1) {
        featureNamesForEdit.splice(indexToRemove, 1)
      }
    }

    defaultToggleState[featureName] = event.target.checked

    setSelectedFeature([...selectedFeature])
    setFeatureNameList([...featureNameList])
    setToggle(defaultToggleState)
    setSelectedFeature(selectedFeature)
    setFeatureNameList(featureNameList)

    if (featureNamesForEdit[0] !== undefined) {
      setFeature1(featureNamesForEdit[0])
    } else {
      setFeature1('')
    }
    if (featureNamesForEdit[1] !== undefined) {
      setFeature2(featureNamesForEdit[1])
    } else {
      setFeature2('')
    }
    if (featureNamesForEdit[2] !== undefined) {
      setFeature3(featureNamesForEdit[2])
    } else {
      setFeature3('')
    }
    if (featureNamesForEdit[3] !== undefined) {
      setFeature4(featureNamesForEdit[3])
    } else {
      setFeature4('')
    }
    if (featureNamesForEdit[4] !== undefined) {
      setFeature5(featureNamesForEdit[4])
    } else {
      setFeature5('')
    }
  }

  const loadProduct = (subscriptionId) => {
    dispatch(getSubscriptionById(subscriptionId))
      .unwrap()
      .then((data) => {
        if (data.code === '200' && data.data) {
          setFeatures(data.data.feature)
          const defaultToggleState = {}
          const featureIds = []
          const featureNamesEdit = []
          data.data.feature.forEach((feature) => {
            defaultToggleState[feature.name] = true
            featureIds.push(feature.id)
            featureNamesEdit.push(feature.name)
          })

          setFeatureNamesForEdit(featureNamesEdit)
          setFeaturesForEdit(featureIds)
          setToggle(defaultToggleState)
          if (data.data.type_name === 'Pay monthly') {
            setState({
              ...state,
              subscriptionType: 'Monthly',
              name: data.data.price_strategy_name,
              monthly_price: data.data.original_price,
              id: data.data.price_strategy,
            })
            setCurrency('$')
            setPeriodValue('Monthly')
          } else {
            setState({
              ...state,
              subscriptionType: 'Yearly',
              name: data.data.price_strategy_name,
              annual_price: data.data.original_price,
              id: data.data.price_strategy,
            })
            setPeriodValue('Yearly')
          }
          dispatch(getVisitLimit(subscriptionId))
            .unwrap()
            .then((data) => {
              setVisitorCount(data.data.visitLimit)
              setProductId(data.data.id)
            })
        } else {
          setFeatures([])
        }
      })
  }

  const [show, setShow] = useState()

  return (
    <>
      <HeaderToolbar title={t('SUBSCRIPTION_EDIT_HEADER')} back_button={true} />
      <>
        <Paper sx={{ mt: 3, pb: 2 }}>
          <Grid container p={2}>
            <Grid container md={12} sm={12} spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={12}>
                {state.name === 'Free Trial' ? (
                  <CustomTextfeild
                    label={t('SUBSCRIPTIONPLANNAME')}
                    name={'name'}
                    value={state.name}
                    error={error.name}
                    handleChange={handleChange1}
                    type={'text'}
                    inputProps={{ maxLength: 100 }}
                    required
                    disabled
                  />
                ) : (
                  <CustomTextfeild
                    label={t('SUBSCRIPTIONPLANNAME')}
                    name={'name'}
                    value={state.name}
                    error={error.name}
                    handleChange={handleChange1}
                    type={'text'}
                    inputProps={{ maxLength: 100 }}
                    required
                  />
                )}
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: 2 }}>
                {state.name === 'Free Trial' ? (
                  <SubscriptionDropdown
                    label={t('SELECTSUBSCRIPTIONTYPEMANDATORY')}
                    value={'Free Trial'}
                    disabled={true}
                    error={error.periodValue}
                    name="Subscription Type"
                    // data={data}
                    handleChange={(e) => handleChangeDropdown(e)}
                  />
                ) : (
                  <SubscriptionDropdown
                    label={t('SELECTSUBSCRIPTIONTYPEMANDATORY')}
                    value={state.subscriptionType}
                    disabled={true}
                    error={error.periodValue}
                    name="Subscription Type"
                    // data={data}
                    handleChange={(e) => handleChangeDropdown(e)}
                  />
                )}
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12}>
                {periodValue === 'Yearly' ? (
                  <SubscriptionTextfield
                    label={t('CUSTDATABLE08')}
                    name={'price'}
                    value={state.annual_price}
                    error={error.price}
                    handleChange={handleChange2}
                    handleCurrencyChange={handleCurrencyChange}
                    type={'text'}
                    disabled={true}
                    periodValue={state.subscriptionType}
                    validation="numeric"
                    edit={true}
                    inputProps={{ maxLength: 6 }}
                    required={true}
                  />
                ) : (
                  <SubscriptionTextfield
                    label={t('CUSTDATABLE08')}
                    name={'price'}
                    value={state.monthly_price}
                    error={error.price}
                    handleChange={handleChange2}
                    handleCurrencyChange={handleCurrencyChange}
                    type={'text'}
                    disabled={true}
                    periodValue={state.subscriptionType}
                    validation="numeric"
                    edit={true}
                    inputProps={{ maxLength: 6 }}
                    required={true}
                  />
                )}
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12}>
                <CustomTextfeild
                  label={t('SUBSCRIPTIONVISITIONCREATION')}
                  name={'visitLimit'}
                  value={visitorCount}
                  error={error.visitorCount}
                  handleChange={handleVisitorCount}
                  type={'number'}
                  validation="numeric"
                  disabled={true}
                  required={true}
                />
              </Grid>
            </Grid>
          </Grid>{' '}
        </Paper>
        <Paper sx={{ mt: 4, maxHeight: '-10px' }}>
          <Grid style={{ display: 'flex', backgroundColor: 'white' }}>
            <Grid
              sx={{ width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' } }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '30px',
              }}
            >
              <StyledBadge>
                <PerfectScrollbar
                  options={{ wheelPropagation: true }}
                  style={{ width: '570px', height: '550px' }}
                >
                  <FormControl component="fieldset" variant="standard"
                  >
                    
                    <h4 style={{ color: '#0F5A9C' }}>
                     {t('SUBSCRIPTIONINFROMATION')}
                    </h4>
                    {allFeatures
                      ? allFeatures.map((feature) => {
                          return (
                            <FormGroup key={feature.id}>
                              <Grid className="leftSwitch"></Grid>
                              <Grid container alignItems="center">
                              <Grid item xs={6}>
                              <FormLabel
                                component="legend"
                                className="formLabel"
                                style={{
                                  maxWidth: '330px',
                                  wordWrap: 'break-word',
                                  textAlign:'center'
                                }}
                              >
                                {feature.name}
                              </FormLabel>
                              </Grid>

                              <Grid item xs={6}>
                              <Android12Switch
                                checked={toggle[feature.name]}
                              sx={{ marginLeft: '20px' }}
                                disabled={true}
                                onChange={(event) =>
                                  handleToggleChange(
                                    event,
                                    feature.id,
                                    feature.name,
                                  )
                                }
                                name={feature.name}
                              />
                              </Grid>
                              </Grid>
                            </FormGroup>
                          )
                        })
                      : null}
                  </FormControl>
                </PerfectScrollbar>
              </StyledBadge>
            </Grid>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <Grid
              sx={{ width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' } }}
              style={{ padding: '24px' }}
            >
              <Grid container spacing={5}>
                <Grid item xs={2.1}>
                  <br />
                  <Card
                    sx={{
                      minWidth: 0,
                      height: '480px',
                      width: '360px',
                      marginLeft: '220px',
                    }}
                  >
                    {(() => {
                      switch (true) {
                        case state.annual_price ||
                          (state.monthly_price > 0 && state.annual_price) ||
                          state.monthly_price <= 100:
                          return (
                            <CardMedia
                              component="img"
                              src={CardOne}
                              alt="green iguana"
                              sx={{
                                marginTop: '-13px',
                                marginLeft: '-19px',
                                width: '400px',
                              }}
                            />
                          )
                        case state.annual_price ||
                          (state.monthly_price > 100 && state.annual_price) ||
                          state.monthly_price <= 200:
                          return (
                            <CardMedia
                              component="img"
                              src={CardTwo}
                              alt="green iguana"
                              sx={{
                                marginTop: '-13px',
                                marginLeft: '-19px',
                                width: '400px',
                              }}
                            />
                          )

                        case state.annual_price ||
                          (state.monthly_price > 200 && state.annual_price) ||
                          state.monthly_price <= 300:
                          return (
                            <CardMedia
                              component="img"
                              src={CardThree}
                              alt="green iguana"
                              sx={{
                                marginTop: '-13px',
                                marginLeft: '-19px',
                                width: '400px',
                              }}
                            />
                          )

                        case state.annual_price ||
                          (state.monthly_price > 300 && state.annual_price) ||
                          state.monthly_price <= 400:
                          return (
                            <CardMedia
                              component="img"
                              src={CardFour}
                              alt="green iguana"
                              sx={{
                                marginTop: '-13px',
                                marginLeft: '-19px',
                                width: '400px',
                              }}
                            />
                          )

                        default:
                          return (
                            <CardMedia
                              component="img"
                              src={CardFive}
                              alt="green iguana"
                              sx={{
                                marginTop: '-13px',
                                marginLeft: '-19px',
                                width: '400px',
                              }}
                            />
                          )
                      }
                    })()}
                    <div
                      style={{
                        position: 'absolute',
                        color: 'white',
                        top: 115,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '19px',
                        opacity: 1,
                        font: 'normal normal normal 19px/17px Roboto',
                      }}
                    >
                      {state.subscriptionType}
                    </div>
                    <Tooltip title={`${state.name}`} placement="bottom-start">
                      <div
                        style={{
                          position: 'absolute',
                          color: 'white',
                          top: 21,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '33px',
                          opacity: 1,
                          font: 'normal normal normal 30px/32px Roboto',
                        }}
                      >
                        {handleTextPrice(state.name)}
                      </div>
                    </Tooltip>
                    {periodValue === 'Yearly' ? (
                      <div
                        style={{
                          position: 'absolute',
                          color: 'white',
                          top: 59,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '40px',
                          font: 'normal normal bold 40px/48px Roboto',
                        }}
                      >
                        {'$' + state.annual_price}
                      </div>
                    ) : (
                      <div
                        style={{
                          position: 'absolute',
                          color: 'white',
                          top: 59,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '40px',
                          font: 'normal normal bold 40px/48px Roboto',
                        }}
                      >
                        {'$' + state.monthly_price}
                      </div>
                    )}

                    {featureNamesForEdit[0] !== undefined ? (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginLeft: '30px',
                          position: 'absolute',
                          color: 'black',
                          top: 220,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <DoneIcon
                            sx={{
                              fontSize: '23px',
                              color: 'green',
                              marginRight: '5px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={`${featureNamesForEdit[0]}`}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {handleTextOverflow(featureNamesForEdit[0])}
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : null}

                    {featureNamesForEdit[1] !== undefined ? (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginLeft: '30px',
                          position: 'absolute',
                          color: 'black',
                          top: 260,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <DoneIcon
                            sx={{
                              fontSize: '23px',
                              color: 'green',
                              marginRight: '5px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={`${featureNamesForEdit[1]}`}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {handleTextOverflow(featureNamesForEdit[1])}
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : null}

                    {featureNamesForEdit[2] !== undefined ? (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginLeft: '30px',
                          position: 'absolute',
                          color: 'black',
                          top: 300,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <DoneIcon
                            sx={{
                              fontSize: '23px',
                              color: 'green',
                              marginRight: '5px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={`${featureNamesForEdit[2]}`}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {handleTextOverflow(featureNamesForEdit[2])}
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : null}

                    {featureNamesForEdit[3] !== undefined ? (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginLeft: '30px',
                          position: 'absolute',
                          color: 'black',
                          top: 340,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <DoneIcon
                            sx={{
                              fontSize: '23px',
                              color: 'green',
                              marginRight: '5px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={`${featureNamesForEdit[3]}`}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {handleTextOverflow(featureNamesForEdit[3])}
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : null}

                    {featureNamesForEdit[4] !== undefined ? (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginLeft: '30px',
                          position: 'absolute',
                          color: 'black',
                          top: 380,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <DoneIcon
                            sx={{
                              fontSize: '23px',
                              color: 'green',
                              marginRight: '5px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={`${featureNamesForEdit[4]}`}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {handleTextOverflow(featureNamesForEdit[4])}
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : null}

                    <CardContent></CardContent>
                    <CardActions>
                      {periodValue === 'Yearly' ? (
                        <ViewSubscription
                          featureNameList={featureNamesForEdit}
                          planName={state.name}
                          price={state.annual_price}
                          visitorCount={visitorCount}
                          subscriptionType={state.subscriptionType}
                          open={openView}
                        />
                      ) : (
                        <ViewSubscription
                          featureNameList={featureNamesForEdit}
                          planName={state.name}
                          price={state.monthly_price}
                          visitLimit={visitorCount}
                          subscriptionType={state.subscriptionType}
                          open={openView}
                        />
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
              <br></br>
              {/* <Grid container xs={12} justifyContent="center" mt={2}> */}
              <Grid item sx={{ mr: 2, left: '200px', bottom: '-23px' }}>
                <SubmitButtons onClick={handleSubmit} variant="contained">
                  <Typography>{t('COMMON007')}</Typography>
                </SubmitButtons>
              </Grid>
              <Grid item sx={{ ml: 2, left: '400px', bottom: '30px' }}>
                <CancelButtons
                  color="secondary"
                  onClick={(e) => cancel()}
                  variant="contained"
                >
                  <Typography>{t('COMMON008')}</Typography>
                </CancelButtons>
              </Grid>
              {/* </Grid> */}
            </Grid>
          </Grid>
        </Paper>
      </>
    </>
  )
}
export default EditSubscription
