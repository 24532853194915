// OAuth-client
export const REACT_LOGIN = '/vis/login'
export const REACT_VERIFY_COMPANY = '/vis/verify-company'
export const REACT_LOGOUT = '/vis/login'

// Rect-Routes
export const REACT_URL_DASHBOARD = '/vis/dashboard'
export const REACT_URL_INVITATIONS = '/vis/v2/invitations'
export const REACT_URL_REGISTRATIONS = '/vis/v2/registrations'
export const REACT_URL_APPROVAL = '/vis/approvals'
export const REACT_URL_BLOCKED_VISITORS = '/vis/blacklistVisitors'
export const REACT_URL_VISITORLOGS = '/vis/logs'
export const REACT_URL_USERPROFILE = '/vis/userProfile'
export const REACT_URL_COMPANYRPROFILE = '/vis/companyProfile'
export const REACT_URL_PERSON = '/vis/person'
export const REACT_URL_ROLE = '/vis/role'
export const REACT_URL_SITE = '/vis/site'
export const REACT_URL_SITETYPE = '/vis/siteType'
export const REACT_URL_ADDRESSTYPE = '/vis/addressType'
export const REACT_URL_CHECKINOUTDEVICES = '/vis/checkInOutDevices'
export const REACT_URL_PURPOSETYPE = '/vis/purposeType'
export const REACT_URL_WATCHLIST = '/vis/watchlist-template'
export const REACT_URL_CHECKLIST = '/vis/checklist-template'
export const REACT_URL_VISITORACCESSLEVEL = '/vis/visitorAccesslevel'
export const REACT_URL_CHECKLIST_ITEM = '/vis/checklist-items'
export const REACT_URL_RESTRICTTYPE = '/vis/visitorRestrictType'
export const REACT_URL_ADDDEVICES = '/vis/addDevice'
export const REACT_URL_ADDCHECKOUT = '/vis/addCheckOut'
export const REACT_URL_EMERGENCYMESSAGE = '/vis/emergencyMessage'
export const REACT_URL_FLOWCHART = '/vis/flowChart'
export const REACT_VISITOR_QR_PAGE = '/vis/qr-code'
export const REACT_DEVICE_LIST = '/vis/deviceList'
export const REACT_DEVICE_INFO = '/vis/deviceInfo'
export const REACT_URL_DEVICELIST = '/vis/device-list'
export const REACT_DEVICE_ADD_INSTRUCTION = '/vis/device-add-instruction'
export const REACT_ADD_DEVICE = '/vis/device-add'
export const REACT_ACCESSLEVELS_LIST = '/vis/accessLevelsList'
export const REACT_TIME_SLOTS = '/vis/timeslots'
export const REACT_VIEW_DEVICE = '/vis/view-device'
export const REACT_MANAGE_ACCESSLEVELS = '/vis/manageAccessLevels'
export const REACT_ADD_TIMESLOTS = '/vis/addTimeSlots'
export const REACT_ADD_DEVICE_TO_ACCESSLEVEL = '/vis/access-addDevice'
export const REACT_UPDATE_TIMESLOT = '/vis/update-timeSlot'
export const REACT_URL_ZONE = '/vis/zone'
export const REACT_URL_WALKINREGISTRATION = '/vis/v2/walkinRegistartion'
export const REACT_URL_REPORTINVITATION = '/vis/v2/Report-Invitation'
export const REACT_URL_REPORTREGISTRATION = '/vis/v2/Report-SelfRegistartion'
export const REACT_URL_CARDTEMPLATE = '/vis/card-template'
export const REACT_URL_ADD_CARD_TEMPLATE = '/vis/add-template'
export const REACT_URL_ADDITIONAL_SETTINGS = '/vis/additional-settings'
export const REACT_URL_VISITORTYPE = '/vis/visitorType'
export const REACT_URL_HEALTHQUESTIONARIES = '/vis/healthQuestionarie'
export const REACT_URL_CHECKLISTTEMPLATE_USA = '/vis/checklistTemplate'
export const REACT_VISITOR_ACCESS_LEVEL_LIST = '/vis/access-level-list'
export const REACT_URL_BLACKLISTTYPE = '/vis/blacklistType'
export const REACT_URL_USERROLE = '/vis/role'
export const REACT_URL_PURPOSETYPE_USA = '/vis/purposeTypes'
export const REACT_DEALER_DASHBOARD = '/vis/dealer-dashboard'
export const REACT_DEALER = '/vis/dealer'
export const REACT_CREATE_DEALER = '/vis/create-dealer'
export const REACT_SUBSCRIPTION_MANAGEMENT = '/vis/subscription-management'
export const REACT_DEALER_DETAILS = '/vis/dealer-details'
export const REACT_MONTHLY_INCOME = '/vis/monthly-income'
export const REACT_TRANSACTION_DETAILS = '/vis/transaction-details'
export const REACT_SETTINGS_LEVEL = '/vis/settings-level'
export const REACT_URL_EMPLOYEE = '/vis/person'
export const REACT_URL_DAILYREPORT = '/vis/dailyReports'
export const REACT_URL_INVITATIONREPORT = '/vis/invitationReport'
export const REACT_URL_WALKINREGISTRATIONREPORT = '/vis/walkInReport'
export const REACT_URL_SELFREGISTRATIONREPORT = '/vis/selfRegistrationReport'
export const REACT_URL_VISITINVITE = '/vis/invitations'
export const REACT_URL_WALKINVISIT = '/vis/walkInRegistration'
export const REACT_URL_BLACKLISTVISITORS = '/vis/blacklistVisitors'
export const REACT_URL_WATCHLISTGLOBAL = '/vis/watchlist'
export const REACT_URL_USER_LIST = '/vis/user-list'
export const REACT_URL_OFFENCE = '/vis/offence'
export const REACT_URL_DEALER_DETAILS = '/vis/dealer-details'
export const REACT_URL_CUSTOMER_TRANSACTION_DETAILS =
  '/vis/Customer-Transaction-Details'
export const REACT_URL_MONTHLY_INCOME = '/vis/monthly-income'
export const REACT_URL_CREATE_LEVEL = '/vis/Create-level'
export const REACT_URL_LEVEL = '/vis/level'
export const REACT_URL_VIEW_CUSTOMER_DETAILS = '/vis/view-dealer-details'
export const REACT_SUBSCRIPTION_CARDS = '/vis/subscriptionCards'
export const REACT_EDIT_DEALER = '/vis/edit-dealer'
export const REACT_DELIVERY_REPORT = '/vis/deliveryReport'
export const REACT_SMS_TEMPLATE = '/vis/sms-template'
export const REACT_SMS_EDIT = '/vis/edit-sms'
export const REACT_ATLAS_INTEGRATION = '/vis/atlasIntegration'
export const REACT_CREATE_CUSTOMER = '/vis/createCustomer'
export const REACT_CUSTOMERS_LIST = '/vis/customerList'
export const REACT_VIEW_CUSTOMERS_DETAILS = '/vis/viewCustomerDetails'
export const REACT_VIEW_DEALER_DETAILS = '/vis/viewDealerDetails'
export const REACT_DEALER_REPORT = '/vis/dealersReport'
export const REACT_DEALER_CUSTOMER_REPORT = '/vis/dealerCustomersReport'
export const REACT_ADMIN_TRANSACTIONS = '/vis/transactions'
export const REACT_DEALER_TRANSACTION = '/vis/dealerTransaction'
export const REACT_CUSTOMER_REPORT = '/vis/customerReport'
export const REACT_CUSTOMER_VIEW_REPORT = '/vis/customerViewReport'
export const REACT_CUSTOMER_TRANSACTION_VIEW_PAGE =
  '/vis/customerTransaction/viewpage'
export const REACT_CREATE_SUBSCRIPTION = '/vis/createProduct'
export const REACT_EDIT_SUBSCRIPTION = '/vis/Edit-Subscription'
export const REACT_ADMIN_DASHBOARD = '/vis/adminDashboard'
export const REACT_CUSTOMER_TRANSACTION_VIEW = '/vis/customerTransactionView'
export const REACT_CUSTOMER_REPORT_VIEW = '/vis/viewCustomerReport'
export const REACT_DASHBOARD_PANEL = '/vis/dashboardPanel'

export const REACT_URL_USERS = '/vis/users'
export const REACT_CREATE_USER = '/vis/user'
export const REACT_URL_DISCOUNTS = '/vis/discounts'
export const REACT_URL_PAYMENT_METHOD = '/vis/paymentMethod'
export const REACT_CREATE_DISCOUNT = '/vis/discount'
export const REACT_DATEFORMAT_ADMIN_DEALER = '/vis/dateformat/admin_dealer'
export const REACT_KEY_CONFIGURATION = '/vis/KeyConfiguration'
export const REACT_CREATE_KEY_CONFIGURATION = '/vis/Create-KeyConfiguration'
export const REACT_VIEW_KEY_CONFIGURATION = '/vis/View-KeyConfiguration'
export const REACT_EMAIL_REPORT = '/vis/email_report_create'
export const REACT_DEVICE_TYPE = '/vis/device_type'
export const REACT_CREATE_DEVICE_TYPE = '/vis/create-Device-Type'
export const REACT_SELF_REGISTRATION_LIST = '/vis/selfRegistrationList'
export const REACT_PLAN_PURCHASED = '/vis/purchasedPlans'
export const REACT_LOGIN_AUTHENTICATE = '/vis/loginAuthenticate'
export const REACT_UNAUTHORIZED = '/vis/unauthorized'
