import { Paper, Typography } from '@material-ui/core'
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ReactComponent as ProfileEditIcon } from '../../assets/ProfileEditIcon.svg'
import CustomDropdownPro from '../../components/CustomInputs/CustomDropdownPro'
import CustomPhone from '../../components/CustomInputs/CustomPhone'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import Toast from '../../components/ToastContainer/CustomToast'
import { getUserInfoPro, updateUserInfoPro } from '../../slices/User/UserSlice1'
import CommonUtil from '../../Util/CommonUtils'
import { countryData, gender } from '../Person/AddressFormData'
import UploadUserProfile from '../Person/UploadUserProfile'
import { useContext } from 'react'
import ProfileContext from '../../components/ProfileContext'
import { getCompanyById } from '../../slices/Company/CompanySlice'
import isoCountries from 'i18n-iso-countries';
import { ThemeContext } from '../../theme/ThemeContext';
import {ReactComponent as EditIcon} from '../../assets/USA_Images/login_images_visitor/EditIcon.svg'

function UserProfile() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  var maxDate = new Date()
  const [phoneInput, setPhoneInput] = useState('')
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: CommonUtil.getDateOfJoining(maxDate),
    dateOfJoining: CommonUtil.getDateOfJoining(maxDate),
    gender: '',
    profilePhoto: '',
    email: '',
    phone: '',
    verified: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    pincode: '',
    country: '',
    id: '',
    phoneCountryCode: '',
    userProfiles: '',
  })

  // const [photoFile, setPhotoFile] = useState('');

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    dateOfBirth: false,
    dateOfJoining: false,
    gender: false,
    profilePhoto: false,
    email: false,
    phone: false,
    verified: false,
    addressLine1: false,
    addressLine2: false,
    city: false,
    state: false,
    pincode: false,
    country: false,
    id: false,
  })

  const [profileImage, setProfileImage] = React.useState()
  const [profile, setProfile] = useState('')
  const [editMode, setEditMode] = useState(true)
  const [countries, setCountries] = React.useState([])
  const [Gender, setGender] = React.useState([])
  const [InavalidprofilePhoto, setInavalidprofilePhoto] = useState(false)
  const { setUserProfilePic } = useContext(ProfileContext)
  const { setUserProfile } = useContext(ProfileContext)
  const [selectedGender, setSelectedGender] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [country, setCountry] = useState('')
  const { darkMode } = useContext(ThemeContext);
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  useEffect(() => {
    setCountries(countryData)
    setGender(gender)
    setInavalidprofilePhoto(false)
    loadData()
  }, [])

  const loadData = () => {
    dispatch(getUserInfoPro(decoded_jwtToken.userId))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0000' || data.code === 'UASI0033') {
          setUser({
            ...user,
            ...data.data,
            phoneCountryCode: data.data.phone
              ? data.data.phone.split('-')[0]
              : '',
            phone: data.data.phone ? data.data.phone.split('-')[1] : '',
            userProfiles: data.data.imagePreSignedURL
          })
          setPhoneInput(data.data.phone)
          setProfile(data.data.imagePreSignedURL)
          const selectedGender = gender.find(
            (gender) => gender.name === data.data.gender,
          )
          setSelectedGender(selectedGender)
          const selectedCountry = countryData.find(
            (country) => country.name === data.data.country,
          )
          setSelectedCountry(selectedCountry)
          if (data.data.phone === "" || data.data.phone === null) {
            loadCompany()
          }
        } else {
          setUser([])
        }
      })
      .catch((er) => { })
  }

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.user.imagePreSignedURL)
    bodyFormData.append('file', profileImage)
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        // Toast("Profile Photo Saved", "success");
        setUserProfilePic(profile)
      })
      .catch(function (response) { })
  }

  const updateUser = () => {
    dispatch(updateUserInfoPro(user))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0002') {
          if (!user.profilePhoto.isEmptyString) {
            uploadProfilePhoto(data.data)
            setUserProfile(user)
            loadData()
          }
          Toast(data.message, 'success')
        } else {
          Toast(data.message, 'error')
        }
      })
      .catch((er) => { })
  }

  const handleProfile = (file) => {
    setUser({
      ...user,
      profilePhoto: user.firstName,
      userProfiles: file.base64,
    })
    setProfile(file.base64)
    setProfileImage(file.file)
  }

  const handleClickEdit = () => {
    setEditMode(editMode ? false : true)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setUser({
      ...user,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handleGenderChange = (event, value) => {
    setSelectedGender(value)
    setUser({
      ...user,
      gender: value.name,
    })
  }

  const handleCountryChange = (event, value) => {
    setSelectedCountry(value)
    setUser({
      ...user,
      country: value.name,
    })
  }

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value)
    if (value === '') {
      setUser({
        ...user,
        phoneCountryCode: '',
        phone: '',
      })
    } else {
      setUser({
        ...user,
        phoneCountryCode: '+' + data.dialCode,
        phone: value.toString().replace(data.dialCode, ''),
      })
    }
    setError({ phone: false })
  }


  const loadCompany = () => {
    dispatch(getCompanyById(decoded_jwtToken.companyId))
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setCountry(countryCode)
          } else {
            setCountry('us')
          }
        }
      })
      .catch((er) => { })
  }
  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }


  const onSubmit = () => {
    if (CommonUtil.isEmptyString(user.firstName)) {
      Toast(t('USERPROFSCREEN007'), 'error')
      setError({ ...error, firstName: true })
      return false
    }

    if (!CommonUtil.isValidName(user.firstName)) {
      Toast(t('FIRSTNAME'), 'error')
      setError({ ...error, firstName: true })
      return
    }

    if (CommonUtil.isEmptyString(user.email)) {
      setError({ ...error, email: true })
      Toast('EMAILVALIDATE', 'error')
      return
    }

    if (!CommonUtil.isValidEmails(user.email)) {
      setError({ ...error, email: true })
      Toast('DEALEREMAILERROR', 'error')
      return
    }
    if (!CommonUtil.isAlphaNumericSpace(user.pincode)) {
      Toast(t('BILLING_PINCODE_VALIDATION'), 'error')
      setError({ ...error, pincode: true })
      return
    }
    if (!InavalidprofilePhoto) {
      updateUser(user)
      setEditMode(true)
    } else {
      Toast('Image size should be less than 2MB', 'error')
    }
  }

  const onCancel = () => {
    setEditMode(true)
    loadData()
  }

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: darkMode ? "#021C31" : "#fff"
        }}
      >
        <Box sx={{ m: 2 }}>
          <Typography variant="h3" style={{ color: darkMode ? "#fff" : "#3D4977", marginLeft: darkMode ? '32px' : '0px' }}>{t('commonsUserProfile')}</Typography>
        </Box>
        <Grid>
          <div style={{ backgroundColor: darkMode ? '#0F273B' : '#F2F2F6', marginRight: darkMode ? '10px' : '0px' }}>
            <Tooltip
              title={
                <Typography fontSize={12} fontWeight={500}>
                  {t('COMMON005')}
                </Typography>
              }
            >
              <IconButton onClick={handleClickEdit}>
              {darkMode ? <EditIcon /> : <ProfileEditIcon />}     
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <Paper style={{ backgroundColor: darkMode ? "#0F273B" : "#fff", marginLeft: darkMode ? "31px" : "0px", marginRight: darkMode ? "10px" : "0px" }}>
        <Grid container p={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            md={4}
            sm={12}
          >
            <Grid xl={6}>
              <UploadUserProfile
                onsetProfile={(profile) => handleProfile(profile)}
                profilePhoto={profile}
                disabled={editMode}
              />
            </Grid>
          </Grid>
          <Grid container md={8} sm={12} spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={t('USER0001')}
                name={'firstName'}
                value={user.firstName}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                // validation='alpha-numeric'
                inputProps={{ maxLength: 25, }}
                required
              // inputColor={editMode ? 'red' : undefined}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={t('COMMON021')}
                name={'lastName'}
                value={user.lastName}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                // validation='alpha-numeric'
                inputProps={{ maxLength: 25 }}
              />
            </Grid>
            {/* <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'User ID'}
                name={'id'}
                value={user.id}
                disabled={true}
                handleChange={handleChange}
                type={'text'}
                error={error.id}
                helperText={error.id}
                validation='alphabets'
              />
            </Grid> */}
            <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
              <Autocomplete
                disabled={editMode}
                options={Gender}
                value={selectedGender}
                onChange={handleGenderChange}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('USERPROFSCREEN011')}
                    sx={{
                      '& .MuiInputBase-input': {
                        color: darkMode ? '#fff' : '#0F273B', // Set text color
                      },
                      '& .MuiInputLabel-root': {
                        color: darkMode ? '#fff' : '#0F273B', // Set label color based on dark mode
                      },
                      // Apply styles to the input when disabled
                      '& .MuiInputBase-input.Mui-disabled': {
                        color: '#B3B3B3', // Adjust text color for disabled state
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: '#B3B3B3', // Adjust label color for disabled state
                      },
                    }}
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                    },
                    '&:hover fieldset': {
                      borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                    },
                    // Override border color when disabled
                    '&.Mui-disabled fieldset': {
                      borderColor: '#B3B3B3', // Adjust border color for disabled state
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={t('COMMON042')}
                name={'email'}
                value={user.email}
                disabled={true}
                handleChange={handleChange}
                type={'text'}
                error={error.email}
                helperText={error.email}
                validation="email"
                required

              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
              <CustomPhone
                placeholder="Mobile"
                disabled={editMode}
                value={phoneInput}
                label={'Mobile'}
                specialLabel={false}
                country={country}
                inputProps={{ maxLength: 12, style: { color: 'red' } }}
                handleChange={(value, data, event, formattedValue) =>
                  handlePhoneChange(value, data, event, formattedValue)
                }
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={t('ADD001')}
                name={'addressLine1'}
                value={user.addressLine1}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                error={error.addressLine1}
                helperText={error.addressLine1}
                // validation='alphabets'
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={t('ADD002')}
                name={'addressLine2'}
                value={user.addressLine2}
                disabled={editMode}
                handleChange={handleChange}
                error={error.addressLine2}
                helperText={error.addressLine2}
                // validation='alphabets'
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={t('ADD005')}
                name={'city'}
                value={user.city}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                error={error.city}
                helperText={error.city}
                validation="alphabets"
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={t('ADD004')}
                name={'state'}
                value={user.state}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                error={error.state}
                helperText={error.state}
                // validation='alphabets'
                inputProps={{ maxLength: 30 }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
              <Autocomplete
                disabled={editMode}
                options={countries}
                value={selectedCountry}
                onChange={handleCountryChange}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label={t('ADD003')}
                    sx={{
                      '& .MuiInputBase-input': {
                        color: darkMode ? '#fff' : '#0F273B', // Set text color
                      },
                      '& .MuiInputLabel-root': {
                        color: darkMode ? '#fff' : '#0F273B', // Set label color based on dark mode
                      },

                      '& .MuiInputBase-input.Mui-disabled': {
                        color: '#B3B3B3', //  text color for disabled state
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: '#B3B3B3', //  label color for disabled state
                      },
                    }}
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                    },
                    '&:hover fieldset': {
                      borderColor: darkMode ? '#fff' : '#434547', //  border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: darkMode ? '#fff' : '#434547', //  border color when focused
                    },
                    // Override border color when disabled
                    '&.Mui-disabled fieldset': {
                      borderColor: '#B3B3B3', //  border color for disabled state
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={t('DEALERZIPCODE')}
                name={'pincode'}
                value={user.pincode}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
          </Grid>

          <Grid container lg={4}></Grid>
          {!editMode ? (
            <Grid container lg={8} justifyContent="center">
              <Grid pr={2} pt={4}>
                <SubmitButtons
                  disable={editMode}
                  variant="contained"
                  onClick={onSubmit}
                >
                  <Typography>{t('COMMON007')}</Typography>
                </SubmitButtons>
              </Grid>
              <Grid pt={4}>
                <CancelButtons
                  disable={editMode}
                  variant="contained"
                  color="secondary"
                  onClick={onCancel}
                >
                  <Typography>{t('COMMON008')}</Typography>
                </CancelButtons>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default UserProfile
