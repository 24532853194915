import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
    let query = "?isPagable=true&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    if (!CommonUtil.isEmptyString(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    return query;
};

const getBlackListType = (filter) => {
    return api.securedAxios().get('/web/getBlackListType' + buildQuery(filter));
}

const createBlackListType = (payload) => {
    return api.securedAxios().post('/web/createBlackListType', payload);
}

const updateBlackListType = (payload) => {
    return api.securedAxios().put('/web/updateBlackListType/' + payload.id, payload);
}

const deleteBlackListType = (id) => {
    return api.securedAxios().delete("/web/deleteBlackListType?id=" + id);
}

const blacklistTypeService = {
    getBlackListType,
    createBlackListType,
    updateBlackListType,
    deleteBlackListType,
}

export default blacklistTypeService;