import { Avatar, Grid, IconButton, Tooltip } from '@mui/material';
import React, {useContext} from 'react';
import FileBase64 from 'react-file-base64';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import cameraplus2 from '../../assets/AddPhotoIcon.svg'
import cameraDark from '../../assets/USA_Images/login_images_visitor/cameraDark.svg'
import { ThemeContext } from '../../theme/ThemeContext'

export default function UploadDocs(props) {
    const { darkMode } = useContext(ThemeContext);
    const [profile, setProfile] = React.useState(darkMode? cameraDark : cameraplus2);
    const [file, setFile] = React.useState({});
    
    const [state, setState] = React.useState({
        profilePhoto: '',
    });
    const handleProfile = (file) => {
        if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/bmp') {
            let fileSize = file.size.split(' ')[0];
            let size = parseInt(fileSize);
            if (size <= 1024) {
                setFile(file);
                setState({
                    ...state,
                    profilePhoto: file.base64.split(',')[1],
                });
                props.onsetProfile(file);
                setProfile(file.base64);
            } else {
                setFile('');
                //setProfile(state.profilePhoto);
                //props.onsetProfile(state.profilePhoto);
                toast.error('File size can not exceed 1MB');
            }
        } else {
            setFile('');
            //setProfile(state.profilePhoto);
            toast.error('Invalid file format');
        }
    };

    return (
        <>
            <Grid conatiner display={'flex'} direction='column' justifyContent='center' alignItems='center'>
                <div style={{ borderStyle: 'dashed ', borderRadius: '5%', margin: '15px', color: '#D2D2D2', border:'2px dashed' }}>
                    <Tooltip title={props.profilePhoto ? 'click to upload picture' : ''}>
                        <IconButton
                            variant='contained'
                            component='label'
                            style={{
                                position: 'relative',
                                width: '250px',
                                height: '180px',
                                font: 'normal normal normal 18px/24px Roboto',
                                letterSpacing: '0px',
                            }}
                            disabled={props.disabled}
                        >
                            {props.profilePhoto ? (
                                <Avatar className='ImageContent'
                                    alt='' src={props.profilePhoto}
                                    style={{
                                        height: '180px',
                                        width: '250px',
                                        zIndex: '99',
                                        borderRadius: '0%'
                                    }} />
                            ) : (
                                <>
                                    <Avatar
                                        className='ImageContent'
                                        alt=''
                                        src={profile}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            borderRadius: '0%',
                                        }}
                                        sx={[
                                            () => ({
                                                '& .MuiAvatar-img': {
                                                    width: '40px',
                                                    height: '33px',
                                                    textAlign: 'center',
                                                    objectFit: 'cover',
                                                    color: 'transparent',
                                                    textIndent: '10000px'
                                                },
                                            }),
                                        ]}
                                    />
                                    <div style={{ fontSize: '14px', position: 'absolute', top: '68%', left: '30%', zIndex: '1', color: '#cdcdcd', justifyContent: 'center' }}>{props.imageName}</div>
                                    <Grid style={{ display: 'none' }}>
                                        <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                                    </Grid>
                                </>
                            )}
                            <Grid style={{ display: 'none' }}>
                                <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                            </Grid>
                        </IconButton>
                    </Tooltip>
                </div>
                {/* <Grid direction='row' display='flex' alignItems='flex-end' style={{ color: '#C3C5C7', opacity: 1, fontSize: '12px', paddingTop: '34px', justifyContent: 'center' }}>
          Allowed *.jpeg, *.jpg, *.png max size of 1 MB
        </Grid> */}
            </Grid>
        </>
    );
}

UploadDocs.defaultProps = {
    disabled: false,
};
