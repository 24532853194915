import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import sucess_img1 from '../../assets/USA_Images/login_images/visitReject.svg'
import vis_sucess_img1 from '../../assets/visitReject.svg'
import Toast from '../../components/ToastContainer/CustomToast'

const useStyles = makeStyles((theme) => ({
  submit: {
    width: '350px',
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  },
  success: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  footer: {
    bottom: '0px',
    padding: '40px',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
  },
}))

function RejectVisit() {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [code, setCode] = useState('')
  const history = useHistory()

  const [payload, setPayload] = useState({
    visitId: '',
  })

  useEffect(() => {
    loadData()
  }, [])

  window.history.pushState(null, null, window.location.href)
  window.onpopstate = function () {
    window.history.go(1)
  }

  const loadData = () => {
    var urlValue = window.location.href
    var url = new URL(urlValue)
    var code = url.searchParams.get('code')
    setCode(code)
    axios
      .get('/web/visit/validate/' + code)
      .then((data) => {
        if (data.data.code === 'CVSI0000') {
          axios({
            url: '/web/visit/reject/' + code,
            method: 'put',
            headers: {
              'Content-Type': 'application/json',
              'Accept-language': localStorage.getItem('i18nextLng'),
              Application: 'mysmartguardplus',
              'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          }).then((res) => {
            if (res.data.code === 'CVAI1029') {
            } else {
              Toast(res.data.message, 'error')
            }
          })
        }
      })
      .catch((er) => {})
  }

  return (
    <Grid className={classes.success}>
      <Grid container alignItems="center">
        <Grid item className="logo-center">
          <img
            src={
              process.env.REACT_APP_ENVIRONMENT === 'USA'
                ? sucess_img1
                : vis_sucess_img1
            }
            alt="logo"
            style={{ width: '300px' }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '25px', marginTop: '10px' }}>
        <Typography
          variant="h3"
          align="center"
          style={{ color: '#464646', fontWeight: '500' }}
        >
          Visit Rejected Successfully
        </Typography>
      </Grid>

      <Grid className={classes.footer}>
        <Typography style={{ fontSize: '10px' }} color="textSecondary">
          {t('COPY_RIGHT_2024')}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default RejectVisit
