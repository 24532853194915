import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../theme/ThemeContext';
import EmergencyDoorDialog from "./EmergencyDoorDialog";
export default function EmergencyDoor(props) {
  const { open, handleClose } = props;
  const { darkMode } = useContext(ThemeContext);
  const { t } = useTranslation()

  return (
    <>
      <EmergencyDoorDialog
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
            {t('SELECT_DOOR')}
          </span>
        }

        acceptText={t('CONTINUE_BUTTON')}
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        fullWidth={true}
        open={open}
        onClose={handleClose}
      >
      </EmergencyDoorDialog>
    </>
  );
}
