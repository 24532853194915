import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter, page, rowsPerPage) => {

    let query = "?isPagable=true&pageNumber=" + page + "&pageSize=" + rowsPerPage;

    if (!CommonUtil.isEmptyString(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    if (!CommonUtil.isEmptyString(filter.visitorTypeId)) {
        query = query + "&visitorTypeId=" + filter.visitorTypeId;
    }
    return query;
};

const getCardTemplate = (payload) => {
    return api
        .securedAxios()
        .get(
            "/web/getCardTemplate" + buildQuery( payload.filter, payload.page, payload.rowsPerPage)
        );
};

const getCardTemplateById =(id) => {
    return api.securedAxios().get("/web/getCardTemplateById/" + id)
}

const createCardTemplate = (payload) => {
    // alert("slice"+ JSON.stringify(payload))
    return api.securedAxios().post("/web/createCardTemplate", payload);
};

const editCardTemplate = (payload) => {
    return api.securedAxios().put("/web/updateCardTemplate/" + payload.id, payload);
};

const editCardTemplatePrintStatus = (payload) => {
    return api.securedAxios().put("/web/updatePrintStatus/" + payload.id, payload);
};

const removeCardTemplate = (idsOrCodes) => {
    return api.securedAxios().delete("/web/deleteCardTemplate?id=" + idsOrCodes);
}

const CardTemplateService = {
    getCardTemplate,
    createCardTemplate,
    editCardTemplate,
    removeCardTemplate,
    getCardTemplateById,
    editCardTemplatePrintStatus
};

export default CardTemplateService;
