import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import PerpouseTypeService from '../../services/PurposeTypeUSA/PurposeTypeUSAService'

const initialState = []
export const listVisitorTypeZlink = createAsyncThunk(
  'visitType/',
  async (payload) => {
    const res = await PerpouseTypeService.getAll(payload)
    return res.data
  },
)

export const addPurposeTypeZlink = createAsyncThunk(
  'visitType/add',
  async (payload) => {
    const res = await PerpouseTypeService.addPurposeTypeZlink(payload)
    return res.data
  },
)

export const updateVisitorType = createAsyncThunk(
  'visitType/update',
  async (payload) => {
    const res = await PerpouseTypeService.updateVisitorType(payload)
    return res.data
  },
)

const PerpouseType = createSlice({
  name: 'visitType',
  initialState,
  extraReducers: {
    [listVisitorTypeZlink.fulfilled]: (state, action) => {
      if (action.payload.code === '') {
        return action.payload.data
      } else {
        return []
      }
    },
  },
})

const { reducer } = PerpouseType
export default reducer
