import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, Grid } from "@mui/material";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as QuestionIcon } from "../../../src/assets/Question.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
},
'& .MuiDialogActions-root': {
    padding: theme.spacing(2),
},
'& .MuiDialog-paper': {
    overflowY: 'visible',
    borderRadius: '14px',
    minWidth: '900px',
},
}));

const styleDialog = {
    '& .MuiDialog-paper': {
      padding: '12px 24px',
      position: 'absolute',
      top: '9em',
      minWidth: '60%',
    },
  };

const BootstrapDialogTitle = ({ children, onClose, ...props }) => {

  return (
    <DialogTitle variant="h4" sx={{ m: 0, p: 2, fontSize: '20px', color: '#242424' }} {...props}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
  );
};


function WatchlistInfo({ heading, description, children, ...props }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const handleClickOpen = () => {
      setOpen(true);
  };
  const handleClose = () => {
      setOpen(false);
  };
  useEffect(() => {
  }, []);

  return (
    <div>
      <Grid onClick={handleClickOpen}>
        <QuestionIcon />
      </Grid>
      <Grid>
        <BootstrapDialog
          sx={styleDialog}
          onClose={handleClose}
          open={open}
        >
            <BootstrapDialogTitle onClose={handleClose}>
           {t('Watchlist info')}
          </BootstrapDialogTitle>

          <DialogContent >
          <Typography variant='subtitle1' gutterBottom component='div'>
          When the people you have added to the followers list are invited, the system will send notifications to the corresponding contacts you have preset. This allows you to keep track of the visits of important people.
        </Typography>
          </DialogContent>

        </BootstrapDialog>
      </Grid>
    </div>
  );
}

export default WatchlistInfo;