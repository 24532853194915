import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UrgentMessagesService } from '../../services/UrgentMessages/UrgentMessagesService';

export const createUrgentMessage = createAsyncThunk('urgentMessage/create', async (payload) => {
    const res = await UrgentMessagesService.createUrgentMessage(payload);
    return res.data;
})

export const listUrgentMessages = createAsyncThunk('urgentMessage/create', async (filter) => {
    const res = await UrgentMessagesService.listUrgentMessages(filter);
    return res.data;
})