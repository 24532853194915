import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import './custom.css'
import { useHistory, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import sucess_img1 from '../../assets/USA_Images/login_images/passwordSuccess.svg'
import vis_sucess_img1 from '../../assets/images/onboard/sucess_img1.svg'
import BackgroundImg from '../../assets/USA_Images/login_images_visitor/img8.svg'

const useStyles = makeStyles((theme) => ({
  submit: {
    width: '350px',
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  },
  success: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  footer: {
    bottom: '0px',
    padding: '40px',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
  },
  fullScreenContainer: {
    height: '100vh',
    width: '100vw',
    position: 'relative',
  },
}))

function PasswordSuccess(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const backgroundStyle =
  {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
  function handleClick(e) {
    e.preventDefault()
    history.push('/vis/login')
  }

  return (
    <Grid container className={classes.fullScreenContainer} style={backgroundStyle}>
      <Grid className={classes.success}>
      <Grid container alignItems="center">
        <Grid item className="logo-center">
          <img
            src={
              process.env.REACT_APP_ENVIRONMENT === 'USA'
                ? sucess_img1
                : vis_sucess_img1
            }
            alt="logo"
            style={{ width: '300px' }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '25px', marginTop: '10px' }}>
        <Typography
          variant="h2"
          align="center"
          style={{ color: '#fff', fontWeight: '500' }}
        >
          Success!
        </Typography>
      </Grid>
      <Grid>
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          gutterBottom
          style={{color: '#fff'}}
        >
          Your account has been reset successfully. Click sign in button.
        </Typography>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs>
          <Box mt={3} textAlign="center">
            <Button onClick={handleClick} fullWidth variant="contained">
              {t('LOGINFORM0002')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid className={classes.footer}>
        <Typography style={{ fontSize: '10px', color: '#fff' }} color="textSecondary">
          {t('COPY_RIGHT_2024')}
        </Typography>
      </Grid>
      </Grid>
    </Grid>
  )
}
export default withRouter(PasswordSuccess)
