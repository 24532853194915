import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import blacklistVisitorService from '../../services/BlacklistVisitor/BlacklistVisitorService';

const initialState = [];

export const listAllBlacklistVisitors = createAsyncThunk('listAllBlacklistVisitors/blacklist', async (payload) => {
    const res = await blacklistVisitorService.listAllBlacklistVisitors(payload);
    return res.data;
}); 

export const blacklistVisitor = createAsyncThunk('blacklistVisitor/blacklist', async (payload) => {
    const res = await blacklistVisitorService.blacklistVisitor(payload);
    return res.data;
}); 

export const unblacklistVisitor = createAsyncThunk('unblacklistVisitor/unblacklist', async (payload) => {
    const res = await blacklistVisitorService.unblacklistVisitor(payload);
    return res.data;
}); 

const blacklistVisitorSlice = createSlice({
    name: 'blacklistVisitor',
    initialState,
    extraReducers: {
        [listAllBlacklistVisitors.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = blacklistVisitorSlice;
export default reducer;