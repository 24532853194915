import api from '../../config/http-common'

const getAccountStatics = (payload) => {
  return api.securedAxios().get('/web/account/statics?cascadeLimit=' + 2)
}

const getAccountList = (payload) => {
  return api
    .securedAxios()
    .post(
      '/web/accounts?cascadeLimit=' +
        payload.cascadeLimit +
        '&pageNumber=' +
        payload.page.pageNumber +
        '&pageSize=' +
        payload.page.pageSize,
      payload.accountDTO,
    )
}

const adminDashboardService = {
  getAccountStatics,
  getAccountList,
}

export default adminDashboardService
