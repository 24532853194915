import { Box, Link } from '@mui/material'
import Search from '../../components/SearchTab/Search'
import React, { useEffect, useState } from 'react'
import VisitorPrposeTypeFilterForm from './VisitorPrposeTypeFilterForm'
import DataTable from '../../components/DataTable/DataTable'
import { listVisitorType } from '../../slices/VisitTypeSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import AddPurposeType from './AddPurposeType'
import PurposeTypeViewCard from './PurposeTypeViewCard'
import EditWatchlistIcon from '../Watchlist/EditWatchlistIcon'
import AccessLevelIcon from './AccessLevelIcon'
import { REACT_URL_VISITORACCESSLEVEL } from '../../actions/EndPoints'
import { useHistory } from 'react-router-dom'
import HeaderToolbar from '../../components/HeaderToolbar'
import AddVisitorPurposeTypeIcon from '../../assets/union.svg'
import CommonUtil from '../../Util/CommonUtils'
import { toast } from 'react-toastify'
import { listVisitorTypeZlink } from '../../slices/PurposeTypeUSA/PurposeTypeUSASlice'

export default function PurposeType(props) {
  const [clear, setClear] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openAccessLevelDialog, setOpenAccessLevelDialog] = useState(false)
  const [totalRecords, setTotalRecords] = useState(1)
  const [rows, setRows] = useState([])

  const zlink = localStorage.getItem('redirectFrom')

  const [filter, setFilter] = React.useState({
    id: '',
    // code: "",
    name: '',
    description: '',
    isPageable: true,
  })

  const defaultFilter = {
    id: '',
    // code: "",
    name: '',
    description: '',
  }

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  const [selectedObject, setSelectedObject] = useState({
    id: '',
    // code: '',
    name: '',
    description: '',
  })
  const handleEditVisitPurposeType = (params) => {
    setOpenEditDialog(!openEditDialog)
    setSelectedObject(params)
  }

  const handleAccessLevel = (params) => {
    setOpenAccessLevelDialog(!openAccessLevelDialog)
    setSelectedObject(params)
    if (!CommonUtil.isEmptyArray(params.accessLevelIds)) {
      history.push({
        pathname: REACT_URL_VISITORACCESSLEVEL,
        state: { data: params },
      })
    } else {
      toast.warning(
        'No access level Data for ' + params.name + ' visitor purpose. ',
      )
      history.push({
        pathname: REACT_URL_VISITORACCESSLEVEL,
        state: { data: params },
      })
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    loadData(filter, paging)
    setOpen(false)
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging])

  const loadData = (filter, paging) => {
    const payload = {
      id: filter.id,
      // code: filter.code,
      name: filter.name,

      pageNumber: paging.page,
      pageSize: paging.rowsPerPage,
      isPageable: true,
    }
    setSelectedObject({})
    dispatch(listVisitorTypeZlink(payload))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          setTotalRecords(data.data.totalCount)
          setRows(data.data.purposeTypes)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
        } else {
          setRows([])
        }
      })
      .catch((er) => {})
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.checklistTemplateName != undefined
          ? test.checklistTemplateName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
          : test.checklistTemplateName
          ? test.checklistTemplateName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
          : '') ||
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        // || (test.code ? test.code.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        test.description.toLowerCase().includes(searchedVal.toLowerCase())
      )
    })
    setRows(filteredRows)
  }

  const applyFilter = (filterData) => {
    setClear(true)
    setPaging(defaultPaging)
    setFilter({
      id: filterData.visitorTypeCode,
      // code: filterData.code,
      name: '',
    })
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const columns = React.useMemo(() => [
    {
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>
      },
    },
    { field: 'name', headerName: 'Purpose', flex: 1, width: 10 },
    {
      field: 'templateId',
      headerName: t('VISTYPE002'),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.templateId !== null ? params.row.templateId.name : '',
    },
    {
      field: 'description',
      headerName: t('RestrictTypeForm005'),
      flex: 1,
      width: 10,
    },
    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <PurposeTypeViewCard
              title={'Purpose Type Details'}
              rowData={params.row}
            />
          }
          label="View"
        />,
        <GridActionsCellItem
          icon={<EditWatchlistIcon />}
          label="Edit"
          onClick={() => handleEditVisitPurposeType(params.row)}
        />,
        <GridActionsCellItem
          icon={<EditWatchlistIcon />}
          label="Edit"
          onClick={() => handleEditVisitPurposeType(params.row)}
        />,
        !zlink ? (
          <>
            <GridActionsCellItem
              icon={<AccessLevelIcon />}
              label="Access Level"
              onClick={() => handleAccessLevel(params.row)}
            />
          </>
        ) : (
          <>
            <GridActionsCellItem
              icon={<AccessLevelIcon />}
              label="Access Level"
              onClick={() => handleAccessLevel(params.row)}
            />
          </>
        ),
      ],
    },
  ])

  return (
    <>
      {!zlink ? (
        <>
          <HeaderToolbar
            src={AddVisitorPurposeTypeIcon}
            onClick={handleOpen}
            title=" Purpose Type"
            tooltipTitle="Add Purpose"
          />
        </>
      ) : (
        <>
          <HeaderToolbar
            src={AddVisitorPurposeTypeIcon}
            onClick={handleOpen}
            title=" Visiting Purpose"
            tooltipTitle="Add Purpose"
          />
        </>
      )}

      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item p={1}>
          <VisitorPrposeTypeFilterForm
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <AddPurposeType
          open={open}
          selectedObject={selectedObject}
          handleClose={() => handleClose()}
        />
      )}

      {openEditDialog && (
        <AddPurposeType
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false)
            handleClose(loadData(filter, paging))
            setSelectedObject({
              id: '',
              // code: '',
              name: '',
              description: '',
            })
          }}
        />
      )}
    </>
  )
}
