import { Box, Link, Tooltip } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import DataTable from '../../../components/DataTable/DataTable'
import AddSiteIcon from '../../../assets/AddSiteIcon.svg'
import { useDispatch } from 'react-redux'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import AddSiteType from './AddSiteType'
import FilterSiteType from './FilterSiteType'
import Search from '../../../components/SearchTab/Search'
import { getSiteTypeList } from '../../../slices/Site/SiteTypeSlice'
import DeleteIcon from '../../Watchlist/DeleteIcon'
import DeleteSiteType from './DeleteSiteType'
import EditWatchlistIcon from '../../Watchlist/EditWatchlistIcon'
import HeaderToolbar from '../../../components/HeaderToolbar'
import SiteTypeViewDetails from './SiteTypeViewDetails'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../../../theme/ThemeContext';

function SiteType() {
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const [clear, setClear] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDeleteSiteType, setOpenDeleteSiteType] = useState(false)
  const [siteTypeId, setSiteTypeId] = useState()
  const [siteTypeName, setSiteTypeName] = useState()
  const [openEditSiteType, setOpenEditSiteType] = useState(false)
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const { darkMode } = useContext(ThemeContext);
  const [selectedObject, setSelectedObject] = useState({
    id: '',
    name: '',
    code: '',
    description: '',
  })

  const [filter, setFilter] = React.useState({
    id: '',
    name: '',
    code: '',
    description: '',
    isPagable: true,
    sort: 'createdAt%7Cdesc',
    caseSensitive: false,
    operator: Boolean.AND,
  })

  const defaultFilter = {
    id: '',
    name: '',
    code: '',
    description: '',
    isPagable: true,
    sort: 'createdAt%7Cdesc',
    caseSensitive: false,
    operator: Boolean.AND,
  }
  const applyFilter = (filterData) => {
    setClear(true)
    const query = {
      name: filterData.name,
      code: filterData.code,
      description: filterData.description,
    }

    setFilter({
      ...filter,
      ...query,
    })
  }

  const resetFilter = () => {
    setClear(false)
    setPage(1)
    setFilter({
      ...defaultFilter,
    })
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    loadData(newPage, size)
    setRowsPerPage(size)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    loadData(1, rowsPerPage)
  }, [filter])

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: filter,
      page: page,
      rowsPerPage: rowsPerPage,
      Boolean: true,
    }

    dispatch(getSiteTypeList(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI6000' && data.data) {
          setTotalRecords(data.data.totalCount)
          setPage(data.data.currentPage)
          setRows(data.data.siteTypes)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
  }

  const handleEditSiteType = (params) => {
    setOpenEditSiteType(!openEditSiteType)
    setSelectedObject(params)
  }

  const handleDeleteSiteType = (params) => {
    setOpenDeleteSiteType(!openDeleteSiteType)
    setSiteTypeId(params.row.id)
    setSiteTypeName(params.row.name)
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        test.code.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (test.description
          ? test.description.toLowerCase().includes(searchedVal.toLowerCase())
          : '')
      )
    })
    setRows(filteredRows)
  }

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(page, rowsPerPage)
      setDel(false)
    } else {
      if (del === true) {
        loadData(page - 1, rowsPerPage)
        setDel(false)
      }
    }
  }, [del])

  const handleTextOverflow = (text) => {
    if (text && text.length > 18) {
      return text.substring(0, 18) + '...'
    } else {
      return text
    }
  }

  const columns = React.useMemo(() => [
    {
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>
      },
    },

    {
      field: 'name',
      headerName: t('COMMON044'),
      flex: 1,
      width: 150,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.name}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.name)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'code',
      headerName: t('COMMON067'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.code,
    },
    {
      field: 'description',
      headerName: t('STYPE009'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.description}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.description)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<SiteTypeViewDetails rowData={params.row} />}
          label="View"
        />,

        <GridActionsCellItem
          icon={<EditWatchlistIcon />}
          label="Edit"
          onClick={() => handleEditSiteType(params.row)}
        />,

        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteSiteType(params)}
        />,
      ],
    },
  ])

  return (
    <>
      <HeaderToolbar
        src={AddSiteIcon}
        onClick={handleOpen}
        title={t('STYPE001')}
        tooltipTitle={t('STYPE002')}
      />


      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{ marginLeft: darkMode ? '-15px' : '0' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '1',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterSiteType
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(page, rowsPerPage)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          count={totalRecords}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <AddSiteType
          open={open}
          selectedObject={selectedObject}
          handleClose={() => {
            loadData(page, rowsPerPage)
            setOpen(false)
          }}
        />
      )}

      {openEditSiteType && (
        <AddSiteType
          selectedObject={selectedObject}
          open={openEditSiteType}
          handleClose={() => {
            setOpenEditSiteType(false)
            loadData(page, rowsPerPage)
            setSelectedObject({
              id: '',
              name: '',
              code: '',
              description: '',
            })
          }}
        />
      )}

      {openDeleteSiteType && (
        <DeleteSiteType
          open={openDeleteSiteType}
          handleClose={(data) => {
            setOpenDeleteSiteType(data)
            loadData(page, rowsPerPage)
          }}
          delete={() => setDel(true)}
          DeleteSiteTypeId={siteTypeId}
          DeleteSiteTypeName={siteTypeName}
        />
      )}
    </>
  )
}

export default SiteType
