import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Grid} from "@material-ui/core";
import Cards from './Cards.css'
import { Opacity } from '@material-ui/icons';
import DoneIcon from '@mui/icons-material/Done';
import CardMedia from '@mui/material/CardMedia';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


// import { FcCheckmark } from "react-icons/fc";
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>  
);
// #F2824C
export default function SubscriptionCards() {

  const [popup,setPop] = useState(false);
  const [popup1,setPop1] = useState(false);
  const [popup2,setPop2] = useState(false);
  const [popup3,setPop3] = useState(false);
  const [popup4,setPop4] = useState(false);

  const handleOpen = () =>{
    setPop(!popup);
  }
 
  const closePopup = () =>{
    setPop(false);
  }

  const handleOpen1 = () =>{
    setPop1(!popup1);
  }
 
  const closePopup1 = () =>{
    setPop1(false);
  }

  const handleOpen2 = () =>{
    setPop2(!popup2);
  }
 
  const closePopup2 = () =>{
    setPop2(false);
  }

  const handleOpen3 = () =>{
    setPop3(!popup3);
  }
 
  const closePopup3 = () =>{
    setPop3(false);
  }

  const handleOpen4 = () =>{
    setPop4(!popup4);
  }
 
  const closePopup4 = () =>{
    setPop4(false);
  }
  
  

  return (
   <Grid container spacing={5}>
    <Grid item xs={2.1}>
    <h4 className='nnx'>Subscription Management</h4>
    <br/>
     <Card sx={{ minWidth: 246 }}> 
     <CardMedia
        component="img"
        // height="310"
        height="150"
        image="../images/carddd1.png"
        alt="green iguana"
      />
      
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <h5>Customize</h5>
          <h4>$100</h4>
          <p>Per 6 month</p>
          <br/>
        </Typography> */}
      <div className='iio'>
        <ul  className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li ><DoneIcon sx={{fontSize:"18px" , color:"green"}} />   Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />   Number of device/tablet for check-in</li>
        </ul>
     </div>
      </CardContent>
      <CardActions>
        {/* <button className='ffk'>+ View all features</button> */}
        <Button size="small" onClick={handleOpen1} sx={{'&:hover': {
    backgroundColor: "white",
  },fontSize:"14px"}}>+ View all features</Button>
        {/* <Button variant="text" onClick={handleOpen1} sx={{color:"#F2824C"}}>+ View all features</Button> */}
      </CardActions>
     
    </Card>
   </Grid>
   <Grid item xs={2.1}>
    <h4 className='nnk'>fef</h4>
    <br/>
     <Card sx={{ minWidth: 246 }}>
      
     <CardMedia
        component="img"
        height="150"
        image="../images/carddd2.png"
        alt="green iguana"
      />
      
       <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <h5>Customize</h5>
          <h4>$100</h4>
          <p>Per 6 month</p>
          <br/>
        </Typography> */}
      <div className='iio'>
        <ul  className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
        </ul>
     </div>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleOpen2}  sx={{'&:hover': {
    backgroundColor: "white",
  }, fontSize:"14px"}}>+ View all features</Button>
      </CardActions>
     
    </Card>
   </Grid>
   <Grid item xs={2.1}>
   <h4 className='nnk'>fef</h4>
    <br/>
     <Card sx={{ minWidth: 250 }}>
      
     <CardMedia
        component="img"
        height="150"
        image="../images/carddd3.png"
        alt="green iguana"
      />
      
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <h5>Customize</h5>
          <h4>$100</h4>
          <p>Per 6 month</p>
          <br/>
        </Typography> */}
      <div className='iio'>
        <ul  className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
        </ul>
     </div>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleOpen3}  sx={{'&:hover': {
    backgroundColor: "white",
  },fontSize:"14px"}}>+ View all features</Button>
      </CardActions>
     
    </Card>
   </Grid>
   <Grid item xs={2.1}>
   <h4 className='nnk'>fef</h4>
    <br/>
     <Card sx={{ minWidth: 250 }}>
      
     <CardMedia
        component="img"
        height="150"
        image="../images/carddd4.png"
        alt="green iguana"
      />
      
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <h5>Customize</h5>
          <h4>$100</h4>
          <p>Per 6 month</p>
          <br/>
        </Typography> */}
      <div className='iio'>
        <ul className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
        </ul>
     </div>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleOpen4}  sx={{'&:hover': {
    backgroundColor: "white",
  },fontSize:"14px"}}>+ View all features</Button>
      </CardActions>
     
    </Card>
   </Grid>
   <Grid item xs={2.1}>
   <h4 className='nnk'>fef</h4>
    <br/>
     <Card sx={{ minWidth: 250 }}>
     <CardMedia
        component="img"
        height="150"
        image="../images/carddd5.png"
        alt="green iguana"
      />
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <h5>Customize</h5>
          <h4>$100</h4>
          <p>Per 6 month</p>
          <br/>
        </Typography> */}
      <div className='iio'>
        <ul  className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
        </ul>
     </div>
      </CardContent> 
      <CardActions>
        <Button size="small" onClick={handleOpen}  sx={{'&:hover': {
    backgroundColor: "white",
  },fontSize:"14px"}}>+ View all features</Button>
      </CardActions>
    </Card>
   </Grid>
   <Grid item xs={2.1}>
     <Card sx={{ minWidth: 250 }}>
     <CardMedia
        component="img"
        height="150"
        image="../images/carddd5.png"
        alt="green iguana"
      />
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <h5>Customize</h5>
          <h4>$100</h4>
          <p>Per 6 month</p>
          <br/>
        </Typography> */}
      <div className='iio'>
        <ul  className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
        </ul>
     </div>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleOpen}  sx={{'&:hover': {
    backgroundColor: "white",
  },fontSize:"14px"}}>+ View all features</Button>
      </CardActions>
    </Card>
   </Grid>
   {/* <Grid item xs={2.1}>
     <Card sx={{ minWidth: 250 }}>
     <CardMedia
        component="img"
        height="134"
        image="../images/carddd5.png"
        alt="green iguana"
      />
      <CardContent> */}
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <h5>Customize</h5>
          <h4>$100</h4>
          <p>Per 6 month</p>
          <br/>
        </Typography> */}
      {/* <div className='iio'>
        <ul>
           <li>Site</li>
           <li>Host/Employee</li>
           <li>Visitor per site check-in</li>
           <li>Sign in</li>
           <li>Number of device/tablet for check-in</li>
        </ul>
     </div>
      </CardContent> 
      <CardActions>
        <Button size="small" onClick={handleOpen}>+ View all features</Button>
      </CardActions>
    </Card>
   </Grid> */}
   <Grid item xs={2.1}>
     <Card sx={{ minWidth: 250 }}>
      
     <CardMedia
        component="img"
        height="150"
        image="../images/carddd5.png"
        alt="green iguana"
      />
      
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <h5>Customize</h5>
          <h4>$100</h4>
          <p>Per 6 month</p>
          <br/>
        </Typography> */}
      <div className='iio'>
        <ul  className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
        </ul>
     </div>
      </CardContent> 
      <CardActions>
        <Button size="small" onClick={handleOpen}  sx={{'&:hover': {
    backgroundColor: "white",
  },fontSize:"14px"}}>+ View all features</Button>
      </CardActions>
        {popup?
        <div className='main'>
          <div className='popup'>
              <div className='popup-header'>
               <div className='uui'>
               {/* <h3>Customize :- $100</h3> */}
               </div>
               <CardActions>
                <IconButton>
               <CloseIcon onClick={closePopup}>X</CloseIcon>
               </IconButton>
               </CardActions>
              </div>
              <div className='uuv'>
                <h3 className='xxa'>Customize</h3>
              </div>
              <div className='iim'>
                <h2 className='xxb'>$100</h2>
                <p className='ppa'>Per 6 month</p>
              </div>
              <div>
                <br/><br/><br/>
              <div className="tty">
        <ul  className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Health screen question</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Contactless and Touch free</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Pre Registrations</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Walk-in check-in/Check-out</li>  
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host Notification(Email/SMS)</li>
        </ul>
     </div>  
     <div className='uuty'>
        <ul  className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  SMS/Email send Invitation</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Outlook/Gmail Integration</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Teams Integration</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Delivery Option for Courier</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  DL Scanner</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Capture Photo</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Print Badges</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sexual Offender</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Kiosk Support</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Reports & Dashboard</li>
        </ul>  
     </div>
              </div>
          </div>

        </div>:""} 
        {popup1?
        <div className='main'>
          <div className='popup'>
              <div className='popup-header'>
               <div className='uui'>
               {/* <h3>Customize :- $100</h3> */}
               </div>
               <CardActions>
               <IconButton>
               <CloseIcon onClick={closePopup1}>X</CloseIcon>
               </IconButton>
               </CardActions>
              </div>
              <div className='uuv'>
                <h3 className='xxa'>Basic</h3>
              </div>
              <div className='iim'>
                <h2 className='xxb'>$50</h2>
                <p className='ppa'>Per 6 month</p>
              </div>
              <div>
                <br/><br/><br/>
              <div className="tty"> 
        <ul className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Health screen question</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Contactless and Touch free</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Pre Registrations</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Walk-in check-in/Check-out</li>  
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host Notification(Email/SMS)</li>
        </ul>
     </div>  
     <div className='uuty'>
        <ul className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  SMS/Email send Invitation</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Outlook/Gmail Integration</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Teams Integration</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Delivery Option for Courier</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  DL Scanner</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Capture Photo</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Print Badges</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sexual Offender</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Kiosk Support</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Reports & Dashboard</li>
        </ul>
     </div>
              </div>
          </div>

        </div>:""} 
        {popup2?
        <div className='main'>
          <div className='popup'>
              <div className='popup-header'>
               <div className='uui'>
               {/* <h3>Customize :- $100</h3> */}
               </div>
               <CardActions>
               <IconButton>
               <CloseIcon onClick={closePopup2}>X</CloseIcon>
               </IconButton>
               </CardActions>
              </div>
              <div className='uuv'>
                <h3 className='xxa'>Standard</h3>
              </div>
              <div className='iim'>
                <h2 className='xxb'>$100</h2>
                <p className='ppa'>Per 6 month</p>
              </div>
              <div>
                <br/><br/><br/>
              <div className="tty">
        <ul className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Health screen question</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Contactless and Touch free</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Pre Registrations</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Walk-in check-in/Check-out</li>  
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host Notification(Email/SMS)</li>
        </ul>
     </div>  
     <div className='uuty'>
        <ul className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  SMS/Email send Invitation</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Outlook/Gmail Integration</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Teams Integration</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Delivery Option for Courier</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  DL Scanner</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Capture Photo</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Print Badges</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sexual Offender</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Kiosk Support</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Reports & Dashboard</li>
        </ul>
     </div>
              </div>
          </div>

        </div>:""} 
        {popup3?
        <div className='main'>
          <div className='popup'>
              <div className='popup-header'>
               <div className='uui'>
               {/* <h3>Customize :- $100</h3> */}
               </div>
               <CardActions>
               <IconButton>
               <CloseIcon onClick={closePopup3}>X</CloseIcon>
               </IconButton>
               </CardActions>
              </div>
              <div className='uuv'>
                <h3 className='xxa'>Premium</h3>
              </div>
              <div className='iim'>
                <h2 className='xxb'>$150</h2>
                <p className='ppa'>Per 6 month</p>
              </div>
              <div>
                <br/><br/><br/>
              <div className="tty">
        <ul className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Health screen question</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Contactless and Touch free</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Pre Registrations</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Walk-in check-in/Check-out</li>  
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host Notification(Email/SMS)</li>
        </ul>
     </div>  
     <div className='uuty'>
        <ul className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  SMS/Email send Invitation</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Outlook/Gmail Integration</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Teams Integration</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Delivery Option for Courier</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  DL Scanner</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Capture Photo</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Print Badges</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sexual Offender</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Kiosk Support</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Reports & Dashboard</li>
        </ul>
     </div>
              </div>
          </div>

        </div>:""} 
        {popup4?
        <div className='main'>
          <div className='popup'>
              <div className='popup-header'>
               <div className='uui'>
               {/* <h3>Customize :- $100</h3> */}
               </div>
               <CardActions>
               <IconButton>
               <CloseIcon onClick={closePopup4}>X</CloseIcon>
               </IconButton>
               </CardActions>
              </div>
              <div className='uuv'>
                <h3 className='xxa'>Enterprise</h3>
              </div>
              <div className='iim'>
                <h2 className='xxb'>$300</h2>
                <p className='ppa'>Per 6 month</p>
              </div>
              <div>
                <br/><br/><br/>
              <div className="tty">
        <ul className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Site</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host/Employee</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Visitor per site check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sign in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Number of device/tablet for check-in</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Health screen question</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Contactless and Touch free</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Pre Registrations</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Walk-in check-in/Check-out</li>  
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Host Notification(Email/SMS)</li>
        </ul>
     </div>  
     <div className='uuty'>
        <ul className='zzm'>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  SMS/Email send Invitation</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Outlook/Gmail Integration</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Teams Integration</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Delivery Option for Courier</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  DL Scanner</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Capture Photo</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Print Badges</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Sexual Offender</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Kiosk Support</li>
           <li><DoneIcon sx={{fontSize:"18px" , color:"green"}} />  Reports & Dashboard</li>
        </ul>
     </div>
              </div>
          </div>

        </div>:""} 
    </Card>
   </Grid>
   </Grid>
  );
}