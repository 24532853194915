import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { Radio } from "@mui/material";



const names = ["All", "Today", "Last 7 Days", "Last 30 days"];

export default function DatePickerComponent(props) {
  const [selectedValue, setSelectedValue] = React.useState([]);


  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };



  const dateFilter = (days) => {
    if (days === "All") {
      var sdate = "";
      var toDate = "";
      props.datePickerFilter(sdate, toDate);
    } else {
      var today = new Date();
      var todayDate = new Date();
      var toDate =
        today.getFullYear() +
        formatDayAndMonth(today.getMonth() + 1) +
        formatDayAndMonth(today.getDate()) +
        "T23:59";
      var last = new Date(todayDate.getTime() - (days * 24 * 60 * 60 * 1000));
      var sdate =
        last.getFullYear() +
        formatDayAndMonth(last.getMonth() + 1) +
        formatDayAndMonth(last.getDate()) +
        "T00:00";
      props.datePickerFilter(sdate, toDate);
    }

  }


  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    if (value === "All") {
      dateFilter("All")
    } else if (value === "Today") {
      dateFilter(0)
    } else if (value === "Last 7 Days") {
      dateFilter(7)
    } else if (value === "Last 30 days") {
      dateFilter(30)
    }
  };

  return (
    <div>
      <FormControl variant="filled" sx={{ width: 200, }}>
        <Select
          sx={[
            () => ({
              "& .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select": {
                /* height: auto; */
                minHeight: '0em !important',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                backgroundColor:'#fff !important'
              },
              ".css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input": {
                height: '0.6em !important',
                backgroundColor: '#fff !important',
                paddingTop: '15px !important',
                paddingBottom: '20px !important',
              },
              "&.MuiSelect-select.MuiSelect-filled.MuiInputBase-input.MuiFilledInput-input": {
                backgroundColor:'#fff !important'
              },
            })
          ]}
          displayEmpty
          value={selectedValue}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span>All</span>;
            }
            return selected.join(", ");
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Radio checked={selectedValue.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div >
  );
}
