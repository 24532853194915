import { MenuItem, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

function HoverImage(props) {

    const [image, setImage] = React.useState(props.normalImage);

    const over = (e) => {
        setImage(props.hoverImage);
    };

    const out = (e) => {
        setImage(props.normalImage);
    }

    return (
        <>
            <MenuItem
                onMouseOver={over}
                onMouseOut={out}
                component={Link}
                to={props.link}
                sx={[
                    (theme) => ({
                        '&:hover': {
                            background: process.env.REACT_APP_ENVIRONMENT === 'USA' ?  '' : process.env.REACT_APP_BG_ICON,
                            color:process.env.REACT_APP_BG_HEADER_PROFILE
                        },
                    }),
                ]}
            >
                <img src={image} alt={props.alt} style={{ paddingRight: '10px' }} />
               <Typography>{props.item}</Typography> 
            </MenuItem>
        </>
    )
}

export default HoverImage;