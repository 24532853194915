import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ThemeContext } from '../../theme/ThemeContext';
import { useContext, useEffect } from "react";

export default function CustomDateFilter(props) {
  const { darkMode } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = React.useState([t("Today")]);

  const names = [t("Today"), t("Last 7 Days"), t("Last 30 Days"), t("All")];

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  const dateFilter = (days) => {
    if (days === "All") {
      var sdate = "";
      var edate = "";
      props.datePickerFilter(sdate, edate);
    } else {
      var today = new Date();
      var todayDate = new Date();
      var edate =
        today.getFullYear() +
        formatDayAndMonth(today.getMonth() + 1) +
        formatDayAndMonth(today.getDate()) +
        "T23:59:59";
      var last = new Date(todayDate.getTime() - days * 24 * 60 * 60 * 1000);
      var sdate =
        last.getFullYear() +
        formatDayAndMonth(last.getMonth() + 1) +
        formatDayAndMonth(last.getDate()) +
        "T00:00";
      props.datePickerFilter(sdate, edate);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(typeof value === "string" ? value.split(",") : value);

    if (value === "Today" || value === "Hoy") {
      dateFilter(0);
    } else if (value === "Last 7 Days" || value === "Los últimos 7 días") {
      dateFilter(7);
    } else if (value === "Last 30 Days" || value === "Últimos 30 días") {
      dateFilter(30);
    } else if (value === "All" || value === "cada") {
      dateFilter("All");
    }
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("darkMode");
    } else {
      document.body.classList.remove("darkMode");
    }
    return () => {
      document.body.classList.remove("darkMode");
    };
  }, [darkMode]);

  const menuProps = {
    PaperProps: {
      sx: {
        backgroundColor: darkMode ? "#021627" : "", 
      },
    },
  };

  return (
    <div>
      <FormControl sx={{ width: 200 }}>
        <Select
          style={{
            backgroundColor: darkMode ? "#021627" : "#fff",
            color: darkMode ? "#fff" : "#000",
            marginRight: darkMode ? "19px" : "2px",
            marginLeft: darkMode ? "3px" : "20px",
          }}
          sx={[
            {
              "& .MuiInputBase-input-MuiFilledInput-input.MuiSelect-select": {
                minHeight: "0em !important",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
              "& .MuiSelect-select-MuiInputBase-input-MuiFilledInput-input": {
                height: "0.6em !important",
                paddingTop: "15px !important",
                paddingBottom: "20px !important",
              },
            },
          ]}
          displayEmpty
          defaultValue={t("Today")}
          value={selectedValue}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span>{t("Today")}</span>;
            }
            return selected.join(", ");
          }}
          MenuProps={menuProps} // Correctly pass menuProps to apply styles
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={{
                // backgroundColor: darkMode ? "#253142" : "#fff",
                color: darkMode ? "#fff" : "#000",
              }}
            >
              <Radio checked={selectedValue.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}