import axios from 'axios';
import api from '../../config/http-common';
import CommonUtil from '../../Util/CommonUtils';

const getAll = () => {
  return api.securedAxios().get('/company');
};

const get = () => {
    return api.securedAxios().get(`/api/v3.0/company/` + localStorage.getItem("companyId"));   
};

const getCompanyById = (companyId) => {
  return api.unsecuredAxios().get(`/api/v3.0/company/` + companyId);   
};

const create = (data) => {
  return api.securedAxios().post(`/api/v3.0/company`, data);
};

const filterCompany = (payload) => {
  var url = '/company?pageNumber=1&pageSize=1000';
  if (!CommonUtil.isEmpty(payload)) {
    if (!CommonUtil.isEmptyString(payload.id)) url = url + '&companyId=' + payload.id;
  }
  return api.securedAxios().get(url);
};
const updateCompanyProfile = (payload) => {
  return api.securedAxios().put(`/company?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&companyId=${payload.id}`, payload);
};

const verifyCompany = (data) => {
  return api.securedAxios().put(`/company/switch`, data);
};

const updateCompany = (payload) => {
  return api.securedAxios().put(`/api/v3.0/updateCompany/${localStorage.getItem('companyId')}`, payload);
};

const createCompany = (payload) => {
  return api.switchCompanyAxios().post(`/api/v3.0/createCompany`, payload);
};

const createSecuredCompany = (payload) => {
  return api.securedAxios().post(`/api/v3.0/createCompany`, payload);
}; 

const CompanyService = {
  getAll,
  getCompanyById,
  get,
  create,
  createSecuredCompany,
  updateCompanyProfile,
  verifyCompany,
  filterCompany,
  updateCompany,
  createCompany,
};

export default CompanyService;
