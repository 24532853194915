import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as DeleteVisitIcon } from "../../../src/assets/DeleteVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Toast from "../../components/ToastContainer/CustomToast";
import { deleteCheckListItem } from "../../slices/CheckListSlice/CheckListSlice";
import { deleteAccessLevel } from "../../slices/PurposeTypeUSA/PurposeTypeAccesslevelUSASlice";


export default function PurposeTypeAccessLevelDelete(props) {
    const { open, handleClose } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [payload, setPayload] = React.useState({
        data: props.DeleteId,
    });


    const handleSubmit = () => {    
        dispatch(deleteAccessLevel(payload.data))
        .unwrap()
        .then((data) => {
          console.info(data)
          if ((data.code = "CVAI0047" && data.data)) {
            Toast(t("COMMON132"),"success"); 
            handleClose();
          } else {
            Toast((data.message),"error");
          }
        })
        .catch((er) => { });
    
    };

    return (
        <>
            <DialogBox
                Header="Delete"
                acceptText="Confirm"
                cancelText="Cancel"
                fullWidth={true}
                onClose={() => handleClose(false)}
                cancel={handleClose}
                onSubmit={handleSubmit}
                open={open}
            >
                <Grid
                    container
                    justifyContent="center"
                    direction="column"
                    alignItems={"center"}
                    style={{ padding: "20px" }}
                >
                    <Grid>
                        <DeleteVisitIcon />
                    </Grid>
                    <Grid>
                        <Grid
                            style={{
                                wordWrap: "break-word",
                                opacity: 1,
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "row",
                                fontSize: "17px",
                                marginTop: "15px",
                            }}
                        >
                            <Typography style={{ color: "#242424", marginRight: "4px", fontSize: '17px' }}>
                                Are you sure you want to delete selected Record?
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogBox>
        </>
    );
}
