import axios from "axios";
import api from "../../config/http-common";
import { JAVA_URL_EXPORT_ALL_VISITORS_LOG, JAVA_URL_EXPORT_CANCELMISSESCHECKIN_VISITORS_LOG, JAVA_URL_EXPORT_CHECKOUT_VISITORS_LOG, JAVA_URL_EXPORT_RESTRICT_VISITOR } from "../EndPoints";

export const exportAllVistorsLog = (onSuccess, onFailure) => async (dispatch) => {
    axios({
        url: JAVA_URL_EXPORT_ALL_VISITORS_LOG,
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'All Visitors Log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
};

export const exportCheckoutVistorsLog = (onSuccess, onFailure) => async (dispatch) => {
    axios({
        url: JAVA_URL_EXPORT_CHECKOUT_VISITORS_LOG,
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'Checkout Visitors Log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
};

export const exportBlockedVistorsLog = (onSuccess, onFailure) => async (dispatch) => {
    axios({
        url: JAVA_URL_EXPORT_RESTRICT_VISITOR,
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'Blocked Visitors.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
};

export const exportCancelledMissedCheckinVistorsLog = (status, onSuccess, onFailure) => async (dispatch) => {
    axios({
        url: JAVA_URL_EXPORT_CANCELMISSESCHECKIN_VISITORS_LOG + status,
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                if (status === "CHECKED_IN") {
                    aEle.download = 'CheckInVisitorsLog.xlsx';
                } else if (status === "Missed") {
                    aEle.download = 'MissedVisitorsLog.xlsx';
                } else if (status === "CANCELLED") {
                    aEle.download = 'CancelledVisitorsLog.xlsx';
                } else if (status === "REJECTED") {
                    aEle.download = 'RejectedVisitorsLog.xlsx';
                }

                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
};