import React from 'react'

const VisitorWhite = () => {
  return (
    <svg className='MuiSvgIcon-root' xmlns="http://www.w3.org/2000/svg" width="24.301" height="24.93" viewBox="0 0 24.301 24.93">
      <g id="Visitor-white" transform="translate(-281.375 -151.627)">
        <path id="Path_3857" data-name="Path 3857" d="M304.246,363.5H282.8a1.3,1.3,0,0,0-1.3,1.3v1.585a.42.42,0,0,0,.42.42h0a.42.42,0,0,0,.42-.42V364.8a.465.465,0,0,1,.465-.465h21.442a.465.465,0,0,1,.465.465v11.614a.465.465,0,0,1-.465.465H282.8a.465.465,0,0,1-.465-.465V369.2a.42.42,0,0,0-.42-.42h0a.42.42,0,0,0-.42.42v7.215a1.3,1.3,0,0,0,1.3,1.3h21.442a1.3,1.3,0,0,0,1.3-1.3V364.8A1.3,1.3,0,0,0,304.246,363.5Z" transform="translate(0 -201.29)" />
        <path id="Path_3857_-_Outline" data-name="Path 3857 - Outline" d="M282.8,363.375h21.442a1.431,1.431,0,0,1,1.429,1.429v11.614a1.431,1.431,0,0,1-1.429,1.429H282.8a1.431,1.431,0,0,1-1.429-1.429V369.2a.545.545,0,1,1,1.09,0v7.215a.34.34,0,0,0,.34.34h21.442a.34.34,0,0,0,.34-.34V364.8a.34.34,0,0,0-.34-.34H282.8a.34.34,0,0,0-.34.34v1.585a.545.545,0,1,1-1.09,0V364.8A1.431,1.431,0,0,1,282.8,363.375ZM304.246,377.6a1.181,1.181,0,0,0,1.179-1.179V364.8a1.181,1.181,0,0,0-1.179-1.179H282.8a1.181,1.181,0,0,0-1.179,1.179v1.585a.295.295,0,1,0,.59,0V364.8a.59.59,0,0,1,.59-.59h21.442a.59.59,0,0,1,.59.59v11.614a.59.59,0,0,1-.59.59H282.8a.59.59,0,0,1-.59-.59V369.2a.295.295,0,1,0-.59,0v7.215A1.181,1.181,0,0,0,282.8,377.6Z" transform="translate(0 -201.29)" />
        <path id="Line_1" data-name="Line 1" d="M1.038-7.21H-7.6A.4.4,0,0,1-8-7.6.4.4,0,0,1-7.6-8H1.038a.4.4,0,0,1,.4.4A.4.4,0,0,1,1.038-7.21Z" transform="translate(301.624 173.296)" />
        <path id="Line_1_-_Outline" data-name="Line 1 - Outline" d="M1.038-7.085H-7.6a.521.521,0,0,1-.52-.52.521.521,0,0,1,.52-.52H1.038a.521.521,0,0,1,.52.52A.521.521,0,0,1,1.038-7.085ZM-7.6-7.875a.27.27,0,0,0-.27.27.27.27,0,0,0,.27.27H1.038a.27.27,0,0,0,.27-.27.27.27,0,0,0-.27-.27Z" transform="translate(301.624 173.296)" />
        <path id="Line_2" data-name="Line 2" d="M1.038-7.21H-7.6A.4.4,0,0,1-8-7.6.4.4,0,0,1-7.6-8H1.038a.4.4,0,0,1,.4.4A.4.4,0,0,1,1.038-7.21Z" transform="translate(301.624 177.494)" />
        <path id="Line_2_-_Outline" data-name="Line 2 - Outline" d="M1.038-7.085H-7.6a.521.521,0,0,1-.52-.52.521.521,0,0,1,.52-.52H1.038a.521.521,0,0,1,.52.52A.521.521,0,0,1,1.038-7.085ZM-7.6-7.875a.27.27,0,0,0-.27.27.27.27,0,0,0,.27.27H1.038a.27.27,0,0,0,.27-.27.27.27,0,0,0-.27-.27Z" transform="translate(301.624 177.494)" />
        <path id="Line_3" data-name="Line 3" d="M-1.679-7.21H-7.6A.4.4,0,0,1-8-7.6.4.4,0,0,1-7.6-8h5.926a.4.4,0,0,1,.4.4A.4.4,0,0,1-1.679-7.21Z" transform="translate(304.316 175.37)" />
        <path id="Line_3_-_Outline" data-name="Line 3 - Outline" d="M-1.679-7.085H-7.6a.521.521,0,0,1-.52-.52.521.521,0,0,1,.52-.52h5.926a.521.521,0,0,1,.52.52A.521.521,0,0,1-1.679-7.085ZM-7.6-7.875a.27.27,0,0,0-.27.27.27.27,0,0,0,.27.27h5.926a.27.27,0,0,0,.27-.27.27.27,0,0,0-.27-.27Z" transform="translate(304.316 175.37)" />
        <path id="Line_4" data-name="Line 4" d="M-2.666-7.21H-7.6A.4.4,0,0,1-8-7.6.4.4,0,0,1-7.6-8h4.939a.4.4,0,0,1,.4.4A.4.4,0,0,1-2.666-7.21Z" transform="translate(305.328 179.617)" />
        <path id="Line_4_-_Outline" data-name="Line 4 - Outline" d="M-2.666-7.085H-7.6a.521.521,0,0,1-.52-.52.521.521,0,0,1,.52-.52h4.939a.521.521,0,0,1,.52.52A.521.521,0,0,1-2.666-7.085ZM-7.6-7.875a.27.27,0,0,0-.27.27.27.27,0,0,0,.27.27h4.939A.27.27,0,0,0-2.4-7.6a.27.27,0,0,0-.27-.27Z" transform="translate(305.328 179.617)" />
        <path id="Rectangle_4288" data-name="Rectangle 4288" d="M-6.629-8H.506A1.373,1.373,0,0,1,1.877-6.629v2.382H-8V-6.629A1.373,1.373,0,0,1-6.629-8ZM1.087-5.037V-6.629A.582.582,0,0,0,.506-7.21H-6.629a.582.582,0,0,0-.581.581v1.592Z" transform="translate(296.587 167.32)" />
        <path id="Rectangle_4288_-_Outline" data-name="Rectangle 4288 - Outline" d="M-6.629-8.125H.506A1.5,1.5,0,0,1,2-6.629v2.507H-8.125V-6.629A1.5,1.5,0,0,1-6.629-8.125ZM1.752-4.372V-6.629A1.248,1.248,0,0,0,.506-7.875H-6.629A1.248,1.248,0,0,0-7.875-6.629v2.257ZM-6.629-7.335H.506a.707.707,0,0,1,.706.706v1.717H-7.335V-6.629A.707.707,0,0,1-6.629-7.335ZM.962-5.162V-6.629a.457.457,0,0,0-.456-.456H-6.629a.457.457,0,0,0-.456.456v1.467Z" transform="translate(296.587 167.32)" />
        <path id="Path_3858" data-name="Path 3858" d="M419.977,160.111h-5.812l-2.612-6.48a.833.833,0,0,1,.42-1.066l1.567-.733a.833.833,0,0,1,1.091.367l2.477,4.709,2.511-4.55a1.073,1.073,0,0,1,1.36-.469l1.133.482a1.073,1.073,0,0,1,.567,1.409Zm-5.279-.79h4.756l2.5-5.852a.283.283,0,0,0-.15-.372l-1.133-.482a.283.283,0,0,0-.359.124l-2.652,4.807a.632.632,0,0,1-1.113-.011l-2.613-4.969-.056-.019-1.567.733a.043.043,0,0,0-.022.055Z" transform="translate(-123.573)" />
        <path id="Path_3858_-_Outline" data-name="Path 3858 - Outline" d="M420.06,160.236h-5.979l-2.643-6.559a.959.959,0,0,1,.483-1.226l1.567-.733a.958.958,0,0,1,1.254.422l2.369,4.5,2.4-4.347a1.2,1.2,0,0,1,1.518-.524l1.133.482a1.2,1.2,0,0,1,.633,1.573Zm-5.81-.25h5.645l2.668-6.256a.948.948,0,0,0-.5-1.245L420.929,152a.948.948,0,0,0-1.2.415l-2.623,4.754-2.585-4.915a.708.708,0,0,0-.927-.312l-1.567.733a.708.708,0,0,0-.357.906Zm5.288-.54h-4.923l-2.444-6.064a.168.168,0,0,1,.085-.215l1.612-.754.151.051.023.044,2.613,4.969a.507.507,0,0,0,.893.009l2.652-4.807a.408.408,0,0,1,.517-.178l1.133.482a.408.408,0,0,1,.216.536Zm-4.755-.25h4.59l2.463-5.776a.158.158,0,0,0-.083-.207l-1.133-.482a.16.16,0,0,0-.2.069l-2.652,4.807a.757.757,0,0,1-1.333-.013l-2.577-4.9-1.426.667Z" transform="translate(-123.573)" />
        <circle id="Ellipse_2788" data-name="Ellipse 2788" cx="0.543" cy="0.543" r="0.543" transform="translate(292.982 160.654)" />
        <path id="Ellipse_2789" data-name="Ellipse 2789" d="M1.9.714A1.186,1.186,0,1,0,3.087,1.9,1.188,1.188,0,0,0,1.9.714M1.9,0A1.9,1.9,0,1,1,0,1.9,1.9,1.9,0,0,1,1.9,0Z" transform="translate(286.13 165.653)" />
        <path id="Path_3858-2" data-name="Path 3858" d="M3.241.589a2.6,2.6,0,0,0-2.5,2.25H5.722A2.58,2.58,0,0,0,3.241.589m0-.714A3.3,3.3,0,0,1,6.46,3.195v.359H0V3.195A3.318,3.318,0,0,1,3.241-.125Z" transform="translate(284.881 169.278)" />
        <path id="Layer_51" data-name="Layer 51" d="M6.681,5.5A1.923,1.923,0,1,0,4.5,5.5,3.589,3.589,0,0,0,2,8.919a.256.256,0,0,0,.256.256H8.922a.256.256,0,0,0,.256-.256A3.589,3.589,0,0,0,6.681,5.5Zm-2.5-1.582a1.41,1.41,0,1,1,1.41,1.41A1.41,1.41,0,0,1,4.179,3.921ZM2.513,8.663a3.076,3.076,0,0,1,6.153,0Z" transform="translate(282.513 163.656)" />
        <path id="Layer_51_-_Outline" data-name="Layer 51 - Outline" d="M5.446,1.5A2.281,2.281,0,0,1,7.2,5.24a3.931,3.931,0,0,1,2.2,3.536.614.614,0,0,1-.614.614H2.114A.614.614,0,0,1,1.5,8.776,3.931,3.931,0,0,1,3.7,5.24,2.281,2.281,0,0,1,5.446,1.5Zm0,3.333A1.053,1.053,0,1,0,4.393,3.778,1.054,1.054,0,0,0,5.447,4.83Zm2.7,3.332a2.719,2.719,0,0,0-5.392,0Z" transform="translate(282.656 163.799)" />
      </g>
    </svg>
  )
}

export default VisitorWhite
