import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import LoginLayoutV2 from './LoginLayoutV2';
import { useTranslation } from 'react-i18next';
import email_sent from '../../assets/USA_Images/login_images/emailleft.svg';
import vis_email_sent from '../../assets/images/onboard/email.svg';
import email_img from '../../assets/USA_Images/login_images/emailsent.svg';
import vis_email_img from '../../assets/images/onboard/email_img.svg';


const useStyles = makeStyles((theme) => ({
    form: {
        width: '70%',
        marginTop: theme.spacing(3),
    },
    submit: {
        backgroundColor: '#36c96d',
        borderRadius: '0.2rem',
        padding: '10px',
        '&:hover': {
            background: '#119743',
        },
    },
}));

function EmailSentScreen(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    function handleClick(e) {
        e.preventDefault();
        history.push('/vis/login');
    }

    return (
        <>
            <LoginLayoutV2 success={true} lang={true} src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  email_sent : vis_email_sent} >
                <Grid className='center_div'>
                    <Grid container alignItems='center' >
                        <Grid item className='logo-center'>
                            <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  email_img : vis_email_img} alt='logo' style={{ width: '280px' }} />
                        </Grid>
                    </Grid>
                    <form className={classes.form} noValidate autoComplete='off'>
                        <Grid item xs={12} style={{ marginBottom: '25px', marginTop: '10px' }}>
                            <Typography variant='h2' align='center' style={{ color: '#fff', fontWeight: '500', opacity:0.9 }}>
                                Email Sent
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant='body1' style={{ color: '#fff', opacity:0.6 }} align='center' gutterBottom>
                                Email has been sent to your email to reset password. Please check your inbox.
                            </Typography>
                        </Grid>
                        <Grid container spacing={5} alignItems='center'>
                            <Grid item xs>
                                <Box mt={7}>
                                    <Button onClick={handleClick}
                                        fullWidth variant='contained'
                                    >
                                        {t('VERIFYCOMP0004')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </LoginLayoutV2>
        </>
    );
}
export default withRouter(EmailSentScreen);
