import { Typography } from '@material-ui/core'
import { Box, Grid, Paper } from '@mui/material'
import imageCompression from 'browser-image-compression'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import CommonUtil from '../../Util/CommonUtils'
import USA_visLogo from '../../assets/USA_Images/login_images/App-logo.svg'
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker'
import CustomPhone from '../../components/CustomInputs/CustomPhone'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import Loader from '../../components/Loader/Loader'
import Toast from '../../components/ToastContainer/CustomToast'
import httpCommon from '../../config/http-common'
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { visitorUpdate } from '../../slices/Invitations/InvitationsSlice'
import ProfileUplod from '../Employee/UploadImg'
import { getCompanyById, getCompanyInfo } from "../../slices/Company/CompanySlice";
import isoCountries from 'i18n-iso-countries'
import SelfRegistrationCustomPhone from '../OnBoardV2/SelfRegistrationCustomPhone'
import CircularProgress from '@mui/material/CircularProgress'
import { ThemeContext } from '../../theme/ThemeContext';
import CustomPhone1 from '../../components/CustomInputs/CustomPhone1'
import CustomTextfield1 from '../../components/CustomInputs/CustomTextfield1'
import CustomDateTimePicker1 from '../../components/CustomInputs/CustomDateTimePicker1'

function ConfirmVisitV2() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  const [paramid, setParamid] = useState('')
  const [code, setCode] = useState('')
  const [validatelist, setValidatelist] = useState([])
  const [profile, setProfile] = React.useState()
  const [profileImage, setProfileImage] = React.useState()
  const [checkList, setCheckList] = useState([])
  const [visitorsId, setVisitorsId] = useState('')
  const [phoneInput, setPhoneInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [uploadpic, setUploadPic] = useState(false)
  const [visitModeWalk, setVisitModeWalk] = useState(true)
  const [date, setDate] = useState('')
  const [dateFormat, setDateFormat] = useState('')
  const [profilePhotEnable, setProfilePhotoEnable] = useState('')
  const [profilePhotoMandatory, setProfilePhotoMandatory] = useState('')
  const [confirmProfilePhoto, setConfirmProfilePhoto] = useState('confirmProfilePhoto')
  const [companyId, setCompanyId] = useState('')
  const [country, setCountry] = useState('')
  const [phoneNumberCheck, setPhoneNumberCheck] = useState('')
  const [companyData, setCompanyData] = useState('');
  const { darkMode , setDarkMode } = useContext(ThemeContext);
  
  const [payload, setPayload] = useState({
    id: '',
    code: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    profilePhoto: '',
    phoneCountryCode: '',
    visitorCompanyName: '',
    hostName: '',
    visitorId: '',
    scheduledStartDate: '',
    scheduledEndDate: '',
    visitMode: '',
  })

  const [error, setError] = useState({
    id: false,
    code: false,
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    visitorCompanyName: false,
    hostName: false,
    profilePhoto: false,
    visitMode: false,
  })

  useEffect(() => {
    setDarkMode(false)
    setIsLoading(true)
    loadData()
  }, [])

  useEffect(() => {
    if (companyId !== '')
      loadSetting()
  }, [companyId])

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value
    })
    setError({
      ...error,
      [name]: false,
    })
  }
  const inputStyle = {
    color: darkMode ? '#fff' : '#000',
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value)
    setPayload({
      ...payload,
      phoneCountryCode: '+' + data.dialCode,
      phoneNumber: value.toString().replace(data.dialCode, ''),
    })
    setError({ phoneNumber: false })
  }

  const loadData = () => {
    var urlValue = window.location.href
    var url = new URL(urlValue)
    var code = url.searchParams.get('code')
    setCode(code)
    httpCommon
      .unsecuredAxios()
      .get('/web/visit/validate/' + code)
      .then((res) => {
        const data = res.data
        if (data.code === 'CVSI0000') {
          setValidatelist(data.data.checkListItem)
          setParamid(data.data.Visit.id)
          setCompanyId(data.data.Visit.companyId);
          setPayload({
            id: data.data.visitor.id,
            firstName: data.data.visitor.firstName,
            lastName: data.data.visitor.lastName,
            email: data.data.visitor.email,
            phoneCountryCode: data.data.visitor.phone
              ? data.data.visitor.phone.split('-')[0]
              : undefined,
            phone: data.data.visitor.phone
              ? data.data.visitor.phone.split('-')[1]
              : undefined,
            visitorCompanyName: data.data.visitor.visitorCompanyName,
            hostName: data.data.Visit.hostName,
            visitorId: data.data.Visit.id,
            registrationId: data.data.Visit.id,
            scheduledStartDate: CommonUtil.getLocalDateTimeInYearFormat(
              data.data.Visit.scheduledStartDate,
            ),
            scheduledEndDate: CommonUtil.getLocalDateTimeInYearFormat(
              data.data.Visit.scheduledEndDate,
            ),
            profilePhoto: payload.firstName,
            companyId: data.data.Visit.companyId,
          })
          setPhoneInput(data.data.visitor.phone)
          setUploadPic(data.data.Visit.visitMode !== 'INVITATION')
          setVisitModeWalk(data.data.Visit.visitMode !== 'WALKIN_REGISTARTION')
          setProfile(
            data.data.visitor.profilePhotoURL
              ? data.data.visitor.profilePhotoURL
              : data.data.visitor.profilePhotoURL,
          )
          setProfileImage(data.data.visitor.profilePhotoURL
            ? data.data.visitor.profilePhotoURL
            : data.data.visitor.profilePhotoURL,)

          setCheckList(data.data.checkListItem)
          setVisitorsId(data.data.Visit.id)
        } else if (
          data.code === 'CVSE0061' ||
          data.code === 'CVAE0074' ||
          data.data.Visit.status === 'APPROVED' ||
          data.data.Visit.status === 'REJECTED'
        ) {
          setPayload([])
          history.push('/vis/result-page')
        }
        if (data.data.Visit.status != "SCHEDULED") {
          setPayload([])
          history.push('/vis/result-page')
        }
        if (data.data.visitor.phone === undefined || data.data.visitor.phone === "") {
          dispatch(getCompanyById(data.data.Visit.companyId))
            .unwrap()
            .then((data) => {
              if ((data.code = 'UASI0026')) {
                const fullCountryName = data.data.country
                const countryCode = getCountryCodeFromName(fullCountryName)
                if (countryCode) {
                  setPhoneNumberCheck(countryCode)
                } else {
                  setPhoneNumberCheck('us')
                }
              }
            })
            .catch((er) => { })
        }
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  const loadSetting = () => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setProfilePhotoEnable(data.data.invitationProfilePhoto)
          setProfilePhotoMandatory(data.data.checkInvitationProfilePhoto)
          if (data.data.dateFormat === 'dd-MM-yyyy') {
            setDateFormat('dd-MM-yyyy')
          }
          if (data.data.dateFormat === 'MM-dd-yyyy') {
            setDateFormat('MM-dd-yyyy')
          }
          if (data.data.dateFormat === 'yyyy-MM-dd') {
            setDateFormat('yyyy-MM-dd')
          }
        } else if (data.code === 'CVAE0065') {
        }
      })
  }

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.data.profilePhotoURL)
    bodyFormData.append('file', profileImage)
    httpCommon
      .unsecuredMultipartAxios()
      .post('/web/employee/profile', bodyFormData)
      .then(function (response) {
        // Toast("Profile Photo Saved", "success");
      })
      .catch(function (response) { })
  }

  const handleSubmit = (event) => {
    if (profilePhotoMandatory) {
      if (visitModeWalk) {
        if (profileImage === undefined || profileImage === '') {
          Toast(t('Profile Photo is Required'), 'error')
          setError({ profilePhoto: true })
          return
        }
      }
    }

    if (CommonUtil.isEmptyString(payload.firstName)) {
      Toast('Please Enter First Name', 'error')
      return
    }
    if (!CommonUtil.isValidNames(payload.firstName)) {
      Toast('Please Enter Valid First Name ', 'error')
      return
    }

    if (
      payload.scheduledStartDate === null ||
      payload.scheduledStartDate === undefined ||
      payload.scheduledStartDate === ''
    ) {
      Toast(t('INVITATIONFORM011'), 'error')
      return
    }

    if (
      payload.scheduledEndDate === null ||
      payload.scheduledEndDate === undefined ||
      payload.scheduledEndDate === ''
    ) {
      Toast(t('INVITATIONFORM012'), 'error')
      return
    }

    setIsLoading(true)

    dispatch(visitorUpdate(Object.assign(payload)))
      .unwrap()
      .then((res) => {
        if (res.code === 'OMSI0236') {
          if (!CommonUtil.isEmptyString(res.data.profilePhotoURL)) {
            uploadProfilePhoto(res)
            setIsLoading(true)
          }
          if (checkList?.questionaries?.length > 0) {
            setIsLoading(true)
            const enabledlistItems = checkList.questionaries.filter(
              (question) => {
                return question.disabled === false
              },
            )
            if (enabledlistItems.length > 0) {
              history.push({
                pathname: '/vis/check-list-form',
                state: {
                  success: true,
                  listValue: enabledlistItems,
                  id: paramid,
                  code: code,
                },
              })
            } else {
              httpCommon
                .securedAxios()
                .put('/web/visit/confirm/' + code)
                .then((res) => {
                  if (res.data.code === 'CVAI0072') {
                    history.push({
                      pathname: '/vis/visit-success',
                      state: { message: res.data.message, success: true },
                    })
                  } else {
                    Toast(res.data.message, 'error')
                  }
                })
                .catch((e) => {
                  console.log(e)
                })
            }
          } else {
            setIsLoading(true)
            httpCommon
              .unsecuredAxios()
              .put('/web/visit/confirm/' + code)
              .then((res) => {
                if (res.data.code === 'CVAI0072') {
                  history.push({
                    pathname: '/vis/visit-success',
                    state: { message: res.data.message, success: true },
                  })
                } else {
                  Toast(res.data.message, 'error')
                }
              })
              .catch((e) => {
                console.log(e)
              })
          }
        }
      })
    setIsLoading(false)
      .catch((e) => {
        console.log(e)
      })
  }

  async function handleProfile(file) {
    setPayload({
      ...payload,
      profilePhoto: payload.firstName,
    })
    setProfile(file.base64)
    setProfileImage(file.file)
  }

  const handleCancel = () => {
    const data = data
    httpCommon
      .securedAxios()
      .put('/web/visit/rejectvisit/' + code)
      .then((res) => {
        if (res.data.code === 'CVAI0073') {
          history.push({
            pathname: '/vis/reject',
            state: { message: res.data.message, reject: true },
          })
        } else {
          Toast(data.message, 'error')
        }
      })
      .catch((e) => {
        console.log(e)
      })
    setIsLoading(false)
    return
  }

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '40px',
          backgroundColor: '#F4F6F8',
        }}
      >
        <Grid container alignItems="center">
          <Grid item className="logo-center">
            <img src={USA_visLogo} alt="logo" style={{ width: '300px' }} />
          </Grid>
        </Grid>
      </Grid>
      <Paper>
        <Typography
          gutterBottom
          variant="h2"
          style={{ padding: '20px', textAlign: 'center', color: '#3D4977' }}
        >
          Visitor Details
        </Typography>
        <Grid container p={5} spacing={3}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            md={4}
            sm={12}
          >
            {profilePhotEnable || uploadpic ? (
              <Grid>
                <ProfileUplod
                  onsetProfile={(profile) => handleProfile(profile)}
                  profilePhoto={profile}
                  disabled={uploadpic}
                  confirmProfilePhoto={confirmProfilePhoto}
                />
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <Grid container md={8} sm={12} spacing={3}>
            <Grid item xs={12} sm={6}>
              <CustomTextfield1
                label={'First Name'}
                name={'firstName'}
                value={payload.firstName}
                required={true}
                handleChange={handleChange}
                error={error.firstName}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextfield1
                label={'Last Name'}
                name={'lastName'}
                value={payload.lastName}
                handleChange={handleChange}
                error={error.lastName}
                inputProps={{ maxLength: 25 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextfield1
                disabled
                label={'Email'}
                name={'email'}
                value={payload.email}
                validation="email"
                handleChange={handleChange}
                error={error.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
              {phoneInput === '' || phoneInput === undefined || phoneInput === null ?
                <CustomPhone1
                  placeholder="Mobile"
                  value={phoneInput}
                  country={phoneNumberCheck}
                  specialLabel={false}
                  handleChange={(value, data, event, formattedValue) =>
                    handlePhoneChange(value, data, event, formattedValue)
                  }
                  disabled
                  inputStyle={inputStyle} 
                /> : <CustomPhone1
                  placeholder="Mobile"
                  value={phoneInput}
                  country={phoneNumberCheck}
                  specialLabel={false}
                  handleChange={(value, data, event, formattedValue) =>
                    handlePhoneChange(value, data, event, formattedValue)
                  }
                  disabled
                  inputStyle={inputStyle} 
                />}
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield1
                disabled
                label={'Company Name'}
                name={'visitorCompanyName'}
                value={payload.visitorCompanyName}
                handleChange={handleChange}
                error={error.visitorCompanyName}
                validation="alpha-numeric"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextfield1
                disabled
                label={'Host (Person to meet)'}
                name={'hostName'}
                value={payload.hostName}
                validation="alpha-numeric"
                handleChange={handleChange}
                error={error.hostName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {dateFormat === 'dd-MM-yyyy' && (
                <CustomDateTimePicker1
                  onChange={(scheduledStartDate) =>
                    setPayload({
                      ...payload,
                      scheduledStartDate: scheduledStartDate,
                    })
                  }
                  value={payload.scheduledStartDate}
                  label={'Invite Start Date *'}
                  date={'dd-MM-yyyy HH:mm'}
                  disabled
                />
              )}
              {dateFormat === 'MM-dd-yyyy' && (
                <CustomDateTimePicker1
                  onChange={(scheduledStartDate) =>
                    setPayload({
                      ...payload,
                      scheduledStartDate: scheduledStartDate,
                    })
                  }
                  value={payload.scheduledStartDate}
                  label={'Invite Start Date *'}
                  date={'MM-dd-yyyy HH:mm'}
                  disabled
                />
              )}
              {dateFormat === 'yyyy-MM-dd' && (
                <CustomDateTimePicker1
                  onChange={(scheduledStartDate) =>
                    setPayload({
                      ...payload,
                      scheduledStartDate: scheduledStartDate,
                    })
                  }
                  value={payload.scheduledStartDate}
                  label={'Invite Start Date *'}
                  date={'yyyy-MM-dd HH:mm'}
                  disabled
                />
              )}
            </Grid>

            <Grid item lg={6} sm={12} xs={12}>
              {dateFormat === 'dd-MM-yyyy' && (
                <CustomDateTimePicker1
                  onChange={(scheduledEndDate) =>
                    setPayload({
                      ...payload,
                      scheduledEndDate: scheduledEndDate,
                    })
                  }
                  value={payload.scheduledEndDate}
                  minDateTime={payload.scheduledStartDate}
                  label="Invite End Date *"
                  date={'dd-MM-yyyy HH:mm'}
                  disabled
                />
              )}
              {dateFormat === 'MM-dd-yyyy' && (
                <CustomDateTimePicker1
                  onChange={(scheduledEndDate) =>
                    setPayload({
                      ...payload,
                      scheduledEndDate: scheduledEndDate,
                    })
                  }
                  value={payload.scheduledEndDate}
                  minDateTime={payload.scheduledStartDate}
                  label="Invite End Date *"
                  date={'MM-dd-yyyy HH:mm'}
                  disabled
                />
              )}
              {dateFormat === 'yyyy-MM-dd' && (
                <CustomDateTimePicker1
                  onChange={(scheduledEndDate) =>
                    setPayload({
                      ...payload,
                      scheduledEndDate: scheduledEndDate,
                    })
                  }
                  value={payload.scheduledEndDate}
                  minDateTime={payload.scheduledStartDate}
                  label="Invite End Date *"
                  date={'yyyy-MM-dd HH:mm'}
                  disabled
                />
              )}
            </Grid>
            {isLoading ? (
              <Box sx={{ display: 'flex' }} className="loader-container">
                <CircularProgress className="loader" />
              </Box>
            ) : (
              <Grid
                container
                lg={12}
                md={12}
                xs={11}
                p={5}
                pt={0}
                spacing={2}
                marginTop={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <SubmitButtons
                    disabled={isLoading}
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    <Typography>{'Continue'}</Typography>
                  </SubmitButtons>
                </Grid>
                <Grid item>
                  <CancelButtons
                    color="secondary"
                    onClick={handleCancel}
                    variant="contained"
                  >
                    <Typography>{'Cancel'}</Typography>
                  </CancelButtons>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default ConfirmVisitV2
