import React, { useState, useContext } from "react";
import { Grid, styled, TextField, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CalenderIcon from '../../assets/Calender';
import Toast from "../../components/ToastContainer/CustomToast";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { createTheme } from "@mui/material/styles";
import { es, enUS } from 'date-fns/locale';
import { useTranslation } from "react-i18next";
import { ThemeContext } from '../../theme/ThemeContext';

const CustomIconLeft = styled(ChevronLeftIcon)(({ theme }) => ({
    color: process.env.REACT_APP_BG_ICON,
    border: process.env.REACT_APP_BG_BORDER,
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: process.env.REACT_APP_BG_ICON,
      color: "#FFFF",
    },
  }));
  
  const CustomIconRight = styled(ChevronRightIcon)(({ theme }) => ({
    color: process.env.REACT_APP_BG_ICON,
    border: process.env.REACT_APP_BG_BORDER,
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: process.env.REACT_APP_BG_ICON,
      color: "#FFFF",
    },
  }));
  
  // Component function
  const CustomDateTimePicker1 = (props) => {
    const [dateOpen, setDateOpen] = useState(false);
    const { t } = useTranslation();
    const [scheduledStartDate, setScheduledStartDate] = useState(new Date());
  
    const handleDate = (e) => {
      setScheduledStartDate(e);
      props.SelectedDate(e);
    };
  
    const onKeyDown = (e) => {
      e.preventDefault();
      Toast(t("INVITESTARTDATEERROR3"), "error");
    };
  
    // Inline styles for label color
    const labelStyle = {
      color: '#0000008a',
    };
  
    return (  
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={props.label.includes("Fecha de inicio") || props.label.includes("Fecha de finalización") ? es : enUS}>
        <DesktopDateTimePicker
          disabled={props.disabled}
          hideTabs={false}
          components={{
            OpenPickerIcon: CalenderIcon,
            LeftArrowIcon: CustomIconLeft,
            RightArrowIcon: CustomIconRight,
          }}
          dateRangeIcon={<CalenderIcon />}
          label={props.label}
          disablePast={props.disablePast}
          disableFuture={props.disableFuture}
          onChange={props.onChange}
          onChangeText={props.onChangeText}
          inputFormat={props.date}
          value={props.value}
          minDateTime={props.minDateTime}
          type="datetime-local"
          InputProps={{
            classes: {
              root: 'cssOutlinedInput',
              focused: 'cssFocused',
              notchedOutline: 'notchedOutline',
            },
          }}
          renderInput={(params) => (
            <TextField
              onKeyDown={onKeyDown}
              style={{ width: '-webkit-fill-available' }}
              {...params}
              InputLabelProps={{
                style: labelStyle, // Apply dynamic label color
              }}
            />
          )}
        />
      </LocalizationProvider>
    );
  };
  
  export default CustomDateTimePicker1;