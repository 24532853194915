import { colors, makeStyles } from '@material-ui/core'
import { Close, DarkMode } from '@mui/icons-material'
import { Autocomplete, Grid, TextField, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Device from '../../assets/DeviceIcon/pic.png'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import Toast from '../../components/ToastContainer/CustomToast'
import TitleBar from '../../components/v4/TitleBar'
import api from '../../config/http-common'
import { getAllSites } from '../../slices/Site/SiteSlice'
import {
  bindDevice,
  findDeviceBySn
} from '../../slices/Zlink Device Management/DeviceSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import CommonUtil from '../../Util/CommonUtils'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'

const useStyles = makeStyles((theme) => ({
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}))



export default function AddDevicePage(props) {
  const classes = useStyles();
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [siteList, setSiteList] = React.useState([])
  const [siteId, setSiteId] = React.useState('')
  const [zoneList, setZoneList] = React.useState([])
  const [zones, setZones] = React.useState([])
  const [siteData, setSiteData] = useState([])
  const [changeDate, setDateChange] = useState(true)
  const { darkMode } = useContext(ThemeContext);
  const [state, setState] = React.useState({
    deviceId: '',
    sn: '',
    siteId: '',
    zoneId: '',
    deviceName: 'b012',
  })
  const [error, setError] = React.useState({
    deviceId: '',
    sn: '',
    siteId: '',
    zoneId: '',
    deviceName: '',
  })

  useEffect(() => {
    if (open === true) {
      loadSites()
      loadZones()
      // loadDevice();
    }
  }, [open])
  const styleDialog = {
    '& .MuiDialog-paper': {
      padding: '30px',
      width: '550px',
      backgroundColor: darkMode ? '#0F273B' : '',
    },
  }

  const ITEM_HEIGHT = 40
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  }

  const loadSites = () => {
    const query = {
      page: 1,
      rowsPerPage: 10000,
      name: '',
    }
    dispatch(getAllSites(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000') {
          setSiteData(data.data.site)
          setDateChange(true)
        } else {
          setSiteData([])
        }
      })
  }

  const loadZones = () => {
    api
      .securedAxios()
      .get(`/api/v2.0/zlink/zones`)
      .then((response) => {
        if (response.data.code === 'LMSI0000')
          setZoneList(response.data.data.zone)
      })
  }

  // const loadDevice = (payload) => {
  //   api.securedAxios().get(`/web/device/${payload.id}`).then((response) => {
  //     if (response.data.code === 'DMSI0000')
  //     alert("find devicename" + JSON.stringify(response.data))
  //   });
  // };

  useEffect(() => {
    if (!CommonUtil.isEmptyArray(zoneList)) {
      const zoneFilter = zoneList.filter((zone) => {
        return zone.siteId === siteId
      })
      setZones(zoneFilter)
    }
  }, [siteId])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  const handleChange = (event) => {
    const name = event.target.name
    const inputValue = event.target.value
    const pattern = /^[a-zA-Z0-9\s]*$/
    if (pattern.test(inputValue)) {
      setState({
        ...state,
        [name]: inputValue,
      })
      setError({
        ...error,
        [name]: false,
      })
    } else {
      setError({
        [name]: true,
      })
    }
  }

  const handleClose = () => {
    setOpen(!open)
  }

  const findDevice = () => {
    if (CommonUtil.isEmptyString(state.sn)) {
      Toast(t('ENTER_DEVICE_NUMBER'), 'error')
      setError({ sn: true })
      return
    }

    dispatch(findDeviceBySn(state.sn))
      .unwrap()
      .then((data) => {
        if (data.code === 'DMSI0000') {
          setState({
            ...state,
            deviceId: data.data.deviceId,
          })
          setOpen(true)
        } else {
          if (data.code === 'DMSE0003') Toast(t('ERROR_DEVICE_NOT_EXIST'), 'error')
        }
      })
  }

  const handleSubmit = (props) => {
    if (CommonUtil.isEmptyString(state.siteId)) {
      setError({
        siteId: Toast(t('SITE_ZONE_FIELD_REQUIRED'), 'error'),
      })
      return
    }
    if (CommonUtil.isEmptyString(state.zoneId)) {
      setError({
        zoneId: Toast(t('SITE_ZONE_FIELD_REQUIRED'), 'error'),
      })
      return
    }

    dispatch(bindDevice(state))
      .unwrap()
      .then((data) => {
        if (data.code === 'DMSI0000') {
          Toast(t('DEVICE_BIND'), 'success')
          history.push('/vis/device-list')
        } else {
          if (data.code === 'DMSE0028') Toast(t('DEVICE_ALREADY_BOUNDED'), 'error')
          if (data.code === 'DMSE0080') Toast(t('DEVICE_UNABLE_AUTHORIZE'), 'error')
        }
        handleClose()
      })
  }

  const zoneField = () => (
    <Autocomplete
      required
      error={error.zoneId}
      fullWidth
      disablePortal
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="zone bind list"
      getOptionLabel={(option) => option.name}
      options={zones}
      renderInput={(params) => (
        <CustomTextfield
          {...params}
          helperText={error.zoneId}
          error={error.zoneId}
          required
          label={t('BindZone')}
        />
      )}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            zoneId: newValue.id,
          })
        }
      }}
      ListboxProps={{ style: { maxHeight: '90px' } }}
    />
  )

  const siteField = (key) => (
    <Autocomplete
      fullWidth
      error={error.siteId}
      disablePortal
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="site bind list"
      getOptionLabel={(option) => option.name}
      options={siteData}
      sx={{ margin: '20px 0px' }}
      renderInput={(params) => (
        <CustomTextfield
          {...params}
          required
          helperText={error.siteId}
          error={error.siteId}
          label={t('BindSite')}
        />
      )}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            siteId: newValue.id,
          })
          setSiteId(newValue.id)
        }
      }}
      ListboxProps={{ style: { maxHeight: '150px' } }}
    />
  )

  return (
    <>
      <TitleBar title={t('BindSite')} back_button={true} />
      <br />
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        style={{ marginBottom: '1rem', fontWeight: 'bold', color: darkMode ? '#fff' : '#242424', marginLeft: darkMode ? '56px' : '23px' }}
      >
        {t('DEVICEINFOO9')}
      </Typography>
      <Grid
        style={{
          backgroundColor: darkMode ? '#0F273B' : '#FFFF',
          padding: '1rem',
          borderRadius: '8px',
          marginLeft: darkMode ? '50px' : '21px',
          marginRight: darkMode ? '35px' : ''
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          style={{ fontWeight: 'bold', color: darkMode ? '#fff' : '#242424', marginTop: '20px' }}
        >
          {t('DEVICEINFO10')}
        </Typography>
        <br />
        <Typography
          className="device-instruction"
          style={{ color: '#707070', paddingBottom: '10px', fontSize: '16px' }}
        >
          1. {t('DEVICEINFO11')}
        </Typography>
        <Typography
          className="device-instruction"
          style={{ color: '#707070', paddingBottom: '10px', fontSize: '16px' }}
        >
          2. {t('DEVICEINFO12')}
          {t('DEVICEINFO13')}
        </Typography>
        <Typography
          className="device-instruction"
          style={{ color: '#707070', paddingBottom: '10px', fontSize: '16px' }}
        >
          3. {t('DEVICEINFO14')}{' '}
        </Typography>
        <Typography
          className="device-instruction"
          style={{ color: '#707070', paddingBottom: '10px', fontSize: '16px' }}
        >
          4. {t('DEVICEINFO15')}{' '}
        </Typography>
      </Grid>
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        style={{ margin: '1rem 0rem', fontWeight: 'bold', color: darkMode ? '#fff' : '#242424', marginLeft: darkMode ? '48px' : '17px' }}
      >
        {t('DEVICEINFO16')}
      </Typography>
      <Grid
        container
        style={{
          backgroundColor: darkMode ? '#0F273B' : '#FFFF',
          padding: '2rem',
          borderRadius: '8px',
          marginLeft: darkMode ? '47px' : '',
          width: darkMode ? '95%' : ''
        }}
      >
        <Grid item sm={4} paddingRight={1}>
          <CustomTextfield
            style={{ color: '#000' }}
            error={error.sn}
            required
            fullWidth
            name="sn"
            label={t('DeviceSN')}
            helperText={error.sn ? t('VAILD_SERIAL_NUMBER') : ''}
            variant="outlined"
            value={state.sn}
            handleChange={handleChange}
            inputProps={{ className: classes.input }}
          />
        </Grid>
      </Grid>
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
          marginTop: '1rem',
          marginLeft: darkMode ? '-29px' : ''
        }}
      >
        <SubmitButtons onClick={() => findDevice()}>
          {t('ADD026')}
        </SubmitButtons>
      </Grid>
      <Dialog sx={styleDialog} onClose={() => setOpen(!open)} open={open}>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4" gutterBottom component="div" style={{color: darkMode ? '#fff' : ''}}>
            {t('DEVICEINFO19')}
          </Typography>
          <Close onClick={() => setOpen(false)} />
        </Grid>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center',
            alignItems: 'center',
            border: '0.5px solid #FFFF',
            backgroundColor: darkMode ? '#36C96D' : '#e9e9e9',
            color: darkMode? '#fff' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.5rem',
            borderRadius: '8px',
            marginTop: '0.5rem',
          }}
        >
          <img
            alt="complex"
            src={Device}
            style={{ height: '60px', width: '60px' }}
          />
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0rem 0.5rem',
            }}
          >
            <Typography>{state.deviceName}</Typography>
            {/* <Typography variant='fieldLabel'>{props.updateData.deviceName}</Typography> */}
            <Typography variant="fieldLabel">{state.sn}</Typography>
          </Grid>
        </Grid>
        <Typography
          variant="title"
          gutterBottom
          component="div"
          style={{ fontSize: '14px', margin: '0.5rem 0rem', color: darkMode ? '#fff' : '#304669' }}
        >
          {t('DEVICEINFO17')}
        </Typography>
        <Typography
          style={{
            color: darkMode ? '#fff' : '#2281AB',
            fontSize: '14px',
            cursor: 'pointer',
            margin: '0.5rem 0rem',
            opacity: 1,
          }}
        >
          {t('DEVICEINFO18')}
        </Typography>
        <Grid>
          {siteField()}
          <br />
          {zoneField()}
        </Grid>
        <Grid
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '1rem',
          }}
        >
          <SubmitButtons onClick={() => handleSubmit()}>
            {t('dailogButtonSave')}
          </SubmitButtons>
          <CancelButtons onClick={() => setOpen(false)}>
            {t('dataTableCancel')}
          </CancelButtons>
        </Grid>
      </Dialog>
    </>
  )
}
