import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DeviceTypeService from '../../services/DeviceTypeService/DeviceTypeService'

export const getDeviceTypeList = createAsyncThunk('/deviceTypeLists', async (payload) => {
    const res = await DeviceTypeService.getDeviceTypeList(payload)
    return res.data
  })

export const updateDeviceType = createAsyncThunk('/deviceTypes' ,async (payload) => {
  const res = await DeviceTypeService.updateDeviceType(payload)
  return res.data;
})
