import { Avatar } from '@material-ui/core'
import {
  Box,
  CircularProgress,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import isoCountries from 'i18n-iso-countries'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import '../../App.css'
import CommonUtil from '../../Util/CommonUtils'
import { REACT_CREATE_DEALER } from '../../actions/EndPoints'
import forwardIcon from '../../assets/Customer/forward.svg'
import DealerIcon from '../../assets/USA_Images/dealer/dealer.svg'
import DataTable from '../../components/DataTable/DataTable'
import Search from '../../components/SearchTab/Search'
import Toast from '../../components/ToastContainer/CustomToast'
import { getCompanyInfo } from '../../slices/Company/CompanySlice'
import {
  getDealerBillingInfo,
  getDealers,
  resendActivationLinkForDealer,
} from '../../slices/Dealer/DealerSlice'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import EmployeeDeleteIcon from '../Employee/EmployeeDeleteIcon'
import ResendIcon from '../Invitations/ResendIcon'
import ViewIcon from '../Invitations/ViewIcon'
import EditIcon from '../Person/EditIcon'
import CreateDealer from './CreateDealer'
import DealerExport from './DealerExport'
import DealerFilter from './DealerFilter'
import DeleteDealer from './DeleteDealer'

export default function Dealer() {
  const history = useHistory()
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [open, setOpen] = useState(false)
  const [openEditDealer, setOpenEditDealer] = useState(false)
  const [clear, setClear] = React.useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const [selectedData, onSelectionChange] = useState([])
  const [pageSize, setPageSize] = useState(0)
  const [openDownload, setOpenDownload] = useState(false)
  const [openDeleteDealer, setOpenDeleteDealer] = useState(false)
  const [dealerId, setDealerId] = useState()
  const [dealerName, setDealerName] = useState()
  const [searchRecord, setSearchRecord] = useState()
  const [editRowData, setEditRowDate] = useState({})
  const [editDealerPage, setEditDealerPage] = useState(false)
  const [parentCompanyCountry, setParentCompanyCountry] = useState('')
  const [loading, setLoading] = useState(true)
  const [selectedObject, setSelectedObject] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    companyCode: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    state: '',
    city: '',
    postCode: '',
    profile: '',
  })

  const [filter, setFilter] = React.useState({
    status: '',
    name: '',
    email: '',
    type: 'PARTNER',
  })

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const defaultFilter = {
    status: '',
    name: '',
    email: '',
    type: 'PARTNER',
  }

  const applyFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      status: data.status,
      name: data.name,
      email: data.email,
      type: 'PARTNER',
    })
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
    setLoading(true)
  }

  useEffect(() => {
    loadParentCompany()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      loadData(filter, paging)
    }, 1000)
  }, [filter, paging, clear])

  const handleChange = (newPage, size) => {
    setLoading(true)
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  const handleOpen = () => {
    setOpen(true)
    history.push({
      pathname: REACT_CREATE_DEALER,
      country: parentCompanyCountry,
    })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...'
    } else {
      return text
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const loadData = (filter, paging) => {
    dispatch(getDealers(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4007') {
          setLoading(false)
          setTotalRecords(data.data.totalCount)
          setRows(data.data.companies)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
      .catch((er) => {})
  }

  const loadParentCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setParentCompanyCountry(countryCode)
          }
        }
      })
      .catch((er) => {})
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  const handleOpenEditDealer = (params) => {
    setEditRowDate(params)
    setEditDealerPage(true)
    dispatch(getDealerBillingInfo(params.id))
      .unwrap()
      .then((data) => {
        history.push({
          pathname: REACT_CREATE_DEALER,
          state: {
            params: params,
            billingInfo: data.data,
          },
          country: parentCompanyCountry,
        })
      })
      .catch((e) => {})
  }

  const handleView = (params) => {
    history.push({
      pathname: '/vis/viewDealerDetails',
      state: params,
    })
  }

  const handleDeleteDealers = (params) => {
    setOpenDeleteDealer(!openDeleteDealer)
    setDealerId(params.id)
    setDealerName(params.name)
  }

  const globalsearch = (searchedVal) => {
    setSearchRecord(searchedVal)
    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.email ? test.email.includes(searchedVal.toLowerCase()) : '') ||
        (test.status
          ? test.status.trim().toLowerCase() ===
            searchedVal.trim().toLowerCase()
          : '') ||
        (test.phone
          ? test.phone.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.adminName
          ? test.adminName.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        `${test.name}`.toLowerCase().includes(searchedVal.toLowerCase())
      )
    })
    setRows(filteredRows)
  }

  const handleResendAccountActivate = (params) => {
    dispatch(resendActivationLinkForDealer(params))
      .unwrap()
      .then((data) => {
        switch (data.code) {
          case 'MSGS4015':
            Toast(t('DEALERRESENDACTIVEEMAIL'), 'success')
            break
          case 'MSGE4006':
            Toast(t('DEALERCHECKACTIVEEMAILNOT'), 'error')
            break
          default:
            Toast(data.message, 'error')
            break
        }
      })
      .catch((e) => {})
  }

  const handleDownload = () => {
    if (rows.length === 0) {
      Toast(t('dataTableEmptyList'), 'error')
      return
    } else {
      setOpenDownload(true)
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          alt={params.row.name}
          src={params.row.imagePreSignedURL}
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
          }}
          alt={params.row.name}
          src={params.row.imagePreSignedURL}
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(
    () => [
      {
        field: 'dealer.profile',
        headerName: '',
        sortable: false,
        width: 5,
        renderCell: (params) => {
          return ZoomHandaler(params)
        },
      },
      {
        field: 'dealer.name',
        headerName: t('CUSTDATABLE01'),
        flex: 1,
        minWidth: 190,
        valueGetter: (params) => params.row.name,
        renderCell: (params) => (
          <Tooltip title={` ${params.row.name}`} placement="bottom-start">
            <span>{handleTextOverflow(params.row.name)}</span>
          </Tooltip>
        ),
      },
      {
        field: 'dealer.email',
        headerName: t('CUSTDATABLE02'),
        flex: 1,
        minWidth: 190,
        valueGetter: (params) => params.row.email,
        renderCell: (params) => (
          <Tooltip title={` ${params.row.email}`} placement="bottom-start">
            <span>{handleTextOverflow(params.row.email)}</span>
          </Tooltip>
        ),
      },
      {
        field: 'dealer.phone',
        headerName: t('CUSTDATABLE03'),
        type: 'singleSelect',
        flex: 1,
        minWidth: 190,
        valueGetter: (params) => params.row.phone,
      },
      {
        field: 'dealer.AdminName',
        headerName: t('CUSTDATABLE04'),
        flex: 1,
        minWidth: 190,
        valueGetter: (params) => params.row.adminName,
      },
      {
        field: 'dealer.status',
        headerName: t('CUSTDATABLE07'),
        flex: 1,
        minWidth: 190,
        valueGetter: (params) => params.row.status,
      },
      {
        field: 'Actions',
        headerName: t('CUSTDATABLE16'),
        type: 'actions',
        flex: 1,
        minWidth: 150,
        width: 150,
        headerAlign: 'center',
        getActions: (params) => [
          <GridActionsCellItem
            icon={<ViewIcon />}
            label={t('VIEW')}
            onClick={() => handleView(params.row)}
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label={t('EDIT')}
            onClick={() => handleOpenEditDealer(params.row)}
          />,

          <GridActionsCellItem
            icon={<EmployeeDeleteIcon />}
            label={t('DELETE')}
            onClick={() => handleDeleteDealers(params.row)}
          />,
          <GridActionsCellItem
            icon={<ResendIcon />}
            label={t('RESENDMAIL')}
            onClick={() => handleResendAccountActivate(params.row)}
          />,
        ],
      },
    ],
    [],
  )
  return (
    <>
      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}
      <Stack direction="row">
        <Box style={{ margin: '16px, 0' }}>
          <Typography variant="h3">{t('DASHBOARDTABDEALER')}</Typography>
        </Box>

        <Box
          display={'flex'}
          width={'100%'}
          flexDirection={'row-reverse'}
          alignItems={'center'}
        >
          <Box item style={{ margin: '0 8px' }} className="table_iconStyle">
            <Tooltip title={t('ADDDEALER')}>
              <img alt="Dealer" src={DealerIcon} onClick={handleOpen} />
            </Tooltip>
          </Box>

          <Box item>
            {clear && (
              <Link
                href="#"
                underline="hover"
                className="line"
                style={{
                  opacity: '0.8',
                  color: '#E3393C',
                  fontSize: '14px',
                  underline: 'hover',
                  display: 'inline',
                }}
                onClick={resetFilter}
              >
                {t('CLEARFILTER')}
              </Link>
            )}
          </Box>

          <Box item style={{ margin: '0 8px' }} className="table_iconStyle1">
            <DealerFilter
              rowsPerPage={rowsPerPage}
              applyFilter={(data) => applyFilter(data)}
            />
          </Box>

          <Box item style={{ margin: '0 8px' }}>
            <Tooltip title={t('EXPORT')} onClick={handleDownload}>
              <img src={forwardIcon} />
            </Tooltip>
          </Box>

          <Box item>
            <Search
              onSearch={(e) => globalsearch(e)}
              clearSearch={() => loadData(filter, paging)}
            />
          </Box>
        </Box>
      </Stack>

      <div style={{ height: 300, width: '100%', marginTop: '15px' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          rowId={(row) => row.id}
          handleChange={(newPage, size) => handleChange(newPage, size)}
          onSelectedRoles={(rows) => onSelectionChange(rows)}
        />
      </div>

      {openEditDealer && (
        <CreateDealer
          selectedObject={selectedObject}
          open={openEditDealer}
          handleClose={() => {
            handleClose(loadData(page, rowsPerPage))
          }}
        />
      )}

      {openDeleteDealer && (
        <DeleteDealer
          open={openDeleteDealer}
          handleDialogClose={handleClose}
          loadData={() => {
            loadData(filter, paging)
          }}
          handleClose={(data) => {
            setOpenDeleteDealer(data)
          }}
          dealerId={dealerId}
          dealerName={dealerName}
        />
      )}

      {openDownload && (
        <DealerExport
          open={openDownload}
          handleClose={() => {
            setOpenDownload(false)
          }}
          status={filter}
          searchRecords={searchRecord}
        />
      )}
    </>
  )
}
