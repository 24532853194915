import api from '../../config/http-common'

const createEmailReport = (payload) => {
    return api.securedAxios().post('/web/email/report', payload)
  }

  const EmailReportService = {
    createEmailReport,
  }

  export default EmailReportService