import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, IconButton } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import Button from '@mui/material/Button'
import Toast from '../../components/ToastContainer/CustomToast'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { getVisitLimit } from '../../slices/Subscription/SubscriptionSlice'
import { useDispatch } from 'react-redux'
import jwt_decode from 'jwt-decode'
import PurchaseDialog from '../../components/DialogBoxComponent/PurchaseDialog'

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    background: '#3d4977',
    width: '100%',
    padding: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    color: '#FFFFFF',
  },
  scrollbarContainer: {
    maxHeight: '280px',
    overflowY: 'auto',
    marginLeft: '300px',
    textAlign: 'left',
    marginTop: '-189px',
  },
  dialogbox: {
    '& .MuiDialog-paper': {
      minWidth: '120vh',
      borderRadius: '14px',
      backgroundColor: '#yourBackgroundColor',
    },
  },
}))

function ListViewSubscription(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(props.open)
  const [featureNameList, setFeatureNameList] = useState(props.featureNameList)
  const planName = props.planName
  const price = props.price
  const id = props.id
  const subscriptionType =
    props.subscriptionType === 'Pay annual' ? 'Yearly' : 'Monthly'
  const [visitorCount, setVisitorCount] = useState(0)
  const [purchaseDialog, setPurchaseDialog] = useState(false)

  const [purchaseData, setPurchaseData] = useState({
    planName: props.planName,
    price: props.originalPrice,
    productId: props.productId,
  })
  const dispatch = useDispatch()
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const handleClose = () => {
    setOpen(false)
  }

  const handlePurchase = () => {
    setPurchaseDialog(!purchaseDialog)
  }

  const handleOpen = () => {
    if (planName === '') {
      Toast(t('Please Enter Plan Name'), 'error')
      return
    }
    if (price === '') {
      Toast(t('Please Enter Price'), 'error')
      return
    }
    if (subscriptionType === '') {
      Toast(t('Please select Subscription Type'), 'error')
      return
    }

    dispatch(getVisitLimit(id))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000' && data.data.visitLimit !== undefined)
          setVisitorCount(data.data.visitLimit)
        else setVisitorCount(0)
      })

    setOpen(true)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 13) {
      return text.substring(0, 17) + '...'
    } else {
      return text
    }
  }

  return (
    <>
      <div>
        {decoded_jwtToken.companyType === 'PARTNER' ? (
          <Button
            size="small"
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              fontSize: '14px',
              bottom: '120px',
              right: '-3px',
              height: '10px',
              mt: '10px',
            }}
            onClick={handleOpen}
          >
            {t('SUBSCRIPTIONVIEWFEATURES')}
          </Button>
        ) : (
          <Button
            size="small"
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
              fontSize: '14px',
              bottom: '110px',
              right: '-3px',
            }}
            onClick={handleOpen}
          >
            {t('SUBSCRIPTIONVIEWFEATURES')}
          </Button>
        )}

        {decoded_jwtToken.companyType === 'PARTNER' ? (
          <Button
            size="small"
            sx={{
              '&:hover': {
                backgroundColor: '#F2824C',
              },
              fontSize: '14px',
              bottom: '140px',
              right: '-3px',
              height: '10px',
              ml: '40px',
              mt: '20px',
              backgroundColor: '#F2824C',
              color: 'white',
            }}
            onClick={handlePurchase}
          >
            {t('SUBSCRIPTIONPURCHASE')}
          </Button>
        ) : (
          ''
        )}
      </div>
      <Dialog
        open={open}
        // onClose={handleClose}
        className={classes.dialogbox}
        aria-labelledby="form-dialog-title"
      >
        <div className="main">
          {/* Your dialog content here */}
          <div className="popup">
            <div className="popup-header">
              <div className="uui">{/* <h3>Customize :- $100</h3> */}</div>
              <IconButton>
                <CloseIcon onClick={handleClose}>X</CloseIcon>
              </IconButton>
            </div>
            {planName != '' ? (
              <Tooltip title={`${planName}`} placement="bottom-start">
                <div
                  // style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  className="uuv"
                >
                  <h3 className="xxa">{handleTextOverflow(planName)}</h3>
                </div>
              </Tooltip>
            ) : (
              ''
            )}
            <div className="iim">
              {price !== '' ? <h2 className="xxb">{price}</h2> : ''}
              {subscriptionType !== '' ? (
                <p className="ppa">{subscriptionType === 'Monthly'?t('MONTHLY'):t('YEARLY_UPPER_CASE')}</p>
              ) : (
                ''
              )}
              {visitorCount !== '' ? (
                <p className="ppm">{t('SUBSCRIPTIONVISITORCOUNT') + visitorCount}</p>
              ) : (
                ''
              )}
            </div>
            <div className={classes.scrollbarContainer}>
              <ul className="zzm">
                <div className="ppz">{t('SUBSCRIPTIONFEATURES')}</div>
                <br></br>
                {featureNameList.map((feature, index) => (
                  <li key={index} style={{ paddingBottom: '10px' }}>
                    <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                    {feature.name}
                  </li>
                ))}
                <br></br>
                <div className="ppz">{t('SUBSCRIPTIONCOMMONFEATURES')}</div>
                <br></br>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES1')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES2')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES3')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES4')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES5')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES6')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES7')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES8')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES9')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES10')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES11')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES12')} 
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES13')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES14')} 
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES15')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES16')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES17')}
                </li>
                <li style={{ paddingBottom: '10px' }}>
                  <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                  {t('SUBSCRIPTIONCOMMONFEATURES18')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Dialog>

      {purchaseDialog && (
        <PurchaseDialog
          open={purchaseDialog}
          data={purchaseData}
          purchasePlanDialog={props.purchasePlanDialog}
          handlePurchasedPlan={props.handlePurchasedPlan}
          subscriptionId={props.id}
          yearlyPlan={props.yearlyPlan}
          handleClose={() => {
            setPurchaseDialog(false)
          }}
        />
      )}
    </>
  )
}

export default ListViewSubscription
