import { Grid } from "@material-ui/core";
import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as CancelVisitIcon } from "../../../src/assets/CancelVisit.svg";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { deleteDealers } from "../../slices/Dealer/DealerSlice";

export default function DeleteDealer(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [payload, setPayload] = React.useState({
    id: props.dealerId,
    name: props.dealerName,
  });

  const handleSubmit = () => {
    dispatch(deleteDealers(payload))
      .unwrap()
      .then((data) => {
        handleClose(false);
        switch (data.code) {
          case "UASI0008":
            Toast(t('DEALERDELETESUCCESS'), "success");
            props.loadData();
            handleClose();
            break;
            case "MSGE4004":
              Toast(t('DEALERCHECKACTIVEORINACTIVE'), "error");
              break;
          default:
            Toast(data.message, "error");
            break;
        }
      })
      .catch((e) => { })
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 10) {
      return text.substring(0, 8) + '...';
    } else {
      return text;
    }
  }

  return (
    <>
      <DialogBox
        Header={t('ADD038')}
        acceptText={t("CONFIRM")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
          style={{ padding: "20px" }}
        >
          <Grid>
            <CancelVisitIcon />
          </Grid>
          <Grid>
            <Grid
              style={{
                wordWrap: "break-word",
                opacity: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                fontSize: "17px",
                marginTop: "15px",
              }}
            >
              <Typography style={{ color: "#242424", marginRight: "4px", fontSize: "15px" }}>
                {t('ADD040')}
              </Typography>
              <Tooltip title={payload.name}>
                <Typography style={{ fontWeight: "bold", marginRight: "4px", fontSize: "15px" }}>
                  {handleTextOverflow(payload.name)}
                </Typography>
              </Tooltip>
              ?
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
