import React, { useContext, useEffect, useState } from "react";
// import CloseIcon from "@mui/icons-material/Close";
import { Dialog, makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from "react-redux";
import CustomTextfield from "../../../src/components/CustomInputs/CustomTextfield";
import CommonUtil from '../../Util/CommonUtils';
import Toast from '../../components/ToastContainer/CustomToast';
import { doorControl, getDoors } from "../../slices/DoorSlice/DoorSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import CancelButtons from "../DialogBoxComponent/CancelButtons";
import SubmitButtons from "../DialogBoxComponent/SubmitButtons";
import EmergencyDoorSearch from "../SearchTab/EmergencyDoorSearch";


const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: "20px",
    minWidth: '650px',
    minHeight: '700px'
  },
}));

export default function EmergencyDoorDialog(props) {
  const classes = useStyles();
  const { darkMode } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [doorsPayload, setDoorsPayload] = useState([]);
  const [delayTime, setDelayTime] = useState(10);
  const { onSubmit, children, onClose, ...other } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation()

  const [error, setError] = useState({
    operator: false,
    type: false,
    triggerNum: false,
    delayTime: false
  });
  const [doorData, setDoorData] = useState([]);
  const [selectedDoors, setSelectedDoors] = useState([]);

  const handleToggle = (door) => () => {
    const currentIndex = selectedDoors.findIndex(selectedDoor => selectedDoor.doorParameters.name === door.doorParameters.name && selectedDoor.deviceId === door.deviceId);
    const newSelected = [...selectedDoors];

    if (currentIndex === -1) {
      const selectedPyload = {
        deviceId: door.deviceId,
        operator: 1001,
        type: 0,
        triggerNum: parseInt(door.doorParameters.doorNo),
        delayTime: parseInt(delayTime)
      }
      doorsPayload.push(selectedPyload)
      newSelected.push(door);
    } else {
      doorsPayload.splice(currentIndex, 1);
      newSelected.splice(currentIndex, 1);
    }
    setSelectedDoors(newSelected);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allDoors = doorData.map(door => ({
        deviceId: door.deviceId,
        operator: 1001,
        type: 0,
        triggerNum: parseInt(door.doorParameters.doorNo),
        delayTime: parseInt(delayTime)
      }));
      setDoorsPayload(allDoors);
      setSelectedDoors(doorData);
    } else {
      setDoorsPayload([]);
      setSelectedDoors([]);
    }
  };

  const globalsearch = (searchedVal) => {
    const trimmedSearchVal = searchedVal.trim().toLowerCase();
    const searchKeywords = trimmedSearchVal.split(' ');
    const filteredRows = doorData.filter((door) => {
      const trimmedDoorName = door.doorParameters.name.trim().toLowerCase();
      return searchKeywords.every(keyword => trimmedDoorName.includes(keyword));
    });
    setDoorData(filteredRows);
  };


  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const updatedPayload = doorsPayload.map(payload => ({
      ...payload,
      delayTime: parseInt(delayTime)
    }));
    setDoorsPayload(updatedPayload);
  }, [delayTime]);

  const loadData = () => {
    dispatch(getDoors())
      .unwrap()
      .then((data) => {
        if (data.code === "DMSI0000" && data.data) {
          setDoorData(data.data.doors);
        }
        if (data.code === "DMSE0004")
          Toast(t('PLEASE_ADD_DEVICE'), "warning");
      })
  }


  const handleSubmit = () => {
    if (CommonUtil.isEmptyArray(selectedDoors)) {
      Toast(t('PLEASE_SELECT_DOOR'), "error");
      return false;
    }

    if (!CommonUtil.isEmptyArray(selectedDoors) && selectedDoors.some(door => door.status === 0)) {
      Toast(t('PLEASE_DESELECT_OFFLINE'), "error");
      return false;
    }

    if (delayTime === "") {
      Toast(t('SET_TIME_CAN_NOT_EMPTY'), "error");
      setError({ ...error, delayTime: true });
      return false;
    }

    if (delayTime === "0") {
      Toast(t('SET_TIME_CAN_NOT_ZERO'), "error");
      setError({ ...error, delayTime: true });
      return false;
    }

    if (parseInt(delayTime) > 254) {
      Toast(t('SET_TIME_CAN_NOT_EXCEED_SEC'), "error");
      setError({ ...error, delayTime: true });
      return false;
    }
    dispatch(doorControl(doorsPayload))
      .unwrap()
      .then((data) => {
        if (data.code === "MSGS4018") {
          Toast(t('REQUEST_SENT_SUCCESSFULLY'), "success");
          props.onClose(false);
        }
        else
          Toast((data.message), "error");
      })
  };


  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    setDelayTime(event.target.value);
  };

  const reset = () => {
    props.onClose(false);
  };

  return (
    <>
      <Dialog classes={{ paper: classes.dialogPaper }} {...props} PaperProps={{
        style: {
          backgroundColor: darkMode ? '#0F273B' : '#fff', // Set the background color to red
        }
      }}>
        <DialogTitle variant="h4" onClick={handleClose}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {props.Header}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <EmergencyDoorSearch
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData()}
          />
          <Grid>
            <h3 style={{ position: "relative", fontSize: "17px", top: "40px", color: darkMode ? "#fff" : "#434547" }}>{t('SELECT_ALL')}</h3>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={doorData.length > 0 && selectedDoors.length === doorData.length}
                onChange={handleSelectAll}
                sx={{
                  color: "#F2824C",
                  '&.Mui-checked': { color: "#F2824C" },
                  position: "absolute",
                  right: 40,
                  top: 153
                }} />
            }
          />

          <PerfectScrollbar style={{ maxHeight: 260, marginTop: "15px" }} options={{ wheelSpeed: 2 }}>
            <List sx={{ display: 'flex', flexWrap: 'wrap', gap: '-10px' }}>
              {doorData.map((door) => (
                <ListItem
                  key={door.doorName}
                  style={{
                    paddingBottom: '0px'
                  }}
                >
                  <ListItemAvatar>
                    {door.status === 1 ?
                      <Avatar
                        sx={{
                          width: 10,
                          height: 10,
                          bgcolor: "#2EB962",
                          color: "transparent",
                          marginLeft: "-15px"
                        }}
                      /> :
                      <Avatar
                        sx={{
                          width: 10,
                          height: 10,
                          bgcolor: "#FF0000",
                          color: "transparent",
                          marginLeft: "-15px"
                        }}
                      />}
                  </ListItemAvatar>

                  {door.deviceName !== "" && door.deviceName !== null ?
                    <ListItemText
                      primary={door.doorParameters.name}
                      sx={{ marginLeft: "-47px" }}
                    />
                    :
                    <ListItemText
                      primary={door.doorName}
                      sx={{ marginLeft: "-47px" }}
                    />}

                  <Checkbox
                    color="success"
                    checked={selectedDoors.indexOf(door) !== -1}
                    onChange={handleToggle(door)}
                    sx={{
                      color: "#F2824C",
                      '&.Mui-checked': { color: "#F2824C" },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>

          {doorData.length >= 4 ? (
            <Grid sx={{ marginTop: "40px" }}>
              <h3 style={{ position: "relative", fontSize: "17px", top: "0px", color: darkMode ? "#fff" : "#434547" }}>{t('SET_TIME')}</h3>
              <CustomTextfield
                label={t('SECONDS')}
                name="Set Time"
                value={delayTime}
                error={error.delayTime}
                handleChange={handleChange}
                required={true}
                validation={"numeric"}
                inputProps={{ maxLength: 3 }}
              />
            </Grid>
          ) :
            doorData.length === 0 ? (
              <Grid sx={{ marginTop: "250px" }}>
                <h3 style={{ position: "relative", fontSize: "17px", top: "0px", color: darkMode ? "#fff" : "#434547" }}>{t('SET_TIME')}</h3>
                <CustomTextfield
                  label={t('SECONDS')}
                  name="Set Time"
                  value={delayTime}
                  error={error.delayTime}
                  handleChange={handleChange}
                  required={true}
                  validation={"numeric"}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
            )
              :
              doorData.length === 1 ? (
                <Grid sx={{ marginTop: "190px" }}>
                  <h3 style={{ position: "relative", fontSize: "17px", top: "0px", color: darkMode ? "#fff" : "#434547" }}>{t('SET_TIME')}</h3>
                  <CustomTextfield
                    label={t('SECONDS')}
                    name="Set Time"
                    value={delayTime}
                    error={error.delayTime}
                    handleChange={handleChange}
                    required={true}
                    validation={"numeric"}
                    inputProps={{ maxLength: 3 }}
                  />
                </Grid>
              )
                :
                (
                  <Grid sx={{ marginTop: "130px" }}>
                    <h3 style={{ position: "relative", fontSize: "17px", top: "0px", color: darkMode ? "#fff" : "#434547" }}>{t('SET_TIME')}</h3>
                    <CustomTextfield
                      label={t('SECONDS')}
                      name="Set Time"
                      value={delayTime}
                      error={error.delayTime}
                      handleChange={handleChange}
                      required={true}
                      validation={"numeric"}
                      inputProps={{ maxLength: 3 }}
                    />
                  </Grid>
                )}

        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <CancelButtons variant='contained' color="secondary" onClick={reset}>
            <Typography variant="body1" style={{ textTransform: "none" }}>
              {t('COMMON008')}
            </Typography>
          </CancelButtons>
          <SubmitButtons onClick={handleSubmit} type="submit">
            <Typography variant="body1" style={{ textTransform: "none" }}>
              {t('COMMON007')}
            </Typography>
          </SubmitButtons>
        </DialogActions>
      </Dialog>
    </>
  );
}
