import React from "react"
const QRCode = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.238" height="27.238" viewBox="0 0 27.238 27.238">
  <g id="QR_Code" data-name="QR Code" transform="translate(-6 -6)">
    <path id="Path_7632" data-name="Path 7632" d="M32.162,18.343v-1.13h-1.09v2.275H30v-1.13h-1.1v2.289h3.255v1.145H31.073V22.95h1.076v2.275H28.894V21.791h-1.1V20.647h-1.1V18.357H28.88V16.068H26.728V14.909H28.88V13.793H25.612v2.275H24.536v1.145H23.473v2.3h1.076v1.13H26.7v1.145H25.625v1.13h-1.09v-1.13H22.343V20.647h-1.09V17.213h1.09V13.779H20.164V11.489h-1.09v2.232H16.9V12.634H15.833V11.475H16.9v-1.13h-1.09V9.2h-1.09v4.536H12.537v1.13h1.076v1.2H12.537V14.923H10.331v-1.13H9.255v1.13H8.165v1.145h-1.1v1.145H6v1.145H7.076v2.289H9.255v1.145h1.09V20.647h2.179v1.145H10.345v2.261h3.269V22.936H12.537V21.791h1.076v1.13h1.035v1.188h0V26.37h1.076V25.24H16.9V24.124h1.09v1.1h3.269V24.1H20.191v-1.13h3.241v1.1h-1.09v2.3h1.1v2.3h1.09V29.8h1.09V28.7h2.165v2.275H26.7v1.13H25.639v1.13h3.255V30.949H29.97V28.674H28.907V26.4h1.076v1.116h1.09V26.4h1.09v1.116h1.076V18.343ZM10.372,19.488H8.179v-1.13H9.255V16.068h1.076Zm1.076-3.42H12.51v1.145h1.1V16.068h1.035V19.5H13.627V18.357h-1.1V19.5H11.448ZM13.6,20.647H12.537V19.5h1.076Zm0,1.145V20.647h1.035v1.145Zm7.613-5.723H19.619V14.909H21.24Zm3.3,2.289V17.213H26.7v1.145Zm3.282,9.157H24.536V24.109h3.255Z" fill="#fff"/>
    <path id="Path_7633" data-name="Path 7633" d="M25.21,14.8Z" transform="translate(7.238 3.185)" fill="#fff"/>
    <circle id="Ellipse_3150" data-name="Ellipse 3150" transform="translate(19.074 12.524)" fill="#fff"/>
    <path id="Path_7634" data-name="Path 7634" d="M18,12.39v.01h0Z" transform="translate(4.343 2.314)" fill="#fff"/>
    <path id="Path_7635" data-name="Path 7635" d="M24.41,22v.01h0V22Z" transform="translate(7.238 7.238)" fill="#fff"/>
    <path id="Path_7636" data-name="Path 7636" d="M18,21.21h0V21.2h0Z" transform="translate(4.343 5.504)" fill="#fff"/>
    <path id="Path_7637" data-name="Path 7637" d="M14.01,23.6H14v.01Z" transform="translate(2.897 7.238)" fill="#fff"/>
    <path id="Path_7638" data-name="Path 7638" d="M18.8,22.81h0Z" transform="translate(4.635 7.238)" fill="#fff"/>
    <path id="Path_7639" data-name="Path 7639" d="M22.343,26.715H21.267v1.076h-1.09V26.728H17.985v1.062H16.9V26.715h-1.09v2.193h-1.09V29.97H16.9V28.907h2.179v1.076H21.24v1.1H20.178v2.152h1.09V32.176h1.076v1.062h2.152V31.073H23.432V28.894h-1.09ZM6,33.238h7.6v-7.6H6Zm1.09-6.51h5.448v5.448H7.09ZM33.238,6H25.625v7.6h7.613Zm-1.09,6.51H26.7V7.062h5.448ZM13.6,6H6v7.6h7.6Zm-1.09,6.51H7.062V7.062H12.51Z" fill="#fff"/>
    <circle id="Ellipse_3151" data-name="Ellipse 3151" transform="translate(6.79 21.253)" fill="#fff"/>
    <path id="Path_7640" data-name="Path 7640" d="M9.19,18v.01h0V18Z" transform="translate(0 4.345)" fill="#fff"/>
    <path id="Path_7641" data-name="Path 7641" d="M13.2,9.19h0Z" transform="translate(2.603)" fill="#fff"/>
    <path id="Path_7642" data-name="Path 7642" d="M15.61,8.38h.78V6H15.6v.8h-.81v.78h-1.6V6.79h-.78V8.4h.79v.79h2.41Z" transform="translate(2.896)" fill="#fff"/>
    <path id="Path_7643" data-name="Path 7643" d="M10.8,13.19h-.01v.01h.01Z" transform="translate(1.734 2.603)" fill="#fff"/>
    <path id="Path_7644" data-name="Path 7644" d="M18,11.59h0v0Z" transform="translate(4.343 2.024)" fill="#fff"/>
    <path id="Path_7645" data-name="Path 7645" d="M16.4,10.79h0v.01h0Z" transform="translate(3.764 1.734)" fill="#fff"/>
    <path id="Path_7646" data-name="Path 7646" d="M17.19,11.59H18v-.78h.81v.78h.78V9.21H16.4v1.58h.79Z" transform="translate(4.478 1.319)" fill="#fff"/>
    <path id="Path_7647" data-name="Path 7647" d="M18.81,11.6v-.01H18.8Z" transform="translate(4.635 2.024)" fill="#fff"/>
    <path id="Path_7648" data-name="Path 7648" d="M22.8,15.6Z" transform="translate(7.238 3.474)" fill="#fff"/>
    <path id="Path_7649" data-name="Path 7649" d="M18,8.38h.8v-.8h.78v-.8H18.8V6H17.21V7.6H18Z" transform="translate(4.602)" fill="#fff"/>
    <path id="Path_7650" data-name="Path 7650" d="M13.19,9.19Z" transform="translate(2.603)" fill="#fff"/>
    <path id="Path_7651" data-name="Path 7651" d="M23.61,13.19h0v.01h0Z" transform="translate(7.238 2.603)" fill="#fff"/>
    <path id="Path_7652" data-name="Path 7652" d="M24.4,14h.81v.81H26V13.19h-.79v-.78h-1.6v.78h.79Z" transform="translate(7.238 2.636)" fill="#fff"/>
    <path id="Path_7653" data-name="Path 7653" d="M11.59,18.81h.01V18.8h-.01Z" transform="translate(2.024 4.635)" fill="#fff"/>
    <circle id="Ellipse_3152" data-name="Ellipse 3152" transform="translate(15.806 26.715)" fill="#fff"/>
    <path id="Path_7654" data-name="Path 7654" d="M6.79,18.81H6v.78H7.59V18.8h.8V18H6.79Z" transform="translate(0 4.718)" fill="#fff"/>
    <path id="Path_7655" data-name="Path 7655" d="M8.39,18.01h0V18h0Z" transform="translate(0 4.345)" fill="#fff"/>
    <path id="Path_7656" data-name="Path 7656" d="M13.19,24.42h-.78V26H14v-.78h-.8Z" transform="translate(2.52 7.238)" fill="#fff"/>
    <path id="Path_7657" data-name="Path 7657" d="M13.99,25.21h0Z" transform="translate(2.893 7.238)" fill="#fff"/>
    <path id="Path_7658" data-name="Path 7658" d="M14.01,23.6l-.01.01h.01Z" transform="translate(2.897 7.238)" fill="#fff"/>
    <path id="Path_7659" data-name="Path 7659" d="M15.58,25.21V24.4h-.79v-.8H14v1.61Zm9.64.01h-.8V26H26V24.42h-.78Z" transform="translate(7.238 7.238)" fill="#fff"/>
    <path id="Path_7660" data-name="Path 7660" d="M7.59,13.19v.01h0v-.01Z" transform="translate(0 2.603)" fill="#fff"/>
    <path id="Path_7661" data-name="Path 7661" d="M6,12.41v.78H7.59v-.78Z" transform="translate(0 2.414)" fill="#fff"/>
    <path id="Path_7662" data-name="Path 7662" d="M19.6,13.99V14h.01v-.01Z" transform="translate(4.924 2.893)" fill="#fff"/>
    <path id="Path_7663" data-name="Path 7663" d="M18.81,12.4V14h.79V12.4Z" transform="translate(4.827 2.518)" fill="#fff"/>
    <path id="Path_7664" data-name="Path 7664" d="M18.8,12.4h.01v-.01Z" transform="translate(4.635 2.314)" fill="#fff"/>
    <circle id="Ellipse_3153" data-name="Ellipse 3153" transform="translate(30.048 16.895)" fill="#fff"/>
    <path id="Path_7665" data-name="Path 7665" d="M23.61,14v-.8h-.8V14Z" transform="translate(7.238 2.714)" fill="#fff"/>
    <circle id="Ellipse_3154" data-name="Ellipse 3154" transform="translate(30.048 15.806)" fill="#fff"/>
    <path id="Path_7666" data-name="Path 7666" d="M6,17.2V18h.79v-.8Z" transform="translate(0 4.222)" fill="#fff"/>
    <path id="Path_7667" data-name="Path 7667" d="M6.79,18v.01h0V18Z" transform="translate(0 4.345)" fill="#fff"/>
    <path id="Path_7668" data-name="Path 7668" d="M19.59,11.6h.01v-.01Z" transform="translate(4.921 2.024)" fill="#fff"/>
    <path id="Path_7669" data-name="Path 7669" d="M20.4,12.39h-.01v.01Z" transform="translate(5.211 2.314)" fill="#fff"/>
    <path id="Path_7670" data-name="Path 7670" d="M20.4,12.39V11.6h-.8v.79Z" transform="translate(5.127 2.11)" fill="#fff"/>
    <path id="Path_7671" data-name="Path 7671" d="M19.6,12.4v-.01h-.01Z" transform="translate(4.921 2.314)" fill="#fff"/>
    <path id="Path_7672" data-name="Path 7672" d="M20.39,11.59v.01h.01Z" transform="translate(5.211 2.024)" fill="#fff"/>
    <path id="Path_7673" data-name="Path 7673" d="M14,10.8v-.01h-.01v.01Z" transform="translate(2.893 1.734)" fill="#fff"/>
    <circle id="Ellipse_3155" data-name="Ellipse 3155" transform="translate(16.895 13.613)" fill="#fff"/>
    <path id="Path_7674" data-name="Path 7674" d="M14.8,11.59V10.8H14v.79Zm9.61,11.2h.8V22h-.8Z" transform="translate(7.238 4.338)" fill="#fff"/>
    <circle id="Ellipse_3156" data-name="Ellipse 3156" transform="translate(32.448 29.248)" fill="#fff"/>
    <path id="Path_7675" data-name="Path 7675" d="M15.6,10h-.8v.78h.8Z" transform="translate(3.318 1.506)" fill="#fff"/>
    <circle id="Ellipse_3157" data-name="Ellipse 3157" transform="translate(17.985 12.524)" fill="#fff"/>
    <path id="Path_7676" data-name="Path 7676" d="M14,6h-.8v.79H14Z" transform="translate(2.714)" fill="#fff"/>
    <circle id="Ellipse_3158" data-name="Ellipse 3158" transform="translate(15.806 6.79)" fill="#fff"/>
    <path id="Path_7677" data-name="Path 7677" d="M24.41,18.81h0V18.8h0Z" transform="translate(7.238 4.635)" fill="#fff"/>
    <path id="Path_7678" data-name="Path 7678" d="M30.518,24.759h1.13V23.643h-1.13Zm-3.462,1.188H25.94v1.1h1.116ZM7.6,31.628h3.433v-3.42H7.6ZM31.648,7.6H28.2v3.435H31.62Zm-20.615.014H7.6v3.42h3.433Z" fill="#fff"/>
  </g>
</svg>

  )
}
export default QRCode


