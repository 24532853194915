import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const getAddressTypes = (filter) => {
    return api.securedAxios().get('/web/address_type' + buildQuery(filter));
}

const buildQuery = (filter) => {
    let query = "?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=false&operator=AND&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    if (!CommonUtil.isEmptyString(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    if (!CommonUtil.isEmptyString(filter.code)) {
        query = query + "&code=" + filter.code;
    }
    return query;
};

const createAddressType = (payload) => {
    return api.securedAxios().post('/web/address_type', payload)
}

const updateAddressType = (payload) => {
    return api.securedAxios().put('/web/address_type/' + payload.id, payload);
}

const deleteAddressType = (id) => {
    return api.securedAxios().delete('/web/address_type?id=' + id)
}

const addressService = {
    getAddressTypes,
    createAddressType,
    updateAddressType,
    deleteAddressType,
}

export default addressService;