import { makeStyles } from '@material-ui/core'
import { IconButton, Paper, Tooltip, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { ThemeContext } from '../../theme/ThemeContext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  styled,
  Switch,
  TextField,
  createFilterOptions,
} from '@mui/material'
import jwt_decode from 'jwt-decode'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CustomDropdown from '../../components/CustomInputs/CustomDropdown'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  createSettings,
  getListSettings,
  updateSettings,
} from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import CommonUtil from '../../Util/CommonUtils'
import { digit } from '../Person/AddressFormData'
import { ScanLimit } from '../Person/AddressFormData'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { listAllEmployees } from '../../slices/Employee/EmployeeSlice'
import {
  customerSubscription,
  getSubscriptionById,
} from '../../slices/Subscription/SubscriptionSlice'

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#0F5A9C !important',
    opacity: 1,
  },
}))

const useStyles = makeStyles((theme) => ({
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}))

const CustomFormControlLabel = styled(FormControlLabel)(({ theme, darkMode }) => ({
  '& .MuiFormControlLabel-label': {
    color: darkMode ? '#fff' : '#000', // Color for dark mode or light mode
  },
  '&.Mui-disabled .MuiFormControlLabel-label': {
    color: darkMode ? '#fff !important' : '#aaa !important', // Color when disabled in dark mode or light mode
  },
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

function AdditionalSettings() {
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [featureList, setFeatureList] = useState([])
  const { t } = useTranslation()
  const [Digit, setDigit] = React.useState([])
  const [scanLimit, setScanLimit] = React.useState([])
  const [isRadioDisabled, setIsRadioDisabled] = useState(false)
  const [employees, setEmployees] = useState([])
  const [enableDLMobile, setEnableDLMobile] = useState(false)
  const { darkMode } = useContext(ThemeContext);
  const [state, setState] = useState({
    id: '',
    companyId: decoded_jwtToken.userId,
    invitationApproval: true,
    walkInRegistartionApproval: true,
    selfRegistartionApproval: true,
    printerStatus: true,
    printerDevice: 'Kiosk',
    invitationVerifyMode: 'QRCODE',
    walkInRegistartionVerifyMode: 'QRCODE',
    selfRegistartionVerifyMode: 'QRCODE',
    invitationCodeDigits: '',
    walkInCodeDigits: '',
    selfRegistartionCodeDigits: '',
    invitationScanLimit: '',
    walkInScanLimit: '',
    selfRegistartionScanLimit: '',
    invitationIntervalTime: '30',
    walkInIntervalTime: '30',
    selfIntervalTime: '30',
    invitationEarlyCheckInTime: '30',
    invitationLateCheckOutTime: '30',
    walkInEarlyCheckInTime: '30',
    walkInLateCheckOutTime: '30',
    selfEarlyCheckInTime: '30',
    selfLateCheckOutTime: '30',
    dlScanMode: checked,
    dateFormat: 'DD-MM-YYYY',
    badgePrint: checkBadgePrint,
    invitationPurposeType: false,
    invitationVisitorType: false,
    invitationSiteType: false,
    invitationCompanyName: false,
    invitationProfilePhoto: false,
    checkInvitationPurposeType: invitationPurposeTypeMandatory,
    checkInvitationVisitorType: invitationVisitorTypeMandatory,
    checkInvitationSiteType: invitationSiteTypeMandatory,
    checkInvitationCompanyName: invitationCompanyNameMandatory,
    checkInvitationProfilePhoto: invitationProfilePhotoMandatory,
    walkInRegistrationPurposeType: false,
    walkInRegistrationVisitorType: false,
    walkInRegistrationSiteType: false,
    wlakInRegistrationCompanyName: false,
    walkInRegistrationProfilePhoto: true,
    walkInRegistrationSignatureOnKiosk: false,
    checkWalkInRegistrationPurposeType: walkInPurposeTypeMandatory,
    checkWalkInRegistrationVisitorType: walkInVisitorTypeMandatory,
    checkWalkInRegistrationSiteType: walkInSiteTypeMandatory,
    checkWalkInRegistrationCompanyName: walkInCompanyNameMandatory,
    checkWalkInRegistrationProfilePhoto: WalkInProfilePhotoMandatory,
    checkWalkInRegistrationSignatureOnKiosk: WalkInSignatureMandatory,
    selfRegistrationPurposeType: false,
    selfRegistrationVisitorType: false,
    selfRgistrationSiteType: false,
    selfRegistrationCompanyName: false,
    selfRegistrationProfilePhoto: true,
    checkSelfRegistrationPurposeType: selfRegistrationPurposeTypeMandatory,
    checkSelfRegistrationVisitorType: selfRegistrationVisitorTypeMandatory,
    checkSelfRegistrationSiteType: selfRegistrationSiteTypeMandatory,
    checkSelfRegistrationCompanyName: selfRegistrationCompanyNameMandatory,
    checkSelfRegistrationProfilePhoto: selfRegistrationProfilePhotoMandatory,
    delivery: false,
    defaultHostId: null,
    invitationBypass: true,
    walkInBypass: true,
    adminAndDealerDateFormat: 'MM-DD-YYYY',
    walkInImageCapture: false,
    selfImageCapture: false,
    walkInImagesCaptureLimit: '',
    selfImagesCaptureLimit: '',
    walkInAutoContactInfo: false,
    selfAutoContactInfo: false,
    proxyDomain: 'mysmartguardplus.com',
  })

  const [expanded, setExpanded] = React.useState('')
  const [scanMode, setScanMode] = useState('')
  const [checked, setChecked] = useState(false)
  const [checkBadgePrint, setCheckBadgePrint] = useState(false)
  const [walkInBypass, setWalkInBypass] = useState(true)
  const [invitationByPass, setInvitationByPass] = useState(true)
  const [printDevice, setPrintDevice] = useState('Kiosk')
  const [
    invitationPurposeTypeMandatory,
    setInvitationPurposeTypeMandatory,
  ] = useState(false)
  const [
    invitationVisitorTypeMandatory,
    setInvitationVisitorTypeMandatory,
  ] = useState(false)
  const [
    invitationSiteTypeMandatory,
    setInvitationSiteTypeMandatory,
  ] = useState(false)
  const [
    invitationCompanyNameMandatory,
    setInvitationCompanyNameMandatory,
  ] = useState(false)
  const [
    invitationProfilePhotoMandatory,
    setInvitationProfilePhotoMandatory,
  ] = useState(false)
  const [walkInPurposeTypeMandatory, setWalkInPurposeTypeMandatory] = useState(
    false,
  )
  const [walkInVisitorTypeMandatory, setWalkInVisitorTypeMandatory] = useState(
    false,
  )
  const [walkInSiteTypeMandatory, setWalkInSiteTypeMandatory] = useState(false)
  const [walkInCompanyNameMandatory, setWalkInCompanyNameMandatory] = useState(
    false,
  )
  const [
    WalkInProfilePhotoMandatory,
    setWalkInProfilePhotoMandatory,
  ] = useState(true)
  const [WalkInSignatureMandatory, setWalkInSignatureMandatory] = useState(
    false,
  )
  const [
    selfRegistrationPurposeTypeMandatory,
    setSelfRegistrationPurposeTypeMandatory,
  ] = useState(false)
  const [
    selfRegistrationVisitorTypeMandatory,
    setSelfRegistrationVisitorTypeMandatory,
  ] = useState(false)
  const [
    selfRegistrationSiteTypeMandatory,
    setSelfRegistrationSiteTypeMandatory,
  ] = useState(false)
  const [
    selfRegistrationCompanyNameMandatory,
    setSelfRegistrationCompanyNameMandatory,
  ] = useState(false)
  const [
    selfRegistrationProfilePhotoMandatory,
    setSelfRegistrationProfilePhotoMandatory,
  ] = useState(true)
  const [selectedHost, setSelectedHost] = useState(null)

  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const captureLimit = [
    {
      id: '1',
      name: '1',
    },
    {
      id: '2',
      name: '2',
    },
    {
      id: '3',
      name: '3',
    },
    {
      id: '4',
      name: '4',
    },
    {
      id: '5',
      name: '5',
    },
  ]

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
  }

  const handleProxyDomain = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
    setError(false)
  }

  const handlePrintDevice = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const changeIntervalTime = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const [error, setError] = useState({
    invitationCodeDigits: false,
    walkInCodeDigits: false,
    selfRegistartionCodeDigits: false,
    invitationIntervalTime: false,
    walkInIntervalTime: false,
    selfIntervalTime: false,
    invitationEarlyCheckInTime: false,
    invitationLateCheckOutTime: false,
    walkInEarlyCheckInTime: false,
    walkInLateCheckOutTime: false,
    selfEarlyCheckInTime: false,
    selfLateCheckOutTime: false,
    proxyDomain: false,
    dateFormat: false,
  })

  const handleChangeofDropdown = (event) => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const [filter, setFilter] = useState({
    firstName: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    code: '',
  })

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 0,
  })

  useEffect(() => {
    setDigit(digit)
    setScanLimit(ScanLimit)
    loadAdditionalSettings()
    loadData(filter, paging)
  }, [])

  const loadAdditionalSettings = () => {
    dispatch(getListSettings(state))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setState({
            ...state,
            id: data.data.id,
            companyId: data.data.companyId,
            invitationApproval: data.data.invitationApproval,
            walkInRegistartionApproval: data.data.walkInRegistartionApproval,
            selfRegistartionApproval: data.data.selfRegistartionApproval,
            printerStatus: data.data.printerStatus,
            printerDevice: data.data.printerDevice,
            invitationVerifyMode: data.data.invitationVerifyMode,
            walkInRegistartionVerifyMode:
              data.data.walkInRegistartionVerifyMode,
            selfRegistartionVerifyMode: data.data.selfRegistartionVerifyMode,
            invitationCodeDigits: data.data.invitationCodeDigits,
            invitationScanLimit: data.data.invitationScanLimit,
            walkInScanLimit: data.data.walkInScanLimit,
            selfRegistartionScanLimit: data.data.selfRegistartionScanLimit,
            walkInCodeDigits: data.data.walkInCodeDigits,
            selfRegistartionCodeDigits: data.data.selfRegistartionCodeDigits,
            invitationIntervalTime: data.data.invitationIntervalTime,
            walkInIntervalTime: data.data.walkInIntervalTime,
            selfIntervalTime: data.data.selfIntervalTime,
            invitationEarlyCheckInTime: data.data.invitationEarlyCheckInTime,
            invitationLateCheckOutTime: data.data.invitationLateCheckOutTime,
            walkInEarlyCheckInTime: data.data.walkInEarlyCheckInTime,
            walkInLateCheckOutTime: data.data.walkInLateCheckOutTime,
            selfEarlyCheckInTime: data.data.selfEarlyCheckInTime,
            selfLateCheckOutTime: data.data.selfLateCheckOutTime,
            delivery: data.data.delivery,
            dlScanMode: checked,
            dateFormat: data.data.dateFormat,
            badgePrint: checkBadgePrint,
            invitationPurposeType: data.data.invitationPurposeType,
            invitationVisitorType: data.data.invitationVisitorType,
            invitationSiteType: data.data.invitationSiteType,
            invitationCompanyName: data.data.invitationCompanyName,
            invitationProfilePhoto: data.data.invitationProfilePhoto,
            walkInRegistrationPurposeType:
              data.data.walkInRegistrationPurposeType,
            walkInRegistrationVisitorType:
              data.data.walkInRegistrationVisitorType,
            walkInRegistrationSiteType: data.data.walkInRegistrationSiteType,
            wlakInRegistrationCompanyName:
              data.data.wlakInRegistrationCompanyName,
            walkInRegistrationProfilePhoto:
              data.data.walkInRegistrationProfilePhoto,
            walkInRegistrationSignatureOnKiosk:
              data.data.walkInRegistrationSignatureOnKiosk,
            selfRegistrationPurposeType: data.data.selfRegistrationPurposeType,
            selfRegistrationVisitorType: data.data.selfRegistrationVisitorType,
            selfRgistrationSiteType: data.data.selfRgistrationSiteType,
            selfRegistrationCompanyName: data.data.selfRegistrationCompanyName,
            selfRegistrationProfilePhoto:
              data.data.selfRegistrationProfilePhoto,
            checkInvitationPurposeType: invitationPurposeTypeMandatory,
            checkInvitationVisitorType: invitationVisitorTypeMandatory,
            checkInvitationSiteType: invitationSiteTypeMandatory,
            checkInvitationCompanyName: invitationCompanyNameMandatory,
            checkInvitationProfilePhoto: invitationProfilePhotoMandatory,
            checkWalkInRegistrationPurposeType: walkInPurposeTypeMandatory,
            checkWalkInRegistrationVisitorType: walkInVisitorTypeMandatory,
            checkWalkInRegistrationSiteType: walkInSiteTypeMandatory,
            checkWalkInRegistrationCompanyName: walkInCompanyNameMandatory,
            checkWalkInRegistrationProfilePhoto: WalkInProfilePhotoMandatory,
            checkWalkInRegistrationSignatureOnKiosk: WalkInSignatureMandatory,
            checkSelfRegistrationPurposeType: selfRegistrationPurposeTypeMandatory,
            checkSelfRegistrationVisitorType: selfRegistrationVisitorTypeMandatory,
            checkSelfRegistrationSiteType: selfRegistrationSiteTypeMandatory,
            checkSelfRegistrationCompanyName: selfRegistrationCompanyNameMandatory,
            checkSelfRegistrationProfilePhoto: selfRegistrationProfilePhotoMandatory,
            defaultHostId: data.data.defaultHostId,
            invitationBypass: data.data.invitationBypass,
            walkInBypass: data.data.walkInBypass,
            viewedVersion: data.data.viewedVersion,
            walkInImageCapture: data.data.walkInImageCapture,
            selfImageCapture: data.data.selfImageCapture,
            walkInImagesCaptureLimit: data.data.walkInImagesCaptureLimit,
            selfImagesCaptureLimit: data.data.selfImagesCaptureLimit,
            walkInAutoContactInfo: data.data.walkInAutoContactInfo,
            selfAutoContactInfo: data.data.selfAutoContactInfo,
            proxyDomain: data.data.proxyDomain,
          })
          setChecked(data.data.dlScanMode)
          setCheckBadgePrint(data.data.badgePrint)
          setInvitationByPass(data.data.invitationBypass)
          setWalkInBypass(data.data.walkInBypass)
          setInvitationPurposeTypeMandatory(
            data.data.checkInvitationPurposeType,
          )
          setInvitationVisitorTypeMandatory(
            data.data.checkInvitationVisitorType,
          )
          setInvitationSiteTypeMandatory(data.data.checkInvitationSiteType)
          setInvitationCompanyNameMandatory(
            data.data.checkInvitationCompanyName,
          )
          setInvitationProfilePhotoMandatory(
            data.data.checkInvitationProfilePhoto,
          )
          setWalkInPurposeTypeMandatory(
            data.data.checkWalkInRegistrationPurposeType,
          )
          setWalkInVisitorTypeMandatory(
            data.data.checkWalkInRegistrationVisitorType,
          )
          setWalkInSiteTypeMandatory(data.data.checkWalkInRegistrationSiteType)
          setWalkInCompanyNameMandatory(
            data.data.checkWalkInRegistrationCompanyName,
          )
          setWalkInProfilePhotoMandatory(
            data.data.checkWalkInRegistrationProfilePhoto,
          )
          setWalkInSignatureMandatory(
            data.data.checkWalkInRegistrationSignatureOnKiosk,
          )
          setSelfRegistrationPurposeTypeMandatory(
            data.data.checkSelfRegistrationPurposeType,
          )
          setSelfRegistrationVisitorTypeMandatory(
            data.data.checkSelfRegistrationVisitorType,
          )
          setSelfRegistrationCompanyNameMandatory(
            data.data.checkSelfRegistrationCompanyName,
          )
          setSelfRegistrationSiteTypeMandatory(
            data.data.checkSelfRegistrationSiteType,
          )
          setSelfRegistrationProfilePhotoMandatory(
            data.data.checkSelfRegistrationProfilePhoto,
          )

          if (CommonUtil.isEmpty(data.data?.invitationCodeDigits)) {
            setQrCode(true)
            setDigitCode(false)
          } else {
            setDigitCode(true)
            setQrCode(false)
          }
          if (CommonUtil.isEmpty(data.data?.walkInCodeDigits)) {
            setQrCode1(true)
            setDigitCode1(false)
          } else {
            setDigitCode1(true)
            setQrCode1(false)
          }
          if (CommonUtil.isEmpty(data.data?.selfRegistartionCodeDigits)) {
            setQrCode2(true)
            setDigitCode2(false)
          } else {
            setDigitCode2(true)

            setQrCode2(false)
          }
        }
      })
      .catch((er) => { })
  }

  const loadData = (filter, paging) => {
    dispatch(listAllEmployees(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'OMSI0000' && data.data)) {
          setEmployees(data.data.employees)
        } else {
          setEmployees([])
        }
      })
      .catch((er) => { })
  }

  useEffect(() => {
    const host = employees.find((host) => host.id === state.defaultHostId)
    if (host?.id) setSelectedHost(host)
  }, [employees, state.id])

  const handleSubmit = () => {
    if (digitCode && CommonUtil.isEmpty(state.invitationCodeDigits)) {
      Toast(t('Enter Invitation Digit Code'), 'error')
      setError({ invitationCodeDigits: true })
      return
    }
    if (digitCode1 && CommonUtil.isEmpty(state.walkInCodeDigits)) {
      Toast(t('Enter walkIn Code Digits'), 'error')
      setError({ walkInCodeDigits: true })
      return
    }
    if (digitCode2 && CommonUtil.isEmpty(state.selfRegistartionCodeDigits)) {
      Toast(t('Enter self Registartion Code Digits'), 'error')
      setError({ selfRegistartionCodeDigits: true })
      return
    }
    if (CommonUtil.isEmpty(state.invitationIntervalTime)) {
      Toast(t('Enter Inivtation Interval Time'), 'error')
      setError({ invitationIntervalTime: true })
      return
    }
    if (CommonUtil.isEmpty(state.walkInIntervalTime)) {
      Toast(t('Enter Walk-In Interval Time'), 'error')
      setError({ walkInIntervalTime: true })
      return
    }
    if (qrCode && state.invitationScanLimit === '') {
      Toast('Invitation Scan Limit cannot be empty', 'error')
      return
    }
    if (qrCode1 && state.walkInScanLimit === '') {
      Toast('WalkIn Scan Limit cannot be empty', 'error')
      return
    }
    if (qrCode2 && state.selfRegistartionScanLimit === '') {
      Toast('Self Registration Scan Limit cannot be empty', 'error')
      return
    }
    if (state.invitationScanLimit === '0') {
      Toast('Invitation Scan Limit Can not be zero', 'error')
      return
    }
    if (state.walkInScanLimit === '0') {
      Toast('Walk-In Registration Scan Limit Can not be zero', 'error')
      return
    }
    if (state.selfRegistartionScanLimit === '0') {
      Toast('Self Registartion ScanLimit Can not be zero', 'error')
      return
    }
    if (CommonUtil.isEmpty(state.selfIntervalTime)) {
      Toast(t('Enter Self Registration Interval Time'), 'error')
      setError({ selfIntervalTime: true })
      return
    }
    if (CommonUtil.isEmpty(state.invitationEarlyCheckInTime)) {
      Toast(t('Enter Invitation Early Check-In Time'), 'error')
      setError({ invitationEarlyCheckInTime: true })
      return
    }
    if (CommonUtil.isEmpty(state.invitationLateCheckOutTime)) {
      Toast(t('Enter Invitation Late Check-Out Time'), 'error')
      setError({ invitationLateCheckOutTime: true })
      return
    }
    if (CommonUtil.isEmpty(state.walkInEarlyCheckInTime)) {
      Toast(t('Enter Walk-In Registration Early Check-In Time'), 'error')
      setError({ walkInEarlyCheckInTime: true })
      return
    }
    if (CommonUtil.isEmpty(state.walkInLateCheckOutTime)) {
      Toast(t('Enter Walk-In Registration Late Check-Out Time'), 'error')
      setError({ walkInLateCheckOutTime: true })
      return
    }
    if (CommonUtil.isEmpty(state.selfEarlyCheckInTime)) {
      Toast(t('Enter Self Registration Early Check-In Time'), 'error')
      setError({ selfEarlyCheckInTime: true })
      return
    }
    if (CommonUtil.isEmpty(state.selfLateCheckOutTime)) {
      Toast(t('Enter  Self Registration Late Check-Out Time'), 'error')
      setError({ selfLateCheckOutTime: true })
      return
    }
    if (state.walkInImageCapture && state.walkInImagesCaptureLimit === 0) {
      Toast(t('WalkIn images capture limit cannot be empty'), 'error')
      return
    }
    if (state.selfImageCapture && state.selfImagesCaptureLimit === 0) {
      Toast(
        t('SelfRegistration  images capture limit cannot be empty'),
        'error',
      )
      return
    }
    if (CommonUtil.isEmpty(state.proxyDomain)) {
      Toast(t('Proxy domain is required'), 'error')
      setError({ proxyDomain: true })
      return
    }
    if (!CommonUtil.isValidDomain(state.proxyDomain)) {
      Toast(t('Enter valid domain'), 'error')
      setError({ proxyDomain: true })
      return
    }
    if (CommonUtil.isEmpty(state.dateFormat)) {
      Toast(t('Please select date format'), 'error')
      setError({ dateFormat: true })
      return
    }
    if (CommonUtil.isEmptyString(state.id)) {
      var requestBody = {
        companyId: decoded_jwtToken.companyId,
        invitationApproval: state.invitationApproval,
        walkInRegistartionApproval: state.walkInRegistartionApproval,
        selfRegistartionApproval: state.selfRegistartionApproval,
        printerStatus: state.printerStatus,
        printerDevice: state.printerDevice,
        invitationVerifyMode: state.invitationVerifyMode,
        walkInRegistartionVerifyMode: state.walkInRegistartionVerifyMode,
        selfRegistartionVerifyMode: state.selfRegistartionVerifyMode,
        invitationCodeDigits: state.invitationCodeDigits,
        invitationScanLimit: state.invitationScanLimit,
        walkInScanLimit: state.walkInScanLimit,
        selfRegistartionScanLimit: state.selfRegistartionScanLimit,
        walkInCodeDigits: state.walkInCodeDigits,
        selfRegistartionCodeDigits: state.selfRegistartionCodeDigits,
        invitationIntervalTime: state.invitationIntervalTime,
        walkInIntervalTime: state.walkInIntervalTime,
        selfIntervalTime: state.selfIntervalTime,
        invitationEarlyCheckInTime: state.invitationEarlyCheckInTime,
        invitationLateCheckOutTime: state.invitationLateCheckOutTime,
        walkInEarlyCheckInTime: state.walkInEarlyCheckInTime,
        walkInLateCheckOutTime: state.walkInLateCheckOutTime,
        selfEarlyCheckInTime: state.selfEarlyCheckInTime,
        selfLateCheckOutTime: state.selfLateCheckOutTime,
        dlScanMode: checked,
        dateFormat: state.dateFormat,
        badgePrint: checkBadgePrint,
        invitationPurposeType: state.invitationPurposeType,
        invitationVisitorType: state.invitationVisitorType,
        invitationSiteType: state.invitationSiteType,
        invitationCompanyName: state.invitationCompanyName,
        invitationProfilePhoto: state.invitationProfilePhoto,
        walkInRegistrationPurposeType: state.walkInRegistrationPurposeType,
        walkInRegistrationVisitorType: state.walkInRegistrationVisitorType,
        walkInRegistrationSiteType: state.walkInRegistrationSiteType,
        wlakInRegistrationCompanyName: state.wlakInRegistrationCompanyName,
        walkInRegistrationProfilePhoto: state.walkInRegistrationProfilePhoto,
        walkInRegistrationSignatureOnKiosk:
          state.walkInRegistrationSignatureOnKiosk,
        selfRegistrationPurposeType: state.selfRegistrationPurposeType,
        selfRegistrationVisitorType: state.selfRegistrationVisitorType,
        selfRgistrationSiteType: state.selfRgistrationSiteType,
        selfRegistrationCompanyName: state.selfRegistrationCompanyName,
        selfRegistrationProfilePhoto: state.selfRegistrationProfilePhoto,
        checkInvitationPurposeType: invitationPurposeTypeMandatory,
        checkInvitationVisitorType: invitationVisitorTypeMandatory,
        checkInvitationSiteType: invitationSiteTypeMandatory,
        checkInvitationCompanyName: invitationCompanyNameMandatory,
        checkInvitationProfilePhoto: invitationProfilePhotoMandatory,
        checkWalkInRegistrationPurposeType: walkInPurposeTypeMandatory,
        checkWalkInRegistrationVisitorType: walkInVisitorTypeMandatory,
        checkWalkInRegistrationSiteType: walkInSiteTypeMandatory,
        checkWalkInRegistrationCompanyName: walkInCompanyNameMandatory,
        checkWalkInRegistrationProfilePhoto: WalkInProfilePhotoMandatory,
        checkWalkInRegistrationSignatureOnKiosk: WalkInSignatureMandatory,
        checkSelfRegistrationPurposeType: selfRegistrationPurposeTypeMandatory,
        checkSelfRegistrationVisitorType: selfRegistrationVisitorTypeMandatory,
        checkSelfRegistrationSiteType: selfRegistrationSiteTypeMandatory,
        checkSelfRegistrationCompanyName: selfRegistrationCompanyNameMandatory,
        checkSelfRegistrationProfilePhoto: selfRegistrationProfilePhotoMandatory,
        delivery: state.delivery,
        defaultHostId: state.defaultHostId,
        invitationByPass: state.invitationBypass,
        walkInBypass: state.walkInBypass,
        adminAndDealerDateFormat: 'MM-DD-YYYY',
        walkInImageCapture: state.walkInImageCapture,
        selfImageCapture: state.selfImageCapture,
        walkInImagesCaptureLimit: state.walkInImagesCaptureLimit,
        selfImagesCaptureLimit: state.selfImagesCaptureLimit,
        walkInAutoContactInfo: state.walkInAutoContactInfo,
        selfAutoContactInfo: state.selfAutoContactInfo,
      }
      console.log('Create' + requestBody)

      if (
        state.invitationIntervalTime == 0 ||
        state.walkInIntervalTime == 0 ||
        state.selfIntervalTime == 0
      ) {
        Toast(t('Interval Time Cannot be Zero'), 'error')
      } else {
        dispatch(createSettings(requestBody))
          .unwrap()
          .then((data) => {
            if (data.code === 'CVAI0063') {
              Toast('Settings created successfully', 'success')
              history.goBack()
            } else if (data.code === 'CVAI0064') {
              Toast('Settings already present', 'error')
              history.goBack()
            } else {
              Toast(data.message, 'error')
            }
            history.goBack()
          })
      }
    } else {
      if (
        state.invitationIntervalTime == 0 ||
        state.walkInIntervalTime == 0 ||
        state.selfIntervalTime == 0
      ) {
        Toast(t('Interval Time Cannot be Zero'), 'error')
      } else {
        dispatch(updateSettings(state))
          .unwrap()
          .then((data) => {
            if (data.code === 'CVAI0071') {
              Toast(data.message, 'success')
            } else if (data.code === 'CVAW0064') {
              Toast('Settings already present', 'error')
            } else {
              Toast(data.message, 'error')
            }
          })
          .catch((er) => { })
      }
    }
  }

  const [qrCode, setQrCode] = useState(true)
  const [digitCode, setDigitCode] = useState(false)

  const [qrCode1, setQrCode1] = useState(true)
  const [digitCode1, setDigitCode1] = useState(false)

  const [qrCode2, setQrCode2] = useState(true)
  const [digitCode2, setDigitCode2] = useState(false)

  const [deliveryPermission, setDeliveryPermission] = useState(false)

  const handleChangeQR = (event) => {
    setError({ invitationCodeDigits: false })
    setQrCode(true)
    setDigitCode(false)
    setState({
      ...state,
      invitationCodeDigits: '',
      invitationVerifyMode: 'QRCODE',
    })
  }

  const handleChangeDigit = (event) => {
    setQrCode(false)
    setDigitCode(true)
    setState({
      ...state,
      invitationCodeDigits: '6',
      invitationVerifyMode: 'PASSCODE',
      invitationScanLimit: '2',
    })
  }
  const handleChangeQR1 = (event) => {
    setError({ walkInCodeDigits: false })
    setQrCode1(true)
    setDigitCode1(false)
    setState({
      ...state,
      walkInCodeDigits: '',
      walkInRegistartionVerifyMode: 'QRCODE',
    })
  }

  const handleImageCapture = (event) => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.checked,
    })
  }

  const handleWalkInAutoContactInfo = (event) => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.checked,
    })
  }

  const handleSelfAutoContactInfo = (event) => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.checked,
    })
  }

  const handleChangeDigit1 = (event) => {
    setQrCode1(false)
    setDigitCode1(true)
    setState({
      ...state,
      walkInCodeDigits: '6',
      walkInRegistartionVerifyMode: 'PASSCODE',
      walkInScanLimit: '2',
    })
  }

  const handleChangeQR2 = (event) => {
    setError({ selfRegistartionCodeDigits: false })
    setQrCode2(true)
    setDigitCode2(false)
    setState({
      ...state,
      selfRegistartionCodeDigits: '',
      selfRegistartionVerifyMode: 'QRCODE',
    })
  }
  const handleChangeDigit2 = (event) => {
    setQrCode2(false)
    setDigitCode2(true)
    setState({
      ...state,
      selfRegistartionCodeDigits: '6',
      selfRegistartionVerifyMode: 'PASSCODE',
      selfRegistartionScanLimit: '2',
    })
  }

  const handleCheckBox = (event) => {
    setChecked(!checked)
  }

  const handleBadgePrint = (event) => {
    setCheckBadgePrint(!checkBadgePrint)
  }

  const handleWalkInBypass = (event) => {
    setWalkInBypass(!walkInBypass)
    setState({
      ...state,
      walkInBypass: event.target.checked,
    })
  }

  const handleInvitationBypass = (event) => {
    setInvitationByPass(!invitationByPass)
    setState({
      ...state,
      invitationBypass: event.target.checked,
    })
  }

  useEffect(() => {
    setState({
      ...state,
      dlScanMode: checked,
    })
  }, [checked])

  useEffect(() => {
    setState({
      ...state,
      badgePrint: checkBadgePrint,
    })
  }, [checkBadgePrint])

  const handleCheckBoxInvitationPurposeType = () => {
    setInvitationPurposeTypeMandatory(!invitationPurposeTypeMandatory)
  }

  const handleCheckBoxInvitationVisitorType = () => {
    setInvitationVisitorTypeMandatory(!invitationVisitorTypeMandatory)
  }

  const handleCheckBoxInvitationSiteType = () => {
    setInvitationSiteTypeMandatory(!invitationSiteTypeMandatory)
  }

  const handleCheckBoxInvitationCompanyName = () => {
    setInvitationCompanyNameMandatory(!invitationCompanyNameMandatory)
  }

  const handleCheckBoxInvitationProfilePhoto = () => {
    setInvitationProfilePhotoMandatory(!invitationProfilePhotoMandatory)
  }

  const handleCheckBoxWalkInPurposeType = () => {
    setWalkInPurposeTypeMandatory(!walkInPurposeTypeMandatory)
  }
  const handleCheckBoxWalkInVisitorType = () => {
    setWalkInVisitorTypeMandatory(!walkInVisitorTypeMandatory)
  }
  const handleCheckBoxWalkInSiteType = () => {
    setWalkInSiteTypeMandatory(!walkInSiteTypeMandatory)
  }
  const handleCheckBoxWalkInCompanyName = () => {
    setWalkInCompanyNameMandatory(!walkInCompanyNameMandatory)
  }
  const handleCheckBoxWalkInProfilePhoto = () => {
    setWalkInProfilePhotoMandatory(!WalkInProfilePhotoMandatory)
  }

  const handleCheckBoxSelfPurposeType = () => {
    setSelfRegistrationPurposeTypeMandatory(
      !selfRegistrationPurposeTypeMandatory,
    )
  }

  const handleCheckBoxSelfVisitorType = () => {
    setSelfRegistrationVisitorTypeMandatory(
      !selfRegistrationVisitorTypeMandatory,
    )
  }

  const handleCheckBoxSelfSiteType = () => {
    setSelfRegistrationSiteTypeMandatory(!selfRegistrationSiteTypeMandatory)
  }
  const handleCheckBoxSelfCompanyName = () => {
    setSelfRegistrationCompanyNameMandatory(
      !selfRegistrationCompanyNameMandatory,
    )
  }

  const handleCheckBoxSelfProfilePhoto = () => {
    setSelfRegistrationProfilePhotoMandatory(
      !selfRegistrationProfilePhotoMandatory,
    )
  }

  const handleCheckBoxSignature = () => {
    setWalkInSignatureMandatory(!WalkInSignatureMandatory)
  }

  useEffect(() => {
    setState({
      ...state,
      checkInvitationPurposeType: invitationPurposeTypeMandatory,
      checkInvitationVisitorType: invitationVisitorTypeMandatory,
      checkInvitationSiteType: invitationSiteTypeMandatory,
      checkInvitationCompanyName: invitationCompanyNameMandatory,
      checkInvitationProfilePhoto: invitationProfilePhotoMandatory,
    })
  }, [
    invitationPurposeTypeMandatory,
    invitationVisitorTypeMandatory,
    invitationSiteTypeMandatory,
    invitationCompanyNameMandatory,
    invitationProfilePhotoMandatory,
  ])

  useEffect(() => {
    setState({
      ...state,
      checkWalkInRegistrationPurposeType: walkInPurposeTypeMandatory,
      checkWalkInRegistrationVisitorType: walkInVisitorTypeMandatory,
      checkWalkInRegistrationSiteType: walkInSiteTypeMandatory,
      checkWalkInRegistrationCompanyName: walkInCompanyNameMandatory,
      checkWalkInRegistrationProfilePhoto: WalkInProfilePhotoMandatory,
      checkWalkInRegistrationSignatureOnKiosk: WalkInSignatureMandatory,
    })
  }, [
    walkInPurposeTypeMandatory,
    walkInVisitorTypeMandatory,
    walkInSiteTypeMandatory,
    walkInCompanyNameMandatory,
    WalkInProfilePhotoMandatory,
    WalkInSignatureMandatory,
  ])

  useEffect(() => {
    setState({
      ...state,
      checkSelfRegistrationPurposeType: selfRegistrationPurposeTypeMandatory,
      checkSelfRegistrationVisitorType: selfRegistrationVisitorTypeMandatory,
      checkSelfRegistrationSiteType: selfRegistrationSiteTypeMandatory,
      checkSelfRegistrationCompanyName: selfRegistrationCompanyNameMandatory,
      checkSelfRegistrationProfilePhoto: selfRegistrationProfilePhotoMandatory,
    })
  }, [
    selfRegistrationPurposeTypeMandatory,
    selfRegistrationVisitorTypeMandatory,
    selfRegistrationSiteTypeMandatory,
    selfRegistrationCompanyNameMandatory,
    selfRegistrationProfilePhotoMandatory,
  ])

  useEffect(() => {
    let payload = {
      companyId: decoded_jwtToken.companyId,
    }
    if (decoded_jwtToken.companyType === 'CUSTOMER') {
      dispatch(customerSubscription(payload))
        .unwrap()
        .then((res) => {
          if (res.code === 'MSGS4032' && res.data) {
            const featureNames = res.data.data.feature.map(
              (feature) => feature.name,
            )
            setFeatureList(featureNames)
          }
        })
    }
  }, [])

  useEffect(() => {
    const normalizedFeatureList = featureList.map((feature) => {
      if (feature === 'Checklist') {
        return 'Checklist'
      }
      if (feature === 'Watchlist') {
        return 'Watchlist'
      }
      if (feature === 'Offender Verification') {
        return 'Offender'
      }
      if (
        feature === 'Walk-In Registration through DL & Passport Scanner on Web'
      ) {
        return 'Walk-In Registration'
      }
      return feature
    })
    if (normalizedFeatureList.length !== 0) {
      normalizedFeatureList.forEach((item) => {
        if (item === 'Delivery Report') {
          setDeliveryPermission(true)
        }
        if (item === 'Walk-In Registration through DL on Mobile Application') {
          setEnableDLMobile(true)
        }
      })
    }
  }, [featureList])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  const handleChangeInvitationPurposeType = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setInvitationPurposeTypeMandatory(false)
  }

  const handleChangeInvitationVisitorType = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setInvitationVisitorTypeMandatory(false)
  }

  const handleChangeInvitationCompanyName = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setInvitationCompanyNameMandatory(false)
  }

  const handleChangeInvitationProfilePhoto = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setInvitationProfilePhotoMandatory(false)
  }

  const handleChangeInvitationSiteType = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setInvitationSiteTypeMandatory(false)
  }

  //handle change for walkIn
  const handleChangeWalkInProfilePhoto = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setWalkInProfilePhotoMandatory(false)
  }

  const handleChangeWalkInPurposeType = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setWalkInPurposeTypeMandatory(false)
  }

  const handleChangeWalkInVisitorType = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setWalkInVisitorTypeMandatory(false)
  }

  const handleChangeWalkInCompanyName = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setWalkInCompanyNameMandatory(false)
  }

  const handleChangeWalkInSiteType = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setWalkInSiteTypeMandatory(false)
  }

  const handleChangeWalkInSignature = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setWalkInSignatureMandatory(false)
  }

  //handle change for self

  const handleChangeSelfProfilePhoto = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setSelfRegistrationProfilePhotoMandatory(false)
  }

  const handleChangeSelfPurposeType = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setSelfRegistrationPurposeTypeMandatory(false)
  }

  const handleChangeSelfVisitorType = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setSelfRegistrationVisitorTypeMandatory(false)
  }

  const handleChangeSelfCompanyName = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setSelfRegistrationCompanyNameMandatory(false)
  }

  const handleChangeSelfSiteType = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
    setSelfRegistrationSiteTypeMandatory(false)
  }
  const dateFormats = [
    { label: 'DD-MM-YYYY', value: 'dd-MM-yyyy' },
    { label: 'MM-DD-YYYY', value: 'MM-dd-yyyy' },
    { label: 'YYYY-MM-DD', value: 'yyyy-MM-dd' }
  ];

  const formatMapping = {
    'DD-MM-YYYY': 'dd-MM-yyyy',
    'MM-DD-YYYY': 'MM-dd-yyyy',
    'YYYY-MM-DD': 'yyyy-MM-dd',
  };

  const handleDataFormat = (event, newValue) => {
    if (newValue === null) {
      setState({
        ...state,
        dateFormat: ''
      });
    } else {
      const backendFormat = formatMapping[newValue.label];
      setState({
        ...state,
        dateFormat: backendFormat || ''
      });
    }
  };

  const handleSelection = (event, value) => {
    if (value) {
      setSelectedHost(value)
      setState({
        ...state,
        defaultHostId: value.id,
      })
    } else {
      setState({
        ...state,
        defaultHostId: '',
      })
      setSelectedHost()
    }
  }

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            variant="h3"
            style={{
              marginLeft: darkMode ? '33px' : '0px',
              marginRight: darkMode ? '18px' : '12px',
              color: darkMode ? '#fff' : '#3D4977',
              marginTop: darkMode ? '3px' : '4px',
            }}
          >
            {t('ADDSETTINGS')}
          </Typography>
        </Box>
      </Grid>
      <Paper style={{ overflowY: "auto", height: "100vh", marginLeft: darkMode ? '31px' : '', marginRight: darkMode ? '31px' : '', backgroundColor: darkMode ? '#021C31' : '' }}>
        <PerfectScrollbar options={{ wheelPropagation: true }} style={{ backgroundColor: darkMode ? '#021C31' : '#fff' }}>
          <Grid
            sx={{ width: { xs: '100%' } }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '30px',
              backgroundColor: darkMode ? '#0F273B' : '#fff'
            }}
          >
            <FormControl component="fieldset" variant="standard" >
              <FormLabel component="legend" className={darkMode ? 'AddtionalTitle1' : 'AddtionalTitle'}>
                {t('APPRMODE')} {t('APPRMODE1')}
              </FormLabel>
              <br />
              <FormGroup >
                <Grid style={{ display: 'flex', marginBottom: '20px', }}>
                  <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                    <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                      {t('labelDashBoardTotalReschedule2')}
                    </FormLabel>
                    <Android12Switch
                      checked={state.invitationApproval}
                      onChange={handleChange}
                      name="invitationApproval"
                    />
                  </Grid>
                </Grid>
                <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                  <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                    <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                      {t('WALKINREPORTLBL6')}
                    </FormLabel>
                    <Android12Switch
                      checked={state.walkInRegistartionApproval}
                      onChange={handleChange}
                      name="walkInRegistartionApproval"
                    />
                  </Grid>
                </Grid>
                <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                  <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                    <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                      {t('SELFREGISTRATIONLBL1')}
                    </FormLabel>
                    <Android12Switch
                      checked={state.selfRegistartionApproval}
                      onChange={handleChange}
                      name="selfRegistartionApproval"
                    />
                  </Grid>
                </Grid>
                {deliveryPermission ? (
                  <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                    <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                      <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                        {t('DELIVERYOPTION')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.delivery}
                        onChange={handleChange}
                        name="delivery"
                      // disabled={deliveryMobileShow}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                  <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                    <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                      {t('BADGEPRINT')}
                    </FormLabel>
                    <Android12Switch
                      checked={checkBadgePrint}
                      onChange={handleBadgePrint}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                  <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                    <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                      {t('VISPUPUPDSUC003')}
                    </FormLabel>
                    <Android12Switch
                      checked={state.printerStatus}
                      onChange={handleChange}
                      name="printerStatus"
                    />
                  </Grid>
                </Grid>
              </FormGroup>
              <RadioGroup
                value={state.printerDevice}
                name="printerDevice"
                onChange={handlePrintDevice}
                row
              >
                <FormControlLabel
                  disabled={!state.printerStatus}
                  value="Kiosk"
                  control={<Radio sx={{ color: darkMode ? '#fff' : '#707070', '&.Mui-checked': { color: darkMode ? '#fff' : '#707070' } }} />}
                  label="Kiosk"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      color: darkMode ? '#fff' : '#707070',
                    },
                  }}
                />
                <FormControlLabel
                  disabled={!state.printerStatus}
                  value="Brother"
                  control={
                    <Radio
                      sx={{
                        color: darkMode ? '#fff' : '#707070',
                        '&.Mui-checked': {
                          color: darkMode ? '#fff' : '#707070',
                        },
                      }}
                    />
                  }
                  label="Brother"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      color: darkMode ? '#fff' : '#707070', // Label color
                    },
                  }}
                />
              </RadioGroup>

              {/*DateFormat*/}
              <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                <Grid className={darkMode ? 'leftSwitch4' : 'leftSwitch2'}>
                  <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                    {t('APRMODE2')}
                    <Divider orientation="horizontal" />
                    <br />
                    <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                      <Grid item xs={12} sm={9}>
                        <Autocomplete
                          sx={{
                            width: 300,
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                              },
                            },
                          }}
                          noOptionsText={'No Options found'}
                          onChange={handleDataFormat}
                          options={dateFormats}
                          defaultValue={dateFormats[0]}
                          value={dateFormats.find(
                            (option) =>
                              option.value === state.dateFormat
                          ) || null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('APRMODE3')}
                              InputLabelProps={{
                                style: {
                                  color: darkMode ? '#fff' : '#0F273B',
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  color: darkMode ? '#fff' : '#0F273B', // Set text color
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </FormLabel>
                </Grid>

              </Grid>


              {/*Proxy Domain*/}
              <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                  <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                    {t('PROXY_DOMAIN')}
                  </FormLabel>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={3.1} sx={{ mt: 0, mb: 2 }}>
                  <CustomTextfield
                    label={t('PROXY_DOMAIN')}
                    error={error.proxyDomain}
                    name="proxyDomain"
                    value={state.proxyDomain.replace('@', '')}
                    handleChange={(e) => handleProxyDomain(e)}
                    helperText={error.proxyDomain}
                    required={true}
                    autoComplete="off"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span style={{ color: darkMode ? '#fff' : '' }}>@ </span>
                        </InputAdornment>
                      ),
                      style: { color: darkMode ? '#fff' : 'black' }
                    }}
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>
              </Grid>
            </FormControl>
            <FormControl component="fieldset" variant="standard" style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
              <FormLabel component="legend" className={darkMode ? 'AddtionalTitle1' : 'AddtionalTitle'}>
                {t('VISPUPUPDSUC004')}
              </FormLabel>
              <br />
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChangeAccordian('panel1')}
                style={{ marginTop: '10px', backgroundColor: darkMode ? "#0F273B" : "#fff" }}
              >
                <AccordionSummary
                  style={{
                    backgroundColor: '#F7F8FA',
                    borderBottom: '1px solid #E6E6E6',
                    backgroundColor: darkMode ? "#1B3245" : "#fff"
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                    {t('labelDashBoardTotalReschedule')}
                  </FormLabel>
                </AccordionSummary>

                <AccordionDetails>
                  <FormGroup>
                    {/* Add for mandatory feild Invitation*/}
                    <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                      <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                        Profile Photo
                      </FormLabel>
                      <Android12Switch
                        checked={state.invitationProfilePhoto}
                        onChange={handleChangeInvitationProfilePhoto}
                        name="invitationProfilePhoto"
                      />
                    </Grid>
                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          disabled={!state.invitationProfilePhoto}
                          control={
                            <Checkbox
                              checked={invitationProfilePhotoMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                            />
                          }
                          checked={!state.invitationProfilePhoto}
                          onChange={handleCheckBoxInvitationProfilePhoto}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid style={{ display: 'flex', marginBottom: '0px' }}>
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                          Purpose of Visit
                        </FormLabel>
                        <Android12Switch
                          checked={state.invitationPurposeType}
                          onChange={handleChangeInvitationPurposeType}
                          name="invitationPurposeType"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          disabled={!state.invitationPurposeType}
                          control={
                            <Checkbox
                              checked={invitationPurposeTypeMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                            />
                          }
                          checked={!state.invitationPurposeType}
                          onChange={handleCheckBoxInvitationPurposeType}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Visitor Type
                        </FormLabel>
                        <Android12Switch
                          checked={state.invitationVisitorType}
                          onChange={handleChangeInvitationVisitorType}
                          name="invitationVisitorType"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={invitationVisitorTypeMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.invitationVisitorType}
                            />
                          }
                          checked={!state.invitationVisitorType}
                          onChange={handleCheckBoxInvitationVisitorType}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Company Name
                        </FormLabel>
                        <Android12Switch
                          checked={state.invitationCompanyName}
                          onChange={handleChangeInvitationCompanyName}
                          name="invitationCompanyName"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={invitationCompanyNameMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.invitationCompanyName}
                            />
                          }
                          checked={!state.invitationCompanyName}
                          onChange={handleCheckBoxInvitationCompanyName}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Site
                        </FormLabel>
                        <Android12Switch
                          checked={state.invitationSiteType}
                          onChange={handleChangeInvitationSiteType}
                          name="invitationSiteType"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={invitationSiteTypeMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.invitationSiteType}
                            />
                          }
                          checked={!state.invitationSiteType}
                          onChange={handleCheckBoxInvitationSiteType}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item sm={3.1} sx={{ mt: 2, mb: 2 }}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          {t('labelDashBoardTotalReschedule1')}
                        </FormLabel>
                        <CustomTextfield
                          label="Select Interval Time"
                          error={error.invitationIntervalTime}
                          name="invitationIntervalTime"
                          value={state.invitationIntervalTime}
                          handleChange={(e) => changeIntervalTime(e)}
                          required={true}
                          validation="numeric"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ color: darkMode ? '#fff' : '' }}>{t('MINT')}</span>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 5 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <FormLabel
                        component="legend"
                        className={darkMode ? "formLabel4" : "formLabel1"}
                        style={{
                          marginLeft: '18px',
                          marginTop: '20px',
                          marginBottom: '0px',
                        }}
                      >
                        {t('SETTING1')}
                      </FormLabel>
                      <Grid item sm={3.1}>
                        <CustomTextfield
                          maxLength={5}
                          label="Early Allow to Check-In"
                          error={error.invitationEarlyCheckInTime}
                          name="invitationEarlyCheckInTime"
                          value={state.invitationEarlyCheckInTime}
                          handleChange={(e) => changeIntervalTime(e)}
                          required={true}
                          validation="numeric"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ color: darkMode ? '#fff' : '' }}>{t('MINT')}</span>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 5 }}
                        />
                      </Grid>
                      <Grid item sm={3.1}>
                        <CustomTextfield
                          maxLength={5}
                          label="Alert to Late Check-Out"
                          error={error.invitationLateCheckOutTime}
                          name="invitationLateCheckOutTime"
                          value={state.invitationLateCheckOutTime}
                          handleChange={(e) => changeIntervalTime(e)}
                          required={true}
                          validation="numeric"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ color: darkMode ? '#fff' : '' }}>{t('MINT')}</span>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 5 }}
                        />
                      </Grid>
                    </Grid>
                    <>
                      <Grid
                        style={{
                          display: 'flex',
                          marginBottom: '30px',
                          marginTop: '20px',
                        }}
                      >
                        <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                          <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                            {t('QRCODE1')}
                          </FormLabel>
                          <Android12Switch
                            checked={qrCode}
                            onChange={handleChangeQR}
                            name="invitationVerifyMode"
                          />
                        </Grid>
                      </Grid>
                      {qrCode ? (
                        <Grid item sm={6} sx={{ width: '320px', mb: 2 }}>
                          <CustomTextfield
                            disabled={qrCode ? false : true}
                            maxLength={3}
                            label={t('COMMON151')}
                            error={error.invitationScanLimit}
                            name="invitationScanLimit"
                            value={state.invitationScanLimit}
                            handleChange={(e) => handleChangeofDropdown(e)}
                            validation="numeric"
                            required={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span style={{ color: darkMode ? '#fff' : '' }}>{t('SETTINGS002')}</span>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{ maxLength: 3 }}
                          />
                        </Grid>
                      ) : (
                        ''
                      )}
                    </>
                    <>
                      <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                        <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                          <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                            {t('SETTINGS003')}
                          </FormLabel>
                          <Android12Switch
                            checked={digitCode}
                            onChange={handleChangeDigit}
                            name="invitationVerifyMode"
                          />
                        </Grid>
                      </Grid>
                      {digitCode ? (
                        <Grid item sm={6} sx={{ mb: 2, width: '320px' }}>
                          <CustomDropdown
                            disabled={digitCode ? false : true}
                            label="Select Digit Code Type"
                            value={state.invitationCodeDigits}
                            error={error.invitationCodeDigits}
                            name="invitationCodeDigits"
                            data={Digit}
                            handleChange={(e) => handleChangeofDropdown(e)}
                          />
                        </Grid>
                      ) : (
                        ''
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={invitationByPass}
                            sx={{
                              color: '#F2824C',
                              '&.Mui-checked': {
                                color: '#F2824C',
                              },
                            }}
                          />
                        }
                        label={'Confirmation detail for visitor'}
                        sx={{
                          color: darkMode ? '#fff' : '#000',
                          '& .MuiFormControlLabel-label': {
                            color: darkMode ? '#fff' : '#000',
                          },
                          '&.Mui-disabled': {
                            color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                          },
                        }}
                        onClick={handleInvitationBypass}
                      />
                    </>
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChangeAccordian('panel2')}
                style={{ marginTop: '30px', backgroundColor: darkMode ? "#0F273B" : "#fff" }}

              >
                <AccordionSummary
                  style={{
                    backgroundColor: '#F7F8FA',
                    borderBottom: '1px solid #E6E6E6',
                    backgroundColor: darkMode ? "#1B3245" : "#fff"
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                    {t('WALKINREPORTLBL')}
                  </FormLabel>
                </AccordionSummary>
                <AccordionDetails>
                  {/*Base on addition setting walkin part field will work*/}
                  <FormGroup>
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '10px',
                        marginTop: '5px',

                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                          {t('AUTO_CONTACT_INFO')}
                        </FormLabel>
                        <Android12Switch
                          checked={state.walkInAutoContactInfo}
                          onChange={handleWalkInAutoContactInfo}
                          name="walkInAutoContactInfo"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Profile Photo
                        </FormLabel>
                        <Android12Switch
                          checked={state.walkInRegistrationProfilePhoto}
                          onChange={handleChangeWalkInProfilePhoto}
                          name="walkInRegistrationProfilePhoto"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={WalkInProfilePhotoMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.walkInRegistrationProfilePhoto}
                            />
                          }
                          checked={!state.walkInRegistrationProfilePhoto}
                          onChange={handleCheckBoxWalkInProfilePhoto}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid style={{ display: 'flex', marginBottom: '0px' }}>
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Purpose of Visit
                        </FormLabel>
                        <Android12Switch
                          checked={state.walkInRegistrationPurposeType}
                          onChange={handleChangeWalkInPurposeType}
                          name="walkInRegistrationPurposeType"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={walkInPurposeTypeMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.walkInRegistrationPurposeType}
                            />
                          }
                          checked={!state.walkInRegistrationPurposeType}
                          onChange={handleCheckBoxWalkInPurposeType}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Visitor Type
                        </FormLabel>
                        <Android12Switch
                          checked={state.walkInRegistrationVisitorType}
                          onChange={handleChangeWalkInVisitorType}
                          name="walkInRegistrationVisitorType"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={walkInVisitorTypeMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.walkInRegistrationVisitorType}
                            />
                          }
                          checked={!state.walkInRegistrationVisitorType}
                          onChange={handleCheckBoxWalkInVisitorType}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', 
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Company Name
                        </FormLabel>
                        <Android12Switch
                          checked={state.wlakInRegistrationCompanyName}
                          onChange={handleChangeWalkInCompanyName}
                          name="wlakInRegistrationCompanyName"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={walkInCompanyNameMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.wlakInRegistrationCompanyName}
                            />
                          }
                          checked={!state.wlakInRegistrationCompanyName}
                          onChange={handleCheckBoxWalkInCompanyName}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Site
                        </FormLabel>
                        <Android12Switch
                          checked={state.walkInRegistrationSiteType}
                          onChange={handleChangeWalkInSiteType}
                          name="walkInRegistrationSiteType"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={walkInSiteTypeMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.walkInRegistrationSiteType}
                            />
                          }
                          checked={!state.walkInRegistrationSiteType}
                          onChange={handleCheckBoxWalkInSiteType}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Signature On Kiosk
                        </FormLabel>
                        <Android12Switch
                          checked={state.walkInRegistrationSignatureOnKiosk}
                          onChange={handleChangeWalkInSignature}
                          name="walkInRegistrationSignatureOnKiosk"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={WalkInSignatureMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={
                                !state.walkInRegistrationSignatureOnKiosk
                              }
                            />
                          }
                          checked={!state.walkInRegistrationSignatureOnKiosk}
                          onChange={handleCheckBoxSignature}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item sm={3.1} sx={{ mt: 2, mb: 2 }}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          {t('labelDashBoardTotalReschedule1')}
                        </FormLabel>
                        <CustomTextfield
                          label="Select Interval Time"
                          error={error.walkInIntervalTime}
                          name="walkInIntervalTime"
                          value={state.walkInIntervalTime}
                          handleChange={(e) => changeIntervalTime(e)}
                          required={true}
                          validation="numeric"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                               <span style={{ color: darkMode ? '#fff' : '' }}> {t('MINT')}</span>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 5 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <FormLabel
                        component="legend"
                        className={darkMode ? "formLabel4" : "formLabel1"}
                        style={{
                          marginLeft: '18px',
                          marginTop: '20px',
                          marginBottom: '0px',
                        }}
                      >
                        {t('SETTING1')}
                      </FormLabel>
                      <Grid item sm={3.1}>
                        <CustomTextfield
                          label="Early Allow to Check-In"
                          error={error.walkInEarlyCheckInTime}
                          name="walkInEarlyCheckInTime"
                          value={state.walkInEarlyCheckInTime}
                          handleChange={(e) => changeIntervalTime(e)}
                          required={true}
                          validation="numeric"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ color: darkMode ? '#fff' : '' }}>{t('MINT')}</span>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 5 }}
                        />
                      </Grid>
                      <Grid item sm={3.1}>
                        <CustomTextfield
                          label="Alert to Late Check-Out"
                          error={error.walkInLateCheckOutTime}
                          name="walkInLateCheckOutTime"
                          value={state.walkInLateCheckOutTime}
                          handleChange={(e) => changeIntervalTime(e)}
                          required={true}
                          validation="numeric"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ color: darkMode ? '#fff' : '' }}>{t('MINT')}</span>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 5 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '20px',
                        marginTop: '30px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                          {t('QRCODE1')}
                        </FormLabel>
                        <Android12Switch
                          checked={qrCode1}
                          onChange={handleChangeQR1}
                          name="walkInRegistartionVerifyMode"
                        />
                      </Grid>
                    </Grid>
                    {qrCode1 ? (
                      <Grid item sm={6} sx={{ width: '320px', mb: 2 }}>
                        <CustomTextfield
                          disabled={qrCode1 ? false : true}
                          maxLength={3}
                          label={t('COMMON151')}
                          error={error.walkInScanLimit}
                          name="walkInScanLimit"
                          value={state.walkInScanLimit}
                          handleChange={(e) => handleChangeofDropdown(e)}
                          validation="numeric"
                          required={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ color: darkMode ? '#fff' : '' }}>{t('SETTINGS002')}</span>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 3 }}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                          {t('SETTINGS003')}
                        </FormLabel>
                        <Android12Switch
                          checked={digitCode1}
                          onChange={handleChangeDigit1}
                          name="walkInRegistartionVerifyMode"
                        />
                      </Grid>
                    </Grid>
                    {digitCode1 ? (
                      <Grid item sm={6} sx={{ mb: 2, width: '320px' }}>
                        <CustomDropdown
                          disabled={digitCode1 ? false : true}
                          label="Select Digit Code Type"
                          value={state.walkInCodeDigits}
                          error={error.walkInCodeDigits}
                          name="walkInCodeDigits"
                          data={Digit}
                          handleChange={(e) => handleChangeofDropdown(e)}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}

                    <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                      <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                        {t('CAPTURE_IMAGE')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.walkInImageCapture}
                        onChange={handleImageCapture}
                        name="walkInImageCapture"
                      />
                    </Grid>
                    {state.walkInImageCapture ? (
                      <Grid
                        item
                        sm={6}
                        sx={{ mb: 2, width: '320px', mt: '15px' }}
                      >
                        <CustomDropdown
                          // disabled={digitCode1 ? false : true}
                          label={t('NUMBER_OF_CAPTURE')}
                          value={state.walkInImagesCaptureLimit}
                          name="walkInImagesCaptureLimit"
                          data={captureLimit}
                          handleChange={(e) => handleChangeofDropdown(e)}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}

                    <FormGroup>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {enableDLMobile ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                sx={{
                                  color: '#F2824C',
                                  '&.Mui-checked': {
                                    color: '#F2824C',
                                  },
                                }}
                              />
                            }
                            label={
                              checked
                                ? 'DL Walk-In on mobile  '
                                : 'Enable DL Walk-In on mobile'
                            }
                            sx={{
                              color: darkMode ? "#fff" : "",
                            }}
                            onClick={handleCheckBox}
                          />
                        ) : (
                          ''
                        )}

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={walkInBypass}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': {
                                  color: '#F2824C',
                                },
                              }}
                            />
                          }
                          label={'Confirmation detail for visitor'}
                          sx={{
                            color: darkMode ? "#fff" : "inherit",
                          }}
                          onClick={handleWalkInBypass}
                        />
                      </div>
                    </FormGroup>
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChangeAccordian('panel3')}
                style={{ marginTop: '30px', backgroundColor: darkMode ? "#0F273B" : "#fff" }}
              >
                <AccordionSummary
                  style={{
                    // backgroundColor: '#F7F8FA',
                    borderBottom: '1px solid #E6E6E6',
                    backgroundColor: darkMode ? "#1B3245" : "#F7F8FA"
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                    {t('SELFREGISTRATIONLBL')}
                  </FormLabel>
                </AccordionSummary>

                <AccordionDetails>
                  <FormGroup>
                    {/*selfregistration mandatory field */}
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '10px',
                        marginTop: '5px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                          {t('AUTO_CONTACT_INFO')}
                        </FormLabel>
                        <Android12Switch
                          checked={state.selfAutoContactInfo}
                          onChange={handleSelfAutoContactInfo}
                          name="selfAutoContactInfo"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Profile Photo
                        </FormLabel>
                        <Android12Switch
                          checked={state.selfRegistrationProfilePhoto}
                          onChange={handleChangeSelfProfilePhoto}
                          name="selfRegistrationProfilePhoto"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={selfRegistrationProfilePhotoMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.selfRegistrationProfilePhoto}
                            />
                          }
                          checked={!state.selfRegistrationProfilePhoto}
                          onChange={handleCheckBoxSelfProfilePhoto}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid style={{ display: 'flex', marginBottom: '0px' }}>
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Purpose of Visit
                        </FormLabel>
                        <Android12Switch
                          checked={state.selfRegistrationPurposeType}
                          onChange={handleChangeSelfPurposeType}
                          name="selfRegistrationPurposeType"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={selfRegistrationPurposeTypeMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.selfRegistrationPurposeType}
                            />
                          }
                          checked={!state.selfRegistrationPurposeType}
                          onChange={handleCheckBoxSelfPurposeType}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Visitor Type
                        </FormLabel>
                        <Android12Switch
                          checked={state.selfRegistrationVisitorType}
                          onChange={handleChangeSelfVisitorType}
                          name="selfRegistrationVisitorType"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={selfRegistrationVisitorTypeMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.selfRegistrationVisitorType}
                            />
                          }
                          checked={!state.selfRegistrationVisitorType}
                          onChange={handleCheckBoxSelfVisitorType}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Company Name
                        </FormLabel>
                        <Android12Switch
                          checked={state.selfRegistrationCompanyName}
                          onChange={handleChangeSelfCompanyName}
                          name="selfRegistrationCompanyName"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={selfRegistrationCompanyNameMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.selfRegistrationCompanyName}
                            />
                          }
                          checked={!state.selfRegistrationCompanyName}
                          onChange={handleCheckBoxSelfCompanyName}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>
                    <Divider orientation="horizontal" />
                    <Grid
                      style={{
                        display: 'flex',
                        marginBottom: '0px',
                        paddingBottom: '0px',
                      }}
                    >
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? "formLabel4" : "formLabel1"}>
                          Site
                        </FormLabel>
                        <Android12Switch
                          checked={state.selfRgistrationSiteType}
                          onChange={handleChangeSelfSiteType}
                          name="selfRgistrationSiteType"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}
                      style={{
                        display: 'flex',
                        marginTop: '0px',
                        paddingTop: '0px',
                      }}
                    >
                      <FormGroup>
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={selfRegistrationSiteTypeMandatory}
                              sx={{
                                color: '#F2824C',
                                '&.Mui-checked': { color: '#F2824C' },
                              }}
                              disabled={!state.selfRgistrationSiteType}
                            />
                          }
                          checked={!state.selfRgistrationSiteType}
                          onChange={handleCheckBoxSelfSiteType}
                          label="Mandatory Field"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            '& .MuiFormControlLabel-label': {
                              color: darkMode ? '#fff' : '#000',
                            },
                            '&.Mui-disabled': {
                              color: darkMode ? '#fff !important' : '#aaa !important', // Set color when disabled
                            },
                          }}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid sx={{ mt: '20px' }}>
                      <Autocomplete
                        options={employees}
                        getOptionLabel={(option) =>
                          option.lastName
                            ? option.firstName + ' ' + option.lastName
                            : option.firstName
                              ? option.firstName
                              : ''
                        }
                        value={selectedHost}
                        onChange={handleSelection}
                        renderInput={(params) => (
                          <CustomTextfield {...params} label="Default Host" />
                        )}
                        sx={{ width: 300 }}
                      />
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item sm={3.1} sx={{ mt: 2, mb: 2 }}>
                        <FormLabel component="legend" className="formLabel1">
                          {t('labelDashBoardTotalReschedule1')}
                        </FormLabel>
                        <CustomTextfield
                          label="Select Interval Time"
                          error={error.selfIntervalTime}
                          name="selfIntervalTime"
                          value={state.selfIntervalTime}
                          handleChange={(e) => changeIntervalTime(e)}
                          required={true}
                          validation="numeric"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ color: darkMode ? '#fff' : '' }}>{t('MINT')}</span>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 5 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <FormLabel
                        component="legend"
                        className="formLabel1"
                        style={{
                          marginLeft: '18px',
                          marginTop: '20px',
                          marginBottom: '0px',
                        }}
                      >
                        {t('SETTING1')}
                      </FormLabel>
                      <Grid item sm={3.1}>
                        <CustomTextfield
                          label="Early Allow to Check-In"
                          error={error.selfEarlyCheckInTime}
                          name="selfEarlyCheckInTime"
                          value={state.selfEarlyCheckInTime}
                          handleChange={(e) => changeIntervalTime(e)}
                          required={true}
                          validation="numeric"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ color: darkMode ? '#fff' : '' }}>{t('MINT')}</span>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 5 }}
                        />
                      </Grid>
                      <Grid item sm={3.1}>
                        <CustomTextfield
                          label="Alert to Late Check-Out"
                          error={error.selfLateCheckOutTime}
                          name="selfLateCheckOutTime"
                          value={state.selfLateCheckOutTime}
                          handleChange={(e) => changeIntervalTime(e)}
                          required={true}
                          validation="numeric"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ color: darkMode ? '#fff' : '' }}>{t('MINT')}</span>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 5 }}
                        />
                      </Grid>
                    </Grid>
                    <>
                      <Grid
                        style={{
                          display: 'flex',
                          marginBottom: '20px',
                          marginTop: '30px',
                        }}
                      >
                        <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                          <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                            {t('QRCODE1')}
                          </FormLabel>
                          <Android12Switch
                            checked={qrCode2}
                            onChange={handleChangeQR2}
                            name="selfRegistartionVerifyMode"
                          />
                        </Grid>
                      </Grid>
                      {qrCode2 ? (
                        <Grid item sm={6} sx={{ width: '320px', mb: 2 }}>
                          <CustomTextfield
                            disabled={qrCode2 ? false : true}
                            maxLength={3}
                            label={t('COMMON151')}
                            error={error.selfRegistartionScanLimit}
                            name="selfRegistartionScanLimit"
                            value={state.selfRegistartionScanLimit}
                            handleChange={(e) => handleChangeofDropdown(e)}
                            validation="numeric"
                            required={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span style={{ color: darkMode ? '#fff' : '' }}>{t('SETTINGS002')}</span>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{ maxLength: 3 }}
                          />
                        </Grid>
                      ) : (
                        ''
                      )}
                    </>
                    <Grid style={{ display: 'flex', marginBottom: '20px' }}>
                      <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                        <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                          {t('SETTINGS003')}
                        </FormLabel>
                        <Android12Switch
                          checked={digitCode2}
                          onChange={handleChangeDigit2}
                          name="selfRegistartionVerifyMode"
                        />
                      </Grid>
                    </Grid>
                    {digitCode2 ? (
                      <Grid item sm={6} sx={{ mb: 2, width: '320px' }}>
                        <CustomDropdown
                          disabled={digitCode2 ? false : true}
                          label="Select Digit Code Type"
                          value={state.selfRegistartionCodeDigits}
                          error={error.selfRegistartionCodeDigits}
                          name="selfRegistartionCodeDigits"
                          data={Digit}
                          handleChange={(e) => handleChangeofDropdown(e)}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                  <Grid className={darkMode ? 'leftSwitch3' : 'leftSwitch1'}>
                    <FormLabel component="legend" className={darkMode ? 'formLabel2' : 'formLabel'}>
                      {t('CAPTURE_IMAGE')}
                    </FormLabel>
                    <Android12Switch
                      checked={state.selfImageCapture}
                      onChange={handleImageCapture}
                      name="selfImageCapture"
                    />
                  </Grid>

                  {state.selfImageCapture ? (
                    <Grid
                      item
                      sm={6}
                      sx={{ mb: 2, width: '320px', mt: '15px' }}
                    >
                      <CustomDropdown
                        label={t('NUMBER_OF_CAPTURE')}
                        value={state.selfImagesCaptureLimit}
                        name="selfImagesCaptureLimit"
                        data={captureLimit}
                        handleChange={(e) => handleChangeofDropdown(e)}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                </AccordionDetails>
              </Accordion>
            </FormControl>
            <Grid container xs={12} justifyContent="flex-end" mt={4}>
              <Grid item sx={{ mr: 2 }}>
                <SubmitButtons onClick={handleSubmit} variant="contained">
                  <Typography>{t('dailogButtonSave')}</Typography>
                </SubmitButtons>
              </Grid>
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Paper>
    </>
  )
}

export default AdditionalSettings
