import { Avatar, Grid } from '@material-ui/core'
import { Box, Link, Stack, Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import '../../App.css'
import DataTable from '../../components/DataTable/DataTable'
import Search from '../../components/SearchTab/Search'
import UserIcon from '../../assets/USA_Images/addUser.svg'
import EditIcon from '../Person/EditIcon'
import ResendIcon from '../Invitations/ResendIcon'
import EmployeeDeleteIcon from '../Employee/EmployeeDeleteIcon'
import { REACT_CREATE_USER } from '../../actions/EndPoints'
import {
  getUserById,
  getUserList,
  resendUserActivationLink,
} from '../../slices/User/UserSlice'
import CommonUtil from '../../Util/CommonUtils'
import UserFilter from './UserFilter'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import Toast from '../../components/ToastContainer/CustomToast'
import DeleteUser from './DeleteUser'
import { getCompanyInfo } from '../../slices/Company/CompanySlice'
import isoCountries from 'i18n-iso-countries'

export default function User() {
  const history = useHistory()
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [open, setOpen] = useState(false)
  const [clear, setClear] = React.useState(false)
  const [searchRecord, setSearchRecord] = useState()
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(0)
  const [searchedRows, setSerchedRows] = useState([])
  const [openDeleteUser, setOpenDeleteUser] = useState(false)
  const [userId, setUserId] = useState()
  const [name, setName] = useState()
  const [del, setDel] = useState(false)
  const [openEditUser, setOpenEditUser] = useState(false)
  const [editRowData, setEditRowData] = useState({})
  const [country, setCountry] = useState('')

  const handleOpen = () => {
    setOpen(true)
    history.push({
      pathname: REACT_CREATE_USER,
      country: country,
    })
  }

  const [filter, setFilter] = React.useState({
    status: '',
  })

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const defaultFilter = {
    status: '',
  }

  const applyFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      status: data.status,
    })
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 18) {
      return text.substring(0, 18) + '...'
    } else {
      return text
    }
  }

  const handleEditUser = (params) => {
    setOpenEditUser(true)
    dispatch(getUserById(params.row.id))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0033') {
          let userData = {
            user: data.data,
            roleName: params.row.roleName,
          }
          setEditRowData(userData)
          history.push({
            pathname: REACT_CREATE_USER,
            state: userData,
            country: country,
          })
        }
      })
  }

  const handleResendAccountActivate = (paramValue) => {
    dispatch(resendUserActivationLink(paramValue))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0001') {
          Toast(t('USERSUCCESS'), 'success')
        }
      })
      .catch((er) => {})
  }

  const handleDeleteUser = (params) => {
    setOpenDeleteUser(true)
    setUserId(params.row.id)
    setName(params.row.name)
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadCompany()
  }, [])

  const loadCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setCountry(countryCode)
          }
        }
      })
      .catch((er) => {})
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  useEffect(() => {
    loadUserData(filter, paging)
  }, [filter, paging])

  const loadUserData = (filter, paging) => {
    dispatch(getUserList(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4000' && !CommonUtil.isEmpty(data.data)) {
          setRows(data.data.users)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.email ? test.email.includes(searchedVal.toLowerCase()) : '') ||
        (test.status
          ? test.status.trim().replace('-','').toLowerCase() ===
            searchedVal.trim().replace('-','').toLowerCase()
          : '') ||
        (test.phone
          ? test.phone.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.roleName
          ? test.roleName.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        `${test.name}`.toLowerCase().includes(searchedVal.toLowerCase())
      )
    })
    setRows(filteredRows)
  }

  const getStatus = (params) => {
    return params.row.status === 'Active' ? true : false
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={
            params.row.imagePreSignedURL !== undefined
              ? params.row.imagePreSignedURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={params.row.imagePreSignedURL}
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(() => [
    {
      field: 'user.profile',
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },
    {
      field: 'name',
      headerName: t('CUSTDATABLE26'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'email',
      headerName: t('CUSTDATABLE02'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.email,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'mobile',
      headerName: t('CUSTDATABLE03'),
      type: 'singleSelect',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.phone,
    },

    {
      field: 'role',
      headerName: t('USERROLE'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.roleName,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'status',
      headerName: t('CUSTDATABLE07'),
      flex: 1,
      valueGetter: (params) => (params.row.status === "Active" ? "ACTIVE" : "INACTIVE")  ,
    },
    {
      field: 'Actions',
      headerName: t('CUSTDATABLE16'),
      type: 'actions',
      flex: 1,
      minWidth: 150,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleEditUser(params)}
        />,

        <GridActionsCellItem
          icon={<EmployeeDeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteUser(params)}
        />,
        <GridActionsCellItem
          icon={<ResendIcon />}
          label="Resend Mail"
          disabled={getStatus(params)}
          onClick={() => handleResendAccountActivate(params.row)}
        />,
      ],
    },
  ])

  return (
    <>
      <Stack
        direction="row"
        style={{
          height: '80px',
        }}
      >
        <Box style={{ margin: '16px, 0' }}>
          <Typography variant="h3">{t('LABELNAVITEMSUSER')}</Typography>
        </Box>

        <Box
          display={'flex'}
          width={'100%'}
          flexDirection={'row-reverse'}
          alignItems={'center'}
        >
          <Box item style={{ margin: '0 8px' }}>
            <Tooltip title={t('USERADD')}>
              <img
                style={{
                  height: '50px',
                }}
                alt="User"
                src={UserIcon}
                onClick={handleOpen}
              />
            </Tooltip>
          </Box>

          <Box item>
            {clear && (
              <Link
                href="#"
                underline="hover"
                className="line"
                style={{
                  opacity: '0.8',
                  color: '#E3393C',
                  fontSize: '14px',
                  underline: 'hover',
                  display: 'inline',
                }}
                onClick={resetFilter}
              >
                {t('CLEARFILTER')}
              </Link>
            )}
          </Box>

          <Box item style={{ margin: '0 8px' }} className="table_iconStyle1">
            <UserFilter
              rowsPerPage={rowsPerPage}
              applyFilter={(data) => applyFilter(data)}
            />
          </Box>

          <Box item>
            <Search
              onSearch={(e) => globalsearch(e)}
              clearSearch={() => loadUserData(filter, paging)}
            />
          </Box>
        </Box>
      </Stack>

      <Grid item style={{ width: '100%', height: '-webkit-fill-available' }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </Grid>
      {openDeleteUser && (
        <DeleteUser
          open={openDeleteUser}
          handleClose={(data) => {
            setOpenDeleteUser(data)
            loadUserData(filter, paging)
          }}
          DeleteId={userId}
          DeleteName={name}
          delete={() => setDel(true)}
        />
      )}
    </>
  )
}
