import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ChecklistTemplateService from '../../services/CheckListService/ChecklistTemplateService';

const initialState = [];

export const listChecklistTemplates = createAsyncThunk('checklist_template', async (payload) => {
    const res = await ChecklistTemplateService.listChecklistTemplates(payload);
    return res.data;
});

export const listChecklistTemplates1 = createAsyncThunk('checklist_template', async (payload) => {
  const res = await ChecklistTemplateService.listChecklistTemplates1(payload);
  return res.data;
});


export const addChecklistTemplate = createAsyncThunk('checklist/add', async (payload) => {
    const res = await ChecklistTemplateService.addChecklistTemplate(payload);
    return res.data;
});

export const updateChecklistTemplate = createAsyncThunk('checklist/edit', async (payload) => {
    const res = await ChecklistTemplateService.updateChecklistTemplate(payload);
    return res.data;
});

export const getChecklistItems = createAsyncThunk('checklist_item', async (payload) => {
  const res = await ChecklistTemplateService.getChecklistItems(payload);
  return res.data;
});

export const addChecklistItem = createAsyncThunk('checklist_item', async (payload) => {
  const res = await ChecklistTemplateService.addChecklistItem(payload);
  return res.data;
});

export const editCheckListItem = createAsyncThunk('checklist_item/edit', async (payload) => {
  const res = await ChecklistTemplateService.editChecklistItem(payload);
  return res.data;
});

export const deleteCheckListItem = createAsyncThunk('checklist_item/edit', async (payload) => {
  const res = await ChecklistTemplateService.deleteChecklistItem(payload);
  return res.data;
});

export const qrCode = createAsyncThunk('qrCode/get', async (payload) => {
  const res = await ChecklistTemplateService.QRCode(payload);
  return res.data;
});

 const checklistTemplates = createSlice({
    name: 'checklistTemplates',
     initialState,
     extraReducers: {
        [listChecklistTemplates.fulfilled]: (state, action) => {
             if (action.payload.code === '') {
                 return action.payload.data;
            } else {
                 return [];
             }
         },
         [addChecklistTemplate.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },
          [updateChecklistTemplate.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },

     }
 })

const { reducer } = checklistTemplates;
export default reducer;