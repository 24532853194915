import api from '../config/http-common'

const buildQuery = (payload) => {
  let query =
    '?pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize

  return query
}

const getDealerSubscriptions = (companyId) => {
  return api
    .securedAxios()
    .get('/web/api/v4/company/' + companyId + '/subscriptions')
}

const getDealerSubscriptionListById = (companyId) => {
  return api
    .securedAxios()
    .get('/web/api/v4/subscriptions/' + companyId + '/list')
}

const filterDealerSubscriptions = (payload) => {
  return api
    .securedAxios()
    .get(
      '/web/api/v4/dealer/' +
        payload.companyId +
        '/subscriptions/filter' +
        buildQuery(payload),
    )
}

const saveSubscriptionsForDealer = (payload) => {
  return api
    .securedAxios()
    .post('/web/api/v4/company/' + payload.companyId + '/subscription', payload)
}

const updateSubscriptionsForDealer = (payload) => {
  return api
    .securedAxios()
    .put('/web/api/v4/company/' + payload.companyId + '/subscription', payload)
}

const updateSubscriptionForCustomer = (payload) => {
  return api
    .securedAxios()
    .put(
      '/web/api/v4/customer/company/' + payload.companyId + '/subscription',
      payload,
    )
}

const upgradeSubscriptionForDealer = (payload) => {
  return api
    .securedAxios()
    .put(
      '/web/api/v4/company/' + payload.companyId + '/upgrade/subscription',
      payload,
    )
}

const getCustomerSubscription = (payload) => {
  return api.securedAxios().get('/web/api/v4/subscription/' + payload)
}

const SubscriptionDealerService = {
  getDealerSubscriptions,
  getDealerSubscriptionListById,
  filterDealerSubscriptions,
  saveSubscriptionsForDealer,
  updateSubscriptionsForDealer,
  upgradeSubscriptionForDealer,
  updateSubscriptionForCustomer,
  getCustomerSubscription
}

export default SubscriptionDealerService
