import { Typography } from '@material-ui/core';
import { Grid, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import HeaderToolbar from '../../components/HeaderToolbar';
import { getUserDetails } from '../../slices/Dealer/DealerSlice';


function TransactionReportViewPage(props) {
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const dispatch = useDispatch();

    const handleTextOverflow = (text) => {
        if (text && text.length > 20) {
            return text.substring(0, 17) + '...';
        } else {
            return text;
        }
    }

    useEffect(() => {
        loadUserDetails();
    }, []);
    const emailValue = props.location.state.email
    const loadUserDetails = () => {
        dispatch(getUserDetails(emailValue))
            .unwrap()
            .then((data) => {
                if (data.code === "UASI0033") {
                    setFirstName(data.data.firstName)
                    setLastName(data.data.lastName)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };


    return (
        <>
            {props.location.state.type === "PARTNER" ? <HeaderToolbar
                back_button={true}
                title={"View Dealer Transaction Details"}
            /> :
                <HeaderToolbar
                    back_button={true}
                    title={"View Customer Transaction Details"}
                />}

            <Grid container lg={12} md={12} item sx={{ mt: "20px", bgcolor: "white" }} >
                <Grid item padding={2}>
                    <Avatar alt="Remy Sharp" src={props.location.state.imagePreSignedURL ? props.location.state.imagePreSignedURL : ''} style={{ height: "180px", width: "180px", marginLeft: "40px" }} />
                </Grid>
                <Grid item xs zeroMinWidth style={{ marginLeft: "20px", marginTop: "20px", borderRight: "3px solid #E1E9F5" }}  >
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>First Name</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${firstName}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(firstName)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>Last Name</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${lastName}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(lastName)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>Email</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${props.location.state.email}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(props.location.state.email)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>Mobile Number</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${props.location.state.phone}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(props.location.state.phone)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>Company Name</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${props.location.state.name}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(props.location.state.name)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs zeroMinWidth style={{ marginLeft: "20px", marginTop: "20px", borderRight: "3px solid #E1E9F5" }}>
                    {/* <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>Transaction Id</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

                        </Grid>
                    </Grid> */}
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} columnSpacing={1} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>Amount Paid</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} columnSpacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>Billing Cycle </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} columnSpacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>Payment Date</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ alignContent: 'end', alignItems: 'end' }}>

                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default TransactionReportViewPage