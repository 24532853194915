import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './Approvals.css';
import BlockRequestsApprovals from './BlockRequestsApprovals';
import InvitationWalkInRegistrationApprovals from './InvitationWalkInRegistrationApprovals';
import SelfRegistrationApprovals from './SelfRegistrationApprovals';
import WalkInRegistrationApprovals from './WalkInRegistrationApprovals';
import { ThemeContext } from '../../theme/ThemeContext';

function TabPanel(props) {
    const { children, value, index,disabled, ...other } = props;
    return <div {...other}>{value === index && <Box pt={2}>{children}</Box>}</div>;
}

export default function ApprovalTabs(props) {
    const [visitorModes, setVisitorModes] = useState(props.modes || {});
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();
    const [invitationVisitId,setInvitationVisitId] = useState('');
    const [walkInRegistrationVisitId,setWalkInRegistrationVisitId] = useState('');
    const [selfRegistrationVisitId,setSelfRegistrationVisitId] = useState('');
    const { darkMode } = useContext(ThemeContext);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }
    useEffect(() => {
        if (visitorModes.invitation === 'INVITATION' && visitorModes.visitId) {
            setValue(0);
            setInvitationVisitId(visitorModes.visitId);
        } else if (visitorModes.walkInRegistration === 'WALKIN_REGISTARTION' && visitorModes.visitId) {
            setValue(1);
            setWalkInRegistrationVisitId(visitorModes.visitId)
        } else if (visitorModes.selfRegistration === 'SELF_REGISTARTION' && visitorModes.visitId) {
            setValue(2);
            setSelfRegistrationVisitId(visitorModes.visitId)
        } 
    }, [visitorModes])



    return (
        <div>
            <Grid>
                <AppBar position='static' color='transparent'>
                <Tabs
                        TabIndicatorProps={{ style: { background: process.env.REACT_APP_BG_ICON } }}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        style={{ position: 'absolute', top: '135px', display: 'flex', marginLeft: darkMode ? '9px' : '-20px' }}
                    >

                        {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                            <Tab label={t('commonsPathInvitation')} style={{ borderRight: 'solid #00000029', textTransform: "none",backgroundColor: darkMode ? '#0F273B': 'white', color: darkMode ? '#fff': 'black'}} />
                            :
                            <Tab label={t('APPROVALITEMS001')} style={{ borderRight: 'solid #00000029', textTransform: "none",backgroundColor: darkMode ? '#0F273B': 'white', color: darkMode ? '#fff': 'black'}} />
                        }
                        <Tab label={t('WALKINREPORTLBL')} style={{ borderRight: 'solid #00000029', textTransform: "none",backgroundColor: darkMode ? '#0F273B': 'white', color: darkMode ? '#fff': 'black'}} />
                        <Tab label={t('APPROVALITEMS002')} style={{ borderRight: 'solid #00000029', textTransform: "none" ,backgroundColor: darkMode ? '#0F273B': 'white', color: darkMode ? '#fff': 'black'  }} />
                        {process.env.REACT_APP_ENVIRONMENT === 'USA' ? <Tab label={t('APPROVALITEMS004')} style={{ textTransform: "none" ,backgroundColor: darkMode ? '#0F273B': 'white', color: darkMode ? '#fff': 'black'}} /> : <Tab label={t('APPROVALITEMS003')} style={{ textTransform: "none" ,backgroundColor: darkMode ? '#0F273B': 'white', color: darkMode ? '#fff': 'black'}} />}
                    </Tabs>
                </AppBar>
                {value === 0 && (
                    <TabPanel value={value} index={value}>
                        <InvitationWalkInRegistrationApprovals  visitIdForInvitation ={invitationVisitId} />
                    </TabPanel>
                )}
                {value === 1  && (
                    <TabPanel value={value} index={value}>
                        <WalkInRegistrationApprovals visitIdForWalkInRegration ={walkInRegistrationVisitId}/>
                    </TabPanel>
                )}
                {value === 2  && (
                    <TabPanel value={value} index={value}>
                        <SelfRegistrationApprovals visitIdForSelfRegistration ={selfRegistrationVisitId} />
                    </TabPanel>
                )}
                {value === 3 && (
                    <TabPanel value={value} index={value}>
                        <BlockRequestsApprovals />
                    </TabPanel>
                )}
            </Grid>
        </div >
    );
}