
import React, { useState } from "react";
// import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import SubmitButtons from "../DialogBoxComponent/SubmitButtons";
import CancelButtons from "../DialogBoxComponent/CancelButtons";
import { Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { ReactComponent as CloseSvg } from "../../../src/assets/Close.svg";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from '@mui/material/styles';
import { DialogActions } from "@mui/material";
import FilterForm from "./FilterForm";
import { useTranslation } from "react-i18next";
import CustomTextfield from "../CustomInputs/CustomTextfield";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Toast from "../ToastContainer/CustomToast";
import { useDispatch } from "react-redux";
import { updateDeviceType } from "../../slices/DeviceTypeSlice/DeviceTypeSlice";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: "20px",
    minWidth:'600px'
  },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });   

export default function UpdateDeviceType(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const { onSubmit, children, onClose, ...other } = props;

  const [payload ,setPayload] = useState({
    id : props.data.id,
    deviceModel : props.data.deviceModel,
	androidVersion : props.data.androidVersion,
	majorVersion : props.data.majorVersion,
	minorVersion : props.data.minorVersion,
	patchVersion:props.data.patchVersion
  })

  const [error , setError] = useState({
    deviceModel : false,
    androidVersion : false,
    majorVersion : false,
    minorVersion : false,
    patchVersion:false
  })

  const [apkFile , setApkFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const integerRegex = /^-?\d*$/;

    if (integerRegex.test(value)) {
        setPayload({
            ...payload,
            [name]: value,
        });
    }
};

  const handleSubmit = () => {
    if(payload.majorVersion === ''){
        Toast("Please Enter Major Version" , 'error')
        setError({
          ...error,
          majorVersion : true
        })
        return;
    }
    if(payload.minorVersion === ''){
        Toast("Please Enter Minor Version" , 'error')
        setError({
          ...error,
          minorVersion : true
        })
        return;
    }
    if(payload.patchVersion === ''){
        Toast("Please Enter Patch Version" , 'error')
        setError({
          ...error,
          patchVersion : true
        })
        return;
    }
    if(apkFile === null){
        Toast("Please select APK file" ,'error')
        return;
    }
    var formData = new FormData()
     for (const key in payload){
        if (payload[key] != null) {
            formData.append(key, payload[key])
          }
     }

     if(apkFile !== null){
        formData.append('file' , apkFile)
     }

     for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`)
      }

     dispatch(updateDeviceType(formData))
     .unwrap()
     .then((res) => {
        if(res.code === 'MSGS4047' && res.data){
            Toast(res.message , 'success')
            handleClose()
            return;
        }
        else{
            Toast(res.message , 'error')
            return
        }
     })
  }

  const handleSelectFile = (event) => {
    setApkFile(event.target.files[0])
    setSelectedFileName(event.target.files[0] ? event.target.files[0].name : selectedFileName)
  }

  return (
    <>
      <Dialog classes={{ paper: classes.dialogPaper }} {...props}>
        <DialogTitle variant="h4"onClick={handleClose} >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {t("UPDATE_DEVICE_TYPE")}
        </DialogTitle>
        <Divider variant="middle" />
        {/* {props.children} */}

        <Grid container padding={2}>
            <Grid style={{width : "100%"}}>
                <CustomTextfield 
                label = "Device Model"
                value = {payload.deviceModel}
                disabled = {true}
                />
            </Grid>
            <Grid style={{width : "100%"}}>
                <CustomTextfield 
                label = "Android Version"
                value = {payload.androidVersion}
                disabled = {true}
                />
            </Grid>
            <Grid style={{width : "100%"}}>
                <CustomTextfield 
                label = "Major Version"
                name = "majorVersion"
                required = {true}
                value = {payload.majorVersion}
                error = {error.majorVersion}
                inputProps = {{maxLength : 2}}
                 validation = 'numeric'
                handleChange={(e) => handleChange(e)}
                />
            </Grid>
            <Grid style={{width : "100%"}}>
                <CustomTextfield 
                label = "Minor Version"
                name = "minorVersion"
                required = {true}
                value = {payload.minorVersion}
                error = {error.minorVersion}
                inputProps = {{maxLength : 2}}
                 validation = 'numeric'
                handleChange={(e) => handleChange(e)}
                />
            </Grid>
            <Grid style={{width : "100%"}}>
                <CustomTextfield 
                label = "Patch Version"
                name = "patchVersion"
                required = {true}
                value = {payload.patchVersion}
                error = {error.patchVersion}
                inputProps = {{maxLength : 2}}
                validation = 'numeric'
                handleChange={(e) => handleChange(e)}
                />
            </Grid>

            <Grid container style={{marginTop:"10px"}}>
            <Grid>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                style={{backgroundColor : "cadetblue"}}
                >
                Upload file
                <VisuallyHiddenInput type="file" onChange={handleSelectFile}/>
            </Button>
            </Grid>
            <Grid style={{display : "flex",justifyContent : "center" , alignItems:"center",alignContent : "center", marginLeft : "10px"}}>
                {selectedFileName && (
                <Typography variant="body2" style={{fontSize : "large" , fontWeight:"bold"}}>
                 {selectedFileName}
                </Typography>
      )}</Grid>
            </Grid>

        </Grid>

        <DialogActions
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <SubmitButtons onClick={handleSubmit} type='submit'>
            <Typography variant="body1" style={{ textTransform: "none" }}>
              {t("COMMON007")}
            </Typography>
          </SubmitButtons>

          <CancelButtons  onClick={handleClose}>
            <Typography variant="body1" style={{ textTransform: "none" }}>
            {t("COMMON008")}
            </Typography>
          </CancelButtons>
        </DialogActions>
      </Dialog>
    </>
  );
}
