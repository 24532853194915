const EmergencyMessageImg = () => {
  return (
    <svg
      className="MuiSvgIcon-root"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="Path_21171"
        data-name="Path 21171"
        d="M17,8h2a2,2,0,0,1,2,2v6a2,2,0,0,1-2,2H17v4l-4-4H9a1.994,1.994,0,0,1-1.414-.586m0,0L11,14h4a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2H5A2,2,0,0,0,3,6v6a2,2,0,0,0,2,2H7v4Z"
      />
    </svg>
  )
}
export default EmergencyMessageImg
