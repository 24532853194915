import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ZoneService from '../../services/Zone/ZoneService';


const initialState = [];

export const getAllZone = createAsyncThunk('zone/list', async (payload) => {
    const res = await ZoneService.getAllZone(payload);
    return res.data;
});

export const getZoneList = createAsyncThunk('zone', async (payload) => {
    const res = await ZoneService.getZoneList(payload);
    return res.data;
});

export const getZoneById = createAsyncThunk('zone/get', async (payload) => {
    const res = await ZoneService.getZoneById(payload);
    return res.data;
});

export const createZone = createAsyncThunk('zone/create', async (payload) => {
    const res = await ZoneService.createZone(payload);
    return res.data;
});

export const updateZone = createAsyncThunk('zone/update', async (payload) => {
    const res = await ZoneService.updateZone(payload);
    return res.data;
});

export const deleteZone = createAsyncThunk('zone/delete', async (id) => {
    const res = await ZoneService.deleteZone(id);
    return res.data;
});

const ZoneSlice = createSlice({
    name: 'zone',
    initialState,
    extraReducers: {
        
    }
})


const { reducer } = ZoneSlice;
export default reducer;