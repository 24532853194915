import React, { useEffect, useState, useContext } from "react";
import { Typography, Grid, Divider, Checkbox, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import TitleBar from "../../components/v4/TitleBar";
import { ReactComponent as Step1 } from "../../assets/v4/Union228.svg";
import { ReactComponent as Step2 } from "../../assets/v4/Union230.svg";
import { ReactComponent as Step3 } from "../../assets/v4/Union229.svg";
import { useHistory } from "react-router-dom";
import { REACT_ADD_DEVICE } from "../../actions/EndPoints";
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
import { ThemeContext } from '../../theme/ThemeContext';

export default function ZlinkAddDeviceInfo(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [isRead, setRead] = useState(false);
  const { darkMode } = useContext(ThemeContext);
  const handleSubmit = () => {
    history.push(REACT_ADD_DEVICE);
  };
  
  return (
    <>
      <TitleBar title={t("Add Device")} back_button={true} />
      <Grid
        container
        style={{
          backgroundColor: darkMode ? "#0F273B" : "#FFFF",
          padding: "2rem",
          borderRadius: "8px",
          marginLeft: darkMode ? '30px' : '',
          width: darkMode ? '97%' : ''
        }}
      >
        <Grid style={{ width: "30%" }}>
          <Typography variant="title" gutterBottom component="div"  style={{color:darkMode ? '#fff':'#000', fontWeight:'500', fontSize:'16px'}}>
            {t("Step1")}  {t("TurnontheDevice")}
          </Typography><br/>
          <Typography variant="fieldLabel" style={{color:darkMode?'#DDDDDD':'#707070', opacity:'0.8'}}>
          {t("DEVICEINFOO3")}
          </Typography>
          <br />
          <Step1
            style={{ alignSelf: "center", width: "100%", padding: "1rem 0rem" }}
          />
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: "0px 1rem" }}
        />

        <Grid style={{ width: "30%" }}>
          <Typography variant="title" gutterBottom component="div"  style={{color:darkMode ? '#fff':'#000', fontWeight:'500', fontSize:'16px'}}>
          {t("Step2")} {t("ConfigureTheNetwork")}
          </Typography><br/>
          <Typography variant="fieldLabel" style={{color:darkMode?'#DDDDDD':'#707070', opacity:'0.8'}}>
              {t("DEVICEINFOO4")}
          </Typography>
          <Step2
            style={{ alignSelf: "center", width: "100%", padding: "1rem 0rem" }}
          />
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: "0px 1rem" }}
        />

        <Grid style={{ width: "30%" }}>
          <Typography variant="title" gutterBottom component="div" style={{color:darkMode ? '#fff':'#000', fontWeight:'500', fontSize:'16px'}}>
          {t("Step3")} {t("DEVICEINFOO5")}
          </Typography> <br/>
          <Typography variant="fieldLabel" style={{color:darkMode?'#DDDDDD':'#707070', opacity:'0.8'}}>
            {t("DEVICEINFOO6")} {t("DEVICEINFOO7")}
          </Typography>
          <Step3
            style={{ alignSelf: "center", width: "100%", padding: "1rem 0rem" }}
          />
        </Grid>
      </Grid>
      <Grid
        style={{
          fontFamily: "Roboto",
          color: "#304669",
          cursor: "default",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "2rem 0.4rem",
        }}
      >
        <Grid container style={{ alignSelf: "center", marginLeft: darkMode ? '28px' : '' }}>
          <Checkbox 
            onChange={(e) => setRead(e.target.checked)}
            style={{color: process.env.REACT_APP_BG_ICON,  padding: "0px", fontSize: "16px" }}
          />&nbsp;
          <Typography style={{margin:'4px', color: darkMode ? '#fff' : ''}}>
            {t("DEVICEINFOO8")}
          </Typography>
        </Grid>
        <Grid style={{marginRight: darkMode ? '11px' : ''}}>
          <SubmitButtons disabled={!isRead} onClick={() => handleSubmit()}>
            {t("COMMON007")}
          </SubmitButtons>
        </Grid>
      </Grid>
    </>
  );
}
