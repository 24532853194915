import { createAsyncThunk } from '@reduxjs/toolkit'
import discountService from '../../services/Discount/DiscountService'

const initialState = []

export const createDiscount = createAsyncThunk(
  'discount/createDiscount',
  async (payload) => {
    const res = await discountService.createDiscount(payload)
    return res.data
  },
)

export const updateDiscount = createAsyncThunk(
  'discounts/updateDiscount',
  async (payload) => {
    const res = await discountService.updateDiscount(payload)
    return res.data
  },
)

export const getDiscountList = createAsyncThunk(
  'discounts/getDiscountList',
  async (payload) => {
    const res = await discountService.getDiscountList(payload)
    return res.data
  },
)

export const getDiscount = createAsyncThunk(
  'discounts/getDiscount',
  async (payload) => {
    const res = await discountService.getDiscount(payload)
    return res.data
  },
)
