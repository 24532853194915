import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter, page, rowsPerPage) => {
  let query =
    "?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=false&operator=AND&pageNumber=" +
    page +
    "&pageSize=" +
    rowsPerPage;

  if (!CommonUtil.isEmptyString(filter.code)) {
    query = query + "&code=" + filter.code;
  }
  if (!CommonUtil.isEmptyString(filter.name)) {
    query = query + "&name=" + filter.name;
  }
  return query;
};

const getSiteTypeList = (payload) => {
  return api
    .securedAxios()
    .get(
      "/web/site_type" +
        buildQuery(payload.filter, payload.page, payload.rowsPerPage)
    );
};

const createSiteType = (payload) => {
  return api.securedAxios().post("/web/site_type", payload);
};

const editSiteType = (payload) => {
   return api.securedAxios().put("/web/site_type/" + payload.id, payload);
};

const removeSiteType = (idsOrCodes) => {
  return api.securedAxios().delete("/web/site_type/" + idsOrCodes);
  
}

const SiteTypeService = {
  getSiteTypeList,
  createSiteType,
  editSiteType,
  removeSiteType,
};

export default SiteTypeService;
