const countryData = [
    {
      id: '1',
      name: 'India',
      value: '91',
    },
    {
      id: '2',
      name: 'United States',
      value: '1',
    },
    {
      id: '3',
      name: 'China',
      value: '86',
    },
    {
      id: '4',
      name: 'Bangladesh',
      value: '880',
    },
    {
      id: '5',
      name: 'South Africa',
      value: '27',
    },
    {
      id: '6',
      name: 'Indonesia',
      value: '62',
    },
    {
      id: '7',
      name: 'Australia',
      value: '61',
    },
    {
      id: '8',
      name: 'Brazil',
      value: '55',
    },
    {
      id: '9',
      name: 'France',
      value: '33',
    },
    {
      id: '10',
      name: 'Germany',
      value: '49',
    },
    {
      id: '11',
      name: 'Italy',
      value: '39',
    },
    {
      id: '12',
      name: 'Japan',
      value: '81',
    },
  ];
  
  const stateData = [
    {
      id: '1',
      name: 'Karnataka',
    },
  ];
  
  const cityData = [
    {
      id: '1',
      name: 'Bengaluru',
    },
    {
      id: '2',
      name: 'Mysore',
    },
  ];
  
  const digit = [
    {
      id: '1',
      name: '4 Digit',
    },
    {
      id: '2',
      name: '6 Digit',
    },
    {
      id: '3',
      name: '8 Digit',
    },
  ];
  
  const timeZoneData = [
    {
      id: '1',
      name: '(GMT-12:00) International Date Line West',
    },
    {
      id: '2',
      name: '(GMT-11:00) Midway Island, Samoa',
    },
    {
      id: '3',
      name: '(GMT-10:00) Hawaii',
    },
    {
      id: '4',
      name: '(GMT-09:00) Alaska',
    },
    {
      id: '5',
      name: '(GMT-08:00) Pacific Time (US & Canada)',
    },
    {
      id: '6',
      name: '(GMT-08:00) Tijuana, Baja California',
    },
    {
      id: '7',
      name: '(GMT-07:00) Arizona',
    },
    {
      id: '8',
      name: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
    },
    {
      id: '9',
      name: '(GMT-07:00) Mountain Time (US & Canada)',
    },
    {
      id: '10',
      name: '(GMT-06:00) Central America',
    },
    {
      id: '11',
      name: '(GMT-06:00) Central Time (US & Canada)',
    },
    {
      id: '12',
      name: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
    },
    {
      id: '13',
      name: '(GMT-06:00) Saskatchewan',
    },
    {
      id: '14',
      name: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
    },
    {
      id: '15',
      name: '(GMT-05:00) Eastern Time (US & Canada)',
    },
    {
      id: '16',
      name: '(GMT-05:00) Indiana (East)',
    },
    {
      id: '17',
      name: '(GMT-04:00) Atlantic Time (Canada)',
    },
    {
      id: '18',
      name: '(GMT-04:00) Caracas, La Paz',
    },
    {
      id: '19',
      name: '(GMT-04:00) Manaus',
    },
    {
      id: '20',
      name: '(GMT-04:00) Santiago',
    },
    {
      id: '21',
      name: '(GMT-03:30) Newfoundland',
    },
    {
      id: '22',
      name: '(GMT-03:00) Brasilia',
    },
    {
      id: '23',
      name: '(GMT-03:00) Buenos Aires, Georgetown',
    },
    {
      id: '24',
      name: '(GMT-03:00) Greenland',
    },
    {
      id: '25',
      name: '(GMT-03:00) Montevideo',
    },
    {
      id: '26',
      name: '(GMT-02:00) Mid-Atlantic',
    },
    {
      id: '27',
      name: '(GMT-01:00) Cape Verde Is.',
    },
    {
      id: '28',
      name: '(GMT-01:00) Azores',
    },
    {
      id: '29',
      name: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
    },
    {
      id: '30',
      name: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
    },
    {
      id: '31',
      name: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    },
    {
      id: '32',
      name: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    },
    {
      id: '33',
      name: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
    },
    {
      id: '34',
      name: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    },
    {
      id: '35',
      name: '(GMT+01:00) West Central Africa',
    },
    {
      id: '36',
      name: '(GMT+02:00) Amman',
    },
    {
      id: '37',
      name: '(GMT+02:00) Athens, Bucharest, Istanbul',
    },
    {
      id: '38',
      name: '(GMT+02:00) Beirut',
    },
    {
      id: '39',
      name: '(GMT+02:00) Cairo',
    },
    {
      id: '40',
      name: '(GMT+02:00) Harare, Pretoria',
    },
    {
      id: '41',
      name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    },
    {
      id: '42',
      name: '(GMT+02:00) Jerusalem',
    },
    {
      id: '43',
      name: '(GMT+02:00) Minsk',
    },
    {
      id: '44',
      name: '(GMT+02:00) Windhoek',
    },
    {
      id: '45',
      name: '(GMT+03:00) Kuwait, Riyadh, Baghdad',
    },
    {
      id: '46',
      name: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    },
    {
      id: '47',
      name: '(GMT+03:00) Nairobi',
    },
    {
      id: '48',
      name: '(GMT+03:00) Tbilisi',
    },
    {
      id: '49',
      name: '(GMT+03:30) Tehran',
    },
    {
      id: '50',
      name: '(GMT+04:00) Abu Dhabi, Muscat',
    },
    {
      id: '51',
      name: '(GMT+04:00) Baku',
    },
    {
      id: '52',
      name: '(GMT+04:00) Yerevan',
    },
    {
      id: '53',
      name: '(GMT+04:30) Kabul',
    },
    {
      id: '54',
      name: '(GMT+05:00) Yekaterinburg',
    },
    {
      id: '55',
      name: '(GMT+05:00) Islamabad, Karachi, Tashkent',
    },
    {
      id: '56',
      name: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    },
    {
      id: '57',
      name: '(GMT+05:30) Sri Jayawardenapura',
    },
    {
      id: '58',
      name: '(GMT+05:45) Kathmandu',
    },
    {
      id: '59',
      name: '(GMT+06:00) Almaty, Novosibirsk',
    },
    {
      id: '60',
      name: '(GMT+06:00) Astana, Dhaka',
    },
    {
      id: '61',
      name: '(GMT+06:30) Yangon (Rangoon)',
    },
    {
      id: '62',
      name: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
    },
    {
      id: '63',
      name: '(GMT+07:00) Krasnoyarsk',
    },
    {
      id: '64',
      name: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    },
    {
      id: '65',
      name: '(GMT+08:00) Kuala Lumpur, Singapore',
    },
    {
      id: '66',
      name: '(GMT+08:00) Irkutsk, Ulaan Bataar',
    },
    {
      id: '67',
      name: '(GMT+08:00) Perth',
    },
    {
      id: '68',
      name: '(GMT+08:00) Taipei',
    },
    {
      id: '69',
      name: '(GMT+09:00) Osaka, Sapporo, Tokyo',
    },
    {
      id: '70',
      name: '(GMT+09:00) Seoul',
    },
    {
      id: '71',
      name: '(GMT+09:00) Yakutsk',
    },
    {
      id: '72',
      name: '(GMT+09:30) Adelaide',
    },
    {
      id: '73',
      name: '(GMT+09:30) Darwin',
    },
    {
      id: '74',
      name: '(GMT+10:00) Brisbane',
    },
    {
      id: '75',
      name: '(GMT+10:00) Canberra, Melbourne, Sydney',
    },
    {
      id: '76',
      name: '(GMT+10:00) Hobart',
    },
    {
      id: '77',
      name: '(GMT+10:00) Guam, Port Moresby',
    },
    {
      id: '78',
      name: '(GMT+10:00) Vladivostok',
    },
    {
      id: '79',
      name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
    },
    {
      id: '80',
      name: '(GMT+12:00) Auckland, Wellington',
    },
    {
      id: '81',
      name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
    },
    {
      id: '82',
      name: "(GMT+13:00) Nuku'alofa",
    },
  ];
  
  
  const gender = [
    {
        id: "M",
        name: "Male",
        value: "M"
    },
    {
        id: 'F',
        name: 'Female',
        value: "F"
    },
    {
        id: 'O',
        name: 'Other',
        value: "O"
    }
  ]
  export { countryData, stateData, cityData, timeZoneData, gender, digit };
  