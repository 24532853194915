import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PerpouseTypeAccelevelUSAService from '../../services/PurposeTypeUSA/PerpouseTypeAccelevelUSAService';


const initialState = [];

export const listcloudAccess_Levels = createAsyncThunk('visitor_type/access_level', async (payload) => {
  const res = await PerpouseTypeAccelevelUSAService.listcloudAccess_Levels(payload);
  return res.data;

});

export const listAccessLevelsPurposeType = createAsyncThunk('accesslevel', async (payload) => {
  const res = await PerpouseTypeAccelevelUSAService.getAccessLevel(payload);
  return res.data;

});

export const listAccessLevels = createAsyncThunk('accesslevel/list', async (payload) => {
  const res = await PerpouseTypeAccelevelUSAService.getAccessLevelList(payload);
  return res.data;

});


export const deleteAccessLevel = createAsyncThunk('accesslevel', async (payload) => {
  const res = await PerpouseTypeAccelevelUSAService.deleteAccessLevel(payload);
  return res.data;

});


export const addAccessLevel = createAsyncThunk('accesslevel', async (payload) => {
  const res = await PerpouseTypeAccelevelUSAService.addAccessLevel(payload);
  return res.data;

});



const PerpouseTypeAccessUSASlice = createSlice({
  name: 'company',
  initialState,
  extraReducers: {
    [listAccessLevelsPurposeType.fulfilled]: (state, action) => {
      // if (action.payload.code === 'UASI0000' && !CommonUtil.isEmpty(action.payload.data)) {
      //   return action.payload.data.company;
      // } else {
      //   return [];
      // }
    },
  },
});

const { reducer } = PerpouseTypeAccessUSASlice;
export default reducer;

