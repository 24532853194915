import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper' : {
        borderRadius : "50%",
      },
      '& .css-uhb5lp' : {
        borderRadius : "50%"
      }
    },
  }));


function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, open } = props;
    return (
        <Dialog
        className={classes.dialogPaper}
         onClose={onClose} open={open}>
            {props.data}
        </Dialog>
    );
}


export default function SimpleDialogDemo(props) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <div>
            <div  onClick={handleClickOpen}>
                {props.button}
            </div>
            <SimpleDialog
                data={props.child}
                open={open}
                onClose={handleClose}
            />
        </div>
    );
}
