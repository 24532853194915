const countriesLists = [
    {
        id: '1',
        name: 'Afghanistan',
        value: '93',
    },
    {
        id: '2',
        name: 'Albania',
        value: '95',
    },
    {
        id: '4',
        name: 'Algeria',
        value: '86',
    },
    {
        id: '6',
        name: 'Andorra',
        value: '88',
    },
    {
        id: '7',
        name: 'Angola',
        value: '89',
    },
    {
        id: '8',
        name: 'Anguilla',
        value: '90',
    },
    {
        id: '9',
        name: 'Antarctica',
        value: '91',
    },
    {
        id: '11',
        name: 'Argentina',
        value: '93',
    },
    {
        id: '12',
        name: 'Armenia',
        value: '94',
    },
    {
        id: '13',
        name: 'Aruba',
        value: '95',
    },
    {
        id: '14',
        name: 'Australia',
        value: '96',
    },
    {
        id: '15',
        name: 'Austria',
        value: '97',
    },
    {
        id: '16',
        name: 'Azerbaijan',
        value: '98',
    },
    {
        id: '17',
        name: 'Bahamas',
        value: '99',
    },
    {
        id: '18',
        name: 'Bahrain',
        value: '100',
    },
    {
        id: '19',
        name: 'Bangladesh',
        value: '101',
    },
    {
        id: '20',
        name: 'Barbados',
        value: '102',
    },
    {
        id: '21',
        name: 'Belarus',
        value: '103',
    },
    {
        id: '22',
        name: 'Belgium',
        value: '104',
    },
    {
        id: '23',
        name: 'Belize',
        value: '105',
    },
    {
        id: '24',
        name: 'Benin',
        value: '106',
    },
    {
        id: '25',
        name: 'Bermuda',
        value: '107',
    },
    {
        id: '26',
        name: 'Bhutan',
        value: '108',
    },
    {
        id: '29',
        name: 'Botswana',
        value: '111',
    },
    {
        id: '30',
        name: 'Brazil',
        value: '112',
    },
    {
        id: '33',
        name: 'Bulgaria',
        value: '115',
    },
    {
        id: '35',
        name: 'Burundi',
        value: '117',
    },
    {
        id: '36',
        name: 'Cambodia',
        value: '118',
    },
    {
        id: '37',
        name: 'Cameroon',
        value: '119',
    },
    {
        id: '38',
        name: 'Canada',
        value: '120',
    },
    {
        id: '39',
        name: 'Cape Verde',
        value: '121',
    },
    {
        id: '42',
        name: 'Chad',
        value: '125',
    },
    {
        id: '43',
        name: 'Chile',
        value: '126',
    },
    {
        id: '44',
        name: 'China',
        value: '127',
    },
    {
        id: '47',
        name: 'Colombia',
        value: '130',
    },
    {
        id: '48',
        name: 'Comoros',
        value: '131',
    },
    {
        id: '49',
        name: 'Congo',
        value: '132',
    },
    {
        id: '52',
        name: 'Costa Rica',
        value: '135',
    },
    {
        id: '54',
        name: 'Croatia',
        value: '137',
    },
    {
        id: '55',
        name: 'Cuba',
        value: '138',
    },
    {
        id: '56',
        name: 'Cyprus',
        value: '139',
    },
    {
        id: '58',
        name: 'Denmark',
        value: '141',
    },
    {
        id: '59',
        name: 'Djibouti',
        value: '142',
    },
    {
        id: '60',
        name: 'Dominica',
        value: '143',
    },
    {
        id: '62',
        name: 'Ecuador',
        value: '145',
    },
    {
        id: '63',
        name: 'Egypt',
        value: '146',
    },
    {
        id: '66',
        name: 'Eritrea',
        value: '149',
    },
    {
        id: '67',
        name: 'Estonia',
        value: '150',
    },
    {
        id: '68',
        name: 'Ethiopia',
        value: '151',
    },
    {
        id: '71',
        name: 'Fiji',
        value: '154',
    },
    {
        id: '72',
        name: 'Finland',
        value: '155',
    },
    {
        id: '73',
        name: 'France',
        value: '156',
    },
    {
        id: '77',
        name: 'Gabon',
        value: '160',
    },
    {
        id: '78',
        name: 'Gambia',
        value: '161',
    },
    {
        id: '79',
        name: 'Georgia',
        value: '162',
    },
    {
        id: '80',
        name: 'Germany',
        value: '163',
    },
    {
        id: '81',
        name: 'Ghana',
        value: '164',
    },
    {
        id: '82',
        name: 'Gibraltar',
        value: '165',
    },
    {
        id: '83',
        name: 'Greece',
        value: '166',
    },
    {
        id: '84',
        name: 'Greenland',
        value: '167',
    },
    {
        id: '85',
        name: 'Grenada',
        value: '168',
    },
    {
        id: '86',
        name: 'Guadeloupe',
        value: '169',
    },
    {
        id: '87',
        name: 'Guam',
        value: '170',
    },
    {
        id: '88',
        name: 'Guatemala',
        value: '171',
    },
    {
        id: '89',
        name: 'Guernsey',
        value: '172',
    },
    {
        id: '90',
        name: 'Guinea',
        value: '173',
    },
    {
        id: '92',
        name: 'Guyana',
        value: '175',
    },
    {
        id: '93',
        name: 'Haiti',
        value: '176',
    },
    {
        id: '95',
        name: 'Vatican City',
        value: '178',
    },
    {
        id: '96',
        name: 'Honduras',
        value: '179',
    },
    {
        id: '97',
        name: 'Hong Kong',
        value: '180',
    },
    {
        id: '98',
        name: 'Hungary',
        value: '181',
    },
    {
        id: '99',
        name: 'Iceland',
        value: '182',
    },
    {
        id: '100',
        name: 'India',
        value: '183',
    },
    {
        id: '101',
        name: 'Indonesia',
        value: '184',
    },
    {
        id: '103',
        name: 'Iraq',
        value: '186',
    },
    {
        id: '104',
        name: 'Ireland',
        value: '187',
    },
    {
        id: '106',
        name: 'Israel',
        value: '189',
    },
    {
        id: '107',
        name: 'Italy',
        value: '190',
    },
    {
        id: '108',
        name: 'Jamaica',
        value: '191',
    },
    {
        id: '109',
        name: 'Japan',
        value: '192',
    },
    {
        id: '110',
        name: 'Jersey',
        value: '193',
    },
    {
        id: '111',
        name: 'Jordan',
        value: '194',
    },
    {
        id: '112',
        name: 'Kazakhstan',
        value: '195',
    },
    {
        id: '113',
        name: 'Kenya',
        value: '196',
    },
    {
        id: '116',
        name: 'Korea',
        value: '199',
    },
    {
        id: '117',
        name: 'Kyrgyzstan',
        value: '200',
    },
    {
        id: '119',
        name: 'Latvia',
        value: '202',
    },
    {
        id: '120',
        name: 'Lebanon',
        value: '203',
    },
    {
        id: '122',
        name: 'Liberia',
        value: '205',
    },
    {
        id: '123',
        name: 'Libya',
        value: '206',
    },
    {
        id: '124',
        name: 'Liechtenstein',
        value: '207',
    },
    {
        id: '125',
        name: 'Lithuania',
        value: '208',
    },
    {
        id: '126',
        name: 'Luxembourg',
        value: '209',
    },
    {
        id: '127',
        name: 'Macao',
        value: '210',
    },
    {
        id: '129',
        name: 'Madagascar',
        value: '212',
    },
    {
        id: '130',
        name: 'Malawi',
        value: '213',
    },
    {
        id: '131',
        name: 'Malaysia',
        value: '214',
    },
    {
        id: '132',
        name: 'Maldives',
        value: '215',
    },
    {
        id: '133',
        name: 'Mali',
        value: '216',
    },
    {
        id: '138',
        name: 'Mauritius',
        value: '221',
    },
    {
        id: '139',
        name: 'Mayotte',
        value: '222',
    },
    {
        id: '140',
        name: 'Mexico',
        value: '223',
    },
    {
        id: '143',
        name: 'Monaco',
        value: '226',
    },
    {
        id: '144',
        name: 'Mongolia',
        value: '227',
    },
    {
        id: '145',
        name: 'Montenegro',
        value: '228',
    },
    {
        id: '146',
        name: 'Montserrat',
        value: '229',
    },
    {
        id: '147',
        name: 'Morocco',
        value: '230',
    },
    {
        id: '148',
        name: 'Mozambique',
        value: '231',
    },
    {
        id: '149',
        name: 'Myanmar',
        value: '232',
    },
    {
        id: '150',
        name: 'Namibia',
        value: '233',
    },
    {
        id: '151',
        name: 'Nauru',
        value: '234',
    },
    {
        id: '152',
        name: 'Nepal',
        value: '235',
    },
    {
        id: '153',
        name: 'Netherlands',
        value: '236',
    },
    {
        id: '156',
        name: 'New Zealand',
        value: '239',
    },
    {
        id: '157',
        name: 'Nicaragua',
        value: '240',
    },
    {
        id: '159',
        name: 'Nigeria',
        value: '242',
    },
    {
        id: '160',
        name: 'Niue',
        value: '243',
    },
    {
        id: '163',
        name: 'Norway',
        value: '246',
    },
    {
        id: '164',
        name: 'Oman',
        value: '247',
    },
    {
        id: '165',
        name: 'Pakistan',
        value: '248',
    },
    {
        id: '166',
        name: 'Palau',
        value: '249',
    },
    {
        id: '167',
        name: 'Palestine',
        value: '250',
    },
    {
        id: '168',
        name: 'Panama',
        value: '251',
    },
    {
        id: '170',
        name: 'Paraguay',
        value: '253',
    },
    {
        id: '171',
        name: 'Peru',
        value: '254',
    },
    {
        id: '172',
        name: 'Philippines',
        value: '255',
    },
    {
        id: '173',
        name: 'Pitcairn',
        value: '256',
    },
    {
        id: '174',
        name: 'Poland',
        value: '257',
    },
    {
        id: '175',
        name: 'Portugal',
        value: '258',
    },
    {
        id: '177',
        name: 'Qatar',
        value: '260',
    },
    {
        id: '179',
        name: 'Romania',
        value: '262',
    },
    {
        id: '181',
        name: 'Rwanda',
        value: '264',
    },
    {
        id: '189',
        name: 'Samoa',
        value: '272',
    },
    {
        id: '190',
        name: 'San Marino',
        value: '273',
    },
    {
        id: '192',
        name: 'Saudi Arabia',
        value: '275',
    },
    {
        id: '193',
        name: 'Senegal',
        value: '276',
    },
    {
        id: '194',
        name: 'Serbia',
        value: '277',
    },
    {
        id: '197',
        name: 'Singapore',
        value: '280',
    },
    {
        id: '198',
        name: 'Slovakia',
        value: '281',
    },
    {
        id: '199',
        name: 'Slovenia',
        value: '282',
    },
    {
        id: '201',
        name: 'Somalia',
        value: '284',
    },
    {
        id: '202',
        name: 'South Africa',
        value: '285',
    },
    {
        id: '204',
        name: 'South Sudan',
        value: '287',
    },
    {
        id: '205',
        name: 'Spain',
        value: '288',
    },
    {
        id: '206',
        name: 'Sri Lanka',
        value: '289',
    },
    {
        id: '207',
        name: 'Sudan',
        value: '290',
    },
    {
        id: '208',
        name: 'Suriname',
        value: '291',
    },
    {
        id: '210',
        name: 'Swaziland',
        value: '293',
    },
    {
        id: '211',
        name: 'Sweden',
        value: '294',
    },
    {
        id: '212',
        name: 'Switzerland',
        value: '295',
    },
    {
        id: '214',
        name: 'Taiwan',
        value: '297',
    },
    {
        id: '215',
        name: 'Tajikistan',
        value: '298',
    },
    {
        id: '217',
        name: 'Thailand',
        value: '300',
    },
    {
        id: '219',
        name: 'Togo',
        value: '302',
    },
    {
        id: '221',
        name: 'Tonga',
        value: '304',
    },
    {
        id: '224',
        name: 'Turkey',
        value: '307',
    },
    {
        id: '225',
        name: 'Turkmenistan',
        value: '308',
    },
    {
        id: '227',
        name: 'Tuvalu',
        value: '310',
    },
    {
        id: '228',
        name: 'Uganda',
        value: '311',
    },
    {
        id: '229',
        name: 'Ukraine',
        value: '312',
    },
    {
        id: '230',
        name: 'United Arab Emirates',
        value: '313',
    },
    {
        id: '231',
        name: 'United Kingdom',
        value: '314',
    },
    {
        id: '232',
        name: 'United States',
        value: '315',
    },
    {
        id: '234',
        name: 'Uruguay',
        value: '317',
    },
    {
        id: '235',
        name: 'Uzbekistan',
        value: '318',
    },
    {
        id: '236',
        name: 'Vanuatu',
        value: '319',
    },
    {
        id: '238',
        name: 'Vietnam',
        value: '321',
    },
    {
        id: '243',
        name: 'Yemen',
        value: '967',
    },
    {
        id: '244',
        name: 'Zambia',
        value: '260',
    },
    {
        id: '245',
        name: 'Zimbabwe',
        value: '263',
    },
    {
        id: '252',
        name: 'Wales',
        value: '501',
    },
  ];
  export const countriesList =countriesLists;