import api from '../../config/http-common'

const createSetting = (payload) => {
  return api.securedAxios().post('/web/createSettings', payload)
}
const updateSettings = (payload) => {
  return api.securedAxios().put('/web/updateSettings/' + payload.id, payload)
}
const getListSetting = (payload) => {
  return api.securedAxios().get('/web/getSettings', payload)
}

const getListSettingById = (id) => {
  return api.unsecuredAxios().get('/web/getSettingsById/' + id)
}

const getVersion = () => {
  return api.securedAxios().get('/web/version')
}


const additionalSettingsService = {
  createSetting,
  updateSettings,
  getListSetting,
  getListSettingById,
  getVersion
}

export default additionalSettingsService
