import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import '../../../src/components/VisitorActionsComponents/profileCard.css'
import { Divider, Grid } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getSiteById } from '../../slices/Site/SiteSlice';
import { useDispatch } from "react-redux";
import CommonUtil from '../../Util/CommonUtils';
import { Tooltip } from '@material-ui/core';
import ViewIcon from '../../Pages/Invitations/ViewIcon';
import moment from 'moment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        overflowY: 'clip',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        overflowY: 'visible',
        borderRadius: '20px',
        minWidth: '1000px',
        // height: '584px',
        opacity: 1,
    },
    '& .MuiDialogContent-root ': {
        padding: '16px 24px',
        position: ' relative',
        bottom: '90px',
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CheckedOutViewPage(props) {
    const [open, setOpen] = React.useState(false);
    const [siteName, setSiteName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [checkIn, setCheckIn] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [duration, setDuration] = useState('');

    const [endDate, setEndDate] = useState('');
    const rowDataMode = props.rowData.registrationMode

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        loadSiteName(props.rowData.siteId);
        setEndDate(moment(CommonUtil.getLocalDate(props.rowData.scheduledEndDate)).format("DD-MM-YY kk:mm"));
        setStartDate(moment(CommonUtil.getLocalDate(props.rowData.scheduledStartDate)).format("DD-MM-YY kk:mm"))
        setCheckIn(moment(CommonUtil.getLocalDate(props.rowData.checkIn)).format("DD-MM-YY kk:mm"))
        setCheckOut(moment(CommonUtil.getLocalDate(props.rowData.checkOut)).format("DD-MM-YY kk:mm"))

        setTotalDuration(props.rowData)

    }, [])

    const loadSiteName = (siteId) => {
        dispatch(getSiteById(siteId))
            .unwrap().then((data) => {
                if (data.code === 'CVAI0000') {
                    console.log(JSON.stringify(data))
                    setSiteName(data.data.name);
                }
                else {
                    setSiteName('');
                }
            })
    };


    const setTotalDuration = (params) => {
        let start = moment(params.checkIn);
        let end = moment(params.checkOut);
        let duration = moment.duration(end.diff(start));
        setDuration(Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(":mm:ss"))
    }

    return (
        <div >
            <div onClick={handleClickOpen} >
                <ViewIcon />

            </div>
            <Grid >
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle onClose={handleClose} >
                        <Avatar alt={props.rowData.visitorId.firstName} src={`data:image/png;base64,${props.rowData.visitorId.profile}`} sx={{ width: 150, height: 150, bottom: '80px', left: '421px', border: 'solid #FFFF', borderWidth: '7px', }} />
                        <div className='CssTextProfileViewPageName'>
                            {props.rowData.visitorId.firstName + ' ' + props.rowData.visitorId.lastName}
                        </div>
                        <div className='CssTextProfileViewPagePhone'>
                            {props.rowData.visitorId.phoneNumber}
                        </div>
                    </BootstrapDialogTitle>
                    <Divider variant="inset" className='MuiDividerCss' />
                    <DialogContent style={{ marginLeft: '70px', marginRight: '70px' }}>
                        < Grid container spacing={12.25} className='CssTextProfileViewPageContent' marginTop={0.5}>
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3} >Email</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'> {props.rowData.visitorId.emailId}</Grid>
                            </Grid>
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3}>Host</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{props.rowData.hostName}</Grid>
                            </Grid>
                            <Grid container spacing={2} p={1.25} >
                                <Grid item xs={3}>Purpose</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{props.rowData.visitTypeDO.name}</Grid>
                            </Grid>
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3}>Site</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{siteName}</Grid>
                            </Grid>
                            <Grid
                                container spacing={2} p={1.25}>
                                <Grid item xs={3}>Company Name</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{props.rowData.visitorId.visitorCompanyName}</Grid>
                            </Grid>
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3} >Invite Date</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {startDate}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3} >Check In / Check Out</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {checkIn + " / " + checkOut}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3} >Total Duration</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {duration}
                                </Grid>
                            </Grid>


                        {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3}>Type</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{
                                    props.rowData.registrationMode === 'ONDEMAND' ? 'Walk-In Registration' :
                                        props.rowData.registrationMode === "INVITATION" ? "Invitation" :
                                            props.rowData.registrationMode === "SELF_REGISTARTION" ? "Self Registration" :
                                                props.rowData.registrationMode === "ONDEMAND_KIOSK" ? "Walkin Registration" :
                                                    props.rowData.registrationMode}</Grid>
                            </Grid>
                        :
                        <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3}>Type</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{
                                    props.rowData.registrationMode === 'ONDEMAND' ? 'Registration' :
                                        props.rowData.registrationMode === "INVITATION" ? "Invitation" :
                                            props.rowData.registrationMode === "SELF_REGISTARTION" ? "Self Registration" :
                                                props.rowData.registrationMode === "ONDEMAND_KIOSK" ? "Walkin Registration" :
                                                    props.rowData.registrationMode}</Grid>
                            </Grid>
                        }    


                            <Grid
                                container spacing={2} p={1.25}>
                                <Grid item xs={3}>Verify Type In / Out</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {props.rowData.verifyTypeIn ? props.rowData.verifyTypeIn + " / ":"  "}    { props.rowData.verifyTypeOut?props.rowData.verifyTypeOut:" "}</Grid>
                            </Grid>
                            <Grid
                                container spacing={2} p={1.25}>
                                <Grid item xs={3}>Status</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {props.rowData.status === "WAIT_FOR_VISITOR" ? "Scheduled"
                                        : props.rowData.status === "CANCELLED" ? "Cancelled"
                                            : props.rowData.status === "REJECTED" ? "Rejected"
                                                : props.rowData.status === "CONFIRMED" ? "Confirmed"
                                                    : props.rowData.status === "CHECKED_IN" ? "Check In"
                                                        : props.rowData.status === "CHECKED_OUT" ? "Check Out"
                                                            : props.rowData.status === "APPROVED" ? "Approved"
                                                                : props.rowData.status}</Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </BootstrapDialog>
            </Grid>
        </div>
    );
}

export default CheckedOutViewPage