import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Sidebar, Header } from '../../layout-components';
import RefreshToken from '../../Pages/RefreshToken/RefreshToken';
import ProfileContext from '../../components/ProfileContext';
import { ThemeContext } from '../../theme/ThemeContext'; 

const LeftSidebar = (props) => {
  const { children, sidebarToggle, sidebarFixed, contentBackground } = props;
  const [open, setOpen] = React.useState(true);
  const [userProfilePic, setUserProfilePic] = useState('');
  const [userProfile, setUserProfile] = useState({});
  const [companyProfilePic, setCompanyProfilePic] = useState('')
  const [companyProfile, setCompanyProfile] = useState('')
  const [visitorStatus, setVisitorStatus] = useState('')
  const [currentTotalVisitCount, setCurrentTotalVisitCount] = useState('')
  const [resendTimer , setResendTimer] = useState(false)
  const value = { userProfilePic, companyProfilePic, userProfile, companyProfile, visitorStatus,currentTotalVisitCount, resendTimer , setResendTimer , setVisitorStatus, setUserProfile, setUserProfilePic, setCompanyProfilePic, setCompanyProfile,setCurrentTotalVisitCount }
const { darkMode } = useContext(ThemeContext);
  const onChangeSidebar = (child) => {
    setOpen(child)
  }
  return (
    <Fragment>
      <ProfileContext.Provider value={value}>
      <div className={clsx('app-wrapper', contentBackground)}>
        <Header />
        <div className={clsx(darkMode ? 'app-mainn' : 'app-main', { 'app-main-sidebar-static': !sidebarFixed })}>
          <Sidebar onchange={onChangeSidebar} />
          <div>
            <div className={clsx('app-content--base', darkMode ? 'app-content--innerrr' : 'app-content--inner')}>
             <div className={clsx(open ? (darkMode ? 'app_container3' : 'app_container1') : 'app_container2')}
                style={{ backgroundColor: darkMode ? '#021C31' : 'inherit' ,
                //  marginLeft:"238px"
                 }}>{children}</div>
            </div>
          </div>
        </div>
      </div>
      <RefreshToken />
      </ProfileContext.Provider>
    </Fragment>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
  contentBackground: state.ThemeOptions.contentBackground,
});

export default connect(mapStateToProps)(LeftSidebar);
