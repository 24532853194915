import api from '../../config/http-common';
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter, page, rowsPerPage) => {
  let query = "?isPageable=true&pageNumber=" + page + "&pageSize=" + rowsPerPage;

  if (!CommonUtil.isEmptyString(filter.name)) {
    query = query + "&name=" + filter.name;
  }
  if (!CommonUtil.isEmptyString(filter.code)) {
    query = query + "&code=" + filter.code;
  }
  if (!CommonUtil.isEmptyString(filter.description)) {
    query = query + "&description=" + filter.description;
  }
  return query;
}

const listChecklistTemplates=(payload)=>{   
return api.securedAxios().get(
    '/api/v2.0/checklist_template' +
      buildQuery(payload.filter, payload.page, payload.rowsPerPage)
  );
};

const listChecklistTemplates1=(payload)=>{   
  return api.securedAxios().get(
      '/api/v2.0/checklist_template?isPageable=' +
        payload.isPageable +
        '&name=' +
        payload.name +
        '&code=' +
        payload.code +
        '&pageNumber=' +
        payload.pageNumber +
        '&pageSize=' +
        payload.pageSize
      
    );
  };
  
const addChecklistTemplate = (payload) => {
  return api.securedAxios().post("/api/v2.0/checklist_template", payload);
};

const updateChecklistTemplate = (payload) => {
  return api.securedAxios().put("/api/v2.0/checklist_template/" + payload.id, payload);
}

const getChecklistItems = (query) => {
  return api.securedAxios().get('/api/v2.0/checklist_item?checklistTemplateId=' + query.templateId 
  + '&isPageable=' + query.isPageable + '&pageNumber=' + query.pageNumber 
  + '&pageSize=' + query.pageSize );
}

const addChecklistItem = (payload) => {
  return api.securedAxios().post('/api/v2.0/checklist_item', payload);
};

const editChecklistItem = (payload) => {
  return api.securedAxios().put('/api/v2.0/checklist_item/' + payload.id, payload);
}

const deleteChecklistItem = (payload) => {
  return api.securedAxios().delete("/api/v2.0/checklist_item?ids=" + payload);
}

const QRCode = (code) => {
  return api.unsecuredAxios().get('/visitor_workflow/visit_registration/' + code + '/qrcode');
}

const ChecklistTemplateService = {
    listChecklistTemplates,
    addChecklistTemplate,
    updateChecklistTemplate,
    listChecklistTemplates1,
    getChecklistItems,
    addChecklistItem,
    editChecklistItem,
    deleteChecklistItem,
    QRCode,
};

export default ChecklistTemplateService;