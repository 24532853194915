import api from '../../config/http-common'
import CommonUtil from '../../Util/CommonUtils'

const buildQuery = (filter) => {
    let query = '?pageNumber=' + filter.pageNumber + '&pageSize=' + filter.pageSize

    return query
  }

  const getDeviceTypeList = (filter) => {
    return api.securedAxios().get('/api/v4/web/deviceTypes/list' + buildQuery(filter))
  }

  const updateDeviceType = (payload) => {
    return api.securedAxios().put('/api/v4/web/deviceTypes/' + payload.get('id') , payload )
  }

  const DeviceTypeService ={
    getDeviceTypeList,
    updateDeviceType
  }

  export default DeviceTypeService
