import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DialogDataTable from '../DataTable/DialogDataTable'
import { useDispatch } from 'react-redux'
import { getPurchasedPlans } from '../../slices/Subscription/SubscriptionSlice'
import moment from 'moment'
import CommonUtil from '../../Util/CommonUtils'
import { IconButton } from '@mui/material'
import CancelButtons from './CancelButtons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@material-ui/core'
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'

const useStyles = makeStyles((theme) => ({
  purchaseDialog: {
    '& .MuiDialog-paper': {
      minWidth: '900px',
      marginLeft: '180px',
    },
  },
}))

export default function SelectPlanDialogBox(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [rows, setRows] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const history = useHistory()
  const companyId = localStorage.getItem('companyId')
  const [date, setDate] = useState('')

  const [filter, setFilter] = useState({
    subscriptionName: '',
    planAssigned: '',
  })
  const [paging, setPaging] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  const defaultFilter = {
    subscriptionName: '',
    planAssigned: '',
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setPaging({
      ...paging,
      pageNumber: newPage,
      pageSize: size,
    })
  }

  const onCancel = () => {
    props.handleClose()
  }

  const handleSelect = (params) => {
    props.handlePlanSelected(params)
    props.handleClose()
  }

  useEffect(() => {
    loadData(filter, paging)
    loadSetting()
  }, [filter, paging])

  const loadData = (filter, paging) => {
    let payload = {
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
      subscriptionName: '',
      planAssigned: false,
    }
    dispatch(getPurchasedPlans(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4035') {
          setRows(res.data.purchasedPlans)
          setTotalPages(res.data.totalPages)
          setTotalRecords(res.data.totalCount)
          setPage(res.data.currentPage)
        } else {
          setRows([])
        }
      })
  }

  const loadSetting = () => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          if (data.data.adminAndDealerDateFormat === 'DD-MM-YYYY') {
            setDate('dd-MM-yyyy')
          }
          if (data.data.adminAndDealerDateFormat === 'MM-DD-YYYY') {
            setDate('MM-dd-yyyy')
          }
          if (data.data.adminAndDealerDateFormat === 'YYYY-MM-DD') {
            setDate('yyyy-MM-dd')
          }
        }
      }
      )
  }

  const renderCellStartDate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.startDate)
    ).format(
      date === 'dd-MM-yyyy' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };
  const renderCellEndDate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.endDate)
    ).format(
      date === 'dd-MM-yyyy' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd' ? 'YYYY-MM-DD HH:mm' : ''
    );
    console.log(formattedDate)
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const columns = React.useMemo(() => [
    {
      field: 'subscriptionName',
      headerName: t('PLAN_NAME'),
      flex: 1,
      minWidth: 150,
    },

    {
      field: 'startDate',
      headerName: t('START_DATE'),
      flex: 1,
      minWidth: 190,
      renderCell: renderCellStartDate(date)
    },

    {
      field: 'endDate',
      headerName: t('END_DATE'),
      flex: 1,
      minWidth: 190,
      renderCell: renderCellEndDate(date)
    },

    {
      field: 'price',
      headerName: t('PRICE'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => params.value.unit_amount_decimal / 100,
    },

    {
      field: 'discount',
      headerName: t('DISCOUNT'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        params.row.discount ? params.row.discount.discountValue + '%' : '',
    },

    {
      field: 'subscriptionMode',
      headerName: t('SUBSCRIPTION_MODE'),
      flex: 1,
      minWidth: 220,
      renderCell: (params) => params.row.price.recurring.interval,
    },

    {
      field: 'maxVisitsCreation',
      headerName: t('VISIT_COUNT'),
      flex: 1,
      minWidth: 130,
    },

    {
      field: 'Action',
      headerName: t('PLAN_ACTION'),
      type: 'action',
      flex: 1,
      minWidth: 100,
      width: 100,
      headerAlign: 'start',
      renderCell: (params) => (
        <Button
          style={{
            backgroundColor: '#0F5A9C26',
            height: '5px',
            color: '#0F5A9C',
          }}
          onClick={() => handleSelect(params.row)}
        >
          {t('SELECT')}
        </Button>
      ),
    },
  ], [date])

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={true}
        disable
        PaperProps={{
          style: {
            borderRadius: '20px !important',
          },
        }}
        className={classes.purchaseDialog}
      >
        <DialogTitle variant="h4" onClick={props.handleClose}>
          <IconButton
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {t('DEALERPLANSELECT')}
        </DialogTitle>
        <DialogContent
          sx={{
            textAlign: 'center',
            fontSize: '14px',
          }}
        >
          <DialogDataTable
            columns={columns}
            rows={rows}
            page={page}
            pageSize={rowsPerPage}
            count={totalRecords}
            pageCount={totalPages}
            handleChange={(newPage, size) => handleChange(newPage, size)}
          />
          <DialogActions
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <CancelButtons
              variant="contained"
              color="secondary"
              onClick={onCancel}
            >
              <Typography style={{ textTransform: 'none' }}>
                {t('COMMON008')}
              </Typography>
            </CancelButtons>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
