import React, { useContext } from 'react'
import DeviceTabs from './DeviceTabs';
import './Device.css';
import HeaderToolbar from '../../components/HeaderToolbar';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../theme/ThemeContext';
function Device() {
  const { t } = useTranslation();
  const { darkMode } = useContext(ThemeContext);

  return (
    <>
      <HeaderToolbar
        title={t('VISACCDEV009')}
      />
      <DeviceTabs />
    </>
  )
}

export default Device