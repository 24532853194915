import React, { useEffect, useState, useContext } from 'react';
import { TextField, Popper,Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { ThemeContext } from '../../theme/ThemeContext';
import CommonUtil from '../Util/CommonUtils'; 

const CssTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'darkMode',
})(({ theme, darkMode }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: darkMode ? '#DDDDDD' : '#b3adad', // Default border color for the fieldset
      borderWidth: '1px', // Set border width to 1px
    },
    '&:hover fieldset': {
      borderColor: '#2286AB', // Border color when hovered
    },
    '&.Mui-focused fieldset': {
      borderColor: darkMode ? '#fff' : '#0F273B', // Border color when focused
    },
    '&.Mui-disabled fieldset': {
      borderColor: darkMode ? '#fff' : 'rgba(0, 0, 0, 0.38)', // Border color when disabled
    },
    '&.Mui-disabled': {
      opacity: 1,
      '-webkit-text-fill-color': darkMode ? '#fff' : 'rgba(0, 0, 0, 0.38)', // Disabled text color
    },
  },
  '& .MuiInputLabel-root': {
    color: darkMode ? '#fff' : '#707070', // Label color
  },
  '& .MuiFormLabel-root': {
    fontSize: '0.875rem',
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    color: darkMode ? '#fff' : '#0F273B', // Default text color
  },
  '& .MuiInputBase-input.Mui-disabled': {
    opacity: 1,
    '-webkit-text-fill-color': darkMode ? '#fff' : 'rgba(0, 0, 0, 0.38)', // Disabled text color fix
  },
}));




const CustomPopper = styled(Popper)(({ darkMode }) => ({
  "& .MuiAutocomplete-listbox": {
    backgroundColor: darkMode ? "#0F273B" : "#fff", // Dropdown background color
    color: darkMode ? "#fff" : "#000", // Dropdown text color
  },
  "& .MuiAutocomplete-option": {
    backgroundColor: darkMode ? "#0F273B" : "#fff", // Option background color
    "&[aria-selected='true']": {
      backgroundColor: darkMode ? "#0D1926" : "#e6f7ff", // Background color when an option is selected
    },
    "&:hover": {
      backgroundColor: darkMode ? "#0D1926" : "#e6f7ff", // Hover background color
    },
  },
}));

// Custom Paper component to apply styles to the "No Options found" text
const CustomPaper = styled(Paper)(({ darkMode }) => ({
  backgroundColor: darkMode ? "#0F273B" : "#fff", // Background color for the dropdown container
  color: darkMode ? "#fff" : "#000", // Text color for the "No Options found" text
}));




export default function CustomTextfield(props) {
  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);
  const [value, setValue] = useState(props.value);
  const { t } = useTranslation();
  const { darkMode, disabled } = useContext(ThemeContext);

  useEffect(() => {
    setError(props.error);
    setHelperText(props.helperText);
  }, [props.error]);

  const handleChange = (e) => {
    var val = e.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (e.target.value.length > 0 && val.length === 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    if (props.regex === 'none' && props.validation) {
      switch (props.validation) {
        case 'alpha-numeric':
          handleCode(e);
          break;
        case 'numeric':
          handleNumeric(e);
          break;
        case 'restrictLength':
          handleRestrictLength(e);
          break;
        case 'email':
          handleEmail(e);
          break;
        case 'password':
          handlePassword(e);
          break;
        case 'code':
          handleCode(e);
          break;
        case 'alphabets':
          handleAlphabets(e);
          break;
        case 'restrictLengthWithAlphaNumeric':
          handleLengthWithAlphaNumeric(e);
          break;
        case 'restrictNumericValueToTwoDigits':
          handleNumericWithTwoDigits(e);
          break;
        case 'restrictNumericValueToTenDigits':
          handleNumericWithTenDigits(e);
          break;
        case 'alphaNumeric':
          handleAlphaNumeric(e);
          break;
        case 'numericWithSpace':
          handleNumericWithSpace(e);
          break;
        case 'alphaNumericWithSpace':
          handleAlphaNumericWithSpace(e);
          break;
        default:
          props.handleChange(e);
      }
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleAlphabets = (e) => {
    if (CommonUtil.isAlphabets(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('Only accepts alphabets'));
    }
  };

  const handleCode = (e) => {
    if (CommonUtil.isAlphaNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('Only accepts alphanumeric characters'));
    }
  };

  const handleNumeric = (e) => {
    if (CommonUtil.isValidNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('INVITATIONFORM019'));
    }
  };

  const handleRestrictLength = (e) => {
    if (e.target.value.length <= 100) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('Characters length should be less than 100'));
    }
  };

  const handleLengthWithAlphaNumeric = (e) => {
    if (e.target.value.length > 100) {
      setError(true);
      setHelperText(t('Characters length should be less than 100'));
    } else if (!CommonUtil.isAlphaNumericWithSpace(e.target.value)) {
      setError(true);
      setHelperText(t('Only accepts alphanumeric characters'));
    } else {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    }
  };

  const handleAlphaNumeric = (e) => {
    if (!CommonUtil.isAlphaNumeric(e.target.value)) {
      setError(true);
      setHelperText(t('Only accepts alphanumeric characters'));
    } else {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    }
  };

  const handleNumericWithTwoDigits = (e) => {
    if (!CommonUtil.isValidNumeric(e.target.value)) {
      setError(true);
      setHelperText(t('ACCEPT_NUMBER'));
    } else if (e.target.value > 99) {
      setError(true);
      setHelperText(t('Discount should be less than 100%'));
    } else {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    }
  };

  const handleNumericWithSpace = (e) => {
    if (CommonUtil.isValidNumericWithSpace(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('ACCEPT_NUMBER'));
    }
  };

  const handleAlphaNumericWithSpace = (e) => {
    if (CommonUtil.isAlphaNumericWithSpace(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('ACCEPT_ALPHANUMERIC'));
    }
  };

  const handleNumericWithTenDigits = (e) => {
    if (!CommonUtil.isValidNumeric(e.target.value)) {
      setError(true);
      setHelperText(t('ACCEPT_NUMBER'));
    } else if (e.target.value.length > 10) {
      setError(true);
      setHelperText(t('Number of digits for the device should be limited to 10'));
    } else {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    }
  };

  const handleEmail = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidEmail(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('INVITATIONFORM014'));
    }
  };

  const handlePassword = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidPassword(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('Only accepts Password'));
    }
  };

  return (
    <CssTextField
      style={{ background: 'transparent' }}
      autoComplete={props.autoComplete}
      {...props}
      required={props.required}
      disabled={props.disabled}
      value={props.value}
      helperText={helperText}
      error={error}
      onChange={(e) => handleChange(e)}
      margin="normal"
      variant="outlined"
      fullWidth
      size="medium"
      type={props.type}
      label={props.label}
      name={props.name}
      onBlur={props.onBlur}
      className="login_input"
      // InputLabelProps={
      //   props.InputLabelProps ? { shrink: props.InputLabelProps } : undefined
      // }
      InputProps={{
        ...props.InputProps,
        style: {
          color: darkMode ? '#fff' : '#000', // Change text color based on dark mode
          ...props.InputProps?.style,
        },
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        style: {
          color: darkMode ? '#fff' : '#000', // Change label color based on dark mode
          ...props.InputLabelProps?.style,
        },
      }}


      PopperComponent={(props) => (
        <CustomPopper {...props} darkMode={darkMode} />
      )}
      PaperComponent={(props) => (
        <CustomPaper {...props} darkMode={darkMode} />
      )}
      darkMode={darkMode}
      // disabled={disabled}
      inputColor={props.inputColor}
    />
  );
}

CustomTextfield.defaultProps = {
  validation: 'none',
  regex: 'none',
  required: false,
  disabled: false,
  inputColor: null,
};

CustomTextfield.propTypes = {
  validation: PropTypes.oneOf([
    'alpha-numeric',
    'alphabets',
    'numeric',
    'restrictLength',
    'email',
    'password',
    'code',
    'restrictLengthWithAlphaNumeric',
    'restrictNumericValueToTwoDigits',
    'restrictNumericValueToTenDigits',
    'alphaNumeric',
    'numericWithSpace',
    'alphaNumericWithSpace',
  ]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  inputColor: PropTypes.string,
};
