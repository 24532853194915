import { Avatar, Box } from '@material-ui/core'
import { Stack, Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import AddIcon from '../../assets/Customer/AddCustomers.svg'
import filterIcon from '../../assets/Customer/Filter.svg'
import forwardIcon from '../../assets/Customer/forward.svg'
import DataTable from '../../components/DataTable/DataTable'
import Search from '../../components/SearchTab/Search'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  fetchCustomersDetailsByDealerCompanyId,
  getCustomers,
  resendActivationLink,
} from '../../slices/Customer/CustomerSlice'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import EditChecklistIcon from '../Checklist/EditChecklistIcon'
import EmployeeDeleteIcon from '../Employee/EmployeeDeleteIcon'
import ResendIcon from '../Invitations/ResendIcon'
import ViewIcon from '../Invitations/ViewIcon'
import CustomerDownload from './CustomerDownload'
import CustomerFilter from './CustomerFilter'
import DeleteCustomer from './DeleteCustomer'
import CircularProgress from '@mui/material/CircularProgress'
import { REACT_CREATE_CUSTOMER } from '../../actions/EndPoints'
import { getCompanyInfo } from '../../slices/Company/CompanySlice'
import isoCountries from 'i18n-iso-countries'

function CustomersList() {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [clear, setClear] = React.useState(false)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [openFilter, setOpenFilter] = useState(false)
  const [openDownload, setOpenDownload] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const [openEditCustomer, setopenEditCustomer] = useState(false)
  const [editRowData, setEditRowDate] = useState({})
  const [openDeleteCustomer, setOpenDeleteCustomer] = useState(false)
  const [customerId, setCustomerId] = useState()
  const [CustomerName, setCustomerName] = useState()
  const [searchRecord, setSearchRecord] = useState()
  const [loading, setLoading] = useState(true)
  const [parentCompanyCountry, setParentCompanyCountry] = useState('')

  const resetFilter = () => {
    setLoading(true)
    setClear(false)
    setPaging({ ...paging, pageNumber: 1 })
    setfilter(defaultFilter)
    setLoading(true)
  }

  const globalsearch = (searchedVal) => {
    setSearchRecord(searchedVal)
    const filteredRows = rows.filter((test) => {
      return (
        test.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.email.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (test.status
          ? test.status.trim().toLowerCase() ===
            searchedVal.trim().toLowerCase()
          : '') ||
        (test.adminName
          ? test.adminName.trim().toLowerCase() ===
            searchedVal.trim().toLowerCase()
          : '') ||
        (test.subscriptionName
          ? test.subscriptionName.trim().toLowerCase() ===
            searchedVal.trim().toLowerCase()
          : '') ||
        (test.phone
          ? test.phone.toLowerCase().includes(searchedVal.toLowerCase())
          : '')
      )
    })
    setRows(filteredRows)
  }
  const handleChange = (newPage, size) => {
    setLoading(true)
    setPage(newPage)
    setPaging({
      pageNumber: newPage,
      pageSize: size,
    })
  }

  const [defaultFilter, setDefaultFilter] = useState({
    status: '',
    name: '',
    email: '',
    subscriptionName: '',
    subscriptionStartDate: '',
    subscriptionEndDate: '',
  })

  const [filter, setfilter] = useState({
    status: '',
    name: '',
    email: '',
    subscriptionName: '',
  })

  const [paging, setPaging] = useState({
    pageNumber: '1',
    pageSize: '10',
  })

  const handleFilter = (data) => {
    setLoading(true)
    setClear(true)
    setfilter({
      ...filter,
      status: data.status,
      name: data.name,
      email: data.email,
      subscriptionName: data.subscriptionName,
    })
  }

  const handleResendAccountActivate = (params) => {
    dispatch(resendActivationLink(params))
      .unwrap()
      .then((data) => {
        switch (data.code) {
          case 'CVAI0001':
            Toast(t('DEALERRESENDACTIVEEMAIL'), 'success')
            break
          case 'MSGE4006':
            Toast(t('DEALERCHECKACTIVEEMAILNOT'), 'error')
            break
          default:
            Toast(data.message, 'error')
            break
        }
      })
      .catch((e) => {})
  }

  useEffect(() => {
    setTimeout(() => {
      loadData(filter, paging)
    }, 1000)
  }, [filter, paging])

  useEffect(() => {
    loadParentCompany()
  }, [])

  const loadParentCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setParentCompanyCountry(countryCode)
          }
        }
      })
      .catch((er) => {})
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  const loadData = (filter, paging) => {
    const payload = {
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
      status: filter.status,
      name: filter.name,
      email: filter.email,
      subscriptionName: filter.subscriptionName,
    }
    dispatch(fetchCustomersDetailsByDealerCompanyId(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4024') {
          setLoading(false)
          setRows(res.data.companies)
          setTotalRecords(res.data.totalCount)
          setPage(res.data.currentPage)
          setTotalPages(res.data.totalPages)
        }
      })
  }

  const handleDeleteCustomer = (params) => {
    setOpenDeleteCustomer(!openDeleteCustomer)
    setCustomerId(params.id)
    setCustomerName(params.name)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 20) + '...'
    } else {
      return text
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={
            params.row.imagePreSignedURL !== undefined
              ? params.row.imagePreSignedURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={params.row.imagePreSignedURL}
        ></Avatar>
      }
    />
  )

  const handleEdit = (params) => {
    setEditRowDate(params)
    setopenEditCustomer(true)
    history.push({
      pathname: '/vis/createCustomer',
      state: params,
      country: parentCompanyCountry,
    })
  }

  const handleView = (params) => {
    history.push({
      pathname: '/vis/viewCustomerDetails',
      state: params,
    })
  }

  const columns = React.useMemo(() => [
    {
      field: 'company.profile',
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },

    {
      field: 'companyName',
      headerName: t('CUSTDATABLE01'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'email',
      headerName: t('CUSTDATABLE02'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.email,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'mobile',
      headerName: t('CUSTDATABLE03'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.phone,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'adminName',
      headerName: t('CUSTDATABLE04'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.adminName,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'subscription',
      headerName: t('CUSTDATABLE05'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.subscriptionName,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'status',
      headerName: t('CUSTDATABLE07'),
      flex: 1,
      minWidth: 140,
      valueGetter: (params) => params.row.status,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'Actions',
      headerName: t('CUSTDATABLE16'),
      type: 'actions',
      flex: 1,
      minWidth: 150,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ViewIcon />}
          label={t('VIEW')}
          onClick={() => handleView(params.row)}
        />,

        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label={t('dataTableEdit')}
          onClick={() => handleEdit(params.row)}
        />,

        <GridActionsCellItem
          icon={<ResendIcon />}
          label="Resend"
          onClick={() => handleResendAccountActivate(params.row)}
        />,

        <GridActionsCellItem
          icon={<EmployeeDeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteCustomer(params.row)}
        />,
      ],
    },
  ])

  const handleFilterIcon = () => {
    setOpenFilter(true)
  }

  const handleDownload = () => {
    if (rows.length === 0) {
      Toast(t('dataTableEmptyList'), 'error')
      return
    } else {
      setOpenDownload(true)
    }
  }

  const handleCreateCustomer = () => {
    history.push({
      pathname: REACT_CREATE_CUSTOMER,
      country: parentCompanyCountry,
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Stack direction="row">
        <Box style={{ margin: '16px, 0' }}>
          <Typography variant="h3">{t('DASHBOARDTABCUSTOMER')}</Typography>
        </Box>

        <Box
          display={'flex'}
          width={'100%'}
          flexDirection={'row-reverse'}
          alignItems={'center'}
        >
          <Box item style={{ margin: '0 8px' }}>
            <Tooltip title={t('ADDCUSTOMER')} onClick={handleCreateCustomer}>
              <img src={AddIcon} />
            </Tooltip>
          </Box>

          <Box item>
            {clear && (
              <Link
                href="#"
                underline="hover"
                className="line"
                style={{
                  opacity: '0.8',
                  color: '#E3393C',
                  fontSize: '14px',
                  underline: 'hover',
                  display: 'inline',
                }}
                onClick={resetFilter}
              >
                {t('CLEARFILTER')}
              </Link>
            )}
          </Box>

          <Box item style={{ margin: '0 8px' }}>
            <Tooltip title={t('FILTER')} onClick={handleFilterIcon}>
              <img src={filterIcon} />
            </Tooltip>
          </Box>

          <Box item style={{ margin: '0 8px' }}>
            <Tooltip title={t('EXPORT')} onClick={handleDownload}>
              <img src={forwardIcon} />
            </Tooltip>
          </Box>

          <Box item>
            <Search
              onSearch={(e) => globalsearch(e)}
              clearSearch={() => loadData(filter, paging)}
            />
          </Box>
        </Box>
      </Stack>
      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}
      <div style={{ height: 300, width: '100%', marginTop: '15px' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openFilter && (
        <CustomerFilter
          open={openFilter}
          applyFilter={handleFilter}
          handleClose={() => {
            setOpenFilter(false)
          }}
        />
      )}

      {openDownload && (
        <CustomerDownload
          open={openDownload}
          handleClose={() => {
            setOpenDownload(false)
          }}
          status={filter}
          searchRecords={searchRecord}
        />
      )}

      {openDeleteCustomer && (
        <DeleteCustomer
          open={openDeleteCustomer}
          handleDialogClose={handleClose}
          loadData={() => {
            loadData(filter, paging)
          }}
          handleClose={(data) => {
            setOpenDeleteCustomer(data)
          }}
          customerId={customerId}
          customerName={CustomerName}
        />
      )}
    </>
  )
}
export default CustomersList
