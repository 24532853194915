import { Box, Breadcrumbs, Grid, Link, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable/DataTable";
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router-dom";
import {
  addDevice,
  getAccDevices,
  removeDevice,
  searchDevices,
} from "../../../slices/CheckInCheckOutDevices/CheckInCheckOutDevicesSlice";
import Search from "../../../components/SearchTab/Search";
import Toast from "../../../components/ToastContainer/CustomToast";
import HeaderToolbar from "../../../components/HeaderToolbar";
import { ReactComponent as AddDeviceIcon } from "../../../assets/images/checkInAndOutDevice/AddDeviceIcon.svg";
import AddCheckInOutDevicesFilter from "./AddCheckInOutDevicesFilter";
import DeviceDialog from "./DeviceDialog";
import { REACT_URL_CHECKINOUTDEVICES } from "../../../actions/EndPoints";
import { useTranslation } from "react-i18next";
import AddSiteIcon from "../../../assets/AddSiteIcon.svg";

function AddCheckOutDevice(props) {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [clear, setClear] = useState(false);
  const [searchedRows, setSerchedRows] = useState([]);
  const { t } = useTranslation();

  const zlink = localStorage.getItem("redirectFrom");

  const [paging, setPaging] = useState({
    page: 0,
    size: 10,
  });

  const [query, setQuery] = useState({
    deviceName: "",
    sn: "",
    ipAddress: "",
  });

  const defaultQuery = {
    deviceName: "",
    sn: "",
    ipAddress: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      size: size,
    });
  };

  useEffect(() => {
    loadData(query, paging);
  }, [query, paging, clear]);

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 0 });
    setQuery(defaultQuery);
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 0 });
    setQuery({
      deviceName: data.deviceName,
      sn: data.sn,
      ipAddress: data.ipAddress,
    });
  };

  const handleAddDevice = (rowData) => {
    var payload = {
      id: rowData.id,
      deviceName: rowData.deviceName,
      ipAddress: rowData.ipAddress,
      sn: rowData.sn,
      deviceType: "acc",
      companyCode: localStorage.getItem("companyCode"),
      siteId: "siteId",
      deviceOperationType: props.history.location.state.operationType,
      siteName: "",
    };

    dispatch(addDevice([payload]))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0000") {
          Toast('Device Added Successfully', "success");
          loadData(query, paging);
        } else {
          Toast(data.message, "error");
        }
      });
  };

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.deviceName
          ? test.deviceName.toLowerCase().includes(searchedVal.toLowerCase())
          : "") ||
        test.sn.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (test.ipAddress
          ? test.ipAddress.toLowerCase().includes(searchedVal.toLowerCase())
          : "") ||
        (test.deviceOperation
          ? test.deviceOperation
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
          : "")
      );
    });
    setRows(filteredRows);
  };

  const loadData = (query, paging) => {
    dispatch(searchDevices(Object.assign(query, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0000" && data.data)) {
          setRows(data.data.devices);
          setTotalRecords(data.data.totalCount);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => {});
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: "",
        sortable: false,
        width: 5,
        renderCell: (params) => {
          return <div></div>;
        },
      },
      {
        field: "deviceName",
        headerName: "Device Name",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.deviceName,
      },
      {
        field: "sn",
        headerName: "Serial Number",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.sn,
      },
      {
        field: "ipAddress",
        headerName: "IP Address",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.ipAddress,
      },
      {
        field: "deviceOperation",
        headerName: "Device Operation",
        flex: 1,
        width: 10,
        valueGetter: (params) =>
          params.row.deviceOperation === "1" ? "Entry" : "Exit",
      },
      {
        field: "Actions",
        headerName: "Action",
        type: "actions",
        flex: 1,
        minWidth: 190,
        width: 150,
        headerAlign: "center",
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <DeviceDialog
                data={params.row}
                handleDevice={() => {
                  handleAddDevice(params.row);
                }}
              />
            }
          />,
        ],
      },
    ],
    []
  );

  return (
    <>
      {!zlink ? (
        <>
          <HeaderToolbar title="Add" tooltipTitle="" back_button={true} />
        </>
      ) : (
        <>
          <HeaderToolbar
            title="Add Check-Out"
            tooltipTitle=""
            back_button={true}
          />
        </>
      )}
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item p={1}>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "1",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>
        <Box item p={1}>
          <AddCheckInOutDevicesFilter
            onSubmit={(data) => {
              handleFilter(data);
            }}
          />
        </Box>
        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(query, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={paging.size}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
    </>
  );
}
export default AddCheckOutDevice;
