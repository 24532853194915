import api from '../../config/http-common';

const listcloudAccess_Levels = (payload) => {
    return api.securedAxios().get('/api/v2.0/visitor_type/access_level' +
        '?isPageable=' + payload.isPageable + '&page=' + payload.page + '&size=' + payload.pageSize);
};

const getAccessLevel = (payload) => {
    return api.securedAxios().get('/api/v2.0/visitor-access-level'
        + '/' + payload.id + '?page=' + payload.page + '&size=' + payload.pageSize);
};

const getAccessLevelList = (payload) => {
    return api.securedAxios().get(`/web/getAccessLevelList/${payload}`);
};


const deleteAccessLevel = (payload) => {
    return api.securedAxios().post(`/web/deleteAccessLevel/${payload.id}`, payload.ids)

};

const addAccessLevel = (payload) => {
    return api.securedAxios().put(`/web/addAccessLevel/${payload.id}`,payload.ids);
};

const PerpouseTypeAccelevelUSAService = {
    listcloudAccess_Levels,
    getAccessLevel,
    deleteAccessLevel,
    addAccessLevel,
    getAccessLevelList
};

export default PerpouseTypeAccelevelUSAService;
