import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PinNumberService from "../../services/PinNumber/PinNumberService";

const initialState = [];



export const getVisitordetailsByCardNumber = createAsyncThunk(
    'visitDetailsbycardNumber',
    async (payload) => {
        const res = await PinNumberService.getVisitordetailsByCardNumber(payload);
        return res.data;

    }
 
);


export const updateStatus = createAsyncThunk(
    'updateStatus',
    async (payload) => {
        const res = await PinNumberService.updateStatus(payload);
        return res.data;

    }
 
);


