import { Paper } from '@material-ui/core'
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import Toast from '../../components/ToastContainer/CustomToast'
import Button from '@mui/material/Button'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import download_icon from '../../assets/download_icon.svg'
import { ReactComponent as ProfileEditIcon } from '../../assets/ProfileEditIcon.svg'
import React, { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'
import CommonUtil from '../../Util/CommonUtils'
import { ThemeContext } from '../../theme/ThemeContext';
import {
  getClientData,
  generateAccessKey,
  saveClientData,
} from '../../slices/AtlasIntegration/AtlasIntegrationSlice'

export default function AtlasIntegration() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const companyId = localStorage.getItem('companyId')
  const [editMode, setEditMode] = useState(false)
  const [isDataEditable, setIsDataEditable] = useState(false)
  const [isDownloadable, setIsDownloadable] = useState(false)
  const [noteable, setNoteable] = useState(false)
  const { darkMode } = useContext(ThemeContext);

  const [payload, setPayload] = useState({
    id: '',
    clientId: '',
    companyId: companyId,
    apiAccessKey: '',
    ipAddressList: '',
    callBackURL: '',
  })

  const [error, setError] = React.useState({
    id: false,
    clientId: false,
    companyId: false,
    apiAccessKey: false,
    ipAddressList: false,
    callBackURL: false,
  })

  useEffect(() => {
    loadClientData(companyId)
  }, [])

  const generateAPIAccessKey = () => {
    dispatch(generateAccessKey())
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4001' && data.data) {
          Toast(data.message, 'success')
          setPayload({
            ...payload,
            clientId: data.data.clientId,
            apiAccessKey: data.data.apiAccessKey,
          })
          setIsDownloadable(true)
          setNoteable(false)
        } else {
          Toast(data.message, 'error')
        }
      })
      .catch((er) => { })
  }

  const handleSave = () => {
    if (
      CommonUtil.isEmpty(payload.clientId) ||
      CommonUtil.isEmpty(payload.apiAccessKey)
    ) {
      Toast(t('Please generate client Id and API key.'), 'error')
      setError({ ...error, code: true })
      return false
    }

    if (CommonUtil.isEmpty(payload.callBackURL)) {
      Toast(t('Please enter call back URL.'), 'error')
      setError({ ...error, code: true })
      return false
    }

    if (CommonUtil.isEmpty(payload.ipAddressList)) {
      Toast(t('Please enter IP Adress.'), 'error')
      setError({ ...error, code: true })
      return false
    }

    dispatch(saveClientData(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4002') {
          Toast(data.message, 'success')
          setPayload({
            ...payload,
            clientId: data.data.clientId,
            apiAccessKey: data.data.apiAccessKey,
            callBackURL: data.data.callBackURL,
            ipAddressList: data.data.ipAddressList.toString(),
          })
          setEditMode(true)
          setIsDataEditable(true)
        } else {
          Toast(data.message, 'error')
        }
      })
  }

  const handleCancel = () => {
    loadClientData(companyId)
  }

  const handleDownload = () => {
    const fileData = new Blob(
      [
        'Client Id= ' +
        payload.clientId +
        ',\n' +
        'Access Key= ' +
        payload.apiAccessKey,
      ],
      { type: 'text/plain;charset=utf-8' },
    )
    saveAs(fileData, 'client_data.txt')
  }

  const handleEdit = () => {
    setIsDataEditable(false)
  }

  const handleChange = (event) => {
    const name = event.target.name
    let value = event.target.value
    setPayload({
      ...payload,
      [name]: value,
    })
    setError({
      [name]: false,
    })
  }

  const loadClientData = (companyId) => {
    dispatch(getClientData(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4003' && data.data) {
          setPayload({
            ...payload,
            clientId: data.data.clientId,
            apiAccessKey: data.data.apiAccessKey,
            callBackURL: data.data.callBackURL,
            ipAddressList: data.data.ipAddressList.toString(),
          })
          setIsDataEditable(true)
          setEditMode(true)
          setIsDownloadable(true)
        } else {
          setPayload({
            ...payload,
          })
          setNoteable(true)
        }
      })
      .catch((er) => { })
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      ></Box>
      <Grid
        style={{
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box style={{ margin: '16px, 0' }}>
          <Typography
            variant="h3"
            style={{
              marginLeft: darkMode ? '33px' : '0px',
              marginRight: darkMode ? '18px' : '12px',
              color: darkMode ? '#fff' : '#3D4977',
              marginTop: darkMode ? '3px' : '4px'
            }}
          >
            {t('ATLAS_INTEGRATION')}
          </Typography>

        </Box>
        <Grid>
          {' '}
          {editMode ? (
            <div style={{ backgroundColor: '#F2F2F6' }}>
              <Tooltip
                title={
                  <Typography fontSize={12} fontWeight={500}>
                    Edit
                  </Typography>
                }
              >
                <IconButton onClick={handleEdit}>
                  <ProfileEditIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Paper
        style={{
          height: '90%',
          marginTop: '5px',
          backgroundColor: darkMode ?  '#021C31' : "#fff",
          color: darkMode ? '#fff' : '#242424'
        }}
      >
        <Stack direction="row" sx={{ padding: '16px 32px' }} spacing={2}>
          <Grid
            container
            justifyContent="center"
            style={{ width: '80%' }}
            spacing={2}
          >
            <Grid item lg={6} sm={6}>
              <CustomTextfield
                label={t('CLIENT_ID')}
                name="clientId"
                disabled={true}
                value={payload.clientId}
                key={`clientId-${darkMode}`}
                
              />
            </Grid>
            <Grid item lg={6} sm={6}>
              <CustomTextfield
                label={t('API_KEY')}
                name="apiKey"
                value={payload.apiAccessKey}
                disabled={true}
                key={`clientId-${darkMode}`}
              />
            </Grid>
            <Grid item lg={6} sm={4}>
              <CustomTextfield
                type={'text'}
                label={t('CALL_BACK_URL')}
                name="callBackURL"
                handleChange={(e) => handleChange(e)}
                value={payload.callBackURL}
                disabled={isDataEditable}
                required={true}
              />
            </Grid>
            <Grid item lg={6} sm={4}>
              <CustomTextfield
                type={'text'}
                label={t('IP_ADDRESS_LIST')}
                name="ipAddressList"
                handleChange={(e) => handleChange(e)}
                value={payload.ipAddressList}
                disabled={isDataEditable}
                required={true}
              />
            </Grid>

            <Grid item xs={12}>
              {' '}
              {!isDataEditable ? (
                noteable ? (
                  <Typography variant="h7">
                    {t('NOTE')}: {t('GENERATE_TEXT')}
                  </Typography>
                ) : (
                  <Typography variant="h7">
                    {t('NOTE')}: {t('RE-GENERATE_TEXT')}
                  </Typography>
                )
              ) : (
                ''
              )}
            </Grid>
            {!isDataEditable ? (
              <Grid item xs={12}>
                <Button
                  onClick={generateAPIAccessKey}
                  size="small"
                  variant="outlined"
                  style={{
                    color: 'blue',
                    borderColor: 'blue',
                  }}
                >
                  {noteable ? (
                    <Typography>{t('GENERATE')}</Typography>
                  ) : (
                    <Typography>{t('RE-GENERATE')}</Typography>
                  )}
                </Button>
              </Grid>
            ) : (
              ''
            )}
            {!isDataEditable ? (
              <Grid container xs={12} justifyContent="flex-end" mt={2}>
                <Grid item sx={{ ml: 3 }}>
                  <CancelButtons
                    onClick={handleCancel}
                    color="secondary"
                    variant="contained"
                  >
                    <Typography>{t('CANCEL')}</Typography>
                  </CancelButtons>
                </Grid>
                <Grid item sx={{ ml: 3 }}>
                  <SubmitButtons onClick={handleSave} variant="contained">
                    <Typography>{t('SAVE')}</Typography>
                  </SubmitButtons>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <Grid sx={{ paddingTop: '15px' }}>
            <Box>
              {' '}
              {isDownloadable ? (
                <Tooltip
                  title={
                    <Typography fontSize={12} fontWeight={500}>
                      Download
                    </Typography>
                  }
                >
                  <img
                    src={download_icon}
                    height="55px"
                    onClick={handleDownload}
                  ></img>
                </Tooltip>
              ) : (
                ''
              )}
            </Box>
          </Grid>
        </Stack>
      </Paper>
    </>
  )
}
