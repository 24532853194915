import { Grid } from '@material-ui/core'
import { Tooltip, Typography } from '@mui/material'
import React, {useContext} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ReactComponent as DeleteIcon } from '../../../src/assets/DeleteIcon.svg'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  deleteEmployee
} from '../../slices/Employee/EmployeeSlice'
import { ThemeContext } from '../../theme/ThemeContext';

export default function DeleteEmployee(props) {
  const { open, handleClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = React.useState({
    employeeIdOrEmployeeCode: props.DeleteId,
    userId: props.DeleteUserId,
    firstName: props.DeleteFirstName,
  })

  const handleSubmit = (data) => {
    dispatch(deleteEmployee(payload.employeeIdOrEmployeeCode))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0023') {
          Toast(t('EMPLOYEE_DELETED'), 'success')
          handleClose(false)
          props.delete()
        } else {
          Toast(data.message, 'error')
          handleClose(false)
        }
      })
  }
  const handleTextOverflow = (text) => {
    if (text && text.length > 10) {
      return text.substring(0, 25) + '...'
    } else {
      return text
    }
  }

  return (
    <>
      <DialogBox
        // Header={t('dataTableDelete')}
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
           {t('dataTableDelete')}
          </span>
        }
        acceptText={t('CONFIRM')}
        cancelText={t('COMMON008')}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={'center'}
          style={{ padding: '20px', backgroundColor: darkMode ? '#0F273B' : '' }}
        >
          <Grid>
            <DeleteIcon width="60px" height={'50px'} />
          </Grid>
          <Grid>
            <Grid
              style={{
                wordWrap: 'break-word',
                opacity: 1,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
                fontSize: '17px',
                marginTop: '15px',
              }}
            >
              <Typography
                style={{
                  color: darkMode ? '#fff' : '#242424',
                  fontSize: '15px',
                  marginRight: '4px',
                }}
              >
                {t('DELETEINFO')} {''}
              </Typography>
              <Tooltip title={payload.firstName}>
                <Typography style={{ fontWeight: 'bold', fontSize: '15px',color: darkMode ? '#fff' : '', }}>
                  {handleTextOverflow(payload.firstName)}
                </Typography>
              </Tooltip>
              <Typography
                style={{
                  color: '#242424',
                  marginRight: '2px',
                  fontSize: '15px',
                }}
              >
                ?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
