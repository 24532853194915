const dealerIcon = () => {
  return (
    <svg
      className="MuiSvgIcon-root"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 30 30"
    >
      <g id="dealer" transform="translate(-334 -103)">
        <path
          id="Path_6233"
          data-name="Path 6233"
          d="M-1975.379,15.439-1981,13.958a1.508,1.508,0,0,1-.579-.3l-5.239-4.319-2.327-1.227a1.624,1.624,0,0,1-.782-.959,1.728,1.728,0,0,1,.1-1.261l2.384-4.815a1.521,1.521,0,0,1,2.069-.706l2.17,1.144,4.719-1.451a1.479,1.479,0,0,1,1.074.08l3.2,1.526h1.589l2.257-1.19a1.521,1.521,0,0,1,2.069.706L-1965.911,6a1.727,1.727,0,0,1,.1,1.261,1.624,1.624,0,0,1-.782.959l-2.256,1.189-1.444,1.781a.808.808,0,0,1-.092.1l-3.522,3.717a1.52,1.52,0,0,1-1.1.479A1.464,1.464,0,0,1-1975.379,15.439Zm-10.034-7.019,4.791,3.95,5.619,1.481,2.926-3.085-3.069-2.353-.783.619a3.738,3.738,0,0,1-4.654,0l-.526-.416a1.659,1.659,0,0,1-.617-1.193,1.685,1.685,0,0,1,.45-1.273l3.794-4a1.6,1.6,0,0,1,.176-.16l-.745-.355-4.724,1.452Zm10.717-1.685,3.688,2.828.744-.919-2.643-5.336h-3.475l-3.794,4,.526.416a2.243,2.243,0,0,0,2.792,0l1.241-.981a.749.749,0,0,1,.466-.164A.748.748,0,0,1-1974.7,6.735Zm3.312-3.887L-1969,7.664l1.715-.9-2.385-4.815Zm-17.064,3.8,1.715.9,2.384-4.815-1.715-.9Z"
          transform="translate(2327 111)"
        />
      </g>
    </svg>
  )
}
export default dealerIcon
