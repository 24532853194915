import { Box, Link, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable/DataTable";
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import checkInSvg from "../../../assets/images/checkInAndOutDevice/CheckIn.svg";
import checkInSvg_vis from "../../../assets/images/checkInAndOutDevice/Check-In-vis.svg";
import checkOutSvg from "../../../assets/images/checkInAndOutDevice/CheckOut.svg";
import checkOutSvg_vis from "../../../assets/images/checkInAndOutDevice/Check-Out-vis.svg";
import { useHistory } from "react-router-dom";
import {
  REACT_DEVICE_INFO,
  REACT_DEVICE_LIST,
  REACT_URL_ADDCHECKOUT,
  REACT_URL_ADDDEVICES,
} from "../../../actions/EndPoints";
import {
  getAccDevices,
  removeDevice,
} from "../../../slices/CheckInCheckOutDevices/CheckInCheckOutDevicesSlice";
import CheckInAndOutDeviceFilter from "./CheckInCheckOutDeviceFilter";
import Search from "../../../components/SearchTab/Search";
import DeleteDialog from "../../../components/DialogBoxComponent/DeleteDialog";
import Toast from "../../../components/ToastContainer/CustomToast";
import HeaderToolbar from "../../../components/HeaderToolbar";
import { useTranslation } from "react-i18next";

function CheckInCheckOutDevices() {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);
  const [clear, setClear] = useState(false);
  const [searchedRows, setSerchedRows] = useState([]);
  const { t } = useTranslation();

  const zlink = localStorage.getItem("redirectFrom");

  const [paging, setPaging] = useState({
    page: 1,
    size: 10,
  });

  const [query, setQuery] = useState({
    deviceName: "",
    serialNumber: "",
    ipAddress: "",
  });

  const defaultQuery = {
    deviceName: "",
    serialNumber: "",
    ipAddress: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      size: size,
    });
  };

  useEffect(() => {
    loadData(query, paging);
  }, [query, paging, clear]);

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setQuery(defaultQuery);
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setQuery({
      deviceName: data.deviceName,
      serialNumber: data.serialNumber,
      ipAddress: data.ipAddress,
    });
  };

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.deviceName
          ? test.deviceName.toLowerCase().includes(searchedVal.toLowerCase())
          : "") ||
        test.sn.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (test.ipAddress
          ? test.ipAddress.toLowerCase().includes(searchedVal.toLowerCase())
          : "") ||
        (test.deviceOperation
          ? test.deviceOperation
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
          : "")
      );
    });
    setRows(filteredRows);
  };

  const loadData = (query, paging) => {
    dispatch(getAccDevices(Object.assign(query, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0000" && data.data)) {
          setRows(data.data.devices);
          setTotalRecords(data.data.totalCount);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => {});
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: "",
        sortable: false,
        width: 5,
        renderCell: (params) => {
          return <div></div>;
        },
      },
      {
        field: "deviceName",
        headerName: "Device Name",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.deviceName,
      },
      {
        field: "sn",
        headerName: "Serial Number",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.sn,
      },
      {
        field: "ipAddress",
        headerName: "IP Address",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.ipAddress,
      },
      {
        field: "deviceOperation",
        headerName: "Device Operation",
        flex: 1,
        width: 10,
        valueGetter: (params) =>
          params.row.deviceOperationType.toString() === "0" ? "Exit" : "Entry",
      },
      {
        field: "Actions",
        headerName: t("Action"),
        type: "actions",
        flex: 1,
        minWidth: 190,
        width: 150,
        headerAlign: "center",
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <DeleteDialog
                handleDelete={() => {
                  handleDelete(params.row.id);
                }}
                deviceName={params.row.deviceName}
                name={" device"}
                text={"Are you sure you want to delete this "}
              />
            }
            label="Delete"
          />,
        ],
      },
    ],
    []
  );

  const handleDelete = (id) => {
    dispatch(removeDevice(id))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0047")) {
          Toast("Device Deleted Successfully", "success");
          loadData(query, paging);
        } else {
          Toast(data.message, "error");
        }
      })
      .catch((er) => {});
  };

  const handleAddEntryDevice = () => {
    history.push({
      pathname: REACT_URL_ADDDEVICES,
      state: { operationType: "1", existDevices: "" },
    });
  };

  const handleAddExitDevice = () => {
    history.push({
      pathname: REACT_URL_ADDCHECKOUT,
      state: { operationType: "0", existDevices: "" },
    });
  };

  const handleAddEntryDeviceZlink = () => {
    history.push({
      pathname: REACT_DEVICE_LIST,
      state: { operationType: "1", existDevices: "" },
    });
  };

  const handleAddExitDeviceZlinks = () => {
    history.push({
      pathname: REACT_DEVICE_INFO,
      state: { operationType: "0", existDevices: "" },
    });
  };

  return (
    <>
      {/* <HeaderToolbar /> */}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px 10px",
        }}
      >
        <Box>
          <Typography
            variant="h3"
          >
            {"Check-In & Out Devices"}
          </Typography>
        </Box>
        <Box style={{ display: "flex", position: "relative", left: "15px" }}>
          <Box
            style={{
              textAlign: "-webkit-right",
              padding: "10px",
              backgroundColor: process.env.REACT_APP_BG_ICON,
              borderRadius: "50%",
              margin: "5px",
            }}
          >
            {!zlink ? (
              <>
                <Tooltip title={"Check-In Device"}>
                  <img
                    alt="..."
                    src={checkInSvg_vis}
                    onClick={handleAddEntryDevice}
                    style={{ width: "22px", cursor: "pointer" }}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title={"Check-In Device"}>
                  <img
                    alt="..."
                    src={checkInSvg}
                    onClick={handleAddEntryDeviceZlink}
                    style={{ width: "22px", cursor: "pointer" }}
                  />
                </Tooltip>
              </>
            )}
          </Box>
          <Box
            style={{
              textAlign: "-webkit-right",
              padding: "10px",
              backgroundColor: process.env.REACT_APP_BG_ICON,
              borderRadius: "50%",
              margin: "5px",
            }}
          >
            {!zlink ? (
              <>
                <Tooltip title={"Check-Out Device"}>
                  <img
                    alt="..."
                    src={checkOutSvg_vis}
                    onClick={handleAddExitDevice}
                    style={{ width: "22px", cursor: "pointer" }}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title={"Check-Out Device"}>
                  <img
                    alt="..."
                    src={checkOutSvg}
                    onClick={handleAddExitDeviceZlinks}
                    style={{ width: "22px", cursor: "pointer" }}
                  />
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "1",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>
        <Box item>
          <CheckInAndOutDeviceFilter
            onSubmit={(data) => {
              handleFilter(data);
            }}
          />
        </Box>
        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(query, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={paging.size}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
    </>
  );
}

export default CheckInCheckOutDevices;
