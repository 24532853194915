import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SelfRegistartionService from "../../services/Approvals/SelfRegistartionService";

const initialState = [];

export const approveSelfRegistration = createAsyncThunk(
  "/visitor_selfRegistration/approve",
  async (payload) => {
    const res = await SelfRegistartionService.update(payload);
    return res.data;
  }
);

export const createSelfRegistration = createAsyncThunk(
  "/visitor_selfRegistration/create",
  async (payload) => {
    const res = await SelfRegistartionService.createSelf(payload);
    return res.data;
  }
);

export const rejectVisitorInvitations = createAsyncThunk(
  "/visitor_selfRegistration/reject",
   async (payload) => {
    const res = await SelfRegistartionService.rejectVisitorInvitations(payload);
    return res.data;
   }
);

export const getAllVisitors = createAsyncThunk('getAllVisitors/retrieve', async (payload) => {
  
  const res = await SelfRegistartionService.getAllVisitors(payload);
  return res.data;
});

export const getVisitsCountOfCustomer = createAsyncThunk('getAllVisitorsCount/retrieve', async (payload) => {
  
  const res = await SelfRegistartionService.getVisitsCountOfCustomer(payload);
  return res.data;
});


const selfRegistrationApproval = createSlice({
  name: "selfRegistrationApproval",
  ...initialState,
  extraReducers: {
    [approveSelfRegistration.fulfilled]: (state, action) => {
      if (action.payload.code === "") {
        return action.payload.data;
      } else {
        return [];
      }
    },
  },

  extraReducers: {
    [rejectVisitorInvitations.fulfilled]: (state, action) => {
      if (action.payload.code === "") {
        return action.payload.data;
      } else {
        return [];
      }
    },
  },

  extraReducers: {
    [createSelfRegistration.fulfilled]: (state, action) => {
      if (action.payload.code === "") {
        return action.payload.data;
      } else {
        return [];
      }
    },
  },


});

const { reducer } = selfRegistrationApproval;
export default reducer;
