import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, IconButton } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    background: '#3d4977',
    width: '100%',
    padding: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    color: '#FFFFFF',
  },

  scrollbarContainer: {
    maxHeight: '200px', 
    overflowY: 'auto',
    marginLeft: '300px',
    marginRight: '15px', 
    textAlign: 'left',
    marginTop:'-100px'
  },
  dialogbox: {
    '& .MuiDialog-paper': {
      minWidth: '120vh',
      borderRadius: '14px',
      backgroundColor: '#yourBackgroundColor',
     
    },

  },

  closeButton:{
   marginRight:'-20px',
   marginTop:'-10px'
  }

}))

function ViewSubscription(props) {
 
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(props.open)
  const featureNameList = props.featureNameList
 
  const planName = props.planName
  const price = props.price
 
  const visitLimit = props.visitLimit !== undefined ? props.visitLimit : props.visitLimit;

  const subscriptionType = props.subscriptionType

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 100) {
      return text.substring(0, 11) + '...'
    } else {
      return text
    }
  }

  return (
    <>
      <div>
        <Button
          size="small"
          sx={{
            '&:hover': {
              backgroundColor: 'white',
            },
            fontSize: '20px',
            bottom: '160px',
            right: '-16px',
          }}
          onClick={handleOpen}
        >
          {t('SUBSCRIPTIONVIEWFEATURES')}
        </Button>
      </div>
      <Dialog
        open={open}
        className={classes.dialogbox}
        aria-labelledby="form-dialog-title"
      >
        <div className="main">
          <div className="popup">
            <div className="popup-header">
              <div className="uui"></div>
              <IconButton  className={classes.closeButton} >
                <CloseIcon onClick={handleClose} >X</CloseIcon>
              </IconButton>
            </div>
            {planName != '' ? (
              <Tooltip title={`${planName}`} placement="bottom-start">
                <div
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  className="uuv"
                >
                  <h3 className="xxa">{handleTextOverflow(planName)}</h3>
                </div>
              </Tooltip>
            ) : (
              ''
            )}
            <div className="iim">
              {price !== '' ? <h2 className="xxb">{price}</h2> : ''}
              {subscriptionType !== '' ? (
                <p className="ppa">{subscriptionType}</p>
              ) : (
                ''
              )}
              {visitLimit !== '' ? (
                <p className="ppm">{t('SUBSCRIPTIONVISITIONCREATION')+':' + visitLimit}</p>
              ) : (
                ''
              )}
            </div>
            <div className={classes.scrollbarContainer}>
              <ul className="zzm">
                {featureNameList.map((feature, index) => (
                  <li key={index} style={{ paddingBottom: '10px'}}>
                    <DoneIcon sx={{ fontSize: '18px', color: 'green' }} />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default ViewSubscription
