import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import addressService from "../../services/Site/AddressTypeService";

const initialState = {
    addressTypes: []
};

export const getAddressTypes = createAsyncThunk('addressType/get', async (filter) => {
    const res = await addressService.getAddressTypes(filter);
    return res.data;
});

export const createAddressType = createAsyncThunk('addressType/create', async (payload) => {
    const res = await addressService.createAddressType(payload);
    return res.data;
});

export const updateAddressType = createAsyncThunk('addressType/update', async (payload) => {
    const res = await addressService.updateAddressType(payload);
    return res.data;
});

export const deleteAddressType = createAsyncThunk('addressType/delete', async (payload) => {
    const res = await addressService.deleteAddressType(payload);
    return res.data;
});

const AddressTypeSlice = createSlice({
    name: 'addressType',
    initialState,
    extraReducers: {
        [getAddressTypes.fulfilled]: (state, action) => {
            state.addressTypes = action.payload;
        }
    },
});

const { reducer } = AddressTypeSlice;
export default reducer;
