import React, { useState } from 'react'
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import SubmitButtons from './SubmitButtons'
import { useTranslation } from 'react-i18next'
import CustomDateTimePicker from '../CustomInputs/CustomDateTimePicker'
import CommonUtil from '../../Util/CommonUtils'
import CancelButtons from './CancelButtons'
const useStyles = makeStyles((theme) => ({
  purchaseDialog: {
    '& .MuiDialog-paper': {
      minWidth: '800px',
      marginLeft: '250px',
    },
  },
}))

function PurchaseHistoryFilter(props) {
  const { t } = useTranslation()
  const [selectPlan,setSelectPlan] = useState()
  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }
  var today = new Date()
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T23:59:59'
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T00:00'
  const classes = useStyles()
  const [payload, setPayload] = useState({
    status: '',
    startDate: CommonUtil.formatToUtc(startDate),
    endDate: CommonUtil.formatToUtc(endDate),
  })

  const handleSubmit = () => {
    props.onSave(payload)
    props.handleClose()
  }

  const statusOptions = [{ label: t('PLAN_ASSIGNED') }, { label: t('PLAN_UN_ASSIGNED') }]

  const handleChangePlan = (data) => {
    let plan = ''
    switch (data) {
      case t('PLAN_ASSIGNED'):
        plan = 'Assigned'
        break;
        case t('PLAN_UN_ASSIGNED'):
        plan = 'Unassigned'
        break;
      default:
        plan=''
    }
    setSelectPlan(plan)
    return plan;
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={true}
        disable
        PaperProps={{
          style: {
            borderRadius: '20px !important',
          },
        }}
        className={classes.purchaseDialog}
      >
        <DialogTitle variant="h4" onClick={props.handleClose}>
          <IconButton
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {t('FILTER')}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <Grid style={{ padding: 3 }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={statusOptions}
              // getOptionLabel = {(option) => option.label}
              sx={{ width: '100%' }}
              onChange={(event, newValue) => {
                const selectedPlan = handleChangePlan(newValue.label)
                if (newValue) {
                  setPayload({
                    ...payload,
                    status: selectedPlan,
                  })
                } else {
                  setPayload({
                    ...payload,
                    status: '',
                  })
                }
              }}
              renderInput={(params) => <TextField {...params} label={t('COMMON001')} />}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            style={{ marginTop: '30px', height: '100%' }}
          >
            <CustomDateTimePicker
              onChange={(startDate) =>
                setPayload({
                  ...payload,
                  startDate: startDate,
                })
              }
              value={payload.startDate}
              label={t('PLAN_START_DATE')}
              date={props.date}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={9}
            style={{ marginTop: '30px', height: '100%' }}
          >
            <CustomDateTimePicker
              onChange={(endDate) =>
                setPayload({
                  ...payload,
                  endDate: endDate,
                })
              }
              value={payload.endDate}
              minDateTime={payload.startDate}
              label={t('PLAN_END_DATE')}
              date={props.date}
            />
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <SubmitButtons variant="contained" onClick={handleSubmit}>
            <Typography variant="body1" style={{ textTransform: 'none' }}>
              {t('COMMON024')}
            </Typography>
          </SubmitButtons>

          <CancelButtons onClick={props.handleClose}>
            <Typography variant="body1" style={{ textTransform: "none" }}>
              {t('COMMON008')}
            </Typography>
          </CancelButtons>

        </DialogActions>
      </Dialog>
    </>
  )
}

export default PurchaseHistoryFilter
