import { Autocomplete, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { listAllEmployees } from "../../slices/Employee/EmployeeSlice";
import { assignRole } from "../../slices/UserRole/UserRoleSlice";
import CommonUtil from "../../Util/CommonUtils";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  activateAccount,
} from "../../slices/Person/PersonSlice";



export default function AddUserForm(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [userId, setuserId] = useState("");
  const [roleId, setroleId] = useState(props.selectedObject.id);
  const [payload, setPayload] = useState({
    id: "",
    firstName: "",
    confirmUser:false,
  });
const [state,setState]=useState({
      firstName:"",
      lastName:"",
      email:"",
      phoneCountryCode:"",
      phone:""
})
  const [error, setError] = React.useState({
    firstName: false,
    confirmUser: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    // alert(event.target.value);
    // setCheckin(event.target.checked);
    setPayload({
      [name]: event.target.checked,
    });
    setError({
      [name]: false,
    });
  };

  const handleUserIDChange = (e) => {
    if(e.userId!==undefined){
      setuserId(e.userId)
      setError({
        firstName: false,
      });

    }
    else{
      setState({
        ...state,
        firstName:e.firstName,
        lastName:e.lastName,
        email:e.email,
        phoneCountryCode:e.phone.split("-")[0],
        phone:e.phone.split("-")[1]
      });
      setuserId("");
    }  
  }

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadEmployeeList();
  };

  const loadEmployeeList = () => {
    const query = {
      page: 1,
      rowsPerPage: 0,
      id: "",
      firstName: "",
      createUser: "",
    };
    dispatch(listAllEmployees(query))
      .unwrap()
      .then((data) => {
        // alert("set of employee"+ JSON.stringify(data))
        if ((data.code = "OMSI0000" && data.data)) {
          setData(data.data.employees);
        } else {
          setData([]);
        }
      })
      .catch((er) => { });
  };
  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(userId)&&CommonUtil.isEmptyString(state.firstName)) {
      Toast(t("User Name is required"), "error");
      setError({ userId: true });
      return false;
    }
    if (payload.confirmUser===false) {
      Toast(t("Confirm User"), "error");
      setError({ confirmUser: true });
      return false;
    }

    var requestBodyForAssignUserToRole = {
      userId: userId,
      roleId: roleId,
      firstName:state.firstName,
      lastName:state.lastName,
      email:state.email,
      phoneCountryCode:state.phoneCountryCode,
      phone:state.phone
    }

    dispatch(assignRole(requestBodyForAssignUserToRole))
      .unwrap()
      .then((data) => {
        // alert(""+ JSON.stringify(requestBodyForAssignUserToRole))
        if (data.code === "UASI0009" && data.data) {
          Toast("User assigned successfully.", "success");
          handleClose();
          loadActivateMail();
        } else {
          Toast(data.message, "error");
          handleClose();
        }
      }
      );
  }

  const loadActivateMail = (filter, paging) => {
    var requestBodyForAssignUserToRole = {
      userId: userId,
      roleId: roleId
    }
    dispatch(activateAccount(requestBodyForAssignUserToRole))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI3003") {
          Toast(t("PERSONACTSUCC001"), "success");
        } else if (data.code === "CVAW0001"){
          Toast(t("User already active"), "warning");
        } else {
          Toast((data.message), 'error');
        }
      })
      .catch((er) => {});
  };


  return (
    <>
      <DialogBox
        Header="Add User"
        acceptText="Submit"
        cancelText="Cancel"
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "5px 32px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={11} sx={{ marginTop: 5 }}>
            {/* <FormControl fullWidth>
            <InputLabel>Select Person</InputLabel>
              <Select
                value={userId}
                label="Search Person, Code..."
                onChange={handleUserIDChange}
              >
                {data ? (
                  data.map((d, index) => (
                    <MenuItem key={index} value={d.userId}>{d.firstName} </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl> */}
            <Autocomplete
              fullWidth
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              id="Search Person"
              //   value={value}
              ListboxProps={{ style: { maxHeight: 150 } }}
              getOptionLabel={(option) => option.firstName+' ('+option.code+')'}
              options={data}
              renderInput={(params) => (
                <TextField {...params} label="Search Person, Code..." />
              )}
              onChange={(event, newValue) => {
                handleUserIDChange(newValue);
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: process.env.REACT_APP_BG_ICON }}
                  name="confirmUser"
                  checked={payload.confirmUser}
                  onChange={handleChange}
                />
              }
              label={<Typography style={{ color: '#3D4977' }}>{t("Confirm User")}</Typography>}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
