import { makeStyles } from "@material-ui/core";
import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CommonUtil from "../../Util/CommonUtils";
import CustomDateTimePicker from "../../components/CustomInputs/CustomDateTimePicker";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import CustomerDialog from "../../components/DialogBoxComponent/CustomerDialog";
import Toast from '../../components/ToastContainer/CustomToast';
import { getAllProducts } from "../../slices/Subscription/SubscriptionSlice";

const useStyles = makeStyles((theme) => ({
  dialogbox: {
    "& .MuiDialog-paper": {
      // minWidth: '110vh',
      minWidth: "600px",
      // marginLeft:"250px",
    },
    ".& .css-f3jnds-MuiDataGrid-columnHeaders": {
      backgroundColor: "#F3F9FB",
    },
  },
}));

function CustomerAndDealerTranscationFilter(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const { onClose } = props;
  const { t } = useTranslation()
  const [date, setDate] = useState()
  const [changeDate, setDateChange] = useState(true)
  const [subscriptionList, setSubscriptionList] = useState([])
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    name: '',
    email: '',
    subscriptionName: '',
    status: '',
    dealerName: ''
  });

  const [error, setError] = useState({
    name: false,
    email: false,
    subscription: false,
    dealerName: false
  })

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  useEffect(() => {
    const query = {
      pageNumber: 1,
      pageSize: 1000,
    }
    dispatch(getAllProducts(query))
      .unwrap()
      .then((data) => {
        if (data.code === '200') {
          setSubscriptionList(data.data.results)
          setDateChange(true)
        }
      })
  }, [])

  const handleSubmit = () => {
    if (props.dealerData === 'dealer') {
      if (
        payload.name.trim() === '' &&
        payload.email.trim() === '') {
        Toast(t("FILTERISEMPTY"), 'error')
        return
      }
    }
    else if (payload.name.trim() === '' &&
      payload.email.trim() === '' &&
      payload.subscriptionName === '' &&
      payload.dealerName.trim() === '' ) {
      Toast(t("FILTERISEMPTY"), 'error')
      return
    }
    props.applyFilter(payload)
    setPayload({
      status: '',
      name: '',
      email: '',
      subscriptionName: '',
      dealerName: ''
    })
    onClose()
  }

  return (
    <CustomerDialog
      className={classes.dialogbox}
      Header={t('FILTER')}
      acceptText={t('COMMON024')}
      cancelText={t('COMMON008')}
      onSubmit={handleSubmit}
      open={open}
      onClose={onClose}
      cancel={onClose}
    >
      <Grid container
        justifyContent="center"
        style={{ padding: '0px 32px', width: '100%', marginTop: '20px' }}>
        {props.dealerData === 'dealer' ?
          (
            <>
              <Grid item xs={12} sm={10} >
                <CustomTextfield
                  label={t('FILTERDEALERNAME')}
                  error={error.name}
                  name="name"
                  value={payload.name}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.name}
                  autoComplete="on"
                />
              </Grid>

              <Grid item xs={12} sm={10} >
                <CustomTextfield
                  label={t('DEALEREMAIL')}
                  error={error.email}
                  name="email"
                  value={payload.email}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.email}
                  autoComplete="on"
                />
              </Grid>
            </>
          ) : (<>
            <Grid item xs={12} sm={10} >
              <CustomTextfield
                label={t('FILTERCUSTOMERNAME')}
                error={error.name}
                name="name"
                value={payload.name}
                handleChange={(e) => handleChange(e)}
                helperText={error.name}
                autoComplete="on"
              />
            </Grid>

            <Grid item xs={12} sm={10} >
              <CustomTextfield
                label={t('DEALEREMAIL')}
                error={error.email}
                name="email"
                value={payload.email}
                handleChange={(e) => handleChange(e)}
                helperText={error.email}
                autoComplete="on"
              />
            </Grid>

            <Grid item xs={12} sm={10} >
              <CustomTextfield
                label={t('CUSTDATABLE22')}
                error={error.dealerName}
                name="dealerName"
                value={payload.dealerName}
                handleChange={(e) => handleChange(e)}
                helperText={error.dealerName}
                autoComplete="on"
              />
            </Grid>
            <Grid item xs={12} sm={10} style={{ marginTop: '20px' }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={subscriptionList}
                getOptionLabel={(option) => option.price_strategy_name}
                onChange={
                  (event, newValue) => setPayload({
                    ...payload,
                    subscriptionName: newValue.price_strategy_name
                  })}
                sx={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label={t('DEALERPLACEHOLDER1')} />}
              />
            </Grid>
          </>)}
      </Grid>
    </CustomerDialog>
  );
}

export default CustomerAndDealerTranscationFilter;
