import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import { Grid } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useTranslation } from 'react-i18next'
import CheckIn from './CheckIn'
import CheckedOut from './CheckedOut'
import Cancelled from './Cancelled'
import Missed from './Missed'
import Rejected from './Rejected'
import AllDailyReports from './AllDailyReports'
import { ThemeContext } from '../../theme/ThemeContext';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return <div {...other}>{value === index && <Box >{children}</Box>}</div>;
}

const tabsStyle = {
    borderRight: 'solid #00000029', minWidth: 200,
    textTransform: "none"
}

export default function DailyReportTabs() {

    const { t } = useTranslation();
    const [value, setValue] = useState(0);
    const { darkMode } = useContext(ThemeContext);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

   return (
    <>
    
    <Grid container >
                <Grid item style={{ marginTop: '20px' }} xl={10} lg={12}  md={12} sm={12}  xs={12}>
                    <Tabs
                        TabIndicatorProps={{ style: { background: process.env.REACT_APP_BG_ICON, height: 3 } }}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        style ={{position : 'absolute',  backgroundColor: darkMode ? '#0F273B' : '#fff', color: darkMode ? '#fff' : 'black', marginLeft: darkMode ? '33px' : '0'}}
                    >
                        <Tab label={t('STATUSTABS002')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS003')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS004')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS005')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS006')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS001')} style={{textTransform: "none" }} />
                    </Tabs>
                </Grid>

                <Grid xs={12}>
                    {
                        value === 0 && (
                            <TabPanel value={value} index={value} >
                                <CheckIn />
                            </TabPanel>
                        )
                    }
                    {
                        value === 1 && (
                            <TabPanel value={value} index={value} >
                            <CheckedOut />
                            </TabPanel>
                        )
                    }
                    {
                         value === 2 && (
                            <TabPanel value={value} index={value} >
                            <Cancelled />
                            </TabPanel>
                        )
                    }
                    {
                         value === 3 && (
                            <TabPanel value={value} index={value} >
                            <Missed />
                            </TabPanel>
                        )
                    }
                    {
                         value === 4 && (
                            <TabPanel value={value} index={value} >
                            <Rejected />
                            </TabPanel>
                        )
                    }
                     {
                         value === 5 && (
                            <TabPanel value={value} index={value} >
                            <AllDailyReports />
                            </TabPanel>
                        )
                    }
 
                    </Grid>
                </Grid>


    </>
  )
}
