import { Dialog, DialogTitle } from '@material-ui/core'
import { Box, DialogActions, DialogContent, Divider, FormGroup, Grid, IconButton, Typography, Tooltip } from '@mui/material'
import React, { useEffect, useContext } from 'react'
import ViewIcon from '../../Invitations/ViewIcon';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { useTranslation } from "react-i18next";
import { countryData, timeZoneData } from './AddressFormData'
import CloseIcon from "@mui/icons-material/Close";
import eyeIcon from '../../../../src/assets/USA_Images/login_images_visitor/eyeIcon.svg'
import { ThemeContext } from '../../../theme/ThemeContext';

const useStyles = makeStyles((theme) => ({
    textarea: {
        resize: "both",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        background: "#3d4977",
        width: "100%",
        padding: "5px",
        textAlign: "center",
        borderRadius: "5px",
        color: "#FFFFFF",
    },
    dialogbox: {
        '& .MuiDialog-paper': {
          minWidth: '120vh',
          borderRadius: '14px',
      },
      },
}));

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: process.env.REACT_APP_BG_SWITCH,
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: "#52d869",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

function SiteViewDialog(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [siteData, setSetData] = React.useState({});
    const { darkMode } = useContext(ThemeContext);
    const [sitedetails, setSiteDetails] = React.useState(

        (process.env.REACT_APP_ENVIRONMENT === 'USA' ?
        {
            [t("COMMON044")]: props.data.name,
            [t("STYPE001")]: props.data.siteTypeName,
            [t("COMMON050")]: props.data.parentName,
            [t("ATYPE009")]: props.data.description,
            [t("ADD001")]: props.data.address.addressLine1,
            [t("ADD002")]: props.data.address.addressLine2,
        }
    :
        {
            [t("COMMON014")]: props.data.name,
            [t("STYPE001")]: props.data.siteTypeName,
            [t("COMMON050")]: props.data.parentName,
            [t("ATYPE009")]: props.data.description,
            [t("ADD001")]: props.data.address.addressLine1,
            [t("ADD002")]: props.data.address.addressLine2,
            [t("ADD017")]: props.data.address.addressLine3,
            [t("ATYPE001")]: props.data.address.addressTypeName
        }
    )

    );
    const [addressDetails, setAddressDetails] = React.useState(

        (process.env.REACT_APP_ENVIRONMENT === 'USA' ?
        {      
            [t("ADD005")]: props.data.address.city,
            [t("ADD004")]: props.data.address.state,
            [t("ADD003")]: props.data.address.country,
            [t("DEALERZIPCODE")]: props.data.address.areaCode,
            [t("ADD010")]: props.data.address.timeZone,
            [t("ATYPE001")]: props.data.address.addressTypeName,
        }
    :
        {
            [t("ADD003")]: props.data.address.country,
            [t("ADD004")]: props.data.address.state,
            [t("ADD005")]: props.data.address.city,
            [t("DEALERZIPCODE")]: props.data.address.areaCode,
            [t("ADD007")]: props.data.address.latitude,
            [t("ADD008")]: props.data.address.longitude,
            [t("ADD009")]: props.data.address.radius,
            [t("ADD010")]: props.data.address.timeZone,
        }
    )    
    
    );

    useEffect(() => {

        {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
        setSiteDetails({...sitedetails, 
            [t("COMMON014")]: props.data.name,
            [t("STYPE001")]: props.data.siteTypeName,
            [t("COMMON050")]: props.data.parentName,
            [t("ATYPE009")]: props.data.description,
            [t("ADD001")]: props.data.address.addressLine1,
            [t("ADD002")]: props.data.address.addressLine2,
        })
    :
        setSiteDetails({...sitedetails, 
            [t("COMMON014")]: props.data.name,
            [t("STYPE001")]: props.data.siteTypeName,
            [t("COMMON050")]: props.data.parentName,
            [t("ATYPE009")]: props.data.description,
            [t("ADD001")]: props.data.address.addressLine1,
            [t("ADD002")]: props.data.address.addressLine2,
            [t("ADD017")]: props.data.address.addressLine3,
            [t("ATYPE001")]: props.data.address.addressTypeName,
        })
    }

    },[props.data]);


    useEffect(() => {

        let timeZone = ''
        timeZoneData.map((item, index) =>
          (item.id === props.data.address.timeZone ? timeZone = item.name : ''));

        let country = ''
        countryData.map((item, index) =>
          (item.id === props.data.address.country ? country = item.name : ''));

        {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
        setAddressDetails({...addressDetails, 

            [t("ADD003")]: props.data.address.country,
            [t("ADD004")]: props.data.address.state,
            [t("ADD005")]: props.data.address.city,
            [t("DEALERZIPCODE")]: props.data.address.areaCode,
            [t("ATYPE001")]: props.data.address.addressTypeName,
            [t("ADD010")]: timeZone,
        })
    :
        setAddressDetails({...addressDetails, 
            
             [t("ADD003")]: props.data.address.country,
              [t("ADD004")]: props.data.address.state,
              [t("ADD005")]: props.data.address.city,
              [t("DEALERZIPCODE")]: props.data.address.areaCode,
              [t("ADD007")]: props.data.address.latitude,
              [t("ADD008")]: props.data.address.longitude,
              [t("ADD009")]: props.data.address.radius,
            //  'Time Zone': props.data.address.timeZone,
          })
        }
      //  window.location.reload(false);
    },[props.data]);

    useEffect(() => {
      
        let timeZone = ''
        timeZoneData.map((item, index) =>
            (item.id === props.data.address.timeZone ? timeZone = item.name : ''));
        
        countryData.map((item, index) =>
        (item.id === props.data.address.country && setAddressDetails
            ({ ...addressDetails, 'Country': item.name, 'Time Zone': timeZone })

        ))
       // window.location.reload(false);
    }, []);


    const [dayLightSaving, setDayLightSaving] = React.useState(props.data.address.dayLightSaving);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };


    const [state, setState] = React.useState(true)

    const handledaylight = (event) => {

        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.checked,
        });
    };

    return (
        <>
         <div>
         <Tooltip title={t('VIEW')}>
            <div onClick={handleOpen}>
            {darkMode ? (
            <img src={eyeIcon} alt="Eye Icon" style={{ width: '24px', height: '24px' }} />
          ) : (
            <ViewIcon />
          )}
          </div>
          </Tooltip>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                className={classes.dialogbox}
                
            >
                    <DialogTitle id="form-dialog-title" style={{ backgroundColor: darkMode ? '#0F273B' : '#FFFFFF'}}>
                        <Box display="flex" m={1.5}>
                            <Box flexGrow={1}>
                                <Typography variant="h3" sx={{color: darkMode ? '#fff' : '#000'}}>
                                    {t("SITE001")}
                                </Typography>
                            </Box>
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    right: 15,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            > <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <Divider />
                <DialogContent style={{ backgroundColor: darkMode ? '#0F273B' : '#FFFFFF', color: darkMode ? '#fff' : ''}}>
                    <Grid container md={12} xs={12} style={{ fontSize: 'large' }}>
                        <Grid container md={5.5} xs={12} spacing={2} >
                            {Object.entries(sitedetails).map(([key, value]) => (
                                <Grid container spacing={2} sx={{ marginLeft: '10px', marginTop: '5px' }}>
                                    <Grid item xs={4} ><Typography variant='body1'>{key}</Typography></Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={7}><Typography style={{ wordBreak: 'break-word' }} variant='inherit'>{value}</Typography></Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid md={1}>
                            <Divider orientation="vertical" sx={{ marginLeft: '20px', marginRight: '30px' }} />
                        </Grid>
                        <Grid container md={5.5} xs={12} spacing={2}>
                            {Object.entries(addressDetails).map(([key, value]) => (
                                <Grid container spacing={2}
                                    sx={{ marginLeft: '10px', marginTop: '5px' }}
                                >
                                    <Grid item xs={4} ><Typography variant='body1'>{key}</Typography></Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={7}><Typography variant='inherit'>{value}</Typography></Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <Grid xs={12}
                                style={{ display: 'flex', margin: '20px 10px' }}
                                container
                                alignItems="center"
                            >
                                <Grid item><Typography variant='body1'>{t("ADD011")}</Typography></Grid> &nbsp;
                                <Grid item style={{ display: 'flex' }}> &nbsp;
                                    <Typography variant='body1'>{t("DAYLIGHTON")}</Typography>/
                                    <Typography variant='body1'>{t("DAYLIGHTOFF")}</Typography>
                                </Grid>
                                <Grid item >
                                    <IOSSwitch
                                        checked={dayLightSaving}
                                        onChange={handledaylight}
                                        name="dayLightSaving"
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SiteViewDialog