import React from "react"
const SwitchImage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.286" height="25.286" viewBox="0 0 25.286 25.286">
      <path id="Path_25" data-name="Path 25" d="M27.62,26.955h-2V8.989a.652.652,0,0,0-.452-.665L20.3,6.287V26.955H18.97V3.665A.665.665,0,0,0,18.3,3H6.327a.665.665,0,0,0-.665.665v23.29h-2a.665.665,0,1,0,0,1.331H27.62a.665.665,0,1,0,0-1.331ZM22.3,10.985h1.331a.665.665,0,0,1,.665.665.679.679,0,0,1-.665.665H22.3a.679.679,0,0,1-.665-.665A.665.665,0,0,1,22.3,10.985Zm0,3.992h1.331a.665.665,0,0,1,0,1.331H22.3a.665.665,0,0,1,0-1.331Zm0,3.992h1.331a.679.679,0,0,1,.665.665.665.665,0,0,1-.665.665H22.3a.665.665,0,0,1-.665-.665.679.679,0,0,1,.665-.665Zm0,3.992h1.331a.665.665,0,0,1,0,1.331H22.3a.665.665,0,0,1,0-1.331Zm-8.65-15.97h1.331a.665.665,0,1,1,0,1.331H13.647a.665.665,0,1,1,0-1.331Zm0,3.992h1.331a.665.665,0,1,1,0,1.331H13.647a.665.665,0,1,1,0-1.331Zm0,3.992h1.331a.665.665,0,1,1,0,1.331H13.647a.665.665,0,0,1,0-1.331Zm0,3.992h1.331a.665.665,0,1,1,0,1.331H13.647a.665.665,0,1,1,0-1.331ZM9.574,6.992h1.411a.665.665,0,0,1,0,1.331H9.654a.665.665,0,1,1,0-1.331Zm0,3.992h1.411a.665.665,0,0,1,0,1.331H9.654a.665.665,0,1,1,0-1.331Zm0,3.992h1.411a.665.665,0,0,1,0,1.331H9.654a.665.665,0,1,1,0-1.331Zm0,3.992h1.411a.665.665,0,0,1,0,1.331H9.654a.665.665,0,1,1,0-1.331Zm3.407,7.985V24.293H11.65v2.662H10.32V23.588a.665.665,0,0,1,.665-.625h2.662a.665.665,0,0,1,.665.665v3.327Z" transform="translate(-3 -3)" fill="#fff" />
    </svg>
  )
}
export default SwitchImage


