import api from "../../config/http-common";

const reject = (payload) => {
  return api.securedAxios().put("/api/v2.0/approve/" + payload.id + "/reject" , payload);
};

const BlockRequestsRejectService = {
  reject,
};
export default BlockRequestsRejectService;
