import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const getSites = (filter) => {
    return api.securedAxios().get('/web/sites' + buildQuery(filter))
}

const getSitesWithoutPaging = () => {
    return api.securedAxios().get('/api/v2.0/site?isPagable=false&sort=createdAt%7Cdesc&caseSensitive=false&operator=AND&pageNumber=0&pageSize=0')
}

const buildQuery = (filter) => {
    let query = "?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=false&operator=AND&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    if (!CommonUtil.isEmptyString(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    if (!CommonUtil.isEmptyString(filter.parentId)) {
        query = query + "&parentId=" + filter.parentId;
    }
    return query;
};

const createSite = (payload) => {
    return api.securedAxios().post('/web/site', payload)
}

const updateSite = (siteId, payload) => {
    return api.securedAxios().put('/web/sites/' + siteId, payload)
}

const deleteSite = (siteIds) => {
    return api.securedAxios().delete('/web/sites/' + siteIds)
}

const addressService = {
    getSites,
    createSite,
    deleteSite,
    updateSite,
    getSitesWithoutPaging
}

export default addressService;