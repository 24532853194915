import React, { useState } from 'react'
import {
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
  } from '@mui/material'
  import { makeStyles } from '@material-ui/core/styles'
  import CloseIcon from '@mui/icons-material/Close'
import CancelButtons from './CancelButtons'
import SubmitButtons from './SubmitButtons'
import Toast from '../ToastContainer/CustomToast'
import CommonUtil from '../../Util/CommonUtils'
import { useTranslation } from 'react-i18next'

  const useStyles = makeStyles((theme) => ({
    purchaseDialog: {
      '& .MuiDialog-paper': {
        minWidth: '800px',
        marginLeft: '250px',
      },
    },
  }))

function ExportDialog(props) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [selectedFormat , setSelectedFormat] = useState('');
    const options = [
        {label:t('EXPORT_LOWER_CASE')}
    ]

    const handleDownload = () => {
        if(CommonUtil.isEmptyString(selectedFormat)){
            Toast(t('PLEASESELECTFORMATERROR'), 'error')
            return;
        }
        if(!selectedFormat.toLowerCase() === 'excel'){
                Toast(t('PLEASESELECTCORRECCTFORMAT'), 'error')
                return;
        }
        props.handleSubmit();
    }
  return (
   <>
    <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={true}
        disable
        PaperProps={{
          style: {
            borderRadius: '20px !important',
          },
        }}
        className={classes.purchaseDialog}
      >
        <DialogTitle variant="h4" onClick={props.handleClose}>
          <IconButton
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {t('DOWNLOADHEADER')}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
            <Grid padding={3}>
            <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={options}
                    value = {selectedFormat}
                    sx={{ width: "100%" }}
                    onChange={(event , newValue) => {
                        if(newValue){
                            setSelectedFormat(newValue.label)
                        }
                        else{
                            setSelectedFormat("")
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label={t('SELECTEXCELFORMAT')} />}
                    />
            </Grid>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <SubmitButtons variant="contained" onClick={handleDownload}>
            <Typography variant="body1" style={{ textTransform: 'none' }}>
             {t('DOWNLOADHEADER')}
            </Typography>
          </SubmitButtons>

          <CancelButtons onClick={props.handleClose}>
            <Typography variant="body1" style={{ textTransform: "none" }}>
              {t('COMMON008')}
            </Typography>
          </CancelButtons>

        </DialogActions>
      </Dialog>
   </>
  )
}

export default ExportDialog