import React from 'react'
import { Tooltip, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

const DownloadInvoiceIcon = () => {
  const [isShown, setIsShown] = React.useState(false)
  const { t } = useTranslation()
  return (
    <>
      <Tooltip title={t('RECEIPT')}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
            >
              <g id="Layer_2" data-name="Layer 2" transform="translate(-4 -3)">
                <g id="download">
                  <rect
                    id="Rectangle_6717"
                    data-name="Rectangle 6717"
                    width="16"
                    height="2"
                    rx="1"
                    transform="translate(4 18)"
                    fill="#c0c7cc"
                  />
                  <rect
                    id="Rectangle_6718"
                    data-name="Rectangle 6718"
                    width="4"
                    height="2"
                    rx="1"
                    transform="translate(4 20) rotate(-90)"
                    fill="#c0c7cc"
                  />
                  <rect
                    id="Rectangle_6719"
                    data-name="Rectangle 6719"
                    width="4"
                    height="2"
                    rx="1"
                    transform="translate(18 20) rotate(-90)"
                    fill="#c0c7cc"
                  />
                  <path
                    id="Path_7248"
                    data-name="Path 7248"
                    d="M12,15a1,1,0,0,1-.58-.18L7.42,12a1,1,0,1,1,1.16-1.63L12,12.76l3.4-2.56a1,1,0,1,1,1.2,1.6l-4,3a1,1,0,0,1-.6.2Z"
                    fill="#c0c7cc"
                  />
                  <path
                    id="Path_7249"
                    data-name="Path 7249"
                    d="M12,13a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0v8A1,1,0,0,1,12,13Z"
                    fill="#c0c7cc"
                  />
                </g>
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
            >
              <g id="Layer_2" data-name="Layer 2" transform="translate(-4 -3)">
                <g id="download">
                  <rect
                    id="Rectangle_6717"
                    data-name="Rectangle 6717"
                    width="16"
                    height="2"
                    rx="1"
                    transform="translate(4 18)"
                    fill="#c0c7cc"
                  />
                  <rect
                    id="Rectangle_6718"
                    data-name="Rectangle 6718"
                    width="4"
                    height="2"
                    rx="1"
                    transform="translate(4 20) rotate(-90)"
                    fill="#c0c7cc"
                  />
                  <rect
                    id="Rectangle_6719"
                    data-name="Rectangle 6719"
                    width="4"
                    height="2"
                    rx="1"
                    transform="translate(18 20) rotate(-90)"
                    fill="#c0c7cc"
                  />
                  <path
                    id="Path_7248"
                    data-name="Path 7248"
                    d="M12,15a1,1,0,0,1-.58-.18L7.42,12a1,1,0,1,1,1.16-1.63L12,12.76l3.4-2.56a1,1,0,1,1,1.2,1.6l-4,3a1,1,0,0,1-.6.2Z"
                    fill="#c0c7cc"
                  />
                  <path
                    id="Path_7249"
                    data-name="Path 7249"
                    d="M12,13a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0v8A1,1,0,0,1,12,13Z"
                    fill="#c0c7cc"
                  />
                </g>
              </g>
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  )
}
export default DownloadInvoiceIcon
