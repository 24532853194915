import api from "../../config/http-common";

const DoorList = (query) => {
    return api.securedAxios().post('/web/door?pageNumber=' + query.page + '&pageSize=' + query.size);
};

const getDoors = (query) => {
    return api.securedAxios().get('/web/device/doors');
};

const doorControl = (payload) => {
    return api.securedAxios().post('/web/device/doorControl/', payload );
};



const addDoorToAccessLevel = (payload) => {
    const body = { doors : payload.doors}
    return api.securedAxios().post('/web/door/' + payload.accessGroupId + '/doors', body);
};

const DoorService = {
    DoorList,
    getDoors,
    doorControl,
    addDoorToAccessLevel
};

export default DoorService;