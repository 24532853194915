import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Tooltip, Typography } from "@mui/material";

import { ReactComponent as DeleteVisitIcon } from "../../../src/assets/DeleteVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { deleteUserRoles } from "../../slices/UserRole/UserRoleSlice";
import Toast from "../../components/ToastContainer/CustomToast";

export default function DeleteUserRole(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = () => {
    const roleId = props.DeleteId;

        dispatch(deleteUserRoles(roleId))
            .unwrap()
            .then((data) => {
                if (data.code === "UASI0043") {
                    Toast((data.message), 'success');
                    props.delete();
                    handleClose(false); 
                }
                else {
                    Toast(t(data.message), 'error');
                }
            })
            .catch((er) => { });
  };
  const handleTextOverflow = (text) => {
    if (text && text.length > 10) {
        return text.substring(0, 30) + '...';
    } else {
    return text;
}
}
  return (
    <>
      <DialogBox
        Header={t("COMMON004")}
        acceptText={t("CONFIRM")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
          style={{ padding: "20px" }}
        >
          <Grid>
            <DeleteVisitIcon />
          </Grid>
          <Grid>
            <Grid
              style={{
                wordWrap: "break-word",
                opacity: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                fontSize: "17px",
                marginTop: "15px",
              }}
            >
              <Typography style={{ color: "#242424", marginRight: "2px", fontSize: '15px' }}>
                {t("DELETEROLEMSG")}
              </Typography>
              <Tooltip title={props.DeleteRoleName}>
              <Typography style={{ fontWeight: "bold", marginRight: "3px", fontSize: '15px'}}>
                {handleTextOverflow(props.DeleteRoleName)}
              </Typography>
              </Tooltip>
              <Typography style={{ color: "#242424", marginRight: "2px", fontSize: '15px'}}>
               ?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
