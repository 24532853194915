import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Avatar from '@mui/material/Avatar'
import sampleImg from '../VisitorActionsComponents/sample.jpg'
import './profileCard.css'
import { Divider, Grid } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { getSiteById } from '../../slices/Site/SiteSlice'
import { useDispatch } from 'react-redux'
import CommonUtil from '../../Util/CommonUtils'
import { Tooltip } from '@material-ui/core'
import ViewIcon from '../../Pages/Invitations/ViewIcon'
import { useTranslation } from 'react-i18next'
import { getAllVisitorDocuments } from '../../slices/VisitorDocuments/VisitorDocumentSlice'
import SimpleDialogDemo from '../../Pages/Approvals/ImageDailogBox'
import eyeIcon from '../../../src/assets/USA_Images/login_images_visitor/eyeIcon.svg'
import { ThemeContext } from '../../theme/ThemeContext';

const BootstrapDialog = styled(Dialog)(({ theme, darkMode }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    overflowY: 'clip',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    overflowY: 'visible',
    borderRadius: '20px',
    minWidth: '800px',
    height: '500px',
    opacity: 1,
    backgroundColor: darkMode ? '#0F273B' : '#FFFFFF',
  },
  '& .MuiDialogContent-root ': {
    padding: '16px 24px',
    position: ' relative',
    bottom: '80px',
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props
  return (
    <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

function BlockedVisitorProfile(props) {
  const rowDataMode = props.rowData.visitMode
  const dispatch = useDispatch()
  const [imageArray, setImageArray] = useState([])
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const { darkMode } = useContext(ThemeContext);
  const handleClickOpen = () => {
    setOpen(true)
    handleClickOpenVisitorDocuments()
  }

  const handleClickOpenVisitorDocuments = () => {
    if (!CommonUtil.isEmptyString(props.rowData)) {
      loadVisitorDocumentObjectKeys(props.rowData)
    }
    setOpen(true)
  }

  const loadVisitorDocumentObjectKeys = () => {
    dispatch(getAllVisitorDocuments(props))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4000' && data.data.length > 0) {
          const imageUrls = data.data
            .filter((imageData) => 'url' in imageData)
            .map((imageData) => imageData.url)
          setImageArray(imageUrls)
        }
      })
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 25) + '...'
    } else {
      return text
    }
  }

  return (
    <div>
      <Tooltip title={t('VIEW')}>
      <div onClick={handleClickOpen}>
      {darkMode ? (
            <img src={eyeIcon} alt="Eye Icon" style={{ width: '24px', height: '24px' }} />
          ) : (
            <ViewIcon />
          )}
      </div>
      </Tooltip>
      <Grid>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle onClose={handleClose}>
            <Avatar
              alt={props.rowData.visitor.firstName}
              src={
                props.rowData.visitor.profilePhotoURL !== undefined
                  ? props.rowData.visitor.profilePhotoURL
                  : null
              }
              sx={{
                width: 150,
                height: 150,
                bottom: '80px',
                margin: 'auto',
                border: 'solid #FFFF',
                borderWidth: '7px',
              }}
            />
            <div className="CssTextProfileViewPageName" style={{color: darkMode ? '#fff' : ''}}> 
              {props.rowData.visitor.lastName === null
                ? props.rowData.visitor.firstName
                : props.rowData.visitor.firstName +
                  ' ' +
                  props.rowData.visitor.lastName}
            </div>
            <div className="CssTextProfileViewPagePhone" style={{color: darkMode ? '#fff' : ''}}>
              {props.rowData.visitor.phone}
            </div>
          </BootstrapDialogTitle>

          <Divider variant="inset" className="MuiDividerCss"  sx={{backgroundColor: darkMode ? '#fff' : ''}}/>
          <DialogContent style={{ marginLeft: '70px', marginRight: '70px', color: darkMode ? '#fff' : '' }}>
            <Grid
              container
              spacing={12.25}
              className="CssTextProfileViewPageContent"
              marginTop={0.5}
              style={{color: darkMode ? '#fff' : ''}}
            >
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  {t('COMMON042')}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Tooltip title={props.rowData.visitor.email}>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {' '}
                  {handleTextOverflow(props.rowData.visitor.email)}
                </Grid>
                </Tooltip>
              </Grid>

              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  {t('commonsTextFeildHost')}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.hostName}
                </Grid>
              </Grid>

              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  {t('PURPOSETYPE')}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.purposeType?.name}
                </Grid>
              </Grid>

              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  {t('COMMON068')}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.visitorType?.name}
                </Grid>
              </Grid>

              {/* <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  Reason
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.blackListId.restrictReason}
                </Grid>
              </Grid> */}

              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  {t('COMMON001')}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  <span
                    style={{
                      color: '#D53033',
                      backgroundColor: '#D5303326',
                      opacity: 1,
                    }}
                  >
                    {props.rowData.status === 'BLOCKED' ? 'Blacklist' : ''}
                  </span>
                </Grid>
              </Grid>

              {rowDataMode === 'WALKIN_REGISTARTION' ||
              rowDataMode === 'SELF_REGISTARTION' ||
              rowDataMode === 'WALKIN_REGISTARTION_KIOSK' ? (
                <Grid container spacing={2} p={1.25}>
                  <Grid item xs={3}>
                    Captured Images
                  </Grid>
                  <Grid item xs={3}>
                    :
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    direction="row"
                    spacing={1}
                    marginLeft={-15}
                  >
                    {imageArray.map((imageUrl, index) => (
                      <Grid item key={index}>
                        <SimpleDialogDemo
                          button={
                            <Avatar
                              style={{
                                cursor: 'pointer',
                                width: '60px',
                                height: '60px',
                                borderRadius: '50%',
                                marginRight: '5px', // Adjust margin to control spacing
                              }}
                              alt={`Image ${index + 1}`}
                              src={imageUrl}
                              onClick={handleClickOpenVisitorDocuments}
                            />
                          }
                          child={
                            <Avatar
                              style={{
                                width: '300px',
                                height: '300px',
                                cursor: 'pointer',
                              }}
                              alt={`Image ${index + 1}`}
                              src={imageUrl}
                              onClick={handleClickOpenVisitorDocuments}
                            />
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
    </div>
  )
}

export default BlockedVisitorProfile
