import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
  let query = "";

  if (!CommonUtil.isEmptyString(filter.firstName)) {
    query = query + "&firstName=" + filter.firstName;
  }
  if (!CommonUtil.isEmptyString(filter.lastName)) {
    query = query + "&lastName=" + filter.lastName;
  }
  if (!CommonUtil.isEmptyString(filter.email)) {
    query = query + "&email=" + filter.email;
  }
  if (!CommonUtil.isEmptyString(filter.phoneNumber)) {
    query = query + "&phoneNumber=" + filter.phoneNumber;
  }
  if (!CommonUtil.isEmptyString(filter.contactEmail)) {
    query = query + "&contactEmail=" + filter.contactEmail;
  }
  if (!CommonUtil.isEmptyString(filter.contactPhoneNumber)) {
    query = query + "&contactPhoneNumber=" + filter.contactPhoneNumber;
  }
  if (!CommonUtil.isEmptyString(filter.purposeTypeId)) {
    query = query + "&purposeTypeId=" + filter.purposeTypeId;
  }
  return query;
};

const getAllWatchlistTemplate = (filter) => {
  return api.securedAxios().get('/web/watchlist' + '?isPagable=true&pageNumber=' + filter.page + '&pageSize=' + filter.rowsPerPage + buildQuery(filter.filter));
}

const createWatchlistTemplate = (payload) => {
  return api.securedAxios().post('/web/watchlist', payload);
}

const updateWatchlistTemplate = (payload) => {
  return api.securedAxios().put('/web/watchlist/' + payload.watchListId, payload);
}

const deleteWatchlistTemplate = (payload) => {
  return api.securedAxios().delete('/web/watchlist?watchListId=' + payload);
}

const watchlistGlobalService = {
  getAllWatchlistTemplate,
  createWatchlistTemplate,
  updateWatchlistTemplate,
  deleteWatchlistTemplate,
}

export default watchlistGlobalService;
