import React, { useContext, useEffect } from 'react'
import DailyReportTabs from './DailyReportTabs'
import { useTranslation } from 'react-i18next';
import HeaderToolbar from '../../components/HeaderToolbar';
import { ThemeContext } from '../../theme/ThemeContext';

export default function DailyReports() {

    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);

    useEffect(() => {
        if (darkMode) {
          document.body.classList.add('darkMode');
        } else {
          document.body.classList.remove('darkMode');
        }
        return () => {
          document.body.classList.remove('darkMode');
        };
      }, [darkMode]);

    return (
        <>
            <HeaderToolbar
                title={process.env.REACT_APP_ENVIRONMENT === 'USA'
                    ? t("DAILYREPORTLBL")
                    : t("labelVisitorLogTitle")}

            />
            <DailyReportTabs />
        </>
    )
}
