import BrowserLanguage from '../../components/Util/BrowserLanguage'
import {
  REACT_DEALER,
  REACT_SUBSCRIPTION_MANAGEMENT,
  REACT_TRANSACTION_DETAILS,
  REACT_DEALER_REPORT,
  REACT_ADMIN_TRANSACTIONS,
  REACT_DASHBOARD_PANEL,
  REACT_URL_USERS,
  REACT_URL_DISCOUNTS,
  REACT_DATEFORMAT_ADMIN_DEALER,
  REACT_KEY_CONFIGURATION,
  REACT_EMAIL_REPORT,
  REACT_DEVICE_TYPE,
} from '../../actions/EndPoints'
import { ReactComponent as Dashboard_Icon } from '../../assets/images/dashboard-icons/dashboard_icon.svg'
import vis_Dashboard_Icon from '../../assets/USA_Images/navitem_images/Dashboard'
import dealerIcon from '../../assets/USA_Images/navitem_images/dealerIcon'
import ReportIcon from '../../assets/USA_Images/navitem_images/ReportIcon'
import Device_Icon from '../../assets/USA_Images/navitem_images/settingWhite'

let language = BrowserLanguage.getDefaultLanguage()

const dataEn = [
  {
    id: '0',
    name: 'Dashboard',
    link: `${REACT_DASHBOARD_PANEL}`,
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? vis_Dashboard_Icon
        : Dashboard_Icon,
  },

  {
    id: '1',
    name: 'Dealer',
    Icon: dealerIcon,
    items: [
      {
        id: '2',
        name: 'Dealer',
        link: `${REACT_DEALER}`,
      },
      {
        id: '3',
        name: 'Subscription Plan',
        link: `${REACT_SUBSCRIPTION_MANAGEMENT}`,
      },
    ],
  },
  {
    id: '111',
    name: 'Report',
    Icon: ReportIcon,
    items: [
      {
        id: '112',
        name: 'Dealer Details',
        link: `${REACT_DEALER_REPORT}`,
      },
      {
        id: '113',
        name: 'Transaction Details',
        link: `${REACT_ADMIN_TRANSACTIONS}`,
      },
    ],
  },
  {
    id: '31',
    name: 'Settings',
    Icon: Device_Icon,
    items: [
      {
        id: '311',
        name: 'User',
        link: `${REACT_URL_USERS}`,
      },
      {
        id: '312',
        name: 'Discount',
        link: `${REACT_URL_DISCOUNTS}`,
      },
      {
        id: '313',
        name: 'Date Format',
        link: `${REACT_DATEFORMAT_ADMIN_DEALER}`,
      },
      {
        id: '314',
        name: 'Email Notification',
        link: `${REACT_EMAIL_REPORT}`,
      },
      {
        id: '316',
        name: 'Key Assignment',
        link: `${REACT_KEY_CONFIGURATION}`,
      },
      {
        id: '317',
        name: 'Device Type',
        link: `${REACT_DEVICE_TYPE}`,
      },
    ],
  },
]

const dataEs = [
  {
    id: '0',
    name: 'Panel',
    link: `${REACT_DASHBOARD_PANEL}`,
    Icon: vis_Dashboard_Icon,
  },
  {
    id: '1',
    name: 'Distribuidora',
    Icon: dealerIcon,
    items: [
      {
        id: '2',
        name: 'Distribuidora',
        link: `${REACT_DEALER}`,
      },
      {
        id: '3',
        name: 'Plan de suscripción',
        link: `${REACT_SUBSCRIPTION_MANAGEMENT}`,
      },
    ],
  },
  {
    id: '111',
    name: 'Informe',
    Icon: ReportIcon,
    items: [
      {
        id: '112',
        name: 'Detalles del distribuidor',
        link: `${REACT_DEALER_REPORT}`,
      },
      {
        id: '113',
        name: 'Detalles de la transacción',
        link: `${REACT_ADMIN_TRANSACTIONS}`,
      },
    ],
  },
  {
    id: '31',
    name: 'Ajustes',
    Icon: Device_Icon,
    items: [
      {
        id: '311',
        name: 'Usuaria',
        link: `${REACT_URL_USERS}`,
      },
      {
        id: '312',
        name: 'Descuento',
        link: `${REACT_URL_DISCOUNTS}`,
      },
      {
        id: '313',
        name: 'Formato de fecha',
        link: `${REACT_DATEFORMAT_ADMIN_DEALER}`,
      },
      {
        id: '314',
        name: 'Notificación de correo electrónico',
        link: `${REACT_EMAIL_REPORT}`,
      },
      // {
      //   id: '315',
      //   name: 'Método de pago',
      //   link: `${REACT_URL_PAYMENT_METHOD}`,
      // },
      {
        id: '316',
        name: 'Configuración clave',
        link: `${REACT_KEY_CONFIGURATION}`,
      },
      {
        id: '317',
        name: 'Tipo de dispositivo',
        link: `${REACT_DEVICE_TYPE}`,
      },
    ],
  },
]

const dataZh = [
  {
    id: '0',
    name: 'Dashboard',
    link: `${REACT_DASHBOARD_PANEL}`,
    Icon: vis_Dashboard_Icon,
  },
  {
    id: '1',
    name: 'Dealer',
    Icon: dealerIcon,
    items: [
      {
        id: '2',
        name: 'Dealer',
        link: `${REACT_DEALER}`,
      },
      {
        id: '3',
        name: 'Subscription Plan',
        link: `${REACT_SUBSCRIPTION_MANAGEMENT}`,
      },
    ],
  },
  {
    id: '111',
    name: 'Report',
    Icon: ReportIcon,
    items: [
      {
        id: '112',
        name: 'Dealer Details',
      },
      {
        id: '113',
        name: 'Transaction Details',
        link: `${REACT_TRANSACTION_DETAILS}`,
      },
    ],
  },
  {
    id: '31',
    name: 'Settings',
    Icon: Device_Icon,
    items: [
      {
        id: '311',
        name: 'User',
      },
      {
        id: '312',
        name: 'Discount',
      },
    ],
  },
]

export default language === 'en' ? dataEn : language === 'es' ? dataEs : dataZh
