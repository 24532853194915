import { Button, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import FormGroup from '@mui/material/FormGroup'
import Tab from '@mui/material/Tab'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CommonUtil from '../../Util/CommonUtils'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import HeaderToolbar from '../../components/HeaderToolbar'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  CreateCustomerCompany,
  updateCustomerAccount,
} from '../../slices/Customer/CustomerSlice'
import { getCustomerSubscription } from '../../slices/Subscription/SubscriptionSlice'
import { getUserInfoPro } from '../../slices/User/UserSlice1'
import PaymentIcon from '../Dealer/PaymentIcon'
import ProfileIcon from '../Dealer/ProfileIcon'
import ProfileUplod from '../Employee/UploadImg'
import { countryData } from '../Person/AddressFormData'
import isoCountries from 'i18n-iso-countries'
import PlanPurchaseDialogBox from '../../components/DialogBoxComponent/PlanPurchaseDialogBox'
import SelectPlanDialogBox from '../../components/DialogBoxComponent/SelectPlanDialogBox'
import { getCompanyInfo } from '../../slices/Company/CompanySlice'
import SelfRegistrationCustomPhone from '../OnBoardV2/SelfRegistrationCustomPhone'

function CreateCustomer(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const [parentCompanyCountry, setParentCompanyCountry] = useState('')
  const [customerSubscription, setCurrentSubscription] = useState([])
  const [assignedSubscription, setAssignedSubscription] = useState()
  const [payload, setPayload] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    name: '',
    address: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    profile: '',
    level: '',
    companyLogo: '',
    status: '',
    companyId: '',
    companyImageData: '',
    billingStartDate: CommonUtil.formatToUtc(),
    billingEndDate: CommonUtil.formatToUtc(),
    paymentGenerationDate: CommonUtil.formatToUtc(),
    subscriptionId: '',
    code: '',
  })
  const [profile, setProfile] = useState('')
  const [InavalidprofilePhoto, setInavalidprofilePhoto] = useState(false)
  const [countries, setCountries] = React.useState([])
  const [tabValue, setTabValue] = useState()
  const [phoneInput, setPhoneInput] = useState('')
  const [billingPhoneInput, setBillingPhoneInput] = useState('')
  const [createCustomer, setcreateCustomer] = useState(true)
  const [authentication, setAuthentication] = useState(true)
  const [autoRenewal, setAutoRenewal] = useState('')
  const [expiryRemind, setExpiryRemind] = useState('')
  const [profileImage, setProfileImage] = useState()
  const [isEditable, setIsEditable] = useState(false)
  const [disable, setDisable] = useState(true)
  const [customerProfile, setCustomerProfile] = useState('customerProfile')
  const [planExpirationReminder, setPlanExpirationReminder] = useState(false)
  const [companyCountry] = useState(props.location.country)
  const [subscriptionId, setSubscriptionId] = useState('')
  const [currentFilter, setCurrentFilter] = useState({
    companyId: props.location.state ? props.location.state.id : '',
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CHECKED_OUT',
      'CANCELLED',
      'INITIATED_FOR_BLOCKED',
      'BLOCKED',
      'REJECTED',
      'MISSED',
      'COMPLETED',
      'LOGGED',
    ],
    visitMode: [
      'INVITATION',
      'WALKIN_REGISTARTION',
      'SELF_REGISTARTION',
      'WALKIN_REGISTARTION_KIOSK',
    ],
  })
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const [selectedCountry, setSelectedCountry] = useState(null)
  const [checkConvertToYearlyPlan, setCheckConvertToYearlyPlan] = useState(
    false,
  )
  const [showConvertPlanYearly, setShowConvertPlanYearly] = useState(false)
  const [state, setState] = useState({
    accountState: false,
  })
  const [dealerSubscriptionId, setDealerSubscriptionId] = useState('')
  const [subscriptionName, setSubscriptionName] = useState('')
  const [discount, setDiscount] = useState()
  const [openPlanSelectDialog, setOpenPlanSelectDialog] = useState(false)
  const [openPlanPurchaseDialog, setOpenPlanPurchaseDialog] = useState(false)
  const [showPlanAssignment, setShowPlanAssignment] = useState(true)
  const [clearPlanTextField, setClearPlanTextField] = useState(false)

  const [error, setError] = useState({
    id: false,
    firstName: false,
    LastName: false,
    email: false,
    phone: false,
    companyName: false,
    companyCode: false,
    addressLine1: false,
    addressLine2: false,
    country: false,
    state: false,
    city: false,
    postCode: false,
    profile: false,
    subscription: false,
    subscriptionName: false,
    code: false,
  })

  useEffect(() => {
    if (props.location.state != null) {
      dispatch(getCustomerSubscription(props.location.state.id))
        .unwrap()
        .then((res) => {
          if (res.code === 'MSGS4032' && res.data) {
            setCurrentSubscription(res.data.subscriptionPurchaseId)
            setAssignedSubscription(res.data.subscriptionPurchaseId)
            setSubscriptionId(res.data.subscriptionPurchaseId.id)
          }
        })
    }
  }, [])

  useEffect(() => {
    if (
      props.location.state === undefined ||
      props.location.state.phone === ''
    ) {
      loadParentCompany()
    }
  }, [props.location.state])

  const loadParentCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setParentCompanyCountry(countryCode)
          }
        }
      })
      .catch((er) => {})
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  useEffect(() => {
    if (props.location.state != null) {
      setIsEditable(true)
      setPayload({
        ...payload,
        email: props.location.state.email,
        phoneCountryCode: props.location.state.phone
          ? props.location.state.phone.split('-')[0]
          : '',
        phone: props.location.state.phone
          ? props.location.state.phone.split('-')[1]
          : '',
        name: props.location.state.name,
        address: props.location.state.address,
        country: props.location.state.country,
        state: props.location.state.state,
        city: props.location.state.city,
        pincode: props.location.state.pincode,
        companyId: props.location.state.id,
        code: props.location.state.code,
        subscriptionId: subscriptionId ? subscriptionId : '',
      })
      setState({
        ...state,
        accountState: props.location.state.status === 'ACTIVE' ? true : false,
      })
      setProfile(props.location.state.imagePreSignedURL)
      setPhoneInput(props.location.state.phone)
      setBillingPhoneInput(props.location.state.billingUserPhone)
      setSubscriptionName(props.location.state.subscriptionName)
      const selectedCountry = countryData.find(
        (country) => country.name === props.location.state.country,
      )
      setSelectedCountry(selectedCountry)
      setShowPlanAssignment(
        props.location.state.subscriptionName ? false : true,
      )
      setClearPlanTextField(true)
    }
  }, [!payload.firstName && !payload.lastName && !payload.subscriptionId])

  useEffect(() => {
    if (props.location.state != null) {
      dispatch(getUserInfoPro(props.location.state.email))
        .unwrap()
        .then((res) => {
          if (res.code === 'UASI0033') {
            setPayload({
              ...payload,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
            })
          }
        })
    }
  }, [])

  useEffect(() => {
    setCountries(countryData)
    setInavalidprofilePhoto(false)
  }, [])

  const handleChange = (event) => {
    const name = event.target.name
    setcreateCustomer(event.target.checked)
    setAuthentication(event.target.checked)
    setAutoRenewal(event.target.checked)
    setExpiryRemind(event.target.checked)
    setPayload({
      ...payload,
      [name]: event.target.value,
    })
  }

  const handlePlanAssignmentChange = (event) => {}
  const handleCheckBoxSelfProfilePhoto = () => {
    setPlanExpirationReminder(!planExpirationReminder)
  }

  const handleProfilePicture = (data) => {
    setProfile(data.base64)
    setProfileImage(data.file)

    setPayload({
      ...payload,
      companyLogo: payload.name,
      companyImageData: data.base64,
    })
  }

  const onNext = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      Toast(t('USERPROFSCREEN007'), 'error')
      setError({ ...error, firstName: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.email)) {
      Toast(t('EMAILVALIDATE'), 'error')
      setError({ ...error, email: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.name)) {
      Toast(t('DEALERCOMPANYERROR'), 'error')
      setError({ ...error, name: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.address)) {
      Toast(t('DEALERADDRESSERROR'), 'error')
      setError({ ...error, address: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.code)) {
      Toast(t('USERPROFSCREEN015'), 'error')
      setError({ ...error, code: true })
      return false
    }
    if (payload.code.length > 36) {
      Toast(t('USERPROFSCREEN016'), 'error')
      setError({ ...error, code: true })
      return false
    }

    const validatePayloadCode = (code) => {
      const pattern = /^[a-zA-Z0-9]([-a-zA-Z0-9_]*[a-zA-Z0-9])?$/
      return pattern.test(code)
    }

    if (!validatePayloadCode(payload.code)) {
      Toast(t('USERPROFSCREEN017'), 'error')
      setError({ ...error, code: true })
      return false
    }

    if (!InavalidprofilePhoto) {
    } else {
      Toast('Image size should be less than 2MB', 'error')
    }

    setSelectedOption('2')
  }

  const onCancel = () => {
    history.push('/vis/customerList')
  }
  const [selectedOption, setSelectedOption] = React.useState('1')
  const handleChanges = (event, newValue) => {
    setSelectedOption(newValue)
  }

  const [subscriptions, setSubscriptions] = useState([])

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value)
    setPayload({
      ...payload,
      phoneCountryCode: '+' + data.dialCode,
      phone: value.toString().replace(data.dialCode, ''),
    })
    setError({ phone: false })
  }

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.data.imagePreSignedURL)
    bodyFormData.append('file', profileImage)
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {})
      .catch(function (response) {})
  }

  const handleConfirm = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      Toast(t('USERPROFSCREEN007'), 'error')
      setError({ ...error, firstName: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.email)) {
      Toast(t('EMAILVALIDATE'), 'error')
      setError({ ...error, email: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.name)) {
      Toast(t('DEALERCOMPANYERROR'), 'error')
      setError({ ...error, name: true })
      return false
    }

    if (CommonUtil.isEmptyString(payload.address)) {
      Toast(t('DEALERADDRESSERROR'), 'error')
      setError({ ...error, address: true })
      return false
    }

    if (CommonUtil.isEmptyString(subscriptionName)) {
      Toast(t('DEALERSUBSCRIPTIONEMPTY'), 'error')
      setError({ ...error, subscriptionName: true })
      return false
    }

    const dto = {
      ...payload,
      status: state.accountState ? 'ACTIVE' : 'INACTIVE',
      companyLogo: payload.companyImageData ? payload.name : '',
      subscriptionId: subscriptionId ? subscriptionId : '',
    }
    if (props.location.state === undefined) {
      dispatch(CreateCustomerCompany(dto))
        .unwrap()
        .then(async (data) => {
          switch (data.code) {
            case 'MSGS4012':
              if (!CommonUtil.isEmptyString(data.data.imagePreSignedURL)){
                   await  uploadProfilePhoto(data)
              }
              Toast(t('CREATECUSTOMERSUCCESS'), 'success')
              history.push('/vis/customerList')
              break
            case 'MSGE4015':
              Toast(t('COMPANYNAMEALREADYPERSENT'), 'error')
              break
            default:
              Toast(data.message, 'error')
              break
          }
        })
    } else {
      dispatch(updateCustomerAccount(dto))
        .unwrap()
        .then(async (data) => {
          switch (data.code) {
            case 'MSGS4013':
              if (!CommonUtil.isEmptyString(data.data.imagePreSignedURL)){
                  await  uploadProfilePhoto(data)
              }
              Toast(t('UPDATECUSTOMERSUCCESS'), 'success')
              history.push('/vis/customerList')
              break
            case 'MSGE4015':
              Toast(t('COMPANYNAMEALREADYPERSENT'), 'error')
              break
            default:
              Toast(data.message, 'error')
              break
          }
        })
    }
  }

  const handleCountryChange = (event, value) => {
    setSelectedCountry(value)
    setPayload({
      ...payload,
      country: value.name,
    })
  }

  const handlePlanCheckBox = () => {
    setShowConvertPlanYearly(false)
    setCheckConvertToYearlyPlan(false)
  }

  const handleClearField = () => {
    setSubscriptionName('')
    setShowPlanAssignment(true)
    setClearPlanTextField(false)
  }

  const handlePurchasedPlan = (data) => {
    setPayload({
      ...payload,
      subscriptionId: data.id,
    })
    setSubscriptionId(data.id)
    setSubscriptionName(data.subscriptionName)
    setShowPlanAssignment(false)
    setClearPlanTextField(true)
    setOpenPlanPurchaseDialog(false)
  }

  const handlePlanSelected = (data) => {
    setPayload({
      ...payload,
      subscriptionId: data.id,
    })
    setSubscriptionId(data.id)
    setSubscriptionName(data.subscriptionName)
    setShowPlanAssignment(false)
    setClearPlanTextField(true)
  }

  return (
    <>
      <HeaderToolbar
        back_button={true}
        title={
          isEditable
            ? t('LABELNAVITEMSEDITCUSTOMER')
            : t('LABELNAVITEMSCREATECUSTOMER')
        }
      />

      <Paper>
        <TabContext value={selectedOption}>
          <Box sx={{ ml: 3 }}>
            <TabList
              onChange={handleChanges}
              aria-label="tab"
              style={{ boxShadow: 'none' }}
              indicatorColor="primary"
            >
              <Tab
                sx={{ ml: 3 }}
                icon={<ProfileIcon />}
                iconPosition="start"
                label={t('DEALERTABPROFILE')}
                value="1"
                style={{ textTransform: 'capitalize' }}
                onClick={handlePlanCheckBox}
              />
              <Tab
                sx={{ ml: 8 }}
                icon={<PaymentIcon />}
                iconPosition="start"
                label={t('DEALERTABSUBSCRIPTION')}
                value="2"
                style={{ textTransform: 'capitalize' }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container p={2}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                md={4}
                sm={12}
              >
                <Grid sm={7}>
                  <ProfileUplod
                    onsetProfile={(profile) => handleProfilePicture(profile)}
                    profilePhoto={profile}
                    uploadPhoto={customerProfile}
                  />
                </Grid>
              </Grid>
              <Grid container md={8} sm={12} spacing={3}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CustomTextfeild
                    label={t('DEALERFIRSTNAME')}
                    name={'firstName'}
                    value={payload.firstName}
                    required={true}
                    error={error.firstName}
                    handleChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CustomTextfeild
                    label={t('DEALERLASTNAME')}
                    name={'lastName'}
                    value={payload.lastName}
                    error={error.lastName}
                    handleChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CustomTextfeild
                    label={t('DEALEREMAIL')}
                    name={'email'}
                    value={payload.email}
                    error={error.email}
                    disabled={isEditable}
                    validation="email"
                    required={true}
                    handleChange={handleChange}
                    inputProps={{ maxLength: 150 }}
                  />
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ margin: '16px 0px' }}
                >
                  <SelfRegistrationCustomPhone
                    placeholder={t('CUSTDATABLE03')}
                    value={phoneInput}
                    country={
                      parentCompanyCountry === '' ? 'us' : parentCompanyCountry
                    }
                    specialLabel={false}
                    handleChange={(value, data, event, formattedValue) =>
                      handlePhoneChange(value, data, event, formattedValue)
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CustomTextfeild
                    label={t('DEALERCOMPANYNAME')}
                    name={'name'}
                    value={payload.name}
                    required={true}
                    error={error.name}
                    handleChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CustomTextfeild
                    label={t('DEALERADDRESSLINE')}
                    name={'address'}
                    value={payload.address}
                    error={error.address}
                    handleChange={handleChange}
                    inputProps={{ maxLength: 200 }}
                    required={true}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CustomTextfeild
                    label={t('DEALERCITY')}
                    name={'city'}
                    value={payload.city}
                    handleChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CustomTextfeild
                    label={t('DEALERSTATE')}
                    name={'state'}
                    value={payload.state}
                    handleChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ mt: '16px' }}
                >
                  <Autocomplete
                    options={countries}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label={t('DEALERCOUNTRY')} />
                    )}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <CustomTextfeild
                    label={t('DEALERZIPCODE')}
                    name={'pincode'}
                    value={payload.pincode}
                    validation="alphaNumericWithSpace"
                    handleChange={handleChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <CustomTextfeild
                    label={t('COMMON036')}
                    name={'code'}
                    value={payload.code}
                    error={error.code}
                    handleChange={handleChange}
                    required={true}
                    inputProps={{ maxLength: 36 }}
                    disabled={isEditable}
                    validation="alpha-numeric"
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6} xl={12}></Grid>
              </Grid>
              <Grid container lg={4}></Grid>
              <Grid container lg={8} justifyContent="flex-end">
                <Grid pr={2}>
                  <CancelButtons
                    variant="contained"
                    color="secondary"
                    onClick={onCancel}
                  >
                    <Typography>{t('COMMON008')}</Typography>
                  </CancelButtons>
                </Grid>
                <Grid>
                  <SubmitButtons variant="contained" onClick={onNext}>
                    <Typography>{t('DEALERNEXT')}</Typography>
                  </SubmitButtons>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid container>
              <Grid
                container
                spacing={2}
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                sx={{ backgroundColor: '#ffffff' }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="h4">{t('DEALERPLANDETAILS')}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={planExpirationReminder}
                          onChange={handleCheckBoxSelfProfilePhoto}
                          sx={{ '&.Mui-checked': { color: '#F2824C' } }}
                        />
                      }
                      checked={planExpirationReminder}
                      label={t('DEALERREMIND')}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ marginTop: -2 }}
                >
                  <CustomTextfeild
                    label={t('DEALERPLACEHOLDER1')}
                    name={'subscriptionName'}
                    value={subscriptionName}
                    error={error.subscriptionName}
                    required={true}
                    handleChange={handlePlanAssignmentChange}
                    InputProps={{
                      endAdornment: clearPlanTextField ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClearField}
                            sx={{
                              position: 'absolute',
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        ''
                      ),
                    }}
                  />
                </Grid>

                {showPlanAssignment ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{
                      marginTop: -2,
                      marginRight: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button
                      onClick={() => {
                        setOpenPlanPurchaseDialog(true)
                      }}
                      style={{
                        height: '28px',
                        backgroundColor: '#F8F8F8',
                        border: '2px solid #F2824CB3',
                        color: '#F2824C',
                      }}
                    >
                      {t('DEALERPURCHASEPLAN')}
                    </Button>
                    {/* <Link
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      underline="always"
                      component="button"
                      variant="body2"
                      onClick={() => {
                        setOpenPlanPurchaseDialog(true)
                      }}
                    >
                      {t('DEALERPURCHASEPLAN')}
                    </Link> */}

                    <Button
                      onClick={() => {
                        setOpenPlanSelectDialog(true)
                      }}
                      style={{
                        height: '28px',
                        backgroundColor: '#F8F8F8',
                        border: '2px solid #F2824CB3',
                        color: '#F2824C',
                        font: '1px',
                      }}
                    >
                      {t('DEALERPLANASSIGNMENT')}
                    </Button>
                    {/* <Link
                      style={{
                        marginLeft: '298px',
                        whiteSpace: 'nowrap',
                      }}
                      underline="always"
                      component="button"
                      variant="body2"
                      onClick={() => {
                        setOpenPlanSelectDialog(true)
                      }}
                    >
                      {t('DEALERPLANASSIGNMENT')}
                    </Link> */}
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>

            <Grid container md={4} sm={12} spacing={2}>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  marginTop: 5,

                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <SubmitButtons variant="contained" onClick={handleConfirm}>
                  <Typography>{t('confirmVisitorButton')}</Typography>
                </SubmitButtons>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Paper>
      {openPlanPurchaseDialog && (
        <PlanPurchaseDialogBox
          open={openPlanPurchaseDialog}
          handleClose={() => {
            setOpenPlanPurchaseDialog(false)
          }}
          handlePurchasedPlan={handlePurchasedPlan}
        />
      )}
      {openPlanSelectDialog && (
        <SelectPlanDialogBox
          open={openPlanSelectDialog}
          handleClose={() => {
            setOpenPlanSelectDialog(false)
          }}
          handlePlanSelected={handlePlanSelected}
        />
      )}
    </>
  )
}

export default CreateCustomer
