import * as React from 'react'

import DialogContent from '@mui/material/DialogContent'
import { Typography } from 'antd'

import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DialogBox from './DialogBox'
import { useTranslation } from 'react-i18next'
import { Grid, Tooltip } from '@material-ui/core'
import Toast from '../../components/ToastContainer/CustomToast'
import { deleteMobileConfiguration } from '../../slices/MobileKeyConfiguration/MobileKeyConfigurationSlice'

export default function ResetKeyAssignmentDialog(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { open, handleClose } = props

  const handleSubmit = () => {
    dispatch(deleteMobileConfiguration(props.customerId))
      .unwrap()
      .then((data) => {
        handleClose(false)
        if (data.code === 'MSGS4000') {
          Toast(data.message, 'success')
          props.loadData()
        }
      })
      .catch((e) => {})
  }

  return (
    <>
      <DialogBox
        Header={t('RESET_KEY_ASSIGNMENT')}
        acceptText={t('CONFIRM')}
        cancelText={t('COMMON008')}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={'center'}
          style={{ padding: '20px' }}
        >
          <Grid>
            <Grid
              style={{
                wordWrap: 'break-word',
                opacity: 1,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
                fontSize: '17px',
                marginTop: '15px',
              }}
            >
              <DialogContent
                sx={{
                  textAlign: 'left',
                  fontSize: '18px',
                }}
              >
                <Typography>{t('RESET_KEY_OPERATION')}</Typography>
                <li>{t('RESET_KEY_OPERATION_TEXT1')}</li>
                <li>{t('RESET_KEY_OPERATION_TEXT2')}</li>
                <li>{t('RESET_KEY_OPERATION_TEXT3')}</li>
              </DialogContent>
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
