
import { Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import { useTranslation } from "react-i18next";
import CustomDropdown from "../../components/CustomInputs/CustomDropdown";
import { listVisitorType } from "../../slices/VisitTypeSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Toast from "../../components/ToastContainer/CustomToast";
import AutocompleteComponent from "../../components/CustomInputs/AutocompleteComponent";

export default function VisitorPrposeTypeFilterForm(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [payload, setPayload] = useState({
    visitorTypeCode: "",
    code: "",
  });

  const handleOpen = () => {
    setOpen(true)
    loadVisitType();
  };   


  const handleClose = () => {
    setPayload({
      visitorTypeCode: "",
      code: "",
    });
    setOpen(false);
  };

  const handleSubmit = () => {
    if (payload.visitorTypeCode.trim() === "" && payload.code.trim() === "") {
      Toast("Please enter filter condition!", "error");
      return;
    }
    
    props.applyFilter(payload);
    setPayload({
      visitorTypeCode: "",
      code: "",

    });
    handleClose();
  }

  const reset = () => {
    if (
      payload.visitorTypeCode.trim() === "" &&
      payload.code === ""
    ) {
      Toast(t("Nothing to Reset"),"error");
      return;
    }

    setPayload({
      visitorTypeCode: "",
      code: "",
    });
  };
 
  const loadVisitType = () => {
    const query = {
      id: "",
      code: "",
      name: "",
      pageNumber: page,
      pageSize: rowsPerPage,
      isPageable: true,
    };

    dispatch(listVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI1000") {
          setData(data.data.visitTypes);
        } else {
          setData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [error, setError] = useState({
    visitorTypeCode: false,
    code: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      [name]: false,

    });

  };

  return (
    <>
      <Tooltip title={<Typography fontSize={12} fontWeight={500}>
       {t("COMMON006")}
        </Typography>}>
        <FilterIcon onClick={() => handleOpen()} style={{ cursor: 'pointer' }}>
          <VisitorPrposeTypeFilterForm />
        </FilterIcon>
      </Tooltip>
      <DialogBox
        Header= {t("COMMON006")} 
        acceptText={t("COMMON024")}
        cancelText={t("COMMON025")}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={reset}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
        >

       {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
        <Grid item xs={12} sm={9} sx={{ marginTop: "20px" }}>
            <CustomTextfeild
              error={error.code}
              name='code'
              label={t('COMMON067')}
              helperText=''
              value={payload.code}
              handleChange={(e) => handleChange(e)}
            />
          </Grid> :

          <Grid item xs={12} sm={9} sx={{ marginTop: "20px" }}>
            <CustomTextfeild
              error={error.code}
              name='code'
              label={t('VISTYPE001')}
              helperText=''
              value={payload.code}
              handleChange={(e) => handleChange(e)}
            />
          </Grid> }
     
          <Grid item xs={12} sm={9} sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <AutocompleteComponent
              label={t("PURPOSELABEL")}
              name="visitorTypeCode"
              error={error.visitorTypeCode}
              value={payload.visitorTypeCode}
              onSelect={(e) => setPayload({
                ...payload,
                visitorTypeCode: e.id,
              })}
              options={data}
            />
          </Grid>

        </Grid>
      </DialogBox>

    </>
  );
}
