import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';

export default function CancelButtons(props) {
  const ColorButton = withStyles((theme) => ({
    root: {
      borderRadius: '0.3rem !important',
      width: '180px',
      height: '55px', 
    },
  }))(Button);

  return <ColorButton variant='contained' color='secondary' {...props} />;
}
