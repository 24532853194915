import BrowserLanguage from '../../components/Util/BrowserLanguage'
import {
  REACT_CUSTOMERS_LIST,
  REACT_SUBSCRIPTION_MANAGEMENT,
  REACT_CUSTOMER_REPORT,
  REACT_DASHBOARD_PANEL,
  REACT_URL_USERS,
  REACT_DATEFORMAT_ADMIN_DEALER,
  REACT_PLAN_PURCHASED,
} from '../../actions/EndPoints'
import vis_Dashboard_Icon from '../../assets/USA_Images/navitem_images/Dashboard'
import Device_Icon from '../../assets/USA_Images/navitem_images/settingWhite'
import dealerIcon from '../../assets/USA_Images/navitem_images/dealerIcon'
import ReportIcon from '../../assets/USA_Images/navitem_images/ReportIcon'

let language = BrowserLanguage.getDefaultLanguage()

const dataEn = [
  {
    id: '0',
    name: 'Dashboard',
    link: `${REACT_DASHBOARD_PANEL}`,
    Icon: vis_Dashboard_Icon,
  },
  {
    id: '1',
    name: 'Customer',
    Icon: dealerIcon,
    items: [
      {
        id: '2',
        name: 'Customer',
        link: `${REACT_CUSTOMERS_LIST}`,
      },
      {
        id: '3',
        name: 'Subscription Plan',
        link: `${REACT_SUBSCRIPTION_MANAGEMENT}`,
      },
      {
        id: '4',
        name: 'Purchased Plan',
        link: `${REACT_PLAN_PURCHASED}`,
      },
    ],
  },
  {
    id: '111',
    name: 'Report',
    Icon: ReportIcon,
    items: [
      {
        id: '112',
        name: 'Customer Details',
        link: `${REACT_CUSTOMER_REPORT}`,
      },
    ],
  },
  {
    id: '31',
    name: 'Settings',
    Icon: Device_Icon,
    items: [
      {
        id: '311',
        name: 'User',
        link: `${REACT_URL_USERS}`,
      },
      {
        id: '312',
        name: 'Date Format',
        link: `${REACT_DATEFORMAT_ADMIN_DEALER}`,
      },
      // {
      //   id: '313',
      //   name: 'Payment Method',
      //   link: `${REACT_URL_PAYMENT_METHOD}`,
      // },
    ],
  },
]

const dataEs = [
  {
    id: '0',
    name: 'Panel',
    link: `${REACT_DASHBOARD_PANEL}`,
    Icon: vis_Dashboard_Icon,
  },
  {
    id: '1',
    name: 'Cliente',
    Icon: dealerIcon,
    items: [
      {
        id: '2',
        name: 'Cliente',
        link: `${REACT_CUSTOMERS_LIST}`,
      },
      {
        id: '3',
        name: 'Plan de suscripción',
        link: `${REACT_SUBSCRIPTION_MANAGEMENT}`,
      },
      {
        id: '4',
        name: 'Plan comprado',
        link: `${REACT_PLAN_PURCHASED}`,
      },
    ],
  },
  {
    id: '111',
    name: 'Informe',
    Icon: ReportIcon,
    items: [
      {
        id: '112',
        name: 'Detalles del cliente',
        link: `${REACT_CUSTOMER_REPORT}`,
      },
    ],
  },
  {
    id: '31',
    name: 'Ajustes',
    Icon: Device_Icon,
    items: [
      {
        id: '311',
        name: 'Usuaria',
        link: `${REACT_URL_USERS}`,
      },
      {
        id: '312',
        name: 'Formato de fecha',
        link: `${REACT_DATEFORMAT_ADMIN_DEALER}`,
      },
      // {
      //   id: '313',
      //   name: 'Método de pago',
      //   link: `${REACT_URL_PAYMENT_METHOD}`,
      // },
    ],
  },
]

const dataZh = [
  {
    id: '0',
    name: 'Dashboard',
    link: `${REACT_DASHBOARD_PANEL}`,
    Icon: vis_Dashboard_Icon,
  },
  {
    id: '1',
    name: 'Dealer',
    Icon: dealerIcon,
    items: [
      {
        id: '2',
        name: 'Customer',
        link: `${REACT_CUSTOMERS_LIST}`,
      },
      {
        id: '3',
        name: 'Subscription Plan',
        link: `${REACT_SUBSCRIPTION_MANAGEMENT}`,
      },
      {
        id: '4',
        name: 'Purchased Plan',
        link: `${REACT_PLAN_PURCHASED}`,
      },
    ],
  },
  {
    id: '111',
    name: 'Report',
    Icon: ReportIcon,
    items: [
      {
        id: '112',
        name: 'Customer Details',
        link: `${REACT_CUSTOMER_REPORT}`,
      },
    ],
  },
  {
    id: '31',
    name: 'Settings',
    Icon: Device_Icon,
    items: [
      {
        id: '311',
        name: 'User',
        // link: `${REACT_SETTINGS_LEVEL}`
      },
    ],
  },
]

export default language === 'en' ? dataEn : language === 'es' ? dataEs : dataZh
