import { makeStyles } from "@material-ui/core/styles";
import { Grid, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as FilterIcon } from "../../assets/FilterIcon.svg";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { ThemeContext } from '../../theme/ThemeContext';

const useStyles = makeStyles((theme) => ({
    textarea: {
        resize: "both",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        background: "#3d4977",
        width: "100%",
        padding: "5px",
        textAlign: "center",
        borderRadius: "5px",
        color: "#FFFFFF",
    },
}));

export default function AndroidDeviceListFilter(props) {
    const { darkMode } = useContext(ThemeContext);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const defaultFilter = {
        deviceName: "",
        deviceNumber: ""
    }
    const [filterQuery, setFilterQuery] = useState({ ...defaultFilter });

    const handleChange = (event) => {
        const name = event.target.name;
        setFilterQuery({
            ...filterQuery,
            [name]: event.target.value,
        });
    };

    const handleClose = () => {
        setFilterQuery(defaultFilter);
        setOpen(false);
    }

    const handleSubmit = () => {
        if (filterQuery.deviceName.trim() === "" && filterQuery.deviceNumber.trim() === "") {
            Toast(t('COMMON154'), "error");
            return;
        }

        props.onSubmit(filterQuery);
        handleClose();
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const reset = () => {
        // if(filterQuery.deviceAlias.trim() === "" && filterQuery.deviceSn.trim() === "" && filterQuery.deviceType.trim() === ""){
        //     Toast(t("Nothing to Reset"), "error");
        //     return;
        // }
        // setFilterQuery(defaultFilter);
        setOpen(false);
    }

    return (
        <>
            <Tooltip title={<Typography fontSize={12} fontWeight={500}>Filter</Typography>} >
                <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
                    <FilterIcon />
                </FilterIcon>
            </Tooltip>

            <DialogBox
                Header={
                    <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
                        {t("COMMON006")}
                    </span>
                }
                acceptText={t('COMMON024')}
                cancelText={t('COMMON008')}
                fullWidth={true}
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                cancel={handleClose}
            >
                <Grid container justifyContent="center" alignItems={"center"} style={{ backgroundColor: darkMode ? '#0F273B' : '' }}>
                    <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                        <CustomTextfield
                            inputProps={{ className: classes.textarea }}
                            label={t('VISACCDEV002')}
                            name="deviceName"
                            value={filterQuery.deviceName}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                        <CustomTextfield
                            inputProps={{ className: classes.textarea }}
                            label={t('DEVICE_NUMBER')}
                            name="deviceNumber"
                            value={filterQuery.deviceNumber}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid>

                </Grid>
            </DialogBox>
        </>
    );
}
