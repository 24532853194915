const ReportIcon = () => {
  return (
    <svg
      className="MuiSvgIcon-root"
      xmlns="http://www.w3.org/2000/svg"
      width="15.4"
      height="21"
      viewBox="0 0 15.4 21"
    >
      <path
        id="Path_7216"
        data-name="Path 7216"
        d="M.7,21a.7.7,0,0,1-.7-.7V.7A.7.7,0,0,1,.7,0h9.842a.618.618,0,0,1,.217.077.263.263,0,0,1,.063,0,.768.768,0,0,1,.2.133l4.2,4.2a.768.768,0,0,1,.133.2v.063a.936.936,0,0,1,.049.189V20.3a.7.7,0,0,1-.7.7Zm.7-1.4H14V5.6H10.5a.7.7,0,0,1-.7-.7V1.4H1.4ZM11.2,4.2h1.813L11.2,2.387ZM3.5,16.1a.7.7,0,1,1,0-1.4h7.7a.7.7,0,0,1,0,1.4Zm0-2.8a.7.7,0,1,1,0-1.4H9.8a.7.7,0,1,1,0,1.4Zm0-2.8a.7.7,0,1,1,0-1.4h8.4a.7.7,0,0,1,0,1.4ZM2.8,7.7A.7.7,0,0,1,2.1,7V3.5a.7.7,0,0,1,.7-.7H6.3a.7.7,0,0,1,.7.7V7a.7.7,0,0,1-.7.7Zm.7-1.4H5.6V4.2H3.5Z"
      />
    </svg>
  )
}
export default ReportIcon
