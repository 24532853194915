import React, { useEffect, useMemo, useState } from "react";
import DataTable from "../../components/DataTable/DataTable";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Search from "../../components/SearchTab/Search";
import { Box, Grid, Link, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import FilterChecklistTemplate from "../ChecklistTemplateUSA/FilterChecklistTemplate";
import AccountActivateIcon from "../../assets/UserRole/AccountActivateIcon";
import AccountDisableIcon from "../../assets/UserRole/AccountDisableIcon";
import ResendIcon from "../../assets/UserRole/ResendIcon";
import { userList } from "../../slices/UserRole/UserRoleSlice";
import jwt_decode from "jwt-decode";
import Toast from "../../components/ToastContainer/CustomToast";
import { activateAccount } from "../../slices/Person/PersonSlice";

export default function UserList(props) {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    name: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    name: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);


  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadData = (filter, paging) => {
    dispatch(userList(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = "UASI0000" && data.data)) {
          setTotalRecords(data.data.totalCount);
          setPage(data.data.currentPage);
          setTotalPages(data.data.totalPages);
          setRowData(data.data.user);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  React.useEffect(() => {
    const decoded = jwt_decode(localStorage.getItem('VISJwtToken'));
    const data = Array.from(rowData);
    const userList = data.filter((value) => value.id !== decoded.id);
    setRows(userList);
  }, [rowData]);

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      name: data.name,
    });
  };


  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return test.firstName ? test.firstName.toLowerCase().includes(searchedVal.toLowerCase()) : ""
        || test.lastName ? test.lastName.toLowerCase().includes(searchedVal.toLowerCase()) : ""
      || test.description.toLowerCase().includes(searchedVal.toLowerCase())
    });
    setRows(filteredRows);
  };

  const columns = [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "firstName",
      headerName: "User Name",
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
    },
    {
      field: "roleName",
      headerName: "Role",
      flex: 0.5,
      width: 110,
      valueGetter: (params) => params.row.roleName,
    },

    // {
    //   field: "description",
    //   headerName: "Description",
    //   flex: 1,
    //   minWidth: 150,
    //   valueGetter: (params) => params.row.description,
    // },

    {
      field: "Actions",
      headerName: "Action",
      type: "actions",
      flex: 1,
      minWidth: 110,
      width: 110,
      headerAlign: "center",
      getActions: (params) => [

        <GridActionsCellItem
          icon={<AccountActivateIcon />}
          label="Account activate"
          onClick={() => handleEditVisit([params.row])}
        />,

        <GridActionsCellItem
          icon={<AccountDisableIcon />}
          label="Add"
        //   onClick={() => handleAddUser(params.row)}
        />,

        <GridActionsCellItem
          icon={<ResendIcon />}
          label="Delete"
        //   onClick={() => handleDeleteUserRole(params)}
        />,

      ],
    }];

  const handleEditVisit = (paramValue) => {
    if (paramValue.length < 1) {
      Toast(t("COMMON019"), "warning")
      return;
    }

    var personsToActivate = [];
    paramValue.map((record, index) => {
      let person = {
        id: record.id,
        firstName: record.firstName,
        lastName: record.lastName,
        email: record.email,
        phone: record.phone,
        userId: record.userId,
        employeeCode: record.employeeCode,
        companyCode: localStorage.getItem("companyCode"),
      };
      personsToActivate[index] = person;
    });

    dispatch(activateAccount(personsToActivate))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI3003") {
          Toast(t("PERSONACTSUCC001"), "success");
        } else if (data.code === "CVAW0001") {
          Toast(t("User already active"), "warning");
        } else {
          Toast((data.message), 'error');
        }
        loadData(filter, paging);
      })
      .catch((er) => { });
  }

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          <Box style={{ margin: '16px, 0' }}><Typography variant="h3">User</Typography></Box>
        </Grid>
      </Box>

      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterChecklistTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

    </>
  )
}

