import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import "./profileCard.css";
import { Divider, Grid,Tooltip } from "@mui/material";
import ViewIcon from "../../Pages/Invitations/ViewIcon";
import { useDispatch } from "react-redux";
import { listVisitorType } from "../../slices/VisitTypeSlice";
import { useTranslation } from "react-i18next";
import { getPurposeTypeById } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";
import eyeIcon from '../../../src/assets/USA_Images/login_images_visitor/eyeIcon.svg'
import { ThemeContext } from '../../theme/ThemeContext';


const BootstrapDialog = styled(Dialog)(({ theme, darkMode }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    overflowY: "clip",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    overflowY: "visible",
    borderRadius: "20px",
    minWidth: "120vh",
    opacity: 1,
    backgroundColor: darkMode ? '#0F273B' : '#FFFFFF',
  },
  '& .MuiDialogContent-root ': {
    padding: '16px 24px',
    position: ' relative',
    bottom: '80px',
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function WatchlistViewProfile(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [purposeName, setPurposeName] = useState();
  const rowDataMode = props.rowData.registrationMode;
  const { t } = useTranslation();
  const { darkMode } = useContext(ThemeContext);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadPurposeTypeData();
  }, [props.rowData.purposeTypeId]);

  const loadPurposeTypeData = () => { 
   
    const payload = {
      id: props.rowData.purposeTypeId,
    };
  
    dispatch(getPurposeTypeById(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAE0095") {
           setPurposeName(data.data);
        }
      })
      .catch((er) => { });
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
        return text.substring(0, 25) + '...';
    } else {
    return text;
}
}

  return (
    <div>
       <Tooltip title={t('VIEW')}>
      <div onClick={handleClickOpen}>
      {darkMode ? (
            <img src={eyeIcon} alt="Eye Icon" style={{ width: '24px', height: '24px' }} />
          ) : (
            <ViewIcon />
          )}
      </div>
      </Tooltip>
      <Grid>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          darkMode={darkMode}
        >
          <Avatar
              alt={props.rowData.firstName}
              src={props.rowData.profilePhotoURL !== undefined ? props.rowData.profilePhotoURL : null}
              sx={{
                width: 150,
                height: 150,
                bottom: "80px",
                margin: "auto",
                border: "solid #FFFF",
                borderWidth: "7px",
              }}
            />
          <BootstrapDialogTitle onClose={handleClose} >
            <div className="CssTextProfileViewPageName" style={{color: darkMode ? '#fff' : ''}}>
              {props.rowData.firstName + " " + props.rowData.lastName}
            </div>
            <div className="CssTextProfileViewPagePhone" style={{color: darkMode ? '#fff' : ''}}>
            {props.rowData.phoneNumber ?
              props.rowData.phoneCountryCode+"-"+props.rowData.phoneNumber : ""}
            </div>
          </BootstrapDialogTitle>
          <Divider variant="inset" className="MuiDividerCss" sx={{backgroundColor: darkMode ? '#fff' : ''}}/>
          <DialogContent style={{ marginLeft: "70px", marginRight: "70px"}}>
            <Grid
              container
              spacing={12.25}
              className="CssTextProfileViewPageContent"
              marginTop={0.5}
              style={{color: darkMode ? '#fff' : ''}}
            >
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  {t("COMMON042")}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {" "}
                  {props.rowData.email}
                </Grid>
              </Grid>
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                {t("PURPOSELABEL")}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Tooltip title={purposeName}>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {handleTextOverflow(purposeName)}
                                 </Grid>
                                 </Tooltip>
              </Grid>
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                {t("WATCHLISTTEMP002")}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.contactEmail}
                </Grid>
              </Grid>

              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                {t("WATCHLISTTEMP009")}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.contactPhoneNumber ?  props.rowData.contactPhoneCountryCode+"-"+props.rowData.contactPhoneNumber : "" }
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
    </div>
  );
}

export default WatchlistViewProfile;
