import {
  Tooltip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import DialogBox from "./DialogBox";
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import { useTranslation } from "react-i18next";
import Toast from "../ToastContainer/CustomToast";
import Autocomplete from '@mui/material/Autocomplete';
import CommonUtil from '../../Util/CommonUtils';
import { ThemeContext } from '../../theme/ThemeContext';

export default function SmsTemplateFilterform(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = useState({
    recipient: ""
  });

  const recepientOptions = [
    { label: 'Host' },
    { label: 'Visitor' },
    { label: 'Employee' }
  ]

  const handleClose = () => {
    setPayload({
      recipient: "",
    });
    setOpen(false);
  };

  const [error, setError] = useState({
    name: false,
  });

  const reset = () => {
    // if (
    //   payload.name.trim() === ""
    // ) {
    //   toast.error(t("Nothing to Reset"), {
    //     closeButton: false,
    //     hideProgressBar: true,
    //   });
    //   return;
    // }

    // setPayload({
    // name: "",
    // });
    setOpen(false);
  };


  const handleSubmit = () => {
    if (payload.recipient === "") {
      Toast(("Please enter filter condition"), 'error')
      return;
    }

    props.applyFilter(payload);
    setPayload({
      recipient: ""
    });
    handleClose();
  };

  useEffect(() => {

  }, [payload.recipient]);

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t("COMMON006")}
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
          <FilterIcon />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
            {t('COMMON006')}
          </span>
        }
        acceptText={t("COMMON024")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid container justifyContent="center" alignItems={'center'} style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
          <Grid item xs={12} sm={9} style={{ marginTop: '30px' }}>
            <Autocomplete
              id="combo-box-demo"
              options={recepientOptions}
              value={payload.recipient}
              onChange={(event, value) => {
                if (!CommonUtil.isEmpty(value))
                  setPayload({
                    ...payload,
                    recipient: value.label
                  })
                else
                  setPayload({
                    ...payload,
                    recipient: ""
                  })
              }}
              // sx={{ width: "100%" , padding :"20px"}}
              renderInput={(params) => <TextField {...params} label="Recepient *"
                InputLabelProps={{
                  style: {
                    color: darkMode ? '#fff' : '#0F273B',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                    },
                    '&:hover fieldset': {
                      borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                    },
                  },
                }}
              />}
            />
          </Grid>

        </Grid>
      </DialogBox>
    </>
  );
}
