import api from '../../config/http-common'
import CommonUtil from '../../Util/CommonUtils'

const buildQuery = (payload) => {
  let query =
    '?pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize
  if (!CommonUtil.isEmpty(payload.status)) {
    query = query + '&status=' + payload.status
  }
  if (!CommonUtil.isEmpty(payload.name)) {
    query = query + '&name=' + payload.name
  }
  if (!CommonUtil.isEmpty(payload.email)) {
    query = query + '&email=' + payload.email
  }

  if(!CommonUtil.isEmpty(payload.subscriptionName)){
    query = query + '&subscriptionName=' + payload.subscriptionName
  }

  if(!CommonUtil.isEmpty(payload.subscriptionStartDate)){
    query = query + '&subscriptionStartDate=' + payload.subscriptionStartDate
  }

  if(!CommonUtil.isEmpty(payload.subscriptionEndDate)){
    query = query + '&subscriptionEndDate=' + payload.subscriptionEndDate
  }

  if (!CommonUtil.isEmpty(payload.dealerName)) {
    query = query + '&dealerName=' + payload.dealerName
  }

  console.log(query)
  return query
}

const create = (payload) => {
  return api.securedAxios().post('/web/api/v4/customer', payload)
}

const get = (payload) => {
  return api.securedAxios().get('/web/api/v4/customers' + buildQuery(payload))
}

const resendActivationLink = (payload) => {
  return api
    .securedAxios()
    .put('/web/api/v4/customers/' + payload.id + '/acountActivation', payload)
}

const deleteCustomer = (payload) => {
  return api.securedAxios().delete('/web/api/v4/customers/' + payload.id)
}

const updateCustomerAccount = (payload) => {
  return api
    .securedAxios()
    .put('/web/api/v4/customers/' + payload.companyId, payload)
}

const listOfCustomerDetalis = (payload) => {
  return api.securedAxios().post('/web/api/v4/customers/details/export' +buildQuery(payload) )
}

const fetchCustomersByDealerId = (payload) => {
  return api
    .securedAxios()
    .get('/web/api/v4/customers/' + payload.dealerId + buildQuery(payload))
}

const listOfCustomerDetalisWithoutStatus = (payload) => {
  return api.securedAxios().post('/web/api/v4/customers/details/export/without/status' + payload )
}

const listOfCustomerDetalisWithoutStatusDealer = (payload) => {
  return api.securedAxios().post('/web/api/v4/customers/details/export/without/status/dealercompany' + payload )
}

const fetchCustomersDetailsByDealerCompanyId = (payload) => {
  return api.securedAxios().get('/web/api/v4/customers/list/dealerAccount' +buildQuery(payload) )
}

const fetchCustomersByDealerIdWithoutStatus = (payload) => {
  return api
    .securedAxios()
    .get('/web/api/v4/customers/without/status/' + payload.dealerId )
}

const downloadCustomerInvoice = (payload) => {
  return api
    .securedAxios()
    .get('/web/api/v4/customer/list/invoice/' + payload.id )
}

const downloadCustomerInvoiceDealerCompany = (payload) => {
  return api
    .securedAxios()
    .get('/web/api/v4/customer/list/invoice/dealer/company/' + payload.id )
}

const CreateCustomerService = {
  create,
  get,
  resendActivationLink,
  deleteCustomer,
  updateCustomerAccount,
  listOfCustomerDetalis,
  fetchCustomersByDealerId,
  listOfCustomerDetalisWithoutStatus,
  fetchCustomersByDealerIdWithoutStatus,
  fetchCustomersDetailsByDealerCompanyId,
  downloadCustomerInvoice,
  downloadCustomerInvoiceDealerCompany,
  listOfCustomerDetalisWithoutStatusDealer,
}

export default CreateCustomerService
