import BarChartIcon from '@material-ui/icons/BarChart'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ChatIcon from '@material-ui/icons/ChatOutlined'
import CodeIcon from '@material-ui/icons/Code'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import FolderIcon from '@material-ui/icons/FolderOutlined'
import GradeTwoTone from '@material-ui/icons/GradeTwoTone'
import ListAltIcon from '@material-ui/icons/ListAlt'
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined'
import MailIcon from '@material-ui/icons/MailOutlined'
import PresentToAllIcon from '@material-ui/icons/PresentToAll'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'
import PersonIcon from '@material-ui/icons/PersonOutlined'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined'
import SettingsIcon from '@material-ui/icons/SettingsOutlined'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone'
import BrowserLanguage from '../../components/Util/BrowserLanguage'
import {
  REACT_URL_ADDRESSTYPE,
  REACT_URL_APPROVAL,
  REACT_URL_BLOCKED_VISITORS,
  REACT_URL_CHECKINOUTDEVICES,
  REACT_URL_DASHBOARD,
  REACT_URL_INVITATIONS,
  REACT_URL_PERSON,
  REACT_URL_REGISTRATIONS,
  REACT_URL_ROLE,
  REACT_URL_SITE,
  REACT_URL_SITETYPE,
  REACT_URL_VISITORLOGS,
  REACT_URL_PURPOSETYPE,
  REACT_URL_WATCHLIST,
  REACT_URL_CHECKLIST,
  REACT_URL_RESTRICTTYPE,
  REACT_URL_EMERGENCYMESSAGE,
  REACT_URL_FLOWCHART,
  REACT_DEVICE_LIST,
  REACT_URL_DEVICELIST,
  REACT_ACCESSLEVELS_LIST,
  REACT_TIME_SLOTS,
  REACT_URL_ZONE,
} from '../../actions/EndPoints'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import { ReactComponent as Dashboard_Icon } from '../../assets/images/dashboard-icons/dashboard_icon.svg'
import { ReactComponent as Site_Icon } from '../../assets/images/dashboard-icons/site_icon.svg'
import { ReactComponent as Device_Icon } from '../../assets/images/dashboard-icons/setting.svg'
import { ReactComponent as Access_Icon } from '../../assets/images/dashboard-icons/access_icon.svg'
import { ReactComponent as Report_Icon } from '../../assets/images/dashboard-icons/visitor.svg'
import { ReactComponent as Organization_Icon } from '../../assets/images/dashboard-icons/organization_icon.svg'
import { ReactComponent as AccessControl } from '../../assets/cloudaccess-svg/AccessControl.svg'
import { ReactComponent as Reports } from '../../assets/cloudaccess-svg/Reports.svg'
import { ReactComponent as SiteManagement } from '../../assets/cloudaccess-svg/SiteManagement.svg'
import { ReactComponent as PersonalManagement } from '../../assets/cloudaccess-svg/Personnel Icon.svg'
var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  AccessControlIcon: AccessControl,
  ReportsIcon: Reports,
  SiteManagementIcon: SiteManagement,
  PersonalManagementIcon: PersonalManagement,
  BusinessIcon: DashboardTwoToneIcon,
  EqualizerIcon: EqualizerIcon,
}

let language = BrowserLanguage.getDefaultLanguage()

const zlink = localStorage.getItem('redirectFrom')

const dataEn = [
  {
    id: '0',
    name: 'Dashboard',
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },

  {
    id: '1',
    name: 'Organization',
    Icon: Organization_Icon,
    items: [
      {
        id: '6',
        name: 'Person',
        link: `${REACT_URL_PERSON}`,
      },
    ],
  },

  {
    id: '2',
    name: 'Site Management',
    Icon: Site_Icon,
    items: [
      {
        id: '12',
        name: 'Site',
        link: `${REACT_URL_SITE}`,
      },
      {
        id: '34',
        name: 'Zone',
        link: `${REACT_URL_ZONE}`,
      },
    ],
  },

  {
    id: '3',
    name: 'Device Management',
    Icon: Site_Icon,
    items: [
      {
        id: '14',
        name: 'Device List',
        link: `${REACT_URL_DEVICELIST}`,
      },
    ],
  },

  {
    id: '5',
    name: 'Visitor',
    Icon: Report_Icon,
    items: [
      {
        id: '19',
        name: 'Visiting Flowchart',
        link: `${REACT_URL_FLOWCHART}`,
      },

      {
        id: '20',
        name: 'Invitations',
        link: `${REACT_URL_INVITATIONS}`,
      },
      {
        id: '21',
        name: 'Registrations',
        link: `${REACT_URL_REGISTRATIONS}`,
      },
      {
        id: '22',
        name: 'Approvals',
        link: `${REACT_URL_APPROVAL}`,
      },
      {
        id: '23',
        name: 'Blocked Visitors',
        link: `${REACT_URL_BLOCKED_VISITORS}`,
      },
      {
        id: '24',
        name: 'Visitor Log',
        link: `${REACT_URL_VISITORLOGS}`,
      },
    ],
  },

  {
    id: '4',
    name: 'Settings',
    Icon: Device_Icon,
    items: [
      {
        id: '12',
        name: 'Time slots',
        link: `${REACT_TIME_SLOTS}`,
      },
      {
        id: '13',
        name: 'Visiting Purpose',
        link: `${REACT_URL_PURPOSETYPE}`,
      },
      {
        id: '15',
        name: 'Visiting Access Level',
        link: `${REACT_ACCESSLEVELS_LIST}`,
      },
      {
        id: '16',
        name: 'Restrict Type',
        link: `${REACT_URL_RESTRICTTYPE}`,
      },
      {
        id: '17',
        name: 'Checklist',
        link: `${REACT_URL_CHECKLIST}`,
      },
      {
        id: '18',
        name: 'Watchlist',
        link: `${REACT_URL_WATCHLIST}`,
      },
    ],
  },
]

export default language === 'en' ? dataEn : language === 'es'
