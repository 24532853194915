import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Checkbox, Grid, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import Toast from '../../components/ToastContainer/CustomToast'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { acceptApprovalVisit } from '../../slices/Invitations/InvitationsSlice'
import { getPurposeType } from '../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice'
import { getVisitorAccessLevels } from '../../slices/VisitorAccessLevels/VisitorAccessLevelSlice'
import { getAllVisitorType } from '../../slices/VisitorType/VisitorTypeSlice'
import CommonUtil from '../../Util/CommonUtils'
import { ThemeContext } from '../../theme/ThemeContext';

export default function ApprovalPageDailogSelfRegistration(props) {
  const { open, handleClose, selectedObject } = props
  const { t } = useTranslation()
  const [selectdAccessLevel, setSelectdAccessLevel] = useState([])
  const dispatch = useDispatch()
  const [purposeData, setPurposeData] = useState([])
  const [visitorData, setVisitorData] = useState([])
  const selfIntervalTime = props.selfIntervalTime
  const [date, setDate] = useState()
  const [changeDate, setDateChange] = useState(true)
  const { darkMode } = useContext(ThemeContext);
  const [
    selfReistrationPurposeTypeStatus,
    setSelfRegistrationPurposeTypeStatus,
  ] = useState('')
  const [
    selfRegistrationVisitorTypeStatus,
    setSelfRegistrationVisitorTypeStatus,
  ] = useState('')
  const [
    selfRegistrationPurposeTypeMandatory,
    setSelfRegistrationPurposeTypeMandatory,
  ] = useState('')
  const [
    selfRegistrationVisitorTypeMandatory,
    setSelfRegistrationVisitorTypeMandatory,
  ] = useState('')

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  var today = new Date()

  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T' +
    (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())

  const [payload, setPayload] = React.useState({
    hostId: '',
    purposeTypeId: selectedObject?.purposeType,
    visitorTypeId: selectedObject?.visitorType,
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(
      moment(startDate).add(selfIntervalTime, 'minutes'),
    ),
    accessLevel: [],
    approvalReason: selectedObject?.approvalReason,
    siteId: '',
  })

  const [error, setError] = React.useState({
    approvalReason: false,
    purposeTypeId: false,
    visitorTypeId: false,
    scheduledStartDate: false,
    approvalReason: false,
    siteId: false,
  })

  useEffect(() => {
    loadPurposeType()
    loadVisitorType()
    loadAcceLevelList()
    loadSetting()
  }, [])

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
    }

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setPurposeData(data.data.purposeTypes)
        } else {
          setPurposeData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadVisitorType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
    }

    dispatch(getAllVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setVisitorData(data.data.visitorTypes)
          setDateChange(true)
        } else {
          setVisitorData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadSetting = () => {
    if (props.open) {
      dispatch(getListSettings())
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0000') {
            setDate(data.data.dateFormat)
            setSelfRegistrationPurposeTypeStatus(
              data.data.selfRegistrationPurposeType,
            )
            setSelfRegistrationVisitorTypeStatus(
              data.data.selfRegistrationVisitorType,
            )
            setSelfRegistrationPurposeTypeMandatory(
              data.data.checkSelfRegistrationPurposeType,
            )
            setSelfRegistrationVisitorTypeMandatory(
              data.data.checkSelfRegistrationVisitorType,
            )
          }
        })
    }
  }

  const loadAcceLevelList = () => {
    const dto = {
      page: 0,
      rowsPerPage: 1000,
      isPageable: false,
    }
    dispatch(getVisitorAccessLevels(dto))
      .unwrap()
      .then((data) => {
        if (data.code === 'DMSI0000') {
          setSelectdAccessLevel(data.data.accessGroups)
        } else {
          setSelectdAccessLevel([])
        }
      })
  }

  const handleSumbit = () => {
    if (payload.purposeTypeId !== null) {
      if (CommonUtil.isEmpty(payload.purposeTypeId)) {
        Toast(t('APPROVE004'), 'error')
        setError({ purposeTypeId: true })
        return false
      }
    }

    if (payload.visitorTypeId !== null) {
      if (CommonUtil.isEmpty(payload.visitorTypeId)) {
        Toast(t('APPROVE006'), 'error')
        setError({ visitorTypeId: true })
        return false
      }
    }
    const dto = {
      approvalReason: payload.approvalReason,
      purposeTypeId: payload.purposeTypeId ? payload.purposeTypeId.id : null,
      visitorTypeId: payload.visitorTypeId ? payload.visitorTypeId.id : null,
      hostId: payload.hostId,
      siteId: payload.siteId.id,
      accessLevel: payload.accessLevel,
      visitorId: selectedObject.id,
    }
    dispatch(acceptApprovalVisit(dto))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI1028') {
          Toast(t('APPROVE005'), 'success')
          props.approve()
          handleClose()
        } else {
          Toast(data.message, 'error')
        }
      })
  }
  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    })
  }

  const onselectVisitorType = (event) => {
    setPayload({
      ...payload,
      visitorTypeId: event,
    })
  }

  const onselectAccessLevel = (event) => {
    setPayload({
      ...payload,
      accessLevel: event,
    })
  }

  return (
    <>
      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
           {t('APPROVE002')}
          </span>
        }
        acceptText={t('COMMON007')}
        cancelText={t('COMMON008')}
        PaperProps={{
          style: {
            borderRadius: '20px !important',
            minWidth: '50vw',
          },
        }}
        open={open}
        onSubmit={handleSumbit}
        onClose={() => handleClose(false)}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: '20px',backgroundColor: darkMode ? '#0F273B' : '' }}
          spacing={2}
        >
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              error={error.firstName}
              name="firstName"
              label={t('COMMON020')}
              value={selectedObject.visitor.firstName}
              handleChange={(e) => handleChange(e)}
              helperText={error.firstName}
              validation="alpha-numeric"
              required={true}
              disabled
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label={t('COMMON021')}
              value={selectedObject.visitor.lastName}
              error={error.lastName}
              name="lastName"
              handleChange={(e) => handleChange(e)}
              helperText={error.lastName}
              validation="alpha-numeric"
              disabled
            />
          </Grid>

          {selfReistrationPurposeTypeStatus ? (
            <Grid item lg={6} sm={12} style={{ marginTop: '5px' }}>
              <Autocomplete
                disabled={payload.purposeTypeId === null}
                style={{ width: '100%' }}
                noOptionsText={'No Options found'}
                name="purposeTypeId"
                value={payload.purposeTypeId}
                options={
                  CommonUtil.isEmptyArray(purposeData) ? [] : purposeData
                }
                getOptionLabel={(option) => (option.name ? option.name : '')}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                    // eslint-disable-next-line
                    onselectPurposeType(newValue)
                  } else {
                    onselectPurposeType('')
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="purposeTypeId"
                    value={payload.purposeTypeId}
                    error={error.purposeTypeId}
                    variant="outlined"
                    fullWidth
                    label={
                      selfRegistrationPurposeTypeMandatory
                        ? 'Purpose of Visit *'
                        : 'Purpose of Visit'
                    }
                  />
                )}
              />
            </Grid>
          ) : null}

          {selfRegistrationVisitorTypeStatus ? (
            <Grid item lg={6} sm={12} style={{ marginTop: '5px' }}>
              <Autocomplete
                disabled={payload.visitorTypeId === null}
                style={{ width: '100%' }}
                noOptionsText={'No Options found'}
                name="visitorTypeId"
                value={payload.visitorTypeId}
                options={
                  CommonUtil.isEmptyArray(visitorData) ? [] : visitorData
                }
                getOptionLabel={(option) => (option.name ? option.name : '')}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                    // eslint-disable-next-line
                    onselectVisitorType(newValue)
                  } else {
                    onselectVisitorType('')
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="visitorTypeId"
                    value={payload.visitorTypeId}
                    error={error.visitorTypeId}
                    variant="outlined"
                    fullWidth
                    label={
                      selfRegistrationVisitorTypeMandatory
                        ? 'Visitor Type *'
                        : 'Visitor Type'
                    }
                  />
                )}
              />
            </Grid>
          ) : null}

          {payload.purposeTypeId === null ? (
            <Grid item lg={6} sm={12} style={{ marginTop: selfRegistrationVisitorTypeStatus === true ? '2px' : '-6.5px' }}>
              <Autocomplete
                disabled
                limitTags={2}
                multiple
                error={error.accessLevel}
                options={selectdAccessLevel}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                    var ids = newValue.map((newValue) => {
                      return newValue.id
                    })
                    onselectAccessLevel(ids)
                  } else {
                    onselectAccessLevel([])
                  }
                  setError({ accessLevel: false })
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <CustomTextfield
                    {...params}
                    label={t('dailogSelectAccessLevels')}
                    value={payload.accessLevel}
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid item lg={6} sm={12} style={{ marginTop: selfRegistrationVisitorTypeStatus === true ? '2px' : '6.5px' }}>
              <Autocomplete
                limitTags={2}
                multiple
                error={error.accessLevel}
                options={selectdAccessLevel}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                    var ids = newValue.map((newValue) => {
                      return newValue.id
                    })
                    onselectAccessLevel(ids)
                  } else {
                    onselectAccessLevel([])
                  }
                  setError({ accessLevel: false })
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('dailogSelectAccessLevels')}
                    value={payload.accessLevel}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item lg={6} sm={12} style={{ marginTop: selfRegistrationVisitorTypeStatus === true ? '-13px' : '-7px' }}>
            <CustomTextfield
              error={error.remarks}
              name="approvalReason"
              label={t('COMMON016')}
              value={payload.approvalReason}
              handleChange={(e) => handleChange(e)}
              helperText={error.remarks}
            />
          </Grid>

          <Grid
            item
            lg={6}
            xs={12}
            style={{ marginTop: selfRegistrationVisitorTypeStatus === true ? '2px' : '10px' }}
          >
            <CustomDateTimePicker
              onChange={(scheduledStartDate) =>
                setPayload({
                  ...payload,
                  scheduledStartDate: scheduledStartDate,
                  scheduledEndDate: CommonUtil.formatToUtc(
                    moment(scheduledStartDate)
                  ),
                })
              }
              value={moment(
                CommonUtil.getLocalDateTimeInYearFormat(
                  selectedObject?.scheduledStartDate
                )
              )}
              label={t('INVITESTARTDATEERROR1')}
              disabled
              changeDate={changeDate}
              date={props.date}
            />
          </Grid>
          <Grid
            item
            lg={
              (Number(!selfReistrationPurposeTypeStatus) +
                Number(!selfRegistrationVisitorTypeStatus)) %
                2 ===
                1
                ? 12
                : 6
            }
            xs={12}
            style={{ marginTop: selfRegistrationVisitorTypeStatus || selfReistrationPurposeTypeStatus === true ? '2px' : '10px' }}
          >
            <CustomDateTimePicker
              onChange={(scheduledEndDate) =>
                setPayload({
                  ...payload,
                  scheduledEndDate: scheduledEndDate,
                })
              }
              // value={payload.scheduledEndDate}
              value={moment(
                CommonUtil.getLocalDateTimeInYearFormat(
                  selectedObject?.scheduledEndDate,
                ),
              )}
              minDateTime={payload.scheduledStartDate}
              label={t('INVITESTARTDATEERROR2')}
              disabled
              changeDate={changeDate}
              date={props.date}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
