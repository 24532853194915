import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CommonUtil from '../../Util/CommonUtils';
import { REACT_DASHBOARD_PANEL } from '../../actions/EndPoints';
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker';
import CustomDatePicker from '../../components/CustomInputs/CustomerDatePicker';
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons';
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons';
import Toast from '../../components/ToastContainer/CustomToast';
import TitleBar from '../../components/v4/TitleBar';
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice';
import { createEmailReport } from '../../slices/EamilReport/EmailReportSlice';

function EmailReportCreate() {
  const { t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();

  const [changeDate, setChangeDate] = useState(true);
  const [emailError, setEmailError] = useState();
  const [emails, setEmails] = useState('');
  const [weeklyOptionValue, setWeeklyOptionValue] = useState('');
  const [selectReport, setSelectReport] = useState('');
  const [selectMode, setSelectMode] = useState('');
  const [selectedWeekly, setSelectedWeekly] = useState('');
  const [date, setDate] = useState('');

  const companyId = localStorage.getItem('companyId');

  useEffect(() => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          if (data.data.adminAndDealerDateFormat === 'DD-MM-YYYY') {
            setDate('DD-MM-YYYY')
          }
          if (data.data.adminAndDealerDateFormat === 'MM-DD-YYYY') {
            setDate('MM-DD-YYYY')
          }
          if (data.data.adminAndDealerDateFormat === 'YYYY-MM-DD') {
            setDate('YYYY-MM-DD')
          }
        }
      });
  }, [companyId, dispatch]);
  var today = new Date()

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T' +
    (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())

  var monthly =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate())

  const [payload, setPayload] = useState({
    mode: '',
    reportType: '',
    emailIds: [],
    monthlyDate: monthly,
    timePeriodStartDate: CommonUtil.formatToUtc(startDate),
    timePeriodEndDate: CommonUtil.formatToUtc(
      moment(startDate).add(10, 'minutes'),
    ),
  })

  const reportTypeOptions = [
    { label: t('EMAIL_REPORT_TYPE_Dealer_Reports') },
    { label: t('EMAIL_REPORT_TYPE_Dealer_Transactions') },
    { label: t('EMAIL_REPORT_TYPE_Customer_Transactions') },
  ]

  const modeOptions = [
    { label: t('EMAIL_REPORT_MODE_Daily') },
    { label: t('EMAIL_REPORT_MODE_Weekly') },
    { label: t('EMAIL_REPORT_MODE_Monthly') },
  ]

  const weeklyOptions = [
    { label: t('EMAIL_REPORT_MODE_WEEKLY_Sunday') },
    { label: t('EMAIL_REPORT_MODE_WEEKLY_Monday') },
    { label: t('EMAIL_REPORT_MODE_WEEKLY_Tuesday') },
    { label: t('EMAIL_REPORT_MODE_WEEKLY_Wednesday') },
    { label: t('EMAIL_REPORT_MODE_WEEKLY_Thursday') },
    { label: t('EMAIL_REPORT_MODE_WEEKLY_Friday') },
    { label: t('EMAIL_REPORT_MODE_WEEKLY_Saturday') },
  ]

  const handleEmailsChange = (event) => {
    const inputEmails = event.target.value
    setEmails(inputEmails)
    if (inputEmails.trim() === '') {
      setEmailError('');
      setPayload((prevState) => ({
        ...prevState,
        emailIds: [],
      }));
      return;
    }
    const regex = /\S+@\S+\.\S+/ // Email validation regex
    const emailArray = inputEmails.split(/[ ,;]+/)
    let isValid = true
    emailArray.forEach((email) => {
      if (!regex.test(email.trim())) {
        isValid = false
      }
    })
    if (isValid) {
      setEmailError('')
      setPayload((prevState) => ({
        ...prevState,
        emailIds: emailArray,
      }))
    } else {
      setEmailError(t('EMAIL_INVAIL_ERROR'))
    }
  }

  const handleReportTypeOption = (event, newValue) => {
    if (newValue === null) {
      setPayload({
        ...payload,
        reportType: '',
      })
    } else {
      setPayload({
        ...payload,
        reportType: newValue.label,
      })
    }
  }

  const handleModeTypeOption = (event, newValue) => {
    if (newValue === null) {
      setPayload({
        ...payload,
        mode: '',
      })
    } else if (payload.mode === t('EMAIL_REPORT_MODE_Weekly')) {
      setPayload({
        ...payload,
        mode: newValue.label,
      })
    } else {
      setPayload({
        ...payload,
        mode: newValue.label,
      })
    }
  }

  const handleWeeklyOptionChange = (event, value) => {
    if (value === "") {
      setWeeklyOptionValue('')
    } else {
      setWeeklyOptionValue(value)
    }
  }


  const handleCancel = () => {
    history.push({
      pathname: REACT_DASHBOARD_PANEL,
    })
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    // const month = (date.getMonth() + 1).toString().padStart(2, '0');(apart from month has number mean we can use this command const month = monthNames[date.getMonth()] this line )
    const month = monthNames[date.getMonth()]
    const day = date.getDate()
    const year = date.getFullYear()
    return `${month} ${day}, ${year}`
  }
  let formattedDate = ''
  if (payload.mode === t('EMAIL_REPORT_MODE_Monthly')) {
    formattedDate = formatDate(payload.monthlyDate)
  }
  const handleSelectReport = (data) => {
    let reportType = '';
    switch (data) {
      case t('EMAIL_REPORT_TYPE_Dealer_Reports'):
        reportType = 'Dealer Reports';
        break;
      case t('EMAIL_REPORT_TYPE_Dealer_Transactions'):
        reportType = 'Dealer Transactions';
        break;
      case t('EMAIL_REPORT_TYPE_Customer_Transactions'):
        reportType = 'Customer Transactions';
        break;
      default:
        reportType = '';
    }
    setSelectReport(reportType);
    return reportType;
  };

  const handleSelectWeekly = (data) => {
    let weekly = '';
    switch (data) {
      case t('EMAIL_REPORT_MODE_WEEKLY_Sunday'):
        weekly = 'Sunday';
        break;
      case t('EMAIL_REPORT_MODE_WEEKLY_Monday'):
        weekly = 'Monday';
        break;
      case t('EMAIL_REPORT_MODE_WEEKLY_Tuesday'):
        weekly = 'Tuesday';
        break;
      case t('EMAIL_REPORT_MODE_WEEKLY_Wednesday'):
        weekly = 'Wednesday';
        break;
      case t('EMAIL_REPORT_MODE_WEEKLY_Thursday'):
        weekly = 'Thursday';
        break;
      case t('EMAIL_REPORT_MODE_WEEKLY_Friday'):
        weekly = 'Friday';
        break;
      case t('EMAIL_REPORT_MODE_WEEKLY_Saturday'):
        weekly = 'Saturday';
        break;
      default:
        weekly = '';
    }
    setSelectedWeekly(weekly);
    return weekly;
  };

  const handleSelectMode = (data) => {
    let modeType = '';
    switch (data) {
      case t('EMAIL_REPORT_MODE_Daily'):
        modeType = 'Daily';
        break;
      case t('EMAIL_REPORT_MODE_Weekly'):
        modeType = 'Weekly';
        break;
      case t('EMAIL_REPORT_MODE_Monthly'):
        modeType = 'Monthly';
        break;
      default:
        modeType = '';
    }
    setSelectMode(modeType);
    return modeType;
  };


  const handleSubmit = () => {
    if (payload.mode === t('EMAIL_REPORT_MODE_Weekly')) {
      if (
        CommonUtil.isEmptyString(payload.mode) ||
        CommonUtil.isEmptyString(weeklyOptionValue)
      ) {
        return Toast(t('EMAILREPORTWEEKLYERROR'), 'error')
      }
    } else if (payload.mode === 'Monthly') {
      if (
        CommonUtil.isEmptyString(payload.mode) ||
        CommonUtil.isEmptyString(payload.monthlyDate)
      ) {
        return Toast(t('EMAILREPORTMONTHLYWERROR'), 'error')
      }
    }
    const selectedReport = handleSelectReport(payload.reportType);
    const selectedMode = handleSelectMode(payload.mode);
    const selectWeekly = handleSelectWeekly(weeklyOptionValue.label)
    const modeWeeklyandMonthly = selectedMode + (selectedMode === 'Weekly'
      ? ' ' + selectWeekly
      : selectedMode === 'Monthly'
        ? ' ' + formattedDate
        : '')
    if (
      new Date(payload.timePeriodStartDate) < new Date() &&
      new Date(payload.timePeriodStartDate) <
      new Date(payload.timePeriodEndDate)
    ) {
      const emailReportDTO = {
        emailIds: payload.emailIds,
        reportType: selectedReport,
        timePeriodStartDate: CommonUtil.formatToUtc(payload.timePeriodStartDate),
        timePeriodEndDate: CommonUtil.formatToUtc(payload.timePeriodEndDate),
        mode: modeWeeklyandMonthly,
      }
      dispatch(createEmailReport(emailReportDTO))
        .unwrap()
        .then((data) => {
          switch (data.code) {
            case "MSGS4034":
              Toast(t('EMAILREPORTSUCCESS'), 'success')
              break;
            case "MSGE4055":
              Toast(t('EMAILNOTIFICATIONEMAILIDEMPTY'), 'error')
              break;
            case "MSGE4056":
              Toast(t('EMAILNOTIFICATIONREPORTTYPEEMPTY'), 'error')
              break;
            case "MSGE4058":
              Toast(t('EMAILNOTIFICATIONMODEEMPTY'), 'error')
              break;

            default:
              break;
          }
        })
    } else if (
      new Date(payload.timePeriodStartDate) > new Date() &&
      new Date(payload.timePeriodStartDate) <
      new Date(payload.timePeriodEndDate)
    ) {
      const emailReportDTO = {
        emailIds: payload.emailIds,
        reportType: selectedReport,
        timePeriodStartDate: CommonUtil.formatToUtc(payload.timePeriodStartDate),
        timePeriodEndDate: CommonUtil.formatToUtc(payload.timePeriodEndDate),
        mode: modeWeeklyandMonthly,
      }
      dispatch(createEmailReport(emailReportDTO))
        .unwrap()
        .then((data) => {
          if (data.code === 'MSGS4034') {
            Toast(t('EMAILREPORTSUCCESS'), 'success')
          } else {
            Toast(data.message, 'error')
          }
        })
    } else {
      Toast(t('EMAILREPORTTIMEERROR'), 'error')
    }
  }

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ m: 2, marginTop: -3 }}>
          <TitleBar title={t('EMAILREPORTHEADER')} back_button={true} />
        </Box>
      </Grid>
      <Paper>
        <Grid
          sx={{ width: { xs: '50%' } }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px',
          }}
        >
          <Grid
            item
            xs={12}
            sm={9}
            style={{ marginTop: '20px', width: '115%' }}
          >
            <Autocomplete
              noOptionsText={'No Options found'}
              onChange={handleModeTypeOption}
              options={modeOptions}
              value={payload.mode}
              renderInput={(params) => (
                <TextField {...params} label={t('EMAILREPORTMODETYPE')} />
              )}
            />
          </Grid>

          {payload.mode === t('EMAIL_REPORT_MODE_Weekly') && (
            <Grid
              item
              xs={12}
              sm={9}
              style={{ marginTop: '20px', width: '115%' }}
            >
              <Autocomplete
                noOptionsText={'No Options found'}
                onChange={handleWeeklyOptionChange}
                options={weeklyOptions}
                value={weeklyOptionValue}
                renderInput={(params) => (
                  <TextField {...params} label={t('EMAILREPORTMODETYPEWEEKLY')} />
                )}
              />
            </Grid>
          )}

          {payload.mode === t('EMAIL_REPORT_MODE_Monthly') && (
            <Grid
              item
              xs={12}
              sm={9}
              style={{ marginTop: '20px', width: '115%' }}
            >
              <CustomDatePicker
                onChange={(monthlyDate) =>
                  setPayload({
                    ...payload,
                    monthlyDate: monthlyDate,
                  })
                }
                value={payload.monthlyDate}
                label={t('EMAILREPORTMODETYPEMONTHLY')}
                changeDate={changeDate}
                date={'MMM/dd/yyyy'}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={9} style={{ marginTop: '20px' }}>
            <TextField
              style={{ width: '115%' }}
              label={t('EMAILREPORTEMAILID')}
              multiline
              rows={4}
              value={emails}
              onChange={handleEmailsChange}
              placeholder={t('EMAIL_REPORT_PLACEHOLER_EMAIL_TEXTFIELD')}
              error={!!emailError}
              helperText={emailError}
              required={true}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            style={{ marginTop: '20px', width: '115%' }}
          >
            <Autocomplete
              noOptionsText={'No Options found'}
              onChange={handleReportTypeOption}
              options={reportTypeOptions}
              value={payload.reportType}
              renderInput={(params) => (
                <TextField {...params} label={t('EMAILREPORTREPORTTYPE')} />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            style={{ marginTop: '20px', width: '115%' }}
          >
            {date === 'DD-MM-YYYY' && (
              <CustomDateTimePicker
                onChange={(timePeriodStartDate) =>
                  setPayload({
                    ...payload,
                    timePeriodStartDate: timePeriodStartDate,
                    timePeriodEndDate: CommonUtil.formatToUtc(
                      moment(timePeriodStartDate).add(10, 'minutes'),
                    ),
                  })
                }
                value={payload.timePeriodStartDate}
                label={t('EMAILREPORTTIMESTARTDATE')}
                changeDate={changeDate}
                date={'dd-MM-yyyy HH:mm'}
              />
            )}
            {date === 'MM-DD-YYYY' && (
              <CustomDateTimePicker
                onChange={(timePeriodStartDate) =>
                  setPayload({
                    ...payload,
                    timePeriodStartDate: timePeriodStartDate,
                    timePeriodEndDate: CommonUtil.formatToUtc(
                      moment(timePeriodStartDate).add(10, 'minutes'),
                    ),
                  })
                }
                value={payload.timePeriodStartDate}
                label={t('EMAILREPORTTIMESTARTDATE')}
                changeDate={changeDate}
                date={'MM-dd-yyyy HH:mm'}
              />
            )}
            {date === 'YYYY-MM-DD' && (
              <CustomDateTimePicker
                onChange={(timePeriodStartDate) =>
                  setPayload({
                    ...payload,
                    timePeriodStartDate: timePeriodStartDate,
                    timePeriodEndDate: CommonUtil.formatToUtc(
                      moment(timePeriodStartDate).add(10, 'minutes'),
                    ),
                  })
                }
                value={payload.timePeriodStartDate}
                label={t('EMAILREPORTTIMESTARTDATE')}
                changeDate={changeDate}
                date={'yyyy-MM-dd HH:mm'}
              />
            )}

          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            style={{ marginTop: '20px', width: '115%' }}
          >
            {date === 'DD-MM-YYYY' && (
              <CustomDateTimePicker
                onChange={(timePeriodEndDate) =>
                  setPayload({
                    ...payload,
                    timePeriodEndDate: timePeriodEndDate,
                  })
                }
                value={payload.timePeriodEndDate}
                label={t('EMAILREPORTTIMEENDDATE')}
                changeDate={changeDate}
                date={'dd-MM-yyyy HH:mm'}
              />
            )}
            {date === 'MM-DD-YYYY' && (
              <CustomDateTimePicker
                onChange={(timePeriodEndDate) =>
                  setPayload({
                    ...payload,
                    timePeriodEndDate: timePeriodEndDate,
                  })
                }
                value={payload.timePeriodEndDate}
                label={t('EMAILREPORTTIMEENDDATE')}
                changeDate={changeDate}
                date={'MM-dd-yyyy HH:mm'}
              />
            )}
            {date === 'YYYY-MM-DD' && (
              <CustomDateTimePicker
                onChange={(timePeriodEndDate) =>
                  setPayload({
                    ...payload,
                    timePeriodEndDate: timePeriodEndDate,
                  })
                }
                value={payload.timePeriodEndDate}
                label={t('EMAILREPORTTIMEENDDATE')}
                changeDate={changeDate}
                date={'yyyy-MM-dd HH:mm'}
              />
            )}

          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              marginTop: '50px',
              paddingBottom: '100px',
              marginLeft: '135px',
              marginBottom: '300px',
            }}
          >
            <Grid item sx={{ ml: 3 }}>
              <CancelButtons
                onClick={handleCancel}
                color="secondary"
                variant="contained"
              >
                <Typography>{t('COMMON008')}</Typography>
              </CancelButtons>
            </Grid>
            <Grid item sx={{ ml: 3 }}>
              <SubmitButtons onClick={handleSubmit} variant="contained">
                <Typography>{t('COMMON007')}</Typography>
              </SubmitButtons>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default EmailReportCreate
