import { Typography } from '@material-ui/core'
import { Grid } from '@mui/material'
import React, {useContext, useEffect} from 'react'
import ApprovalTabs from './ApprovalTabs'
import './Approvals.css';
import HeaderToolbar from '../../components/HeaderToolbar';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../theme/ThemeContext';

function Approvals(props) {
  const { t } = useTranslation();
  const { darkMode } = useContext(ThemeContext);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);
    return (
        <>
        {props.location.state === undefined ? <>
        <HeaderToolbar 
            title={t('commonsPathApprovals')}
            />
            <ApprovalTabs/> 
            </>
            : 
            <>
            <HeaderToolbar title={t("commonsPathApprovals")} />
            <ApprovalTabs modes={props.location.state.modes}/>
            </> }
           
        </>
    )   
}

export default Approvals