import { Box } from '@material-ui/core'
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DataTable from '../../components/DataTable/DataTable'
import Search from '../../components/SearchTab/Search'
import { getDeviceTypeList } from '../../slices/DeviceTypeSlice/DeviceTypeSlice'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import DeleteIcon from '@mui/icons-material/Delete';
import DeviceTypeIcon from '../Icons/DeviceTypeIcon'
import CreateDeviceType from './CreateDeviceType'
import EditChecklistIcon from '../Checklist/EditChecklistIcon'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import UpdateDeviceType from '../../components/DialogBoxComponent/UpdateDeviceType'


function DeviceTypeLists() {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [clear, setClear] = React.useState(false)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const history = useHistory()
  const dispatch = useDispatch()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const companyId = localStorage.getItem('companyId')
  const [loading, setLoading] = useState(true)
  const [pageSize, setPageSize] = useState(0)
  const [openUpdateDialog , setOpenUpdateDialog] = useState(false);
  const [data , setData] = useState({})

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, pageNumber: 1 })
    setfilter(defaultFilter)
  }

  const globalsearch = (searchedVal) => {
    const lowerCaseSearchedVal = searchedVal.toLowerCase();
    const filteredRows = rows.filter((test) => {
      const deviceModel = test.deviceModel ? test.deviceModel.toLowerCase() : '';
      const androidVersion = test.androidVersion ? test.androidVersion.toString() : '';
      const versionString = `${test.majorVersion}.${test.minorVersion}.${test.patchVersion}`;
      return deviceModel.includes(lowerCaseSearchedVal) ||
        androidVersion.includes(lowerCaseSearchedVal) ||
        versionString.includes(lowerCaseSearchedVal);
    });
    setRows(filteredRows);
  };
  const handleChange = (newPage, size) => {
    setPage(newPage)
    setPaging({
      pageNumber: newPage,
      pageSize: size,
    })
  }

  const [defaultFilter, setDefaultFilter] = useState({

  })

  const [filter, setfilter] = useState({

  })

  const [paging, setPaging] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  // const handleFilter = (data) => {
  //   setClear(true)
  //   setfilter({
  //     ...filter,
  //     status: data.status,
  //     name: data.name,
  //     email: data.email,
  //     subscriptionName: data.subscriptionName
  //   })
  // }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging])

  const loadData = (filter, paging) => {
    dispatch(getDeviceTypeList(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4000') {
          setLoading(false)
          setTotalRecords(data.data.totalCount)
          setRows(data.data.deviceType)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }


  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 30) + '...'
    } else {
      return text
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo

    />
  )
  const columns = React.useMemo(() => [
    {
      field: 'company.profile',
      headerName: '',
      sortable: false,
      width: 50,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },
    {
      field: 'DeviceMode1',
      headerName: t('DEVICE_TYPE_MODEL'),
      flex: 1,
      minWidth: 500,
      valueGetter: (params) => params.row.deviceModel,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'androidVersion',
      headerName: t('DEVICE_TYPE_VERSION'),
      flex: 1,
      minWidth: 350,
      valueGetter: (params) => params.row.androidVersion,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'currentApplication',
      headerName: t('DEVICE_TYPE_APPLICATION'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.majorVersion + "." + params.row.minorVersion + "." + params.row.patchVersion,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
      <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label={t('COMMON052')}
          onClick={() => {
            setOpenUpdateDialog(!openUpdateDialog)
            handleUpdateDeviceType(params.row)}}
        />,
      ]
    }

  ])

  const handleUpdateDeviceType = (data) => {
    setData(data)
  }

  const updateDeviceType = () => {

  }

  const handleCreateNewDeviceType = () => {
    history.push("/vis/create-Device-Type")
  }


  return (
    <>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={'space-between'}
        alignItems={"center"}
      >
        <Box item p={1}>
          <Typography style={{ fontWeight: '410', fontSize: '25px', lineHeight: '25px', color: '#3D4977' }}>
           {t('DEVICE_TYPE_HEADER')}
          </Typography>
        </Box>
        <Box item p={1}>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={'space-between'}
            alignItems={"center"}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box item>
                <Search
                  onSearch={(e) => globalsearch(e)}
                  clearSearch={() => loadData(filter, paging)}
                />
              </Box>
              {/* <Box>
                <Tooltip title = {"Add Device Type"}>
                <IconButton onClick={handleCreateNewDeviceType}>
                  <DeviceTypeIcon />
                </IconButton>
                </Tooltip>
              </Box> */}
              <Box display={"flex"} flexDirection={"row-reverse"} alignItems={"center"}>
                <Box item>
                  {clear && (
                    <Typography
                      style={{
                        color: "#E3393C",
                        fontSize: "14px",
                        textDecoration: 'underline',
                        cursor: 'pointer'
                      }}
                      onClick={() => resetFilter()}
                    >
                      {"Clear Filter"}
                    </Typography>
                  )}
                </Box>
              </Box>

            </Stack>
          </Box>
        </Box>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}
      <div style={{ height: 300, width: '100%', marginTop: '15px' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {
        openUpdateDialog && (
          <UpdateDeviceType 
          onClose = {() => {
            setOpenUpdateDialog(!openUpdateDialog)
            loadData(filter, paging)
          }}
          onSubmit = {updateDeviceType}
          open = {openUpdateDialog}
          data = {data}
          />
        )
      }
    </>
  )
}
export default DeviceTypeLists
