const CommonUtil = {
  isValidEmail(email) {
    if (email === undefined || email === '') return true
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    var mailformat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    //return mailformat.test(String(email).toLowerCase());
    const regex = /^(?!\.)(?!.*\.$)(?!.*?\.\.)+(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return regex.test(String(email).toLowerCase())
  },
  isValidMobile(mobile) {
    if (mobile === undefined || mobile === '') return true
    const re = /^(\+\d{1,3}[- ]?)?\d{10,15}$/
    return re.test(String(mobile))
  },

  isExceptNumericName(name) {
    if (name === undefined || name === '') return true
    let finalString = name.replace(/[^\d.-]/g, '')
    return finalString
  },

  isValidName(name) {
    if (name === undefined || name === '') return true
    var regex = /^[a-zA-Z ]{2,30}$/
    return regex.test(name)
  },

  isAlphaNumeric(data) {
    if (data === undefined || data === '') return true
    var regex = /^[a-z0-9]+$/i
    return regex.test(data)
  },

  isAlphaNumericWithSpace(data) {
    if (data === undefined || data === '') return true
    var regex = /^[a-zA-Z0-9 ]*$/i
    return regex.test(data)
  },

  isAlphabets(data) {
    if (data === undefined || data === '') return true
    var regex = /^[a-zA-Z]+$/
    return regex.test(data)
  },

  isValidNumericName(name) {
    if (name === undefined || name === '') return true
    var regex = /^[A-Za-z0-9 ]+$/
    return regex.test(name)
  },

  isValidLowerCase(name) {
    if (name === undefined || name === '') return true
    var regex = /^[a-z]+$/
    return regex.test(name)
  },

  isValidUpperCase(name) {
    if (name === undefined || name === '') return true
    var regex = /^[A-Z]+$/
    return regex.test(name)
  },

  isValidNumeric(name) {
    if (name === undefined || name === '') return true
    var regex = /^[0-9\. ]+$/
    return regex.test(name)
  },

  isValidNumericWithSpace(name) {
    if (name === undefined || name === '') return true
    var regex = /^(?!-)[\d\s-]+$/
    return regex.test(name)
  },

  isValidDoubleValue(value) {
    if (value === undefined || value === '') return true
    var regex = /^[+-]?([1-9][0-9]*|0)(\.[0-9]+)?((e|E)[+-]?[0-9]+)?$/
    return regex.test(value)
  },

  isValidCode(code) {
    if (code === undefined || code === '') return true
    var regex = /^[a-zA-Z0-9_-]*$/
    return regex.test(code)
  },
  isValidZipcode(areaCode) {
    if (
      areaCode.length < 5 ||
      areaCode.length > 6 ||
      !areaCode.match(/^[0-9 ]+$/)
    )
      return true
  },
  isAlphaNumericSlash(code) {
    if (code === undefined || code === '') return true
    var regex = /^[a-zA-Z0-9-]*$/
    return regex.test(code)
  },

  isEmptyString(val) {
    if (val === null || val === undefined || val === '') return true
    else return false
  },

  isValidPassword(password) {
    if (password === undefined || password === '') return true
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    return re.test(String(password))
  },
  formatDayAndMonth(numValue) {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  },

  isEmpty(obj) {
    if (obj === null || obj === undefined) return true
    return Object.keys(obj).length === 0
  },
  getCompany() {
    return localStorage ? localStorage.getItem('companyId') : null
  },

  getLocalDate(date) {
    var newDate = new Date(date)
    var formated =
      newDate.getFullYear() +
      '-' +
      (newDate.getMonth() + 1) +
      '-' +
      newDate.getDate() +
      ' ' +
      (newDate.getHours() < 10
        ? '0' + newDate.getHours()
        : newDate.getHours()) +
      ':' +
      (newDate.getMinutes() < 10
        ? '0' + newDate.getMinutes()
        : newDate.getMinutes())
    return formated
  },

  getLocalDate1(date) {
    var newDate = new Date(date)
    return newDate
      .toISOString()
      .replace('T', ' ')
      .substr(0, newDate.toISOString().lastIndexOf(':'))
  },

  getLocalTime(time) {
    var newTime = new Date(parseInt(time))
    return newTime.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    })
  },

  TreeDataFormat(arr) {
    var arr1 = []
    for (var i = 0; i < arr.length; i++) {
      var obj = {}
      obj['value'] = arr[i].id
      obj['label'] = arr[i].name
      obj['parentid'] = arr[i].parentId
      arr1.push(obj)
    }
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem

    // First map the nodes of the array to an object -> create a hash table.
    for (var k = 0, len = arr1.length; k < len; k++) {
      arrElem = arr1[k]
      mappedArr[arrElem.value] = arrElem
      mappedArr[arrElem.value]['children'] = []
    }

    for (var value in mappedArr) {
      if (mappedArr.hasOwnProperty(value)) {
        mappedElem = mappedArr[value]
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parentid) {
          try {
            mappedArr[mappedElem['parentid']]['children'].push(mappedElem)
          } catch (err) {}
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem)
        }
      }
    }
    return tree
  },

  ZoneDataFormat(arr) {
    var arr1 = []
    for (var i = 0; i < arr.length; i++) {
      var obj = {}
      obj['key'] = arr[i].id
      obj['value'] = arr[i].id
      obj['title'] = arr[i].name
      obj['parentid'] = arr[i].parentId
      arr1.push(obj)
    }
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem

    // First map the nodes of the array to an object -> create a hash table.
    for (var k = 0, len = arr1.length; k < len; k++) {
      arrElem = arr1[k]
      mappedArr[arrElem.value] = arrElem
      mappedArr[arrElem.value]['children'] = []
    }

    for (var value in mappedArr) {
      if (mappedArr.hasOwnProperty(value)) {
        mappedElem = mappedArr[value]
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parentid) {
          mappedArr[mappedElem['parentid']]['children'].push(mappedElem)
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem)
        }
      }
    }
    return tree
  },
  sentenceCase(str) {
    if (str === null || str === '') return false
    else str = str.toString()

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  },
  getDefaultCountryCode() {
    var countryCode = ''
    if (localStorage.getItem('locationInfo')) {
      countryCode = JSON.parse(
        localStorage.getItem('locationInfo'),
      ).country_code.toLowerCase()
    }
    return countryCode
  },
  getLocationDetails(query) {
    var res = ''
    if (localStorage.getItem('locationInfo')) {
      var location = JSON.parse(localStorage.getItem('locationInfo'))
      switch (query) {
        case 'ip': {
          res = location.ip
          break
        }
        case 'latitude': {
          res = location.latitude
          break
        }
        case 'longitude': {
          res = location.longitude
          break
        }
        case 'country_code': {
          res = location.country_code
          break
        }
        case 'country_name': {
          res = location.country_name
          break
        }
        case 'country_calling_code': {
          res = location.country_calling_code
          break
        }
      }
    }
    return res
  },
  setCookie(cname, cvalue, exdays) {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  },
  getCookie(cname) {
    let name = cname + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  },
}

export default CommonUtil
