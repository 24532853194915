import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import watchlistGlobalService from "../../services/WatchlistGlobal/WatchlistGlobalService";

const initialState = [];

export const getAllWatchlistTemplate = createAsyncThunk('watchlist/list', async (payload) => {
    const res = await watchlistGlobalService.getAllWatchlistTemplate(payload);
    return res.data;
});

export const createWatchlistTemplate = createAsyncThunk('watchlist/create', async (payload) => {
    const res = await watchlistGlobalService.createWatchlistTemplate(payload);
    return res.data;
});

export const updateWatchlistTemplate = createAsyncThunk('watchlist/update', async (payload) => {
    const res = await watchlistGlobalService.updateWatchlistTemplate(payload);
    return res.data;
})

export const deleteWatchlistTemplate = createAsyncThunk('watchlist/delete', async (payload) => {
    const res = await watchlistGlobalService.deleteWatchlistTemplate(payload);
    return res.data;
});

const watchlistTemplateSlice = createSlice({
    name: 'getAllWatchlistTemplate',
    initialState,
    extraReducers: {
        [getAllWatchlistTemplate.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})
const { reducer } = watchlistTemplateSlice;

export default reducer;