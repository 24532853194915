import { Typography } from '@material-ui/core';
import { Grid, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CommonUtil from '../../Util/CommonUtils';
import HeaderToolbar from '../../components/HeaderToolbar';
import { getUserDetails } from '../../slices/Dealer/DealerSlice';
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice';

function CustomersReportView(props) {

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const companyId = localStorage.getItem('companyId')
    const [dateFormat, setDateFormat] = useState('')

    const history = useHistory();
    const handleTextOverflow = (text) => {
        if (text && text.length > 35) {
            return text.substring(0, 25) + '...';
        } else {
            return text;
        }
    }

    useEffect(() => {
        loadUserDetails();
        loadSetting();
    }, []);
    const emailValue = props.location.state.email
    const loadUserDetails = () => {
        dispatch(getUserDetails(emailValue))
            .unwrap()
            .then((data) => {
                if (data.code === "UASI0033") {
                    setFirstName(data.data.firstName)
                    setLastName(data.data.lastName)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const loadSetting = () => {
        dispatch(getListSettingsById(companyId))
            .unwrap()
            .then((data) => {
                if (data.code === 'CVAI0000') {
                    setDateFormat(data.data.adminAndDealerDateFormat)
                }
            }
            )
    }

    return (
        <>

            <HeaderToolbar
                title={t('VIEWPAGECUSTOMERREPORT')}
                back_button={true}
            />


            <Grid container lg={12} md={12} item sx={{ mt: "20px", bgcolor: "white" }} >
                <Grid item padding={2}>
                    <Avatar alt="Remy Sharp" src={props.location.state.imagePreSignedURL ? props.location.state.imagePreSignedURL : ""} style={{ height: "180px", width: "180px", marginLeft: "40px" }} />
                </Grid>
                <Grid item xs zeroMinWidth style={{ marginLeft: "20px", marginTop: "20px", borderRight: "3px solid #E1E9F5" }}  >
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>{t('DEALERFIRSTNAME')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${firstName}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(firstName)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>{t('DEALERLASTNAME')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${lastName}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(lastName)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>{t('COMMON042')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${props.location.state.email}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(props.location.state.email)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>{t('VIEWPAGEMOBLIENUMBER')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${props.location.state.phone}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(props.location.state.phone)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>{t('DEALERCOMPANYNAME')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${props.location.state.name}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(props.location.state.name)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>



                <Grid item xs zeroMinWidth style={{ marginLeft: "20px", marginTop: "20px", borderRight: "3px solid #E1E9F5" }}>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} columnSpacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>{t('CUSTDATABLE04')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${props.location.state.name}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(firstName + " " + lastName)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} columnSpacing={1} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>{t('DEALERTABSUBSCRIPTION')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${props.location.state.subscriptionName}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(props.location.state.subscriptionName)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} columnSpacing={1}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>{t('CUSTDATABLE20')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ alignContent: 'end', alignItems: 'end' }}>
                            <Tooltip title={`${props.location.state.planBasePrice}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(props.location.state.planBasePrice/100)}</Typography>
                            </Tooltip>
                        </Grid>

                    </Grid>

                    <Grid container style={{ marginTop: "10px" }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} columnSpacing={1}>
                            <Typography style={{ opacity: "50%" }}>{t('CUSTDATABLE38')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography style={{ marginLeft: '5px' }}>
                                {props.location.state.subscriptionEndDate ? (
                                    dateFormat === 'DD-MM-YYYY' ?
                                        moment(CommonUtil.getLocalDateTimeInYearFormat(props.location.state.subscriptionEndDate)).format("DD-MM-YYYY HH:mm") :
                                        dateFormat === 'MM-DD-YYYY' ?
                                            moment(CommonUtil.getLocalDateTimeInYearFormat(props.location.state.subscriptionEndDate)).format("MM-DD-YYYY HH:mm") :
                                            dateFormat === 'YYYY-MM-DD' ?
                                                moment(CommonUtil.getLocalDateTimeInYearFormat(props.location.state.subscriptionEndDate)).format("YYYY-MM-DD HH:mm") :
                                                ''
                                ) : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} columnSpacing={1} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>{t('CUSTDATABLE39')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title={`${props.location.state.planBasePrice}`} placement="bottom-start">
                                <Typography noWrap>{handleTextOverflow(props.location.state.planBasePrice/100)}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item xs zeroMinWidth style={{ marginLeft: "20px", marginTop: "20px", borderRight: "3px solid #E1E9F5" }}>
                    <Grid container wrap="nowrap" style={{ marginTop: "10px" }} columnSpacing={1} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>{t('CUSTDATABLE09')}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>


        </>
    )
}

export default CustomersReportView