import { Grid } from '@material-ui/core'
import React from 'react'
import { Tooltip, Typography } from '@mui/material'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import { ReactComponent as DeleteIcon } from '../../../src/assets/DeleteIcon.svg'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Toast from '../../components/ToastContainer/CustomToast'

import { deleteUser } from '../../slices/User/UserSlice'

export default function DeleteUser(props) {
  const { open, handleClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [payload, setPayload] = React.useState({
    userId: props.DeleteId,
    name: props.DeleteName,
  })

  const handleSubmit = () => {
    dispatch(deleteUser(payload.userId))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0003' || data.code === 'UASI0028') {
          Toast(t('USERDELETE'), 'success')
          handleClose(false)
          props.delete()
        } else {
          Toast(data.message, 'error')
          handleClose(false)
        }
      })
  }
  const handleTextOverflow = (text) => {
    if (text && text.length > 10) {
      return text.substring(0, 25) + '...'
    } else {
      return text
    }
  }

  return (
    <>
      <DialogBox
        Header={t('dataTableDelete')}
        acceptText={t('CONFIRM')}
        cancelText={t('COMMON008')}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={'center'}
          style={{ padding: '20px' }}
        >
          <Grid>
            <DeleteIcon width="60px" height={'50px'} />
          </Grid>
          <Grid>
            <Grid
              style={{
                wordWrap: 'break-word',
                opacity: 1,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
                fontSize: '17px',
                marginTop: '15px',
              }}
            >
              <Typography
                style={{
                  color: '#242424',
                  fontSize: '15px',
                  marginRight: '4px',
                }}
              >
                {t('USERDELETEINFO')} {''}
              </Typography>
              <Tooltip title={payload.name}>
                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>
                  {handleTextOverflow(payload.name)}
                </Typography>
              </Tooltip>
              <Typography
                style={{
                  color: '#242424',
                  marginRight: '2px',
                  fontSize: '15px',
                }}
              >
                ?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
