import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid, Link, Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../components/DialogBoxComponent/RoleDialogBox";
import { useDispatch} from "react-redux";
import CommonUtil from "../../Util/CommonUtils";
import Toast from "../../components/ToastContainer/CustomToast";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import Tab from '@mui/material/Tab';
import { TabContext, TabList} from "@mui/lab";
import { options } from "./PermissionTree";
import { createUserRoles, getPermissionsList } from "../../slices/UserRole/UserRoleSlice";
import { defaultPermissionCode } from "./DefaultPermissionsCode";


export default function AddUserRole(props) {

  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [permissionList, setPermissionList] = useState([]);
  const [state, setState] = React.useState({
    id: '',
    name: '',
    description: '',
    scope: 'CUSTOM',
    permissionId:'',
  });
  const [child,setChild]=useState([]);
  const [codes,setCodes]=useState([]);
  const [permissions, setPermissions] = useState(defaultPermissionCode);
  const [error, setError] = useState({ name: '', code: '' });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const [selectedOption, setSelectedOption] = React.useState(codes);
  const handleChanges = (event, newValue) => {
    // alert("selected Option" + JSON.stringify(newValue))
    setSelectedOption(newValue);
  }

     //This is for getting permission list 
     useEffect(()=>{
      dispatch(getPermissionsList())
      .unwrap()
      .then((data) => {
        if(data.code==="UASI0000"){
           setPermissionList(data.data.permissions);
        }
      });
   },[]) 

  const handleSubmit = () => {
    if (CommonUtil.isEmpty(state.name)) {
      Toast(("Please Enter Name"), 'error')
      setError({ name: true });
      return;
    }

    if (CommonUtil.checkCharactersGreaterThan250(state.description)) {
      Toast(("Please Enter Description"), 'error')
      setError({ description: true });
      return;
    }
    var newPermissions = [];
    permissionList.map((p) => {
      if (permissions[p.code]) {
        var permission =  p.id ;
        newPermissions.push(permission);
      }
    });
    const payload = {
      id: props.selectedObject.id,
      name:state.name,
      scope:state.scope,
      description:state.description,
      permissionId:newPermissions,
      
    };
      dispatch(createUserRoles(payload))
        .unwrap()
        .then((data) => {
          if (data.code === "UASI0045") {
            Toast("Role created successfully.", 'success')
            handleClose();
          } else {
            Toast(data.message, "error")
          }
        });
  };
  const handleChecked = (codes,e) => {
    permissions[e.target.name] = e.target.checked;
    setPermissions({
      ...permissions
    });
  };
  const isChecked=(code)=>{
    return permissions[code];
  }
  const functionSelectAllPermission=()=>{
    child.map((val)=>{
      permissions[val.code]=true;
      setPermissions({
          ...permissions
        })
    })
  }

  const functionDeSelectAllPermission=()=>{
    child.map((val)=>{
      permissions[val.code]=false;
      setPermissions({
          ...permissions
        })
    })
  }
  return (
    <>
      <DialogBox
        Header={state.roleId ? "Update Role" : "Add Role"}
        acceptText="Submit"
        cancelText="Cancel"
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid item xs={12} style={{ marginTop: "25px", display: "flex" }}>
          <Grid item xs={6} width={1}>
            <Typography
              href="#"
              style={{
                color: "#3D4977",
                fontSize: "18px",
                fontWeight: "450",
                paddingLeft: "25PX",
                opacity: "100%",
              }}
            >
              {t("Role Basic Info")} {""}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          //justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >

          <Grid item xs={7}>
            <CustomTextfield
              type={'text'}
              label="Role Name"
              error={error.name}
              name="name"
              value={state.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
              required={true}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>

          <Grid item xs={7} >
            <CustomTextfield
              type={'text'}
              label="Description"
              error={error.description}
              name="description"
              value={state.description}
              handleChange={(e) => handleChange(e)}
              helperText={error.description}
              inputProps={{ maxLength: 150 }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "10px", display: "flex" }}>
          <Grid item xs={6} width={1}>
            <Typography
              href="#"
              style={{
                color: "#3D4977",
                fontSize: "18px",
                fontWeight: "450",
                paddingLeft: "25PX",
              }}
            >
              {t("Privilege")} {""}
            </Typography>
          </Grid>
        </Grid>
         <Box>
          <TabContext value={selectedOption}>
            <Box sx={{ margin: '20px 20px 0 ' }}>
              <TabList onChange={handleChanges} aria-label="tab" centered style={{ boxShadow: 'none' }}
                indicatorColor="none">
                {options.map((option,index) => (
                  <Tab key={index} label={option.parent.title} className="tabStyle" onClick={()=>{setChild(option.child);setCodes(option.codes)}} />))}
              </TabList>
            </Box>

            <Box display={"flex"} width={"100%"} flexDirection={"row-reverse"} alignItems={"center"} >
              <Box item p={1} sx={{ m: 2 }}>
                <Link
                  href="#"
                  underline="none"
                  className="line"
                  style={{
                    opacity: "0.8",
                    color: "#3D4977",
                    fontSize: "14px",
                    underline: "hover",
                    display: "inline",
                  }}
                  onClick={()=>functionDeSelectAllPermission()}
                >
                  {"Deselect all"}
                </Link>
              </Box>
              <Box item p={1}>
                <Link
                  href="#"
                  underline="none"
                  className="line"
                  style={{
                    opacity: "0.8",
                    color: "#3D4977",
                    fontSize: "14px",
                    underline: "hover",
                    display: "inline",
                  }}
                  onClick={()=>functionSelectAllPermission()}
                >
                  {"Select all"}
                </Link>
              </Box>
            </Box>
           <Box display={'flex'} className="tabStyle" >
           {child.map((val,index)=><Stack>  
               <FormControlLabel label={val.title} style={{ color: '#3D4977', fontSize: "20px" }} control={<Checkbox name={val.code}style={{ color: '#F2824C' }} />} checked={permissions[val.code]||isChecked(val.code)} onChange={(event)=>handleChecked(codes,event)} />
                </Stack> )}
                </Box>
          </TabContext>
        </Box> 
      </DialogBox>
    </>
  );
}
