import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Typography } from '@material-ui/core';
import img1 from '../../assets/USA_Images/selfRegistartion.png';
import img1_visitor from '../../assets/USA_Images/login_images_visitor/img1.png';
import img2 from '../../assets/USA_Images/dashbaord.png';
import img2_visitor from '../../assets/USA_Images/login_images_visitor/img2.png';
import img3 from '../../assets/USA_Images/invitation.svg';
import img3_visitor from '../../assets/USA_Images/login_images_visitor/img3.svg';
import img4 from '../../assets/USA_Images/registration.svg';
import img4_visitor from '../../assets/USA_Images/login_images_visitor/img4.svg';
import img5 from '../../assets/USA_Images/visitor.svg';
import img5_visitor from '../../assets/USA_Images/login_images_visitor/img5.svg';
import img6 from '../../assets/USA_Images/visitorlog.svg';
import img6_visitor from '../../assets/USA_Images/login_images_visitor/img6.svg';

export default function SimpleSlider() {
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
    };

    return (
        <Grid
            style={{
                width: '100%',
            }}
        >
            <Slider {...settings}>
               
                <Grid item xs={12} className="carousel-item">
                    <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? img3 : img3_visitor} alt='logo' style={{ width: '600px' }} className="carousel-item" />
                </Grid>
                <Grid item xs={12} className="carousel-item">
                    <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? img4 : img4_visitor} alt='logo' style={{ width: '600px' }} className="carousel-item" />
                </Grid>
                <Grid item xs={12} className="carousel-item">
                    <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? img5 : img5_visitor} alt='logo' style={{ width: '600px' }} className="carousel-item" />
                </Grid>
                <Grid item xs={12} className="carousel-item">
                    <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? img6 : img6_visitor} alt='logo' style={{ width: '600px' }} className="carousel-item" />
                </Grid>
                {/* <Grid item xs={12} className="carousel-item">
                    <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? img2 : img1_visitor} alt='logo' style={{ width: '600px' }} className="carousel-item" />
                </Grid> */}
            </Slider>
        </Grid>
    );
}