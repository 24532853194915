export const defaultPermissionCode = {
    'DASHBOARD':false,
    'VISITOR': false,
    'INVITATION ': false,
    'WALK-IN_REGISTRATION': false,
    'APPROVALS': false,
    'BLACKLIST_VISITOR': false,
    'EMPLOYEES': false,
    'PERSON': false,
    'ROLE': false,
    'SITE_MANAGEMENT': false,
    'ADDRESS_TYPE': false,
    'SITE_TYPE': false,
    'SITE': false,
    'ZONE': false,
    'TIMES_LOTS': false,
    'VISITING_ACCESS_LEVEL': false,
    'DEVICE_MANAGEMENT': false,
    'DEVICE_LIST': false,
    'REPORT': false,
    'DAILY_REPORT': false,
    'INVITATION': false,
    'SELF_REGISTRATION': false,
    'SETTINGS': false,
    'VISITOR_TYPE': false,
    'PURPOSE_TYPE': false,
    'BLACKLIST_TYPE': false,
    'CHECKLIST': false,
    'WATCHLIST': false,
    'URGENT_MESSAGES': false,
    'ADDITIONAL_SETTINGS': false,
    'CARD_TEMPLATE': false,
}