import { makeStyles } from "@material-ui/core";
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonUtil from '../../Util/CommonUtils';
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker';
import CustomTextfield from '../../components/CustomInputs/CustomTextfield';
import CustomerDialog from '../../components/DialogBoxComponent/CustomerDialog';
import Toast from '../../components/ToastContainer/CustomToast';


const useStyles = makeStyles((theme) => ({
    dialogbox: {
        '& .MuiDialog-paper': {
            // minWidth: '110vh',
            minWidth: "600px",
            // marginLeft:"250px",
        },
        ".& .css-f3jnds-MuiDataGrid-columnHeaders": {
            backgroundColor: "#F3F9FB"
        },
    },
}))


function DealerReportFilter(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(props.open)
    const { onClose } = props;
    const { t } = useTranslation()

    const [payload, setPayload] = useState({
        name: '',
        email: '',
        status: ''
    })
    const [error, setError] = useState({
        name: false,
        email: false,
        status: false
    })


    const handleChange = (event) => {
        const name = event.target.name
        setPayload({
            ...payload,
            [name]: event.target.value,
        })

        setError({
            [name]: false,
        })
    }

    const handleSubmit = () => {
        if (payload.name.trim() === '' &&
            payload.email.trim() === '') {
            Toast(t("FILTERISEMPTY"), "error");
            return;
        }
        props.applyFilter(payload)
        onClose()
    }
    return (
        <CustomerDialog
            className={classes.dialogbox}
            Header={t('FILTER')}
            acceptText={t('COMMON024')}
            cancelText={t('COMMON008')}
            onSubmit={handleSubmit}
            open={open}
            onClose={onClose}
            cancel={onClose}
        >
            <Grid
                container
                justifyContent="center"
                style={{ padding: '0px 32px', width: '100%', marginTop: '20px' }}>
                <Grid item xs={12} sm={10}>
                    <CustomTextfield
                        label={t('FILTERDEALERNAME')}
                        error={error.name}
                        name="name"
                        value={payload.name}
                        handleChange={(e) => handleChange(e)}
                        helperText={error.name}
                        autoComplete="on"
                    />
                </Grid>
                <Grid item xs={12} sm={10}>
                    <CustomTextfield
                        label={t('DEALEREMAIL')}
                        error={error.email}
                        name="email"
                        value={payload.email}
                        handleChange={(e) => handleChange(e)}
                        helperText={error.email}
                        autoComplete="on"
                    />
                </Grid>
            </Grid>
        </CustomerDialog>
    )
}

export default DealerReportFilter