import { makeStyles } from '@material-ui/core'
import {
  Autocomplete,
  Avatar,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { REACT_URL_CARDTEMPLATE } from '../../actions/EndPoints'
import Com_logo from '../../assets/USA_Images/login_images/logo_mbl.svg'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import HeaderToolbar from '../../components/HeaderToolbar'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  addCardTemplate,
  getCardTemplateById,
  updateCardTemplate,
} from '../../slices/CardTemplate/CardTemplateSlice'
import { getAllVisitorType } from '../../slices/VisitorType/VisitorTypeSlice'
import CommonUtil from '../../Util/CommonUtils'
import LandscapeIcon from './LandscapeIcon'
import PortraitIcon from './PortraitIcon'
import { ThemeContext } from '../../theme/ThemeContext';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.light),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      // left: 12,
      fontSize: '12px',
    },
  },

  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#0F5A9C !important',
    opacity: 1,
  },
}))

const StyledBadge = styled(FormGroup)(({ theme }) => ({
  '& .MuiTypography-root': {
    left: '0px !important',
    position: 'absolute',
  },
}))
const useStyles = makeStyles((theme) => ({
  title: {
    color: '#c4c4c4 !important',
    fontSize: '20px !important',
    fontWeight: '500 !important',
  },
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}))

function AddCardTemplate(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { open, handleClose } = props
  const [visitorData, setVisitorData] = useState([])
  const { t } = useTranslation()
  const [visitorTypeId, setVisitorTypeId] = useState('')
  const [position, setPosition] = useState('Portrait')
  const { darkMode } = useContext(ThemeContext);
  const [state, setState] = React.useState({
    id: '',
    visitorTypeId: '',
    name: '',
    description: '',
    companyId: '',
    print: true,
    companyLogo: true,
    companyName: true,
    profile: true,
    firstName: true,
    lastName: false,
    mobile: true,
    email: true,
    visitorType: true,
    checkInTime: true,
    qrCode: true,
    visitorSign: true,
    cardorientation: '',
  })

  const [error, setError] = useState({
    name: false,
    visitorTypeId: false,
    description: false,
  })

  useEffect(() => {
    if (prevState !== undefined) {
      loadData()
    }
  }, [])

  useEffect(() => {
    loadVisitType()
  }, [])

  const prevState = props.history.location.state
  const handleChangeDropdown = (event) => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handleChange1 = (event) => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
  }

  const loadVisitType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getAllVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000' && data.data) {
          setVisitorData(data.data.visitorTypes)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadData = () => {
    dispatch(getCardTemplateById(prevState.data.id))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAE0096') {
          setPosition(data.data.cardorientation)
          setState({
            ...state,
            id: data.data.id,
            cardorientation: data.data.cardorientation,
            name: data.data.name,
            description: data.data.description,
            visitorTypeId: data.data.visitorTypeId,
            companyLogo: data.data.companyLogo,
            companyName: data.data.companyName,
            profile: data.data.profile,
            print: data.data.print,
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            mobile: data.data.mobile,
            email: data.data.email,
            visitorType: data.data.visitorType,
            checkInTime: data.data.checkInTime,
            qrCode: data.data.qrCode,
            visitorSign: data.data.visitorSign,
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.name)) {
      Toast(t('Enter Card Name'), 'error')
      setError({ name: true })
      return
    }
    if (CommonUtil.isEmptyString(state.visitorTypeId)) {
      Toast(t('Please select visitor type'), 'error')
      setError({ visitorTypeId: true })
      return
    }

    const dto = {
      id: state.id,
      visitorTypeId: state.visitorTypeId.id,
      name: state.name,
      description: state.description,
      companyId: state.companyId,
      print: state.print,
      companyLogo: state.companyLogo,
      companyName: state.companyName,
      profile: state.profile,
      firstName: state.firstName,
      lastName: state.lastName,
      mobile: state.mobile,
      email: state.email,
      visitorType: state.visitorType,
      checkInTime: state.checkInTime,
      qrCode: state.qrCode,
      visitorSign: state.visitorSign,
      cardorientation: state.cardorientation,
    }

    if (CommonUtil.isEmptyString(prevState)) {
      dispatch(addCardTemplate(dto))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0028' && data.data) {
            Toast(data.message, 'success')
            history.push({
              pathname: REACT_URL_CARDTEMPLATE,
              state: { ...data },
            })
            handleClose()
          } else {
            Toast(data.message, 'error')
          }
        })
    } else {
      dispatch(updateCardTemplate(dto))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0029') {
            Toast(t(data.message), 'success')
            history.push({
              pathname: REACT_URL_CARDTEMPLATE,
              state: { ...data },
            })
            handleClose()
          } else {
            Toast(t(data.message), 'error')
          }
        })
    }
  }

  useEffect(() => {
    if (props.location.state) {
      setVisitorTypeId(
        visitorData.find((visitorType) => {
          return visitorType.id === props.location.state.data.visitorTypeId
        }),
      )
    }
  }, [visitorData?.length > 0])

  useEffect(() => {
    if (props.location.state)
      setState({
        ...state,
        id: props.location.state.data.id,
        visitorTypeId: visitorTypeId,
        name: props.location.state.data.name,
        description: props.location.state.data.description,
        companyId: props.location.state.data.companyId,
        print: props.location.state.data.print,
        companyLogo: props.location.state.data.companyLogo,
        companyName: props.location.state.data.companyName,
        profile: props.location.state.data.profile,
        firstName: props.location.state.data.firstName,
        lastName: props.location.state.data.lastName,
        mobile: props.location.state.data.mobile,
        email: props.location.state.data.email,
        visitorType: props.location.state.data.visitorType,
        checkInTime: props.location.state.data.checkInTime,
        qrCode: props.location.state.data.qrCode,
        visitorSign: props.location.state.data.visitorSign,
        // cardorientation: props.location.state.cardorientation,
      })
  }, [!CommonUtil.isEmptyString(visitorTypeId)])

  const [show, setShow] = useState()

  const handlePortrait = () => {
    setShow(false)
    setState({
      ...state,
      cardorientation: 'Portrait',
    })
    setPosition('Portrait')
  }
  const handleLandscape = () => {
    setShow(true)
    setState({
      ...state,
      cardorientation: 'Landscape',
    })
    setPosition('Landscape')
  }
  const cancel = (e) => {
    history.push(REACT_URL_CARDTEMPLATE)
  }

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);
  
  return (
    <>
      <HeaderToolbar
        title={state.id ? t('EDITTEMP') : t('ADDTEMP')}
        tooltipTitle=""
      />
      <>
        <Paper sx={{ mt: 3, pb: 2 }} style={{backgroundColor: darkMode ? '#0F273B' : '' , marginLeft: darkMode ? '32px' : '' , width: darkMode ? '95%' : ''}}>
          <Grid container p={2}>
            <Grid container md={12} sm={12} spacing={2}>
              <Grid item xl={6} lg={6} md={6} sm={12}>
                <CustomTextfeild
                  label={t('COMMON069')}
                  name={'name'}
                  value={state.name}
                  error={error.name}
                  handleChange={handleChange1}
                  type={'text'}
                  inputProps={{ className: classes.input, maxLength: 30 }}
                  required
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: 2 }}>
                <Autocomplete
                  style={{ width: '100%', marginTop:'-16px' }}
                  noOptionsText={'No Options found'}
                  name="visitorTypeId"
                  value={state.visitorTypeId}
                  options={
                    CommonUtil.isEmptyArray(visitorData) ? [] : visitorData
                  }
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      // eslint-disable-next-line
                      setState({ ...state, visitorTypeId: newValue })
                    } else {
                      setState({ ...state, visitorTypeId: '' })
                    }
                    setError({ visitorTypeId: false })
                  }}
                  renderInput={(params) => (
                    <CustomTextfeild
                      {...params}
                      name="visitorTypeId"
                      value={state.visitorTypeId}
                      error={error.visitorTypeId}
                      variant="outlined"
                      fullWidth
                      label={t('COMMON068') + '*'}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12}>
                <CustomTextfeild
                  label={t('ATYPE009')}
                  name="description"
                  value={state.description}
                  handleChange={handleChange1}
                  type={'text'}
                  // validation='alphabets'
                  inputProps={{ className: classes.input, maxLength: 250 }}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </Grid>{' '}
        </Paper>
        <Paper sx={{ mt: 4 }} style={{backgroundColor: darkMode ? '#0F273B' : '' , marginLeft: darkMode ? '32px' : '' , width: darkMode ? '95%' : ''}}>
          <Grid style={{ display: 'flex' }}>
            <Grid
              sx={{ width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' } }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '30px',
              }}
            >
              <StyledBadge>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend" className={darkMode ? "labelTitle1" : "labelTitle"}>
                    {t('COMPANYINFO')}
                  </FormLabel>
                  <br />
                  <FormGroup>
                    <Grid style={{ display: 'flex' }}>
                      <Grid className="leftSwitch">
                        <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                          {t('COMPANYINFO1')}
                        </FormLabel>
                        <Android12Switch
                          checked={state.companyLogo}
                          onChange={handleChange}
                          name="companyLogo"
                        />
                      </Grid>
                      <Grid></Grid>
                    </Grid>
                    <Grid className="leftSwitch">
                      <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                        {t('VISUPDATE0006')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.companyName}
                        onChange={handleChange}
                        name="companyName"
                      />
                    </Grid>
                  </FormGroup>
                  <br />
                  <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                    {t('VISUPDATE0008')}
                  </FormLabel>
                  <br />
                  <FormGroup>
                    <Grid className="leftSwitch">
                      <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                        {t('USERPROFCARD002')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.profile}
                        onChange={handleChange}
                        name="profile"
                      />
                    </Grid>

                    <Grid className="leftSwitch">
                      <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                        {t('COMMON020')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.firstName}
                        onChange={handleChange}
                        name="firstName"
                      />
                    </Grid>

                    <Grid className="leftSwitch">
                      <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                        {t('COMMON021')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.lastName}
                        onChange={handleChange}
                        name="lastName"
                      />
                    </Grid>

                    <Grid className="leftSwitch">
                      <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                        {t('COMMON063')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.mobile}
                        onChange={handleChange}
                        name="mobile"
                      />
                    </Grid>

                    <Grid className="leftSwitch">
                      <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                        {t('VISUPDATE0001')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.email}
                        onChange={handleChange}
                        name="email"
                      />
                    </Grid>

                    <Grid className="leftSwitch">
                      <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                        {t('COMMON068')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.visitorType}
                        onChange={handleChange}
                        name="visitorType"
                      />
                    </Grid>
                    <Grid className="leftSwitch">
                      <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                        {t('CheckInTime')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.checkInTime}
                        onChange={handleChange}
                        name="checkInTime"
                      />
                    </Grid>
                    <Grid className="leftSwitch">
                      <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                        {t('QRCODE1')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.qrCode}
                        onChange={handleChange}
                        name="qrCode"
                      />
                    </Grid>
                    <Grid className="leftSwitch">
                      <FormLabel component="legend" className={darkMode ? "formLabel2" : "formLabel"}>
                        {t('VIOSITORSIGN')}
                      </FormLabel>
                      <Android12Switch
                        checked={state.visitorSign}
                        onChange={handleChange}
                        name="visitorSign"
                      />
                    </Grid>
                  </FormGroup>
                </FormControl>
              </StyledBadge>
            </Grid>

            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <Grid
              sx={{ width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' } }}
              style={{ padding: '24px' }}
            >
              <Grid>
                <Grid sx={{ textAlign: 'end' }}>
                  <IconButton className="scaleImage" onClick={handlePortrait}>
                    <PortraitIcon />
                  </IconButton>
                  <IconButton className="scaleImage" onClick={handleLandscape}>
                    <LandscapeIcon />
                  </IconButton>
                </Grid>

                {position === 'Portrait' ? (
                  <Paper
                    elevation={0}
                    style={{
                      height: '500px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: darkMode ? '#1A364E' : ''
                    }}
                  >
                    <Grid
                      className="id-Card"
                      sx={{ boxShadow: 1 }}
                      style={{ backgroundColor: '#0F5A9C1A' }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid
                          className="avatarIcon"
                          sx={{ display: state.companyLogo ? 'block' : 'none' }}
                        >
                          <Avatar alt="" src={Com_logo} />
                        </Grid>
                        <Grid
                          sx={{ display: state.companyName ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                        >
                          {t('VISUPDATE0006')}
                        </Grid>
                      </Grid>
                      <Grid>
                        <Grid
                          sx={{ display: state.profile ? 'block' : 'none' }}
                        >
                          <IconButton
                            className="ImageAvatar1"
                            variant="contained"
                            component="label"
                            color="secondary"
                          >
                            <Avatar className="ImageAvatar" alt="" src={''} />
                          </IconButton>
                        </Grid>
                        <Grid style={{ display: 'inline-flex' }}>
                          <Grid
                            sx={{ display: state.firstName ? 'block' : 'none' }}
                            className= {darkMode ? "card_label5" : "card_label2"}
                          >
                            {t('COMMON020')}
                          </Grid>{' '}
                          &nbsp;
                          <Grid
                            sx={{ display: state.lastName ? 'block' : 'none' }}
                            className={darkMode ? "card_label5" : "card_label2"}
                          >
                            {t('COMMON021')}
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{ display: state.mobile ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                        >
                          {t('COMMON063')}
                        </Grid>
                        <Grid
                          sx={{ display: state.email ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                        >
                          {t('VISUPDATE0001')}
                        </Grid>
                        <Grid
                          sx={{ display: state.visitorType ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                        >
                          {t('COMMON068')}
                        </Grid>
                        <Grid
                          sx={{ display: state.checkInTime ? 'block' : 'none' }}
                          className={darkMode ? "card_label5" : "card_label2"}
                        >
                          {t('CheckIn')}
                        </Grid>
                        <Grid
                          sx={{ display: state.qrCode ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                        >
                          {t('QRCODE1')} {''}
                        </Grid>
                        <Grid
                          sx={{ display: state.visitorSign ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                          style={{
                            textAlign: 'end',
                            bottom: 40,
                            position: 'absolute',
                          }}
                        >
                          {t('Signature')} {''}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <Paper
                    elevation={0}
                    style={{
                      height: '500px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: darkMode ? '#1A364E' : ''
                    }}
                  >
                    <Grid
                      className="id-CardHorizontal"
                      sx={{ boxShadow: 1 }}
                      style={{ backgroundColor: darkMode ? 'rgba(15, 90, 156, 0.1)' : '#0F5A9C1A' }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid
                          className="avatarIcon"
                          sx={{ display: state.companyLogo ? 'block' : 'none' }}
                        >
                          <Avatar alt="" src={Com_logo} />
                        </Grid>
                        <Grid
                          sx={{ display: state.companyName ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                        >
                          {t('VISUPDATE0006')}
                        </Grid>
                      </Grid>
                      <Grid>
                        <Grid
                          sx={{ display: state.profile ? 'block' : 'none' }}
                        >
                          <IconButton
                            className="ImageAvatar1"
                            variant="contained"
                            component="label"
                            color="secondary"
                          >
                            <Avatar className="ImageAvatar" alt="" src={''} />
                          </IconButton>
                        </Grid>
                        <Grid style={{ display: 'inline-flex' }}>
                          <Grid
                            sx={{ display: state.firstName ? 'block' : 'none' }}
                            className= {darkMode ? "card_label5" : "card_label2"}
                          >
                            {t('COMMON020')}
                          </Grid>{' '}
                          &nbsp;
                          <Grid
                            sx={{ display: state.lastName ? 'block' : 'none' }}
                            className={darkMode ? "card_label5" : "card_label2"}
                          >
                            {t('COMMON021')}
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{ display: state.mobile ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                        >
                          {t('COMMON063')}
                        </Grid>
                        <Grid
                          sx={{ display: state.email ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                        >
                          {t('VISUPDATE0001')}
                        </Grid>
                        <Grid
                          sx={{ display: state.visitorType ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                        >
                          {t('COMMON068')}
                        </Grid>
                        <Grid
                          sx={{ display: state.checkInTime ? 'block' : 'none' }}
                          className={darkMode ? "card_label5" : "card_label2"}
                        >
                          {t('CheckIn')}
                        </Grid>
                        <Grid
                          sx={{ display: state.qrCode ? 'block' : 'none' }}
                          className={darkMode ? "card_label3" : "card_label"}
                        >
                          {t('QRCODE1')} {''}
                        </Grid>
                        <Grid style={{ display: 'flex' }}>
                          <Grid
                            sx={{
                              display: state.visitorSign ? 'block' : 'none',
                            }}
                            className={darkMode ? "card_label3" : "card_label"}
                            style={{
                              textAlign: 'end',
                              bottom: 60,
                              position: 'absolute',
                            }}
                          >
                            {t('Signature')} {''}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </Grid>
              <Grid container xs={12} justifyContent="center" mt={2}>
                {state.name === 'Default' ? (
                  <Grid item sx={{ mr: 2 }}>
                    <SubmitButtons
                      disabled
                      onClick={handleSubmit}
                      variant="contained"
                    >
                      <Typography>{t('COMMON007')}</Typography>
                    </SubmitButtons>
                  </Grid>
                ) : (
                  <Grid item sx={{ mr: 2 }}>
                    <SubmitButtons onClick={handleSubmit} variant="contained">
                      <Typography>{t('COMMON007')}</Typography>
                    </SubmitButtons>
                  </Grid>
                )}

                <Grid item sx={{ ml: 2 }}>
                  <CancelButtons
                    color="secondary"
                    onClick={(e) => cancel()}
                    variant="contained"
                  >
                    <Typography>{t('COMMON008')}</Typography>
                  </CancelButtons>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    </>
  )
}
export default AddCardTemplate
