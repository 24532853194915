import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SubscriptionCustomerService from '../services/SubscriptionCustomerService';

const initialState = [];

export const getCustomerSubscriptionDetailsByCompanyId = createAsyncThunk('subscription/customer', async (companyId) => {
    const res = await SubscriptionCustomerService.getCustomerSubscriptionDetailsByCompanyId(companyId);
    return res.data;
});

export const saveSubscriptionDetailsForCustomer = createAsyncThunk('subscription/customer', async (payload) => {
    const res = await SubscriptionCustomerService.saveSubscriptionDetailsForCustomer(payload);
    return res.data;
});

export const updateSubscriptionDetailsForCustomer = createAsyncThunk('subscription/dealer', async (companyId) => {
    const res = await SubscriptionCustomerService.updateSubscriptionDetailsForCustomer(companyId);
    return res.data;
});

export const getSubscription = createAsyncThunk('subscription/customer', async (companyId) => {
    const res = await SubscriptionCustomerService.getSubscription(companyId);
    return res.data;
});

export const getSubscriptionById = createAsyncThunk('subscription/customer', async (id) => {
    const res = await SubscriptionCustomerService.getSubscriptionById(id);
    return res.data;
});


const SubscriptionCustomerSlice = createSlice({
    name: 'subscriptionCustomer',
    initialState,
    extraReducers: {
    
    }
})

const { reducer } = SubscriptionCustomerSlice;
export default reducer;