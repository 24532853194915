import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { Grid } from "@material-ui/core";
import { getCompanyInfo } from "../../slices/Company/CompanySlice";
import { useDispatch } from "react-redux";
import isoCountries from "i18n-iso-countries";

isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function CustomPhoneCompany({
  isValid,
  value,
  handleChange,
  specialLabel,
  disabled,
  placeholder
}) {
  const phoneInputStyles = {
    width: "100%",
    height: "56px",
  };

  // const defaultCountry = process.env.REACT_APP_ENVIRONMENT === 'USA' ? "us" : "";

  return (
    <Grid>
      <PhoneInput
        fullWidth
        placeholder={placeholder}
        specialLabel={specialLabel}
        searchPlaceholder=""
        countryCodeEditable={true}
        enableSearch="true"
        disabled={disabled}
        disableCountryCode={false}
        enableAreaCodes={false}
        isValid={isValid}
        value={value}
        onChange={handleChange}
        inputStyle={phoneInputStyles}
        country={process.env.REACT_APP_ENVIRONMENT === 'USA'? "us" : ""}  
      />
    </Grid>
  );
}

CustomPhoneCompany.propTypes = {
  value: PropTypes.any,
  isValid: PropTypes.bool,
  handleChange: PropTypes.func,
};