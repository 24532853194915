import { makeStyles } from '@material-ui/core'
import CameraswitchIcon from '@mui/icons-material/Cameraswitch'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import b64toBlob from 'b64-to-blob'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  isAndroid,
  isIOS,
  isIPad13,
  isIPhone13,
  isMobile,
  isTablet,
} from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import Webcam from 'react-webcam'
import { ThemeContext } from '../../theme/ThemeContext'
import SubmitButtons from '../DialogBoxComponent/SubmitButtons'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: (props) =>
      props.selfAligment === 'selfAligment' ? '10px' : '20px',
    minWidth: (props) =>
      props.selfAligment === 'selfAligment' ? '400px' : '600px',
  },
}));

function VisitorAssetsDialog(props) {
  const [image, setImage] = useState('');
  const { t } = useTranslation()
  const classes = useStyles(props);
  const webcamRef = React.useRef(null);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [facingMode, setFacingMode] = useState({ exact: 'environment' });
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isIPod = /iPod/.test(navigator.userAgent);
  const isDeviceMobileOrTablet = isMobile || isTablet || isIOS || isAndroid || isIPad13 || isIPhone13 || isIPod;
  const { darkMode } = useContext(ThemeContext);

  useEffect(() => {
    setIsMobileDevice(isDeviceMobileOrTablet);
  }, [isDeviceMobileOrTablet]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setImage(imageSrc);
      const contentType = 'image/jpeg';
      const b64Data = imageSrc.replace('data:image/jpeg;base64,', '');
      const blob = b64toBlob(b64Data, contentType);
      const blobUrl = URL.createObjectURL(blob);
      setImage(blobUrl);
      props.handleSubmit(blob, imageSrc);
    }
  }, [webcamRef, props]);

  const videoConstraints = isMobileDevice
    ? { facingMode }
    : { facingMode: { exact: 'user' } };

  const handleCameraFlip = () => {
    setFacingMode((prevMode) => (prevMode.exact === 'user' ? { exact: 'environment' } : { exact: 'user' }));
  };

  const handleClose = () => {
    if (webcamRef.current) {
      const tracks = webcamRef.current.stream?.getTracks();
      tracks?.forEach((track) => track.stop());
    }
    setTimeout(() => {
      props.handleClose();
    }, 500);
    setFacingMode((prevMode) => (prevMode.exact === 'user' ? { exact: 'environment' } : { exact: 'user' }));
  };

  return (
    <Dialog
      classes={{ paper: `${classes.dialogPaper} ${darkMode ? 'darkMode' : ''}` }}
      {...props}
      open={props.open}
      PaperProps={{
        style: {
          backgroundColor: darkMode ? '#0F273B' : '#fff',
        },
      }}
    >
      <DialogTitle
        variant="h4"
        sx={{ fontSize: '20px' }}
        style={{ backgroundColor: darkMode ? '#0F273B' : '#fff', color: darkMode ? '#fff' : '#3D4977' }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {t('CAPTURE_ASSETS')}
      </DialogTitle>

      <DialogContent
        style={{
          marginLeft: '20%',
          marginTop: '20px',
          // backgroundColor: darkMode ? '#0F273B' : '#fff',
        }}
      >
        {image ? (
          <img src={image} alt="Captured" />
        ) : (
          <>
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              onClick={capture}
              videoConstraints={videoConstraints}
              style={{
                height: '200px',
                width: props.selfAligment === 'selfAligment' ? '200px' : '300px',
              }}
            />
            {isMobileDevice && <CameraswitchIcon onClick={handleCameraFlip} />}
          </>
        )}
      </DialogContent>

      <DialogActions
        style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '20px',
          backgroundColor: darkMode ? '#0F273B' : '#fff',
        }}
      >
        <SubmitButtons onClick={capture} type="button">
          <Typography variant="body1" style={{ textTransform: 'none' }}>
            {t('CAPTURE')}
          </Typography>
        </SubmitButtons>
      </DialogActions>
    </Dialog>
  );
}

export default VisitorAssetsDialog;