import { Avatar } from '@material-ui/core'
import { Box, IconButton, Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import '../../App.css'
import SendInviteIcon from '../../assets/Group5341.svg'
import DataTable from '../../components/DataTable/DataTable'
import HeaderToolbar from '../../components/HeaderToolbar'
import InvitationFilterForm from '../../components/InvitationFilterForm'
import RescheduleVisitor from '../../components/ReschduleVisitor/RescheduleVisitor'
import Search from '../../components/SearchTab/Search'
import CustomStyleStatus from '../../components/statusStyleFormat/statusStyleFormat'
import Toast from '../../components/ToastContainer/CustomToast'
import VisitorProfileCard from '../../components/VisitorActionsComponents/VisitorProfileCard'
import {
  listInvitations,
  resendInvitation,
} from '../../slices/Invitations/InvitationsSlice'
import CommonUtil from '../../Util/CommonUtils'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import BlacklistVisitorForm from '../BlacklistVisitors/BlacklistVisitorForm'
import BlockVisitorIcon from './BlockVisitorIcon'
import CancelIcon from './CancelIcon'
import CancelInvitation from './CancelInvitation'
import CreateNewInvitation from './CreateNewInvitation'
import RescheduleIcon from './RescheduleIcon'
import ResendIcon from './ResendIcon'
import { useContext } from 'react'
import ProfileContext from '../../components/ProfileContext'
import ImportIcon from '../../../src/assets/USA_Images/dashboard_icons/Import.svg'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import BulkInvitation from '../../components/DialogBoxComponent/BulkInvitation'
import CircularProgress from '@mui/material/CircularProgress'
import { ThemeContext } from '../../theme/ThemeContext';
import cancelIcon from '../../../src/assets/USA_Images/login_images_visitor/cancelIcon.svg'
import BlockVisitorIcon1 from '../../../src/assets/USA_Images/login_images_visitor/BlockVisitorIcon1.svg'
import ResendIcon1 from '../../../src/assets/USA_Images/login_images_visitor/ResendIcon1.svg'

function InvitationsList() {
  const [clear, setClear] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [totalRecords, setTotalRecords] = useState(1)
  const [rows, setRows] = useState([])
  const [openCancelVisitor, setOpenCancelVisitor] = useState(false)
  const [visitorId, setVisitorId] = useState()
  const [visitorFirstName, setVisitorFirstName] = useState()
  const [id, setId] = useState()
  const [OpenRescheduleDailog, setOpenRescheduleDailog] = useState(false)
  const [openBlacklistVisitor, setOpenBlacklistVisitor] = useState(false)
  const [blacklistId, setBlacklistId] = useState()
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const [invitationIntervalTime, setInvitationIntervalTime] = useState('')
  const { visitorStatus } = useContext(ProfileContext)
  const { setResendTimer, resendTimer } = useContext(ProfileContext)
  const [isResendDisabled, setIsResendDisabled] = React.useState(resendTimer);
  const [openBulkInvitationDialog, setOpenBulkInvitationDialog] = useState(
    false,
  )
  const [purposeTypeToggle, setPurposeTypeToggle] = useState('')
  const [vistitorTypeToggle, setVisitorTypeToggle] = useState('')
  const [companyNameToggle, setCompanyNameToggle] = useState('')
  const [siteTypeToggle, setSiteTypeToggle] = useState('')
  const [purposeTypeMandatory, setPurposeTypeMandatory] = useState('')
  const [visitorTypeMandatory, setVisitorTypeMandatory] = useState('')
  const [companyNameMandatory, setCompanyNameMandatory] = useState('')
  const [siteTypeMandatory, setSiteTypeMandatory] = useState('')
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState('');
  const { darkMode } = useContext(ThemeContext);
  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  var today = new Date()
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T23:59:59'
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T00:00'

  const status = [
    'SCHEDULED',
    'CONFIRMED',
    'APPROVED',
    'CHECKED_IN',
    'CANCELLED',
    'REJECTED',
    'CHECKED_OUT',
  ]

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = React.useState({
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CANCELLED',
      'REJECTED',
      'CHECKED_OUT',
      'COMPLETED',
      'LOGGED',
    ],
    visitorTypeId: '',
    siteId: '',
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: ['INVITATION'],
    isPagable: true,
  })

  const defaultFilter = {
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CANCELLED',
      'REJECTED',
      'CHECKED_OUT',
      'COMPLETED',
      'LOGGED',
    ],
    visitorTypeId: '',
    siteId: '',
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: ['INVITATION'],
    isPagable: true,
  }

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const handleChange = (newPage, size) => {
    setLoading(true)
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
    loadSetting()
  }, [filter, paging, clear, visitorStatus])

  const handleOpen = () => {
    setOpen(true)
  }

  const resetFilter = () => {
    setLoading(true)
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const loadData = (filter, paging) => {
    dispatch(listInvitations(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          setLoading(false)
          setTotalRecords(data.data.totalCount)
          setRows(data.data.visits)
          setPage(data.data.curPage)
          setPageSize(data.data.pageSize)
          setTotalPages(data.data.totalPages)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  const handleFilter = (data) => {
    setLoading(true)
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      siteId: data.siteId,
      visitorTypeId: data.visitorTypeId,
      visitMode: data.visitMode,
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
    })
  }

  const loadSetting = () => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setInvitationIntervalTime(data.data.invitationIntervalTime)
          setDate(formatDate(data.data.dateFormat));
          setPurposeTypeToggle(data.data.invitationPurposeType)
          setVisitorTypeToggle(data.data.invitationVisitorType)
          setCompanyNameToggle(data.data.invitationCompanyName)
          setSiteTypeToggle(data.data.invitationSiteType)
          setPurposeTypeMandatory(data.data.checkInvitationPurposeType)
          setVisitorTypeMandatory(data.data.checkInvitationVisitorType)
          setCompanyNameMandatory(data.data.checkInvitationCompanyName)
          setSiteTypeMandatory(data.data.checkInvitationSiteType)
        }
      })
  }
  const formatDate = (dateFormat) => {
    switch (dateFormat) {
      case 'dd-MM-yyyy':
        return 'dd-MM-yyyy HH:mm';
      case 'MM-dd-yyyy':
        return 'MM-dd-yyyy HH:mm';
      case 'yyyy-MM-dd':
        return 'yyyy-MM-dd HH:mm';
      default:
        return 'yyyy-MM-dd HH:mm';
    }
  };

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage })
      setDel(false)
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false)
      }
    }
  }, [del])

  const globalsearch = (searchedVal) => {
    if (searchedVal === 'Check In') {
      searchedVal = 'CHECKED_IN'
    }
    if (searchedVal === 'Check Out') {
      searchedVal = 'CHECKED_OUT'
    }
    const filteredRows = rows.filter((test) => {
      return (
        (test.visitor.firstName
          ? test.visitor.firstName
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
          : '') ||
        (test.visitor.lastName
          ? test.visitor.lastName
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
          : '') ||
        (test.visitor.phone
          ? test.visitor.phone.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.visitor.email
          ? test.visitor.email.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.hostName
          ? test.hostName.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.scheduledStartDate
          ? test.scheduledStartDate
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
          : '') ||
        test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.visitor.firstName} ${test.visitor.lastName}`
          .toLowerCase()
          .includes(searchedVal.toLowerCase())
      )
    })
    setRows(filteredRows)
  }

  const handleCancelVisit = (params) => {
    setOpenCancelVisitor(!openCancelVisitor)
    setVisitorId(params.id)
    setVisitorFirstName(params.row.visitor.firstName)
  }

  const handleReschedule = (params) => {
    setOpenRescheduleDailog(!OpenRescheduleDailog)
    setId(params.id)
  }

  const getStatus = (params) => {
    if (
      params.row.status === 'CHECKED_IN' ||
      params.row.status === 'CHECKED_OUT' ||
      params.row.status === 'COMPLETED'
    ) {
      return true
    }
  }

  const getStatuss = (params) => {
    if (
      params.row.status === 'CHECKED_IN' ||
      params.row.status === 'CHECKED_OUT' ||
      params.row.status === 'CANCELLED' ||
      params.row.status === 'REJECTED' ||
      params.row.status === 'MISSED' ||
      params.row.status === 'COMPLETED'
    ) {
      return true
    }
  }

  const handleResendEmail = (params) => {
    setResendTimer(true)
    setIsResendDisabled(true)
    dispatch(resendInvitation(params.id))
      .unwrap()
      .then((data) => {
        if (data.code === "MSGE4083") {
          Toast(data.message, "error");
          return
        }
        if ((data.code = 'CVAI0070')) {
          Toast(t('INVSENTSUCCESS'), 'success')
        }
      })
      .catch((er) => { })
      .finally(() => {
        setTimeout(() => {
          setResendTimer(false)
          setIsResendDisabled(false)
        }, 30000)
      })
  }

  // const isResendDisabled1 = (id) => timers[id] > 0;

  // const [timers, setTimers] = React.useState({});

  // const startTimer = (id, duration) => {
  //   debugger
  //   setTimers(prev => ({
  //     ...prev,
  //     [id]: duration,
  //   }));

  //   const interval = setInterval(() => {
  //     setTimers(prev => {
  //       const newTime = prev[id] - 1;
  //       if (newTime <= 0) {
  //         clearInterval(interval);
  //         return {
  //           ...prev,
  //           [id]: 0,
  //         };
  //       }
  //       return {
  //         ...prev,
  //         [id]: newTime,
  //       };
  //     });
  //   }, 1000);
  // };

  const [timers, setTimers] = React.useState([]);

  const startTimer = (id, duration) => {
    // Immediately set the timer for this specific row
    setTimers(prev => ({
      ...prev,
      [id]: duration,
    }));

    // Clear the timer after the specified duration
    setTimeout(() => {
      setTimers(prev => {
        const newTimers = { ...prev };
        delete newTimers[id]; // Remove timer for this row after duration
        return newTimers;
      });
    }, duration * 1000); // Duration in milliseconds
  };

  const isResendDisabled1 = (id) => {
    // Check if the timer for this row is greater than 0
    return timers[id] > 0;
  };



  const visitExpired = (params) => {
    if (params.row.status === 'SCHEDULED' || params.row.status === 'APPROVED') {
      return true
    }
  }

  const handleBlacklistVisit = (params) => {
    setOpenBlacklistVisitor(!openBlacklistVisitor)
    setBlacklistId(params.row.id)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 15) + '...'
    } else {
      return text
    }
  }

  const renderCellScheduledStartDate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };


  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(() => [
    {
      field: 'visitor.profile',
      headerName: '',
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },
    {
      field: 'visitor.firstName',
      headerName: t('COMMON014'),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null
          ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
          : params.row.visitor.firstName,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.visitor.lastName !== null
            ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
            : params.row.visitor.firstName
            }`}
          placement="bottom-start"
        >
          <span>
            {handleTextOverflow(
              params.row.visitor.lastName !== null
                ? params.row.visitor.firstName +
                ' ' +
                params.row.visitor.lastName
                : params.row.visitor.firstName,
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'visitor.phone',
      headerName: t('COMMON063'),
      type: 'singleSelect',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.phone,
    },
    {
      field: 'visitor.email',
      headerName: t('PERSON005'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.visitor.email}`}
          placement="bottom-start"
        >
          <span>{handleTextOverflow(params.row.visitor.email)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'hostName',
      headerName: t('commonsTextFeildHostValue'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.hostName,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.hostName}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.hostName)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'scheduledStartDate',
      headerName: t('INVITEDATELBL'),
      flex: 1,
      width: 10,
      renderCell: renderCellScheduledStartDate(date),
    },
    {
      field: 'status',
      headerName: t('COMMON001'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />
      },
    },
    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisitorProfileCard rowData={params.row} />}
          label={t('VIEW')}
        />
        ,
        <Tooltip title={t('COMMON008')}>
          <div>
            <GridActionsCellItem
              icon={
                darkMode ? (
                  <img src={cancelIcon} alt="Cancel" />
                ) : (
                  <CancelIcon />
                )
              }
              label={t('COMMON008')}
              onClick={() => handleCancelVisit(params)}
              disabled={getStatuss(params)}
            />
          </div>
        </Tooltip>,
        <GridActionsCellItem
          icon={<RescheduleIcon />}
          label="Reschedule"
          onClick={() => handleReschedule(params)}
          disabled={getStatus(params)}
        />,
        <Tooltip title="Block Visitor">
          <div>
            <GridActionsCellItem
              icon={darkMode ? <img src={BlockVisitorIcon1} alt="Block Visitor Icon" /> : <BlockVisitorIcon />}
              label="Block Visitor"
              onClick={() => handleBlacklistVisit(params)}
            />
          </div>
        </Tooltip>,
        <Tooltip title="Resend">
          <div>
            <GridActionsCellItem
              icon={darkMode ? <img src={ResendIcon1} alt="Resend" /> : <ResendIcon />}
              label="Resend"
              onClick={() => {
                handleResendEmail(params)
                // startTimer(params.row.id, 30);
              }}
              disabled={isResendDisabled || !visitExpired(params)}
            />
          </div>
        </Tooltip>
        ,
      ],
    },
  ], [isResendDisabled, date])

  const handleImport = () => {
    setOpenBulkInvitationDialog(!openBulkInvitationDialog)
  }

  const commonStyles = {
    marginLeft: '250px',
    width: '100%',
    marginTop: darkMode ? '33px' : '0',
  };
  return (
    <>
      <HeaderToolbar
        src={SendInviteIcon}
        onClick={handleOpen}
        title={t('labelDashBoardTotalReschedule')}
        tooltipTitle={t('INVITATION010')}
      />
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{ marginLeft: darkMode ? '-15px' : '0' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <InvitationFilterForm
            rowsPerPage={rowsPerPage}
            selectedObject={filter.visitMode}
            applyFilter={(data) => handleFilter(data)}
            date={date}
          />
        </Box>

        <Box item>
          <Tooltip title={t('VISACCLEVEL003')}>
            <IconButton>
              <img src={ImportIcon} onClick={handleImport}></img>
            </IconButton>
          </Tooltip>
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}
      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <CreateNewInvitation
          invitationIntervalTime={invitationIntervalTime}
          open={open}
          handleDialogClose={handleClose}
          loadData={() => {
            loadData(filter, paging)
          }}
          date={date}
          purposeTypeToggle={purposeTypeToggle}
          visitorTypeToggle={vistitorTypeToggle}
          companyNameToggle={companyNameToggle}
          siteTypeToggle={siteTypeToggle}
          purposeTypeMandatory={purposeTypeMandatory}
          visitorTypeMandatory={visitorTypeMandatory}
          companyNameMandatory={companyNameMandatory}
          siteTypeMandatory={siteTypeMandatory}
        />
      )}

      {openCancelVisitor && (
        <CancelInvitation
          open={openCancelVisitor}
          handleDialogClose={handleClose}
          loadData={() => {
            loadData(filter, paging)
          }}
          handleClose={(data) => {
            setOpenCancelVisitor(data)
          }}
          CancelId={visitorId}
          CancelFirstName={visitorFirstName}
        />
      )}

      {OpenRescheduleDailog && (
        <RescheduleVisitor
          open={OpenRescheduleDailog}
          handleClose={(data) => {
            setOpenRescheduleDailog(data)
            // loadData(filter, paging);
          }}
          reschedule={() => setDel(true)}
          CustomerID={id}
          date={date}

        />
      )}

      {openBlacklistVisitor && (
        <BlacklistVisitorForm
          open={openBlacklistVisitor}
          handleClose={(data) => {
            setOpenBlacklistVisitor(data)
          }}
          disable={() => setDel(true)}
          BlacklistVisitorId={blacklistId}
        />
      )}

      {openBulkInvitationDialog && (
        <BulkInvitation
          open={openBulkInvitationDialog}
          invitationIntervalTime={invitationIntervalTime}
          handleClose={() => {
            setOpenBulkInvitationDialog(false)
            loadData(filter, paging)
          }}
          date={date}
          purposeTypeToggle={purposeTypeToggle}
          visitorTypeToggle={vistitorTypeToggle}
          companyNameToggle={companyNameToggle}
          siteTypeToggle={siteTypeToggle}
          purposeTypeMandatory={purposeTypeMandatory}
          visitorTypeMandatory={visitorTypeMandatory}
          companyNameMandatory={companyNameMandatory}
          siteTypeMandatory={siteTypeMandatory}
        />
      )}
    </>
  )
}

export default InvitationsList
