import { Box, Grid, Tooltip, Typography, Link } from '@mui/material'
import React from 'react'
import DataTable from '../../components/DataTable/DataTable'
import HeaderToolbar from '../../components/HeaderToolbar'
import Search from '../../components/SearchTab/Search'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditWatchlistIcon from '../Watchlist/EditWatchlistIcon'
import DeleteDialog from '../../components/DialogBoxComponent/DeleteDialog'
import { useState } from 'react'
import { useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteTimeSlot,
  getAllTimeSlots,
} from '../../slices/TimeSlot/TimeSlotSlice'
import timeSlot from '../../assets/USA_Images/timeSlot.svg'
import { useHistory } from 'react-router-dom'
import {
  REACT_ADD_TIMESLOTS,
  REACT_UPDATE_TIMESLOT,
} from '../../actions/EndPoints'
import Toast from '../../components/ToastContainer/CustomToast'
import FilterTimeSlots from './FilterTimeSlots'
import FilterChecklistTemplate from '../ChecklistTemplateUSA/FilterChecklistTemplate'
import DeleteChecklist from '../Checklist/DeleteChecklist'
import DeleteTimeSlot from './DeleteTimeSlot'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../../theme/ThemeContext';

export const TimeSlotList = () => {
  const [clear, setClear] = React.useState(false)
  const [totalRecords, setTotalRecords] = useState(1)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch = useDispatch()
  const history = useHistory()
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [delPayLoad, setDelPayload] = useState({})
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const [openDeleteTimeSlot, setOpenDeleteTimeSlot] = useState(false)
  const [timeSlotId, setTimeSlotID] = useState()
  const [timeSlotName, setTimeSlotname] = useState()
  const { t } = useTranslation()
  const { darkMode } = useContext(ThemeContext);

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = React.useState({
    name: '',
  })

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const defaultFilter = {
    name: '',
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging, clear])

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage })
      setDel(false)
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false)
      }
    }
  }, [del])

  const handleOpen = () => {
    setOpenEditDialog(true)
  }

  const handleClose = () => {
    setOpenEditDialog(false)
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const handleTimeSlotDelete = (params) => {
    setOpenDeleteTimeSlot(!openDeleteTimeSlot)
    setTimeSlotID(params.id)
    setTimeSlotname(params.operationName)
  }

  const loadData = (filter, paging) => {
    dispatch(getAllTimeSlots(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'DMSI0000' && data.data)) {
          setTotalRecords(data.data.totalCount)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setRows(data.data.timeZone)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
      .catch((er) => {})
  }

  const handleFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      name: data.name,
    })
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return test.operationName
        ? test.operationName.toLowerCase().includes(searchedVal.toLowerCase())
        : ''
    })
    setRows(filteredRows)
  }

  const columns = React.useMemo(() => [
    {
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>
      },
    },

    {
      field: 'operationName',
      headerName: t('accessLevelName'),
      flex: 1.8,
      width: 10,
      valueGetter: (params) => params.row.operationName,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.operationName}`}
          placement="bottom-start"
        >
          {' '}
          <span>{params.row.operationName}</span>
        </Tooltip>
      ),
    },

    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      renderCell: (params) => [
        <GridActionsCellItem
          icon={<EditWatchlistIcon />}
          onClick={() => {
            history.push({
              pathname: REACT_UPDATE_TIMESLOT,
              state: { data: params.id },
            })
          }}
        />,

        <GridActionsCellItem
          icon={<DeleteChecklist />}
          label="Delete"
          onClick={() => handleTimeSlotDelete(params.row)}
        />,
      ],
    },
  ])

  const getActions = (action, data) => {
    if (action === 0) {
      history.push({ pathname: REACT_UPDATE_TIMESLOT, state: { ...data } })
    } else if (action === 1) {
      // setOpen(!open);
    }
  }
  return (
    <div>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',   
          // marginTop: darkMode ? '-34px' : ''       
        }}
      >
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          <Box style={{ margin: '16px, 0' }}>
            <Typography style={{ marginLeft: darkMode ? '33px' : '0px', marginRight: darkMode ? '18px' : '12px', color: darkMode ? '#fff' : '#3D4977', marginTop: darkMode ? '37px': '4px' }} variant="h3">{t('ADD023')}</Typography>
          </Box>
        </Grid>
        <Box
          style={{
            textAlign: 'center',
            padding: '10px',
            backgroundColor: process.env.REACT_APP_BG_ICON,
            borderRadius: '50%',
            cursor: 'pointer',
            width: '45px',
            height: '45px',
            marginRight: darkMode ? '15px' : ''
          }}
        >
          <Tooltip title={t('ADD023')}>
            <img
              alt="..."
              src={timeSlot}
              onClick={() => {
                history.push({
                  pathname: REACT_ADD_TIMESLOTS,
                  state: {},
                })
              }}
              style={{ width: '25px' }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{ marginLeft: darkMode ? '-15px' : '0' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {'Clear Filter'}
            </Link>
          )}
        </Box>

        <Box item>
          {/* <FilterTimeSlots
            rowsPerPage={rowsPerPage} */}
          <FilterChecklistTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {openDeleteTimeSlot && (
        <DeleteTimeSlot
          open={openDeleteTimeSlot}
          handleClose={(data) => {
            setOpenDeleteTimeSlot(data)
          }}
          delete={() => setDel(true)}
          DeleteId={timeSlotId}
          name={timeSlotName}
        />
      )}
      ,
    </div>
  )
}
