import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import blacklistTypeService from '../../services/BlacklistType/BlacklistTypeService';


const initialState = [];

export const getBlackListType = createAsyncThunk('getBlackListType/', async (payload) => {
    const res = await blacklistTypeService.getBlackListType(payload);
    return res.data;
});

export const createBlackListType = createAsyncThunk('createBlackListType/create', async (payload) => {
    const res = await blacklistTypeService.createBlackListType(payload);
    return res.data;
});

export const updateBlackListType = createAsyncThunk('updateBlackListType/update', async (payload) => {
    const res = await blacklistTypeService.updateBlackListType(payload);
    return res.data;
});

export const deleteBlackListType = createAsyncThunk('deleteBlackListType/delete', async (id) => {
    const res = await blacklistTypeService.deleteBlackListType(id);
    return res.data;
});

const blacklistTypeSlice = createSlice({
    name: 'getBlackListType',
    initialState,
    extraReducers: {
        [getBlackListType.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = blacklistTypeSlice;
export default reducer;