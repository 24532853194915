import {
  Autocomplete,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CommonUtil from '../../Util/CommonUtils'
import FilterIcons from '../../assets/USA_Images/dealer/filter.svg'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import Toast from '../../components/ToastContainer/CustomToast'

export default function DealerFilter(props) {
  const [open, setOpen] = React.useState(props.open)
  const { t } = useTranslation()
  const [statusValue, SetstatusValue] = useState()
  const status = [{ label: t('STATUS_ACTIVE') },
  { label: t('STATUS_IN_ACTIVE') }]

  const [payload, setPayload] = useState({
    name: '',
    status: '',
    email: '',
  })

  const handleClose = () => {
    setPayload({
      name: '',
      status: '',
      email: '',
    })
    setOpen(false)
  }

  const [error, setError] = useState({
    name: false,
    status: false,
    email: false,
  })

  const reset = () => {
    if (
      payload.name.trim() === '' &&
      payload.status.trim() === '' &&
      payload.email.trim() === ''
    ) {
      Toast(t('Nothing to Reset'), 'error')
      return
    }

    setPayload({
      name: false,
      status: false,
      email: false,
    })
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  const handleSubmit = () => {
    if (
      payload.name.trim() === '' &&
      payload.status.trim() === '' &&
      payload.email.trim() === ''
    ) {
      Toast(t("FILTERISEMPTY"), 'error')
      return
    }
    props.applyFilter(payload)
    setPayload({
      name: '',
      status: '',
      email: '',
    })
    handleClose()
  }

  const handleChangeLangauage = (data) => {
    let status = ''
    switch (data) {
      case t('STATUS_ACTIVE'):
        status = 'ACTIVE'
        break;
      case t('STATUS_IN_ACTIVE'):
        status = 'INACTIVE'
        break;
      default:
        status = ''
    }
    SetstatusValue(status)
    return status;
  }

  useEffect(() => { }, [])

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t('FILTER')}
          </Typography>
        }
      >
        <Tooltip title={t('FILTER')}>
          <img alt="Dealer" src={FilterIcons} onClick={() => setOpen(true)} />
        </Tooltip>
      </Tooltip>

      <DialogBox
        Header={t('FILTER')}
        acceptText={t('COMMON024')}
        cancelText={t('COMMON008')}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: '0px 32px', width: '100%', marginTop: '20px' }}
        >
          <Grid item xs={12} sm={10} >
            <CustomTextfield
              label={t('FILTERDEALERNAME')}
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
              autoComplete="on"
            />
          </Grid>

          <Grid item xs={12} sm={10} style={{ marginTop: '5px' }}>
            <Autocomplete
              options={status}
              renderInput={(params) => <TextField {...params} label={t('CUSTDATABLE07')} />}
              sx={{ width: '100%' }}
              onChange={(event, value) => {
                if (!CommonUtil.isEmpty(value)) {
                  const selectedStatus = handleChangeLangauage(value.label)
                  setPayload({
                    ...payload,
                    status: selectedStatus
                  })
                }
                else {
                  setPayload({
                    ...payload,
                    status: ''
                  })
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={10} style={{ marginTop: '5px' }} >
            <CustomTextfield
              label={t('CUSTDATABLE02')}
              error={error.email}
              name="email"
              value={payload.email}
              handleChange={(e) => handleChange(e)}
              helperText={error.email}
              autoComplete="on"
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
