import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { useDispatch } from "react-redux";
import Toast from "../../components/ToastContainer/CustomToast";
import CommonUtil from "../../components/Util/CommonUtils";
import { useTranslation } from "react-i18next";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import { unblacklistVisitor } from "../../slices/BlacklistVisitor/BlacklistVisitorSlice";
import { ThemeContext } from '../../theme/ThemeContext';

export default function UnblacklistVisitorForm(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = React.useState({
    visitId: props.rowData.id,
    unrestrictedBy: props.rowData.visitor.lastName === null ? props.rowData.visitor.firstName: props.rowData.visitor.firstName + ' ' + props.rowData.visitor.lastName,
    unrestrictReason: "",
  });

  const [error, setError] = React.useState({
    unrestrictedBy: false,
    unrestrictReason: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.unrestrictedBy)) {
      Toast(t('ENTER_VISITOR_NAME'), "error");
      setError({ unrestrictedBy: true });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.unrestrictReason)) {
      Toast(t('ENTER_REASON'), "error");
      setError({ unrestrictReason: true });
      return false;
    }

    dispatch(unblacklistVisitor(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAE1047") {
          Toast(t("COMMON075"), "success");
          props.disable()
          handleClose();
        } else {
          Toast(data.message, "error");
        }
      });
  };

  return (
    <>
      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
         { process.env.REACT_APP_ENVIRONMENT === "USA"
            ? t("UNBLOCKVIS002")
            : "Unblock Visitor"
         }
         </span>
        }

        acceptText= {t("COMMON007")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={10} sm={10} style={{ marginTop: "10px" }}>
            <CustomTextfield
              type={"text"}
              label={t("BLOCKVISITOR001")}
              error={error.unrestrictedBy}
              name="unrestrictedBy"
              value={payload.unrestrictedBy}
              handleChange={(e) => handleChange(e)}
              inputProps={{ maxLength: 120 }}
            />
          </Grid>

          <Grid item xs={10} sm={10}>
            <CustomTextfield
              type={"text"}
              label={t("BLOCKVISITOR002")}
              error={error.unrestrictReason}
              name="unrestrictReason"
              value={payload.unrestrictReason}
              handleChange={(e) => handleChange(e)}
              inputProps={{ maxLength: 120 }}
            />
          </Grid>

        </Grid>
      </DialogBox>
    </>
  );
}
