import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import checklistTemplateService from '../../services/ChecklistTemplatesUSA/ChecklistTemplateService';

const initialState = [];

export const getAllChecklisttemplate = createAsyncThunk('checklistTemplate/', async (payload) => {
    const res = await checklistTemplateService.getAllChecklisttemplate(payload);
    return res.data;
});

export const addChecklistTemplate = createAsyncThunk('checklistTemplate/add', async (payload) => {
    const res = await checklistTemplateService.addChecklistTemplate(payload);
    return res.data;
}); 

export const updateChecklistTemplate = createAsyncThunk('checklistTemplate/update', async (payload) => {
    const res = await checklistTemplateService.updateChecklistTemplate(payload);
    return res.data;
}); 

export const deleteChecklistTemplate = createAsyncThunk('checklistTemplate/delete', async (payload) => {
    const res = await checklistTemplateService.deleteChecklistTemplate(payload);
    return res.data;
}); 

const checklistTemplateSlice = createSlice({
    name: 'checklistTemplate',
    initialState,
    extraReducers: {
        [getAllChecklisttemplate.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = checklistTemplateSlice;
export default reducer;