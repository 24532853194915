import { Avatar, Typography } from "@material-ui/core";
import { Box, Link, Tooltip, IconButton } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import SendWatchlistIcon from "../../assets/USA_Images/WatchlistIcon.svg";
import DataTable from "../../components/DataTable/DataTable";
// import { GridActionsCellItem } from '@mui/x-data-grid-pro';

import "../../App.css";
import { toast } from "react-toastify";
import Search from "../../components/SearchTab/Search";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import { useTranslation } from "react-i18next";
import { getSiteTypes } from "../../slices/Site/SiteSlice";
import WatchlistTemplateForm from "./WatchlistTemplateForm";
import WatchlistFilterForm from "./WatchlistFilterForm";
import {
  listWatchlistTemplates,
  updateWatchlistTemplate,
} from "../../slices/Watchlist/WatchlistSlice";
import WatchlistViewProfile from "../../components/VisitorActionsComponents/WatchlistViewProfile";
import DeleteIcon from "./DeleteIcon";
import DeleteWatchlist from "./DeleteWatchlist";
import EditWatchlistIcon from "./EditWatchlistIcon";
import HeaderToolbar from "../../components/HeaderToolbar";
import WatchlistInfo from "./WatchlistInfo";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import { getAllWatchlistTemplate } from "../../slices/WatchlistGlobal/WatchlistGlobalSlice";
import { getCompanyInfo } from "../../slices/Company/CompanySlice";
import isoCountries from 'i18n-iso-countries'
import { ThemeContext } from '../../theme/ThemeContext';

export default function WatchlistTemplate(props) {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [clear, setClear] = React.useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteVisitor, setOpenDeleteVisitor] = useState(false);
  const [watchlistId, setWatchlistId] = useState();
  const [country, setCountry] = useState('')
  const { darkMode } = useContext(ThemeContext);
  

  const [openInformation, setOpenInformation] = React.useState(false);
  const zlink = localStorage.getItem("redirectFrom");

  const [selectedObject, setSelectedObject] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneCountryCode: "",
    phoneNumber: "",
    contactEmail: "",
    contactPhoneCountryCode: "",
    contactPhoneNumber: "",
    visitorCompanyName: "",
    purposeTypeId: "",
  });

  const [filter, setFilter] = React.useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneCountryCode: "",
    phoneNumber: "",
    contactEmail: "",
    contactPhoneCountryCode: "",
    contactPhoneNumber: "",
    visitorCompanyName: "",
    purposeTypeId: "",
    isPagable: true,
  });

  const defaultFilter = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneCountryCode: "",
    phoneNumber: "",
    contactEmail: "",
    contactPhoneCountryCode: "",
    contactPhoneNumber: "",
    visitorCompanyName: "",
    purposeTypeId: "",
    isPagable: true,
  };

  const applyFilter = (filterData) => {
    setClear(true);
    const query = {
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      email: filterData.email,
      phoneCountryCode: filterData.phoneCountryCode,
      phoneNumber: filterData.phoneNumber,
      visitorCompanyName: filterData.visitorCompanyName,
      contactEmail: filterData.contactEmail,
      contactPhoneCountryCode: filterData.contactPhoneCountryCode,
      contactPhoneNumber: filterData.contactPhoneNumber,
      purposeTypeId: filterData.purposeTypeId,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setClear(false);
    setFilter({
      ...defaultFilter,
    });
  };

  const handleChange = (newPage, size) => {
    setPage(newPage - 1);
    loadData(newPage == 0 ? 0 : newPage - 1, size);
    setRowsPerPage(size);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadData(0, rowsPerPage);
  }, [filter]);

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: filter,
      page: page,
      rowsPerPage: rowsPerPage,
    };

    dispatch(getAllWatchlistTemplate(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI1000" && data.data) {
          setTotalRecords(data.data.totalCount);
          setRows(data.data.watchLists);
          setPage(data.data.currentPage + 1);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  useEffect(() => {
    loadCompany()
  }, [])

  const loadCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setCountry(countryCode)
          }
        }
      })
      .catch((er) => { })
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog);
    setSelectedObject(params);
  };

  const handleDeleteVisit = (params) => {
    setOpenDeleteVisitor(!openDeleteVisitor);
    setWatchlistId(params.id);
  };

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        test.firstName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.lastName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.email.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.contactEmail.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.firstName} ${test.lastName}`.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.phoneCountryCode}-${test.phoneNumber}`.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.contactPhoneCountryCode}-${test.contactPhoneNumber}`.toLowerCase().includes(searchedVal.toLowerCase())

      );
    });
    setRows(filteredRows);
  };


  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },

    {
      field: "name",
      headerName: t("COMMON014"),
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
    },

    {
      field: "email",
      headerName: t("COMMON042"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.email,
    },

    {
      field: "phoneNumber",
      headerName: t("COMMON063"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.phoneNumber ? (params.row.phoneCountryCode + "" + - params.row.phoneNumber) : "",
    },

    {
      field: "contactEmail",
      headerName: t("WATCHLISTTEMP002"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.contactEmail,
    },

    {
      field: "contactPhoneNumber",
      headerName: t("WATCHLISTTEMP009"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.contactPhoneNumber ? (params.row.contactPhoneCountryCode + "" + - params.row.contactPhoneNumber) : "",
    },

    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<WatchlistViewProfile rowData={params.row} />}
          label="View"
        />,

        <GridActionsCellItem
          icon={<EditWatchlistIcon />}
          label="Edit"
          onClick={() => handleEditVisit(params.row)}
        />,

        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteVisit(params)}
        />,
      ],
    },
  ]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      {!zlink ? (
        <>
          <HeaderToolbar
            src={SendWatchlistIcon}
            onClick={handleOpen}
            title= {t('WATCHLISTTEMP001')}
            tooltipTitle={t('WATCHLIST005')}
          />

        </>
      ) : (
        <>

          <HeaderToolbar
            src={SendWatchlistIcon}
            onClick={handleOpen}
            title={t('WATCHLISTTEMP001')}
            tooltipTitle={t('WATCHLIST005')}
          />

          <Box style={{ marginLeft: '145px', marginTop: '-55px' }}>
            <IconButton onClick={() => setOpenInformation(!openInformation)}>
              <WatchlistInfo />
            </IconButton>
          </Box>
        </>
      )}


      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        sx={{marginLeft: darkMode ? '-16px' : ''}}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <WatchlistFilterForm
            rowsPerPage={rowsPerPage}
            country={country}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(0, rowsPerPage)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <WatchlistTemplateForm
          selectedObject={selectedObject}
          open={open}
          country={country}
          handleClose={() => {
            handleClose(loadData(0, rowsPerPage));
          }}
        />
      )}

      {openEditDialog && (
        <WatchlistTemplateForm
          selectedObject={selectedObject}
          open={openEditDialog}
          country={country}
          handleClose={() => {
            setOpenEditDialog(false);
            loadData(0, rowsPerPage);
            setSelectedObject({
              id: "",
              firstName: "",
              lastName: "",
              email: "",
              phoneCountryCode: "",
              phoneNumber: "",
              contactEmail: "",
              contactPhoneCountryCode: "",
              contactPhoneNumber: "",
              visitorCompanyName: "",
              purposeTypeId: "",
            });
          }}
        />
      )}

      {openDeleteVisitor && (
        <DeleteWatchlist
          open={openDeleteVisitor}
          handleClose={(data) => {
            setOpenDeleteVisitor(data);
            loadData(0, rowsPerPage);
          }}
          DeleteId={watchlistId}
        />
      )}
    </>
  );
}
