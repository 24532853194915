import React from "react";
import { Tooltip, Grid } from "@mui/material";

const AccountDisableIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <>
      <Tooltip title="Account Deactivate">
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="17.465" height="18.85" viewBox="0 0 17.465 18.85">
          <path id="Path_6247" data-name="Path 6247" d="M8601,3232.366a3.982,3.982,0,1,1,3.982,3.984A3.986,3.986,0,0,1,8601,3232.366Zm1.041,0a2.941,2.941,0,1,0,2.941-2.938A2.943,2.943,0,0,0,8602.042,3232.366Zm-1.6,3.548H8592v-1.761a7.47,7.47,0,0,1,11.268-6.434l.354.208-.4.11a4.916,4.916,0,0,0-3.58,4.721,4.861,4.861,0,0,0,.925,2.867l.289.29Zm-7.4-1.761v.719h5.947a5.887,5.887,0,0,1-.384-2.115,6,6,0,0,1,2.478-4.829,6.506,6.506,0,0,0-1.611-.2A6.436,6.436,0,0,0,8593.042,3234.153Zm10.595-1.2a.5.5,0,0,1,0-1h2.846a.5.5,0,0,1,0,1Zm-8.469-10.971a3.983,3.983,0,1,1,3.981,3.984A3.987,3.987,0,0,1,8595.168,3221.983Zm1.041,0a2.94,2.94,0,1,0,2.94-2.94A2.945,2.945,0,0,0,8596.209,3221.983Z" transform="translate(-8591.75 -3217.751)" fill="#c0c7cc" stroke="#434547" stroke-width="0.5"/>
        </svg>
        
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="17.465" height="18.85" viewBox="0 0 17.465 18.85">
  <path id="Path_6247" data-name="Path 6247" d="M8601,3232.366a3.982,3.982,0,1,1,3.982,3.984A3.986,3.986,0,0,1,8601,3232.366Zm1.041,0a2.941,2.941,0,1,0,2.941-2.938A2.943,2.943,0,0,0,8602.042,3232.366Zm-1.6,3.548H8592v-1.761a7.47,7.47,0,0,1,11.268-6.434l.354.208-.4.11a4.916,4.916,0,0,0-3.58,4.721,4.861,4.861,0,0,0,.925,2.867l.289.29Zm-7.4-1.761v.719h5.947a5.887,5.887,0,0,1-.384-2.115,6,6,0,0,1,2.478-4.829,6.506,6.506,0,0,0-1.611-.2A6.436,6.436,0,0,0,8593.042,3234.153Zm10.595-1.2a.5.5,0,0,1,0-1h2.846a.5.5,0,0,1,0,1Zm-8.469-10.971a3.983,3.983,0,1,1,3.981,3.984A3.987,3.987,0,0,1,8595.168,3221.983Zm1.041,0a2.94,2.94,0,1,0,2.94-2.94A2.945,2.945,0,0,0,8596.209,3221.983Z" transform="translate(-8591.75 -3217.751)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.5"/>
</svg>

          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default AccountDisableIcon;
