import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PinDialog from "./PinDialog";


export default function PinDialogBox(props) {
  const { open, handleClose } = props;
  const [pinNumber, setPinNumber] = useState('');
  const { t } = useTranslation()

  return (
    <>
      <PinDialog
        Header={t('COMMON150')}
        acceptText={t('CONTINUE_BUTTON')}
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        fullWidth={true}
        open={open}
        onClose={handleClose}
      >
      </PinDialog>
    </>
  );
}
