import { Grid, Tooltip, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import HeaderToolbar from '../../components/HeaderToolbar'
import { getUserDetails } from '../../slices/Dealer/DealerSlice'
import { useTranslation } from 'react-i18next'

function ViewDealerDetails(props) {
  const { t } = useTranslation()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const dispatch = useDispatch()
  const handleTextOverflow = (text) => {
    if (text && text.length > 35) {
      return text.substring(0, 25) + '...'
    } else {
      return text
    }
  }
  useEffect(() => {
    loadUserDetails()
  }, [])
  const emailValue = props.location.state.email
  const loadUserDetails = () => {
    dispatch(getUserDetails(emailValue))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0033') {
          setFirstName(data.data.firstName)
          setLastName(data.data.lastName)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  return (
    <>
      <HeaderToolbar back_button={true} title={t('VIEWPAGEDEALER')} />

      <Grid
        container
        lg={12}
        md={12}
        item
        sx={{ mt: '20px', bgcolor: 'white' }}
      >
        <Grid item padding={2}>
          <Avatar
            alt="Remy Sharp"
            src={
              props.location.state.imagePreSignedURL
                ? props.location.state.imagePreSignedURL
                : ''
            }
            style={{ height: '180px', width: '180px', marginLeft: '40px' }}
          />
        </Grid>
        <Grid
          item
          xs
          zeroMinWidth
          style={{
            marginLeft: '20px',
            marginTop: '20px',
            borderRight: '3px solid #E1E9F5',
          }}
        >
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
               {t('DEALERFIRSTNAME')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip title={`${firstName}`} placement="bottom-start">
                <Typography noWrap>{handleTextOverflow(firstName)}</Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('DEALERLASTNAME')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip title={`${lastName}`} placement="bottom-start">
                <Typography noWrap>{handleTextOverflow(lastName)}</Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('DEALEREMAIL')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={`${props.location.state.email}`}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.email)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('VIEWPAGEMOBLIENUMBER')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={`${props.location.state.phone}`}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.phone)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('DEALERCOMPANYNAME')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={`${props.location.state.name}`}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.name)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs
          zeroMinWidth
          style={{
            marginLeft: '20px',
            marginTop: '20px',
            borderRight: '3px solid #E1E9F5',
          }}
        >
          <Grid container style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} columnSpacing={1}>
              <Typography style={{ opacity: '50%' }}>{t('CUSTDATABLE07')}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={`${props.location.state.status}`}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.status)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid
            container
            wrap="nowrap"
            style={{ marginTop: '10px' }}
            columnSpacing={1}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('DEALERADDRESSLINE')}{' '}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={`${props.location.state.address}`}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.address)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            wrap="nowrap"
            style={{ marginTop: '10px' }}
            columnSpacing={1}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
               {t('DEALERCITY')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={`${props.location.state.city}`}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.city)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            wrap="nowrap"
            style={{ marginTop: '10px' }}
            columnSpacing={1}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('DEALERSTATE')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ alignContent: 'end', alignItems: 'end' }}
            >
              <Tooltip
                title={`${props.location.state.state}`}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.state)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            wrap="nowrap"
            style={{ marginTop: '10px' }}
            columnSpacing={1}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
               {t('DEALERCOUNTRY')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={`${props.location.state.country}`}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.country)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs
          zeroMinWidth
          style={{ marginLeft: '20px', marginTop: '20px' }}
        >
          <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                {t('DEALERZIPCODE')}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Tooltip
                title={`${props.location.state.pincode}`}
                placement="bottom-start"
              >
                <Typography noWrap>
                  {handleTextOverflow(props.location.state.pincode)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>

          {/* <Grid container wrap="nowrap" style={{ marginTop: "10px" }} >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography noWrap style={{ opacity: "50%" }}>Due Date</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

                        </Grid>
                    </Grid> */}
          {/* <Grid container wrap="nowrap" style={{ marginTop: '10px' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography noWrap style={{ opacity: '50%' }}>
                Auto Renewal
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  )
}

export default ViewDealerDetails
