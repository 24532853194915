import {
  Tooltip,
  Grid,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Button,
} from '@mui/material'
import { Divider } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { ReactComponent as FilterIcon } from '../../../src/assets/FilterIcon.svg'
import { Typography } from '@mui/material'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { listVisitorType } from '../../slices/VisitTypeSlice'
import CloseIcon from '@mui/icons-material/Close'
import Toast from '../../components/ToastContainer/CustomToast'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import AutocompleteComponent from '../../components/CustomInputs/AutocompleteComponent'
import { getPurposeType } from '../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice'
import { getAllVisitorType } from '../../slices/VisitorType/VisitorTypeSlice'
import { ThemeContext } from '../../theme/ThemeContext';

export default function SelfRegistrationFilter(props) {
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [purposeData, setPurposeData] = useState([])
  const [visitorData, setVisitorData] = useState([])
  const { darkMode } = useContext(ThemeContext);
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose1 = () => {
    setOpen(false)
  }

  const [payload, setPayload] = useState({
    purposeTypeId: '',
    visitorTypeId: '',
    visitMode: props.selectedObject,
    status: props.status,
  })

  const handleClose = () => {
    setPayload({
      purposeTypeId: '',
      visitorTypeId: '',
      visitMode: props.selectedObject,
      status: props.status,
    })
    setOpen(false)
  }

  const [error, setError] = useState({
    purposeType: false,
  })

  const reset = () => {
    // if (
    //   payload.purposeTypeId.trim() === "" &&
    //   payload.visitorTypeId.trim() === ""
    // ) {
    //   Toast(t("COMMON027"), "error");
    //   return;
    // }

    // setPayload({
    //   purposeTypeId: "",
    //   visitorTypeId: "",
    //   visitMode: props.selectedObject,
    //   status: props.status,
    // });
    setOpen(false)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  const handleSubmit = () => {
    if (
      payload.purposeTypeId.trim() === '' &&
      payload.visitorTypeId.trim() === ''
    ) {
      Toast(t('COMMON015'), 'error')
      return
    }

    props.applyFilter(payload)
    setPayload({
      purposeTypeId: '',
      visitorTypeId: '',
      visitMode: props.selectedObject,
    })
    handleClose()
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    loadPurposeType()
    loadVisitorType()
  }

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setPurposeData(data.data.purposeTypes)
        } else {
          setPurposeData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadVisitorType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getAllVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setVisitorData(data.data.visitorTypes)
        } else {
          setVisitorData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event.id,
    })
  }

  const onselectVisitorType = (event) => {
    setPayload({
      ...payload,
      visitorTypeId: event.id,
    })
  }

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t('COMMON006')}
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <SelfRegistrationFilter />
        </FilterIcon>
      </Tooltip>
      <Grid item xs={6}>
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
          <DialogTitle variant="h4" onClick={handleClose} 
          style={{backgroundColor: darkMode ? '#0F273B' : ''}}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 15,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* {t('COMMON006')} */}
            <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
            {t('COMMON006')}
              </span>
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent style={{ backgroundColor: darkMode ? '#0F273B' : ''}}>
            <Grid container justifyContent="center" alignItems={'center'}>
              <Grid item xs={12} sm={10} sx={{ mt: 3 }}>
                <AutocompleteComponent
                  label={t('PURPOSETYPE')}
                  name="purposeTypeId"
                  value={payload.purposeTypeId}
                  onSelect={(e) => onselectPurposeType(e)}
                  options={purposeData}
                  onBlur={() => setError({ ...error, purposeTypeId: '' })}
                />
              </Grid>

              <Grid item xs={12} sm={10} sx={{ mt: 3 }}>
                <AutocompleteComponent
                  label={t('COMMON068')}
                  name="visitorTypeId"
                  value={payload.visitorTypeId}
                  onSelect={(e) => onselectVisitorType(e)}
                  options={visitorData}
                  onBlur={() => setError({ ...error, visitorTypeId: '' })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: 'center',
              backgroundColor: darkMode ? '#0F273B' : ''
            }}
          >
            <Button
              variant="contained"
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                width: '180px',
                height: '55px',
              }}
              onClick={handleSubmit}
            >
              <Typography variant="body1" style={{ textTransform: 'none' }}>
                {t('COMMON024')}
              </Typography>
            </Button>
            <CancelButtons
              variant="contained"
              color="secondary"
              onClick={reset}
            >
              <Typography variant="body1">{t('COMMON008')}</Typography>
            </CancelButtons>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  )
}
