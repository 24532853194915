import { Button } from '@mui/material'
import React from 'react'
import HeaderToolbar from '../../components/HeaderToolbar'
import { useTranslation } from 'react-i18next'

function CreateDeviceType() {
    const {t} = useTranslation()
  return (
    <>
        <HeaderToolbar 
        back_button = {true}
        title = {t("CREATE_DEVICE_TYPE")}
        />
    </>
  )
}

export default CreateDeviceType