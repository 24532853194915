import { Tooltip } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


function CustomerIcon() {
  const [isShown ,setIsShown] = useState(false);
  const { t } = useTranslation();
return (
   <>
   <Tooltip title={t('VIEWCUSTOMER')}>
    <Grid 
     onMouseEnter={() => setIsShown(true)}
     onMouseLeave={() => setIsShown(false)}
    >
  {isShown ? 
   <svg 
   xmlns="http://www.w3.org/2000/svg" 
   width="25.233" 
   height="25.696" 
   viewBox="0 0 25.233 25.696">

  <path 
  id="Path_7301" 
  data-name="Path 7301" 
  d="M9.49,20.329a8.456,8.456,0,0,1,15.743,0,8.456,8.456,0,0,1-15.743,0Zm4.294,0a3.578,3.578,0,1,0,3.578-3.578A3.579,3.579,0,0,0,13.784,20.329ZM11.64,25.7H.909A.91.91,0,0,1,0,24.787V19.456a9.144,9.144,0,0,1,15.918-6.145,10.109,10.109,0,0,0-2.074.522A7.326,7.326,0,0,0,1.818,19.456v4.422H4V20a.909.909,0,0,1,1.818,0v3.876H9.607a10.265,10.265,0,0,0,1.942,1.752l.092.063,0,0Zm3.574-5.367a2.147,2.147,0,1,1,2.147,2.147A2.148,2.148,0,0,1,15.215,20.329ZM4.085,5.064a5.063,5.063,0,1,1,5.063,5.063A5.069,5.069,0,0,1,4.085,5.064Zm1.818,0A3.246,3.246,0,1,0,9.147,1.818,3.249,3.249,0,0,0,5.9,5.064Z" 
  />
  
</svg>
:
<svg 
   xmlns="http://www.w3.org/2000/svg" 
   width="25.233" 
   height="25.696" 
   viewBox="0 0 25.233 25.696">

  <path 
  id="Path_7301" 
  data-name="Path 7301" 
  d="M9.49,20.329a8.456,8.456,0,0,1,15.743,0,8.456,8.456,0,0,1-15.743,0Zm4.294,0a3.578,3.578,0,1,0,3.578-3.578A3.579,3.579,0,0,0,13.784,20.329ZM11.64,25.7H.909A.91.91,0,0,1,0,24.787V19.456a9.144,9.144,0,0,1,15.918-6.145,10.109,10.109,0,0,0-2.074.522A7.326,7.326,0,0,0,1.818,19.456v4.422H4V20a.909.909,0,0,1,1.818,0v3.876H9.607a10.265,10.265,0,0,0,1.942,1.752l.092.063,0,0Zm3.574-5.367a2.147,2.147,0,1,1,2.147,2.147A2.148,2.148,0,0,1,15.215,20.329ZM4.085,5.064a5.063,5.063,0,1,1,5.063,5.063A5.069,5.069,0,0,1,4.085,5.064Zm1.818,0A3.246,3.246,0,1,0,9.147,1.818,3.249,3.249,0,0,0,5.9,5.064Z" 
  fill="#c0c7cc"
  />
  
</svg>
}
</Grid>
</Tooltip>
   </>
  )
}

export default CustomerIcon