import api from '../../config/http-common'
import CommonUtil from '../../Util/CommonUtils'

const buildQuery = (filter) => {
  let query =
    '?isPagable=true&pageNumber=' +
    filter.page +
    '&pageSize=' +
    filter.rowsPerPage
  if (!CommonUtil.isEmptyString(filter.name)) {
    query = query + '&name=' + filter.name
  }
  if (!CommonUtil.isEmptyString(filter.status)) {
    query = query + '&status=' + filter.status
  }
  return query
}

const getAllVisitorType = (filter) => {
  return api.securedAxios().get('/web/getVisitorType' + buildQuery(filter))
}

const createVisitorType = (payload) => {
  return api.securedAxios().post('/web/createVisitorType', payload)
}

const updateVisitorType = (payload) => {
  return api.securedAxios().put('/web/updateVisitorType/' + payload.id, payload)
}

const visitorTypeService = {
  getAllVisitorType,
  createVisitorType,
  updateVisitorType,
}

export default visitorTypeService
