import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
// import './custom.css';
import { useHistory, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import sucess_img1 from '../../assets/USA_Images/login_images/linkexpire.svg'
import vis_sucess_img1 from '../../assets/images/onboard/link_expired.svg'

const useStyles = makeStyles((theme) => ({
  submit: {
    width: '350px',
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  },
  success: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  footer: {
    bottom: '0px',
    padding: '40px',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
  },
}))

function VisLinkExpired(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  function handleClick(e) {
    e.preventDefault()
    history.push('/vis/login')
  }

  return (
    <Grid className={classes.success}>
      <Grid container alignItems="center">
        <Grid item className="logo-center">
          <img
            src={
              process.env.REACT_APP_ENVIRONMENT === 'USA'
                ? sucess_img1
                : vis_sucess_img1
            }
            alt="logo"
            style={{ width: '300px' }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '25px', marginTop: '10px' }}>
        <Typography
          variant="h2"
          align="center"
          style={{ color: '#464646', fontWeight: '500' }}
        >
          This Link is Expired
        </Typography>
      </Grid>
      <Grid className={classes.footer}>
        <Typography style={{ fontSize: '10px' }} color="textSecondary">
          {t('COPY_RIGHT_2024')}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default withRouter(VisLinkExpired)
