import CommonUtil from '../../components/Util/CommonUtils'
import api from '../../config/http-common'

const buildQuery = (payload) => {
  let query =
    '?pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize

  return query
}

// Product
// Passing Subscription as Product to Backend
const createProduct = (payload) => {
  return api.securedAxios().post('/web/api/v4/product', payload)
}

const updateProduct = (payload) => {
  return api
    .securedAxios()
    .put('/web/api/v4/products/' + payload.productId, payload)
}

const getAllProducts = (payload) => {
  return api.securedAxios().get('/web/api/v4/products/' + buildQuery(payload))
}

const getProductById = (subscriptionId) => {
  return api.securedAxios().get('/web/api/v4/products/' + subscriptionId)
}

const getVisitLimit = (subscriptionId) => {
  return api
    .securedAxios()
    .get('/web/api/v4/products/' + subscriptionId + '/product')
}

// discount
const createDiscount = (payload) => {
  return api.securedAxios().post('/web/api/v4/discount', payload)
}

const updateDiscount = (discountId, payload) => {
  return api.securedAxios().put('/web/api/v4/discount/' + discountId, payload)
}

const getDiscounts = (payload) => {
  return api.securedAxios().get('/web/api/v4/discounts' + buildQuery(payload))
}

// features
const createFeature = (payload) => {
  return api.securedAxios().post('/web/api/v4/feature', payload)
}

const createFeatures = (payload) => {
  return api.securedAxios().post('/web/api/v4/features', payload)
}

const getFeatures = (payload) => {
  return api.securedAxios().get('/web/api/v4/features' + buildQuery(payload))
}

const listPaymentMethods = (companyId) => {
  return api
    .securedAxios()
    .get('/web/api/v4/paymentMethods/' + companyId + '/list')
}

const purchasePlan = (payload) => {
  return api
    .securedAxios()
    .post(
      '/web/api/v4/products/' +
        payload.productId +
        '/customers/' +
        payload.companyId +
        '/subscription',
      payload,
    )
}

const getCustomerSubscription = (payload) => {
  return api.securedAxios().get('/web/api/v4/subscription/' + payload)
}

const getPurchasedPlans = (payload) => {
  return api
    .securedAxios()
    .get('/web/api/v4/subscriptions/purchase/list' + query(payload))
}

const query = (payload) => {
  let query =
    '?pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize
  if (!CommonUtil.isEmptyString(payload.subscriptionName))
    query = query + '&subscriptionName=' + payload.subscriptionName
  if (!CommonUtil.isEmptyString(payload.planAssigned))
    query = query + '&planAssigned=' + payload.planAssigned
  if (!CommonUtil.isEmptyString(payload.startDate))
    query = query + '&startDate=' + payload.startDate
  if (!CommonUtil.isEmptyString(payload.endDate))
    query = query + '&endDate=' + payload.endDate
  return query
}

const customerSubscription = (payload) => {
  return api
    .securedAxios()
    .get('/web/api/v4/Customer/' + payload.companyId + '/products/list')
}

const upgradeSubscription = (payload) => {
  return api
    .securedAxios()
    .put(
      '/web/api/v4/products/' +
        payload.productId +
        '/customers/' +
        payload.dealerCompanyId +
        '/subscriptions/' +
        payload.subscriptionId,
      payload,
    )
}

const SubscriptionService = {
  createProduct,
  updateProduct,
  getAllProducts,
  getProductById,
  getDiscounts,
  createDiscount,
  updateDiscount,
  getFeatures,
  createFeature,
  createFeatures,
  getVisitLimit,
  listPaymentMethods,
  purchasePlan,
  getCustomerSubscription,
  getPurchasedPlans,
  customerSubscription,
  upgradeSubscription,
}

export default SubscriptionService
