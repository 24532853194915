import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import isoCountries from 'i18n-iso-countries'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useDymoCheckService, useDymoFetchPrinters } from 'react-dymo-hooks'
import { useTranslation } from 'react-i18next'
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux'
import CustomPhone from '../../components/CustomInputs/CustomPhone'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import ProfileContext from '../../components/ProfileContext'
import Toast from '../../components/ToastContainer/CustomToast'
import api from '../../config/http-common'
import CustomPhones from '../../Pages/OnBoardV2/CustomPhones'
import { getCompanyById } from '../../slices/Company/CompanySlice'
import { updateStatus } from '../../slices/PinNumber/PinNumberSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import CommonUtil from '../../Util/CommonUtils'

const Dymo = require('dymojs'),
  dymo = new Dymo()

export default function PinVisitorDetails(props) {
  const { darkMode } = useContext(ThemeContext);
  const { open } = props
  const dispatch = useDispatch()
  const { setVisitorStatus } = useContext(ProfileContext)
  const [date, setDate] = useState()
  const [country, setCountry] = useState('')
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [checkInpayload, setCheckInPayload] = useState({
    pinNumber: props.pinNumber,
    status: 'CHECKED_IN',
  })

  const [checkOutpayload, setCheckOutPayload] = useState({
    pinNumber: props.pinNumber,
    status: 'CHECKED_OUT',
  })

  const handleClose1 = () => {
    props.handleDialogClose()
  }

  const statusDymoService = useDymoCheckService()

  const { statusFetchPrinters, printers } = useDymoFetchPrinters(
    statusDymoService,
  )

  const print = (data) => {
    if (printers[0].isConnected === 'True') {
      dymo.print(printers[0].name, data)
    }
  }

  const handleCheckIn = () => {
    setLoading(true)
    if (props.multipleData.status === 'CHECKED_OUT') {
      Toast(t('VISITOR_ALREADY_CHECK_OUT'), 'error')
      props.handleDialogClose()
    } else if (props.multipleData.status !== 'CHECKED_IN') {
      dispatch(updateStatus(checkInpayload))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0000') {
            setLoading(false)
            setVisitorStatus(data.data.visit.status)
            Toast(t('VISITOR_CHECK_IN_SUCCESSFULLY'), 'success')
            if (data.data.dymoLabel != null) {
              print(data.data.dymoLabel)
            }
            props.handleDialogClose()
          } else {
            Toast(data.message, 'error')
            props.handleDialogClose()
          }
        })
    } else {
      Toast(t('VISITOR_ALREADY_CHECK_IN'), 'error')
    }
  }

  const handleCheckOut = () => {
    if (props.multipleData.status === 'CHECKED_OUT') {
      Toast(t('VISITOR_ALREADY_CHECK_OUT_VISITOR'), 'error')
      props.handleDialogClose()
    } else if (props.multipleData.status === 'CHECKED_IN') {
      dispatch(updateStatus(checkOutpayload))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0000') {
            setVisitorStatus(data.data.visit.status)
            Toast(t('VISITOR_CHECK_OUT_SUCCESSFULLY'), 'success')
            props.handleDialogClose()
          } else {
            Toast(data.message, 'error')
            props.handleDialogClose()
          }
        })
    } else {
      Toast(t('VISITOR_NOT_CHECK_IN'), 'error')
      props.handleDialogClose()
    }
  }

  const handleChange = () => { }

  useEffect(() => {
    api
      .securedAxios()
      .get('/web/getSettings')
      .then((res) => {
        if (res.data.code === 'CVAI0000') {
          setDate(res.data.data.dateFormat);
        }
      })
  }, [])

  useEffect(() => {
    if (props.data.phone === '' || props.data.phone === null) {
      loadCompany()
    }
  })

  const loadCompany = () => {
    dispatch(getCompanyById(props.data.companyId))
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setCountry(countryCode)
          } else {
            setCountry('us')
          }
        }
      })
      .catch((er) => { })
  }
  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose1}
      fullWidth={true}
      disable
      PaperProps={{
        style: {
          borderRadius: '20px !important',
          minWidth: '50vw',
          backgroundColor: darkMode ? '#0F273B' : '#fff',
        },
      }}
    >
      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}
      <DialogTitle variant="h4" onClick={handleClose1} style={{ color: darkMode ? '#fff' : '' }}>
        <IconButton
          onClick={handleClose1}
          sx={{
            position: 'absolute',
            right: 15,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {t('INVIT002')}
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          style={{ padding: '16px 32px', width: '100%' }}
          spacing={2}
        >
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label={t('COMMON020')}
              value={props.data.firstName}
              inputProps={{ maxLength: 50 }}
              handleChange={(e) => handleChange(e)}
              disabled={true}
              InputLabelProps={{ shrink: true }}
            // style={{
            //   color: darkMode ? '#fff' : 'inherit',
            // }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label={t('COMMON021')}
              value={props.data.lastName}
              disabled={true}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label={t('COMMON042')}
              value={props.data.email}
              disabled={true}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item lg={6} xs={12} style={{ margin: '16px 0px' }}>
            {props.data.phone ? (
              <CustomPhone
                disabled={true}
                value={props.data.phone}
                country={country}
              />
            ) : (
              <CustomPhones
                disabled={true}
                value={props.data.phone}
                country={country}
              />
            )}
          </Grid>

          <Grid item lg={6} xs={12}>
            <CustomTextfield
              label={t('PURPOSE_VISIT')}
              value={props.purposeType?.name}
              InputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <CustomTextfield
              label={t('COMMON068')}
              value={props.visitorType?.name}
              InputLabelProps={{ shrink: true }}
              disabled={true}

            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <CustomTextfield
              label={t('VISUPDATE0006')}
              value={props.data.visitorCompanyName}
              InputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <CustomTextfield
              label={t('commonsTextFeildHostValue')}
              value={props.host}
              InputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <CustomTextfield
              label={t('INVITESTARTDATE')}
              //  value={props.startDate}
              value={
                date === 'MM-dd-yyyy'
                  ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(props.startDate),
                  ).format('MM-DD-YYYY HH:mm')
                  : date === 'dd-MM-yyyy' ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(props.startDate),
                  ).format('DD-MM-YYYY HH:mm') : date === 'yyyy-MM-dd' ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(props.startDate),
                  ).format('YYYY-MM-DD HH:mm') : ''
              }
              InputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <CustomTextfield
              label={t('INVITESTARTDATEERROR4')}
              // value={props.endDate}
              value={
                date === 'MM-dd-yyyy'
                  ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(props.startDate),
                  ).format('MM-DD-YYYY HH:mm')
                  : date === 'dd-MM-yyyy' ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(props.startDate),
                  ).format('DD-MM-YYYY HH:mm') : date === 'yyyy-MM-dd' ? moment(
                    CommonUtil.getLocalDateTimeInYearFormat(props.startDate),
                  ).format('YYYY-MM-DD HH:mm') : ''
              }
              InputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'center',
        }}
      >
        <SubmitButtons variant="contained" onClick={handleCheckIn}>
          <Typography variant="body1" style={{ textTransform: 'none' }}>
            {t('CheckIn')}
          </Typography>
        </SubmitButtons>

        <CancelButtons
          variant="contained"
          color="secondary"
          onClick={handleCheckOut}
        >
          <Typography variant="body1" style={{ textTransform: 'none' }}>
            {t('CHECK_OUT')}
          </Typography>
        </CancelButtons>
      </DialogActions>
    </Dialog>
  )
}
