import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import logo_mbl from '../../assets/USA_Images/login_images/logo_mbl.svg'

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(2),
    textAlign: 'center',
    position: 'absolute',
    top: '50px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#000000',
  },
  scrollbarContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
    marginLeft: '300px',
    textAlign: 'left',
    marginTop: '-189px',
  },
  dialogbox: {
    '& .MuiDialog-paper': {
      width: '800px',
      height: '400px',
      borderRadius: '14px',
      backgroundColor: '#yourBackgroundColor',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  imageContainer: {
    marginTop: '-100px',
    marginLeft: '-410px',
    textAlign: 'center',
  },
}))

function FreeTrialDialog(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(props.open)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 8) {
      return text.substring(0, 11) + '...'
    } else {
      return text
    }
  }

  return (
    <>
      <div></div>
      <Dialog
        open={open}
        // onClose={handleClose}
        className={classes.dialogbox}
        aria-labelledby="form-dialog-title"
      >
        <div>
          <div>
            <div>
              <div className="uui"></div>
              <IconButton className={classes.closeButton}>
                <CloseIcon onClick={handleClose}>X</CloseIcon>
              </IconButton>
              <Typography variant="h5" className={classes.heading}>
                Your Trial has been expired!
              </Typography>
            </div>
            <p
              style={{
                marginTop: '130px',
                marginLeft: '190px',
                fontSize: '18px',
                fontWeight: 'bold',
                opacity: '80%',
                color: 'rgba(36, 36, 36, 1)',
              }}
            >
              Please Contact
            </p>
            <p
              style={{
                marginTop: '30px',
                marginLeft: '190px',
                fontSize: '18px',
                fontWeight: 'bold',
                opacity: '80%',
                color: 'rgba(36, 36, 36, 1)',
              }}
            >
              Email: info@mysmartguardplus.com
            </p>
            <p
              style={{
                marginTop: '20px',
                marginLeft: '190px',
                fontSize: '18px',
                fontWeight: 'bold',
                opacity: '80%',
                color: 'rgba(36, 36, 36, 1)',
              }}
            >
              Tel: +1(862)505-2101
            </p>
            <div className={classes.imageContainer}>
              <img src={logo_mbl} width="7.5%" height="" alt="mysmartguard" />
            </div>
            <div></div>
            <div></div>
            <div>
              <ul></ul>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default FreeTrialDialog
