import api from '../../config/http-common'

const getAll = (query) => {
  return api
    .securedAxios()
    .get(
      '/web/getPurposeType?pageNumber=' +
        query.pageNumber +
        '&pageSize=' +
        query.pageSize +
        '&id=' +
        query.id +
        '&name=' +
        query.name +
        '&status=' +
        query.status,
    )
}

const addPurposeTypeZlink = (payload) => {
  return api.securedAxios().post('/web/createPurposeType', payload)
}

const updateVisitorType = (payload) => {
  return api.securedAxios().put('/web/updatePurposeType/' + payload.id, payload)
}

const PerpouseTypeService = {
  getAll,
  addPurposeTypeZlink,
  updateVisitorType,
}

export default PerpouseTypeService
