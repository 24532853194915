import api from '../../config/http-common'

const createDiscount = (payload) => {
  return api.securedAxios().post('/api/v4/web/discount', payload)
}

const updateDiscount = (payload) => {
  return api.securedAxios().put('/api/v4/web/discounts/' + payload.id, payload)
}

const buildQuery = (payload) => {
  let query =
    '?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=false&operator=AND&pageNumber=' +
    payload.page +
    '&pageSize=' +
    payload.rowsPerPage
  return query
}

const getDiscountList = (payload) => {
  return api
    .securedAxios()
    .get(
      '/api/v4/web/discounts/' +
        localStorage.getItem('companyId') +
        '/list' +
        buildQuery(payload),
    )
}

const getDiscount = (payload) => {
  return api
    .securedAxios()
    .get(
      '/api/v4/web/discounts/' +
        localStorage.getItem('companyId') +
        '/discount?status=' +
        payload.status,
    )
}

const discountService = {
  createDiscount,
  updateDiscount,
  getDiscountList,
  getDiscount,
}
export default discountService
