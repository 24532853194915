import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import './custom.css'
import { useHistory, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import thanks_img from '../../assets/USA_Images/login_images/thanks.svg'
import vis_thanks_img from '../../assets/images/onboard/thanks_img.svg'

const useStyles = makeStyles((theme) => ({
  submit: {
    width: '350px',
    backgroundColor: '#36c96d',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#119743',
    },
  },
  success: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  footer: {
    bottom: '0px',
    padding: '40px',
    textAlign: 'center',
    width: '100%',
    position: 'relative',
  },
}))

function ThankYouScreen(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  function handleClick(e) {
    e.preventDefault()
    history.push('/vis/login')
  }

  return (
    <Grid className={classes.success}>
      <Grid container alignItems="center">
        <Grid item className="logo-center">
          <img
            src={
              process.env.REACT_APP_ENVIRONMENT === 'USA'
                ? thanks_img
                : vis_thanks_img
            }
            alt="logo"
            style={{ width: '300px' }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '25px', marginTop: '10px' }}>
        <Typography
          variant="h2"
          align="center"
          style={{ color: '#464646', fontWeight: '500' }}
        >
          Thanks for Signing Up
        </Typography>
      </Grid>
      <Grid>
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          gutterBottom
        >
          We are happy to have you on board
        </Typography>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs>
          <Box mt={3} textAlign="center">
            <Button fullWidth onClick={handleClick} variant="contained">
              {t('LOGINFORM0002')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid className={classes.footer}>
        <Typography style={{ fontSize: '10px' }} color="textSecondary">
          {t('COPY_RIGHT_2024')}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default withRouter(ThankYouScreen)
