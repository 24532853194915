import { Box, Button, Grid, TextareaAutosize, Tooltip } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState,useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import sendIconWhite from '../../../src/assets/images/UrgentMessages/sendIconWhite.svg'
import DataTable from '../../components/DataTable/DataTable'
import HeaderToolbar from '../../components/HeaderToolbar'
import Toast from '../../components/ToastContainer/CustomToast'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import {
  createUrgentMessage,
  listUrgentMessages,
} from '../../slices/UrgentMessages/UrgentMessagesSlice'
import CommonUtil from '../../Util/CommonUtils'
import { ThemeContext } from '../../theme/ThemeContext';

function EmergencyMessage() {
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const { darkMode } = useContext(ThemeContext);
  const [paging, setPaging] = useState({
    page: 1,
    size: 10,
  })
  const { t } = useTranslation()
  const [message, setMessage] = useState({
    message: '',
  })
  const [date, setDate] = useState('')

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setPaging({
      page: newPage,
      size: size,
    })
  }

  const handleTextChange = (event) => {
    const name = event.target.name
    setMessage({
      ...message,
      [name]: event.target.value,
    })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 30) + '...'
    } else {
      return text
    }
  }

  useEffect(() => {
    loadData(paging)
    loadSetting()
  }, [paging])

  const loadData = (paging) => {
    const filter = {
      pageNumber: paging.page,
      pageSize: paging.size,
    }
    dispatch(listUrgentMessages(filter))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000' && data.data) {
          setRows(data.data.urgentMessages)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
        }
      })
      .catch((er) => { })
  }

  const createMessage = () => {
    if (CommonUtil.isEmptyString(message.message)) {
      Toast(t('ENTER_EMERGENCY'), 'error')
      return
    }
    dispatch(createUrgentMessage(message))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0077' && data.data) {
          setMessage({ ...message, message: '' })
          Toast(data.message, 'success')
          loadData(paging)
        } else Toast(data.message, 'error')
      })
      .catch((er) => { })
  }


  const loadSetting = () => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setDate(formatDate(data.data.dateFormat));
        }
      })
  }

  const formatDate = (dateFormat) => {
    switch (dateFormat) {
      case 'dd-MM-yyyy':
        return 'dd-MM-yyyy HH:mm';
      case 'MM-dd-yyyy':
        return 'MM-dd-yyyy HH:mm';
      case 'yyyy-MM-dd':
        return 'yyyy-MM-dd HH:mm';
      default:
        return 'yyyy-MM-dd HH:mm';
    }
  };

  const renderCellCreatedAt = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.createdAt)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: '',
        sortable: false,
        width: 5,
        renderCell: (params) => {
          return <div></div>
        },
      },
      {
        field: 'message',
        headerName: t('commonsPathMessage'),
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.message,
        renderCell: (params) => (
          <Tooltip title={`${params.value}`} placement="bottom-start">
            <span>{handleTextOverflow(params.value)}</span>
          </Tooltip>
        ),
      },
      {
        field: 'Created By',
        headerName: t('URGENTMESS0003'),
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.createdByName,
      },

      {
        field: 'Created Time',
        headerName: t('URGENTMESS0007'),
        flex: 1,
        width: 10,
        renderCell: renderCellCreatedAt(date),
      },
    ],
    [date],
  )

  return (
    <div style={{ padding: '0px 20px' }}>
      <HeaderToolbar
        src={''}
        onClick={''}
        title={t('URGENTMESS0004')}
        tooltipTitle=""
      />

      <Grid
        container
        style={{ display: 'flex', marginTop: '15px', marginBottom: '15px', marginLeft: darkMode ? '33px' : '0', width: darkMode ? '1334px' : '1329px' }}
      >
        <Grid item xs={11}>
          <Grid
            style={{
              position: 'absolute',
              bottom: '10px',
              right: '10px',
              color: '#A7A7A7',
            }}
          >
            {t('tableToolTipSendMessage1')}
          </Grid>
          <TextareaAutosize
            className="textareaStyle"
            minRows={3}
            maxlength="300"
            placeholder={t('URGENTMESS0005')}
            name="message"
            value={message.message}
            onChange={handleTextChange}
            style={{backgroundColor: darkMode ? '#0F273B' : '#fff', color: darkMode ? '#fff' : ''}}
          />
        </Grid>
        <Grid
          item
          xs={1}
          alignSelf="flex-end"
          float="left"
          writingMode="vertical-rl"
          bottom="7px"
        >
          <Box display="flex" justifyContent="flex-end">
            <Tooltip title={t('tableToolTipSendMessage')} arrow>
              <Button
                variant="contained"
                size="small"
                onClick={createMessage}
                style={{
                  backgroundColor: '#E3393C',
                }}
              >
                <img alt="SendIcon" src={sendIconWhite} />
              </Button>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>

      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={paging.size}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
    </div>
  )
}

export default EmergencyMessage
