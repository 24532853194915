import { Avatar, Box } from '@material-ui/core'
import { CircularProgress, Tooltip } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import * as XLSX from 'xlsx'
import CommonUtil from '../../Util/CommonUtils'
import filterIcon from '../../assets/Customer/Filter.svg'
import forwardIcon from '../../assets/Customer/forward.svg'
import DataTable from '../../components/DataTable/DataTable'
import HeaderToolbar from '../../components/HeaderToolbar'
import Search from '../../components/SearchTab/Search'
import Toast from '../../components/ToastContainer/CustomToast'
import BrowserLanguage from '../../components/Util/BrowserLanguage'
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import {
  downloadCustomerInvoiceDealerCompany,
  fetchCustomersDetailsByDealerCompanyId
} from '../../slices/Customer/CustomerSlice'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import ViewIcon from '../Invitations/ViewIcon'
import CustomerReportFilter from './CustomerReportFilter'
import CustomerTransactionAndReportExports from './CustomerTransactionAndReportExports'

let language = BrowserLanguage.getDefaultLanguage()
function CustomerReports() {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [clear, setClear] = React.useState(false)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [openFilter, setOpenFilter] = useState(false)
  const [openDownload, setOpenDownload] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const companyId = localStorage.getItem('companyId')
  const [date, setDate] = useState()
  const [rowCustomer, setRowCustomer] = useState({})
  const [loading, setLoading] = useState(true)

  const handleOpen = () => {
    setOpen(true)
  }
  const resetFilter = () => {
    setLoading(true)
    setClear(false)
    setPaging({ ...paging, pageNumber: 1 })
    setfilter(defaultFilter)
    setLoading(true)
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.email
          ? test.email.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.phone
          ? test.phone.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.subscriptionName
          ? test.subscriptionName
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
          : '') ||
        (test.planBasePrice
          ? test.planBasePrice
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
          : '') ||
        (test.billingCycle
          ? test.billingCycle.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.dealerName
          ? test.dealerName.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        `${test.name}`.toLowerCase().includes(searchedVal.toLowerCase())
      )
    })
    setRows(filteredRows)
  }
  const handleChange = (newPage, size) => {
    setLoading(true)
    setPage(newPage)
    setPaging({
      pageNumber: newPage,
      pageSize: size,
    })
  }

  const [defaultFilter, setDefaultFilter] = useState({
    status: '',
    name: '',
    email: '',
    subscriptionName: '',
  })

  const [filter, setfilter] = useState({
    status: '',
    name: '',
    email: '',
    subscriptionName: '',
  })

  const [paging, setPaging] = useState({
    pageNumber: '1',
    pageSize: '10',
  })

  const handleFilter = (data) => {
    setLoading(true)
    setClear(true)
    setfilter({
      ...filter,
      status: data.status,
      name: data.name,
      email: data.email,
      subscriptionName: data.subscriptionName,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
    loadSetting()
  }, [filter, paging])

  const loadData = (filter, paging) => {
    const payload = {
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
      status: filter.status,
      name: filter.name,
      email: filter.email,
      subscriptionName: filter.subscriptionName,
    }
    dispatch(fetchCustomersDetailsByDealerCompanyId(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4024') {
          setLoading(false)
          setRows(res.data.companies)
          setTotalRecords(res.data.totalCount)
          setPage(res.data.currentPage)
          setTotalPages(res.data.totalPages)
        }
      })
  }

  const loadSetting = () => {
    dispatch(getListSettingsById(companyId))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          if (data.data.adminAndDealerDateFormat === 'DD-MM-YYYY') {
            setDate('dd-MM-yyyy HH:mm')
          }
          if (data.data.adminAndDealerDateFormat === 'MM-DD-YYYY') {
            setDate('MM-dd-yyyy HH:mm')
          }
          if (data.data.adminAndDealerDateFormat === 'YYYY-MM-DD') {
            setDate('yyyy-MM-dd HH:mm')
          }
        }
      }
      )
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
      return text.substring(0, 20) + '...'
    } else {
      return text
    }
  }
  
  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={
            params.row.imagePreSignedURL !== undefined
              ? params.row.imagePreSignedURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={params.row.imagePreSignedURL}
        ></Avatar>
      }
    />
  )

  const handleView = (params) => {
    history.push({
      pathname: '/vis/viewCustomerReport',
      state: params,
    })
  }
  const renderCellNextPaymentDuedate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.subscriptionEndDate)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };


  const columns = React.useMemo(() => [
    {
      field: 'company.profile',
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },

    {
      field: 'companyName',
      headerName: t('CUSTDATABLE01'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'email',
      headerName: t('CUSTDATABLE02'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.email,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'Mobile',
      headerName: t('COMMON063'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.phone,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'subscription',
      headerName: t('CUSTDATABLE05'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.subscriptionName,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'BillingCycle',
      headerName: t('CUSTDATABLE14'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.billingCycle,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'PlanBasePrice',
      headerName: t('CUSTDATABLE20'),
      flex: 1,
      minWidth: 190,
      valueGetter: (params) => params.row.planBasePrice / 100,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'nextPaymentDuedate',
      headerName: t('CUSTDATABLE38'),
      flex: 1,
      minWidth: 190,
      renderCell: renderCellNextPaymentDuedate(date)
    },

    {
      field: 'Nextpaymentamount',
      headerName: t('CUSTDATABLE39'),
      flex: 1,
      minWidth: 230,
      valueGetter: (params) => params.row.planBasePrice / 100,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 120,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ViewIcon />}
          label={t('VIEW')}
          onClick={() => handleView(params.row)}
        />,
      ],
    },
  ],[date])

  const handleFilterIcon = () => {
    setOpenFilter(true)
  }

  const handleDownload = () => {
    if (rows.length === 0) {
      Toast(t('dataTableEmptyList'), 'error')
      return
    } else {
      setOpenDownload(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}
      <HeaderToolbar title={t('CUSTDATABLE10')} />

      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item style={{ margin: '0 8px' }}>
          <Tooltip title={t('ADD036')} onClick={handleFilterIcon}>
            <img src={filterIcon} />
          </Tooltip>
        </Box>

        <Box item style={{ margin: '0 8px' }}>
          <Tooltip title={t('ADD037')} onClick={handleDownload}>
            <img src={forwardIcon} />
          </Tooltip>
        </Box>

        <Box item>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: '100%', marginTop: '15px' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openFilter && (
        <CustomerReportFilter
          open={openFilter}
          applyFilter={handleFilter}
          onClose={() => {
            setOpenFilter(false)
          }}
          date={date}
        />
      )}

      {openDownload && (
        <CustomerTransactionAndReportExports
          open={openDownload}
          handleClose={() => {
            setOpenDownload(false)
          }}
          dateFormat={date}
        />
      )}
    </>
  )
}
export default CustomerReports
