import {
  Avatar,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Switch,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HeaderToolbar from '../../components/HeaderToolbar'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import SubscriptionTextfield from '../../components/CustomInputs/SubscriptionTextfield'
import { makeStyles } from '@material-ui/core'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import { REACT_SUBSCRIPTION_MANAGEMENT } from '../../actions/EndPoints'
import CommonUtil from '../../Util/CommonUtils'
import Toast from '../../components/ToastContainer/CustomToast'
import { useEffect } from 'react'
import {
  createDiscount,
  createFeatures,
  getFeatures,
} from '../../slices/Subscription/SubscriptionSlice'
import SubscriptionDropdown from '../../components/CustomInputs/SubscriptionDropdown'
import DoneIcon from '@mui/icons-material/Done'
import { Tooltip } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { createProduct } from '../../slices/Subscription/SubscriptionSlice'
import { getDiscounts } from '../../slices/Subscription/SubscriptionSlice'
import ViewSubscription from './ViewSubscription'
import CardTwo from '../../assets/CardTwo.svg'
import 'react-perfect-scrollbar/dist/css/styles.css';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.light),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      fontSize: '12px',
    },
  },

  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#0F5A9C !important',
    opacity: 1,
  },
}))

const StyledBadge = styled(FormGroup)(({ theme }) => ({
  '& .MuiTypography-root': {
    left: '0px !important',
    position: 'absolute',
  },
}))
const useStyles = makeStyles((theme) => ({
  title: {
    color: '#c4c4c4 !important',
    fontSize: '20px !important',
    fontWeight: '500 !important',
  },
}))

function CreateSubscription(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [openView, setOpenView] = useState(false)
  const [freeTrial, setFreeTrail] = useState(false)
  const { open, handleClose } = props

  const dto1 = {
    name: 'Walk-In Registration through DL & Passport Scanner on Web',
    quota: 0,
    type_of_quota: '0',
    validity: true,
  }
  const dto2 = {
    name: 'Offender Verification',
    quota: 0,
    type_of_quota: '0',
    validity: true,
  }
  const dto3 = {
    name: 'Delivery Report',
    quota: 0,
    type_of_quota: '0',
    validity: true,
  }
  const dto4 = {
    name: 'Checklist',
    quota: 0,
    type_of_quota: '0',
    validity: true,
  }
  const dto5 = {
    name: 'Watchlist',
    quota: 0,
    type_of_quota: '0',
    validity: true,
  }
  const dto6 = {
    name: 'Delivery Options for Mobile Application',
    quota: 0,
    type_of_quota: '0',
    validity: true,
  }
  const dto7 = {
    name: 'Walk-In Registration through DL on Mobile Application',
    quota: 0,
    type_of_quota: '0',
    validity: true,
  }
  const dto8 = {
    name: 'Card Template',
    quota: 0,
    type_of_quota: '0',
    validity: true,
  }
  const dto9 = {
    name: 'Atlas Integration',
    quota: 0,
    type_of_quota: '0',
    validity: true,
  }

  const discountPayload = { name: 'Discount1', rate: 1, validity: true }

  const createFeatureList = [
    dto1,
    dto2,
    dto3,
    dto4,
    dto5,
    dto6,
    dto7,
    dto8,
    dto9,
  ]

  const { t } = useTranslation()
  const [features, setFeatures] = useState([])
  const [priceName, setPriceName] = useState('')
  const [priceAmount, setPriceAmount] = useState('')
  const [selectedFeature, setSelectedFeature] = useState([])
  const [featureNameList, setFeatureNameList] = useState([])
  const [periodValue, setPeriodValue] = useState('')
  const [currency, setCurrency] = useState('$')
  const [feature1, setFeature1] = useState('')
  const [feature2, setFeature2] = useState('')
  const [feature3, setFeature3] = useState('')
  const [feature4, setFeature4] = useState('')
  const [priceState, setPriceState] = useState(false)
  const [feature5, setFeature5] = useState('')
  const [discountId, setDiscountId] = useState('')
  const [type, setType] = useState(0)
  const [period, setperiod] = useState(0)

  const [state, setState] = React.useState({
    feature: selectedFeature,
    name: '',
    annual_price: 0,
    monthly_price: 0,
    country: 'US',
    currency: 'USD',
    discount: '',
    validity: true,
    type: 0,
    period: 0,
    visitLimit: 0,
  })
  const [featureName, setFetureName] = React.useState({})

  const [error, setError] = useState({
    name: false,
    price: false,
    annual_price: false,
    monthly_price: false,
    country: false,
    currency: false,
    periodValue: false,
    visitLimit: false,
    subscriptionType: false,
  })

  useEffect(() => {
    loadFeatures()
  }, [])

  const handleChangePlan = (data) => {
    let plan = ''
    switch (data) {
      case t('MONTHLY'):
        plan = 'Monthly'
        break
      case t('FREE_TRIAL'):
        plan = 'Free Trial'
        break
      default:
        plan = ''
    }
    return plan
  }

  const handleChangeDropdown = (event) => {
    const selectPlan = handleChangePlan(event.target.value)
    if (selectPlan === 'Yearly') {
      setPeriodValue('Yearly')
      setState({
        ...state,
        type: 2,
        period: 1,
      })
      setFreeTrail(false)
      setError({
        ...error,
        subscriptionType: false,
      })
    } else if (selectPlan === 'Monthly') {
      setPeriodValue('Monthly')
      setState({
        ...state,
        type: 1,
        period: 1,
        name: state.name === 'Free Trial' ? '' : state.name,
      })
      setFreeTrail(false)
      setError({
        ...error,
        subscriptionType: false,
      })
    } else {
      setState({
        ...state,
        monthly_price: 0,
        name: 'Free Trial',
        type: 1,
        period: 1,
      })
      setError({
        ...error,
        price: false,
        subscriptionType: false,
      })
      setFreeTrail(true)
      setPriceAmount(currency + '0')
      setPriceName('Free Trial')

      setPeriodValue('Monthly')
    }
    setError({
      ...error,
      periodValue: false,
      subscriptionType: false,
    })
    if (state.annual_price !== '' || state.monthly_price !== '') {
      setPriceState(true)
    }
  }

  const cancel = (e) => {
    history.push(REACT_SUBSCRIPTION_MANAGEMENT)
  }

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.name)) {
      Toast(t('SUBSCRIPTION_PLAN_NAME'), 'error')
      setError({ ...error, name: true })
      return
    }

    if (CommonUtil.isEmptyString(periodValue)) {
      Toast(t('SELECT_SUBSCRIPTION_Type'), 'error')
      setError({ ...error, subscriptionType: true })
      return
    }

    if (state.annual_price.length > 6 || state.monthly_price.length > 6) {
      Toast(t('SUBSCRIPTION_LESS_THEN_SIX'), 'error')
      setError({ ...error, price: true })
      return
    }

    if (state.annual_price === '' || state.monthly_price === '') {
      Toast(t('SUBSCRIPTION_ENTER_PLAN'), 'error')
      setError({ ...error, price: true })
      return
    }

    if (
      state.annual_price === 0 &&
      state.monthly_price === 0 &&
      freeTrial !== true
    ) {
      Toast(t('SUBSCRIPTION_ENTER_PLAN'), 'error')
      setError({ ...error, price: true })
      return
    }

    if (
      isNaN(parseInt(state.annual_price) && isFinite(state.annual_price)) ||
      isNaN(parseInt(state.monthly_price) && isFinite(state.monthly_price))
    ) {
      Toast(t('SUBSCRIPTION_CAN_NOT_CHARACTER'), 'error')
      setError({ ...error, price: true })
      return
    }

    if (
      !/^\d+$/.test(state.annual_price) ||
      !/^\d+$/.test(state.monthly_price)
    ) {
      Toast(t('SUBSCRIPTION_CAN_NOT_CHARACTER'), 'error')
      setError({ ...error, price: true })
      return
    }

    if (
      isNaN(parseInt(state.visitLimit) && isFinite(state.visitLimit)) &&
      state.visitLimit !== ''
    ) {
      Toast(t('SUBSCRIPTION_VISITOR_NOT_CHARACTER'), 'error')
      setError({ ...error, visitLimit: true })
      return
    }

    if (state.visitLimit === '') {
      Toast(t('SUBSCRIPTION_VISITOR_TYPE'), 'error')
      setError({ ...error, visitLimit: true })
      return
    }

    if (!/^\d+$/.test(state.visitLimit)) {
      Toast(t('SUBSCRIPTION_VISITOR_NOT_CHARACTER'), 'error')
      setError({ ...error, visitLimit: true })
      return
    }

    if (state.visitLimit.length > 6) {
      Toast(t('SUBSCRIPTION_VISITOR_EXCEED_SIX'), 'error')
      setError({ ...error, visitLimit: true })
      return
    }

    if (state.visitLimit === 0) {
      Toast(t('SUBSCRIPTION_VISITOR_TYPE'), 'error')
      setError({ ...error, visitLimit: true })
      return
    }

    if (CommonUtil.isEmptyString(periodValue)) {
      Toast(t('SELECT_SUBSCRIPTION_Type'), 'error')
      setError({ ...error, periodValue: true })
      return
    }

    if (priceState === false) {
      Toast(t('SUBSCRIPTION_ENTER_PRICE'), 'error')
      setError({ ...error, price: true })
      return
    }

    if (CommonUtil.isEmptyString(state.currency)) {
      Toast(t('SUBSCRIPTION_SELECT_CURRENCY'), 'error')
      setError({ ...error, price: true })
      return
    }

    if (CommonUtil.isEmptyArray(state.feature)) {
      Toast(t('SUBSCRIPTION_ATLEAST_ONE_SUBSCRIPTION'), 'error')
      return
    }

    dispatch(createProduct(state))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4026' && data.data) {
          Toast(t('SUBSCRIPTION_CREATE_SUCESS'), 'success')
          history.push(REACT_SUBSCRIPTION_MANAGEMENT)
        } else {
          Toast(data.message, 'error')
          return
        }
      })
  }

  const handleChange = (event) => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.value,
    })
    setError({
      name: false,
    })
  }

  const handleCurrencyChange = (event) => {
    if (event.target.value === t('SUBSCRIPTIONUSD')) {
      setCurrency('$')
      setState({
        ...state,
        currency: 'USD',
      })
    } else if (event.target.value === 'Euro') {
      setCurrency('€')
      setState({
        ...state,
        currency: 'EUR',
      })
    }
  }

  const handleChange2 = (event) => {
    if (periodValue === 'Yearly') {
      setState({
        ...state,
        annual_price: event.target.value,
      })
    } else {
      setState({
        ...state,
        monthly_price: event.target.value,
      })
    }
    setPriceAmount(currency + event.target.value)
    setPriceState(true)
    setError({
      ...error,
      price: false,
    })
  }

  const handleToggleChange = (event, featureId, featureName) => {
    if (event.target.checked === true) {
      selectedFeature.push(featureId)
      featureNameList.push(featureName)
    } else {
      const featureIdIndex = selectedFeature.indexOf(featureId)
      if (indexToRemove !== -1) {
        selectedFeature.splice(featureIdIndex, 1)
      }
      const indexToRemove = featureNameList.indexOf(featureName)
      if (indexToRemove !== -1) {
        featureNameList.splice(indexToRemove, 1)
      }
    }
    setSelectedFeature(selectedFeature)
    setFeatureNameList(featureNameList)
    if (featureNameList[0] !== undefined) {
      setFeature1(featureNameList[0])
    } else {
      setFeature1('')
    }
    if (featureNameList[1] !== undefined) {
      setFeature2(featureNameList[1])
    } else {
      setFeature2('')
    }
    if (featureNameList[2] !== undefined) {
      setFeature3(featureNameList[2])
    } else {
      setFeature3('')
    }
    if (featureNameList[3] !== undefined) {
      setFeature4(featureNameList[3])
    } else {
      setFeature4('')
    }
    if (featureNameList[4] !== undefined) {
      setFeature5(featureNameList[4])
    } else {
      setFeature5('')
    }
  }

  const loadFeatures = () => {
    const query = {
      pageNumber: 1,
      pageSize: 100,
    }

    dispatch(getFeatures(query))
      .unwrap()
      .then((data) => {
        if (data.code === '200' && data.data) {
          setFeatures(data.data.results)
          if (data.code === '200' && data.data.count === 0) {
            dispatch(createFeatures(createFeatureList))
              .unwrap()
              .then((data) => {
                dispatch(getFeatures(query))
                  .unwrap()
                  .then((updatedData) => {
                    if (updatedData.code === '200' && updatedData.data) {
                      setFeatures(updatedData.data.results)
                    }
                  })
              })
          }
        } else {
          setFeatures([])
        }
      })

    dispatch(getDiscounts(query))
      .unwrap()
      .then((data) => {
        if (data.code === '200' && data.data) {
          const discountList = data.data.results
          if (data.data.count === 0) {
            dispatch(createDiscount(discountPayload))
              .unwrap()
              .then((discountdata) => {
                if (discountdata.code === '201') {
                  setDiscountId(discountdata.data.id)
                  setState({
                    ...state,
                    discount: discountdata.data.id,
                  })
                }
              })
          }
          if (discountList.length > 0) {
            setDiscountId(discountList[0].id)
            setState({
              ...state,
              discount: discountList[0].id,
            })
          }
        }
      })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 17) {
      return text.substring(0, 26) + '...'
    } else {
      return text
    }
  }

  const handleTextPrice = (text) => {
    if (text && text.length > 9) {
      return text.substring(0, 9) + '...'
    } else {
      return text
    }
  }

  return (
    <>
      <HeaderToolbar
        title={t('LABELNAVITEMSCREATESUBSCRIPTION')}
        back_button={true}
      />

      <Grid container spacing={2} padding={2} style={{backgroundColor : "#ffffff" , marginLeft:"10px", marginTop : "10px"}}>
        <Grid item xs = {12} sm={12} md={6} lg={6} xl={6}>
        {freeTrial ? (
                  <CustomTextfeild
                    name={'name'}
                    value={state.name}
                    // error={error.name}
                    handleChange={handleChange}
                    type={'text'}
                    // validation="restrictLength"
                    required
                    disabled
                  />
                ) : (
                  <CustomTextfeild
                    label={t('SUBSCRIPTIONPLANNAME')}
                    name={'name'}
                    error={error.name}
                    value={state.name}
                    handleChange={handleChange}
                    type={'text'}
                    validation="restrictLength"
                    required
                    autoComplete="off"
                  />
                )}
        </Grid>
        <Grid item xs = {12} sm={12} md={6} lg={6} xl={6} sx={{mt : "15px"}}>
              <SubscriptionDropdown
                  label={t('SELECTSUBSCRIPTIONTYPEMANDATORY')}
                  error={error.subscriptionType}
                  name="Subscription Type"
                  handleChange={(e) => handleChangeDropdown(e)}
                />
        </Grid>
        <Grid item xs = {12} sm={12} md={6} lg={6} xl={6}>
        {freeTrial ? (
                  <SubscriptionTextfield
                    name={'price'}
                    value={state.monthly_price}
                    error={error.price}
                    handleChange={handleChange2}
                    handleCurrencyChange={handleCurrencyChange}
                    type={'text'}
                    periodValue={periodValue}
                    validation="numeric"
                    required={true}
                    disabled
                  />
                ) : (
                  <SubscriptionTextfield
                    label={t('SUBSCRIPTIONPRICE')}
                    name={'price'}
                    error={error.price}
                    value={state.monthly_price}
                    handleChange={handleChange2}
                    handleCurrencyChange={handleCurrencyChange}
                    type={'text'}
                    periodValue={periodValue}
                    validation="numeric"
                    required={true}
                  />
                )}
        </Grid>
        <Grid item xs = {12} sm={12} md={6} lg={6} xl={6}>
              <CustomTextfeild
                  label={t('SUBSCRIPTIONVISITIONCREATION')}
                  name={'visitLimit'}
                  error={error.visitLimit}
                  handleChange={handleChange}
                  validation="text"
                  required={true}
                />
        </Grid>

      </Grid>
     
     <Grid container spacing={2} style={{margin:"10px" }}>

        <Grid item xs={5} md= {6}  lg={6} style={{ backgroundColor: "#ffffff" }}>
          <StyledBadge>
                  <h4 style={{ color: '#0F5A9C' }}>
                    {t('SUBSCRIPTIONINFROMATION')}
                  </h4>
                  <div> 
                        <PerfectScrollbar
                          options={{ wheelPropagation: true }}
                          style={{ width: '380px', height: '400px', marginTop:"10px" }}
                        >
                <FormControl component="fieldset" variant="standard">
                  {features
                    ? features.map((feature) => {
                        return (
                          <FormGroup key={feature.id}>
                            <Grid className="leftSwitch"></Grid>
                            <Grid container alignItems="center">
                              <Grid item xs={6}>
                                <FormLabel
                                  component="legend"
                                  className="formLabel"
                                  style={{
                                    maxWidth: '330px',
                                    wordWrap: 'break-word',
                                  }}
                                >
                                  {feature.name}
                                </FormLabel>
                              </Grid>
                              <Grid item xs={6}>
                                <Android12Switch
                                  checked={featureName.name}
                                  onChange={(event) =>
                                    handleToggleChange(event, feature.id, feature.name)
                                  }
                                  name={feature.name}
                                />
                              </Grid>
                            </Grid>
                          </FormGroup>
                        );
                      })
                    : null}
                </FormControl>
              </PerfectScrollbar>
            </div>
          </StyledBadge>
        </Grid>
      
      <Grid item xs={7} md={6} lg={6} style={{backgroundColor:"#f0f0f0" , display : 'flex' , justifyContent : 'center'}}>
        <Stack>
              <Grid>
                  <Card
                    sx={{
                      minWidth: 0,
                      height: '480px',
                      width: '360px',
                    }}
                  >
                    <CardMedia
                      component="img"
                      src={CardTwo}
                      alt="green iguana"
                      sx={{
                        marginTop: '-13px',
                        marginLeft: '-19px',
                        width: '400px',
                      }}
                    />

                    <div
                      style={{
                        position: 'absolute',
                        color: 'white',
                        top: 115,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '19px',
                        opacity: 1,
                        font: 'normal normal normal 19px/17px Roboto',
                      }}
                    >
                      {periodValue === 'Monthly' ? t('MONTHLY') : ''}
                    </div>
                    <Tooltip
                      title={`${
                        state.name === 'Free Trial'
                          ? t('FREE_TRIAL')
                          : state.name
                      }`}
                      placement="bottom-start"
                    >
                      <div
                        style={{
                          position: 'absolute',
                          color: 'white',
                          top: 21,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '33px',
                          opacity: 1,
                          font: 'normal normal normal 30px/32px Roboto',
                        }}
                      >
                        {handleTextPrice(
                          state.name === 'Free Trial'
                            ? t('FREE_TRIAL')
                            : state.name,
                        )}
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        position: 'absolute',
                        color: 'white',
                        top: 59,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '40px',
                        font: 'normal normal bold 40px/48px Roboto',
                      }}
                    >
                      {priceAmount}
                    </div>

                    {feature1 !== '' ? (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginLeft: '30px',
                          position: 'absolute',
                          color: 'black',
                          top: 220,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <DoneIcon
                            sx={{
                              fontSize: '23px',
                              color: 'green',
                              marginRight: '5px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={`${feature1}`}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {handleTextOverflow(feature1)}
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : null}

                    {feature2 !== '' ? (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginLeft: '30px',
                          position: 'absolute',
                          color: 'black',
                          top: 260,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <DoneIcon
                            sx={{
                              fontSize: '23px',
                              color: 'green',
                              marginRight: '5px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={`${feature2}`}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {handleTextOverflow(feature2)}
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : null}

                    {feature3 !== '' ? (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginLeft: '30px',
                          position: 'absolute',
                          color: 'black',
                          top: 300,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <DoneIcon
                            sx={{
                              fontSize: '23px',
                              color: 'green',
                              marginRight: '5px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={`${feature3}`}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {handleTextOverflow(feature3)}
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : null}

                    {feature4 !== '' ? (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginLeft: '30px',
                          position: 'absolute',
                          color: 'black',
                          top: 340,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <DoneIcon
                            sx={{
                              fontSize: '23px',
                              color: 'green',
                              marginRight: '5px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={`${feature4}`}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {handleTextOverflow(feature4)}
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : null}

                    {feature5 !== '' ? (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginLeft: '30px',
                          position: 'absolute',
                          color: 'black',
                          top: 380,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontSize: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid item>
                          <DoneIcon
                            sx={{
                              fontSize: '23px',
                              color: 'green',
                              marginRight: '5px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={`${feature5}`}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {handleTextOverflow(feature5)}
                            </div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ) : null}

                    <CardContent></CardContent>
                    <CardActions>
                      {state.name !== '' &&
                      (state.annual_price !== 0 || state.monthly_price !== 0) &&
                      periodValue !== '' ? (
                        <ViewSubscription
                          featureNameList={featureNameList}
                          planName={priceName}
                          price={priceAmount}
                          visitLimit={state.visitLimit}
                          subscriptionType={periodValue}
                          open={openView}
                        />
                      ) : (
                        ''
                      )}
                    </CardActions>
                  </Card>
                  </Grid>

                  <Grid container style={{marginTop : "20px" }}>
                    <Grid item>
                  <SubmitButtons onClick={handleSubmit} variant="contained">
                  <Typography>{t('COMMON007')}</Typography>
                </SubmitButtons>
                </Grid>
                <Grid item style={{marginLeft : "10px"}}>
                <CancelButtons
                  color="secondary"
                  onClick={(e) => cancel()}
                  variant="contained"

                >
                  <Typography>{t('COMMON008')}</Typography>
                </CancelButtons>
                </Grid>
                  </Grid>
                  </Stack>
      </Grid>

  </Grid>

    </>
  )
}
export default CreateSubscription
