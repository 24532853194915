import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import atlasIntegrationService from '../../services/AtlasIntegration/AtlasIntegrationService';

const initialState=[];

export const generateAccessKey = createAsyncThunk('client/apiAccessKey',async()=>{
    const res = await atlasIntegrationService.generateAccessKey();
    return res.data;
});

export const saveClientData = createAsyncThunk('client',async(payload)=>{
    const res = await atlasIntegrationService.saveClientRecord(payload);
    return res.data;
});

export const getClientData = createAsyncThunk('clients/',async(companyId)=>{
    const res = await atlasIntegrationService.getClientData(companyId);
    return res.data;
});



const atlasIntegrationSlice = createSlice({
    name: 'atlasIntegration',
    initialState,
    extraReducers: {
        [getClientData.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }
     }
})

const { reducer } = atlasIntegrationSlice;
export default reducer;