import { Grid } from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { Typography } from "@mui/material";
import { ReactComponent as DeleteVisitIcon } from "../../assets/DeleteVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Toast from "../../components/ToastContainer/CustomToast";
import { deleteSiteType } from "../../slices/Site/SiteTypeSlice";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { deleteCardTemplate } from "../../slices/CardTemplate/CardTemplateSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import { ReactComponent as DeleteIcon } from '../../assets/DeleteIcon.svg'

export default function DeleteCardTemplate(props) {
    const { open, handleClose } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    useEffect(() => { }, []);

    const handleSubmit = () => {
        const cardTemplateId = props.DeleteTemplateId;
        dispatch(deleteCardTemplate(cardTemplateId))
            .unwrap()
            .then((data) => {
                handleClose(false);
                if (data.code === "CVAI0031") {
                    Toast(t(data.message), "success");
                    props.delete();
                    return;
                }
                else {
                    Toast(t(data.data.error[0].message), 'error')
                }
            });
    };

    return (
        <>
            <DialogBox
                 Header={
                    <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
                     {t("COMMON004")}
                    </span>
                  }
                acceptText={t("CONFIRM")}
                cancelText={t("COMMON008")}
                fullWidth={true}
                onClose={() => handleClose(false)}
                cancel={handleClose}
                onSubmit={handleSubmit}
                open={open}
            >
                <Grid
                    container
                    justifyContent="center"
                    direction="column"
                    alignItems={"center"}
                    style={{ padding: "20px", backgroundColor: darkMode ? '#0F273B' : '' }}
                >
                    <Grid>
                    <DeleteIcon width="60px" height={'50px'}/>
                    </Grid>
                    <Grid>
                        <Grid
                            style={{
                                wordWrap: "break-word",
                                opacity: 1,
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "row",
                                fontSize: "17px",
                                marginTop: "15px",
                            }}
                        >
                            <Typography
                                style={{
                                    color: darkMode ? '#fff' : '#242424',
                                    marginRight: "4px", fontSize: '17px',
                                }}
                            >
                                {t("TEMPLATEDELETEMSG")} ?
                            </Typography>
                            {/* <Typography style={{ fontSize: "16px", marginRight: "4px" }}>
                                {props.DeleteTemplateName}  
                            </Typography> */}

                        </Grid>
                    </Grid>
                </Grid>
            </DialogBox>
        </>
    );
}
