import { createAsyncThunk } from "@reduxjs/toolkit"
import CreateCustomerService from "../../services/Customer/CustomerService";

export const CreateCustomerCompany = createAsyncThunk('createCustomer' , async (payload) => {
    const res = await CreateCustomerService.create(payload);
    return res.data;
})

export const getCustomers = createAsyncThunk('customers/list' , async(payload) => {
    const res = await CreateCustomerService.get(payload);
    return res.data;
})

export const resendActivationLink = createAsyncThunk('resendActivationLink' , async(payload) => {
    const res = await CreateCustomerService.resendActivationLink(payload);
    return res.data;
})

export const deleteCustomer = createAsyncThunk('customers/deleteCustomer' , async(payload) => {
    const res = await CreateCustomerService.deleteCustomer(payload);
    return res.data;
})

export const updateCustomerAccount = createAsyncThunk('customers/deleteCustomer' , async(payload) => {
    const res = await CreateCustomerService.updateCustomerAccount(payload);
    return res.data;
})

export const listOfCustomerDetalis = createAsyncThunk('customers/listOfCustomer' , async(payload) => {
    const res = await CreateCustomerService.listOfCustomerDetalis(payload);
    return res.data;
})

export const fetchCustomersByDealerId = createAsyncThunk('/fetchCustomers' , async(payload) => {
    const res = await CreateCustomerService.fetchCustomersByDealerId(payload);
    return res.data;
})

export const listOfCustomerDetalisWithoutStatus = createAsyncThunk('customers/listOfCustomer/withOut/Status' , async(payload) => {
    const res = await CreateCustomerService.listOfCustomerDetalisWithoutStatus(payload);
    return res.data;
})

export const listOfCustomerDetalisWithoutStatusDealer = createAsyncThunk('customers/listOfCustomer/withOut/Status' , async(payload) => {
    const res = await CreateCustomerService.listOfCustomerDetalisWithoutStatusDealer(payload);
    return res.data;
})

export const fetchCustomersByDealerIdWithoutStatus = createAsyncThunk('/fetchCustomers/withoutStatus' , async(payload) => {
    const res = await CreateCustomerService.fetchCustomersByDealerIdWithoutStatus(payload);
    return res.data;
})

export const fetchCustomersDetailsByDealerCompanyId = createAsyncThunk('/fetchCustomers/list' , async(payload) => {
    const res = await CreateCustomerService.fetchCustomersDetailsByDealerCompanyId(payload);
    return res.data;
})

export const downloadCustomerInvoice = createAsyncThunk('/download/invoice/list' , async(payload) => {
    const res = await CreateCustomerService.downloadCustomerInvoice(payload);
    return res.data;
})

export const downloadCustomerInvoiceDealerCompany = createAsyncThunk('/download/invoice/dealer/list' , async(payload) => {
    const res = await CreateCustomerService.downloadCustomerInvoiceDealerCompany(payload);
    return res.data;
})