import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as DeleteIcon } from "../../../src/assets/DeleteIcon.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Toast from "../../components/ToastContainer/CustomToast";
import { checkEmployeeMemeberIsActive, deleteEmployee } from "../../slices/Employee/EmployeeSlice";
import { deleteDevice } from "../../slices/Zlink Device Management/DeviceSlice";

export default function DeleteDevice(props) {
  const { open, onClose,reloadlist,deleteData } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [payload, setPayload] = React.useState({
    id: deleteData.id,
    deviceName: deleteData.deviceName,
  });

  const handleSubmit = (data) => {
  
          dispatch(deleteDevice(payload.id))
            .unwrap()
            .then((data) => {
              if (data.code === "DMSI0000") {
                Toast("Device Deleted successfully.", 'success');
                onClose();
                reloadlist();
              }
              else {
                Toast((data.message), 'error')
                onClose();
              }
            })
        }

  return (
    <>
      <DialogBox
        Header="Delete"
        acceptText="Confirm"
        cancelText="Cancel"
        fullWidth={true}
        onClose={() =>onClose()}
        cancel={onClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
          style={{ padding: "20px" }}
        >
          <Grid>
            <DeleteIcon width='60px' height={'50px'} />
          </Grid>
          <Grid>
            <Grid
              style={{
                wordWrap: "break-word",
                opacity: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                fontSize: "17px",
                marginTop: "15px",
              }}
            >
              <Typography style={{ color: "#242424", marginRight: "4px" }}>
                Are you sure you want to delete this device for {" "}
              </Typography>
              <Typography style={{ fontWeight: "bold", marginRight: "4px" }}>
                {payload.deviceName}
              </Typography>
              ?
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
