import React, { useEffect, useState } from "react";
import HeaderToolbar from "../../components/HeaderToolbar";
import RestrictTypeAddIcon from "../../../src/assets/RestrictTypeAddIcon.svg"
import { Box, Link } from "@mui/material";
import Search from "../../components/SearchTab/Search";
import ChecklistFilterForm from "../Checklist/ChecklistFilterForm";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DataTable from "../../components/DataTable/DataTable";
import { getRestrictTypeListV2 } from "../../slices/RestirctTypeSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ChecklistTemplateDetails from "../Checklist/ChecklistTemplateDetails";
import EditChecklistIcon from "../Checklist/EditChecklistIcon";
import AddRestrictType from "./AddRestrictType";


export default function RestrictType(props) {
    const [open, setOpen] = useState(false);
    const [clear, setClear] = React.useState(false);
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [openEditDialog, setOpenEditDialog] = useState(false);

    const [selectedObject, setSelectedObject] = useState({
        id: '',
        code: '',
        name: '',
        description: '',
    });

    const [filter, setFilter] = React.useState({
        id: "",
        code: "",
        name: "",
        description: "",
        isPageable: true,
    });

    const defaultFilter = {
        code: "",
        name: "",
        description: "",
    };

    const handleOpen = () => {
        setOpen(true);
    };


    useEffect(() => {
        loadData(0, rowsPerPage);
    }, [filter]);

    const loadData = (page, rowsPerPage) => {
        const payload = {
            filter: filter,
            page: page,
            rowsPerPage: rowsPerPage,
        };

        dispatch(getRestrictTypeListV2(payload))
            .unwrap()
            .then((data) => {
                if (data.code === "CVSI0000" && data.data) {
                    setTotalRecords(data.data.totalCount);
                    setRows(data.data.item);
                    setPage(data.data.curPage);
                    setTotalPages(data.data.totalPages);
                } else {
                    setRows([]);
                }
            })
            .catch((er) => { });
    };

    const globalsearch = (searchedVal) => {
        const filteredRows = rows.filter((test) => {
            return test.description.toLowerCase().includes(searchedVal.toLowerCase()) ||
                test.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
                test.code.toLowerCase().includes(searchedVal.toLowerCase())
        });
        setRows(filteredRows);
    };

    const handleEditRestrictType = (params) => {
        setOpenEditDialog(!openEditDialog);
        setSelectedObject(params);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (newPage, size) => {
        setPage(newPage);
        setRowsPerPage(size);
        loadData(newPage-1, size);
    };

    const applyFilter = (filterData) => {
        setClear(true);
        const query = {
            code: filterData.code,
            name: filterData.name,
            description: filterData.description,
        };

        setFilter({
            ...filter,
            ...query,
        });
    };

    const resetFilter = () => {
        setClear(false);
        setFilter({
            ...defaultFilter,
        });
    };

    const columns = React.useMemo(() => [
        {
            headerName: "",
            sortable: false,
            width: 5,
            renderCell: (params) => {
              return <div></div>;
            },
          },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            width: 110,
            valueGetter: (params) => params.row.name,
        },

        (process.env.REACT_APP_ENVIRONMENT === 'USA'?
        {
            field: "code",
            headerName: t("COMMON067"),
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => params.row.code,
        } :

        {
            field: "code",
            headerName: "Code",
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => params.row.code,
        }),

        {
            field: "description",
            headerName: "Description",
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => params.row.description,
        },

        {
            field: "Actions",
            headerName: t("ACTION"),
            type: "actions",
            flex: 1,
            minWidth: 190,
            width: 150,
            headerAlign: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<ChecklistTemplateDetails
                        title={"Restrict Type Details"}
                        rowData={params.row}
                    />
                    }
                    label="View"
                />,

                <GridActionsCellItem
                    icon={<EditChecklistIcon />}
                    label="Edit"
                    onClick={() => handleEditRestrictType(params.row)}
                />,


            ],
        },
    ]);

    return (
        <>
            <HeaderToolbar
                src={RestrictTypeAddIcon}
                onClick={handleOpen}
                title=" Restrict Type "
                tooltipTitle="Add Restrict" />

            <Box
                display={"flex"}
                width={"100%"}
                flexDirection={"row-reverse"}
                alignItems={"center"}
            >
                <Box item>
                    {clear && (
                        <Link
                            href="#"
                            underline="hover"
                            className="line"
                            style={{
                                opacity: "0.8",
                                color: "#E3393C",
                                fontSize: "14px",
                                underline: "hover",
                                display: "inline",
                            }}
                            onClick={resetFilter}
                        >
                            {t('CLEARFILTER')}
                        </Link>
                    )}
                </Box>

                <Box item>
                    <ChecklistFilterForm
                        rowsPerPage={rowsPerPage}
                        applyFilter={(data) => applyFilter(data)}
                    />
                </Box>

                <Box item p={1}>
                    <Search
                        onSearch={(e) => globalsearch(e)}
                        clearSearch={() => loadData(page, rowsPerPage)}
                    />
                </Box>
            </Box>

            <div style={{ height: 300, width: "100%" }}>
                <DataTable
                    columns={columns}
                    rows={rows}
                    page={page}
                    count={totalRecords}
                    // rowId={(row) => row.id}
                    pageCount={totalPages}
                    pageSize={rowsPerPage}
                    handleChange={(newPage, size) => handleChange(newPage, size)}

                />
            </div>

            {open && (
                <AddRestrictType
                    open={open}
                    selectedObject={selectedObject}
                    handleClose={() => {
                        handleClose(loadData(page, rowsPerPage)
                        );
                    }}
                />
            )}
            {openEditDialog && (
                <AddRestrictType
                    open={openEditDialog}
                    selectedObject={selectedObject}
                    handleClose={() => {
                        setOpenEditDialog(false);
                        handleClose(loadData(page, rowsPerPage));
                        setSelectedObject({
                            id: '',
                            code: '',
                            name: '',
                            description: '',
                        });
                    }}
                />
            )}

        </>
    )
}


