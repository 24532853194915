import BrowserLanguage from '../../components/Util/BrowserLanguage'
import {
  REACT_URL_ADDRESSTYPE,
  REACT_URL_APPROVAL,
  REACT_URL_BLOCKED_VISITORS,
  REACT_URL_CHECKINOUTDEVICES,
  REACT_URL_DASHBOARD,
  REACT_URL_INVITATIONS,
  REACT_URL_PERSON,
  REACT_URL_REGISTRATIONS,
  REACT_URL_ROLE,
  REACT_URL_SITE,
  REACT_URL_SITETYPE,
  REACT_URL_VISITORLOGS,
  REACT_URL_PURPOSETYPE,
  REACT_URL_WATCHLIST,
  REACT_URL_CHECKLIST,
  REACT_URL_RESTRICTTYPE,
  REACT_URL_EMERGENCYMESSAGE,
} from '../../actions/EndPoints'
import { ReactComponent as Dashboard_Icon } from '../../assets/images/dashboard-icons/dashboard_icon.svg'
import { ReactComponent as vis_Dashboard_Icon } from '../../assets/USA_Images/navitem_images/Dashboard.svg'
import Device_Icon from '../../assets/USA_Images/navitem_images/settingWhite'
import vis_Device_Icon from '../../assets/images/dashboard-icons/SettingsGray'
import visitor_icon from '../../assets/USA_Images/navitem_images/VisitorWhite'
import vis_visitor_icon from '../../assets/images/dashboard-icons/VisitorGray'
import Organization_Icon from '../../assets/USA_Images/navitem_images/OrganizationWhite'
import vis_Organization_Icon from '../../assets/images/dashboard-icons/OrganizationGray'
import SiteManagement from '../../assets/USA_Images/navitem_images/SiteManagementWhite'
import vis_SiteManagement from '../../assets/images/dashboard-icons/Sitemanagementgray2'

let language = BrowserLanguage.getDefaultLanguage()
const dataEn = [
  {
    id: '0',
    name: 'Dashboard',
    link: `${REACT_URL_DASHBOARD}`,
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? vis_Dashboard_Icon
        : Dashboard_Icon,
  },
  {
    id: '5',
    name: 'Visitor',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? visitor_icon
        : vis_visitor_icon,
    items: [
      {
        id: '20',
        name: 'Invitations',
        link: `${REACT_URL_INVITATIONS}`,
      },
      {
        id: '21',
        name: 'Registrations',
        link: `${REACT_URL_REGISTRATIONS}`,
      },
      {
        id: '22',
        name: 'Approvals',
        link: `${REACT_URL_APPROVAL}`,
      },
      {
        id: '23',
        name:
          process.env.REACT_APP_ENVIRONMENT === 'USA'
            ? 'Blacklist Visitor'
            : 'Blocked Visitors',
        link: `${REACT_URL_BLOCKED_VISITORS}`,
      },
      {
        id: '24',
        name: 'Visitor Log',
        link: `${REACT_URL_VISITORLOGS}`,
      },
    ],
  },
  {
    id: '1',
    name:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? 'Employees'
        : 'Organization',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Organization_Icon
        : vis_Organization_Icon,
    items: [
      {
        id: '6',
        name: 'Person',
        link: `${REACT_URL_PERSON}`,
      },
      {
        id: '7',
        name: 'Role',
        link: `${REACT_URL_ROLE}`,
      },
    ],
  },
  {
    id: '2',
    name: 'Site Management',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? SiteManagement
        : vis_SiteManagement,
    items: [
      {
        id: '10',
        name: 'Address Type',
        link: `${REACT_URL_ADDRESSTYPE}`,
      },
      {
        id: '11',
        name: 'Site Type',
        link: `${REACT_URL_SITETYPE}`,
      },
      {
        id: '12',
        name: 'Site',
        link: `${REACT_URL_SITE}`,
      },
      {
        id: '12',
        name: 'Check In & Out Devices',
        link: `${REACT_URL_CHECKINOUTDEVICES}`,
      },
    ],
  },
  {
    id: '3',
    name: 'Settings',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Device_Icon
        : vis_Device_Icon,
    items: [
      {
        id: '13',
        name: 'Purpose Type',
        link: `${REACT_URL_PURPOSETYPE}`,
      },
      {
        id: '14',
        name: 'Restrict Type',
        link: `${REACT_URL_RESTRICTTYPE}`,
      },
      {
        id: '15',
        name: 'Checklist',
        link: `${REACT_URL_CHECKLIST}`,
      },
      {
        id: '16',
        name: 'Watchlist',
        link: `${REACT_URL_WATCHLIST}`,
      },
    ],
  },
]

const dataEs = [
  {
    id: '0',
    name: 'Tablero',
    link: `${REACT_URL_DASHBOARD}`,
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? vis_Dashboard_Icon
        : Dashboard_Icon,
  },
  {
    id: '5',
    name: 'Visitante',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? visitor_icon
        : vis_visitor_icon,
    items: [
      {
        id: '20',
        name: 'Invitaciones',
        link: `${REACT_URL_INVITATIONS}`,
      },
      {
        id: '21',
        name: 'Inscripciones',
        link: `${REACT_URL_REGISTRATIONS}`,
      },
      {
        id: '22',
        name: 'Aprobaciones',
        link: `${REACT_URL_APPROVAL}`,
      },
      {
        id: '23',
        name: 'Visitantes bloqueados',
        link: `${REACT_URL_BLOCKED_VISITORS}`,
      },
      {
        id: '24',
        name: 'Registro de visitantes',
        link: `${REACT_URL_VISITORLOGS}`,
      },
    ],
  },
  {
    id: '1',
    name: 'Organización',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Organization_Icon
        : vis_Organization_Icon,
    items: [
      {
        id: '6',
        name: 'Persona',
        link: `${REACT_URL_PERSON}`,
      },
      {
        id: '7',
        name: 'Role',
        link: `${REACT_URL_ROLE}`,
      },
    ],
  },
  {
    id: '2',
    name: 'Manejo de sitio',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? SiteManagement
        : vis_SiteManagement,
    items: [
      {
        id: '10',
        name: 'Tipo de dirección',
        link: `${REACT_URL_ADDRESSTYPE}`,
      },
      {
        id: '11',
        name: 'Tipo de sitio',
        link: `${REACT_URL_SITETYPE}`,
      },
      {
        id: '12',
        name: 'Sitio',
        link: `${REACT_URL_SITE}`,
      },
      {
        id: '12',
        name: 'Registrar dispositivos de entrada y salida',
        link: `${REACT_URL_CHECKINOUTDEVICES}`,
      },
    ],
  },
  {
    id: '3',
    name: 'Ajustes',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Device_Icon
        : vis_Device_Icon,
    items: [
      {
        id: '13',
        name: 'Tipo de propósito',
        link: `${REACT_URL_PURPOSETYPE}`,
      },
      {
        id: '14',
        name: 'Tipo de restricción',
        link: `${REACT_URL_RESTRICTTYPE}`,
      },
      {
        id: '15',
        name: 'Lista de Verificación',
        link: `${REACT_URL_CHECKLIST}`,
      },
      {
        id: '16',
        name: 'lista de seguimiento',
        link: `${REACT_URL_WATCHLIST}`,
      },
    ],
  },
]

const dataZh = [
  {
    id: '0',
    name: '仪表板',
    link: `${REACT_URL_DASHBOARD}`,
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? vis_Dashboard_Icon
        : Dashboard_Icon,
  },
  {
    id: '5',
    name: '游客',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? visitor_icon
        : vis_visitor_icon,
    items: [
      {
        id: '20',
        name: '邀请函',
        link: `${REACT_URL_INVITATIONS}`,
      },
      {
        id: '21',
        name: '注册',
        link: `${REACT_URL_REGISTRATIONS}`,
      },
      {
        id: '22',
        name: '批准',
        link: `${REACT_URL_APPROVAL}`,
      },
      {
        id: '23',
        name: '被阻止的访客',
        link: `${REACT_URL_BLOCKED_VISITORS}`,
      },
      {
        id: '24',
        name: '访客日志',
        link: `${REACT_URL_VISITORLOGS}`,
      },
    ],
  },
  {
    id: '1',
    name: '组织',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Organization_Icon
        : vis_Organization_Icon,
    items: [
      {
        id: '6',
        name: '人',
        link: `${REACT_URL_PERSON}`,
      },
      {
        id: '7',
        name: '角色',
        link: `${REACT_URL_ROLE}`,
      },
    ],
  },
  {
    id: '2',
    name: '现场管理',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? SiteManagement
        : vis_SiteManagement,
    items: [
      {
        id: '10',
        name: '地址类型',
        link: `${REACT_URL_ADDRESSTYPE}`,
      },
      {
        id: '11',
        name: '网站类型',
        link: `${REACT_URL_SITETYPE}`,
      },
      {
        id: '12',
        name: '地点',
        link: `${REACT_URL_SITE}`,
      },
      {
        id: '12',
        name: '签入和签出设备',
        link: `${REACT_URL_CHECKINOUTDEVICES}`,
      },
    ],
  },
  {
    id: '3',
    name: '设置',
    Icon:
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? Device_Icon
        : vis_Device_Icon,
    items: [
      {
        id: '13',
        name: '用途类型',
        link: `${REACT_URL_PURPOSETYPE}`,
      },
      {
        id: '14',
        name: '限制类型',
        link: `${REACT_URL_RESTRICTTYPE}`,
      },
      {
        id: '15',
        name: '清单',
        link: `${REACT_URL_CHECKLIST}`,
      },
      {
        id: '16',
        name: '关注列表',
        link: `${REACT_URL_WATCHLIST}`,
      },
    ],
  },
]

export default language === 'en' ? dataEn : language === 'es' ? dataEs : dataZh
