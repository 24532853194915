import { makeStyles } from '@material-ui/core'
import { FormControlLabel, FormGroup } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import isoCountries from 'i18n-iso-countries'
import jwt_decode from 'jwt-decode'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux'
import { countriesList } from '../../components/CustomInputs/CountriesList'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  createEmployee,
  getEmployeeNotificationDetailsByEmployeeId,
  updateEmployee,
  viewEmployeeById
} from '../../slices/Employee/EmployeeSlice'
import { selectCompany } from '../../slices/onboard/LoginSlice'
import { getAddressTypes } from '../../slices/Site/AddressTypeSlice'
import { getSubscription, getSubscriptionById } from '../../slices/SubscriptionCustomerSlice'
import {
  listofRoles
} from '../../slices/UserRole/UserRoleSlice'
import CommonUtil from '../../Util/CommonUtils'
import SelfRegistrationCustomPhone from '../OnBoardV2/SelfRegistrationCustomPhone'
import { gender, timeZoneData } from './EmployeeAddressFormData'
import { ThemeContext } from '../../theme/ThemeContext';
import ProfileUplod from './UploadImg'
const useStyles = makeStyles((theme) => ({
  dialogbox: {
    '& .css-hlj6pa-MuiDialogActions-root': {
      marginLeft: '290px !important',
    },
    '& .MuiDialog-paper': {
      minWidth: '110vh',
      backgroundColor: (props) => (props.darkMode ? '#0F273B' : '#fff'),
    },
  },
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },
}))

export default function CreateEmployee(props) {
  const { darkMode } = useContext(ThemeContext);
  const classes = useStyles({ darkMode });
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [open, setOpen] = React.useState(props.open)
  const [addressTypes, setAddressTypes] = React.useState([])
  const [personAddress, setPersonAddress] = React.useState([])
  const [countries, setCountries] = React.useState([])
  const [Gender, setGender] = React.useState([])
  const [timeZones, setTimeZones] = React.useState([])
  const [editMode, setEditMode] = useState(true)
  const [profilePhoto, setProfilePhoto] = useState('')
  const { handleClose } = props
  const [profileImage, setProfileImage] = React.useState()
  const [profile, setProfile] = React.useState()
  const [countryCode, setCountryCode] = React.useState('')
  const [phoneInput, setPhoneInput] = useState('')
  const [roleType, setRoleType] = useState([])
  const [isEmailTrue, setIsEmailTrue] = React.useState(true)
  const [isPhoneTrue, setIsPhoneTrue] = React.useState(false)
  const [selectedRoleName, setSelectedRoleName] = useState('')
  const [userRoleType, setUserRoleType] = useState(null)
  const [selectedRole, setSelectedRole] = useState(null)
  const [selectedValue, setSelectedValue] = useState(null)
  const [str, setStr] = useState('')
  const [openPhone, setOpenPhone] = useState(false)
  const [country, setCountry] = useState(props.country)
  const [selectedGender, setSelectedGender] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [personCode, setPersonCode] = useState(false)
  const [personRole, setPersonRole] = useState(null)
  const [isDrivingLicenseEnabled, setIsDrivingLicenseEnabled] = React.useState(false)
  const [isDrivingLicenseEnabledForAdmin, setIsDrivingLicenseEnabledForAdmin] = React.useState(false)
  const [customerSubscriptionFeatures, setCustomerSubscriptionFeatures] = useState('')
  const [customerSubscriptionFeaturesForAdmin, setCustomerSubscriptionFeaturesForAdmin] = useState('')

  const [subscriptionId, setSubscriptionId] = React.useState('')
  const [employeePayload, setEmployeePayload] = React.useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phone: '',
    code: '',
    gender: '',
    profilePhoto: '',
    dateOfBirth: '',
    departmentIdOrCode: '',
    designationIdOrCode: '',
    siteId: '',
    status: 'INACTIVE',
    type: 'PERMANENT',
    personType: '',
    joinDate: '19-06-2020',
    createUser: true,
    isPhoneSms: false,
    drivingLicenseEnabled: false,
    drivingLicenseEnabledForAdmin: false,
    employeeProfileData: ''

  })

  const [error, setError] = React.useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    gender: false,
    code: false,
    userId: false,
    profilePhoto: false,
    addressLine1: false,
    addressLine2: false,
    addressLine3: false,
    country: false,
    state: false,
    city: false,
    areaCode: false,
    timeZone: false,
    latitude: false,
    longitude: false,
    radius: false,
    addressTypeId: false,
    dateOfBirth: false,
    joinDate: false,
    countryCode: false,
  })

  const [addressPayload, setAddressPayload] = React.useState({
    addressId: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    areaCode: '',
    userId: '',
    latitude: '',
    longitute: '',
    radius: '',
    timeZone: '',
    dayLightSaving: true,
    addressTypeId: '',
    employeeId: '',
    siteId: '',
    deafult: true,
  })

  const [role, setRole] = useState({
    name: '',
    roleScope: 'APPLICATION',
  })

  const query = {
    page: 1,
    rowsPerPage: 10000,
    code: '',
    name: '',
  }
  //
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),

  )

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.data.profilePhotoURL)
    bodyFormData.append('file', profileImage)
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        // Toast("Profile Photo Saved", "success");
      })
      .catch(function (response) { })
  }

  const uploadUserProfilephoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.data.userProfileURL)
    bodyFormData.append('file', profileImage)
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        // Toast("Profile Photo Saved", "success");
      })
      .catch(function (response) { })
  }

  const updateProfilePhoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.data.profilePhotoURL)
    bodyFormData.append('file', profileImage)
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        // Toast("Profile Photo Saved", "success");
      })
      .catch(function (response) { })
  }

  useEffect(() => {
    dispatch(getAddressTypes(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI6000') setAddressTypes(data.data.addressTypes)
      })
    const payload = {
      companyCode: '',
      companyId: '',
    }
    dispatch(selectCompany(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0011') {
          const decoded = jwt_decode(data.data.access_token)
          localStorage.setItem('companyName', decoded.companyName)
        }
      })


    setCountries(countriesList)
    setTimeZones(timeZoneData)
    setGender(gender)
  }, [])
  const handleEmployeeChange = (event) => {
    const name = event.target.name
    setEmployeePayload({
      ...employeePayload,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handleGenderChange = (event, value) => {
    setSelectedGender(value)
    setEmployeePayload({
      ...employeePayload,
      gender: value.value,
    })
  }

  const handleCountryChange = (event, value) => {
    setSelectedCountry(value)
    if (value !== null) {
      setAddressPayload({
        ...addressPayload,
        country: value.id,
      })
    } else {
      setAddressPayload({
        ...addressPayload,
        country: '',
      })
    }

    setError({ country: false })
  }

  const handleEmployeeNameChange = (event) => {
    const name = event.target.name
    setEmployeePayload({
      ...employeePayload,
      [name]: event.target.value,
      profilePhoto: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handleAddressChange = (event) => {
    const name = event.target.name
    setAddressPayload({
      ...addressPayload,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value)
    setEmployeePayload({
      ...employeePayload,
      phoneCountryCode: '+' + data.dialCode,
      phone: value.toString().replace(data.dialCode, ''),
    })
    setError({ phone: false })
  }

  const handlePhoneSmsChange = (event) => {
    setEmployeePayload({
      ...employeePayload,
      isPhoneSms: event.target.checked,
    })
    setIsPhoneTrue(event.target.checked)
  }

  const handleDLChange = (event) => {
    setEmployeePayload({
      ...employeePayload,
      drivingLicenseEnabled: event.target.checked,
    })
    setIsDrivingLicenseEnabled(event.target.checked)

  }

  const handleDLChangeForAdmin = (event) => {
    setEmployeePayload({
      ...employeePayload,
      drivingLicenseEnabledForAdmin: event.target.checked,
    })
    setIsDrivingLicenseEnabledForAdmin(event.target.checked)

  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (CommonUtil.isEmptyString(employeePayload.firstName)) {
      Toast(t('USERPROFSCREEN007'), 'error')
      setError({ firstName: true })
      return
    }

    if (!CommonUtil.isValidNames(employeePayload.firstName)) {
      setError({ ...error, firstName: true })
      Toast(t('FIRSTNAME'), 'error')
      return
    }

    if (CommonUtil.isEmptyString(employeePayload.lastName)) {
      Toast(t('USERPROFSCREEN008'), 'error')
      setError({ lastName: true })
      return
    }

    if (!CommonUtil.isValidNames(employeePayload.lastName)) {
      Toast(t('LASTNAME'), 'error')
      setError({ lastName: true })
      return
    }

    if (CommonUtil.isEmptyString(employeePayload.email)) {
      Toast(t('EMAILVALIDATE'), 'error')
      setError({ email: true })
      return false
    }

    if (!CommonUtil.isValidEmail(employeePayload.email)) {
      setError({ ...error, email: true })
      Toast(t('CORRECTEMAIL'), 'error')
      return
    }

    if (!CommonUtil.isValidEmails(employeePayload.email)) {
      setError({ ...error, email: true })
      Toast(t('EMAILVALIDATE'), 'error')
      return false
    }

    if (CommonUtil.isEmptyString(employeePayload.code)) {
      Toast(t('PERSONINFO1'), 'error')
      setError({ code: true })
      return false
    }

    if (CommonUtil.isEmptyString(addressPayload.addressLine1)) {
      Toast(t('ATYPE0010'), 'error')
      setError({ addressLine1: true })
      return false
    }

    if (CommonUtil.isEmptyString(addressPayload.country)) {
      Toast(t('ATYPE0012'), 'error')
      setError({ ...error, country: true })
      return false
    }

    if (
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? ''
        : CommonUtil.isEmptyString(addressPayload.longitude)
    ) {
      Toast(t('ATYPE0014'), 'error')
      setError({ longitude: true })
      return false
    }

    if (
      process.env.REACT_APP_ENVIRONMENT === 'USA'
        ? ''
        : CommonUtil.isEmptyString(addressPayload.latitude)
    ) {
      Toast(t('ATYPE0015'), 'error')
      setError({ latitude: true })
      return false
    }

    if (CommonUtil.isEmpty(selectedValue)) {
      Toast(t('ATYPE0016'), 'error')
      return false
    }
    const newTemplate = {
      employeeDTOV4: {
        ...employeePayload,

        companyCode: localStorage.getItem('companyCode'),

        companyCode: localStorage.getItem('companyCode'),
        isPhoneSms: isPhoneTrue ? 'true' : 'false',
        drivingLicenseEnabled: isDrivingLicenseEnabled ? 'true' : 'false'
      },
      addressDTOV3: {
        ...addressPayload,
      },
      filterUserRoleDTO: {
        ...role,
      },
    }

    const updatetemplate = {
      employeeDTOV4: {
        ...employeePayload,
        companyCode: localStorage.getItem('companyCode'),
        createUser: false,
        status: props.selectedObject.status,
        isPhoneSms: isPhoneTrue ? 'true' : 'false',
        drivingLicenseEnabled: isDrivingLicenseEnabled ? 'true' : 'false'
      },
      addressDTOV3: {
        ...addressPayload,
      },
      filterUserRoleDTO: {
        ...role,
      },
    }
    //  alert(employeePayload.phone)
    if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      dispatch(createEmployee(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0020') {
            Toast(t('ATYPE0017'), 'success')
            if (!CommonUtil.isEmptyString(data.data.profilePhotoURL)) {
              uploadProfilePhoto(data)
              uploadUserProfilephoto(data)
            }
            setTimeout(() => {
              handleClose()
            }, 1000)
          } else {
            Toast(data.message, 'error')
          }
        })
    } else {
      dispatch(updateEmployee(updatetemplate))
        .unwrap()
        .then((data) => {
          if (data.code === 'CVAI0021' || data.code === 'OMSI0007') {
            Toast(t('ATYPE0018'), 'success')
            if (!CommonUtil.isEmpty(data.data.data.profilePhotoURL)) {
              updateProfilePhoto(data.data)
            }
            setTimeout(() => {
              handleClose()
            }, 1000)
          } else {
            Toast(data.message, 'error')
            handleClose()
          }
        })
    }
  }

  useEffect(() => {
    if (props.selectedObject.id) {
      dispatch(getEmployeeNotificationDetailsByEmployeeId(props.selectedObject.id))
        .unwrap()
        .then((res) => {
          if (res.code === 'MSGS4016') {
            setIsPhoneTrue(res.data.smsnotification)
            setIsDrivingLicenseEnabled(res.data.drivingLicenseEnabled)
            setIsDrivingLicenseEnabledForAdmin(res.data.drivingLicenseEnabledForAdmin)
          }
        })
    }
  }, [])

  useEffect(() => {
    if (props.selectedObject.id) {
      dispatch(viewEmployeeById(props.selectedObject.id))
        .unwrap()
        .then((data) => {
          setEmployeePayload({
            ...employeePayload,
            id: props.selectedObject.id,
            firstName: data.data.employeeDTOV4.firstName,
            lastName: data.data.employeeDTOV4.lastName,
            email: data.data.employeeDTOV4.email,
            phone: data.data.employeeDTOV4.phone,
            phoneCountryCode: data.data.employeeDTOV4.phone
              ? data.data.employeeDTOV4.phone.split('-')[0]
              : '',
            phone: data.data.employeeDTOV4.phone
              ? data.data.employeeDTOV4.phone.split('-')[1]
              : '',
            code: data.data.employeeDTOV4.code,
            gender: data.data.employeeDTOV4.gender,
            departmentIdOrCode: data.data.employeeDTOV4.departmentIdOrCode,
            designationIdOrCode: data.data.employeeDTOV4.designationIdOrCode,
            siteId: data.data.employeeDTOV4.siteId,
            profilePhotoURL: data.data.employeeDTOV4.profilePhotoURL,
            profilePhoto: data.data.employeeDTOV4.firstName,
            personType: data.data.employeeDTOV4.personType,
            drivingLicenseEnabled: data.data.employeeDTOV4.drivingLicenseEnabled,
            drivingLicenseEnabledForAdmin: data.data.employeeDTOV4.drivingLicenseEnabledForAdmin,
            employeeProfileData: data.data.employeeDTOV4.profilePhotoURL
          })
          setProfile(data.data.employeeDTOV4.profilePhotoURL)
          if (data.data.employeeDTOV4.phone !== null) {
            setPhoneInput(data.data.employeeDTOV4.phone)
          }
          if (data.data.address) {
            setAddressPayload({
              ...addressPayload,
              addressId: data.data.address.id,
              addressLine1: data.data.address.addressLine1,
              addressLine2: data.data.address.addressLine2,
              addressLine3: data.data.address.addressLine3,
              country: data.data.address.country,
              state: data.data.address.state,
              city: data.data.address.city,
              areaCode: data.data.address.areaCode,
              latitude: data.data.address.latitude,
              longitude: data.data.address.longitude,
              radius: data.data.address.radius,
              timeZone: data.data.address.timeZone,
              addressTypeId: data.data.address.addressTypeId,
            })
            const countryName = findCountryNameById(data.data.address.country)
            const countryCode = getCountryCodeFromName(countryName)
            const selectedCountry = countriesList.find(
              (country) => country.id === data.data.address.country,
            )
            setSelectedCountry(selectedCountry)
          }
          const selectedGender = gender.find(
            (gender) => gender.value === data.data.employeeDTOV4.gender,
          )
          setSelectedGender(selectedGender)
          if (data.data.employeeDTOV4.phone === null) {
            setCountry(countryCode)
          }
        })
    }
  }, [])

  const findCountryNameById = (id) => {
    const country = countriesList.find((country) => country.id === id)
    if (country) {
      return country.name
    }
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }



  const getCustomerSubscription = () => {
    dispatch(getSubscription(decoded_jwtToken.companyId))
      .unwrap()
      .then((data) => {

        dispatch(getSubscriptionById(data.subscriptionPurchaseId.productId.subscriptionId))
          .unwrap()
          .then((features) => {
            if (features.code === '200') {
              features.data.feature.map((item) => {
                if (item.name === 'Walk-In Registration through DL on Mobile Application') {
                  setCustomerSubscriptionFeatures(item.name)
                } else if (item.name === 'Walk-In Registration through DL & Passport Scanner on Web') {
                  setCustomerSubscriptionFeaturesForAdmin(item.name)
                }
              })
              console.log(customerSubscriptionFeatures)
            }

          })
      })
  }


  const handleProfile = (file) => {
    setEmployeePayload({
      ...employeePayload,
      profilePhoto: employeePayload.firstName,
      employeeProfileData: file.base64,
    })
    setProfile(file.base64)
    setProfileImage(file.file)
  }

  useEffect(() => {
    loadData(filter, paging)
    getCustomerSubscription()
  }, [])

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = React.useState({
    name: '',
    roleScope: 'APPLICATION',
  })

  const loadData = (filter, paging) => {
    dispatch(listofRoles(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0000') {
          setRoleType(data.data.roles)
        } else {
          setRoleType([])
        }
      })
  }

  const handleRoleSelection = (event, value) => {

    if (value) {
      setSelectedRoleName(value.name)
      setSelectedRole(value)
      setSelectedValue(value)
      setPersonRole(value.name)
    } else {
      setSelectedRoleName('')
      setSelectedRole()
      setPersonRole('')
    }
  }

  useEffect(() => {
    setRole({
      ...role,
      name: selectedRoleName,
      roleScope: 'APPLICATION',
    })
  }, [selectedRoleName])


  useEffect(() => {
    const defaultRole = roleType.find((role) => role.id === props.roleId)
    if (defaultRole?.id) {
      setSelectedValue(defaultRole)
      if (defaultRole.name === 'Front Desk' || defaultRole.name === 'Admin') {
        setPersonRole(defaultRole.name)
      }
    }
  }, [props.roleId, roleType])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <DialogBox
        className={classes.dialogbox}
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
           {employeePayload.id ? t('COMMON142') : t('COMMON141')}
          </span>
        }
        acceptText={t('COMMON007')}
        cancelText={t('COMMON008')}
        onSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
        cancel={handleClose}
        
        
      >
        <Grid container justifyContent="center" sx={{ marginTop: '20px', }}>
          <Grid
            xs={12}
            sm={4}
            container
            justifyContent="center"
            style={{ width: '100%' }}
            spacing={2}
            direction="column"
            alignItems="center"
            // style={{backgroundColor: "red"}}
          >
            <ProfileUplod
              onsetProfile={(profile) => handleProfile(profile)}
              profilePhoto={profile}
            
            />
          </Grid>

          <Grid sm={8} container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={'text'}
                error={error.firstName}
                name="firstName"
                label={t('USER0001')}
                value={employeePayload.firstName}
                handleChange={(e) => handleEmployeeNameChange(e)}
                helperText={error.firstName}
                required={true}
                inputProps={{ className: classes.input }}
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={'text'}
                error={error.lastName}
                name="lastName"
                label={t('USER0002')}
                value={employeePayload.lastName}
                handleChange={(e) => handleEmployeeChange(e)}
                helperText={error.lastName}
                required={true}
                inputProps={{ className: classes.input }}
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {props.selectedObject.id ? (
                <CustomTextfield
                  type={'text'}
                  error={error.email}
                  name="email"
                  label={t('COMMON042')}
                  value={employeePayload.email}
                  handleChange={(e) => handleEmployeeChange(e)}
                  helperText={error.email}
                  inputProps={{ className: classes.input }}
                  required={true}
                  disabled={true}
                />
              ) : (
                <CustomTextfield
                  type={'text'}
                  error={error.email}
                  name="email"
                  label={t('COMMON042')}
                  value={employeePayload.email}
                  handleChange={(e) => handleEmployeeChange(e)}
                  helperText={error.email}
                  inputProps={{ className: classes.input }}
                  required={true}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} style={{ margin: '16px 0px' }}>
              <SelfRegistrationCustomPhone
                placeholder={t('COMMON063')}
                value={phoneInput}
                country={country}
                specialLabel={false}
                inputProps={{ className: classes.input }}
                handleChange={(value, data, event, formattedValue) =>
                  handlePhoneChange(value, data, event, formattedValue)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={'text'}
                error={error.code}
                name="code"
                label={t('COMMON140')}
                value={employeePayload.code}
                handleChange={(e) => handleEmployeeChange(e)}
                helperText={error.code}
                inputProps={{ className: classes.input }}
                validation="alpha-numeric"
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ margin: '16px 0px' }}>
              <Autocomplete
                options={Gender}
                value={selectedGender}
                onChange={handleGenderChange}
                getOptionLabel={(option) => option.name}
                inputProps={{ className: classes.input }}
                renderInput={(params) => (
                  <TextField {...params} label={t('USERPROFSCREEN011')} 
                  InputLabelProps={{
                    style: {
                      color: darkMode ? '#fff' : '#0F273B',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                      },
                      '&:hover fieldset': {
                        borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                      },
                    },
                  }}
                  />
                )}
              />
            </Grid>

            {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
              <Grid item xs={12} sm={6}>
                <CustomTextfield
                  label={t('VISUPDATE0006')}
                  name={'Company Name'}
                  value={decoded_jwtToken.companyName}
                  disabled={editMode}
                  required={true}
                  handleChange={handleEmployeeChange}
                  inputProps={{ className: classes.input, maxLength: 20 }}
                />
              </Grid>
            ) : (
              ''
            )}

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                required
                error={error.addressLine1}
                name="addressLine1"
                label={t('ADD001')}
                helperText={error.addressLine1}
                value={addressPayload.addressLine1}
                handleChange={handleAddressChange}
                inputProps={{ className: classes.input, maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                error={error.addressLine2}
                name="addressLine2"
                label={t('ADD002')}
                helperText={error.addressLine2}
                value={addressPayload.addressLine2}
                handleChange={handleAddressChange}
                inputProps={{ className: classes.input, maxLength: 50 }}
              />
            </Grid>

            {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
              ''
            ) : (
              <Grid item xs={12} sm={6}>
                <CustomTextfield
                  error={error.addressLine3}
                  name="addressLine3"
                  label={t('ADD017')}
                  helperText={error.addressLine3}
                  value={addressPayload.addressLine3}
                  handleChange={handleAddressChange}
                  inputProps={{ className: classes.input, maxLength: 50 }}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                error={error.city}
                name="city"
                label={t('ADD005')}
                helperText={error.city}
                value={addressPayload.city}
                handleChange={handleAddressChange}
                inputProps={{ className: classes.input, maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextfield
                error={error.state}
                name="state"
                label={t('ADD004')}
                helperText={error.state}
                value={addressPayload.state}
                handleChange={handleAddressChange}
                inputProps={{ className: classes.input, maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: '16px' }}>
              <Autocomplete
                options={countries}
                value={selectedCountry}
                onChange={handleCountryChange}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error.country}
                    label={t('INVITATION006')}

                    InputLabelProps={{
                      style: {
                        color: darkMode ? '#fff' : '#0F273B',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                        },
                        '&:hover fieldset': {
                          borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextfield
                error={error.areaCode}
                name="areaCode"
                label={t('DEALERZIPCODE')}
                helperText={error.areaCode}
                value={addressPayload.areaCode}
                handleChange={handleAddressChange}
                inputProps={{ className: classes.input, maxLength: 10 }}
                validation="alphaNumericWithSpace"
              />
            </Grid>

            {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
              ''
            ) : (
              <Grid item xs={12} sm={6}>
                <CustomTextfield
                  error={error.radius}
                  name="radius"
                  label={t('ADD009')}
                  helperText={error.radius}
                  value={addressPayload.radius}
                  handleChange={handleAddressChange}
                  inputProps={{ className: classes.input, maxLength: 20 }}
                  validation="numeric"
                />
              </Grid>
            )}

            {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
              ''
            ) : (
              <Grid item xs={12} sm={6}>
                <CustomTextfield
                  error={error.longitude}
                  name="longitude"
                  label={t('ADD008')}
                  helperText={error.longitude}
                  required
                  value={addressPayload.longitude}
                  handleChange={handleAddressChange}
                  inputProps={{ className: classes.input, maxLength: 20 }}
                  validation="numeric"
                />
              </Grid>
            )}

            {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
              ''
            ) : (
              <Grid item xs={12} sm={6}>
                <CustomTextfield
                  required
                  error={error.latitude}
                  name="latitude"
                  label={t('ADD007')}
                  helperText={error.latitude}
                  value={addressPayload.latitude}
                  handleChange={handleAddressChange}
                  inputProps={{ className: classes.input, maxLength: 20 }}
                  validation="numeric"
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} sx={{ marginTop: '15px' }}>
              <Autocomplete
                options={roleType}
                getOptionLabel={(option) => option.name}
                value={selectedValue}
                onChange={handleRoleSelection}
                renderInput={(params) => (
                  <TextField {...params} label="Role *" 
                  InputLabelProps={{
                    style: {
                      color: darkMode ? '#fff' : '#0F273B',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                      },
                      '&:hover fieldset': {
                        borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                      },
                    },
                  }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{color: darkMode ? '#fff' : 'black'}}>
              <FormGroup row  >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPhoneTrue}
                      onChange={handlePhoneSmsChange}
                    />
                  }
                  label="SMS"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isEmailTrue}
                    // onChange={e => setIsEmailTrue(e.target.checked)}
                    />
                  }
                  label={t('Email')}
                />

                {(customerSubscriptionFeatures === 'Walk-In Registration through DL on Mobile Application') && (personRole === "Front Desk") &&
                  (<FormControlLabel
                    control={
                      <Checkbox
                        checked={isDrivingLicenseEnabled}
                        onChange={handleDLChange}
                      />
                    }
                    label="Access To  DL on Mobile Application"
                  />
                  )}

                {(customerSubscriptionFeaturesForAdmin === 'Walk-In Registration through DL & Passport Scanner on Web') && (personRole === "Admin") &&
                  (<FormControlLabel
                    control={
                      <Checkbox
                        checked={isDrivingLicenseEnabledForAdmin}
                        onChange={handleDLChangeForAdmin}
                      />
                    }
                    label="DL Access To  Web and Mobile"
                  />
                  )}

              </FormGroup>

            </Grid>
          </Grid>
        </Grid>
      </DialogBox>

    </>
  )
}
