import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";


const buildQuery = (filter) => {
    let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    if (!CommonUtil.isEmptyString(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    return query;
};

const getVisitorAccessLevels = (filter) => {
    return api.securedAxios().post('/web/accessLevel/List' + buildQuery(filter));
}

const getVisitorAccessLevelsByPurposeId = (payload) =>{
  
    return api.securedAxios().get('/web/getAccessLevelList/' + payload.id + "?pageNumber=" + payload.page + "&pageSize=" + payload.rowsPerPage );
}

// const buildQuery = (filter) => {
//     let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
//     if (!CommonUtil.isEmptyString(filter.name)) {
//         query = query + "&name=" + filter.name;
//     }
//     if (!CommonUtil.isEmptyString(filter.id)) {
//         query = query + "&id=" + filter.id;
//     }
//     return query;
// };

const createVisitorAccessLEvels = (payload) => {
    return api.securedAxios().post('/web/accessLevel', payload);
}

const updateVisitorAccessLEvels = (payload) => {
    return api.securedAxios().put('/web/accessLevel/' + payload.id, payload);
}

const deleteVisitorAccessLEvels = (payload) => {
    return api.securedAxios().delete('/web/accessLevel?id=' + payload.id + '&name=' + payload.name);
}
const listDoorsByAccessLevelId = (query) => {
    return api.securedAxios().post('/web/door/accessDoors/list' + buildQueryDoors(query));
}

const buildQueryDoors = (filter) => {
    let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    if (!CommonUtil.isEmptyString(filter.accessGroupid)) {
        query = query + "&accessGroupid=" + filter.accessGroupid;
    }
    if (!CommonUtil.isEmptyString(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    return query;
};

const deleteDoorsFromAccessLevel = (payload) => {
    return api.securedAxios().post('/web/door/' + payload.accessGroupId + '/doors/delete', payload.doorId);
}

const updateDoor = (payload) => {
    return api.securedAxios().put('/web/door/update/' + payload.doorId, payload.payload);
}

const updateDoorName = (payload) => {
    return api.securedAxios().put('/web/door/updateDoorName/' + payload.doorId, payload.payload);
}
const addDoorsFromAccessLevel = (payload) => {

}

export const VisitorAccessLevelService = {
    getVisitorAccessLevels, 
    createVisitorAccessLEvels,
    updateVisitorAccessLEvels,
    deleteVisitorAccessLEvels,
    listDoorsByAccessLevelId,
    deleteDoorsFromAccessLevel,
    addDoorsFromAccessLevel,
    updateDoor,
    updateDoorName,
    getVisitorAccessLevelsByPurposeId
}