import { Grid, makeStyles } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import CommonUtil from '../../Util/CommonUtils';
import CustomerDialog from '../../components/DialogBoxComponent/CustomerDialog';
import Toast from "../../components/ToastContainer/CustomToast";
import { getDealersAll, listOfDealerDetalisWithoutStatus } from '../../slices/Dealer/DealerSlice';
import { useTranslation } from 'react-i18next';
import BrowserLanguage from '../../components/Util/BrowserLanguage'


let language = BrowserLanguage.getDefaultLanguage()


const useStyles = makeStyles((theme) => ({
  dialogbox: {
    '& .MuiDialog-paper': {
      // minWidth: '110vh',
      minWidth: "600px",
      // marginLeft:"250px",
    },
    ".& .css-f3jnds-MuiDataGrid-columnHeaders": {
      backgroundColor: "#F3F9FB"
    },
  },
}))

function DealerExport(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setopen] = useState(props.open)
  const { handleClose } = props
  const [payload, setPayload] = useState({
    downloadExcel: "",
  });
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [error, setError] = useState({
    downloadExcel: false,
  });
  const statusValue = props.status.status;

  const nameValue = props.searchRecords || '';

  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  const isEmail = emailRegex.test(nameValue);

  const [filter, setFilter] = React.useState({
    status: statusValue,
    name: isEmail ? '' : nameValue,
    email: isEmail ? nameValue : ''
  });

  useEffect(() => {
    if (statusValue !== "") {
      loadData(filter);
    } else {
      loadDataWithoutStatus();
    }
  }, [filter])

  const loadData = (filter) => {
    dispatch(getDealersAll(Object.assign(filter)))
      .unwrap()
      .then((res) => {
        if ((res.code = "MSGS4007")) {
          setRows(res.data.companies);
        }
      })
      .catch((er) => { });
  };

  const loadDataWithoutStatus = () => {
    dispatch(listOfDealerDetalisWithoutStatus(''))
      .unwrap()
      .then((res) => {
        if (res.code === "MSGS4007") {
          setRows(res.data.companies);
        }

      })
  }


  const handleSubmit = () => {
    if (payload.downloadExcel === "") {
      setError({ downloadExcel: true });
      Toast(t('EXPORTERROR'), "error");
      return;
    }
    handleDownload();
    handleClose();
  };

  const handleDownload = async () => {
    const modifieData = customizeData(dealerExports);
    const ws = XLSX.utils.json_to_sheet(modifieData);
    const columnWidths = [
      { wch: 20 }, // CompanyName
      { wch: 20 }, // Email
      { wch: 20 }, // Phone
      { wch: 20 }, // status
      { wch: 20 }, // AdminName
    ];
    ws['!cols'] = columnWidths;
    const wb = { Sheets: { 'Dealer': ws }, SheetNames: ['Dealer'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
    const fileExtension = '.xlsx';
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'Dealer Records' + fileExtension);
    Toast(t('EXPORT_SUCCESS'), 'success');
  }
  let customizeData = null;
  if (language === 'en') {
    customizeData = (data) => {
      return data.map(item => ({
        'Company Name': item.name,
        Email: item.email,
        Mobile: item.phone,
        Status: item.status,
        'Admin Name': item.adminName

      }));
    };
  } else {
    customizeData = (data) => {
      return data.map(item => ({
        'nombre de empresa': item.name,
        'Correo electrónico': item.email,
        Móvil: item.phone,
        Estado: item.status,
        'Nombre del administrador': item.adminName
      }));
    };
  }

  const onselectDownload = (event) => {
    setPayload({
      ...payload,
      downloadExcel: event,
    });
  };

  const dealerExports = rows;

  const options = [
    { label: t('EXCEL_DROP_DOWN') }
  ]
  return (
    <>
      <CustomerDialog
        className={classes.dialogbox}
        Header={t('DOWNLOADHEADER')}
        acceptText={t('DOWNLOADHEADER')}
        cancelText={t('COMMON008')}
        onSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
        cancel={handleClose}
      >

        <Grid container
          style={{ padding: "20px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              value={payload.downloadExcel}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                  onselectDownload(newValue);
                } else {
                  onselectDownload('');
                }
                setError({ downloadExcel: false })
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label={t('SELECTEXCELFORMAT')} />}
            />
          </Grid>
        </Grid>

      </CustomerDialog>

    </>
  )
}

export default DealerExport