import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from '@material-ui/core'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { REACT_URL_DISCOUNTS } from '../../actions/EndPoints'
import React, { useEffect, useState } from 'react'
import CommonUtil from '../../Util/CommonUtils'
import Toast from '../../components/ToastContainer/CustomToast'
import {
  createDiscount,
  updateDiscount,
} from '../../slices/Discount/DiscountSlice'
import { TextField } from '@mui/material'
import TitleBar from '../../components/v4/TitleBar'

export default function CreateDiscount(props) {
  let history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [dicountValueNumeric, setDicountValueNumeric] = useState(false)
  const [dicountValueLength, setDicountValueLength] = useState(false)

  const handleNameFieldChange = (event) => {
    const name = event.target.name
    setDiscountPayload({
      ...discountPayload,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  useEffect(() => {
    if (props.location.state != null) {
      setIsEditable(true)
      setDiscountPayload({
        ...discountPayload,
        id: props.location.state.id,
        name: props.location.state.name,
        discountValue: props.location.state.discountValue,
      })
    }
  }, [])

  const handleDiscountPriceFieldChange = (event) => {
    const name = event.target.name
    if (!CommonUtil.isValidNumeric(event.target.value)) {
      setDicountValueNumeric(true)
    } else if (event.target.value > 99) {
      setDicountValueLength(true)
    } else {
      setDicountValueNumeric(false)
      setDicountValueLength(false)
    }
    setDiscountPayload({
      ...discountPayload,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handleCancel = () => {
    history.push({
      pathname: REACT_URL_DISCOUNTS,
    })
  }

  const [discountPayload, setDiscountPayload] = React.useState({
    id: '',
    name: '',
    discountValue: '',
    status: true
  })

  const [error, setError] = React.useState({
    name: '',
    discountValue: '',
  })
  const [isEditable, setIsEditable] = useState(false)

  const discountValueStr = discountPayload.discountValue.toString();
  const regex = /^\d+(\.\d{1,2})?$/;

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(discountPayload.name)) {
      Toast(t('DISCOUNTNAMEERROR'), 'error')
      setError({ name: true })
      return
    }
    if (discountPayload.name.length > 40) {
      Toast(t('ERROR_DISCOUNT_LENGHT'), 'error')
      setError({ name: true })
      return
    }

    if (CommonUtil.isEmptyString(discountPayload.discountValue)) {
      Toast(t('DISCOUNTPLANERROR'), 'error')
      setError({ discountValue: true })
      return
    }


    if (!regex.test(discountValueStr)) {
      Toast(t('DISCOUNT_DECIMAL_VALIDATION'), 'error');
      setError({ discountValue: true });
      return;
    }

    if (!dicountValueNumeric && !dicountValueLength) {
      if (props.location.state === undefined) {
        dispatch(createDiscount(discountPayload))
          .unwrap()
          .then((data) => {
            if (data.code === 'MSGS4022') {
              Toast(t('DISCOUNTSUCCESS'), 'success')
              history.push(REACT_URL_DISCOUNTS)
            } else Toast(data.message, 'error')
          })
          .catch((er) => { })
      } else {
        dispatch(updateDiscount(discountPayload))
          .unwrap()
          .then((data) => {
            if (data.code === 'MSGS4023') {
              Toast(t('DISCOUNTUPDATESUCCESS'), 'success')
              history.push(REACT_URL_DISCOUNTS)
            } else Toast(data.message, 'error')
          })
          .catch((er) => { })
      }
    }
  }

  return (
    <>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ m: 2, marginTop: -3 }}>
          <TitleBar
            title={isEditable ? t('EDITDISCOUNT') : t('CREATEDISCOUNT')}
            back_button={true}
          />
        </Box>
      </Grid>
      <Paper>
        <Grid container>
          <Grid container md={8} sm={12} spacing={3}>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              style={{
                marginTop: '50px',
                marginLeft: '40px',
              }}
            >
              <CustomTextfeild
                type={'text'}
                error={error.name}
                label={t('CUSTDATABLE26')}
                name={'name'}
                required={true}
                value={discountPayload.name}
                handleChange={(e) => handleNameFieldChange(e)}
                helperText={error.name}
                inputProps={{ maxLength: 99 }}
              />
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              style={{
                marginTop: '-30px',
                marginLeft: '40px',
              }}
            >
              <TextField
                style={{
                  marginTop: '20px',
                  width: '100%',
                }}
                type={'text'}
                error={dicountValueNumeric || dicountValueLength}
                label={t('DISCOUNT')}
                required={true}
                name={'discountValue'}
                value={discountPayload.discountValue}
                onChange={(e) => handleDiscountPriceFieldChange(e)}
                helperText={
                  dicountValueNumeric
                    ? t('ERROR_NUMERICS')
                    : '' || dicountValueLength
                      ? t('ERROR_LESS_THEN_PERCENTAGE')
                      : ''
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  maxLength: 2,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            md={8}
            sm={12}
            justifyContent="center"
            spacing={3}
            style={{
              marginTop: '30px',
              marginLeft: '-175px',
              marginBottom: '100px',
            }}
          >
            <Grid item>
              <CancelButtons
                onClick={handleCancel}
                color="secondary"
                variant="contained"
              >
                <Typography>{t('COMMON008')}</Typography>
              </CancelButtons>
            </Grid>
            <Grid item>
              <SubmitButtons onClick={handleSubmit} variant="contained">
                <Typography>{t('COMMON007')}</Typography>
              </SubmitButtons>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
