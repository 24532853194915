import React, { Fragment } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Box, IconButton } from "@material-ui/core";
import { REACT_URL_DASHBOARD } from "../../actions/EndPoints";
import projectLogo from "../../assets/images/ZKBio-cloud-visitor-white-Logo.svg";
import ZkBioCloudLogo from "../../assets/images/ZKBio-cloudVisitorLogo.svg";

const HeaderLogo = () => {
  const zlink = localStorage.getItem("redirectFrom");

  return (
    <Fragment>
      <span></span>
      <div >
        {!zlink ? (
          <>
            {/* <Link to={REACT_URL_DASHBOARD} className="header-logo-wrapper-link">
              <img
                className="header-logo"
                alt="Cloud Access"
                src={ZkBioCloudLogo}
              />
            </Link> */}
          </>
        ) : ( 
          <>
            {/* <Link to={REACT_URL_DASHBOARD} className="header-logo-wrapper-link">
              <img
                className="header-logo"
                alt="My smart guard"
                src={projectLogo}
              />
            </Link> */}
          </>
        )}
      </div>
    </Fragment>
  );
};

export default HeaderLogo;
