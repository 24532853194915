import { Box, Tooltip, Typography, Button, Dialog, IconButton, Grid, styled } from '@mui/material';
import React from 'react'
import { SetCheckInCheckOutDevice } from './SetCheckInCheckOutDevice'
import { ReactComponent as Information } from "../../assets/DeviceIcon/Information.svg";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { ReactComponent as Add } from "../../assets/USA_Images/device-management.svg";
import Search from '../../components/SearchTab/Search';
import { Link } from 'react-router-dom';
import DataTable from '../../components/DataTable/DataTable';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { REACT_DEVICE_ADD_INSTRUCTION, REACT_VIEW_DEVICE } from '../../actions/EndPoints';
import MoreOption from '../../components/v4/MoreOption';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { listOfDevice, rebootDevice } from '../../slices/Zlink Device Management/DeviceSlice';
import FilterDevice from './FilterDevice';
import checkOutSvg from "../../assets/images/dashboard-icons/Check-Out.svg";
import checkInSvg from "../../assets/images/dashboard-icons/Check-In.svg";
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Switch } from "@mui/material";
import { toast } from 'react-toastify';
import DeleteDevice from './DeleteDevice';
import {  useContext } from "react";
import { ThemeContext } from '../../theme/ThemeContext';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 38,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 11,
    padding: 0,
    transform: "translateX(5px)",
    backgroundColor: "#fff",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(15px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "#226BAB"
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#226BAB"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#f1f1f1",
    width: 15,
    height: 15,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }
  },

  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#fff",
    borderRadius: 30 / 2,
    border: "1px solid #cdcdcd"
  }
}));

const styleDialog = {
  '& .MuiDialog-paper': {
    padding: '12px 24px',
    position: 'absolute',
    top: '5em',
    minWidth: '60%',
  },
};
const defaultMenuData = [
  { label: 'View device details', index: 0 },
  // { label: 'Set check-in/check-out device', index: 1 },
  { label: 'Person in this device', index: 2 },
  { label: 'Sync data & time according to site', index: 3 },
  { label: 'Clean device admin password', index: 4 },
  { label: 'Reboot device', index: 5 },
  { label: 'Delete device', index: 6 },
];


export const AccessDevice = () => {
  const [open, setOpen] = React.useState(false);
  const [clear, setClear] = React.useState(false);
  const [openInformation, setOpenInformation] = React.useState(false);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [newPage1, setNewPage] = useState(1);
  const [updateData, setUpdateData] = useState({});
  const [reload, setReload] = useState(false);
  const [deleteOpen,setDeleteOpen]=useState(false);
  const [deleteData,setDeleteData]=useState(false);
  const { darkMode } = useContext(ThemeContext);
  const defaultFilter = {
    deviceAlias: '',
    deviceSn: '',
    deviceType: ''
  };
  const [filter, setFilter] = React.useState({
    ...defaultFilter
  });
  const handleChange = (newPage, size) => {
    setNewPage(newPage);
    setPage(newPage);
    setRowsPerPage(size);
    loadData(newPage, size);
  };
  useEffect(() => {
    loadData(page, rowsPerPage);
  }, [filter]);

  useEffect(() => {
    if (reload === true)
      loadData(page, rowsPerPage);
  }, [reload]);

  const loadData = (page, rowsPerPage) => {
    const payload = {
      deviceAlias: filter.deviceAlias,
      deviceSn: filter.deviceSn,
      deviceType: filter.deviceType,
      pageNumber: page,
      pageSize: rowsPerPage
    };
    // setSelectedObject({});
    dispatch(listOfDevice(payload))
      .unwrap()
      .then((data) => {
        if ((data.code = "DMSI0000" && data.data)) {
          setTotalRecords(data.data.totalCount);
          setRows(data.data.devices);
          setPage(data.data.currentPage);
          setTotalPages(data.data.totalPages);
          setReload(false);
          setOpen(false);
        } else {
          setRows([]);
          setTotalRecords(0);
          setPage(newPage1);
          setTotalPages(0);
        }
      })
      .catch((er) => { });
  };

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (test.deviceName !== undefined ? test.deviceName.toLowerCase().includes(searchedVal.toLowerCase()) :
        test.deviceName ? test.deviceName.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || (test.sn ? test.sn.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || (test.siteName ? test.siteName.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || (test.zoneName ? test.zoneName.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || (test.deviceModel ? test.deviceModel.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || (test.deviceProperty ? test.status.toLowerCase().includes(searchedVal.toLowerCase()) : "")
    });
    setRows(filteredRows);
  };

  const columns = [
    { headerName: "", sortable: false, width: 5, renderCell: (params) => { return <div></div>; } },
    { field: 'deviceName', headerName: t('VISACCDEV002'), flex: 1, width: 10 },
    { field: 'sn', headerName: "SN", flex: 1, width: 10 },
    { field: 'siteName', headerName: t("COMMON144"), flex: 1, width: 10, },
    { field: 'zoneName', headerName: t('COMMON143'), flex: 1, width: 10, },
    { field: 'deviceModel', headerName: t('Model'), flex: 1, width: 10 },
    {
      field: 'status', headerName: t('commonsTextFieldLabelStatus'), flex: 1, width: 10,
      renderCell: (e) => (
        <span style={{ color: e.row.status === '1' ? '#36C96D' : '#f56a5f' }}>
          {e.row.status === '1' ? 'Online' : 'Offline'}
        </span>)
    },
    // {
    //   field: 'enable/disable', headerName: t("Enable/Disable"), flex: 1, width: 10,
    //   renderCell: (e) => (
    //     <Grid style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', paddingRight: '15px', alignItems: 'center', justifyContent: 'center' }}>
    //       <GridActionsCellItem
    //         icon={<Tooltip>
    //           <MaterialUISwitch defaultChecked />
    //         </Tooltip>}
    //       />
    //     </Grid>)
    // },

    // { field: 'status', headerName: 'status', flex: 1, width: 10, renderCell: (e) => (<span style={{ color: e.row.status === '0' ? '#4caf50' : '#f44336' }}><b>{e.row.status === '0' ? 'Online' : 'Offline'}</b></span>) },
    {
      field: "Actions", headerName: t("ACTION"), type: "actions", flex: 1, minWidth: 180, width: 150,
      renderCell: (e) => (
        <Grid style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <MoreOption data={defaultMenuData} handleSelect={(option) => handleActions(option, e.row)} />
        </Grid>)
    },
  ];
  const handleActions = (action, data) => {
    if (action === 0) {
      history.push({ pathname: REACT_VIEW_DEVICE, state: { ...data } });
    } 
    // else if (action === 1) {
    //   setOpen(!open);
    //   setUpdateData(data);

    // } 
    else if (action === 5) {
      handleRebootDevice(data);
    }
    else if (action === 6) {
      setDeleteData(data);
      setDeleteOpen(true);
    }
  };

  const handleRebootDevice = (data) => {
    const payload = {
      deviceId: data.id,
      operator: {
        operator: 'reboot'
      }
    }
    dispatch(rebootDevice(payload)).unwrap()
      .then((data) => {
        if (data.code === "DMSI0000") {
          toast.success(data.msg);
        }
        else {
          toast.error(data.msg);
        }
      })
  }
  const resetFilter = () => {
    setClear(false);
    setPage(newPage1);
    setFilter({
      ...defaultFilter,
    });
  };
  const handleFilter = (data) => {
    setClear(true);
    setPage(1);
    setFilter({
      deviceAlias: data.deviceAlias,
      deviceSn: data.deviceSn,
      deviceType: data.deviceType
    });
  };

  const clearSearch = () => {
    setFilter({
      ...defaultFilter,
    });
    loadData(page, rowsPerPage)
  };
  const handleClose = (data) => {
    setOpen(false);
    setDeleteOpen(false)
  };

  const reloadlist = (value) => {
    setReload(true);
  };

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px" }}>
        <Box>
        </Box>
        <Box style={{ display: "flex", position: "relative", left: "15px" }}>
          <Box style={{ textAlign: "-webkit-right", padding: "5px", margin: "5px" }}>
            <Tooltip title={t("Device Information")}>
              <IconButton onClick={() => setOpenInformation(!openInformation)}>
                <Information />
              </IconButton>
            </Tooltip>
          </Box>
          <Box style={{ textAlign: "-webkit-right", padding: "5px", margin: "5px", }}>
            <Tooltip title={t("Refresh Device")}>
              <IconButton onClick={() => loadData(1, rowsPerPage)}>
                <Refresh />
              </IconButton>
            </Tooltip>
          </Box>
          <Box style={{ textAlign: "-webkit-right", padding: "5px", margin: "5px" }}>
            <Tooltip title={t("Add Device")}>
              <IconButton variant='contained' onClick={() => history.push(REACT_DEVICE_ADD_INSTRUCTION)}>
                <Add />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        marginTop={'20px'}
      >
        <Box item p={1}>
          {clear && (
            <Link href="#" underline="hover" className="line"
              style={{ opacity: "1", color: "#E3393C", fontSize: "14px", underline: "hover", display: "inline", }}
              onClick={() => resetFilter()}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>
        <Box item p={1}>
          <FilterDevice
            onSubmit={(data) => { handleFilter(data) }}
          />
        </Box>
        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => clearSearch()}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: "100%", }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      <Dialog sx={styleDialog} onClose={() => setOpenInformation(!openInformation)} open={openInformation}>
        <IconButton onClick={() => setOpenInformation(!openInformation)} style={{ alignSelf: 'end' }}>
          <Close />
        </IconButton>
        <Typography variant='h5' component='div' gutterBottom style={{ color: darkMode ? '#fff' : '#474A4E', fontWeight: 'bold' }}>
          {t("Device Center Info")}
        </Typography>
        <Typography variant='subtitle1' gutterBottom component='div'>
          {t("DEVICEINFOO")} 
         {t("DEVICEINFOOO")}
        </Typography>
        <Typography variant='subtitle1' gutterBottom component='div'>
          {t("DEVICEINFOO1")}
          {t("DEVICEINFOO2")}
        </Typography>
      </Dialog>
      {/* {open && <SetCheckInCheckOutDevice open={open} onClose={handleClose} updateData={updateData} reloadlist={reloadlist} />} */}
      {deleteOpen && <DeleteDevice 
      open={deleteOpen} 
      onClose={handleClose} 
      deleteData={deleteData} 
      reloadlist={reloadlist} />}
    </>
  )
}
