import api from '../../config/http-common'

const getAllVisitorDocuments = (payload) => {
  return api
    .securedAxios()
    .get('/web/api/v4/visitors/' + payload.rowData.id + '/documents')
}

const uploadVisitorAsset = (payload) => {
return api.unsecuredAxios().post('/web/api/v4/visitor/document' , payload);
}

const deteleAssetImage = (payload) => {
  return api.unsecuredAxios().post('/web/api/v4/visitor/documents' , payload)
}

const visitorDocumentService = {
  getAllVisitorDocuments,
  uploadVisitorAsset,
  deteleAssetImage
}

export default visitorDocumentService
