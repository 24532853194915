import { Grid, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'

const useStyles = makeStyles((theme) => ({
  dialogbox: {
    '& .MuiDialog-paper': {
      // minWidth: '110vh',
      minWidth: '600px',
      // marginLeft:"250px",
    },
    '.& .css-f3jnds-MuiDataGrid-columnHeaders': {
      backgroundColor: '#F3F9FB',
    },
  },
}))

function TransactionReportFilter(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(props.open)
  const { handleClose } = props

  const [payload, setPayload] = useState({})

  const handleSubmit = () => {
    handleClose()
  }

  const handleReset = () => {
    handleClose()
  }

  return (
    <>
      <DialogBox
        Header={'Filter'}
        acceptText={'Apply'}
        cancelText={'Reset'}
        style={{ color: '#3D4977', opacity: 1, minHeight: '' }}
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleReset}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: '20px', width: '100%' }}
          spacing={3}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomDateTimePicker
              onChange={(scheduledStartDate) =>
                setPayload({
                  ...payload,
                  scheduledStartDate: scheduledStartDate,
                })
              }
              value={payload.scheduledStartDate}
              label={'Payment Date'}
              date={'dd-MM-yyyy HH:mm'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomDateTimePicker
              onChange={(scheduledStartDate) =>
                setPayload({
                  ...payload,
                  scheduledStartDate: scheduledStartDate,
                })
              }
              value={payload.scheduledStartDate}
              label={'Billing Date'}
              date={'dd-MM-yyyy HH:mm'}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}

export default TransactionReportFilter
