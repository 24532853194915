import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import MobileKeyConfigurationService from '../../services/MobileKeyConfiguration/MobileKeyConfigurationService'

const initialState = []

export const createMobileKeyConfigurations = createAsyncThunk(
  'mobileKeyConfigurations/',
  async (payload) => {
    const res = await MobileKeyConfigurationService.createMobileKeyConfigurations(
      payload,
    )
    return res.data
  },
)

export const getMobileConfigurations = createAsyncThunk(
  'mobileKeyConfigurations/',
  async (payload) => {
    const res = await MobileKeyConfigurationService.getMobileConfigurations(
      payload,
    )
    return res.data
  },
)

export const deleteMobileConfiguration = createAsyncThunk(
  'mobileKeyConfigurations/delete',
  async (customerId) => {
    const res = await MobileKeyConfigurationService.deleteMobileConfiguration(
      customerId,
    )
    return res.data
  },
)

const MobileKeyConfigurationSlice = createSlice({
  name: 'mobileKeyConfiguration',
  initialState,
  extraReducers: {},
})

const { reducer } = MobileKeyConfigurationSlice
export default reducer
