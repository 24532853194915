import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const UnblockIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
    <Tooltip title={t("UNBLOCKVIS002")}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.43"
              height="16.786"
              viewBox="0 0 13.43 16.786"
            >
              <path
                id="Path_6840"
                data-name="Path 6840"
                d="M10.715,11.233a1.251,1.251,0,0,0-.839,2.191V14.59a.839.839,0,0,0,1.679,0V13.424a1.251,1.251,0,0,0-.839-2.191Zm4.2-3.357H8.2V6.2a2.518,2.518,0,0,1,4.3-1.788,2.585,2.585,0,0,1,.655,1.158.841.841,0,1,0,1.628-.42,4.272,4.272,0,0,0-1.1-1.922A4.2,4.2,0,0,0,6.518,6.2V7.876A2.518,2.518,0,0,0,4,10.394v5.875a2.518,2.518,0,0,0,2.518,2.518h8.394a2.518,2.518,0,0,0,2.518-2.518V10.394A2.518,2.518,0,0,0,14.912,7.876Zm.839,8.394a.839.839,0,0,1-.839.839H6.518a.839.839,0,0,1-.839-.839V10.394a.839.839,0,0,1,.839-.839h8.394a.839.839,0,0,1,.839.839Z"
                transform="translate(-4 -2.001)"
                fill="#3d4977"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.43"
              height="16.786"
              viewBox="0 0 13.43 16.786"
            >
              <path
                id="Path_6840"
                data-name="Path 6840"
                d="M10.715,11.233a1.251,1.251,0,0,0-.839,2.191V14.59a.839.839,0,0,0,1.679,0V13.424a1.251,1.251,0,0,0-.839-2.191Zm4.2-3.357H8.2V6.2a2.518,2.518,0,0,1,4.3-1.788,2.585,2.585,0,0,1,.655,1.158.841.841,0,1,0,1.628-.42,4.272,4.272,0,0,0-1.1-1.922A4.2,4.2,0,0,0,6.518,6.2V7.876A2.518,2.518,0,0,0,4,10.394v5.875a2.518,2.518,0,0,0,2.518,2.518h8.394a2.518,2.518,0,0,0,2.518-2.518V10.394A2.518,2.518,0,0,0,14.912,7.876Zm.839,8.394a.839.839,0,0,1-.839.839H6.518a.839.839,0,0,1-.839-.839V10.394a.839.839,0,0,1,.839-.839h8.394a.839.839,0,0,1,.839.839Z"
                transform="translate(-4 -2.001)"
                fill="#c0c7cc"
              />
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default UnblockIcon;
