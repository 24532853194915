import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BlockVisitorService from "../../services/BlockVisitor/BlockVisitorService";

const initialState = [];

export const getRestrictTypeListV2 = createAsyncThunk(
  "retsrictType/retrieve",
  async (payload) => {
    const res = await BlockVisitorService.get(payload);
    return res.data;
  }
);

export const restrictVisitorV2 = createAsyncThunk(
  "blockedVisitor/retrieve",
  async (payload) => {
    const res = await BlockVisitorService.create(payload);
    return res.data;
  }
);

export const approveBlockedVisitor = createAsyncThunk(
  "approveVisitors/retrieve",
  async (payload) => {
    const res = await BlockVisitorService.approve(payload);
    return res.data;
  }
);

export const getApproveBlockVisitor = createAsyncThunk("getApprove/retrieve", async (payload) => {
  const res = await BlockVisitorService.approveGet(payload);
  return res.data;
})

export const unblockVisitor = createAsyncThunk(
  "unblockVisitors/retrieve",
  async (payload) => {
    const res = await BlockVisitorService.unblock(payload);
    return res.data;
  }
);

export const getRestrictedVisitorsFilterList = createAsyncThunk(
  "restrictVisitorsFilter/retrieve",
  async (payload) => {
    const res = await BlockVisitorService.getAll(payload);
    return res.data;
  }
);

const blockedVsiitorSlice = createSlice({
  name: "blockedVisitor",
  ...initialState,
  extraReducers: {
    [getRestrictTypeListV2.fulfilled]: (state, action) => {
      if (action.payload.code === "") {
        return action.payload.data;
      } else {
        return [];
      }
    },
    [restrictVisitorV2.fulfilled]: (state, action) => {
      if (action.payload.code === "") {
        return action.payload.data;
      } else {
        return [];
      }
    },

    [getRestrictedVisitorsFilterList.fulfilled]: (state, action) => {
        if (action.payload.code === '') {
            return action.payload.data;
        } else {
            return [];
        }
    },

    [unblockVisitor.fulfilled]: (state, action) => {
        if (action.payload.code === '') {
            return action.payload.data;
        } else {
            return [];
        }
    },

    [approveBlockedVisitor.fulfilled]: (state, action) => {
        if (action.payload.code === '') {
            return action.payload.data;
        } else {
            return [];
        }
    },


  },
});
const { reducer } = blockedVsiitorSlice;

export default reducer;
