import axios from 'axios'
import { REACT_LOGIN } from '../actions/EndPoints'
import { startLoader, stopLoader } from '../slices/Loader/LoaderSlice'
import store from './configureStore'

const axiosHelper = (headers) => {
  let req = axios.create({
    headers: headers,
  })
  req.interceptors.request.use((request) => {
    store.dispatch(startLoader())
    return request
  })
  req.interceptors.response.use(
    (response) => {
      store.dispatch(stopLoader())
      return response
    },
    (error) => {
      store.dispatch(stopLoader())
      if (
        401 === error.response.status &&
        window.location.pathname !== REACT_LOGIN
      ) {
        window.location = REACT_LOGIN
      }
    },
  )
  return req
}

const securedAxios = () => {
  return axiosHelper({
    'Content-type': 'application/json',
    Authorization: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
    Application: 'mysmartguardplus',
    companyId: localStorage.getItem('companyId'),
    'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'Accept-language': localStorage.getItem('i18nextLng'),
  })
}

const switchCompanyAxios = () => {
  return axiosHelper({
    'Content-type': 'application/json',
    Authorization: localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN),
    Application: 'mysmartguardplus',
    'Accept-language': localStorage.getItem('i18nextLng'),
  })
}

const unsecuredAxios = () => {
  return axiosHelper({
    'Content-type': 'application/json',
    'Accept-language': localStorage.getItem('i18nextLng'),
    Application: 'mysmartguardplus',
    companyId: localStorage.getItem('companyId'),
    'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  })
}

const unsecuredMultipartAxios = () => {
  return axiosHelper({
    'Content-type': 'multipart/form-data',
    'Accept-language': localStorage.getItem('i18nextLng'),
    Application: 'mysmartguardplus',
    'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  })
}

const securedAxiosForExport = (url) => {
  let req = axios.create({
    method: 'GET',
    url: url,
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
      companyId: localStorage.getItem('companyId'),
      Application: 'mysmartguardplus',
      'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'Accept-language': localStorage.getItem('i18nextLng'),
    },
    responseType: 'blob',
  })
  req.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (
        401 === error.response.status &&
        window.location.pathname !== REACT_LOGIN
      ) {
        window.location = REACT_LOGIN
      }
    },
  )
  return req
}

const api = {
  axiosHelper,
  securedAxios,
  unsecuredAxios,
  unsecuredMultipartAxios,
  switchCompanyAxios,
  securedAxiosForExport,
}

export default api
