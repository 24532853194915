import { colors, makeStyles } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import axios from 'axios'
import isoCountries from 'i18n-iso-countries'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from 'react-redux'
import cameraIcon from '../../assets/cameraIcon.svg'
import cameraDark from '../../assets/USA_Images/login_images_visitor/cameraDark.svg'
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import { WebcamCapture } from '../../components/CustomInputs/WebCam'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import ProfileContext from '../../components/ProfileContext'
import Toast from '../../components/ToastContainer/CustomToast'
import httpCommon from '../../config/http-common'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { listHost } from '../../slices/HostSlice'
import { createInvitation } from '../../slices/Invitations/InvitationsSlice'
import { getPurposeType } from '../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice'
import { getAllSites } from '../../slices/Site/SiteSlice'
import { getUserInfoPro } from '../../slices/User/UserSlice1'
import { getAllVisitorType } from '../../slices/VisitorType/VisitorTypeSlice'
import CommonUtil from '../../Util/CommonUtils'
import CustomPhones from '../OnBoardV2/CustomPhones'
import './phoneInput.css'
import UploadDocs from './UploadDocs'

import CircularProgress from '@mui/material/CircularProgress'
import b64toBlob from 'b64-to-blob'
import { useDymoCheckService, useDymoFetchPrinters } from 'react-dymo-hooks'
import errorIcon from '../../assets/USA_Images/offenderError.svg'
import successIcon from '../../assets/USA_Images/offenderSuccess.svg'
import VisitorAssetsDialog from '../../components/DialogBoxComponent/VisitorAssetsDialog'
import { getCompanyInfo } from '../../slices/Company/CompanySlice'
import {
  deteleAssetImage,
  uploadVisitorAsset,
} from '../../slices/VisitorDocuments/VisitorDocumentSlice'
import { ThemeContext } from '../../theme/ThemeContext'
const Dymo = require('dymojs'),
  dymo = new Dymo()

const useStyles = makeStyles((theme) => ({
  dialogbox: {
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '0px !important',
    },
  },
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  },

  
}))

export default function CreateNewRegistration(props) {
  const { t } = useTranslation()
  const { open } = props
  const dispatch = useDispatch()
  const [testImage, setTestImage] = useState('')
  const [siteData, setSiteData] = useState([])
  const [data, setData] = useState()
  const [hostList, setHostList] = useState([])
  const [selectedHost, setSelectHost] = useState({})
  const classes = useStyles()
  const [InavalidprofilePhoto, setInavalidprofilePhoto] = useState(false)
  const [visitorData, setVisitorData] = useState([])
  const [purposeData, setPurposeData] = useState([])
  const [phoneInput, setPhoneInput] = useState('')
  const [profileImage, setProfileImage] = useState()
  var minutes =
    props.walkInIntervalTime === '' ? 30 : Number(props.walkInIntervalTime)
  const [walkInIntervalTime, setWalkInIntervalTime] = useState(minutes)
  const [printerImage, setPrinterImage] = useState('')
  const [str, setStr] = useState('')
  const [documentScanMode, setDocumentScanMode] = useState('')
  const [dlImage, setdlImage] = useState()
  const [changeDate, setDateChange] = useState(true)
  const [userPhone, setUserPhone] = useState('')
  const { setCurrentTotalVisitCount } = useContext(ProfileContext)
  const [dlScannerPermission, setDlScannerPermission] = useState(
    props.dlScannerPermission,
  )
  const [featureList, setFeatureList] = useState([])
  const [webCam, setWebCam] = useState(false)
  const [asssetImageIcon, setAssetImageIcon] = useState(props.walkImageCapture)
  const [assetuploadData, setAssetUploadData] = useState({})
  const [assetsImages, setAssetsImages] = useState([])
  const [country, setCountry] = useState('')
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [objectKeys, setObjectKeys] = useState([])
  const [walkInImageCapture, setWalkInImageCapture] = useState(false)
  const [autoContactInfo, setAutoContactIfo] = useState(false)
  const [loading, setLoading] = useState(false)
  const [offenderFeature, setOffenderFeature] = useState(props.offender)
  const { darkMode } = useContext(ThemeContext);

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )
  var today = new Date()

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T' +
    (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())

  const [payload, setPayload] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    phoneCountryCode: '',
    purposeTypeId: '',
    visitorTypeId: '',
    hostId: '',
    visitorCompanyName: '',
    siteId: '',
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(
      moment(startDate).add(walkInIntervalTime, 'minutes'),
    ),
    visitMode: 'WALKIN_REGISTARTION',
    profilePhoto: '',
    documentNumber: '',
    documentExpireDate: '',
    documentFrontImage: '',
    documentRearImage: '',
    verifyTypeIn: '',
    walkInScan: '',
    invitationScan: '',
    selfScan: '',
    address: '',
    remarks: '',
    approvalReason: '',
    printerImage: '',
    documentIssuedDate: '',
    documentScanMode: documentScanMode,
    dateOfBirth: '',
  })

  const [offenderPayload, setOffenderpayload] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    city: '',
    state: '',
    zipcode: '',
    lat: 0,
    lng: 0,
    radius: 0,
  })

  const [visitorAssetPayload, setVisitorAssetPayload] = useState({
    fileName: '',
    scope: '',
    applicationName: '',
    companyId: localStorage.getItem('companyId'),
  })

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    emailId: false,
    phoneNumber: false,
    purposeTypeId: false,
    visitorTypeId: false,
    hostId: false,
    visitorCompanyName: false,
    siteId: false,
    profilePhoto: false,
    documentNumber: false,
    documentExpireDate: false,
    approvalReason: false,
    remarks: false,
  })

  const statusDymoService = useDymoCheckService()

  const { statusFetchPrinters, printers } = useDymoFetchPrinters(
    statusDymoService,
  )

  const print = (data) => {
    if (printers[0].isConnected === 'True') {
      dymo.print(printers[0].name, data)
    }
  }

  const onError = (data) => {
    if (data)
      switch (data.code) {
        case 'CVAE1015':
          setError({ emailId: true })
          break
        case 'CVAE1016':
          setError({ emailId: true })
          break
        case 'CVAE1012':
          setError({ firstName: true })
          break
        case 'CVAE1013':
          setError({ firstName: true })
          break
        case 'CVAE1017':
          setError({ phoneNumber: true })
          break
        case 'CVAE1050':
          setError({ visitorTypeId: true })
          break
        case 'CVAE1041':
          setError({ purposeTypeId: true })
          break
        case 'CVAE1051':
          setError({ visitorCompanyName: true })
          break
        case 'CVAE1019':
          setError({ hostId: true })
          break
        case 'CVAE1021':
          setError({ scheduledStartDate: true })
          break
        case 'CVAE1022':
          setError({ scheduledEndDate: true })
          break
        case 'CVAE1023':
          setError({ scheduledEndDate: true })
          break
        case 'CVAE0300':
          setError({ siteId: true })
          break
        default:
          setError({ ...error })
      }
    Toast(data.message, 'error')
  }

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', data.data.profilePhotoURL)
    if (documentScanMode) {
      bodyFormData.append('file', dlImage)
    } else {
      bodyFormData.append('file', profileImage)
    }
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) { })
      .catch(function (response) { })
  }

  const uploadAssetImages = (data) => {
    var bodyFormData = new FormData()
    bodyFormData.append('url', assetuploadData.preSignedURL)
    bodyFormData.append('file', data)

    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) { })
      .catch(function (response) { })
  }

  const handleClose = (data) => {
    clearData()
    props.handleDialogClose()
    if (data.type) {
      handleDeleteAssets()
    }
  }

  const handleDeleteAssets = () => {
    let payload = {
      fileName: '',
      scope: '',
      applicationName: '',
      companyId: '',
      objectKeys: objectKeys,
    }
    dispatch(deteleAssetImage(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4043') {
        } else {
          Toast(res.message, 'error')
        }
      })
  }

  const blobToBase64 = (blob) => {
    var reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      return reader.result
    }
  }

  const handleSubmit = () => {
    if (!InavalidprofilePhoto) {
      if (profilePhotoMandatory) {
        if (profileImage === undefined || profileImage === '') {
          Toast(t('PROFILE_PHOTO'), 'error')
          setError({ profilePhoto: true })
          return
        }
      }

      if (CommonUtil.isEmptyString(payload.firstName)) {
        Toast(t('USERPROFSCREEN007'), 'error')
        setError({ firstName: true })
        return
      }

      if (!CommonUtil.isValidNames(payload.firstName)) {
        Toast(t('WALKINREPORTLBL2'), 'error')
        setError({ firstName: true })
        return
      }
      if (!autoContactInfo) {
        if (
          CommonUtil.isEmptyString(payload.emailId) &&
          CommonUtil.isEmptyString(payload.phoneNumber)
        ) {
          Toast(t('USERPROFSCREEN014'), 'error')
          setError({ emailId: true })
          return
        }
        if (
          decoded_jwtToken.email === payload.emailId.trim() ||
          payload.emailId.trim() === selectedHost.email
        ) {
          setError({ emailId: true })
          Toast(
            t('INVITATIONFORM006'),
            'error',
          )
          return
        }

        if (
          payload.phoneNumber !== null &&
          payload.phoneNumber !== undefined &&
          payload.phoneNumber !== ''
        ) {
          if (
            userPhone ===
            payload.phoneCountryCode + '-' + payload.phoneNumber ||
            payload.phoneCountryCode + '-' + payload.phoneNumber ===
            selectedHost.phone
          ) {
            setError({ phoneNumber: false })
            Toast(t('INVITATIONFORM009'), 'error')
            return
          }
        }
      }
      if (CommonUtil.isEmpty(selectedHost)) {
        Toast(t('INVITATIONFORM007'), 'error')
        setError({ selectedHost: true })
        return
      }

      if (
        payload.scheduledStartDate === null ||
        payload.scheduledStartDate === undefined ||
        payload.scheduledStartDate === ''
      ) {
        setError({ scheduledStartDate: true })
        Toast(t('INVITATIONFORM011'), 'error')
        return
      }

      if (
        payload.scheduledEndDate === null ||
        payload.scheduledEndDate === undefined ||
        payload.scheduledEndDate === ''
      ) {
        setError({ scheduledEndDate: true })
        Toast(t('INVITATIONFORM012'), 'error')
        return
      }

      if (scannedDocument) {
        if (payload.documentNumber === '') {
          Toast(t('ENTER_DL_NUMBER'), 'error')
          return
        }
        if (payload.documentIssuedDate === '') {
          Toast(t('ENTER_DL_ISSUE_DATE'), 'error')
          return
        }
        if (payload.documentExpireDate === '') {
          Toast(t('ENTER__DL_EXPIRATION_DATE'), 'error')
          return
        }
        if (payload.documentFrontImage === '') {
          Toast(t('ENTER_FRONT_IMAGE'), 'error')
          return
        }
        if (payload.documentRearImage === '') {
          Toast(t('ENTER_REAR_IMAGE'), 'error')
          return
        }
        if (profilePhotoMandatory) {
          if (dlImage === '') {
            Toast(t('PROFILE_PHOTO'), 'error')
            return
          }
        }
      }

      if (
        new Date(payload.scheduledStartDate) < new Date() &&
        new Date(payload.scheduledStartDate) <
        new Date(payload.scheduledEndDate)
      ) {
        var dt = new Date()
        var addMinutes = new Date().setMinutes(dt.getMinutes())
        var currentDate = CommonUtil.formatToUtc(addMinutes)

        const dto = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          purposeTypeId: payload.purposeTypeId.id,
          visitorTypeId: payload.visitorTypeId.id,
          hostId: payload.hostId,
          phoneCountryCode: !CommonUtil.isEmpty(payload.phoneNumber)
            ? payload.phoneCountryCode
            : '',
          phoneNumber: payload.phoneNumber,
          scheduledStartDate: currentDate,
          scheduledEndDate: CommonUtil.formatToUtc(payload.scheduledEndDate),
          emailId: payload.emailId,
          visitMode: payload.visitMode,
          visitorCompanyName: payload.visitorCompanyName,
          siteId: payload.siteId.id,
          documentNumber: payload.documentNumber,
          documentExpireDate: payload.documentExpireDate,
          remarks: payload.remarks,
          approvalReason: payload.approvalReason,
          documentFrontImage: payload.documentFrontImage,
          documentRearImage: payload.documentRearImage,
          profilePhoto: payload.firstName,
          verifyTypeIn: payload.verifyTypeIn,
          invitationScan: payload.invitationScan,
          walkInScan: payload.walkInScan,
          selfScan: payload.selfScan,
          printerImage: printerImage,
          documentIssuedDate: payload.documentIssuedDate,
          documentScanMode: documentScanMode,
          objectKeys: objectKeys,
        }
        dispatch(createInvitation(dto))
          .unwrap()
          .then((data) => {
            if (data.code === 'CVAI0067') {
              props.loadData()
              setCurrentTotalVisitCount(data.data.currentVisitCount)
              handleClose(data.data)
              Toast(t('REGSENTSUCC001'), 'success')
              if (!CommonUtil.isEmptyString(data.data.profilePhotoURL)) {
                uploadProfilePhoto(data)
              }
              setTimeout(() => {
                props.loadData()
              }, 1000)
              if (confirmationBypass === true && data.data.dymoLabel != null) {
                print(data.data.dymoLabel)
              }
              handleClose(false)
            } else {
              onError(data)
            }
          })
      } else if (
        new Date(payload.scheduledStartDate) > new Date() &&
        new Date(payload.scheduledStartDate) <
        new Date(payload.scheduledEndDate)
      ) {
        const dto = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          emailId: payload.emailId,
          phoneNumber: payload.phoneNumber,
          phoneCountryCode: payload.phoneCountryCode,
          profilePhoto: payload.firstName,
          purposeTypeId: payload.purposeTypeId.id,
          visitorTypeId: payload.visitorTypeId.id,
          hostId: payload.hostId,
          visitorCompanyName: payload.visitorCompanyName,
          remarks: payload.remarks,
          siteId: payload.siteId.id,
          documentFrontImage: payload.documentFrontImage,
          documentRearImage: payload.documentRearImage,
          scheduledStartDate: CommonUtil.formatToUtc(
            payload.scheduledStartDate,
          ),
          scheduledEndDate: CommonUtil.formatToUtc(payload.scheduledEndDate),
          visitMode: 'WALKIN_REGISTARTION',
          documentNumber: payload.documentNumber,
          documentExpireDate: payload.documentExpireDate,
          printerImage: printerImage,
          documentIssuedDate: payload.documentIssuedDate,
          documentScanMode: documentScanMode,
          objectKeys: objectKeys,
        }
        dispatch(createInvitation(dto))
          .unwrap()
          .then((data) => {
            if (data.code === 'CVAI0067') {
              props.loadData()
              handleClose(data.data)
              Toast(t('REGSENTSUCC001'), 'success')
              if (!CommonUtil.isEmptyString(data.data.profilePhotoURL)) {
                uploadProfilePhoto(data)
              }
              props.loadData()
              if (confirmationBypass === true) {
                print(data.data.dymoLabel)
              }
              handleClose(false)
            } else {
              onError(data)
            }
          })
      } else {
        Toast(t('INVITATIONFORM003'), 'error')
      }
    }
    clearData()
  }

  const titleStyle = {
    color: '#242424',
    opacity: 1,
  }

  useEffect(() => {
    setInavalidprofilePhoto(false)
    loadData(props.open)
    startSentinelSession()
  }, [])

  const loadData = () => {
    loadHosts()
    loadSites()
    loadVisitorType()
    loadPurposeType()
    loadUser()
    loadCompany()
    loadSetting()
  }

  const loadUser = () => {
    dispatch(getUserInfoPro(decoded_jwtToken.userId))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0000' || data.code === 'UASI0033') {
          setUserPhone(data.data.phone)
        }
      })
      .catch((er) => { })
  }

  const loadVisitorType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getAllVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setVisitorData(data.data.visitorTypes)
        } else {
          setVisitorData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: '',
      status: 'ACTIVE',
    }

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setPurposeData(data.data.purposeTypes)
          setDateChange(true)
        } else {
          setPurposeData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadSetting = () => {
    dispatch(getListSettings())
      .unwrap()
      .then((data) => {
        setPayload({
          ...payload,
          verifyTypeIn: data.data.walkInRegistartionVerifyMode,
          walkInScan: data.data.walkInScanLimit,
        })
        setConfirmationBypass(data.data.badgePrint)
        setWalkInImageCapture(data.data.walkInImageCapture)
        setAutoContactIfo(data.data.walkInAutoContactInfo)
      })
  }

  const loadHosts = () => {
    const payload = {
      pageNumber: 1,
      pageSize: 20,
      email: decoded_jwtToken.email,
    }
    dispatch(listHost(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'OMSI0000') {
          if (!CommonUtil.isEmptyArray(data.data)) {
            getSelectedItem(data.data.employees[0])
            setHostList(data.data.employee)
          }
        } else {
          setHostList([])
        }
      })
  }

  const config = {
    headers: { companyId: localStorage.getItem('companyId') },
  }

  const handleText = (event) => {
    httpCommon
      .unsecuredAxios()
      .get(
        '/web/selfRegistartion/employees/list?sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=1&pageSize=0&firstName=' +
        event.target.value,
        config,
      )
      .then((data) => {
        if (data.data.code === 'OMSI0000') {
          // getSelectedItem(data.data.data.employees[0]);
          setHostList(data.data.data.employees)
        } else {
          getSelectedItem()
          setHostList([])
        }
      })
  }

  const getSelectedItem = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        hostId: newValue.id,
      })
      setSelectHost(newValue)
    } else {
      setPayload({
        ...payload,
        hostId: '',
      })
      setSelectHost({})
    }
  }
  const loadSites = () => {
    const query = {
      page: 1,
      rowsPerPage: 10000,
      name: '',
    }
    dispatch(getAllSites(query))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000') {
          setSiteData(data.data.site)
        } else {
          setSiteData([])
        }
      })
  }

  const loadCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setCountry(countryCode)
          } else {
            setCountry('us')
          }
        }
      })
      .catch((er) => { })
  }
  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  const filterOptions = createFilterOptions({
    stringify: ({ firstName, email }) => `${firstName} ${email}`,
    limit: 1,
  })

  const [setPrivacy, setitPrivacy] = useState(false)
  const [confirmationBypass, setConfirmationBypass] = useState(false)

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })
    setitPrivacy(event.target.checked)
    setError({
      [name]: false,
    })
  }

  const handleChange1 = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })
    setError({
      [name]: false,
    })
  }

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value)
    setPayload({
      ...payload,
      phoneCountryCode: '+' + data.dialCode,
      phoneNumber: value.toString().replace(data.dialCode, ''),
    })
    setError({ phoneNumber: false })
  }

  const onselectVisitorType = (event) => {
    setPayload({
      ...payload,
      visitorTypeId: event,
    })
  }

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    })
  }

  const setImageFromCam = (image) => {
    if (profilePhotoToggle === true) {
      setPrinterImage(image)
    }
  }

  const [dlScanner, setDlScanner] = useState(false)

  const handleProfile = (file) => {
    setPayload({
      ...payload,
      profilePhoto: payload.firstName,
    })
    setProfileImage(file)
    setdlImage(file)
  }

  const handleAddAssets = () => {
    if (objectKeys.length >= props.walkImageCaptureLimit) {
      Toast(t('IMAGE_LIMIT'), 'error')
      return
    }
    let payload = {
      fileName: 'VISITOR_ASSET' + assetsImages.length + 1,
      scope: 'Application',
      applicationName: 'MSGP',
      companyId: localStorage.getItem('companyId'),
    }

    dispatch(uploadVisitorAsset(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4042' && res.data) {
          setAssetUploadData(res.data)
          setWebCam(!webCam)
          setObjectKeys((prevData) => [...prevData, res.data.objectKey])
        } else {
          Toast(res.message, 'error')
        }
      })
  }

  const handleDeleteAsset = (index) => {
    let objectKey = objectKeys.filter((_, i) => i == index)
    let payload = {
      fileName: '',
      scope: '',
      applicationName: '',
      companyId: '',
      objectKeys: objectKey,
    }
    dispatch(deteleAssetImage(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4043') {
          Toast(t('REMOVE_ASSET_IMAGE'), 'success')
          let newImages = assetsImages.filter((_, i) => i !== index)
          setAssetsImages(newImages)
          let newObjectKeys = objectKeys.filter((_, i) => i !== index)
          setObjectKeys(newObjectKeys)
        } else {
          Toast(res.message, 'error')
        }
      })
  }

  const handleWithOutCaptureImage = () => {
    const index = objectKeys.length - 1
    let objectKey = objectKeys.filter((_, i) => i == index)
    let payload = {
      fileName: '',
      scope: '',
      applicationName: '',
      companyId: '',
      objectKeys: objectKey,
    }
    dispatch(deteleAssetImage(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4043') {
          // Toast("Asset removed Successfully", 'success')
          let newImages = assetsImages.filter((_, i) => i !== index)
          setAssetsImages(newImages)
          let newObjectKeys = objectKeys.filter((_, i) => i !== index)
          setObjectKeys(newObjectKeys)
        } else {
          Toast(res.message, 'error')
        }
      })
  }

  const [value, setValue] = React.useState(
    props.dlScannerPermission ? 'scanLicense' : 'manualEntry',
  )
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue)
  }

  const [scannedDocument, setScannedDocument] = useState()
  const sentinelGUID = 'eb18e024-22b0-4df0-af18-9cea21942756'

  const startSentinelSession = () => {
    axios.post('http://localhost:10300/sentinel/v1/session/' + sentinelGUID)
  }

  const getScannedData = () => {
    const endPoint2 =
      'http://localhost:10300/sentinel/v1/session/' +
      sentinelGUID +
      '/document?api_key=v2'
    axios.get(endPoint2).then((res) => {
      if (res.data === '') {
        Toast(t('WALKINREPORTLBLDLSCAN'), 'info')
        setDlScanner(false)
        return
      }
      console.log(res.data)
      setDocumentScanMode('DOCUMENT_SCANNER_MODE')
      setScannedDocument(res.data)
      processScannedData(res.data)
      setDisableOffender(false)
    })
  }

  const offenderAuthorizationKey = 'AIzaSyDGpRrhZbiHQZnuMyCNUyuNhEKPjzCXgOE'
  const [offenders, setOffenders] = useState([])
  const [offenderFound, setOffenderFound] = useState(false)
  const [offenderNotFound, setOffenderNotFound] = useState(false)
  const [backGroundVerified, setBackGroundVerified] = useState(false)
  const [disableOffender, setDisableOffender] = useState(true)
  const handleOffenderCheck = () => {
    setDisableOffender(true)
    setBackGroundVerified(true)
    setLoading(true)
    const options = { method: 'GET', headers: { accept: 'application/json' } }
    // fetch('https://api.offenders.io/sexoffender/?key=AIzaSyDGpRrhZbiHQZnuMyCNUyuNhEKPjzCXgOE&firstName=Michael&zipcode=70761', options)
    fetch(
      'https://api.offenders.io/sexoffender/?key=' +
      offenderAuthorizationKey +
      '&firstName=' +
      payload.firstName +
      '&lastName=' +
      payload.lastName +
      '&dob=' +
      payload.dateOfBirth,
      options,
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        setOffenders(response)
        console.log(response.offenders.length)
        if (response.offenders.length > 0) {
          setOffenderFound(true)
        } else {
          setOffenderNotFound(true)
        }
      })
      .catch((err) => console.error(err))

    setLoading(false)
  }

  useEffect(() => {
    setDocumentScanMode(documentScanMode)
  }, [documentScanMode])

  const processScannedData = (data) => {
    setDlScanner(true)
    setPayload({
      ...payload,
      documentFrontImage:
        'data:image/jpeg;base64,' +
        data.Document.Images['Image-Array'][0].DocumentImage.Bitmap[
        'image byte array'
        ],
      documentRearImage:
        'data:image/jpeg;base64,' +
        data.Document.Images['Image-Array'][1].DocumentImage.Bitmap[
        'image byte array'
        ],
    })

    data.Document.Regions['Region-Array'].map((item) => {
      if (item.DocumentRegion.Name === 'Photo') {
        var blob = b64toBlob(
          item.DocumentRegion['Image-Bitmap'].Bitmap['image byte array'],
          'image/jpeg',
        )
        handleProfile(blob)
        setProfileImage(
          'data:image/jpeg;base64,' +
          item.DocumentRegion['Image-Bitmap'].Bitmap['image byte array'],
        )
        if (profilePhotoToggle) {
          setPrinterImage(
            item.DocumentRegion['Image-Bitmap'].Bitmap['image byte array'],
          )
        }
      }
    })
    var dateOfBirth = ''
    var documentNumber = ''
    var firstName = ''
    var lastName = ''
    var documentExpireDate = ''
    var address = ''
    var documentIssuedDate = ''
    var documentFrontImage =
      data.Document.Images['Image-Array'][0].DocumentImage.Bitmap[
      'image byte array'
      ]
    var documentRearImage =
      data.Document.Images['Image-Array'][1].DocumentImage.Bitmap[
      'image byte array'
      ]

    data.Document.Fields['Field-Array'].map((item) => {
      if (item.DocumentField.Name === 'Document Number') {
        documentNumber = item.DocumentField.Value
      }

      if (item.DocumentField.Name === 'Full Name') {
        firstName = item.DocumentField.Value.split(' ')[0]
        lastName =
          item.DocumentField.Value.split(' ')[1] !== undefined
            ? item.DocumentField.Value.split(' ')[1]
            : ''
      }

      if (item.DocumentField.Name === 'Expiration Date') {
        documentExpireDate = item.DocumentField.Value.split('T')[0]
      }

      if (item.DocumentField.Name === 'Issue Date') {
        documentIssuedDate = item.DocumentField.Value.split('T')[0]
      }

      if (item.DocumentField.Name === 'Address') {
        address = item.DocumentField.Value
      }
      if (item.DocumentField.Name === 'Birth Date') {
        dateOfBirth = item.DocumentField.Value.split('T')[0]
      }
    })
    setPayload({
      ...payload,
      documentNumber: documentNumber,
      firstName: firstName,
      lastName: lastName,
      documentExpireDate: documentExpireDate,
      address: address,
      documentIssuedDate: documentIssuedDate,
      documentFrontImage: documentFrontImage,
      documentRearImage: documentRearImage,
      dateOfBirth: dateOfBirth,
    })
  }

  const clearData = () => {
    axios
      .delete('http://localhost:10300/sentinel/v1/session/' + sentinelGUID)
      .then((res) => {
        console.log(res)
      })
  }

  const handeleConfirmBypass = () => {
    setConfirmationBypass(confirmationBypass ? false : true)
    console.log('confirmationBypass', confirmationBypass)
  }

  const purposeTypeToggle = props.purposeTypeToggle
  const visitorTypeToggle = props.visitorTypeToggle
  const companyNameToggle = props.companyNameToggle
  const siteTypeToggle = props.siteTypeToggle
  const profilePhotoToggle = props.profliePhotoToggle
  const purposeTypeMandatory = props.purposeTypeMandatory
  const visitorTypeMandatory = props.visitorTypeMandatory
  const companyNameMandatory = props.companyNameMandatory
  const siteTypeMandatory = props.siteTypeMandatory
  const profilePhotoMandatory = props.profilePhotoMandatory

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>

      <Dialog
        className={classes.dialogbox}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle variant="h4" onClick={handleClose} style={{ backgroundColor: darkMode ? '#0F273B' : '#fff', color: darkMode ? '#fff' : 'black' }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}

          >
            <CloseIcon />
          </IconButton>
          {t('WALKINREPORTLBL')}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
          <Grid
            container
            justifyContent="center"
            style={{
              marginBottom: '15px'
            }}

          >
            {loading && (
              <Box sx={{ display: 'flex' }} className="loader-container">
                <CircularProgress className="loader" />
              </Box>
            )}
            {profilePhotoToggle ? (
              <Grid
                xs={12}
                sm={4}
                container
                justifyContent="center"
                style={{ width: '100%', padding: '45px, 0px' }}
                direction="column"
                alignItems="center"
              >
                {dlScanner ? (
                  <img
                    src={profileImage}
                    style={{
                      borderStyle: 'dashed ',
                      borderRadius: '50%',
                      padding: '5px',
                      color: '#D2D2D2',
                      height: '200px',
                      width: '220px',
                    }}
                    onClick={() => setDlScanner(false)}
                  />
                ) : (
                  <>
                    <WebcamCapture
                      onsetProfile={(profile) => handleProfile(profile)}
                      profilePhoto={profileImage}
                      base64Image={(data) => setImageFromCam(data)}
                    />
                    <Grid
                      className="Somashekar"
                      style={{ marginTop: '160px' }}
                    ></Grid>
                  </>
                )}
              </Grid>
            ) : null}

            <Grid
              md={8}
              container
              justifyContent="center"
              style={{ padding: '16px 0px', width: '100%' }}
            >
              <Grid sm={12}>
                <TabContext value={value}>
                  <Box sx={{ margin: '20px 0px 0 ' }}>
                    <TabList
                      onChange={handleChangeTabs}
                      aria-label="lab API tabs example"
                      centered
                      style={{ boxShadow: 'none', backgroundColor: darkMode ? "#707070" : "#fff", width: '519px', marginLeft: '32px' }}
                      indicatorColor="none"
                    >
                      <Tab
                        label={t('WALKINREPORTLBLMANUAL')}
                        value="manualEntry"
                        className={darkMode ? "tabStyleReg1":"tabStyleReg"}
                        
                        
                      />
                      dlScannerPermission
                      {dlScannerPermission ? (
                        <Tab
                          label={t('WALKINREPORTLBLSCANLICENCE')}
                          value="scanLicense"
                          className={darkMode ? "tabStyleReg1":"tabStyleReg"}
                        
                        />
                      ) : (
                        ''
                      )}
                    </TabList>
                    <TabPanel value="manualEntry">
                      <Grid container sm={12} spacing={1}>
                        <Grid item lg={6} sm={12}>
                          <CustomTextfield
                            label={t('COMMON020')}
                            error={error.firstName}
                            name="firstName"
                            value={payload.firstName}
                            handleChange={(e) => handleChange(e)}
                            helperText={error.firstName}
                            required={true}
                            autoComplete="off"
                            inputProps={{ className: classes.input }}
                          />
                        </Grid>

                        <Grid item lg={6} sm={12}>
                          <CustomTextfield
                            label={t('COMMON021')}
                            error={error.lastName}
                            name="lastName"
                            value={payload.lastName}
                            handleChange={(e) => handleChange(e)}
                            helperText={error.lastName}
                            autoComplete="off"
                            inputProps={{ className: classes.input }}
                          />
                        </Grid>

                        {scannedDocument ? (
                          <Grid item lg={6} sm={12}>
                            <CustomTextfield
                              label="DL No"
                              error={error.documentNumber}
                              name="documentNumber"
                              value={payload.documentNumber}
                              handleChange={(e) => handleChange(e)}
                              disabled
                            />
                          </Grid>
                        ) : null}

                        {scannedDocument ? (
                          <Grid item lg={6} sm={12}>
                            <CustomTextfield
                              label={'Expired Date *'}
                              error={error.documentExpireDate}
                              name="documentExpireDate"
                              value={payload.documentExpireDate}
                              handleChange={(e) => handleChange(e)}
                              disabled
                            />
                          </Grid>
                        ) : null}

                        {!autoContactInfo ? (
                          <Grid item lg={6} sm={12}>
                            <CustomTextfield
                              label={t('COMMON042')}
                              error={error.emailId}
                              name="emailId"
                              value={payload.emailId}
                              handleChange={(e) => handleChange(e)}
                              helperText={error.emailId}
                              validation="email"
                              autoComplete="off"
                              inputProps={{ className: classes.input }}
                            />
                          </Grid>
                        ) : (
                          ''
                        )}

                        {!autoContactInfo ? (
                          <Grid
                            item
                            lg={6}
                            sm={12}
                            style={{ margin: '16px 0px' }}
                          >
                            <CustomPhones
                              placeholder={t('COMMON063')}
                              value={phoneInput}
                              specialLabel={false}
                              country={country}
                              handleChange={(
                                value,
                                data,
                                event,
                                formattedValue,
                              ) =>
                                handlePhoneChange(
                                  value,
                                  data,
                                  event,
                                  formattedValue,
                                )
                              }
                              inputProps={{ className: classes.input }}
                            />
                          </Grid>
                        ) : (
                          ''
                        )}

                        {purposeTypeToggle ? (
                          <Grid
                            item
                            lg={6}
                            sm={12}
                            style={{ margin: '16px 0px' }}
                          >
                            <Autocomplete
                              style={{ width: '100%' }}
                              noOptionsText={'No Options found'}
                              name="purposeTypeId"
                              value={payload.purposeTypeId}
                              options={
                                CommonUtil.isEmptyArray(purposeData)
                                  ? []
                                  : purposeData
                              }
                              getOptionLabel={(option) =>
                                option.name ? option.name : ''
                              }
                              onChange={(event, newValue) => {
                                if (!CommonUtil.isEmpty(newValue)) {
                                  // eslint-disable-next-line
                                  onselectPurposeType(newValue)
                                } else {
                                  onselectPurposeType('')
                                }
                                setError({ purposeTypeId: false })
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="purposeTypeId"
                                  value={payload.purposeTypeId}
                                  error={error.purposeTypeId}
                                  variant="outlined"
                                  fullWidth
                                  label={
                                    purposeTypeMandatory
                                      ? t('PURPOSELABEL1') + ' *'
                                      : t('PURPOSELABEL1')
                                  }
                                  InputLabelProps={{
                                    style: {
                                      color: darkMode ? '#fff' : '#0F273B',
                                    },
                                  }}

                                  InputProps={{
                                    ...params.InputProps,
                                    style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                                  }}

                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                                      },
                                      '&:hover fieldset': {
                                        borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                                      },
                                    },
                                  }}

                                />
                              )}
                            />
                          </Grid>
                        ) : null}

                        {visitorTypeToggle ? (
                          <Grid
                            item
                            lg={6}
                            sm={12}
                            style={{ margin: '16px 0px' }}
                          >
                            <Autocomplete
                              style={{ width: '100%' }}
                              noOptionsText={'No Options found'}
                              name="visitorTypeId"
                              value={payload.visitorTypeId}
                              options={
                                CommonUtil.isEmptyArray(visitorData)
                                  ? []
                                  : visitorData
                              }
                              getOptionLabel={(option) =>
                                option.name ? option.name : ''
                              }
                              onChange={(event, newValue) => {
                                if (!CommonUtil.isEmpty(newValue)) {
                                  // eslint-disable-next-line
                                  onselectVisitorType(newValue)
                                } else {
                                  onselectVisitorType('')
                                }
                                setError({ visitorTypeId: false })
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="visitorTypeId"
                                  value={payload.visitorTypeId}
                                  error={error.visitorTypeId}
                                  variant="outlined"
                                  fullWidth
                                  label={
                                    visitorTypeMandatory
                                      ? t('COMMON068') + ' *'
                                      : t('COMMON068')
                                  }
                                  InputLabelProps={{
                                    style: {
                                      color: darkMode ? '#fff' : '#0F273B',
                                    },
                                  }}

                                  InputProps={{
                                    ...params.InputProps,
                                    style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                                  }}

                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                                      },
                                      '&:hover fieldset': {
                                        borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                                      },
                                    },
                                  }}

                                />
                              )}
                            />
                          </Grid>
                        ) : null}

                        <Grid
                          item
                          lg={6}
                          sm={12}
                          style={{ margin: '16px 0px' }}
                        >
                          <Autocomplete
                            style={{
                              margin: '-15px',
                              marginLeft: '1px',
                              color: '#242424',
                            }}
                            id="free-solo-demo"
                            freeSolo
                            noOptionsText={'No Options found'}
                            fullWidth
                            filterOptions={filterOptions}
                            options={
                              CommonUtil.isEmptyArray(hostList) ? [] : hostList
                            }
                            disabled={decoded_jwtToken.roleName === 'Employee'}
                            getOptionLabel={(option) =>
                              option.lastName
                                ? option.firstName + ' ' + option.lastName
                                : option.firstName
                                  ? option.firstName
                                  : ''
                            }
                            value={selectedHost}
                            onChange={(event, newValue) => {
                              getSelectedItem(newValue)
                              setError({ selectedHost: false })
                            }}
                            renderInput={(params) => (
                              <CustomTextfield
                                {...params}
                                label={t('commonsTextFeildHost')}
                                required={true}
                                placeholder={t('commonsTextFeildHost')}
                                name="hostId"
                                key="Confirmation Code"
                                id="hostId"
                                handleChange={(e) => handleText(e)}
                                error={error.selectedHost}
                              />
                            )}
                          />
                        </Grid>

                        {companyNameToggle ? (
                          <Grid item lg={6} xs={12}>
                            <CustomTextfield
                              label={
                                companyNameMandatory
                                  ? t('VISUPDATE0006') + ' *'
                                  : t('VISUPDATE0006')
                              }
                              error={error.visitorCompanyName}
                              name="visitorCompanyName"
                              value={payload.visitorCompanyName}
                              handleChange={(e) => handleChange(e)}
                              helperText={error.visitorCompanyName}
                              inputProps={{ className: classes.input }}
                            />
                          </Grid>
                        ) : null}

                        <Grid
                          item
                          lg={6}
                          xs={12}
                          style={{ margin: '16px 0px' }}
                        >
                          <CustomDateTimePicker
                            onChange={(scheduledStartDate) =>
                              setPayload({
                                ...payload,
                                scheduledStartDate: scheduledStartDate,
                                scheduledEndDate: CommonUtil.formatToUtc(
                                  moment(scheduledStartDate).add(
                                    walkInIntervalTime,
                                    'minutes',
                                  ),
                                ),
                              })
                            }
                            disableFuture
                            disablePast
                            value={payload.scheduledStartDate}
                            label={t('INVITESTARTDATEERROR1')}
                            changeDate={changeDate}
                            date={props.date}
                          />
                        </Grid>

                        <Grid
                          item
                          lg={6}
                          xs={12}
                          style={{ margin: '16px 0px' }}
                        >
                          <CustomDateTimePicker
                            onChange={(scheduledEndDate) =>
                              setPayload({
                                ...payload,
                                scheduledEndDate: scheduledEndDate,
                              })
                            }
                            disableFuture
                            disablePast
                            value={payload.scheduledEndDate}
                            minDateTime={payload.scheduledStartDate}
                            label={t('INVITESTARTDATEERROR2')}
                            changeDate={changeDate}
                            date={props.date}
                          />
                        </Grid>

                        {siteTypeToggle ? (
                          <Grid
                            item
                            lg={6}
                            sm={12}
                            style={{ margin: '16px 0px' }}
                          >
                            <Autocomplete
                              style={{ width: '100%' }}
                              noOptionsText={'No Options found'}
                              name="siteId"
                              value={payload.siteId}
                              options={
                                CommonUtil.isEmptyArray(siteData)
                                  ? []
                                  : siteData
                              }
                              getOptionLabel={(option) =>
                                option.name ? option.name : ''
                              }
                              onChange={(event, newValue) => {
                                if (!CommonUtil.isEmpty(newValue)) {
                                  // eslint-disable-next-line
                                  setPayload({ ...payload, siteId: newValue })
                                } else {
                                  setPayload({ ...payload, siteId: '' })
                                }
                                setError({ siteId: false })
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="siteId"
                                  value={payload.siteId}
                                  error={error.siteId}
                                  variant="outlined"
                                  fullWidth
                                  label={
                                    siteTypeMandatory
                                      ? t('SITE013') + ' *'
                                      : t('SITE013')
                                  }
                                  InputLabelProps={{
                                    style: {
                                      color: darkMode ? '#fff' : '#0F273B',
                                    },
                                  }}

                                  InputProps={{
                                    ...params.InputProps,
                                    style: { color: darkMode ? '#fff' : '#0F273B' }, // Set text color
                                  }}

                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                                      },
                                      '&:hover fieldset': {
                                        borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                                      },
                                    },
                                  }}

                                />
                              )}
                            />
                          </Grid>
                        ) : null}

                        {scannedDocument ? (
                          <Grid item lg={6} xs={12}>
                            <CustomTextfield
                              label="Address"
                              error={error.address}
                              name="address"
                              value={payload.address}
                              handleChange={(e) => handleChange(e)}
                              helperText={error.address}
                              validation="alphabets"
                              inputProps={{ className: classes.input }}
                            />
                          </Grid>
                        ) : null}

                        {scannedDocument ? (
                          <Grid
                            item
                            lg={
                              (Number(!purposeTypeToggle) +
                                Number(!visitorTypeToggle) +
                                Number(!companyNameToggle) +
                                Number(!siteTypeToggle)) %
                                2 ===
                                1
                                ? 6
                                : 12
                            }
                            sm={12}
                          >
                            <CustomTextfield
                              label={t('COMMON016')}
                              error={error.approvalReason}
                              name="approvalReason"
                              value={payload.approvalReason}
                              validation="restrictLength"
                              handleChange={(e) => handleChange(e)}
                              helperText={error.remarks}
                              inputProps={{
                                maxLength: 100,
                                className: classes.input,
                              }}
                            />
                          </Grid>
                        ) : (
                          <Grid
                            item
                            lg={
                              (Number(!purposeTypeToggle) +
                                Number(!visitorTypeToggle) +
                                Number(!companyNameToggle) +
                                Number(!siteTypeToggle)) %
                                2 ===
                                1
                                ? 12
                                : 6
                            }
                            sm={12}
                          >
                            <CustomTextfield
                              label={t('COMMON016')}
                              error={error.approvalReason}
                              name="approvalReason"
                              value={payload.approvalReason}
                              handleChange={(e) => handleChange(e)}
                              validation="restrictLength"
                              helperText={error.approvalReason}
                              inputProps={{
                                maxLength: 100,
                                className: classes.input,
                              }}
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          spacing={3}
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            columnGap: '15px',
                          }}
                        >
                          {assetsImages.map((imageSrc, index) => (
                            <div
                              key={index}
                              style={{ position: 'relative' }}
                              onMouseEnter={() => setHoveredIndex(index)}
                              onMouseLeave={() => setHoveredIndex(null)}
                            >
                              <img
                                key={index}
                                src={imageSrc}
                                alt={`Image ${index + 1}`}
                                height="60"
                                width="60"
                              />

                              {hoveredIndex === index && (
                                <Tooltip title={t('REMOVE_ASSET')}>
                                  <CloseIcon
                                    key={index}
                                    style={{
                                      position: 'absolute',
                                      right: '-10px',
                                      top: '-10px',
                                      backgroundColor: '#DDDDDD',
                                      borderRadius: '50px',
                                      height: '17px',
                                      width: '17px',
                                    }}
                                    onClick={() => handleDeleteAsset(index)}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          ))}
                          {walkInImageCapture && assetsImages.length < 5 && (
                            <Tooltip title={t('ADD_ASSET')}>
                              <img
                                src={cameraIcon}
                                alt="Invalid Icon"
                                onClick={handleAddAssets}
                                height="30"
                                width="30"
                              />
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                      <Grid display="flex" p={1}>
                        <Box flexGrow={1}></Box>
                      </Grid>
                      <DialogActions
                        style={{
                          justifyContent: 'center',
                        }}
                      >
                        <SubmitButtons
                          variant="contained"
                          onClick={handleSubmit}
                        >
                          <Typography
                            variant="body1"
                            style={{ textTransform: 'none' }}
                          >
                            {t('COMMON035')}
                          </Typography>
                        </SubmitButtons>
                        <CancelButtons
                          variant="contained"
                          color="secondary"
                          onClick={handleClose}
                        >
                          <Typography
                            variant="body1"
                            style={{ textTransform: 'none' }}
                          >
                            {t('INVITATION003')}
                          </Typography>
                        </CancelButtons>
                      </DialogActions>
                    </TabPanel>

                    {dlScannerPermission && (
                      <TabPanel value="scanLicense">
                        {props.showDLScan ? (
                          <Grid
                            style={{ textAlign: 'end', marginRight: '15px' }}
                          >
                            {dlScanner &&
                              payload.documentFrontImage !== null ? (
                              <SubmitButtons
                                variant="contained"
                                disabled
                                onClick={() => {
                                  getScannedData()
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{ textTransform: 'none' }}
                                >
                                  {t('WALKINREPORTLBLDL')}
                                </Typography>
                              </SubmitButtons>
                            ) : (
                              <SubmitButtons
                                variant="contained"
                                onClick={() => {
                                  getScannedData()
                                }}
                                style = {{height: '44px'}}
                              >
                                <Typography
                                  variant="body1"
                                  style={{ textTransform: 'none' }}
                                >
                                  {t('WALKINREPORTLBLDL')}
                                </Typography>
                              </SubmitButtons>
                            )}
                          </Grid>
                        ) : (
                          ''
                        )}
                        <Grid container sm={12} spacing={1}>
                          <Grid item lg={6} sm={12}>
                            <UploadDocs
                              imageName={t('WALKINREPORTLBLCAPT')}
                              profilePhoto={
                                scannedDocument
                                  ? 'data:image/jpeg;base64,' +
                                  scannedDocument.Document.Images[
                                    'Image-Array'
                                  ][0].DocumentImage.Bitmap[
                                  'image byte array'
                                  ]
                                  : ''
                              }
                            />
                          </Grid>
                          <Grid item lg={6} sm={12}>
                            <UploadDocs
                              imageName={t('WALKINREPORTLBLCAPTBCK')}
                              profilePhoto={
                                scannedDocument
                                  ? 'data:image/jpeg;base64,' +
                                  scannedDocument.Document.Images[
                                    'Image-Array'
                                  ][1].DocumentImage.Bitmap[
                                  'image byte array'
                                  ]
                                  : ''
                              }
                            />
                          </Grid>
                          {offenderFeature && (
                            <Grid padding={2}>
                              <SubmitButtons
                                disabled={disableOffender}
                                variant="contained"
                                onClick={() => {
                                  handleOffenderCheck()
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{ textTransform: 'none', color: darkMode? 'rgb(205, 205, 205)' : '' }}
                                >
                                  {t('OFFENDER')}
                                </Typography>
                              </SubmitButtons>
                            </Grid>
                          )}
                          {backGroundVerified && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              style={{
                                height: '60px',
                                width: '100%',
                                backgroundColor: '#e6ffe6',
                                borderRadius: '10px',
                                marginTop: '10px',
                              }}
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                style={{
                                  display: 'inline-flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={successIcon}
                                  alt="success"
                                  style={{
                                    marginLeft: '15px',
                                    marginTop: '8px',
                                  }}
                                />
                                <Typography
                                  style={{
                                    color: '#6BC88E',
                                    marginTop: '10px',
                                  }}
                                >
                                  Background Verified
                                </Typography>
                              </Stack>
                            </Grid>
                          )}
                          {offenderNotFound && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              style={{
                                height: '60px',
                                width: '100%',
                                backgroundColor: '#e6ffe6',
                                borderRadius: '10px',
                                marginTop: '10px',
                              }}
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                style={{
                                  display: 'inline-flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={successIcon}
                                  alt="success"
                                  style={{
                                    marginLeft: '15px',
                                    marginTop: '8px',
                                  }}
                                />
                                <Typography
                                  style={{
                                    color: '#6BC88E',
                                    marginTop: '10px',
                                  }}
                                >
                                  No Offender Record Found
                                </Typography>
                              </Stack>
                            </Grid>
                          )}
                          {offenderFound && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              style={{
                                height: '60px',
                                width: '100%',
                                backgroundColor: '#FFEAEB',
                                borderRadius: '10px',
                                marginTop: '10px',
                              }}
                            >
                              <Stack
                                direction="row"
                                spacing={2}
                                style={{
                                  display: 'inline-flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={errorIcon}
                                  alt="success"
                                  style={{
                                    marginLeft: '15px',
                                    marginTop: '8px',
                                  }}
                                />
                                <Typography
                                  style={{
                                    color: '#FF4D50',
                                    marginTop: '10px',
                                  }}
                                >
                                  Offender Record Found
                                </Typography>
                              </Stack>
                            </Grid>
                          )}
                        </Grid>
                        <DialogActions
                          style={{
                            justifyContent: 'flex-end',
                          }}
                        >
                          <SubmitButtons
                            variant="contained"
                            onClick={() => setValue('manualEntry')}
                          >
                            <Typography
                              variant="body1"
                              style={{ textTransform: 'none' }}
                            >
                              {t('VERIFYCOMP0003')}
                            </Typography>
                          </SubmitButtons>
                        </DialogActions>
                      </TabPanel>
                    )}
                  </Box>
                </TabContext>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        {webCam && (
          <VisitorAssetsDialog
            open={webCam}
            handleClose={() => {
              handleWithOutCaptureImage()
              setWebCam(!webCam)
            }}
            content={assetuploadData}
            handleSubmit={(blob, base64Data, content) => {
              setAssetsImages((prevImages) => [...prevImages, base64Data])
              uploadAssetImages(blob)
              setWebCam(!webCam)
              Toast(t('ASSET_SUCCESSFULLY'), 'success')
            }}
          />
        )}
      </Dialog>
    </>
  )
}
