import React, { useEffect, useState, useContext } from 'react'
import DataTable from '../../components/DataTable/DataTable'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { useHistory } from 'react-router-dom'
import { Box, Link, Tooltip } from '@mui/material'
import CustomStyleStatus from '../../components/statusStyleFormat/statusStyleFormat'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import { Avatar } from '@material-ui/core'
import HeaderToolbar from '../../components/HeaderToolbar'
import Search from '../../components/SearchTab/Search'
import UnblacklistVisitorForm from './UnblacklistVisitorForm'
import BlockedVisitorProfile from '../../components/VisitorActionsComponents/BlockedVisitorProfile'
import UnblockIcon from '../BlockedVisitors/UnblockIcon'
import { listInvitations } from '../../slices/Invitations/InvitationsSlice'
import SelfRegistrationFilter from '../Approvals/SelfRegistrationFilter'
import { exportBlacklistVisitors } from '../../services/Invitations/ExportService'
import Toast from '../../components/ToastContainer/CustomToast'
import moment from 'moment'
import FilterExportDialog from '../DailyReports/FilterExportDialog'
import FilterBlockedVisitors from './FilterBlacklistVisitor'
import CommonUtil from '../../Util/CommonUtils'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { ThemeContext } from '../../theme/ThemeContext';

export default function BlacklistVisitors() {
  const [clear, setClear] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [totalRecords, setTotalRecords] = useState(1)
  const [rows, setRows] = useState([])
  const [openUnblacklistVisitor, setOpenUnblacklistVisitor] = useState(false)
  const [unblacklistId, setUnblacklistId] = useState()
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const { darkMode } = useContext(ThemeContext);
  const status = ['BLOCKED', 'UN_BLOCKED']
  const [date, setDate] = useState('')

  // const blockStatus = ["BLOCKED"];

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = React.useState({
    isRestricted: 'true',
    visitorTypeId: '',
    purposeTypeId: '',
    status: ['BLOCKED', 'UN_BLOCKED'],
    visitMode: [
      'INVITATION',
      'WALKIN_REGISTARTION',
      'WALKIN_REGISTARTION_KIOSK',
      'SELF_REGISTARTION',
    ],
  })

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const defaultFilter = {
    isRestricted: 'true',
    visitorTypeId: '',
    purposeTypeId: '',
    status: ['BLOCKED', 'UN_BLOCKED'],
    // blockStatus: "BLOCKED",
    visitMode: [
      'INVITATION',
      'WALKIN_REGISTARTION',
      'WALKIN_REGISTARTION_KIOSK',
      'SELF_REGISTARTION',
    ],
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging, clear])

  const handleOpen = () => {
    setOpen(true)
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const loadData = (filter, paging) => {
    dispatch(listInvitations(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          setPageSize(data.data.pageSize)
          setTotalRecords(data.data.totalCount)
          setRows(data.data.visits)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
        } else {
          setRows([])
        }
      })
      .catch((er) => {})
  }

  const handleFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      visitorTypeId: data.visitorTypeId,
      purposeTypeId: data.purposeTypeId,
      status: data.status,
      visitMode: data.visitMode,
      isRestricted: data.isRestricted,
    })
  }
 
  useEffect(() => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if ((data.code === "CVAI0000")) {
          setDate(formatDate(data.data.dateFormat));
        }
      })
  }, []);

  const formatDate = (dateFormat) => {
    switch (dateFormat) {
      case 'dd-MM-yyyy':
        return 'dd-MM-yyyy HH:mm';
      case 'MM-dd-yyyy':
        return 'MM-dd-yyyy HH:mm';
      case 'yyyy-MM-dd':
        return 'yyyy-MM-dd HH:mm';
      default:
        return 'yyyy-MM-dd HH:mm';
    }
  };
  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage })
      setDel(false)
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false)
      }
    }
  }, [del])

  const globalsearch = (searchedVal) => {
    if (searchedVal === 'Blacklist') {
      searchedVal = 'BLOCKED'
    }

    const filteredRows = rows.filter((test) => {
      return (
        (test.visitor.firstName
          ? test.visitor.firstName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
          : '') ||
        (test.visitor.lastName
          ? test.visitor.lastName
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
          : '') ||
        (test.visitor.phone
          ? test.visitor.phone.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.visitor.email
          ? test.visitor.email.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.visitor.firstName} ${test.visitor.lastName}`
          .toLowerCase()
          .includes(searchedVal.toLowerCase())
      )
    })
    setRows(filteredRows)
  }

  const handleBlacklistExport = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      status: ['BLOCKED', 'UN_BLOCKED'],
      visitMode: [
        'INVITATION',
        'WALKIN_REGISTARTION',
        'WALKIN_REGISTARTION_KIOSK',
        'SELF_REGISTARTION',
      ],
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
    })

    const stats = {
      page: 1,
      rowsPerPage: 1000,
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
      status: ['BLOCKED', 'UN_BLOCKED'],
    }

    dispatch(exportBlacklistVisitors(onSuccess, onFailure, stats))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          onSuccess()
        } else {
          onFailure()
        }
      })
      .catch((er) => {})
  }

  const onSuccess = () => {
    return Toast(t('EXPORTSUCC001'), 'success')
  }

  const onFailure = () => {
    Toast(t('COMMON000'), 'error')
  }

  const handleUnblockVisitor = (params) => {
    setOpenUnblacklistVisitor(!openUnblacklistVisitor)
    setUnblacklistId(params.row)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 16) {
      return text.substring(0, 17) + '...'
    } else {
      return text
    }
  }

  const renderCellScheduledStartDate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(() => [
    {
      field: 'visitor.profile',
      headerName: '',
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },
    {
      field: 'visitor.firstName',
      headerName: t('COMMON014'),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null
          ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
          : params.row.visitor.firstName,
      renderCell: (params) => (
        <Tooltip
          title={` ${
            params.row.visitor.lastName !== null
              ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
              : params.row.visitor.firstName
          }`}
          placement="bottom-start"
        >
          <span>
            {handleTextOverflow(
              params.row.visitor.lastName !== null
                ? params.row.visitor.firstName +
                    ' ' +
                    params.row.visitor.lastName
                : params.row.visitor.firstName,
            )}
          </span>
        </Tooltip>
      ),
    },

    {
      field: 'visitor.phone',
      headerName: t('COMMON063'),
      type: 'singleSelect',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.phone,
    },
    {
      field: 'visitor.email',
      headerName: t('PERSON005'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.visitor.email}`}
          placement="bottom-start"
        >
          <span>{handleTextOverflow(params.row.visitor.email)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'scheduledStartDate',
      headerName: t('INVITEDATELBL'),
      flex: 1,
      width: 10,
      renderCell:renderCellScheduledStartDate(date)
    },

    {
      field: 'status',
      headerName: t('COMMON001'),
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />
      },
    },
    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<BlockedVisitorProfile rowData={params.row} />}
          label={t('VIEW')}
        />,
        <GridActionsCellItem
          icon={<UnblockIcon />}
          label={t('APPROVE002')}
          onClick={() => handleUnblockVisitor(params)}
        />,
      ],
    },
  ],[date])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <HeaderToolbar 
      title={t('BLOCKEDVIS004')}
      />

      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{marginLeft: darkMode ? '-16px' : '0px'}}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterBlockedVisitors
            rowsPerPage={rowsPerPage}
            selectedObject={filter.visitMode}
            status={status}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        {/* <Box item p={1}>
          <ExportReport handleClick={handleBlacklistExport()} />
        </Box> */}

        <Box item p={1}>
          <FilterExportDialog
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleBlacklistExport(data)}
            date={date}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openUnblacklistVisitor && (
        <UnblacklistVisitorForm
          rowData={unblacklistId}
          open={openUnblacklistVisitor}
          handleClose={(data) => {
            setOpenUnblacklistVisitor(data)
          }}
          disable={() => setDel(true)}
          UnBlacklistVisitorId={unblacklistId}
        />
      )}
    </>
  )
}
