import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, Grid, Link, Typography, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import back_button from '../../assets/images/onboard/back_button.svg';
import './custom.css';
import HeaderI18n from '../../layout-components/HeaderI18n';
import CustomCarousel from '../../Pages/OnBoardV2/CustomCarousel'
import { useHistory, withRouter } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import BackgroundImg from '../../assets/USA_Images/login_images_visitor/img7.svg'
const darkModeStyles = {
  backgroundImage: `url(${BackgroundImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

// const glassEffectStyles = {
//   background: 'rgba(255, 255, 255, 0.1)',
//   borderRadius: '1px',
//   backdropFilter: 'blur(15px)',
//   boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//   border: '1px solid rgba(255, 255, 255, 0.2)',
//   padding: '10px',
//   margin: '66px 0',
//   color: '#fff',
//   overflow: 'hidden', 
//   position: 'relative',
// };

const useStyles = makeStyles((theme) => ({
  container: {
    height: '90%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  footer: {
    bottom: '10px',
    marginLeft: '-180px',
    textAlign: 'left',
    width: '100%',
    position: 'absolute',
  },
  footer_USA: {
    bottom: '10px',
    textAlign: 'center',
    width: '90%',
    position: 'absolute',
  }
}));

function LoginLayoutV2({ bgColor, color, width, height, fontFamily, lang, login_img, success, backbutton, login_img_vis, ...props }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const isDarkMode = process.env.REACT_APP_ENVIRONMENT === 'USA';
  const handleClick = (event) => {
    if (event === 'user agreement') {
      history.push({
        pathname: "/vis/user-agreement",
        state: { success: true },
      });
    } else if (event === 'privacy policy') {
      history.push({
        pathname: "/vis/privacy-policy",
        state: { success: true },
      });
    }
  };


  return (
    <Grid className='signin-container'>
      <Grid component='main' maxWidth='full'
      >
        <Grid container>
          <Grid item sm={7} xs={12} className='paper_left1'>
            {props.src ? (
              <img
                src={props.src}
                alt='logo' className='left_img' />
            ) : (
              <CustomCarousel />
            )}
          </Grid>
          <Grid item sm={5} xs={12} className='USA_SignIn-Right'
            style={darkModeStyles}>
            <Grid container direction='row'>
              {backbutton ? (
                <Grid item onClick={props.handleClick}>
                  <Tooltip title={props.title} style={{ cursor: 'pointer' }} >
                    <img src={back_button} alt='logo' width={'42px'} />
                  </Tooltip>
                </Grid>
              ) : (
                <></>
              )}
              {lang ? (
                <Grid item style={{ position: 'absolute', right: 12 }}>
                  <HeaderI18n />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <div >
              {props.children}

              <Grid className={classes.footer_USA}>
                <Typography style={{ fontSize: '12px', color: '#fff', opacity: '0.8' }} color='textSecondary'>
                  {t('COPY_RIGHT_2024')}
                </Typography>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
LoginLayoutV2.propTypes = {
  bgColor: PropTypes.string,
  width: PropTypes.string,
};

LoginLayoutV2.defaultProps = {
  width: '150px',
};

export default LoginLayoutV2;
