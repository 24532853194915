import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import { makeStyles } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { Height } from '@mui/icons-material'
import jwt_decode from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { upgradeSubscription } from '../../slices/Subscription/SubscriptionSlice'
import Toast from '../../components/ToastContainer/CustomToast'

const useStyles = makeStyles((theme) => ({
  upGardeDialog: {
    '& .MuiDialog-paper': {
      minWidth: '900px',
      marginLeft: '250px',
    },
  },
}))

function UpgradeSubscriptionDialog(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [existingPlan, setExistingPlan] = useState(props.data)
  const [newPlan, setNewPlan] = useState(props.selectedPlan)
  const [currentPlanPrice, setCurrentPlanPrice] = useState(
    props.data.price.unit_amount_decimal / 100,
  )
  const [newPlanPrice, setNewPlanPRice] = useState(
    props.data.price.recurring.interval === 'month'
      ? props.selectedPlan.original_price
      : props.selectedPlan.original_price * 12,
  )
  const [totalAmount, setTotalAmount] = useState(
    props.data.price.recurring.interval === 'month'
      ? props.selectedPlan.original_price -
          props.data.price.unit_amount_decimal / 100
      : props.selectedPlan.original_price * 12 -
          props.data.price.unit_amount_decimal / 100,
  )

  var companyId = localStorage.getItem('companyId')

  const onClose = () => {
    props.handleClose()
  }

  const handleSubmit = () => {
    let payload = {
      productId: newPlan.productId,
      dealerCompanyId: companyId,
      subscriptionId: existingPlan.id,
      payementFrequency: existingPlan.price.recurring.interval,
      subscriptionName: newPlan.price_strategy_name,
      totalPrice:
        props.data.price.recurring.interval === 'month'
          ? newPlan.original_price
          : newPlan.original_price * 12,
    }
    dispatch(upgradeSubscription(payload))
      .unwrap()
      .then((res) => {
        switch (res.code) {
          case 'MSGS4036':
            Toast(t('UPGRADE_SUCCESS'), 'success')
            onClose()
            break
          case 'MSGE4060':
            Toast(t('UPGRADE_SUBSCRIPTION_PLAN_ERROR'), 'error')
            onClose()
            break
          case 'MSGE4061':
            Toast(t('UPGRADE_ERROR_SUBSCRIPTION_PLAN'), 'error')
            onClose()
            break
          case 'MSGE4051':
            Toast(t('UPGRADE_ERROR_PRODUCT_NOT_EXIST'), 'error')
            onClose()
            break
          default:
            Toast(res.message, 'error')
            break
        }
      })
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={onClose}
        fullWidth={true}
        disable
        PaperProps={{
          style: {
            borderRadius: '20px !important',
          },
        }}
        className={classes.upGardeDialog}
      >
        {' '}
        <DialogTitle variant="h4" onClick={onClose}>
          <IconButton
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {t('UPGRADE_HEADER_SUBSCRIPTION')}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <Grid container padding={2}>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                {' '}
                {t('EXISTINGPLANPRICE')}{' '}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              {props.data.price.recurring.interval === 'month' ? (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {'$ ' + currentPlanPrice + t('MONTHLY_VALUE')}
                </Typography>
              ) : (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {'$ ' + currentPlanPrice + t('YERALY_VALUE')}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container padding={2}>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                {' '}
                {t('UPGRADEPLANPRICE')}{' '}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              {props.data.price.recurring.interval === 'month' ? (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {'$ ' + newPlanPrice + t('MONTHLY_VALUE')}
                </Typography>
              ) : (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {'$ ' + newPlanPrice + t('YERALY_VALUE')}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container padding={2}>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                {}
                {t('TOTALAMOUNT')}{' '}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              {props.data.price.recurring.interval === 'month' ? (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {'$ ' + totalAmount + t('MONTHLY_VALUE')}
                </Typography>
              ) : (
                <Typography sx={{ fontStyle: 'bold' }} variant="h4">
                  {'$ ' + totalAmount + t('YERALY_VALUE')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <SubmitButtons variant="contained" onClick={handleSubmit}>
            <Typography variant="body1" style={{ textTransform: 'none' }}>
              {t('COMMON007')}
            </Typography>
          </SubmitButtons>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UpgradeSubscriptionDialog
