import { Avatar } from "@material-ui/core";
import { Box, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable";
import InvitationFilterForm from "../../components/InvitationFilterForm";
import Search from "../../components/SearchTab/Search";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import VisitorProfileCard from "../../components/VisitorActionsComponents/VisitorProfileCard";
import { getListSettings } from "../../slices/AdditionalSettings/AdditionalSettingsSlice";
import { listInvitations } from "../../slices/Invitations/InvitationsSlice";
import CommonUtil from "../../Util/CommonUtils";
import ApprovalPageWalkIn from "../Approval/ApprovalPageWalkIn";
import ApproveIcon from "./ApproveIcon";
import SimpleDialogDemo from "./ImageDailogBox";
import RejectIcon from "./RejectIcon";
import RejectRegistartionInvitationDialog from "./RejectRegistartionInvitationDialog";
import { ThemeContext } from '../../theme/ThemeContext';

export default function WalkInRegistrationApprovals(props) {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [rejectVisitId, setRejectVisitId] = useState();
  const [openRejectInvitation, setOpenRejectInvitation] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [selectedObject, setSelectedObject] = useState([]);
  const [del, setDel] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [date, setDate] = useState('')
  const { darkMode } = useContext(ThemeContext);

  const status = ["CONFIRMED"];

  const visitIdDetails = props.visitIdForWalkInRegration;

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    status: ["CONFIRMED"],
    visitorTypeId: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: "",
    visitMode: ["WALKIN_REGISTARTION", "WALKIN_REGISTARTION_KIOSK"],
    isPagable: true,
  });

  const defaultFilter = {
    status: ["CONFIRMED"],
    visitorTypeId: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: "",
    visitMode: ["WALKIN_REGISTARTION", "WALKIN_REGISTARTION_KIOSK"],
    isPagable: true,
  };

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setRowsPerPage(size);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);

  }, [filter, paging, clear]);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadData = (filter, paging) => {
    dispatch(listInvitations(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0000" && data.data)) {
          setTotalRecords(data.data.totalCount);
          setRows(data.data.visits);
          setPage(data.data.curPage);
          setPageSize(data.data.pageSize);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };


  useEffect(() => {

    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage });
      setDel(false);
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false);
      }
    }
  }, [del]);

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      siteId: data.siteId,
      visitorTypeId: data.visitorTypeId,
      visitMode: data.visitMode,
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
      status: data.status,
    });
  };

  useEffect(() => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if ((data.code === "CVAI0000")) {
          setDate(formatDate(data.data.dateFormat));
        }
      })
  }, []);

  const formatDate = (dateFormat) => {
    switch (dateFormat) {
      case 'dd-MM-yyyy':
        return 'dd-MM-yyyy HH:mm';
      case 'MM-dd-yyyy':
        return 'MM-dd-yyyy HH:mm';
      case 'yyyy-MM-dd':
        return 'yyyy-MM-dd HH:mm';
      default:
        return 'yyyy-MM-dd HH:mm';
    }
  };

  const globalsearch = (searchedVal) => {
    if (searchedVal === "Check In") {
      searchedVal = "CHECKED_IN";
    }
    const filteredRows = rows.filter((test) => {
      return (
        (test.visitor.firstName ? test.visitor.firstName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.visitor.lastName ? test.visitor.lastName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.visitor.phone ? test.visitor.phone.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.visitor.email ? test.visitor.email.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.scheduledStartDate ? test.scheduledStartDate.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.visitor.firstName} ${test.visitor.lastName}`.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const handleRejectVisit = (params) => {
    setOpenRejectInvitation(!openRejectInvitation);
    setRejectVisitId(params.id);
  };

  const handleApproveVisit = (params) => {
    setOpenApproveDialog(!openApproveDialog);
    setSelectedObject(params.row)
  };
  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...';
    } else {
      return text;
    }
  }

  const renderCellScheduledStartDate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: "300px",
            height: "300px",
            border: "1px solid #FFFF",
            fontSize: "100px",
            borderRadius: "50%",
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        ></Avatar>
      }
    />
  );

  const columns = React.useMemo(() => [
    {
      field: "visitor.profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params);
      },
    },
    {
      field: "visitor.firstName",
      headerName: t("COMMON014"),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName),
      renderCell: (params) => (
        <Tooltip title={` ${params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName)}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName))}</span>
        </Tooltip>
      ),
    },
    {
      field: "visitor.phone",
      headerName: t("COMMON063"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.phone,
    },
    {
      field: "visitor.email",
      headerName: t("PERSON005"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.visitor.email}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.visitor.email)}</span>
        </Tooltip>
      ),
    },
    {
      field: "scheduledStartDate",
      headerName: t("INVITEDATELBL"),
      flex: 1,
      width: 10,
      renderCell: renderCellScheduledStartDate(date)
    },
    {
      field: "status",
      headerName: t("COMMON001"),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />;
      },
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisitorProfileCard rowData={params.row} />}
          label={t("VIEW")}
        />,
        <GridActionsCellItem
          icon={<ApproveIcon />}
          label={t("buttonVisitorRegistrationApprove")}
          onClick={() => handleApproveVisit(params)}
        />,
        <GridActionsCellItem
          icon={<RejectIcon />}
          label={t("APPROVE003")}
          onClick={() => handleRejectVisit(params)}
        />,
      ],
    },
  ], [date]);


  return (
    <>
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        sx={{marginLeft: darkMode ? '-16px' : ''}}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {t("CLEARFILTER")}
            </Link>
          )}
        </Box>

        <Box item>
          <InvitationFilterForm
            rowsPerPage={rowsPerPage}
            selectedObject={filter.visitMode}
            status={status}
            applyFilter={(data) => handleFilter(data)}
            date={date}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openApproveDialog && (
        <ApprovalPageWalkIn
          selectedObject={selectedObject}
          open={openApproveDialog}
          handleClose={(data) => {
            setOpenApproveDialog(data);
            setSelectedObject([]);
          }}
          approve={() => setDel(true)}
        />
      )}

      {openRejectInvitation && (
        <RejectRegistartionInvitationDialog
          open={openRejectInvitation}
          handleClose={(data) => {
            setOpenRejectInvitation(data);
          }}
          disable={() => setDel(true)}
          RejectRegisterId={rejectVisitId}
        />
      )}
    </>)
}
