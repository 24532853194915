import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { callRefreshToken } from '../../slices/onboard/LoginSlice';
import { REACT_LOGIN } from '../../actions/EndPoints';

export default function RefreshToken() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  useEffect(() => {
    console.log('Start Init Refreshed token @' + store.user.tokenRefreshTimer);
    if (store.user.tokenRefreshTimer > 60000) {
      console.log('Initiated Refreshed token @' + store.user.tokenRefreshTimer);
      console.log('Initiated Refreshed token @' + new Date());
      setInterval(() => {
        refreshToken();
      }, store.user.tokenRefreshTimer);
    }
  }, [process.env.REACT_APP_ACCESS_TOKEN]);

  const refreshToken = async () => {
    dispatch(callRefreshToken())
  };
  return <></>;
}
