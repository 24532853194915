import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import androidDeviceService from "../../services/AndroidDevices/AndroidDeviceService";


const initialState=[];

export const addAndroidDevice = createAsyncThunk('android/create',async(payload)=>{
    const res = await androidDeviceService.addAndroidDevice(payload);
    return res.data;
});

export const getListOfAndroidDevice = createAsyncThunk('android/list',async(payload)=>{
    const res = await androidDeviceService.getListOfAndroidDevice(payload);
    return res.data;
});

export const upadteAndroidDeviceStatus = createAsyncThunk('android/update', async(payload)=>{
    const res = await androidDeviceService.upadteAndroidDeviceStatus(payload);
    return res.data;
});

export const apkUpdate = createAsyncThunk('android/update/APK', async(payload)=>{
    const res = await androidDeviceService.apkUpdate(payload);
    return res.data;
});

// const androidDeviceSlice = createSlice({
//     name:'android',
//     initialState,
//     extraReducers:{
//         [createAndroid.fulfilled]:(state,action)=>{
//             if(action.payload.code === ''){
//                 return action.payload.data;
//             }
//             else{
//                 return[];
//             }
//         }
//     }
// })

// androidDeviceSlice;
export default addAndroidDevice;