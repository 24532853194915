import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import HostService from '../services/HostService';

const initialState = [];

export const listHost = createAsyncThunk('host/retrieve', async (payload) => {
    const res = await HostService.get(payload);
    return res.data;
});

export const allHostList = createAsyncThunk('allHostList/retrieve', async (payload) => {
    const res = await HostService.allHostList(payload);
    return res.data;
});

const hostSlice = createSlice({
    name: 'host',
    initialState,
    extraReducers: {
        [listHost.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = hostSlice;
export default reducer;