import { Box, IconButton, Link, Tooltip } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import DataTable from '../../components/DataTable/DataTable'
import { useDispatch } from 'react-redux'
import HeaderToolbar from '../../components/HeaderToolbar'
import Search from '../../components/SearchTab/Search'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import AddIcon from '../../assets/USA_Images/Add_Access_Level.svg'
import {
  deleteVisitorAccessLevels,
  getVisitorAccessLevels,
} from '../../slices/VisitorAccessLevels/VisitorAccessLevelSlice'
import { REACT_MANAGE_ACCESSLEVELS } from '../../actions/EndPoints'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import AddAccessLevelDialog from './AddAccessLevelDialog'
import DeleteDialog from '../../components/DialogBoxComponent/DeleteDialog'
import Toast from '../../components/ToastContainer/CustomToast'
import EditIcon from '../Person/EditIcon'
import DoorIcon from './DoorIcon'
import { ReactComponent as Refresh } from '../../assets/DeviceIcon/Refresh.svg'
import ChecklistFilterForm from '../Checklist/ChecklistFilterForm'
import { getPurposeType } from '../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice'
import { ThemeContext } from '../../theme/ThemeContext';

function VisitingAccessLevels() {
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const [del, setDel] = useState(false)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const [clear, setClear] = useState(false)
  const [searchedRows, setSerchedRows] = useState([])
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [selectedObject, setSelectedObject] = useState({})
  const [pageSize, setPageSize] = useState(0)
  const { darkMode } = useContext(ThemeContext);

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })
  const [filter, setFilter] = useState({
    name: '',
    parentId: '',
  })

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const defaultFilter = {
    name: '',
    parentId: '',
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging, clear])

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage })
      setDel(false)
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false)
      }
    }
  }, [del])

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.timeSlotName
          ? test.timeSlotName.toLowerCase().includes(searchedVal.toLowerCase())
          : '')
      )
    })
    setRows(filteredRows)
  }

  const handleOpen = () => {
    setOpenEditDialog(true)
  }

  const handleClose = () => {
    setOpenEditDialog(false)
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const loadData = (filter, paging) => {
    dispatch(getVisitorAccessLevels(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'DMSI0000' && data.data) {
          setTotalRecords(data.data.totalCount)
          setRows(data.data.accessGroups)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  const handleFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      name: data.name,
    })
  }

  const [pagingg, setPagingg] = useState({
    page: 1,
    rowsPerPage: 10,
  })
  const [filterr, setFilterr] = React.useState({
    name: '',
  })

  const handleDelete = (data) => {
    let delPayLoad = {
      id: data.id,
      name: data.name,
    }
    dispatch(getPurposeType(Object.assign(filterr, pagingg)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          const purposeTypes = data.data.purposeTypes
          const isValuePresent = purposeTypes.some((obj) =>
            obj.accessLevelIds.includes(delPayLoad.id),
          )
          if (isValuePresent === false) {
            dispatch(deleteVisitorAccessLevels(delPayLoad))
              .unwrap()
              .then((data) => {
                if (data.code === 'DMSI0000') {
                  Toast(t('COMMON132'), 'success')
                  loadData(filter, paging)
                } else if (data.code === 'DMSE00542') {
                  Toast(t('ACCESS_LEVEL_MAPPED_DOOR'), 'error')
                } else
                  Toast(data.message, 'error')
              })
              .catch((er) => { })
          } else {
            Toast(t('ACCESS_LEVEL_ALREADY_MAPPED_PURPOSE_TYPE'), 'error')
          }
        }
      })
      .catch((er) => { })
  }

  const columns = React.useMemo(
    () => [
      {
        headerName: '',
        sortable: false,
        width: 5,
        renderCell: (params) => {
          return <div></div>
        },
      },
      {
        field: 'name',
        headerName: t('VISACCLEVELS002'),
        flex: 1.5,
        width: 10,
        valueGetter: (params) => params.row.name,
        renderCell: (params) => (
          <Tooltip title={` ${params.row.name}`} placement="bottom-start">
            <span>{params.row.name}</span>
          </Tooltip>
        ),
      },
      {
        field: 'timeSlot',
        headerName: t('ADD025'),
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.timeSlotName,
        renderCell: (params) => (
          <Tooltip
            title={` ${params.row.timeSlotName}`}
            placement="bottom-start"
          >
            <span>{params.row.timeSlotName}</span>
          </Tooltip>
        ),
      },
      {
        field: 'Actions',
        headerName: t('ACTION'),
        type: 'actions',
        flex: 1,
        width: 10,
        headerAlign: 'center',
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DoorIcon />}
            onClick={() => {
              history.push({
                pathname: REACT_MANAGE_ACCESSLEVELS,
                state: { data: params.row },
              })
            }}
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => {
              setSelectedObject({
                id: params.row.id,
                name: params.row.name,
                schedulerId: params.row.schedulerId,
                edit: true,
              })
              handleOpen()
            }}
          />,
          <GridActionsCellItem
            icon={
              <DeleteDialog
                handleDelete={() => handleDelete(params.row)}
                deviceName={params.row.name}
                name={'Access Level'}
                text={'Are you sure you want to delete this '}
              />
            }
            label="Delete"
          />,
        ],
      },
    ],
    [],
  )

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <>
        <HeaderToolbar
          src={AddIcon}
          onClick={handleOpen}
          title= {t('Visiting Access Level')}
          tooltipTitle={t('VISAPPROVEFILTER002')}
        />
      </>

      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{ marginLeft: darkMode ? '-15px' : '0' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {'Clear Filter'}
            </Link>
          )}
        </Box>

        <Box item>
          <ChecklistFilterForm
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box
          style={{ textAlign: '-webkit-right', padding: '5px', margin: '5px' }}
        >
          <Tooltip title={t('COMMON002')}>
            <IconButton onClick={() => loadData(filter, paging)}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          getRowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          count={totalRecords}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {openEditDialog ? (
        <AddAccessLevelDialog
          open={openEditDialog}
          handleDialogClose={handleClose}
          selectedObject={selectedObject}
          updateSelectedObject={() => {
            setSelectedObject({})
          }}
          loadData={() => {
            loadData(filter, paging)
          }}
        />
      ) : null}
    </>
  )
}

export default VisitingAccessLevels
