import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Link,
  Switch,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core'
import DataTable from '../DataTable/DataTable'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DialogDataTable from '../DataTable/DialogDataTable'
import { useDispatch } from 'react-redux'
import { getPurchasedPlans } from '../../slices/Subscription/SubscriptionSlice'
import moment from 'moment'
import CommonUtil from '../../Util/CommonUtils'
import { ButtonBase, Grid, IconButton, Stack } from '@mui/material'
import CancelButtons from './CancelButtons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import CloseIcon from '@mui/icons-material/Close'
import SubscriptionManagement from '../../Pages/Dealer/SubscriptionManagement'

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#0F5A9C !important',
    opacity: 1,
  },
}))

const useStyles = makeStyles((theme) => ({
  purchaseDialog: {
    '& .MuiDialog-paper': {
      maxWidth: '900px',
      marginLeft: '150px',
    },
  },
}))

export default function PlanPurchaseDialogBox(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [rows, setRows] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const history = useHistory()

  const [filter, setFilter] = useState({
    subscriptionName: '',
    planAssigned: '',
  })
  const [paging, setPaging] = useState({
    pageNumber: 1,
    pageSize: 10,
  })

  const defaultFilter = {
    subscriptionName: '',
    planAssigned: '',
  }

  const onCancel = () => {
    props.handleClose()
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging])

  const loadData = (filter, paging) => {
    let payload = {
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
      subscriptionName: '',
      planAssigned: false,
    }
    dispatch(getPurchasedPlans(payload))
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4035') {
          setRows(res.data.purchasedPlans)
          setTotalPages(res.data.totalPages)
          setTotalRecords(res.data.totalCount)
          setPage(res.data.currentPage)
        } else {
          setRows([])
        }
      })
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={true}
        disable
        PaperProps={{
          style: {
            borderRadius: '20px !important',
          },
        }}
        className={classes.purchaseDialog}
      >
        <DialogTitle variant="h4" onClick={props.handleClose}>
          <IconButton
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            textAlign: 'center',
            fontSize: '14px',
          }}
        >
          <SubscriptionManagement
            purchasePlanDialog={true}
            handlePurchasedPlan={props.handlePurchasedPlan}
          />
          <DialogActions
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <CancelButtons
              variant="contained"
              color="secondary"
              onClick={onCancel}
            >
              <Typography style={{ textTransform: 'none' }}>
                {t('COMMON008')}
              </Typography>
            </CancelButtons>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
