import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
    
 let query = "?isPagable=true&sort=createdAt%7Cdesc&roleScope=APPLICATION&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage + "&roleScope=" + filter.roleScope;

    if (!CommonUtil.isEmptyString(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    return query 
};

const getAllUserRoles = (filter) => {
    return api.securedAxios().get('/web/roleScope' + buildQuery(filter));
}

const createUserRoles = (payload) => {
    return api.securedAxios().post('/web/role/create_user_role', payload);
}

const updateUserRoles = (payload) => {
    return api.securedAxios().put('/web/role/update/' + payload.id, payload);
}

const deleteUserRoles = (roleId) => {
    return api.securedAxios().delete("/web/role/" + roleId)
}

const getPermissionsList = () => {
    return api.securedAxios().get('/web/role/permissions');
}

const getPermissionsListByroleId = (payload) => {
    return api.securedAxios().get('/web/role/'+payload+"/permissions");
}
const roleAssign = (payload) => {
        return api.securedAxios().post("/web/role/assign_role", payload);
  }

const userList = (payload) => {
    return api.securedAxios().post('/web/role/user' + buildQuery(payload));
}

const listQuery = (payload) =>  {
const query = "?roleScope=" + payload.roleScope + "&name=" + payload.name
return query;
 }

const listofRoles = (payload) => {
    return api.securedAxios().get("/web/role/list" +   listQuery(payload) )
}

const getMemberShipByUserId = (payload) => {
    return api.securedAxios().get("/api/v3/user/" + payload.userId)
  }

const userRoleService = {
    getAllUserRoles,
    createUserRoles,
    updateUserRoles,
    deleteUserRoles,
    getPermissionsList,
    roleAssign,
    getPermissionsListByroleId,
    userList,
    listofRoles,
    getMemberShipByUserId
}

export default userRoleService;