import { Box } from '@material-ui/core'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React from 'react'
import HeaderToolbar from '../../components/HeaderToolbar'
import InvitationWalkInRegistrationApprovals from '../Approvals/InvitationWalkInRegistrationApprovals'
import CustomersIcon from '../Customer/CustomersIcon'
import DealerIcon from './DealerIcon'
import DealerTransaction from './DealerTransaction'
import CustomersTransaction from '../Customer/CustomersTransaction'
import { useTranslation } from 'react-i18next'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return <div {...other}>{value === index && <Box pt={2}>{children}</Box>}</div>
}

function AdminTransactions(props) {
  const [value, setValue] = React.useState(props.location.state ? props.location.state.value : 0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const { t } = useTranslation()
  return (
    <>
      <HeaderToolbar title={t('CUSTDATABLE11')} />
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        style={{
          position: 'absolute',
          height: '20px',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Tab
          style={{
            borderRight: 'solid #00000029',
            width: '200px',
            fontSize: '18px',
            textTransform: 'none',
            color: value === 0 ? '#F2824C' : '#B7B6B3',
            backgroundColor: value === 0 ? '#ffffff' : 'transparent',
          }}
          icon={<DealerIcon fill={value === 0 ? '#F2824C' : '#B7B6B3'} />}
          iconPosition="start"
          label={t('DASHBOARDTABDEALER')}
        />
        <Tab
          style={{
            borderRight: 'solid #00000029',
            width: '200px',
            fontSize: '18px',
            textTransform: 'none',
            color: value === 1 ? '#F2824C' : '#B7B6B3',
            backgroundColor: value === 1 ? '#ffffff' : 'transparent',
          }}
          icon={<CustomersIcon fill={value === 1 ? '#F2824C' : '#B7B6B3'} />}
          iconPosition="start"
          label={t('DASHBOARDTABCUSTOMER')}
        />
      </Tabs>
      {value === 0 && (
        <TabPanel value={value} index={value}>
          <DealerTransaction />
        </TabPanel>
      )}

      {value === 1 && (
        <TabPanel value={value} index={value}>
          <CustomersTransaction />
        </TabPanel>
      )}
    </>
  )
}

export default AdminTransactions
