import React from 'react'
import { Paper, Typography } from '@mui/material'
import { Grid, Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import CustomDropdown from '../../components/CustomInputs/CustomDropdown'
import { useState, useEffect } from 'react'
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker'
import CommonUtil from '../../Util/CommonUtils'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import UploadImg from '../../Pages/Registrations/UploadImg'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function DealerDetails1() {
  const [gender, setGender] = React.useState([])
  const [value, setValue] = React.useState(dayjs('2022-04-07'))
  const [company, setCompany] = useState({
    dateOfBirth: CommonUtil.formatDayAndMonth(),
    dateOfJoining: CommonUtil.formatDayAndMonth,
    addressLine1: '',
    addressLine2: '',
    country: '',
    state: '',
    city: '',
    postCode: '',
  })
  const [countries, setCountries] = React.useState([])
  const [states, setStates] = React.useState([])
  const [city, setCity] = React.useState([])

  const gender1 = [
    {
      id: 'M',
      name: 'Male',
      value: 'M',
    },
    {
      id: 'F',
      name: 'Female',
      value: 'F',
    },
    {
      id: 'O',
      name: 'Other',
      value: 'O',
    },
  ]

  const countryData = [
    {
      id: '1',
      name: 'India',
      value: '91',
    },
    {
      id: '2',
      name: 'United States',
      value: '1',
    },
    {
      id: '3',
      name: 'China',
      value: '86',
    },
    {
      id: '4',
      name: 'Bangladesh',
      value: '880',
    },
    {
      id: '5',
      name: 'South Africa',
      value: '27',
    },
    {
      id: '6',
      name: 'Indonesia',
      value: '62',
    },
    {
      id: '7',
      name: 'Australia',
      value: '61',
    },
    {
      id: '8',
      name: 'Brazil',
      value: '55',
    },
    {
      id: '9',
      name: 'France',
      value: '33',
    },
    {
      id: '10',
      name: 'Germany',
      value: '49',
    },
    {
      id: '11',
      name: 'Italy',
      value: '39',
    },
    {
      id: '12',
      name: 'Japan',
      value: '81',
    },
  ]

  const stateData = [
    {
      id: '1',
      name: 'Arunachal Pradesh',
    },
    {
      id: '2',
      name: 'Andhra Pradesh',
    },
    {
      id: '3',
      name: 'Assam',
    },
    {
      id: '4',
      name: 'Bihar',
    },
    {
      id: '5',
      name: 'Chattisgrah',
    },
    {
      id: '6',
      name: 'Goa',
    },
    {
      id: '7',
      name: 'Gujrath',
    },
    {
      id: '8',
      name: 'Harayana',
    },
    {
      id: '9',
      name: 'Himarchal Pradesh',
    },
    {
      id: '10',
      name: 'Jharkand',
    },
    {
      id: '11',
      name: 'Karnataka',
    },
    {
      id: '12',
      name: 'Kerala',
    },
    {
      id: '13',
      name: 'Madhya Pradesh',
    },
    {
      id: '14',
      name: 'Maharastra',
    },
    {
      id: '15',
      name: 'Manipur',
    },
    {
      id: '16',
      name: 'Meghalaya',
    },
    {
      id: '17',
      name: 'Mizoram',
    },

    {
      id: '18',
      name: 'Nagaland',
    },
    {
      id: '19',
      name: 'Odissa',
    },
    {
      id: '20',
      name: 'Punjab',
    },
    {
      id: '21',
      name: 'Rajasthan',
    },
    {
      id: '22',
      name: 'Sikkim',
    },
    {
      id: '23',
      name: 'Tamil Nadu',
    },
    {
      id: '24',
      name: 'Telangana',
    },
    {
      id: '25',
      name: 'Tripura',
    },
    {
      id: '26',
      name: 'Uttar Pradesh',
    },
    {
      id: '27',
      name: 'Uttarkhand',
    },
    {
      id: '28',
      name: 'West Bengal',
    },
  ]

  const cityData = [
    {
      id: '1',
      name: 'Bengaluru',
    },
    {
      id: '2',
      name: 'Mysore',
    },
    {
      id: '3',
      name: 'Malleshwaram',
    },
    {
      id: '4',
      name: 'VasanthNagar',
    },
    {
      id: '5',
      name: 'Indranagar',
    },
    {
      id: '6',
      name: 'ShivajiNagar',
    },
  ]

  useEffect(() => {
    setCountries(countryData)
    setStates(stateData)
    setCity(cityData)
    setGender(gender1)
  }, [])

  const handleChange = (event) => {
    const name = event.target.name
    setCompany({
      ...company,
      [name]: event.target.value,
    })
  }

  const commonStyles = {
    height: '280px',
    width: '280px',
    backgroundColor: '#FFFFFF',
    opacity: 1,
  }
  return (
    <>
      <Paper sx={{ backgroundColor: '#F2F7FF' }}>
        {/* <Paper sx={{opacity:"1"}}> */}

        {/* <Paper elevation={5} sx={{width:"70px" , height:"970px" , opacity:"1" }}></Paper>  */}

        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // marginLeft:"121px",
            // marginTop:"103px"
          }}
        >
          <Box sx={{ m: 2 }}>
            <Typography gutterBottom variant="h3" sx={{ opacity: 1 }}>
              &lt; User Profile
            </Typography>
          </Box>
        </Grid>

        <Stack direction="row" spacing={2}>
          {/* <Stack direction="row" spacing={2}>   sx={{ml:"115px",mt:"25px"}}*/}
          {/* <Paper elevation={5} sx={{width:"70px" , height:"970px" , opacity:"1" }}></Paper>  */}
          <Paper
            elevation={8}
            sx={{
              height: '890px',
              width: '450px',
              opacity: '1',
              borderRadius: '10px',
            }}
          >
            {/* <Grid sx={{height:"280px" ,width:"280px" , marginTop:"266px",marginLeft:"85px" , borderRadius:"50%" , border: '1px dashed grey'}}> */}
            <Grid
              sx={{
                height: '280px',
                width: '280px',
                marginTop: '266px',
                marginLeft: '85px',
                bgcolor: 'white',
              }}
            >
              <UploadImg />
            </Grid>
          </Paper>

          <Paper
            elevation={8}
            sx={{
              height: '890px',
              width: '1310px',
              opacity: '1',
              borderRadius: '10px',
            }}
          >
            <Grid
              container
              columnSpacing={3}
              p={3}
              rowSpacing={1.5}
              sx={{ mt: '5px' }}
            >
              <Grid item xl={6} xs={3} lg={6} md={6} sm={12}>
                <CustomTextfeild
                  label={'First Name'}
                  required={true}
                  defaultValue="Julia"
                />
              </Grid>

              <Grid item xl={6} xs={3} lg={6} md={6} sm={12}>
                <CustomTextfeild label={'Last Name'} defaultValue="Roberts" />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12}>
                <CustomTextfeild label={'User Id'} defaultValue="123456" />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '20px' }}>
                <CustomDropdown
                  data={gender}
                  label={'Gender'}
                  defaultValue="Male"
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12}>
                <CustomTextfeild
                  label={'Email'}
                  required={true}
                  validation="email"
                  defaultValue="rberts@zkteco.in"
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12}>
                <CustomTextfeild
                  label={'Mobile'}
                  required={true}
                  validation="numeric"
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12}>
                {/* <CustomDateTimePicker
                    onChange={(dateOfBirth) =>
                      setCompany({
                        ...company,
                        dateOfBirth: dateOfBirth,
                      })
                    }
                    // value={company.dateOfBirth}
                    label={"Date of Birth"}
              
                  /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={['day', 'month', 'year']}
                    label="Date of Birth"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={null}
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12}>
                {/* <CustomDateTimePicker
                    onChange={(dateOfJoining) =>
                      setCompany({
                        ...company,
                        dateOfJoining: dateOfJoining,
                      })
                    }
                    // value={company.dateOfJoining}
                    label={"Date of Joining"}

                  /> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={['day', 'month', 'year']}
                    label="Date of Joining"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={null}
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '20px' }}>
                <CustomDropdown
                  data={countries}
                  label="Country"
                  name="country"
                  value={company.country}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '20px' }}>
                <CustomDropdown
                  data={states}
                  label={'State'}
                  name={'state'}
                  value={company.state}
                  validation="alpha-numeric"
                  handleChange={handleChange}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '20px' }}>
                <CustomDropdown
                  data={city}
                  label={'City'}
                  name={'city'}
                  value={company.city}
                  validation="alphabets"
                  handleChange={handleChange}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12}>
                <CustomTextfeild
                  label={'Address Line 1'}
                  name={'addressLine1'}
                  value={company.addressLine1}
                  validation="alphabets"
                  handleChange={handleChange}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12}>
                <CustomTextfeild
                  label={'Address Line 2'}
                  name={'addressLine2'}
                  value={company.addressLine2}
                  validation="alphabets"
                  handleChange={handleChange}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12}>
                <CustomTextfeild
                  label={'Zip Code'}
                  name={'postCode'}
                  value={company.postCode}
                  validation="numeric"
                  handleChange={handleChange}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>

              <Grid container lg={4}></Grid>
              <Grid
                container
                lg={8}
                justifyContent="flex-end"
                sx={{ mt: '70px' }}
              >
                <Grid pr={2}>
                  <CancelButtons variant="contained" color="secondary">
                    <Typography style={{ textTransform: 'none' }}>
                      {'Cancel'}
                    </Typography>
                  </CancelButtons>
                </Grid>

                <Grid pr={2}>
                  <SubmitButtons variant="contained">
                    <Typography>{'Submit'}</Typography>
                  </SubmitButtons>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Stack>
      </Paper>
      {/* </Stack>            */}

      {/* </Paper>  */}
    </>
  )
}

export default DealerDetails1
