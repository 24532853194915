import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BlockRequestsRejectService from "../../services/Approvals/BlockRequestsRejectService";

const initialState = [];

export const rejectVisitorInvite = createAsyncThunk(
  "rejectVisitor/retrieve",
  async (payload) => {
    const res = await BlockRequestsRejectService.reject(payload);
    return res.data;
  }
);

const rejectVisitorSlice = createSlice({
  name: "rejectVisitor",
  ...initialState,
  extraReducers: {
    [rejectVisitorInvite.fulfilled]: (state, action) => {
      if (action.payload.code === "") {
        return action.payload.data;
      } else {
        return [];
      }
    },
  },
});

const { reducer } = rejectVisitorSlice;

export default reducer;
