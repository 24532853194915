import * as React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ThemeContext } from '../../theme/ThemeContext';
import {  useContext } from "react";
import moreIcon from '../../assets/USA_Images/login_images_visitor/moreIcon.svg'

export default function MoreOption(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [val, setval] = React.useState('');
  const { darkMode } = useContext(ThemeContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (option) => {
    setval(option);
    props.handleSelect(option);
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        disableRipple
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        {darkMode ? (
          <img src={moreIcon} alt='More options' style={{ width: 24, height: 24 }} />
        ) : (
          <MoreVertIcon />
        )}
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 58 * 4.5,
            width: '31ch',
            backgroundColor: darkMode ? '#0F273B' : '',
            color: darkMode ? '#fff' : ''
          },
        }}
      >
        {props.data.map((option, index) => (
          <MenuItem key={index} onClick={() => handleChange(option.index)} selected={option === val.index}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
