import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VisitorAccessLevelService } from "../../services/VisitorAccessLevel/VisitorAccessLevelService";

const initialState = {
  DoorsByAccessLevelId: {},
};
export const getVisitorAccessLevels = createAsyncThunk(
  "visitorAccessLevel/list",
  async (payload) => {
    const res = await VisitorAccessLevelService.getVisitorAccessLevels(payload);
    return res.data;
  }
);

export const getVisitorAccessLevelsByPurposeId = createAsyncThunk(
  "visitorAccessLevel/list/purposeid",
  async (payload) => {
    const res = await VisitorAccessLevelService.getVisitorAccessLevelsByPurposeId(
      payload
    );
    return res.data;
  }
);

export const createVisitorAccessLevels = createAsyncThunk(
  "visitorAccessLevel/add",
  async (payload) => {
    const res = await VisitorAccessLevelService.createVisitorAccessLEvels(
      payload
    );
    return res.data;
  }
);

export const updateVisitorAccessLevels = createAsyncThunk(
  "visitorAccessLevel/edit",
  async (payload) => {
    const res = await VisitorAccessLevelService.updateVisitorAccessLEvels(
      payload
    );
    return res.data;
  }
);

export const deleteVisitorAccessLevels = createAsyncThunk(
  "visitorAccessLevel/delete",
  async (payload) => {
    const res = await VisitorAccessLevelService.deleteVisitorAccessLEvels(
      payload
    );
    return res.data;
  }
);

export const listDoorsByAccessLevelId = createAsyncThunk(
  "visitorAccessLevelDoors/get",
  async (payload) => {
    const res = await VisitorAccessLevelService.listDoorsByAccessLevelId(
      payload
    );
    return res.data;
  }
);

export const DeleteDoorFromAccessLevel = createAsyncThunk(
  "doorFromAccessLevel/delete",
  async (payload) => {
    const res = await VisitorAccessLevelService.listDoorsByAccessLevelId(
      payload
    );
    return res.data;
  }
);

export const deleteDoorsFromAccessLevel = createAsyncThunk(
  "visitorAccessLevelDoors/delete",
  async (payload) => {
    const res = await VisitorAccessLevelService.deleteDoorsFromAccessLevel(
      payload
    );
    return res.data;
  }
);
export const addDoorsFromAccessLevel = createAsyncThunk(
  "visitorAccessLevelDoors/add",
  async (payload) => {
    const res = await VisitorAccessLevelService.addDoorsFromAccessLevel(
      payload
    );
    return res.data;
  }
);

export const updateDoor = createAsyncThunk(
  "visitorAccessLevelDoors/update",
  async (payload) => {
    const res = await VisitorAccessLevelService.updateDoor(payload);
    return res.data;
  }
);

export const updateDoorName = createAsyncThunk(
  "visitorAccessLevelDoors/update",
  async (payload) => {
    const res = await VisitorAccessLevelService.updateDoorName(payload);
    return res.data;
  }
);

const visitorAccessLevelSlice = createSlice({
  name: "visitor_Access_Level",
  initialState,
  reducers: {},
  extraReducers: {
    [listDoorsByAccessLevelId.fulfilled]: (state, action) => {
      state.DoorsByAccessLevelId = action.payload.data;
    },
  },
});

const { reducer } = visitorAccessLevelSlice;
export default reducer;
