import React, { useState, useContext } from "react";
import { Grid, styled, TextField, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CalenderIcon from '../../assets/Calender';
import Toast from "../../components/ToastContainer/CustomToast";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { createTheme } from "@mui/material/styles";
import { es, enUS } from 'date-fns/locale';
import { useTranslation } from "react-i18next";
import { ThemeContext } from '../../theme/ThemeContext';

// Custom theme with dynamic darkMode
const MuiTheme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? "dark" : "light", // Change to mode
    primary: {
      main: process.env.REACT_APP_BG_ICON,
    },
    secondary: {
      main: "#f50057",
    },
  },
});

// Styled components for icons
const CustomIconLeft = styled(ChevronLeftIcon)(({ theme }) => ({
  color: process.env.REACT_APP_BG_ICON,
  border: process.env.REACT_APP_BG_BORDER,
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: process.env.REACT_APP_BG_ICON,
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(({ theme }) => ({
  color: process.env.REACT_APP_BG_ICON,
  border: process.env.REACT_APP_BG_BORDER,
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: process.env.REACT_APP_BG_ICON,
    color: "#FFFF",
  },
}));

// Styled TextField with dynamic darkMode
const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'darkMode',
})(({ theme, darkMode }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: darkMode ? '#DDDDDD' : '#b3adad', // Default border color for the fieldset
      borderWidth: '1px', // Set border width to 1px
    },
    '&:hover fieldset': {
      borderColor: '#2286AB', // Border color when hovered
    },
    '&.Mui-focused fieldset': {
      borderColor: darkMode ? '#fff' : '#0F273B', // Border color when focused
    },
    '&.Mui-disabled fieldset': {
      borderColor: darkMode ? '#fff' : 'rgba(0, 0, 0, 0.38)', // Border color when disabled
    },
    '&.Mui-disabled': {
      opacity: 1,
      '-webkit-text-fill-color': darkMode ? '#fff' : 'rgba(0, 0, 0, 0.38)', // Disabled text color
    },
  },
  '& .MuiInputLabel-root': {
    color: darkMode ? 'rgba(255, 255, 255, 0.6)' : '#707070', // Label color
  },
  '& .MuiFormLabel-root': {
    fontSize: '0.875rem',
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    color: darkMode ? '#fff' : '#0F273B', // Default text color
  },
  '& .MuiInputBase-input.Mui-disabled': {
    opacity: 1,
    '-webkit-text-fill-color': darkMode ? '#fff' : 'rgba(0, 0, 0, 0.38)', // Disabled text color fix
  },
}));

// Component function
const CustomDateTimePicker = (props) => {
  const { darkMode } = useContext(ThemeContext); // Get darkMode from context
  const [dateOpen, setDateOpen] = useState(false);
  const { t } = useTranslation();
  const [scheduledStartDate, setScheduledStartDate] = React.useState(new Date());

  const handleDate = (e) => {
    setScheduledStartDate(e);
    props.SelectedDate(e);
  };

  const onKeyDown = (e) => {
    e.preventDefault();
    Toast(t("INVITESTARTDATEERROR3"), "error");
  };

  const theme = MuiTheme(darkMode); // Create theme based on darkMode

  // Inline styles for label color
  const labelStyle = {
    color: darkMode ? '#fff' : '#0000008a',
  };

  return (  
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={props.label.includes("Fecha de inicio") || props.label.includes("Fecha de finalización") ? es : enUS}>
        <DesktopDateTimePicker
          disabled={props.disabled}
          hideTabs={false}
          components={{
            OpenPickerIcon: CalenderIcon,
            LeftArrowIcon: CustomIconLeft,
            RightArrowIcon: CustomIconRight,
          }}
          dateRangeIcon={<CalenderIcon />}
          label={props.label}
          disablePast={props.disablePast}
          disableFuture={props.disableFuture}
          onChange={props.onChange}
          onChangeText={props.onChangeText}
          inputFormat={props.date ? props.date : "MM-dd-yyyy HH:mm"}
          value={props.value}
          minDateTime={props.minDateTime}
          type="datetime-local"
          InputProps={{
            classes: {
              root: 'cssOutlinedInput', // Use class name directly if necessary
              focused: 'cssFocused',
              notchedOutline: 'notchedOutline',
            },
          }}
          renderInput={(params) => (
            <StyledTextField
              onKeyDown={onKeyDown}
              style={{ width: '-webkit-fill-available' }}
              {...params}
              InputLabelProps={{
                style: labelStyle, // Apply dynamic label color
              }}
              darkMode={darkMode} // Pass darkMode prop to StyledTextField
            />
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CustomDateTimePicker;
