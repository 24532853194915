import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Card, CardContent, Box } from "@material-ui/core";
import check_in from "../../assets/USA_Images/dashboard_icons/Check-In.svg";
import vis_check_in from "../../assets/images/dashboard-icons/Check-In.svg";
import checkout from "../../assets/USA_Images/dashboard_icons/Check-Out.svg";
import vis_checkout from "../../assets/images/dashboard-icons/Check-Out.svg";
import selfregistartion from "../../assets/USA_Images/dashboard_icons/selfregistration.svg";
import vis_selfregistartion from "../../assets/images/dashboard-icons/Registration.svg";
import registartion from '../../assets/USA_Images/dashboard_icons/dashregistration.svg'
import vis_registartion from "../../assets/images/dashboard-icons/SystemIcon.svg";
import Invitation from "../../assets/USA_Images/dashboard_icons/Invitations.svg";
import vis_Invitation from "../../assets/images/dashboard-icons/Invitations.svg";
import Confirmed from "../../assets/USA_Images/dashboard_icons/Confirmed.svg";
import vis_Confirmed from "../../assets/images/dashboard-icons/Confirmed.svg";
import DashboardTiles from "../DashBoard/DashboardTiles";
import { ThemeContext } from '../../theme/ThemeContext';

function DashboardCards(props) {
  const { t } = useTranslation();
  const { darkMode } = useContext(ThemeContext);

  const invitation = () => {
    props.getRowData("Invitation")
  };
  const checkin = () => {
    props.getRowData("Checkin")
  };
  const checkOut = () => {
    props.getRowData("checkOut")

  };
  const selfRegistration = () => {
    props.getRowData("selfRegistration")
  };
  const registration = () => {
    props.getRowData("registration")
  };

  const confirmed = () => {
    props.getRowData("confirmed")
  };


  return (
    <>
      <Grid container spacing={3} style={{ marginLeft: darkMode ? '23px' : '-10px', width: darkMode ? '98%' : '101%' }}>
        <DashboardTiles>
          <Card onClick={checkin}
            className="card mb-4" style={{
              height: '120px',
              backgroundColor: darkMode ? '#0B2E4D' : 'inherit'
            }}>
            <CardContent className="p-4">
              <div className="align-box-row align-items-start">
                <div className="font-weight-bold">
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    // color="textSecondary"
                    style={{ color: darkMode ? 'white' : 'rgb(61, 73, 119)' }}
                  >
                    {t("commonsCheckIn")}
                  </Typography>
                  <span
                    className="font-size-xxl mt-2"
                    style={{
                      color: darkMode ? 'white' : '#3D4977'
                      , fontSize: "25px"
                    }}
                  >
                    {props.data.checkInVisitors}
                  </span>
                </div>
                <div className="ml-auto">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? check_in : vis_check_in}
                    width="60px"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </DashboardTiles>
        <DashboardTiles>
          <Card style={{ height: '120px', backgroundColor: darkMode ? '#0B2E4D' : 'inherit' }}
            onClick={checkOut}
            className="card mb-4">
            <CardContent className="p-4">
              <div className="align-box-row align-items-start">
                <div className="font-weight-bold">
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    // color="textSecondary"
                    style={{ color: darkMode ? 'white' : 'rgb(61, 73, 119)' }}
                  >
                    {t("commonsCheckOut")}
                  </Typography>
                  <span
                    className="font-size-xxl mt-2"
                    style={{ color: darkMode ? 'white' : '#3D4977', fontSize: "25px" }}
                  >
                    {props.data.checkOutVisitors}
                  </span>
                </div>
                <div className="ml-auto">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? checkout : vis_checkout}
                    width="50px"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </DashboardTiles>
        <DashboardTiles>
          <Card style={{ height: '120px', backgroundColor: darkMode ? '#0B2E4D' : 'inherit' }}
            onClick={selfRegistration}
            className='card mb-4' >
            <CardContent className='p-4'>
              <div className='align-box-row align-items-start'>
                <div className='font-weight-bold'>
                  <Typography gutterBottom variant='h6' component='h2'
                    // color='textSecondary'
                    style={{ color: darkMode ? 'white' : 'rgb(61, 73, 119)' }}
                  >
                    {t("APPROVALITEMS002")}
                  </Typography>
                  <span className='font-size-xxl mt-2' style={{ color: darkMode ? 'white' : '#3D4977', fontSize: '25px' }}>
                    {props.data.selfRegistartions}
                  </span>
                </div>
                <div className='ml-auto'>
                  <img alt='...' className='img-fluid' src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? selfregistartion : vis_selfregistartion} width="40px" />
                </div>
              </div>
            </CardContent>
          </Card>
        </DashboardTiles>
        <DashboardTiles>
          <Card style={{ height: '120px', backgroundColor: darkMode ? '#0B2E4D' : 'inherit' }}
            onClick={registration}
            className='card mb-4' >
            <CardContent className='p-4'>
              <div className='align-box-row align-items-start'>
                <div className='font-weight-bold'>
                  <Typography gutterBottom variant='h6' component='h2'
                    // color='textSecondary'
                    style={{ color: darkMode ? 'white' : 'rgb(61, 73, 119)' }}
                  >
                    {process.env.REACT_APP_ENVIRONMENT === 'USA' ? t("labelDashBoardTotalWalkInRegistration") : "Registrations"}
                  </Typography>
                  <span
                    className="font-size-xxl mt-2"
                    style={{ color: darkMode ? 'white' : '#3D4977', fontSize: "25px" }}
                  >
                    {props.data.walkinRegistartions}
                  </span>
                </div>
                <div className="ml-auto">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? registartion : vis_registartion}
                    width="40px"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </DashboardTiles>
        <DashboardTiles>
          <Card style={{ height: '120px', backgroundColor: darkMode ? '#0B2E4D' : 'inherit' }}
            onClick={invitation}
            className="card mb-4">
            <CardContent className="p-4">
              <div className="align-box-row align-items-start">
                <div className="font-weight-bold">
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    // color="textSecondary"
                    style={{ color: darkMode ? 'white' : 'rgb(61, 73, 119)' }}
                  >
                    {t("labelDashBoardTotalReschedule")}
                  </Typography>
                  <span
                    className="font-size-xxl mt-2"
                    style={{ color: darkMode ? 'white' : '#3D4977', fontSize: "25px" }}
                  >
                    {props.data.invitations}
                  </span>
                </div>
                <div className="ml-auto">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? Invitation : vis_Invitation}
                    width="40px"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </DashboardTiles>
        <DashboardTiles>
          <Card style={{ height: '120px', backgroundColor: darkMode ? '#0B2E4D' : 'inherit' }}
            onClick={confirmed}
            className="card mb-4">
            <CardContent className="p-4">
              <div className="align-box-row align-items-start">
                <div className="font-weight-bold">
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    // color="textSecondary"
                    style={{ color: darkMode ? 'white' : 'rgb(61, 73, 119)' }}
                  >
                    {t("labelDashBoardTotalWaitingForApproval")}
                  </Typography>
                  <span
                    className="font-size-xxl mt-2"
                    style={{ color: darkMode ? 'white' : '#3D4977', fontSize: "25px" }}
                  >
                    {props.data.confirmedVisitors}
                  </span>
                </div>
                <div className="ml-auto">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? Confirmed : vis_Confirmed}
                    width="45px"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </DashboardTiles>
      </Grid>
    </>
  );
}
export default DashboardCards;
