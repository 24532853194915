import React, { useEffect, useState } from 'react'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import iot from '../../assets/MIOTLogo/miotlogo.png'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import './custom.css'
import { useHistory, withRouter } from 'react-router-dom'
import CommonUtil from '../../components/Util/CommonUtils'
import LoginLayoutV2 from './LoginLayoutV2'
import jwt_decode from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import visLogo from '../../assets/images/onboard/vis-logo.png'
import USA_visLogo from '../../assets/USA_Images/login_images/App-logo.svg'
import { authorizeUser } from '../../slices/onboard/LoginSlice'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Toast from '../../components/ToastContainer/CustomToast'
import { ReactComponent as UserIcon } from '../../assets/UserIcon.svg'
import { ReactComponent as USAUserIcon } from '../../assets/USA_Images/login_images/userIcon.svg'
import mysmgLogo from '../../../src/assets/USA_Images/user_profile/mysmgLogo.svg'
import { ReactComponent as PassIcon } from '../../assets/passIcon.svg'
import { ReactComponent as USAPassIcon } from '../../assets/USA_Images/login_images/pass_icon.svg'
import Button from '@mui/material/Button'
import { Checkbox, Dialog, DialogTitle } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import USACustomTextfield from '../../components/CustomInputs/USACustomTextfield'
import {
  generateAuthenticationCode,
  getLoginAuthenticationDetails,
} from '../../slices/LoginAuthentication/LoginAuthenticationSlice'
import { REACT_LOGIN_AUTHENTICATE } from '../../actions/EndPoints'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '70%',
    marginTop: theme.spacing(3),
  },
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#fff !important',
    },
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props
  return (
    <DialogTitle variant="h4" {...other}>
      {children}
      {onClose ? (
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 3,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

function LoginFormV2(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [userAgreement, setUserAgreement] = React.useState(false);
  const [init, setInit] = useState({ userAgreement: true, termsCondition: true });
  const history = useHistory()

  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
  })

  const [error, setError] = useState({
    email: false,
    password: false,
  })
  const store = useSelector((state) => state)

  const query = new URLSearchParams(props.location.search)

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.replace('/vis/login')
      }
    }
  }, [history])

  const handleChange = (event) => {
    const value = event.target.value
    const name = event.target.name

    setValues({
      ...values,
      [name]: value,
    })
    setError({
      [name]: false,
    })
  }

  const handleClickShowPassword = (event) => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const passwordToHexa = (password) => {
    let hashPassword = ''
    for (let i = 0; i <= password.length; i++) {
      const passowrdHexaValue = password.charCodeAt(i).toString(16)
      // Pad with zero's to ensure two-digit representation
      hashPassword += passowrdHexaValue.padStart(2, '0')
    }
    return hashPassword
  }

  const checkLoginAuthentication = (authObject) => {
    var cookies = document.cookie.split(';')
    let secretKey = ''
    cookies.forEach((cookie) => {
      if (cookie.split('=')[0].trim() === 'loginSecurityKey') {
        secretKey = cookie.split('=')[1]
      }
    })
    const payload = {
      email: authObject.userName,
      loginSecurityKey: secretKey,
    }
    dispatch(getLoginAuthenticationDetails(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGE4081') {
          const authenticationDTO = {
            email: authObject.userName,
            userId: authObject.userId,
            rememberMe: false,
          }
          dispatch(generateAuthenticationCode(authenticationDTO)).unwrap()
          history.push({
            pathname: REACT_LOGIN_AUTHENTICATE,
            state: { data: authenticationDTO },
          })
        } else history.push('/vis/verify-company')
      })
      .catch((er) => {})
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (CommonUtil.isEmptyString(values.email)) {
      setError({ ...error, email: true })
      Toast(t('COMMON053'), 'error')
      return
    }

    if (CommonUtil.isEmptyString(values.password)) {
      setError({ ...error, password: true })
      Toast(t('REGISTERUSER0002'), 'error')
      return
    }

    const user = {
      userName: values.email,
      password: passwordToHexa(values.password).replace('NaN', ''),
    }

    dispatch(authorizeUser(user))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          localStorage.setItem(
            process.env.REACT_APP_TEMP_TOKEN,
            'Bearer ' + data.data.access_token,
          )
          const decoded = jwt_decode(data.data.access_token)
          localStorage.setItem('applicationId', decoded.client_id)
          if (
            user.userName !== 'admin@mysmartguardplus.us' &&
            user.userName !== 'admin@mysmartguardplus.co' &&
            user.userName !== 'administrator@mysmartguardplus.com'
          ) {
            const authObject = {
              userName: user.userName,
              userId: decoded.userId,
            }
            checkLoginAuthentication(authObject)
          } else history.push('/vis/verify-company')
        } else {
          onFail(data)
        }
      })
      .catch((er) => {})
  }

  const onFail = (data) => {
    switch (data.code) {
      case 'CVAE0112':
        Toast(t('INVALID_USERNAME_PASSWORD'), 'error')
        break
      default:
        Toast(t(data.message), 'error')
        break
    }
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
  }

  const handleOnPasteEvent = (event) => {
    event.preventDefault()
  }

  const handleOnCopyEvent = (event) => {
    event.preventDefault()
  }

  const handleOnCutEvent = (event) => {
    event.preventDefault()
  }

  return !CommonUtil.isEmptyString(query.get('code')) ? (
    <></>
  ) : (
    <>
      <LoginLayoutV2 lang={true}>
        <Grid className="center_div">
          <Grid container alignItems="center">
            <Grid item className="logo-center">
              <img
                src={
                  process.env.REACT_APP_ENVIRONMENT === 'USA'
                    ? mysmgLogo
                    : visLogo
                }
                alt="logo"
                style={{ width: '250px' }}
              />
            </Grid>
          </Grid>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid item xs={12}>
              <Typography
                variant="h2"
                align="left"
                style={{
                  color:
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? '#fff'
                      : '#464646',
                  fontWeight: '500',
                  marginTop: '20px',
                  opacity: '0.9',
                }}
              >
                {t('LOGINFORM0002')}
              </Typography>
              <Typography
                variant="subtitle2"
                align="left"
                style={{
                  color:
                    process.env.REACT_APP_ENVIRONMENT === 'USA'
                      ? '#fff'
                      : '#7a7b97',
                  marginBottom: '20px',
                  marginTop: '6px',
                  fontSize: '12px',
                  opacity: '0.8',
                }}
              >
                {t('LOGINPAGE001')}
              </Typography>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs style={{ display: 'flex' }}>
                <Grid className="company_icon">
                  {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
                    <USAUserIcon />
                  ) : (
                    <UserIcon className="visibleIcons" />
                  )}
                </Grid>
                <USACustomTextfield
                  type={'text'}
                  error={error.email}
                  name="email"
                  label={t('LOGINPAGE002') + ' *'}
                  value={values.email}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.email}
                  validation="email"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ className: classes.input }}
                  onContextMenu={(e) => handleContextMenu(e)}
                  onPaste={(e) => handleOnPasteEvent(e)}
                  onCopy={(e) => handleOnCopyEvent(e)}
                  onCut={(e) => handleOnCutEvent(e)}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs style={{ display: 'flex' }}>
                <Grid className="company_icon">
                  {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
                    <USAPassIcon />
                  ) : (
                    <PassIcon className="visibleIcons" />
                  )}
                </Grid>
                <USACustomTextfield
                  type={values.showPassword ? 'text' : 'password'}
                  error={error.password}
                  name="password"
                  label={t('COMMON047') + ' *'}
                  value={values.password}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.password}
                  validation="password"
                  inputProps={{ className: classes.input }}
                  InputLabelProps={{ shrink: true }}
                  onContextMenu={(e) => handleContextMenu(e)}
                  onCopy={(e) => handleOnCopyEvent(e)}
                  onPaste={(e) => handleOnPasteEvent(e)}
                  onCut={(e) => handleOnCutEvent(e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          className="PasswordIcon"
                        >
                          {values.showPassword ? (
                            <Visibility className="visibleIcons" />
                          ) : (
                            <VisibilityOff className="visibleIcons" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs>
                <Box align="right" className="link_box">
                  <Link
                    style={{
                      color:
                        process.env.REACT_APP_ENVIRONMENT === 'USA'
                          ? '#fff'
                          : '#2286AB',
                      opacity: '0.8',
                    }}
                    href="/vis/forgot-password"
                    className="forgot_password"
                  >
                    {t('COMMON054')}
                  </Link>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={5} alignItems="center">
              <Grid item xs>
                <Button type="submit" fullWidth variant="contained">
                  {process.env.REACT_APP_ENVIRONMENT === 'USA'
                    ? t('LOGINFORM0002')
                    : t('COMMON038')}
                </Button>
              </Grid>
            </Grid>
          </form>
          {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
            <></>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs>
                <Box mt={3} className="signIn">
                  <hr className="hrline_Left" />
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    align="center"
                    style={{ fontSize: '12px' }}
                  >
                    {t('LOGINFORM0003')}
                  </Typography>
                  <hr className="hrline_Right" />
                </Box>
              </Grid>
            </Grid>
          )}
          {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
            <></>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs>
                <Box mt={1} align="center">
                  <IconButton className="icon_button">
                    <img src={iot} alt="iotlogo" className="social_icons" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          )}
          {process.env.REACT_APP_ENVIRONMENT === 'USA' ? (
            <></>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs>
                <Box align="center">
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    align="center"
                    style={{ fontSize: '12px', paddingBottom: '15px' }}
                  >
                    {t('LOGINFORM0004')}{' '}
                    <Link href="/vis/signup" className="forgot_password">
                      {' '}
                      {t('LOGINFORM0005')}
                    </Link>
                  </Typography>
                </Box>
              </Grid>

            </Grid>
            
          )}
            <Grid style={{marginTop : "30px"}}>
                  <Link style={{ color: '#F2824C', textDecoration: 'none', cursor: 'pointer' }}
                                onClick={() => window.open("../MySmartGuardPlus_UserAgreement.pdf" ,'_blank' )}
                                >
                                    {t("SIGNUP003")}
                  </Link>
                                <span style={{ color: '#fff', opacity: '0.9' }}>{" " + "and" + " "}</span>
                  <Link style={{ color: '#F2824C', textDecoration: 'none', cursor: 'pointer' }}
                                onClick={() => window.open("../MySmartGuardPlus_PrivacyDocument.pdf" ,'_blank' )}
                                    >
                                    {t("SIGNUP004")}
                   </Link>      
            </Grid>
        </Grid>
      </LoginLayoutV2>
    </>
  )
}
export default withRouter(LoginFormV2)
