import React from 'react';
import { Paper, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import {Grid , Box} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import SearchIcon from '@mui/icons-material/Search';
import Toolbar from '@mui/material/Toolbar';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import pic from '../../assets/images/Group 7629.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import editicon from '../../assets/images/EditIcon.svg';
import deleteicon from '../../assets/images/DeleteIcon.svg';
import Stack from '@mui/material/Stack';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    opacity:"1",
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
      
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity:"1"
  }));
  
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      opacity:"1",
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));
function Level() {
  return (
    <>
       <Paper  sx={{backgroundColor:"#F2F7FF"}}>
       <Box>
      <AppBar position="static" color="transparent" sx={{ml:"0px"}} >
        <Toolbar>
         
          <Typography
            variant="h3"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } , color:"#0F5A9C"}}>
           Level
          </Typography>

         <Box sx={{bgcolor:"white"}}>
         <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}/>
          </Search>
          </Box> 
         
          <Box sx={{ml:"20px"}}>
            <img src={pic}></img>
          </Box>

        </Toolbar>
      </AppBar>
    </Box>

<Table sx={{width:"100%" , height:"100%" , bgcolor:"white"}}>
    <TableHead sx={{bgcolor:"#2281AB"}}>
        <TableRow>
            <TableCell sx={{color:"white"}}>Name</TableCell>
            <TableCell sx={{color:"white"}}>Description</TableCell>
             <TableCell sx={{color:"white"}}>Action</TableCell>

        </TableRow>
    </TableHead>

    <TableBody>

    <TableRow>
        <TableCell>Gold</TableCell>
        <TableCell>Gold will get 30% discount</TableCell>
        <TableCell><Stack direction="row"  spacing={2}> <Box><img src={editicon}></img></Box>    <Box><img src={deleteicon}></img></Box> </Stack> </TableCell>
     </TableRow>

     <TableRow sx={{borderCollapse:"collapse",borderBottom:"none" ,border: "0px solid grey"}}>
        <TableCell>Silver</TableCell>
        <TableCell>Silver will get 15% discount</TableCell>
        <TableCell><Stack direction="row"  spacing={2}> <Box><img src={editicon}></img></Box>    <Box><img src={deleteicon}></img></Box> </Stack> </TableCell>
     </TableRow>
     


     <TableRow sx={{borderBottom:"none"}}>
        <TableCell>Platinium</TableCell>
        <TableCell>Platinum will get 60% discount</TableCell>
        <TableCell><Stack direction="row"  spacing={2}> <Box><img src={editicon}></img></Box>    <Box><img src={deleteicon}></img></Box> </Stack> </TableCell>
     </TableRow>
     
     
    
    </TableBody>
</Table>

        
       </Paper>
    </>
  )
}

export default Level
