import { Grid } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { Tooltip, Typography } from "@mui/material";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as CancelVisitIcon } from "../../../src/assets/CancelVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { cancelVisitorInvite } from "../../slices/Invitations/InvitationsSlice";
import Toast from "../../components/ToastContainer/CustomToast";
import { ThemeContext } from '../../theme/ThemeContext';
import { ReactComponent as CancelIconSvg } from "../../assets/USA_Images/login_images_visitor/CancelIcon1.svg";

export default function CancelRegistration(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = React.useState({
    id: props.CancelId,
    firstName: props.CancelFirstName,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    dispatch(cancelVisitorInvite(payload))
      .unwrap()
      .then((data) => {
        handleClose(false);
        if (data.code == "CVAI0068") {
          Toast(t("WALKINREPORTLBL5"), 'success');
          props.loadData();
          handleClose();
        } else {
          Toast(data.message,"error");
        }
      })
      .catch((er) => {});
  };
  const handleTextOverflow = (text) => {
    if (text && text.length > 10) {
        return text.substring(0, 20) + '...';
    } else {
    return text;
}
}

  return (
    <>
      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
            {t("ADD030")}
          </span>
        }
        acceptText={t("CONFIRM")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
          style={{ padding: "20px", backgroundColor: darkMode ? '#0F273B' : '' }}
        >
          <Grid>
          {darkMode ? <CancelIconSvg /> : <CancelVisitIcon />}
          </Grid>
          <Grid>
            <Grid
              style={{
                wordWrap: "break-word",
                opacity: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                fontSize: "17px",
                marginTop: "15px",
                color: darkMode? "#fff" : ""
              }}
            >
              <Typography style={{ color: darkMode? "#fff" : "#242424", marginRight: "4px",fontSize:"15px" }}>
               {t("WALKINREPORTLBL4")}
              </Typography>
              <Tooltip title={payload.firstName}>
              <Typography style={{ fontWeight: "bold", marginRight: "4px" }}>
                {handleTextOverflow(payload.firstName)}
              </Typography>
              </Tooltip>
              ?
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
