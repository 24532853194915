import React, { useContext } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CommonUtil from "../../Util/CommonUtils";
import { TextField, Popper, Paper, Typography } from "@mui/material";
import { ThemeContext } from "../../theme/ThemeContext";
import { styled } from "@mui/system";

const CustomPopper = styled(Popper)(({ darkMode }) => ({
  "& .MuiAutocomplete-listbox": {
    backgroundColor: darkMode ? "#6D7473" : "#fff", // Dropdown background color
    color: darkMode ? "#fff" : "#000", // Dropdown text color
  },
  "& .MuiAutocomplete-option": {
    backgroundColor: darkMode ? "#0F273B" : "#fff", // Option background color
    "&[aria-selected='true']": {
      backgroundColor: darkMode ? "#0D1926" : "#e6f7ff", // Background color when an option is selected
    },
    "&:hover": {
      backgroundColor: darkMode ? "#0D1926" : "#e6f7ff", // Hover background color
    },
  },
}));

const CustomPaper = styled(Paper)(({ darkMode }) => ({
  backgroundColor: darkMode ? "#6D7473" : "#fff", // Background color for the dropdown container
  color: darkMode ? "#fff" : "#000", // Text color for options
}));

const CssTextField = styled((props) => <TextField {...props} />)(
  ({ darkMode }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: darkMode ? "#DDDDDD" : "#b3adad", // Border color for the fieldset
      },
      "&:hover fieldset": {
        borderColor: "#2286AB", // Border color when hovered
      },
      "&.Mui-focused fieldset": {
        borderColor: darkMode ? "#fff" : "#0F273B", // Border color when focused
      },
    },
    "& .MuiInputLabel-root": {
      color: darkMode ? "rgba(255, 255, 255, 0.6)" : "#707070", // Label color
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.875rem",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      color: darkMode ? "#fff" : "#000"
    },
  })
);

const NoOptionsText = styled(Typography)(({ darkMode }) => ({
  color: darkMode ? "#fff" : "#000",
  padding: '10px',
}));

export default function AutocompleteComponent({
  options,
  onSelect,
  error,
  InputProps,
  label,
  defaultValue,
  onBlur,
  value,
  multiple,
  disabled,
  renderOption,
  disableCloseOnSelect,
  ...props
}) {
  const filterOptions = createFilterOptions({
    stringify: ({ name, code }) => `${name} ${code}`,
  });
  const { darkMode } = useContext(ThemeContext);

  return (
    <>
      <Autocomplete
        multiple={multiple}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        noOptionsText={<NoOptionsText darkMode={darkMode}>No Options found</NoOptionsText>}
        size="small"
        filterOptions={filterOptions}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        options={CommonUtil.isEmpty(options) ? [] : options}
        renderOption={renderOption}
        onChange={(event, newValue) => {
          if (!CommonUtil.isEmpty(newValue)) {
            onSelect(newValue);
          }
        }}
        onBlur={onBlur}
        autoHighlight={true}
        PopperComponent={(props) => (
          <CustomPopper {...props} darkMode={darkMode} />
        )}
        PaperComponent={(props) => (
          <CustomPaper {...props} darkMode={darkMode} />
        )}
        renderInput={(params) => (
          <CssTextField
            sx={[
              () => ({
                "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall ": {
                  paddingTop: "14px !important",
                  paddingBottom: "14px !important",
                },
              }),
            ]}
            {...params}
            className="invite_company"
            required={props.required}
            variant="outlined"
            fullWidth
            helperText={error}
            label={label}
            value={value}
            error={error}
            darkMode={darkMode}
          />
        )}
      />
    </>
  );
}
