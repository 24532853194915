import axios from 'axios'
import api from '../../config/http-common'
import CommonUtil from '../../Util/CommonUtils'

const buildQuery = (filter) => {
  let query = '?pageNumber=' + filter.page + '&pageSize=' + filter.rowsPerPage

  if (!CommonUtil.isEmpty(filter.status)) {
    query = query + '&status=' + filter.status
  }
  if (!CommonUtil.isEmpty(filter.name)) {
    query = query + '&name=' + filter.name
  }
  if (!CommonUtil.isEmpty(filter.email)) {
    query = query + '&email=' + filter.email
  }
  if (!CommonUtil.isEmpty(filter.type)) {
    query = query + '&type=' + filter.type
  }
  return query
}

const createDealer = (data) => {
  return api.securedAxios().post('/web/api/v4/dealer', data)
}

const updateDealer = (payload) => {
  return api
    .securedAxios()
    .put('/web/api/v4/dealers/' + payload.companyId, payload)
}

const getDealers = (filter) => {
  return api.securedAxios().post('/web/api/v4/dealers' + buildQuery(filter))
}

const deleteDealer = (payload) => {
  return api.securedAxios().delete('/web/api/v4/dealers/' + payload.id)
}

const resendActivationLinkForDealer = (payload) => {
  return api
    .securedAxios()
    .put('/web/api/v4/dealers/' + payload.id + '/acountActivation', payload)
}

const getDealersAll = (filter) => {
  return api
    .securedAxios()
    .post('/web/api/v4/dealers/details/export' + buildQuery(filter))
}

const getUserDetails = (payload) => {
  return api.securedAxios().get('/web/api/v4/users/' + payload)
}

const listOfDealerDetalisWithoutStatus = (payload) => {
  return api
    .securedAxios()
    .post('/web/api/v4/dealers/details/export/without/status' + payload)
}

const getdealerById = (payload) => {
  return api.securedAxios().get('/web/api/v4/dealer/' + payload.id)
}

const getDealerBillingInfo = (companyId) => {
  return api.securedAxios().get('/web/api/v4/dealers/billingInfo/' + companyId)
}

const getDealerInvoice = (payload) => {
  return api
    .securedAxios()
    .get('/web/api/v4/subscriptions/invoice/' + payload.id)
}

const DealerService = {
  createDealer,
  updateDealer,
  getDealers,
  deleteDealer,
  resendActivationLinkForDealer,
  getDealersAll,
  getUserDetails,
  listOfDealerDetalisWithoutStatus,
  getdealerById,
  getDealerBillingInfo,
  getDealerInvoice,
}

export default DealerService
