import { createAsyncThunk } from '@reduxjs/toolkit'
import visitorDocumentService from '../../services/VisitorDocuments/VisitorDocumentService'

const initialState = []

export const getAllVisitorDocuments = createAsyncThunk(
  'getAllVisitorDocuments/',
  async (payload) => {
    const res = await visitorDocumentService.getAllVisitorDocuments(payload)
    return res.data
  },
)

export const  uploadVisitorAsset = createAsyncThunk('/uploadAsset' , async(payload) => {
  const res = await visitorDocumentService.uploadVisitorAsset(payload)
  return res.data;
})

export const deteleAssetImage = createAsyncThunk('/deleteAsset' , async(payload) => {
  const res = await visitorDocumentService.deteleAssetImage(payload);
  return res.data;
})

const visitorDocumentSlice = {
  getAllVisitorDocuments,
}

export default visitorDocumentSlice
