import React from "react";
import { Tooltip, Grid } from "@mui/material";

const ForceCheckoutIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <>
      <Tooltip title="Force Check Out">
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
<svg xmlns="http://www.w3.org/2000/svg" width="17.024" height="17.028" viewBox="0 0 17.024 17.028">
    <g id="Group_7253" data-name="Group 7253" transform="translate(9726 -21324.275)">
        <path id="Path_6867" data-name="Path 6867" d="M.762,0,7.728,2.063a.762.762,0,0,1,.762.762l0,13.384a.762.762,0,0,1-.762.762L.8,15.628a.762.762,0,0,1-.762-.762L0,.762A.762.762,0,0,1,.762,0Z" transform="translate(-9726 21324.332)" fill="#c0c7cc" />
        <path id="Path_6868" data-name="Path 6868" d="M-9707.006,21339.9h-11.01a.712.712,0,0,1-.711-.713v-14.2a.71.71,0,0,1,.711-.709h11.01a.709.709,0,0,1,.711.709v4.262a.713.713,0,0,1-.711.713.714.714,0,0,1-.714-.713v-3.549h-9.583v12.773h9.583v-3.516a.713.713,0,0,1,.714-.713.711.711,0,0,1,.711.713v4.227A.711.711,0,0,1-9707.006,21339.9Z" transform="translate(-7.032 -0.002)" fill="#c0c7cc" />
        <path id="Path_6869" data-name="Path 6869" d="M-9439.131,21539.848h-5.543a.712.712,0,0,1-.713-.713.714.714,0,0,1,.713-.713h5.543a.714.714,0,0,1,.712.713A.712.712,0,0,1-9439.131,21539.848Z" transform="translate(-271.312 -207.048)" fill="#c0c7cc" />
        <path id="Path_6870" data-name="Path 6870" d="M-9320.063,21480.719a.724.724,0,0,1-.507-.211.713.713,0,0,1,.007-1.008l1.649-1.635-1.646-1.6a.714.714,0,0,1-.014-1.008.714.714,0,0,1,1.009-.014l2.166,2.111a.715.715,0,0,1,.217.508.711.711,0,0,1-.213.508l-2.166,2.146A.721.721,0,0,1-9320.063,21480.719Z" transform="translate(-391.793 -145.766)" fill="#c0c7cc" />
    </g>
</svg>
          ):(
            <svg xmlns="http://www.w3.org/2000/svg" width="17.024" height="17.028" viewBox="0 0 17.024 17.028">
            <g id="Group_7253" data-name="Group 7253" transform="translate(9726 -21324.275)">
                <path id="Path_6867" data-name="Path 6867" d="M.762,0,7.728,2.063a.762.762,0,0,1,.762.762l0,13.384a.762.762,0,0,1-.762.762L.8,15.628a.762.762,0,0,1-.762-.762L0,.762A.762.762,0,0,1,.762,0Z" transform="translate(-9726 21324.332)" fill="#c0c7cc" />
                <path id="Path_6868" data-name="Path 6868" d="M-9707.006,21339.9h-11.01a.712.712,0,0,1-.711-.713v-14.2a.71.71,0,0,1,.711-.709h11.01a.709.709,0,0,1,.711.709v4.262a.713.713,0,0,1-.711.713.714.714,0,0,1-.714-.713v-3.549h-9.583v12.773h9.583v-3.516a.713.713,0,0,1,.714-.713.711.711,0,0,1,.711.713v4.227A.711.711,0,0,1-9707.006,21339.9Z" transform="translate(-7.032 -0.002)" fill="#c0c7cc" />
                <path id="Path_6869" data-name="Path 6869" d="M-9439.131,21539.848h-5.543a.712.712,0,0,1-.713-.713.714.714,0,0,1,.713-.713h5.543a.714.714,0,0,1,.712.713A.712.712,0,0,1-9439.131,21539.848Z" transform="translate(-271.312 -207.048)" fill="#c0c7cc" />
                <path id="Path_6870" data-name="Path 6870" d="M-9320.063,21480.719a.724.724,0,0,1-.507-.211.713.713,0,0,1,.007-1.008l1.649-1.635-1.646-1.6a.714.714,0,0,1-.014-1.008.714.714,0,0,1,1.009-.014l2.166,2.111a.715.715,0,0,1,.217.508.711.711,0,0,1-.213.508l-2.166,2.146A.721.721,0,0,1-9320.063,21480.719Z" transform="translate(-391.793 -145.766)" fill="#c0c7cc" />
            </g>
        </svg>

)}
</Grid>
</Tooltip>
</>
);
};
export default ForceCheckoutIcon;

