import { Grid } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { Tooltip, Typography } from "@mui/material";

import { ReactComponent as DeleteVisitIcon } from "../../../../src/assets/DeleteVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DialogBox from "../../../components/DialogBoxComponent/DialogBox";
import { deleteSiteType } from "../../../slices/Site/SiteTypeSlice";
import Toast from "../../../components/ToastContainer/CustomToast";
import { deleteAddressType } from "../../../slices/Site/AddressTypeSlice";
import { ThemeContext } from '../../../theme/ThemeContext';
import { ReactComponent as DeleteIcon } from '../../../../src/assets/DeleteIcon.svg'

export default function DeleteAddressType(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { darkMode } = useContext(ThemeContext);

  useEffect(() => { }, []);

  const handleSubmit = () => {
    const id = props.DeleteAddressTypeId;

    dispatch(deleteAddressType(id))
      .unwrap()
      .then((data) => {
        handleClose(false);
        if (data.code === "LMSI6003") {
          Toast(t(data.message), "success");
          props.delete();
          handleClose();
        }
        // else if( data.code === "LMSE6095") {
        else if (data.code === "LMSE6074") {
          Toast(t("PERSONFIELD3"), 'error');
          handleClose();
        }
        else {
          Toast(t("PERSONFIELD3"), 'error');
          handleClose();
        }
      });
  };
  const handleTextOverflow = (text) => {
    if (text && text.length > 10) {
      return text.substring(0, 30) + '...';
    } else {
      return text;
    }
  }

  return (
    <>
      <DialogBox
        // Header={t("COMMON004")}
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
            {t("COMMON004")}
          </span>
        }
        acceptText={t("CONFIRM")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
          style={{ padding: "20px", backgroundColor: darkMode ? '#0F273B' : '' }}
        >
          <Grid>
          <DeleteIcon width="60px" height={'50px'} />
          </Grid>
          <Grid>
            <Grid
              style={{
                wordWrap: "break-word",
                opacity: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                fontSize: "17px",
                marginTop: "15px",
                color: darkMode ? '#fff' : '',
              }}
            >
              <Typography style={{ color: "#242424", marginRight: "4px", fontSize: '15px',color: darkMode ? '#fff' : '' }}>
                {t("ADSDELETEMSG")}
              </Typography>
              <Tooltip title={props.DeleteAddressTypeName}>
                <Typography style={{ fontWeight: "bold", marginRight: "4px", fontSize: '15px' }}>
                  {handleTextOverflow(props.DeleteAddressTypeName)}
                </Typography>
              </Tooltip>
              <Typography style={{ color: "#242424", marginRight: "4px", fontSize: '15px' }}>
                ?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
