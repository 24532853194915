import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import LoginAuthenticationService from '../../services/LoginAuthentication/LoginAuthenticationService'

const initialState = []

export const generateAuthenticationCode = createAsyncThunk(
  'loginAuthentication/',
  async (payload) => {
    const res = await LoginAuthenticationService.generateAuthenticationCode(
      payload,
    )
    return res.data
  },
)

export const validateAuthenticationCode = createAsyncThunk(
  'loginAuthentication/',
  async (payload) => {
    const res = await LoginAuthenticationService.validateAuthenticationCode(
      payload,
    )
    return res.data
  },
)

export const getLoginAuthenticationDetails = createAsyncThunk(
  'loginAuthentication/',
  async (payload) => {
    const res = await LoginAuthenticationService.getLoginAuthenticationDetails(
      payload,
    )
    return res.data
  },
)

const LoginAuthenticationSlice = createSlice({
  name: 'loginAuthenticationSlice',
  initialState,
  extraReducers: {},
})

const { reducer } = LoginAuthenticationSlice
export default reducer
