import React, { useEffect, useState } from 'react'
import AddSubscriptionIcon from '../../assets/Group7629.svg'
import SubscriptionHeader from '../../components/CustomInputs/SubscriptionHeader'
import { Grid } from '@material-ui/core'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  getAllProducts,
  getSubscriptions,
} from '../../slices/Subscription/SubscriptionSlice'
import { filterDealerSubscriptions } from '../../slices/SubscriptionDealerSlice'
import DoneIcon from '@mui/icons-material/Done'
import {
  REACT_CREATE_SUBSCRIPTION,
  REACT_EDIT_SUBSCRIPTION,
} from '../../actions/EndPoints'
import { useTranslation } from 'react-i18next'
import ListViewSubscription from './ListViewSubscription'
import jwt_decode from 'jwt-decode'
import CardOne from '../../assets/CardOne.svg'
import CardTwo from '../../assets/CardTwo.svg'
import CardThree from '../../assets/CardThree.svg'
import CardFour from '../../assets/CardFour.svg'
import CardFive from '../../assets/CardFive.svg'
import Group8078 from '../../assets/Group8078.svg'
import {
  FormControlLabel,
  FormLabel,
  Stack,
  Switch,
  Tooltip,
  styled,
} from '@mui/material'
import { Typography } from 'antd'

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#0F5A9C !important',
    opacity: 1,
  },
}))

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

function SubscriptionManagement(props) {
  const history = useHistory()
  const { t } = useTranslation()
  const [cardCount, setCardCount] = useState(0)
  const [subscriptionList, setSubscriptionList] = useState([])
  const [openView, setOpenView] = useState(false)
  const dispatch = useDispatch()
  const [yearlyPlan, setYearlyPlan] = useState(false)
  const [hoveredCard, setHoveredCard] = useState(null)
  const [purchasePlanDialog, setPurchasePlanDialog] = useState(false)
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )

  const handleOpen = () => {
    history.push({
      pathname: REACT_CREATE_SUBSCRIPTION,
    })
  }

  const handleEditButton = (subscription) => {
    history.push({
      pathname: REACT_EDIT_SUBSCRIPTION,
      subscription: subscription,
      state: subscription.id,
    })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 13) {
      return text.substring(0, 17) + '...'
    } else {
      return text
    }
  }

  const handleTextPrice = (text) => {
    if (text && text.length > 9) {
      return text.substring(0, 9) + '...'
    } else {
      return text
    }
  }

  useEffect(() => {
    const query = {
      pageNumber: 1,
      pageSize: 1000,
    }
    const payload = {
      companyId: decoded_jwtToken.companyId,
      pageNumber: 1,
      pageSize: 1000,
    }
    {
      decoded_jwtToken.companyType === 'NONE'
        ? dispatch(getAllProducts(query))
            .unwrap()
            .then((data) => {
              if (data.code === '200' && data.data) {
                setSubscriptionList(data.data.results)
                setCardCount(data.data.count)
              } else {
                setCardCount(0)
              }
            })
        : dispatch(filterDealerSubscriptions(payload))
            .unwrap()
            .then((data) => {
              if (data.code === 'CVAI0000' && data.data) {
                setSubscriptionList(data.data.results)
                setCardCount(data.data.count)
              } else {
                setCardCount(0)
              }
            })
    }
  }, [])

  const handleToggle = () => {
    setYearlyPlan(!yearlyPlan)
  }

  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT === 'USA' &&
      decoded_jwtToken.companyType === 'NONE' ? (
        <SubscriptionHeader
          src={AddSubscriptionIcon}
          onClick={handleOpen}
          title={t('LABELNAVITEMSSUBSCRIPTION')}
          tooltipTitle={t('SUBSCRIPTIONADDSUBSCRIPTION')}
        />
      ) : (
        <SubscriptionHeader title={t('LABELNAVITEMSSUBSCRIPTION')} />
      )}

      {decoded_jwtToken.companyType === 'PARTNER' ? (
        <Grid style={{ marginTop: '10px', padding: '10px' }}>
          <Stack direction="row">
            <FormLabel className="formLabel" style={{ marginTop: '8px' }}>
              {t('MONTHLY')}
            </FormLabel>
            <Android12Switch
              checked={yearlyPlan}
              onChange={handleToggle}
              name="yearlyplan"
            />
            <FormLabel className="formLabel" style={{ marginTop: '8px' }}>
              {t('YEARLY')}
            </FormLabel>
          </Stack>
        </Grid>
      ) : (
        ''
      )}

      <Grid container spacing={5}>
        {Array.from(subscriptionList).map((subscription, index) => (
          <Grid
            item
            xs={2.1}
            key={index}
            onMouseEnter={() => setHoveredCard(index)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <br />
            <Card sx={{ minWidth: 0, height: '311px', width: '220px' }}>
              {(() => {
                switch (true) {
                  case subscription.original_price >= 0 &&
                    subscription.original_price <= 100:
                    return (
                      <CardMedia
                        component="img"
                        height="336px"
                        src={CardOne}
                        alt="green iguana"
                        sx={{
                          marginTop: '-10px',
                          marginLeft: '-10px',
                          width: '240px',
                        }}
                      />
                    )
                  case subscription.original_price > 100 &&
                    subscription.original_price <= 200:
                    return (
                      <CardMedia
                        component="img"
                        height="336px"
                        src={CardTwo}
                        alt="green iguana"
                        sx={{
                          marginTop: '-10px',
                          marginLeft: '-10px',
                          width: '240px',
                        }}
                      />
                    )

                  case subscription.original_price > 200 &&
                    subscription.original_price <= 300:
                    return (
                      <CardMedia
                        component="img"
                        height="336px"
                        src={CardThree}
                        alt="green iguana"
                        sx={{
                          marginTop: '-10px',
                          marginLeft: '-10px',
                          width: '240px',
                        }}
                      />
                    )

                  case subscription.original_price > 300 &&
                    subscription.original_price <= 400:
                    return (
                      <CardMedia
                        component="img"
                        height="336px"
                        src={CardFour}
                        alt="green iguana"
                        sx={{
                          marginTop: '-10px',
                          marginLeft: '-10px',
                          width: '240px',
                        }}
                      />
                    )

                  default:
                    return (
                      <CardMedia
                        component="img"
                        height="336px"
                        src={CardFive}
                        alt="green iguana"
                        sx={{
                          marginTop: '-10px',
                          marginLeft: '-10px',
                          width: '240px',
                        }}
                      />
                    )
                }
              })()}
              {subscription.type_name === 'Pay annual' ? (
                <div
                  style={{
                    position: 'absolute',
                    color: 'white',
                    top: 73,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '11px',
                    opacity: 1,
                  }}
                >
                  {t('YEARLY_UPPER_CASE')}
                </div>
              ) : (
                <div
                  style={{
                    position: 'absolute',
                    color: 'white',
                    top: 73,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '11px',
                    opacity: 1,
                  }}
                >
                  {t('MONTHLY')}
                </div>
              )}
              <Tooltip
                title={`${subscription.price_strategy_name}`}
                placement="bottom-start"
              >
                <div
                  style={{
                    position: 'absolute',
                    color: 'white',
                    top: 12,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '15px',
                    opacity: 1,
                  }}
                >
                  {handleTextPrice(subscription.price_strategy_name)}
                </div>
              </Tooltip>
              <div
                style={{
                  position: 'absolute',
                  color: 'white',
                  top: 35,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  fontSize: '25px',
                  font: 'bold',
                }}
              >
                ${subscription.original_price}
              </div>

              <Grid
                container
                spacing={2}
                style={{ position: 'absolute', top: '150px', left: '160px' }}
              >
                {process.env.REACT_APP_ENVIRONMENT === 'USA' &&
                decoded_jwtToken.companyType === 'NONE' &&
                hoveredCard === index ? (
                  <Tooltip title={t('EDIT')} placement="bottom">
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        marginLeft: '0px',
                        marginTop: '-60px',
                        borderColor: 'transparent',
                      }}
                      onClick={() => handleEditButton(subscription)}
                    >
                      <img
                        src={Group8078}
                        alt="edit icon"
                        style={{ width: '40px', height: '33px' }}
                      />
                    </Button>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>
              {subscription.feature[0] !== undefined ? (
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginLeft: '20px',
                    position: 'absolute',
                    color: 'black',
                    top: 135,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <DoneIcon
                      sx={{
                        fontSize: '23px',
                        color: 'green',
                        marginRight: '-10px',
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={`${subscription.feature[0].name}`}
                      placement="bottom-start"
                    >
                      <div
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {handleTextOverflow(subscription.feature[0].name)}
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {subscription.feature[1] !== undefined ? (
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginLeft: '20px',
                    position: 'absolute',
                    color: 'black',
                    top: 160,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <DoneIcon
                      sx={{
                        fontSize: '23px',
                        color: 'green',
                        marginRight: '-10px',
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={`${subscription.feature[1].name}`}
                      placement="bottom-start"
                    >
                      <div
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {handleTextOverflow(subscription.feature[1].name)}
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}

              {subscription.feature[2] !== undefined ? (
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginLeft: '20px',
                    position: 'absolute',
                    color: 'black',
                    top: 185,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <DoneIcon
                      sx={{
                        fontSize: '23px',
                        color: 'green',
                        marginRight: '-10px',
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={`${subscription.feature[2].name}`}
                      placement="bottom-start"
                    >
                      <div
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {handleTextOverflow(subscription.feature[2].name)}
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}

              {subscription.feature[3] !== undefined ? (
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginLeft: '20px',
                    position: 'absolute',
                    color: 'black',
                    top: 210,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <DoneIcon
                      sx={{
                        fontSize: '23px',
                        color: 'green',
                        marginRight: '-10px',
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={`${subscription.feature[3].name}`}
                      placement="bottom-start"
                    >
                      <div
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {handleTextOverflow(subscription.feature[3].name)}
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}

              {subscription.feature[4] !== undefined ? (
                <Grid
                  container
                  spacing={2}
                  style={{
                    marginLeft: '20px',
                    position: 'absolute',
                    color: 'black',
                    top: 235,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    fontSize: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <DoneIcon
                      sx={{
                        fontSize: '23px',
                        color: 'green',
                        marginRight: '-10px',
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={`${subscription.feature[4].name}`}
                      placement="bottom-start"
                    >
                      <div
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {handleTextOverflow(subscription.feature[4].name)}
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              <CardContent></CardContent>
              <CardActions>
                <ListViewSubscription
                  featureNameList={subscription.feature}
                  planName={subscription.price_strategy_name}
                  id={subscription.id}
                  price={'$' + subscription.original_price}
                  subscriptionType={subscription.type_name}
                  open={openView}
                  originalPrice={subscription.original_price}
                  purchasePlanDialog={props.purchasePlanDialog}
                  handlePurchasedPlan={props.handlePurchasedPlan}
                  productId={subscription.productId}
                  yearlyPlan={yearlyPlan}
                />
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default SubscriptionManagement
