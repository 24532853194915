import api from '../config/http-common';



const get = (payload) => {
    return api.securedAxios().post('/web/employees/list?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize + '&email=' + payload.email);
};

const allHostList = (payload) => {
    return api.securedAxios().post('/web/employees/list?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize);
};

const HostService = {
    get,
    allHostList,
};

export default HostService;
