import { MenuItem, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

function HoverWhatsNew(props) {

    const [image, setImage] = React.useState(props.normalImage);
    //
    const [showItems, setShowItems] = React.useState(false); 

    const over = (e) => {
        setImage(props.hoverImage);
    };

    const out = (e) => {
        setImage(props.normalImage);
    }


    const toggleItems = () => {
        setShowItems(!showItems); 
    };



    const openNewTab = () => {
        const newWindow = window.open('/whatsnew');
        if (newWindow) {
            newWindow.document.write(`
            <html>
            <head>
                <title>What's new in MySmartGuard Plus</title>

                <style>
                    body {
                        font-family: Arial, sans-serif;
                        margin: 0;
                        padding: 0;
                    }
                    .container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 100vh;
                        background-color: #f5f5f5;
                        padding: 20px;
                    }
                    .content {
                        max-width: 800px;
                        text-align: left;
                        background-color: #fff;
                        border-radius: 5px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        padding: 20px 40px;
                        
                    }
                    h2 {
                        color: #333;
                    }
                    h3 {
                        color: #666;
                    }
                    h4 {
                        color: #999;
                    }
                    p {
                        color: black;
                    }
                </style>
            </head>
            <body>
                 <div class="container">
                    <div class="content">
                        <h2>What's new in MySmartGuard Plus</h2>
                        <p>We regularly update MySmartGuard Plus with improvements or new features. You can see the details here.</p>

                        <h3>Latest News</h3>
                        <h4>MySmartGuard Plus Managed Risk service now available with MySmartGuard Plus.</h4>
                        <p>MySmartGuard Plus Managed Risk combines industry-leading technology from Tenable with threat expertise from MySmartGuard Plus, delivered as a proactive vulnerability management service.</p>

                        <h4>Enhancements to Adaptive Attack Protection for MySmartGuard Plus Endpoint customers.</h4>
                        <p>MySmartGuard Plus Endpoint customers now have more visibility into devices under attack, new controls to put devices into a more aggressive protection mode, and new persistent Adaptive Attack Protection policy rules.</p>

                        <h4>Device isolation support for Linux in MySmartGuard Plus Central.</h4>
                        <p>You can now isolate Linux devices from within MySmartGuard Plus Central.</p>

                        <h4>MySmartGuard Plus AP6 firmware enhancements including Wireless Guest Network (Bridge Mode).</h4>
                        <p>The MR4 release of MySmartGuard Plus AP6 Series access points includes the Wireless Guest Network (Bridge Mode) along with many bug fixes.</p>

                        <h4>Expand multi-factor authentication coverage in MySmartGuard Plus Central.</h4>
                        <p>MySmartGuard Plus Central admins can now expand second-factor (MFA) sign-in security to Self Service Portal, Sophos ID (landing page), Sophos Community, Partner Portal, Sophos Support, and more. We strongly recommend taking advantage of the added security.</p>

                        <h4>Scheduled updates for macOS in MySmartGuard Plus Central.</h4>
                        <p>macOS will soon support scheduled updates. Updates will be applied on the day and time specified in your Update Management policy.</p>

                        <h3>April 15, 2024</h3>
                        <h2>Version: V1.4.0</h2>
                        <h4>MySmartGuard Plus Central Custom Dashboard Beta is now available for Endpoint, Server, XDR, and MDR customers.</h4>
                        <p>Endpoint, Server, XDR and MDR customers are now able to try our new custom dashboard feature.</p>

                        <h3>April 2, 2024</h3>
                        <h2>Version: V1.3.0</h2>
                        <h4>Partner PSA: Usage sync enhancements have been made, improving Autotask and ConnectWise PSA integrations.</h4>
                        <p>We've updated Autotask and ConnectWise PSA integrations so that they only create PSA products on request, not automatically, for partners who have turned on usage sync. We've also improved the product mapping user experience based on MSP feedback.</p>

                        <h3>March 4, 2024</h3>
                        <h2>Version: V1.2.0</h2>
                        <h4>Added security for MFA management in MySmartGuard Plus Central.</h4>
                        <p>MySmartGuard Plus Central users who want to manage their current multi-factor authentication (MFA) sign-in settings must now validate their identity with MFA first. This makes sure that only legitimate users can view, add, or remove authentication methods.</p>

                        <h3>NDR and log collector integrations on AWS AMI</h3>
                        <p>You can now use an AWS AMI to deploy an appliance hosting NDR and third-party integrations.</p>

                        <h3>February 21, 2024</h3>
                        <h2>Version: V1.1.0</h2>
                        <h4>MySmartGuard Plus DNS Protection: EAP update includes new features such as saving report templates and scheduling reports.</h4>
                        <p>The latest update to MySmartGuard Plus DNS Protection includes the ability to save your own report templates, export reports in various formats, and schedule reports. It's not too late to join the EAP and try it for yourself.</p>

                        <h3>February 12, 2024</h3>
                        <h2>Version: V1.0.0</h2>
                        <h4>MySmartGuard Plus Switch firmware enhancements with improved authentication and capabilities for Spanning Tree Protocol.</h4>
                        <p>The MySmartGuard Plus Switch MR4 release includes the following firmware enhancements: Admin authentication through Radius or TACACS+, 802.1x authentication through TACACS+, and new capabilities for Spanning Tree Protocol (STP).</p>
                    </div>
                </div>
            </body>
            </html>
            `);
            newWindow.document.close();
        }
    };

    return (
        <>
            <MenuItem
                onMouseOver={over}
                onMouseOut={out}
                // component={Link}
                onClick={openNewTab}
                to={props.link}
                sx={[
                    (theme) => ({
                        '&:hover': {
                            background: process.env.REACT_APP_ENVIRONMENT === 'USA' ?  '' : process.env.REACT_APP_BG_ICON,
                            color:process.env.REACT_APP_BG_HEADER_PROFILE
                        },
                    }),
                ]}
            >
                <img src={image} alt={props.alt} style={{ paddingRight: '10px' }} />
               <Typography>{props.item}</Typography> 
            </MenuItem>

            


        </>
    )
}

export default HoverWhatsNew;
