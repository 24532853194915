import { makeStyles } from '@material-ui/core'
import { Autocomplete, Grid, TextField } from '@mui/material'
import isoCountries from 'i18n-iso-countries'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getCompanyInfo } from '../../slices/Company/CompanySlice'
import { listofRoles } from '../../slices/UserRole/UserRoleSlice'
import CustomPhone from '../CustomInputs/CustomPhone'
import CustomTextfield from '../CustomInputs/CustomTextfield'
import Toast from '../ToastContainer/CustomToast'
import DialogBox from './DialogBox'

const useStyles = makeStyles((theme) => ({
  dialogbox: {
    '& .css-hlj6pa-MuiDialogActions-root': {
      marginLeft: '290px !important',
    },
    '& .MuiDialog-paper': {
      minWidth: '110vh',
    },
  },
}))

function BulkEmployeeEditDialog({ open, onClose, rowData, onSave }) {
  const classes = useStyles()
  const [firstName, setFirstName] = useState(rowData.firstName)
  const [lastName, setLastName] = useState(rowData.lastName)
  const [email, setEmail] = useState(rowData.email)
  const [AddressLine1, setAddressLine1] = useState(rowData.AddressLine1)
  const [PersonID, setPersonID] = useState(rowData.code)
  const [Country, setCountry] = useState(rowData.Country)
  const [roleId, setRoleId] = useState(rowData.roleId ? rowData.roleId : '')
  const [gender, setGender] = useState(rowData.gender)
  // const [phone, setPhone] = useState(rowData.phone ? rowData.phoneCountryCode + rowData.phone : "");
  const [phone, setPhone] = useState(rowData.phone ? rowData.phone : '')
  const [phoneCountryCode, setPhoneCountryCode] = useState(
    rowData.phoneCountryCode,
  )
  const [phoneNumber, setPhonenumber] = useState(
    rowData.phoneNumber ? rowData.phoneNumber : rowData.phone,
  )
  const [phoneInput, setPhoneInput] = useState(
    rowData.phone ? rowData.phoneCountryCode + rowData.phone : '',
  )
  const [code, setCode] = useState(rowData.code)
  const { t } = useTranslation()
  const [roleType, setRoleType] = useState([])
  const [defaultRole, setDefaultRole] = useState()
  const dispatch = useDispatch()
  const [selectedValue, setSelectedValue] = useState(null)
  const [defaultGender, setDefaultGender] = useState(null)
  const [roleName, setRoleName] = useState(rowData.roleName)
  const [parentCompnayCountry,setParentCompanyCountry]= useState('')

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = React.useState({
    name: '',
    roleScope: 'APPLICATION',
  })

  useEffect(() => {
    loadData(filter, paging)
    if (phoneNumber === '' || phoneNumber === null) {
      loadCompany()
    }
  }, [])

  useEffect(() => {
    if (rowData.roleId != '') {
      const defaultRole = roleType.find((role) => role.id === rowData.roleId)
      if (defaultRole?.id) setSelectedValue(defaultRole)
    }
  }, [rowData.roleId, roleType])

  const loadData = (filter, paging) => {
    dispatch(listofRoles(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0000') {
          const roles = data.data.roles.filter((role) => {
            if (role.code != 'DEVICEADMIN') return role
          })
          setRoleType(roles)
        } else {
          setRoleType([])
        }
      })
  }

  useEffect(() => {
    if (rowData.gender != '') {
      const value = genderOptions.find(
        (gender) => gender.value === rowData.gender,
      )
      if (value) setDefaultGender(value)
    }
  }, [rowData.gender])

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneCountryCode('+' + data.dialCode)
    setPhonenumber(value.toString().replace(data.dialCode, ''))
    setPhone(value.toString().replace(data.dialCode, ''))
    // setPhone(phoneCountryCode + "-" + phoneNumber);
  }

  const genderOptions = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
    { label: 'Others', value: 'O' },
  ]

  const handleSave = () => {
    if (firstName === '') {
      Toast(t('USERPROFSCREEN007'), 'error')
      return
    }
    if (lastName === '') {
      Toast(t('USERPROFSCREEN008'), 'error')
      return
    }
    if (email === '') {
      Toast(t('EMAILVALIDATE'), 'error')
      return
    }
    if (PersonID === '') {
      Toast(t('PERSONINFO1'), 'error')
      return
    }
    // if( Country === ""){
    //   Toast(t("PERSONINFO3") , "error")
    //   return;
    // }

    if (roleId === '') {
      Toast(t('ROLEEMPTYERROR'), 'error')
      return
    }

    onSave({
      ...rowData,
      firstName,
      lastName,
      email,
      AddressLine1,
      PersonID,
      code,
      Country,
      phone,
      phoneNumber,
      phoneCountryCode,
      roleId,
      roleName,
      gender,
    })
    Toast(t('PERSONINFO4'), 'success')
    onClose()
  }

  const loadCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setParentCompanyCountry(countryCode)
          } else {
            setParentCompanyCountry('us')
          }
        }
      })
      .catch((er) => { })
  }
  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }


  return (
    <>
      <DialogBox
        className={classes.dialogbox}
        Header={t('PERSONINFO5')}
        acceptText={t('COMMON007')}
        cancelText={t('COMMON008')}
        onSubmit={handleSave}
        open={open}
        onClose={onClose}
        cancel={onClose}
      >
        <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
          <Grid sm={8} container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={'text'}
                name="FirstName"
                label={t('USER0001')}
                value={firstName}
                required={true}
                handleChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={'text'}
                name="LastName"
                label={t('USER0002')}
                value={lastName}
                required={true}
                handleChange={(e) => setLastName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={'text'}
                name="Email"
                label={t('VISUPDATE0001')}
                value={email}
                required={true}
                handleChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ margin: '16px 0px' }}>
              <CustomPhone
                placeholder={t('VISUPDATE0002')}
                value={phoneInput}
                specialLabel={false}
                country={parentCompnayCountry}
                handleChange={(value, data, event, formattedValue) =>
                  handlePhoneChange(value, data, event, formattedValue)
                }
              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
             
             <CustomTextfield
               type={"text"}
               name="AddressLine1"
               label={t("ADD001")}
               value={AddressLine1}
              required={true}
              handleChange ={(e) => setAddressLine1(e.target.value)}
             />
             
           </Grid> */}

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={'text'}
                name="PersonID"
                label={t('COMMON140')}
                value={PersonID}
                required={true}
                handleChange={(e) => {
                  setPersonID(e.target.value)
                  setCode(e.target.value)
                }}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
             
             <CustomTextfield
               type={"text"}
               name="Country"
               label={t("ADD003")}
               value={Country}
              required={true}
              handleChange ={(e) => setCountry(e.target.value)}
             />
             
           </Grid> */}

            <Grid item xs={12} sm={6} style={{ margin: '16px 0px' }}>
              <Autocomplete
                disablePortal
                id="gender"
                options={genderOptions}
                value={defaultGender}
                sx={{ width: '100%' }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setGender(newValue.value)
                    setDefaultGender(newValue)
                  } else {
                    setGender('')
                    setDefaultGender()
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Gender" />
                )}
              />
            </Grid>

            <Grid xs={12} sm={12}>
              <Autocomplete
                disablePortal
                options={roleType}
                getOptionLabel={(option) => option.name}
                value={selectedValue}
                sx={{ width: '100%' }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setRoleId(newValue.id)
                    setSelectedValue(newValue)
                    setRoleName(newValue.name)
                  } else {
                    setRoleId('')
                    setSelectedValue()
                    setRoleName()
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Role" />}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}

export default BulkEmployeeEditDialog
