const countryData = [
  {
    id: 1,
    name: 'Afghanistan',
    value: '+93',
  },
  {
    id: 2,
    name: 'Aland Islands',
    value: '+358',
  },
  {
    id: 3,
    name: 'Albania',
    value: '+355',
  },
  {
    id: 4,
    name: 'Algeria',
    value: '+213',
  },
  {
    id: 5,
    name: 'AmericanSamoa',
    value: '+1684',
  },
  {
    id: 6,
    name: 'Andorra',
    value: '+376',
  },
  {
    id: 7,
    name: 'Angola',
    value: '+244',
  },
  {
    id: 8,
    name: 'Anguilla',
    value: '+1264',
  },
  {
    id: 9,
    name: 'Antarctica',
    value: '+672',
  },
  {
    id: 10,
    name: 'Antigua and Barbuda',
    value: '+1268',
  },
  {
    id: 11,
    name: 'Argentina',
    value: '+54',
  },
  {
    id: 12,
    name: 'Armenia',
    value: '+374',
  },
  {
    id: 13,
    name: 'Aruba',
    value: '+297',
  },
  {
    id: 14,
    name: 'Australia',
    value: '+61',
  },
  {
    id: 15,
    name: 'Austria',
    value: '+43',
  },
  {
    id: 16,
    name: 'Azerbaijan',
    value: '+994',
  },
  {
    id: 17,
    name: 'Bahamas',
    value: '+1242',
  },
  {
    id: 18,
    name: 'Bahrain',
    value: '+973',
  },
  {
    id: 19,
    name: 'Bangladesh',
    value: '+880',
  },
  {
    id: 20,
    name: 'Barbados',
    value: '+1246',
  },
  {
    id: 21,
    name: 'Belarus',
    value: '+375',
  },
  {
    id: 22,
    name: 'Belgium',
    value: '+32',
  },
  {
    id: 23,
    name: 'Belize',
    value: '+501',
  },
  {
    id: 24,
    name: 'Benin',
    value: '+229',
  },
  {
    id: 25,
    name: 'Bermuda',
    value: '+1441',
  },
  {
    id: 26,
    name: 'Bhutan',
    value: '+975',
  },
  {
    id: 27,
    name: 'Bolivia, Plurinational State of',
    value: '+591',
  },
  {
    id: 28,
    name: 'Bosnia and Herzegovina',
    value: '+387',
  },
  {
    id: 29,
    name: 'Botswana',
    value: '+267',
  },
  {
    id: 30,
    name: 'Brazil',
    value: '+55',
  },
  {
    id: 31,
    name: 'British Indian Ocean Territory',
    value: '+246',
  },
  {
    id: 32,
    name: 'Brunei Darussalam',
    value: '+673',
  },
  {
    id: 33,
    name: 'Bulgaria',
    value: '+359',
  },
  {
    id: 34,
    name: 'Burkina Faso',
    value: '+226',
  },
  {
    id: 35,
    name: 'Burundi',
    value: '+257',
  },
  {
    id: 36,
    name: 'Cambodia',
    value: '+855',
  },
  {
    id: 37,
    name: 'Cameroon',
    value: '+237',
  },
  {
    id: 38,
    name: 'Canada',
    value: '+1',
  },
  {
    id: 39,
    name: 'Cape Verde',
    value: '+238',
  },
  {
    id: 40,
    name: 'Cayman Islands',
    value: '+ 345',
  },
  {
    id: 41,
    name: 'Central African Republic',
    value: '+236',
  },
  {
    id: 42,
    name: 'Chad',
    value: '+235',
  },
  {
    id: 43,
    name: 'Chile',
    value: '+56',
  },
  {
    id: 44,
    name: 'China',
    value: '+86',
  },
  {
    id: 45,
    name: 'Christmas Island',
    value: '+61',
  },
  {
    id: 46,
    name: 'Cocos (Keeling) Islands',
    value: '+61',
  },
  {
    id: 47,
    name: 'Colombia',
    value: '+57',
  },
  {
    id: 48,
    name: 'Comoros',
    value: '+269',
  },
  {
    id: 49,
    name: 'Congo',
    value: '+242',
  },
  {
    id: 50,
    name: 'Congo, The Democratic Republic of the Congo',
    value: '+243',
  },
  {
    id: 51,
    name: 'Cook Islands',
    value: '+682',
  },
  {
    id: 52,
    name: 'Costa Rica',
    value: '+506',
  },
  {
    id: 53,
    name: 'Cote d Ivoire',
    value: '+225',
  },
  {
    id: 54,
    name: 'Croatia',
    value: '+385',
  },
  {
    id: 55,
    name: 'Cuba',
    value: '+53',
  },
  {
    id: 56,
    name: 'Cyprus',
    value: '+357',
  },
  {
    id: 57,
    name: 'Czech Republic',
    value: '+420',
  },
  {
    id: 58,
    name: 'Denmark',
    value: '+45',
  },
  {
    id: 59,
    name: 'Djibouti',
    value: '+253',
  },
  {
    id: 60,
    name: 'Dominica',
    value: '+1767',
  },
  {
    id: 61,
    name: 'Dominican Republic',
    value: '+1849',
  },
  {
    id: 62,
    name: 'Ecuador',
    value: '+593',
  },
  {
    id: 63,
    name: 'Egypt',
    value: '+20',
  },
  {
    id: 64,
    name: 'El Salvador',
    value: '+503',
  },
  {
    id: 65,
    name: 'Equatorial Guinea',
    value: '+240',
  },
  {
    id: 66,
    name: 'Eritrea',
    value: '+291',
  },
  {
    id: 67,
    name: 'Estonia',
    value: '+372',
  },
  {
    id: 68,
    name: 'Ethiopia',
    value: '+251',
  },
  {
    id: 69,
    name: 'Falkland Islands (Malvinas)',
    value: '+500',
  },
  {
    id: 70,
    name: 'Faroe Islands',
    value: '+298',
  },
  {
    id: 71,
    name: 'Fiji',
    value: '+679',
  },
  {
    id: 72,
    name: 'Finland',
    value: '+358',
  },
  {
    id: 73,
    name: 'France',
    value: '+33',
  },
  {
    id: 74,
    name: 'French Guiana',
    value: '+594',
  },
  {
    id: 75,
    name: 'French Polynesia',
    value: '+689',
  },
  {
    id: 76,
    name: 'Gabon',
    value: '+241',
  },
  {
    id: 77,
    name: 'Gambia',
    value: '+220',
  },
  {
    id: 78,
    name: 'Georgia',
    value: '+995',
  },
  {
    id: 79,
    name: 'Germany',
    value: '+49',
  },
  {
    id: 80,
    name: 'Ghana',
    value: '+233',
  },
  {
    id: 81,
    name: 'Gibraltar',
    value: '+350',
  },
  {
    id: 82,
    name: 'Greece',
    value: '+30',
  },
  {
    id: 83,
    name: 'Greenland',
    value: '+299',
  },
  {
    id: 84,
    name: 'Grenada',
    value: '+1473',
  },
  {
    id: 85,
    name: 'Guadeloupe',
    value: '+590',
  },
  {
    id: 86,
    name: 'Guam',
    value: '+1671',
  },
  {
    id: 87,
    name: 'Guatemala',
    value: '+502',
  },
  {
    id: 88,
    name: 'Guernsey',
    value: '+44',
  },
  {
    id: 89,
    name: 'Guinea',
    value: '+224',
  },
  {
    id: 90,
    name: 'Guinea-Bissau',
    value: '+245',
  },
  {
    id: 91,
    name: 'Guyana',
    value: '+595',
  },
  {
    id: 92,
    name: 'Haiti',
    value: '+509',
  },
  {
    id: 93,
    name: 'Holy See (Vatican City State)',
    value: '+379',
  },
  {
    id: 94,
    name: 'Honduras',
    value: '+504',
  },
  {
    id: 95,
    name: 'Hong Kong',
    value: '+852',
  },
  {
    id: 96,
    name: 'Hungary',
    value: '+36',
  },
  {
    id: 97,
    name: 'Iceland',
    value: '+354',
  },
  {
    id: 98,
    name: 'India',
    value: '+91',
  },
  {
    id: 99,
    name: 'Indonesia',
    value: '+62',
  },
  {
    id: 100,
    name: 'Iran, Islamic Republic of Persian Gulf',
    value: '+98',
  },
  {
    id: 101,
    name: 'Iraq',
    value: '+964',
  },
  {
    id: 102,
    name: 'Ireland',
    value: '+353',
  },
  {
    id: 103,
    name: 'Isle of Man',
    value: '+44',
  },
  {
    id: 104,
    name: 'Israel',
    value: '+972',
  },
  {
    id: 105,
    name: 'Italy',
    value: '+39',
  },
  {
    id: 106,
    name: 'Jamaica',
    value: '+1876',
  },
  {
    id: 107,
    name: 'Japan',
    value: '+81',
  },
  {
    id: 108,
    name: 'Jersey',
    value: '+44',
  },
  {
    id: 109,
    name: 'Jordan',
    value: '+962',
  },
  {
    id: 110,
    name: 'Kazakhstan',
    value: '+77',
  },
  {
    id: 111,
    name: 'Kenya',
    value: '+254',
  },
  {
    id: 112,
    name: 'Kiribati',
    value: '+686',
  },
  {
    id: 113,
    name: 'Korea, Democratic People s Republic of Korea',
    value: '+850',
  },
  {
    id: 114,
    name: 'Korea, Republic of South Korea',
    value: '+82',
  },
  {
    id: 115,
    name: 'Kuwait',
    value: '+965',
  },
  {
    id: 116,
    name: 'Kyrgyzstan',
    value: '+996',
  },
  {
    id: 117,
    name: 'Laos',
    value: '+856',
  },
  {
    id: 118,
    name: 'Latvia',
    value: '+371',
  },
  {
    id: 119,
    name: 'Lebanon',
    value: '+961',
  },
  {
    id: 120,
    name: 'Lesotho',
    value: '+266',
  },
  {
    id: 121,
    name: 'Liberia',
    value: '+231',
  },
  {
    id: 122,
    name: 'Libyan Arab Jamahiriya',
    value: '+218',
  },
  {
    id: 123,
    name: 'Liechtenstein',
    value: '+423',
  },
  {
    id: 124,
    name: 'Lithuania',
    value: '+370',
  },
  {
    id: 125,
    name: 'Luxembourg',
    value: '+352',
  },
  {
    id: 126,
    name: 'Macao',
    value: '+853',
  },
  {
    id: 127,
    name: 'Macedonia',
    value: '+389',
  },
  {
    id: 128,
    name: 'Madagascar',
    value: '+261',
  },
  {
    id: 129,
    name: 'Malawi',
    value: '+265',
  },
  {
    id: 130,
    name: 'Malaysia',
    value: '+60',
  },
  {
    id: 131,
    name: 'Maldives',
    value: '+960',
  },
  {
    id: 132,
    name: 'Mali',
    value: '+223',
  },
  {
    id: 133,
    name: 'Malta',
    value: '+356',
  },
  {
    id: 134,
    name: 'Marshall Islands',
    value: '+692',
  },
  {
    id: 135,
    name: 'Martinique',
    value: '+596',
  },
  {
    id: 136,
    name: 'Mauritania',
    value: '+222',
  },
  {
    id: 137,
    name: 'Mauritius',
    value: '+230',
  },
  {
    id: 138,
    name: 'Mayotte',
    value: '+262',
  },
  {
    id: 139,
    name: 'Mexico',
    value: '+52',
  },
  {
    id: 140,
    name: 'Micronesia, Federated States of Micronesia',
    value: '+691',
  },
  {
    id: 141,
    name: 'Moldova',
    value: '+373',
  },
  {
    id: 142,
    name: 'Monaco',
    value: '+377',
  },
  {
    id: 143,
    name: 'Mongolia',
    value: '+976',
  },
  {
    id: 144,
    name: 'Montenegro',
    value: '+382',
  },
  {
    id: 145,
    name: 'Montserrat',
    value: '+1664',
  },
  {
    id: 146,
    name: 'Morocco',
    value: '+212',
  },
  {
    id: 147,
    name: 'Mozambique',
    value: '+258',
  },
  {
    id: 148,
    name: 'Myanmar',
    value: '+95',
  },
  {
    id: 149,
    name: 'Namibia',
    value: '+264',
  },
  {
    id: 150,
    name: 'Nauru',
    value: '+674',
  },
  {
    id: 151,
    name: 'Nepal',
    value: '+977',
  },
  {
    id: 152,
    name: 'Netherlands',
    value: '+31',
  },
  {
    id: 153,
    name: 'Netherlands Antilles',
    value: '+599',
  },
  {
    id: 154,
    name: 'New Caledonia',
    value: '+687',
  },
  {
    id: 155,
    name: 'New Zealand',
    value: '+64',
  },
  {
    id: 156,
    name: 'Nicaragua',
    value: '+505',
  },
  {
    id: 157,
    name: 'Niger',
    value: '+227',
  },
  {
    id: 158,
    name: 'Nigeria',
    value: '+234',
  },
  {
    id: 159,
    name: 'Niue',
    value: '+683',
  },
  {
    id: 160,
    name: 'Norfolk Island',
    value: '+672',
  },
  {
    id: 161,
    name: 'Northern Mariana Islands',
    value: '+1670',
  },
  {
    id: 162,
    name: 'Norway',
    value: '+47',
  },
  {
    id: 163,
    name: 'Oman',
    value: '+968',
  },
  {
    id: 164,
    name: 'Pakistan',
    value: '+92',
  },
  {
    id: 165,
    name: 'Palau',
    value: '+680',
  },
  {
    id: 166,
    name: 'Palestinian Territory, Occupied',
    value: '+970',
  },
  {
    id: 167,
    name: 'Panama',
    value: '+507',
  },
  {
    id: 168,
    name: 'Papua New Guinea',
    value: '+675',
  },
  {
    id: 169,
    name: 'Paraguay',
    value: '+595',
  },
  {
    id: 170,
    name: 'Peru',
    value: '+51',
  },
  {
    id: 171,
    name: 'Philippines',
    value: '+63',
  },
  {
    id: 172,
    name: 'Pitcairn',
    value: '+872',
  },
  {
    id: 173,
    name: 'Poland',
    value: '+48',
  },
  {
    id: 174,
    name: 'Portugal',
    value: '+351',
  },
  {
    id: 175,
    name: 'Puerto Rico',
    value: '+1939',
  },
  {
    id: 176,
    name: 'Qatar',
    value: '+974',
  },
  {
    id: 177,
    name: 'Romania',
    value: '+40',
  },
  {
    id: 178,
    name: 'Russia',
    value: '+7',
  },
  {
    id: 179,
    name: 'Rwanda',
    value: '+250',
  },
  {
    id: 180,
    name: 'Reunion',
    value: '+262',
  },
  {
    id: 181,
    name: 'Saint Barthelemy',
    value: '+590',
  },
  {
    id: 182,
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    value: '+290',
  },
  {
    id: 183,
    name: 'Saint Kitts and Nevis',
    value: '+1869',
  },
  {
    id: 184,
    name: 'Saint Lucia',
    value: '+1758',
  },
  {
    id: 185,
    name: 'Saint Martin',
    value: '+590',
  },
  {
    id: 186,
    name: 'Saint Pierre and Miquelon',
    value: '+508',
  },
  {
    id: 187,
    name: 'Saint Vincent and the Grenadines',
    value: '+1784',
  },
  {
    id: 188,
    name: 'Samoa',
    value: '+685',
  },
  {
    id: 189,
    name: 'San Marino',
    value: '+378',
  },
  {
    id: 190,
    name: 'Sao Tome and Principe',
    value: '+239',
  },
  {
    id: 191,
    name: 'Saudi Arabia',
    value: '+966',
  },
  {
    id: 192,
    name: 'Senegal',
    value: '+221',
  },
  {
    id: 193,
    name: 'Serbia',
    value: '+381',
  },
  {
    id: 194,
    name: 'Seychelles',
    value: '+248',
  },
  {
    id: 195,
    name: 'Sierra Leone',
    value: '+232',
  },
  {
    id: 196,
    name: 'Singapore',
    value: '+65',
  },
  {
    id: 197,
    name: 'Slovakia',
    value: '+421',
  },
  {
    id: 198,
    name: 'Slovenia',
    value: '+386',
  },
  {
    id: 199,
    name: 'Solomon Islands',
    value: '+677',
  },
  {
    id: 200,
    name: 'Somalia',
    value: '+252',
  },
  {
    id: 201,
    name: 'South Africa',
    value: '+27',
  },
  {
    id: 202,
    name: 'South Sudan',
    value: '+211',
  },
  {
    id: 203,
    name: 'South Georgia and the South Sandwich Islands',
    value: '+500',
  },
  {
    id: 204,
    name: 'Spain',
    value: '+34',
  },
  {
    id: 205,
    name: 'Sri Lanka',
    value: '+94',
  },
  {
    id: 206,
    name: 'Sudan',
    value: '+249',
  },
  {
    id: 207,
    name: 'Suriname',
    value: '+597',
  },
  {
    id: 208,
    name: 'Svalbard and Jan Mayen',
    value: '+47',
  },
  {
    id: 209,
    name: 'Swaziland',
    value: '+268',
  },
  {
    id: 210,
    name: 'Sweden',
    value: '+46',
  },
  {
    id: 211,
    name: 'Switzerland',
    value: '+41',
  },
  {
    id: 212,
    name: 'Syrian Arab Republic',
    value: '+963',
  },
  {
    id: 213,
    name: 'Taiwan',
    value: '+886',
  },
  {
    id: 214,
    name: 'Tajikistan',
    value: '+992',
  },
  {
    id: 215,
    name: 'Tanzania, United Republic of Tanzania',
    value: '+255',
  },
  {
    id: 216,
    name: 'Thailand',
    value: '+66',
  },
  {
    id: 217,
    name: 'Timor-Leste',
    value: '+670',
  },
  {
    id: 218,
    name: 'Togo',
    value: '+228',
  },
  {
    id: 219,
    name: 'Tokelau',
    value: '+690',
  },
  {
    id: 220,
    name: 'Tonga',
    value: '+676',
  },
  {
    id: 221,
    name: 'Trinidad and Tobago',
    value: '+1868',
  },
  {
    id: 222,
    name: 'Tunisia',
    value: '+216',
  },
  {
    id: 223,
    name: 'Turkey',
    value: '+90',
  },
  {
    id: 224,
    name: 'Turkmenistan',
    value: '+993',
  },
  {
    id: 225,
    name: 'Turks and Caicos Islands',
    value: '+1649',
  },
  {
    id: 226,
    name: 'Tuvalu',
    value: '+688',
  },
  {
    id: 227,
    name: 'Uganda',
    value: '+256',
  },
  {
    id: 228,
    name: 'Ukraine',
    value: '+380',
  },
  {
    id: 229,
    name: 'United Arab Emirates',
    value: '+971',
  },
  {
    id: 230,
    name: 'United Kingdom',
    value: '+44',
  },
  {
    id: 231,
    name: 'United States',
    value: '+1',
  },
  {
    id: 232,
    name: 'Uruguay',
    value: '+598',
  },
  {
    id: 233,
    name: 'Uzbekistan',
    value: '+998',
  },
  {
    id: 234,
    name: 'Vanuatu',
    value: '+678',
  },
  {
    id: 235,
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    value: '+58',
  },
  {
    id: 236,
    name: 'Vietnam',
    value: '+84',
  },
  {
    id: 237,
    name: 'Virgin Islands, British',
    value: '+1284',
  },
  {
    id: 238,
    name: 'Virgin Islands, U.S.',
    value: '+1340',
  },
  {
    id: 239,
    name: 'Wallis and Futuna',
    value: '+681',
  },
  {
    id: 240,
    name: 'Yemen',
    value: '+967',
  },
  {
    id: 241,
    name: 'Zambia',
    value: '+260',
  },
  {
    id: 242,
    name: 'Zimbabwe',
    value: '+263',
  },
];

const stateData = [
  {
    id: '1',
    name: 'Karnataka',
  },
];

const cityData = [
  {
    id: '1',
    name: 'Bengaluru',
  },
  {
    id: '2',
    name: 'Mysore',
  },
];

const digit = [
  {
    id: '4',
    name: '4 Digit',
  },
  {
    id: '6',
    name: '6 Digit',
  },
  {
    id: '8',
    name: '8 Digit',
  },
];




const ScanLimit = [
  {
    id: '1',
    name: '1 times',
  },
  {
    id: '2',
    name: '2 times',
  },
  {
    id: '3',
    name: '3 times',
  },
  {
    id: '4',
    name: '4 times',
  },
  {
    id: '5',
    name: '5 times',
  },
]

const timeZoneData = [
  {
    id: '1',
    name: '(GMT-12:00) International Date Line West',
  },
  {
    id: '2',
    name: '(GMT-11:00) Midway Island, Samoa',
  },
  {
    id: '3',
    name: '(GMT-10:00) Hawaii',
  },
  {
    id: '4',
    name: '(GMT-09:00) Alaska',
  },
  {
    id: '5',
    name: '(GMT-08:00) Pacific Time (US & Canada)',
  },
  {
    id: '6',
    name: '(GMT-08:00) Tijuana, Baja California',
  },
  {
    id: '7',
    name: '(GMT-07:00) Arizona',
  },
  {
    id: '8',
    name: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
  },
  {
    id: '9',
    name: '(GMT-07:00) Mountain Time (US & Canada)',
  },
  {
    id: '10',
    name: '(GMT-06:00) Central America',
  },
  {
    id: '11',
    name: '(GMT-06:00) Central Time (US & Canada)',
  },
  {
    id: '12',
    name: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
  },
  {
    id: '13',
    name: '(GMT-06:00) Saskatchewan',
  },
  {
    id: '14',
    name: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
  },
  {
    id: '15',
    name: '(GMT-05:00) Eastern Time (US & Canada)',
  },
  {
    id: '16',
    name: '(GMT-05:00) Indiana (East)',
  },
  {
    id: '17',
    name: '(GMT-04:00) Atlantic Time (Canada)',
  },
  {
    id: '18',
    name: '(GMT-04:00) Caracas, La Paz',
  },
  {
    id: '19',
    name: '(GMT-04:00) Manaus',
  },
  {
    id: '20',
    name: '(GMT-04:00) Santiago',
  },
  {
    id: '21',
    name: '(GMT-03:30) Newfoundland',
  },
  {
    id: '22',
    name: '(GMT-03:00) Brasilia',
  },
  {
    id: '23',
    name: '(GMT-03:00) Buenos Aires, Georgetown',
  },
  {
    id: '24',
    name: '(GMT-03:00) Greenland',
  },
  {
    id: '25',
    name: '(GMT-03:00) Montevideo',
  },
  {
    id: '26',
    name: '(GMT-02:00) Mid-Atlantic',
  },
  {
    id: '27',
    name: '(GMT-01:00) Cape Verde Is.',
  },
  {
    id: '28',
    name: '(GMT-01:00) Azores',
  },
  {
    id: '29',
    name: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
  },
  {
    id: '30',
    name: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
  },
  {
    id: '31',
    name: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    id: '32',
    name: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    id: '33',
    name: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    id: '34',
    name: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    id: '35',
    name: '(GMT+01:00) West Central Africa',
  },
  {
    id: '36',
    name: '(GMT+02:00) Amman',
  },
  {
    id: '37',
    name: '(GMT+02:00) Athens, Bucharest, Istanbul',
  },
  {
    id: '38',
    name: '(GMT+02:00) Beirut',
  },
  {
    id: '39',
    name: '(GMT+02:00) Cairo',
  },
  {
    id: '40',
    name: '(GMT+02:00) Harare, Pretoria',
  },
  {
    id: '41',
    name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    id: '42',
    name: '(GMT+02:00) Jerusalem',
  },
  {
    id: '43',
    name: '(GMT+02:00) Minsk',
  },
  {
    id: '44',
    name: '(GMT+02:00) Windhoek',
  },
  {
    id: '45',
    name: '(GMT+03:00) Kuwait, Riyadh, Baghdad',
  },
  {
    id: '46',
    name: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
  },
  {
    id: '47',
    name: '(GMT+03:00) Nairobi',
  },
  {
    id: '48',
    name: '(GMT+03:00) Tbilisi',
  },
  {
    id: '49',
    name: '(GMT+03:30) Tehran',
  },
  {
    id: '50',
    name: '(GMT+04:00) Abu Dhabi, Muscat',
  },
  {
    id: '51',
    name: '(GMT+04:00) Baku',
  },
  {
    id: '52',
    name: '(GMT+04:00) Yerevan',
  },
  {
    id: '53',
    name: '(GMT+04:30) Kabul',
  },
  {
    id: '54',
    name: '(GMT+05:00) Yekaterinburg',
  },
  {
    id: '55',
    name: '(GMT+05:00) Islamabad, Karachi, Tashkent',
  },
  {
    id: '56',
    name: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    id: '57',
    name: '(GMT+05:30) Sri Jayawardenapura',
  },
  {
    id: '58',
    name: '(GMT+05:45) Kathmandu',
  },
  {
    id: '59',
    name: '(GMT+06:00) Almaty, Novosibirsk',
  },
  {
    id: '60',
    name: '(GMT+06:00) Astana, Dhaka',
  },
  {
    id: '61',
    name: '(GMT+06:30) Yangon (Rangoon)',
  },
  {
    id: '62',
    name: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
  },
  {
    id: '63',
    name: '(GMT+07:00) Krasnoyarsk',
  },
  {
    id: '64',
    name: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    id: '65',
    name: '(GMT+08:00) Kuala Lumpur, Singapore',
  },
  {
    id: '66',
    name: '(GMT+08:00) Irkutsk, Ulaan Bataar',
  },
  {
    id: '67',
    name: '(GMT+08:00) Perth',
  },
  {
    id: '68',
    name: '(GMT+08:00) Taipei',
  },
  {
    id: '69',
    name: '(GMT+09:00) Osaka, Sapporo, Tokyo',
  },
  {
    id: '70',
    name: '(GMT+09:00) Seoul',
  },
  {
    id: '71',
    name: '(GMT+09:00) Yakutsk',
  },
  {
    id: '72',
    name: '(GMT+09:30) Adelaide',
  },
  {
    id: '73',
    name: '(GMT+09:30) Darwin',
  },
  {
    id: '74',
    name: '(GMT+10:00) Brisbane',
  },
  {
    id: '75',
    name: '(GMT+10:00) Canberra, Melbourne, Sydney',
  },
  {
    id: '76',
    name: '(GMT+10:00) Hobart',
  },
  {
    id: '77',
    name: '(GMT+10:00) Guam, Port Moresby',
  },
  {
    id: '78',
    name: '(GMT+10:00) Vladivostok',
  },
  {
    id: '79',
    name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
  },
  {
    id: '80',
    name: '(GMT+12:00) Auckland, Wellington',
  },
  {
    id: '81',
    name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
  },
  {
    id: '82',
    name: "(GMT+13:00) Nuku'alofa",
  },
];


const gender = [
  {
    id: "M",
    name: "Male",
    value: "M"
  },
  {
    id: 'F',
    name: 'Female',
    value: "F"
  },
  {
    id: 'O',
    name: 'Other',
    value: "O"
  }
]
export { countryData, stateData, cityData, timeZoneData, gender, digit, ScanLimit };
