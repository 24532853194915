import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import CommonUtil from "../Util/CommonUtils";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from "@mui/system";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#FFFFFF"
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FFFFFF"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFFFF",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF"
    },
    "&.MuiInputBase-root .MuiOutlinedInput-input": {
      color: '#fff'
    },
  },
  "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-error.MuiFormLabel-filled":{
    color: '#FFA2A4 !important',
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline" : {
    borderColor: '#FFA2A4 !important',
  },
  "& .MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained.MuiFormHelperText-filled" : {
    color: '#FFA2A4 !important',
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink":{
    color:'#fff !important'
  },
  "& .MuiInputLabel-outlined":{
    color:'#fff !important'
  }
});


export default function USACustomTextfield(props) {
  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);
  const [value, setValue] = useState(props.value);
  const { t } = useTranslation();
  // const classes = useStyles();

  useEffect(() => {
    setError(props.error);
    setHelperText(props.helperText);
  }, [props.error]);

  const handleChange = (e) => {
    var val = e.target.value;
    if (val.trim() === "") {
      val = val.trim();
    }
    if (e.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === " " && val[val.length - 2] === " ") {
        return;
      }
      if (val[0] === " ") {
        return;
      }
    }
    if (props.regex === "none" && props.validation) {
      switch (props.validation) {
        case "alpha-numeric":
          handleCode(e);
          break;
        case "numeric":
          handleNumeric(e);
          break;
        case "email":
          handleEmail(e);
          break;
        case "password":
          handlePassword(e);
          break;
        case "code":
          handleCode(e);
          break;
        default:
          props.handleChange(e);
      }
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleAlphaNumeric = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("Only accepts alphabets, numerics and space"));
    }
  };

  const handleCode = (e) => {
    if (CommonUtil.isAlphaNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('ACCEPT_ALPHANUMERIC'));
    }
  };

  const handleNumeric = (e) => {
    if (CommonUtil.isValidNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('INVITATIONFORM019'));
    }
  };

  const handleEmail = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidEmail(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("INVITATIONFORM014"));
    }
  };

  const handlePassword = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidPassword(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    }
    //  else {
    //   setError(true);
    //   setHelperText(t('ONLY_ACCEPT_PASSWORD'));
    // }
  };

  return (
    <CssTextField
      style={{ background: "transparent" }}
      autoComplete="off"
      {...props}
      required={props.required}
      disabled={props.disabled}
      value={props.value}
      helperText={helperText}
      error={error}
      onChange={(e) => handleChange(e)}
      margin="normal"
      variant="outlined"
      fullWidth
      size="medium"
      type={props.type}
      label={props.label}
      name={props.name}
      // className={classes.textStyle}
      onBlur={props.onBlur}
      className='login_input'
      InputLabelProps={props.InputLabelProps ? { shrink: props.InputLabelProps } : undefined}
      // InputLabelProps={{ shrink: true }}
      InputProps={
        props.InputProps
      }
    />
  );
}
USACustomTextfield.defaultProps = {
  validation: "none",
  regex: "none",
  required: false,
  disabled: false,
};

USACustomTextfield.propType = {
  validation: PropTypes.oneOf([
    "alpha-numeric",
    "alphabets",
    "numeric",
    "email",
    "password",
    "code",
  ]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};
