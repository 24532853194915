import api from '../config/http-common';

const get = (payload) => {
    return api.securedAxios().get('/web/sites');
};

const getSiteById = (siteId) => {
    return api.securedAxios().get('/web/site/' + siteId);
};

const SiteService = {
    get,
    getSiteById
};

export default SiteService;
