import { Grid } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { Typography } from "@mui/material";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as DeleteVisitIcon } from "../../../src/assets/DeleteVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Toast from "../../components/ToastContainer/CustomToast";
import { deleteBlackListType } from "../../slices/BlacklistType/BlacklistTypeSlice";
import { ThemeContext } from '../../theme/ThemeContext';
import { ReactComponent as DeleteIcon } from '../../assets/DeleteIcon.svg'

export default function DeleteBlacklistType(props) {
    const { open, handleClose } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { darkMode } = useContext(ThemeContext);
    const handleSubmit = () => {

        const id = props.DeleteId;

        dispatch(deleteBlackListType(id))
            .unwrap()
            .then((data) => {
                if (data.code === "CVAI0062") {
                    Toast((data.message), 'success')
                    props.delete();
                    handleClose();
                }
                else {
                    Toast((data.message), 'error')
                    handleClose();
                }
            })
            .catch((er) => { });
    };

    return (
        <>
            <DialogBox
                Header={
                    <span style={{ fontSize: '21px', color: darkMode ? '#fff' : '' }}>
                     {t("COMMON004")}
                    </span>
                  }
                acceptText={t("confirmVisitorButton")}
                cancelText={t("dataTableCancel")}
                fullWidth={true}
                onClose={() => handleClose(false)}
                cancel={handleClose}
                onSubmit={handleSubmit}
                open={open}
            >
                <Grid
                    container
                    justifyContent="center"
                    direction="column"
                    alignItems={"center"}
                    style={{ padding: "20px", backgroundColor: darkMode ? '#0F273B' : '' }}
                >
                    <Grid>
                        <DeleteIcon width="60px" height={'50px'}/>
                    </Grid>
                    <Grid>
                        <Grid
                            style={{
                                wordWrap: "break-word",
                                opacity: 1,
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "row",
                                fontSize: "17px",
                                marginTop: "15px",
                            }}
                        >
                            <Typography style={{ color: darkMode ? '#fff' : '#242424', marginRight: "4px", fontSize: '17px' }}>
                                {t("COMP0015")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogBox>
        </>
    );
}
