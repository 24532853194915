import { Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import USA_visLogo from "../../assets/USA_Images/login_images/App-logo.svg";
import QRCode from "react-qr-code";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { QRCodeForVisitors } from '../../slices/Invitations/InvitationsSlice';
import moment from "moment";
import CommonUtil from "../../Util/CommonUtils";
import { getCompanyInfo, updateCompanyInfo, getCompanyById } from '../../slices/Company/CompanySlice'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice';
import { getListSettingsById } from '../../slices/AdditionalSettings/AdditionalSettingsSlice';


function QRcode() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [showQr, setShowQr] = useState('');
    const [cardNumber, setCardNumber] = useState(0);
    const [companyId, setCompanyId] = useState('');
    const [message, setMessage] = useState('');
    const [vistorName, setVisitorName] = useState('');
    const [hostName, setHostName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [phoneInput, setPhoneInput] = useState("");
    const [profilePhoto, setProfilePhoto] = useState('');

    var urlValue = window.location.href;
    var url = new URL(urlValue);
    var code = url.searchParams.get("code");

    useEffect(() => {
        if (code != null) {
            dispatch(QRCodeForVisitors(code))
                .unwrap()
                .then((data) => {
                    if (data.code === "CVSI0000") {
                        setCardNumber(data.data.value);
                        setShowQr(data.data.verifyTypeIn);
                        setVisitorName(data.data.name);
                        setHostName(data.data.hostName)
                        setStartDate(data.data.startDate);
                        setEndDate(data.data.endDate);
                    } else {
                        setMessage(data.message);
                    }
                    setCompanyId(data.data.companyId);
                    loadCompany(data.data.companyId);
                });
        } else {
            history.push("/vis/result-page");
        };
    }, [])

    const [company, setCompany] = useState({
        id: '',
        name: '',
        registrationNumber: '',
        owner: '',
        code: '',
        industry: '',
        companySize: '',
        email: '',
        phone: '',
        address: '',
        addressLine2: '',
        createdAt: '',
        city: '',
        state: '',
        pincode: '',
        country: '',
        companyLogo: '',
    })

    const loadCompany = (companyId) => {
        dispatch(getCompanyById(companyId))
            .unwrap()
            .then((data) => {
                if ((data.code = "UASI0000")) {
                    setCompany({
                        id: data.data.id,
                        name: data.data.name,
                        registrationNumber: data.data.registrationNumber,
                        owner: data.data.owner,
                        code: data.data.code,
                        industry: data.data.industry,
                        companySize: data.data.companySize,
                        email: data.data.email,
                        phone: data.data.phone,
                        address: data.data.address,
                        city: data.data.city,
                        state: data.data.state,
                        pincode: data.data.pincode,
                        country: data.data.country,
                        companyLogo: data.data.imagePreSignedURL,
                        createdAt: data.data.createdAt,
                    })
                    setPhoneInput(data.data.phone)
                    setProfilePhoto(data.data.imagePreSignedURL ? data.data.imagePreSignedURL : "")
                    dispatch(getListSettingsById(companyId))
                        .unwrap()
                        .then((data) => {
                            if ((data.code === "CVAI0000")) {
                                setDate(data.data.dateFormat)
                            }
                        })
                }
            })
            .catch((er) => { })
    }

    const [date, setDate] = useState()


    return (
        <>
            <Grid
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                    backgroundColor: '#F4F6F8'
                }}
            >
                <Grid container alignItems='center'>
                    {/* <Grid item className='logo-center' lg={3} md={4}>
                      
                    </Grid>   */}
                    <Grid item className='logo-center' lg={4} sm={12} xs={12}>
                        <img src={profilePhoto} alt='logo' style={{ width: '100px', height: "100px", borderRadius: "50%" }} />
                    </Grid>
                    <Grid item className='logo-center' lg={4} sm={12} xs={12} >
                        {/* <img src={USA_visLogo} alt='logo' style={{ width: '220px' }} /> */}
                        {/* {company.name} */}
                        <Typography variant="h4" sx={{ color: "#2281AB" }}>{company.name}</Typography>
                    </Grid>
                    <Grid item className='logo-center' lg={4} sm={12} xs={12} >
                        <img src={USA_visLogo} alt='logo' style={{ width: '220px' }} />
                    </Grid>
                </Grid>
            </Grid>
            <Paper>
                <Paper elevation={3} style={{ backgroundColor: "white", }}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            {showQr === 'QRCODE' && (
                                <Grid container style={{ minHeight: "20vh", padding: "16px", display: 'flex', justifyContent: "center", alignItems: "center", height: '50vh', }}>
                                    <QRCode value={cardNumber} size={200} />
                                </Grid>
                            )}
                            {showQr === 'PASSCODE' && (
                                <Grid container style={{ minHeight: "20vh", padding: "16px", display: 'flex', justifyContent: "center", alignItems: "center", height: '50vh', fontSize: '50px' }}>
                                    {cardNumber}
                                </Grid>
                            )}
                            {message !== '' && (
                                <Grid container style={{ minHeight: "20vh", padding: "16px", direction: "row", justify: "center", alignItems: "center" }}>
                                    {message}
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ justifyContent: 'center', textAlign: 'left', padding: '50px' }}>
                            <Typography style={{ color: '#3D4977', fontSize: "35px", paddingBottom: "5px", textTransform: 'capitalize' }}>
                                {vistorName}
                            </Typography>
                            <Typography style={{ color: '#F2824C', fontSize: "26px", paddingBottom: "5px" }}>
                                {'Schedule Appointment'}
                            </Typography>
                            <Typography style={{ fontSize: "26px", paddingBottom: "5px" }}>
                                <span style={{ color: '#3D4977', fontSize: "26px" }}>{'Host '}&nbsp;&nbsp;&nbsp;: </span>{hostName}
                            </Typography>
                            <Typography style={{ fontSize: "26px", paddingBottom: "5px" }}>
                                <span style={{ color: '#3D4977', fontSize: "26px" }}>{'Start Date '}&nbsp;:</span>
                                {/* {moment(CommonUtil.getLocalDateTimeInYearFormat(startDate)).format(
                                    "DD-MM-YY HH:mm"
                                )} */}
                                {date === "MM-dd-yyyy"
                                    ? moment(CommonUtil.getLocalDateTimeInYearFormat(startDate)).format("MM-DD-YYYY HH:mm")
                                    : date === "dd-MM-yyyy" ? moment(CommonUtil.getLocalDateTimeInYearFormat(startDate)).format("DD-MM-YYYY HH:mm") : date === "yyyy-MM-dd" ? moment(CommonUtil.getLocalDateTimeInYearFormat(startDate)).format("YYYY-MM-DD HH:mm") : ''}
                            </Typography>
                            <Typography style={{ fontSize: "26px", paddingBottom: "5px" }}>
                                <span style={{ color: '#3D4977', fontSize: "26px" }}>{'End Date '}&nbsp;&nbsp;&nbsp;:</span>
                                {/* {moment(CommonUtil.getLocalDateTimeInYearFormat(endDate)).format(
                                    "DD-MM-YY HH:mm"
                                )} */}
                                {date === "MM-dd-yyyy"
                                    ? moment(CommonUtil.getLocalDateTimeInYearFormat(endDate)).format("MM-DD-YYYY HH:mm")
                                    : date === "dd-MM-yyyy" ? moment(CommonUtil.getLocalDateTimeInYearFormat(endDate)).format("DD-MM-YYYY HH:mm") : date === "yyyy-MM-dd" ? moment(CommonUtil.getLocalDateTimeInYearFormat(endDate)).format("YYYY-MM-DD HH:mm") : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Paper>
        </>
    )
}

export default QRcode
