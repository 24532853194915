import { Box, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import checklistIcon from "../../assets/checklist.svg";
import DataTable from "../../components/DataTable/DataTable";

import "../../App.css";
import Search from "../../components/SearchTab/Search";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import EditChecklistIcon from "./EditChecklistIcon";
import HeaderToolbar from "../../components/HeaderToolbar";
import ChecklistTemplateForm from "./ChecklistTemplateForm";
import ChecklistFilterForm from "./ChecklistFilterForm";
import { listChecklistTemplates } from "../../slices/CheckListSlice/CheckListSlice";
import ChecklistTemplateDetails from "./ChecklistTemplateDetails";
import ChecklistIcon from "./ChecklistIcon";
import { useHistory } from "react-router-dom";
import { REACT_URL_CHECKLIST_ITEM } from "../../actions/EndPoints";

export default function ChecklistTemplate(props) {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [clear, setClear] = React.useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openChecklistItem, setOpenChecklistItem] = useState(false);
  const [watchlistId, setWatchlistId] = useState();
  const [selectedObject, setSelectedObject] = useState({
    id: "",
    name: "",
    companyId: "",
    code: "",
    description: "",
  });

  const [filter, setFilter] = React.useState({
    id: "",
    name: "",
    companyId: "",
    code: "",
    description: "",
  });

  const defaultFilter = {
    id: "",
    name: "",
    companyId: "",
    code: "",
    description: "",
  };

  const applyFilter = (filterData) => {
    setClear(true);
    const query = {
      id: filterData.id,
      name: filterData.name,
      companyId: filterData.companyId,
      code: filterData.code,
      description: filterData.description,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setClear(false);
    setFilter({
      ...defaultFilter,
    });
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    loadData(newPage, size);
    setRowsPerPage(size);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadData(1, rowsPerPage);
  }, [filter]);

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: filter,
      page: page,
      rowsPerPage: rowsPerPage,
    };

    dispatch(listChecklistTemplates(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI1000" && data.data) {
          setTotalRecords(data.data.totalCount);
          setRows(data.data.checklistTemplates);
          setPage(data.data.currentPage);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog);
    setSelectedObject(params);
  };

  const handleChecklistItem = (params) => {
  //  setSelectedObject(params.row);
      history.push({
      pathname: REACT_URL_CHECKLIST_ITEM,
      state: { data: params.row },
    });


  };

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        test.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.code.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.description.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };
  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.name,
    },

    (process.env.REACT_APP_ENVIRONMENT === 'USA'?
    {
      field: "code",
      headerName: t("COMMON067"),
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.code,
    } :

    {
      field: "code",
      headerName: "Code",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.code,
    }),

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
    },

    {
      field: "Actions",
      headerName: "Action",
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ChecklistTemplateDetails
            title="Checklist Template Details"
            rowData={params.row} />}
          label="View"
        />,

        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label="Edit"
          onClick={() => handleEditVisit(params.row)}
        />,

        <GridActionsCellItem
          icon={<ChecklistIcon />}
          label="Delete"
          onClick={() => handleChecklistItem(params)}
        />,
      ],
    },
  ]);

  return (
    <>
      <HeaderToolbar src={checklistIcon} onClick={handleOpen} title="Checklist Template" tooltipTitle="Add Checklist Template" />
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <ChecklistFilterForm
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(page, rowsPerPage)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <ChecklistTemplateForm
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            handleClose(loadData(page, rowsPerPage));
          }}
        />
      )}

      {openEditDialog && (
        <ChecklistTemplateForm
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false);
            loadData(page, rowsPerPage);
            setSelectedObject({
              id: "",
              name: "",
              code: "",
              description: "",
            });
          }}
        />
      )}
    </>
  );
}
