import { QueryBuilder } from "@mui/icons-material";
import api from "../../config/http-common";
import { JAVA_URL_DEVICES, JAVA_URL_SEARCH_DEVICES } from "../EndPoints";

export const searchDevices = (payload) => {
    return api.securedAxios().get(JAVA_URL_SEARCH_DEVICES + buildQueryforSearch(payload));
};

export const searchSdmsDevices = () => {
    const companyCode = localStorage.getItem('companyCode');
    return api.securedAxios().get("/api/v1.0/visitor/device" + "?companyCode=" + companyCode);
};

export const listAccDevices = (payload) => {
    return api.securedAxios().get(JAVA_URL_DEVICES + buildQuery(payload));
};


const buildQuery = (payload) => {
    let query = '?deviceType=acc';

    if (payload.deviceName !== ''){
        query = query + '&deviceName=' + payload.deviceName;
    }
    if (payload.serialNumber !== '') {
        query = query + '&serialNumber=' + payload.serialNumber;
    }
    if (payload.ipAddress !== ''){
        query = query + '&ipAddress=' + payload.ipAddress;
    }
    return query + '&pageNumber=' + payload.page + '&pageSize=' + payload.size;
}

const buildQueryforSearch = (payload) => {
    let query = '?deviceType=acc';

    if (payload.deviceName !== ''){
        query = query + '&deviceName=' + payload.deviceName;
    }
    if (payload.sn !== '') {
        query = query + '&sn=' + payload.sn;
    }
    if (payload.ipAddress !== ''){
        query = query + '&ipAddress=' + payload.ipAddress;
    }
    return query + '&page=' + payload.page + '&size=' + payload.size;
}

const buildQueryForCloudAccess = (payload) => {
    let query = '?deviceType=acc';

    if (payload.deviceName !== '')
        query = query + '&deviceName=' + payload.deviceName;
    if (payload.serialNumber !== '')
        query = query + '&sn=' + payload.sn;
    if (payload.ipAddress !== '')
        query = query + '&ipAddress=' + payload.ipAddress;

    return query + '&page=' + payload.page + '&size=' + payload.size;
}

export const addDevice = (query) => {

    return api.securedAxios().post(JAVA_URL_DEVICES, query);
}

export const removeDevice = (ids) => {
    return api.securedAxios().delete(JAVA_URL_DEVICES + "?ids=" + ids);
}

const CheckInCheckOutDevicesService = {
    searchDevices,
    searchSdmsDevices,
    listAccDevices,
    addDevice,
    removeDevice
}

export default CheckInCheckOutDevicesService;