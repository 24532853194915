import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const EditWatchlistIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t("COMMON005")}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.977"
              height="17.977"
              viewBox="0 0 17.977 17.977"
            >
              <path
                id="Edit"
                d="M3,18V13.76a1,1,0,0,1,.29-.71l2.35-2.34h0l7-7h0L14.45,1.9a3,3,0,0,1,4.24,0L20.1,3.31a3,3,0,0,1,0,4.24L9,18.71a1,1,0,0,1-.71.29H4A1,1,0,0,1,3,18Zm4.78-6.61,2.83,2.83,5.58-5.58L13.36,5.81ZM15.9,3.27,14.78,4.39l2.83,2.83L18.73,6.1a1,1,0,0,0,0-1.41L17.31,3.27A1,1,0,0,0,15.9,3.27ZM5,17H7.83l1.36-1.36L6.36,12.81,5,14.17Z"
                transform="translate(-3 -1.023)"
                fill="#3d4977"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.977"
              height="17.977"
              viewBox="0 0 17.977 17.977"
            >
              <path
                id="Layer_5"
                data-name="Layer 5"
                d="M3,18V13.76a1,1,0,0,1,.29-.71l2.35-2.34h0l7-7h0L14.45,1.9a3,3,0,0,1,4.24,0L20.1,3.31a3,3,0,0,1,0,4.24L9,18.71a1,1,0,0,1-.71.29H4A1,1,0,0,1,3,18Zm4.78-6.61,2.83,2.83,5.58-5.58L13.36,5.81ZM15.9,3.27,14.78,4.39l2.83,2.83L18.73,6.1a1,1,0,0,0,0-1.41L17.31,3.27A1,1,0,0,0,15.9,3.27ZM5,17H7.83l1.36-1.36L6.36,12.81,5,14.17Z"
                transform="translate(-3 -1.023)"
                fill="#c0c7cc"
              />
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default EditWatchlistIcon;
