import { MenuItem, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import jwt_decode from 'jwt-decode'

function HoverHelper(props) {

    const [image, setImage] = React.useState(props.normalImage);
    var decoded_jwtToken = jwt_decode(
        localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
      )

    const over = (e) => {
        setImage(props.hoverImage);
    };

    const out = (e) => {
        setImage(props.normalImage);
    }

    const openPdfInNewTab = () => {
        if(decoded_jwtToken.companyType === 'NONE'){
        window.open('../MySmartGuardPlus_HQ_User_Manual.pdf', '_blank');
        }
        if(decoded_jwtToken.companyType === 'PARTNER'){
            window.open('../MySmartGuardPlus_Delear_User_Manual.pdf' , '_blank')
        }
        if(decoded_jwtToken.companyType === 'CUSTOMER'){
            window.open('../MySmartGuardPlus_Customer_User_Manual.pdf','_blank')
        }
      };

    return (
        <>
            <MenuItem
                onMouseOver={over}
                onMouseOut={out}
                // component={Link}
                onClick={openPdfInNewTab}
                to={props.link}
                sx={[
                    (theme) => ({
                        '&:hover': {
                            background: process.env.REACT_APP_ENVIRONMENT === 'USA' ?  '' : process.env.REACT_APP_BG_ICON,
                            color:process.env.REACT_APP_BG_HEADER_PROFILE
                        },
                    }),
                ]}
            >
                <img src={image} alt={props.alt} style={{ paddingRight: '10px' }} />
               <Typography>{props.item}</Typography> 
            </MenuItem>
        </>
    )
}

export default HoverHelper;