import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import isoCountries from 'i18n-iso-countries';
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomPhone from "../../components/CustomInputs/CustomPhone";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { getListSettings } from "../../slices/AdditionalSettings/AdditionalSettingsSlice";
import { getCompanyById } from "../../slices/Company/CompanySlice";
import { acceptApprovalVisit } from "../../slices/Invitations/InvitationsSlice";
import { getPurposeType } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";
import { getVisitorAccessLevels } from "../../slices/VisitorAccessLevels/VisitorAccessLevelSlice";
import CommonUtil from "../../Util/CommonUtils";
import CustomPhones from "../OnBoardV2/CustomPhones";
import { ThemeContext } from '../../theme/ThemeContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ApprovalPageWalkIn(props) {
  const { open, handleClose, selectedObject } = props;
  const { t } = useTranslation();
  const [selectedAccessLevel, setSelectdAccessLevel] = useState([]);
  const dispatch = useDispatch();
  const [purposeData, setPurposeData] = useState([]);
  const [walkInPurposeTypeStatus, setWalkPurposeTypeStatus] = useState('');
  const [walkIncompanyNameStatus, setWalkInCompanyNameStatus] = useState('');
  const [purposeTypeMandatory, setPurposeTypeMandatory] = useState('');
  const [walkInPurposeTypeMandatory, setWalkInPurposeTypeMandatory] = useState('');
  const [companyNameMandatory, setCompanyNameMandatory] = useState('');
  const [walkInCompanyNameMandatory, setWalkInCompanyNameMandatory] = useState('');
  const [country, setCountry] = useState('')
  const { darkMode } = useContext(ThemeContext);
  const [payload, setPayload] = React.useState({
    hostId: "",
    approvedBy: "",
    accessLevel: [],
    approvalReason: selectedObject?.approvalReason,
    purposeTypeId: selectedObject?.purposeType,
    siteId: "",
    visitMode: "",
    visitorCompanyName: ""
  });

  const [error, setError] = React.useState({
    approvalReason: false,
    purposeTypeId: false,
  });

  useEffect(() => {
    if (selectedObject.companyId !== '' && selectedObject.visitor.phone === null)
      loadCompany()
    loadPurposeType();
    loadAcceLevelList();
    loadSetting();
  }, []);

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0000") {
          setPurposeData(data.data.purposeTypes);
        } else {
          setPurposeData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadAcceLevelList = () => {
    const dto = {
      page: 0,
      rowsPerPage: 1000,
      isPageable: false,
    };
    dispatch(getVisitorAccessLevels(dto))
      .unwrap()
      .then((data) => {
        if (data.code === "DMSI0000") {
          setSelectdAccessLevel(data.data.accessGroups);
        } else {
          setSelectdAccessLevel([]);
        }
      });
  };

  const loadSetting = () => {
    dispatch(getListSettings())
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0000") {
          setWalkPurposeTypeStatus(data.data.walkInRegistrationPurposeType);
          setWalkInCompanyNameStatus(data.data.wlakInRegistrationCompanyName);
          setWalkInPurposeTypeMandatory(data.data.checkWalkInRegistrationPurposeType);
          setWalkInCompanyNameMandatory(data.data.checkWalkInRegistrationCompanyName);
        }
      })
  }

  const loadCompany = () => {
    dispatch(getCompanyById(selectedObject.companyId))
      .unwrap()
      .then((data) => {
        if ((data.code = 'UASI0026')) {
          const fullCountryName = data.data.country
          const countryCode = getCountryCodeFromName(fullCountryName)
          if (countryCode) {
            setCountry(countryCode)
          } else {
            setCountry('us')
          }
        }
      })
      .catch((er) => { })
  }

  const getCountryCodeFromName = (countryName) => {
    const formattedCountryName = countryName
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase())
    const countryCode = (
      isoCountries.getAlpha2Code(formattedCountryName, 'en') || ''
    ).toLowerCase()
    return countryCode || null
  }

  const onError = (data) => { };

  const handleSumbit = () => {
    if (payload.purposeTypeId !== null) {
      if (CommonUtil.isEmpty(payload.purposeTypeId)) {
        Toast(t("APPROVE004"), 'error');
        setError({ purposeTypeId: true });
        return;
      }
      const dto = {
        approvalReason: payload.approvalReason,
        purposeTypeId: payload.purposeTypeId.id,
        accessLevel: payload.accessLevel,
        visitorId: selectedObject.id,
      };

      dispatch(acceptApprovalVisit(dto))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI1028" || data.code === 'CVAI0000') {
            Toast(t("APPROVE005"), "success");
            props.approve();
            handleClose();
          } else {
            onError(data);
          }
        });
    } else {
      const dto = {
        approvalReason: payload.approvalReason,
        purposeTypeId: null,
        accessLevel: payload.accessLevel,
        visitorId: selectedObject.id,
      };
      dispatch(acceptApprovalVisit(dto))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI1028" || data.code === 'CVAI0000') {
            Toast(t("APPROVE005"), "success");
            props.approve();
            handleClose();
          } else {
            onError(data);
          }
        });
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };
  const handlePhoneChange = (event) => {
    setPayload({
      ...payload,
      phoneNumber: event,
    });
    setError({ phoneNumber: false });
  };

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    });
  };

  const onselectAccessLevel = (event) => {
    setPayload({
      ...payload,
      accessLevel: event,
    });
  };

  return (
    <>
      <DialogBox
         Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
           {t('APPROVE002')}
          </span>
        }
        acceptText={t("COMMON007")}
        cancelText={t("COMMON008")}
        open={open}
        onSubmit={handleSumbit}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        PaperProps={{
          style: {
            borderRadius: "20px !important",
            minWidth: "50vw",
          },
        }}
      >
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: "2px", padding: "20px" , backgroundColor: darkMode ? '#0F273B' : ''}}
          spacing={2}
        >
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              error={error.firstName}
              name="firstName"
              label={t("COMMON020")}
              value={selectedObject.visitor.firstName}
              handleChange={(e) => handleChange(e)}
              helperText={error.firstName}
              required={true}
              disabled
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label={t("COMMON021")}
              value={selectedObject.visitor.lastName}
              error={error.lastName}
              name="lastName"
              handleChange={(e) => handleChange(e)}
              helperText={error.lastName}
              disabled
            />
          </Grid>
          <Grid item lg={6} xs={12} style={{ margin: "16px 0px" }}>
            {selectedObject.visitor.phone ?
              <CustomPhone
                value={selectedObject.visitor.phone}
                specialLabel={false}
                country={country}
                handleChange={(e) => handlePhoneChange(e)}
                disabled
              /> :
              <CustomPhones
                value={selectedObject.visitor.phone}
                specialLabel={false}
                country={country}
                handleChange={(e) => handlePhoneChange(e)}
                disabled
              />
            }
          </Grid>
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label={t("COMMON074")}
              value={selectedObject.visitor.email}
              name="emailId"
              handleChange={(e) => handleChange(e)}
              helperText={error.lastName}
              disabled
            />
          </Grid>

          {walkInPurposeTypeStatus ? (<Grid item lg={6} sm={12} style={{ margin: "16px 0px" }}>
            <Autocomplete
              disabled={payload.purposeTypeId === null}
              style={{ width: '100%' }}
              noOptionsText={'No Options found'}
              name='purposeTypeId'
              value={payload.purposeTypeId}
              options={CommonUtil.isEmptyArray(purposeData) ? [] : purposeData}
              getOptionLabel={(option) => option.name ? option.name : ''}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                  onselectPurposeType(newValue);
                } else {
                  onselectPurposeType('');
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name='purposeTypeId' value={payload.purposeTypeId} error={error.purposeTypeId} variant='outlined'
                  fullWidth label={walkInPurposeTypeMandatory ? t("PURPOSETYPE2") + " *" : t("PURPOSETYPE2")}
                />
              )}
            />
          </Grid>) : null}

          {walkIncompanyNameStatus ? (<Grid item lg={6} sm={12}>
            <CustomTextfield
              disabled={!payload.visitorCompanyName}
              label={walkInCompanyNameMandatory ? t("VISUPDATE0006") + " *" : t("VISUPDATE0006")}
              error={error.visitorCompanyName}
              name="visitorCompanyName"
              value={selectedObject.visitor.visitorCompanyName}
              onChange={handleChange}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>) : null}

          {payload.purposeTypeId === null ? (<Grid item lg={6} sm={12} style={{ margin: '-8px 0px'}}>
            <Autocomplete
              disabled
              multiple
              limitTags={2}
              error={error.accessLevel}
              options={selectedAccessLevel}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                  var ids = newValue.map((newValue) => { return newValue.id });
                  onselectAccessLevel(ids);
                } else {
                  onselectAccessLevel([]);
                }
                setError({ accessLevel: false });
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <CustomTextfield {...params} label={t("dailogSelectAccessLevels")} value={payload.accessLevel} />
              )}
            />
          </Grid>) : (
            <Grid item lg={6} sm={12} style={{ margin: "16px 0px" }}><Autocomplete
              multiple
              limitTags={2}
              error={error.accessLevel}
              options={selectedAccessLevel}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                  var ids = newValue.map((newValue) => { return newValue.id });
                  onselectAccessLevel(ids);
                } else {
                  onselectAccessLevel([]);
                }
                setError({ accessLevel: false });
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label={t("dailogSelectAccessLevels")} value={payload.accessLevel} />
              )}
            />
            </Grid>)}

          {payload.visitMode === "WALKIN_REGISTARTION_KIOSK" ?
            <Grid item lg={(Number(!walkInPurposeTypeStatus) + Number(!walkIncompanyNameStatus)) % 2
              === 1 ? 12 : 6} sm={12}>
              <CustomTextfield
                label={t("COMMON016")}
                error={error.remarks}
                name="remarks"
                value={selectedObject.remarks}
                validation="restrictLength"
                handleChange={(e) => handleChange(e)}
                helperText={error.remarks}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            :

            <Grid item lg={(Number(!walkInPurposeTypeStatus) + Number(!walkIncompanyNameStatus)) % 2
              === 1 ? 12 : 6} sm={12} style={{marginTop: '-9px'}}>
              <CustomTextfield
                label={t("COMMON016")}
                error={error.approvalReason}
                name="approvalReason"
                value={payload.approvalReason}
                handleChange={(e) => handleChange(e)}
                helperText={error.approvalReason}
                validation="restrictLength"
                inputProps={{ maxLength: 120 }}
              />
            </Grid>
          }




        </Grid>
      </DialogBox>
    </>
  );
}
