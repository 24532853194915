import React from 'react'
import { Paper, Typography} from '@mui/material';
import {Grid , Box} from '@mui/material'
import Stack from '@mui/material/Stack';
import pic from '../../assets/images/CustomerTransactionDetails.svg';
import Divider from '@mui/material/Divider';

function CustomerTransactionDetails() {
  return (
    <>
    
      <Paper sx={{backgroundColor:"#F2F7FF", height:"1080px", width:"1920px"}}>
        
    
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
         
        }}
      >
        
        <Box sx={{ m: 2 }}>
          <Typography
            gutterBottom
            variant="h3">
              
              &lt;   View Customer Transaction Details
          </Typography>
        </Box>
      </Grid>
      
        <Paper sx={{height:"280px" ,width:"1590px", bgcolor:"#FFFFFF", opacity:"1", borderRadius:"10px",mt:"30px",width:"1590px"}}>

        <Stack direction="row">
       <Box sx={{height:"200px" , width:"200px", mt:"40px", ml:"40px"}}>
       <img src={pic}/>
       </Box>

       <Box sx={{height:"280px" , width:"454px"}}>
       <Stack direction="row">
        <Box sx={{height:"19px" , width:"78px" ,mt:"42px" , ml:"50px", opacity:"0.5",color:"#242424"}}><Typography>First Name</Typography></Box>
        <Box sx={{height:"19px" , width:"78px", ml:"115px",mt:"42px", opacity:"1",color:"#242424"}}><Typography>Angelique</Typography></Box>

       </Stack> 
       <Stack direction="row">
        <Box sx={{height:"19px" , width:"78px" ,mt:"25px" , ml:"50px", opacity:"0.5",color:"#242424"}}><Typography>Last Name</Typography></Box>
        <Box sx={{height:"19px" , width:"78px", ml:"115px",mt:"25px", opacity:"1",color:"#242424"}}><Typography>Morse</Typography></Box>

       </Stack>

       <Stack direction="row">
        <Box sx={{height:"19px" , width:"78px" ,mt:"25px" , ml:"50px", opacity:"0.5",color:"#242424"}}><Typography>Email</Typography></Box>
        <Box sx={{height:"19px" , width:"78px", ml:"115px",mt:"25px", opacity:"1",color:"#242424"}}><Typography>Angelique@gmail.com</Typography></Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{height:"19px" , width:"78px" ,mt:"25px" , ml:"50px", opacity:"0.5",color:"#242424"}}><Typography>Mobile no</Typography></Box>
        <Box sx={{height:"19px" , width:"78px", ml:"115px",mt:"25px", opacity:"1",color:"#242424"}}><Typography>9945032510</Typography></Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{height:"19px" , width:"103px" ,mt:"25px" , ml:"50px", opacity:"0.5",color:"#242424"}}><Typography>Transaction ID</Typography></Box>
        <Box sx={{height:"19px" , width:"78px", ml:"90px",mt:"25px", opacity:"1",color:"#242424"}}><Typography>40S03358550812152</Typography></Box>
      </Stack>

       </Box>

       <Divider orientation="vertical" flexItem sx={{mt:"42px" ,height:"195px"}} ></Divider>

       <Box sx={{height:"280px" ,width:"906px"}}>
       <Stack direction="row">
        <Box sx={{height:"19px" , width:"103px" ,mt:"42px" , ml:"50px", opacity:"0.5",color:"#242424"}}><Typography>Amount Paid</Typography></Box>
        <Box sx={{height:"19px" , width:"78px", ml:"101px",mt:"42px", opacity:"1" ,color:"#242424"}}><Typography>$ 50</Typography></Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{height:"19px" , width:"103px" ,mt:"25px" , ml:"50px", opacity:"0.5",color:"#242424"}}><Typography>Billing Cycle</Typography></Box>
        <Box sx={{height:"19px" , width:"174px", ml:"101px",mt:"25px", opacity:"1",color:"#242424"}}><Typography>14-10-2022 - 13-11-2022</Typography></Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{height:"19px" , width:"103px" ,mt:"25px" , ml:"50px", opacity:"0.5",color:"#242424"}}><Typography>Payment Date</Typography></Box>
        <Box sx={{height:"19px" , width:"81px", ml:"101px",mt:"25px", opacity:"1",color:"#242424"}}><Typography>14-10-2022</Typography></Box>
      </Stack>
       </Box>
       </Stack>
      
        </Paper>
    
       
        </Paper>
 
    </>
  )
}

export default CustomerTransactionDetails
