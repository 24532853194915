import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import visitorTypeService from '../../services/VisitorType/VisitorTypeService'

const initialState = []

export const getAllVisitorType = createAsyncThunk(
  'visitorType/',
  async (payload) => {
    const res = await visitorTypeService.getAllVisitorType(payload)
    return res.data
  },
)

export const createVisitorType = createAsyncThunk(
  'visitorType/create',
  async (payload) => {
    const res = await visitorTypeService.createVisitorType(payload)
    return res.data
  },
)

export const updateVisitorType = createAsyncThunk(
  'visitorType/update',
  async (payload) => {
    const res = await visitorTypeService.updateVisitorType(payload)
    return res.data
  },
)

const visitorTypeSlice = createSlice({
  name: 'visitorType',
  initialState,
  extraReducers: {
    [getAllVisitorType.fulfilled]: (state, action) => {
      if (action.payload.code === '') {
        return action.payload.data
      } else {
        return []
      }
    },
  },
})

const { reducer } = visitorTypeSlice
export default reducer
