import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CompanyService from '../../services/Company/CompanyService'

export const getCompanyInfo = createAsyncThunk('commpany/info', async () => {
    const res = await CompanyService.get();
    return res.data;
});

export const getCompanyById = createAsyncThunk('commpany/fetch', async (companyId) => {
    const res = await CompanyService.getCompanyById(companyId);
    return res.data;
});

export const updateCompanyInfo = createAsyncThunk('commpany/update', async (formData) => {
    const res = await CompanyService.updateCompany(formData);
    return res.data;
});

export const createCompany = createAsyncThunk('commpany/create', async (payload) => {
    const res = await CompanyService.createCompany(payload);
    return res.data;
});

export const createSecuredCompany = createAsyncThunk('commpany/create', async (payload) => {
    const res = await CompanyService.createSecuredCompany(payload);
    return res.data;
});