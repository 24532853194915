import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DataTable from '../../components/DataTable/DataTable'
import BlockVisitorForm from '../../components/DialogBoxComponent/BlockVisitorForm';
import CustomStyleStatus from '../../components/statusStyleFormat/statusStyleFormat';
import VisitorProfileCard from '../../components/VisitorActionsComponents/VisitorProfileCard';
import { listInvitations } from '../../slices/Invitations/InvitationsSlice';
import CommonUtil from "../../Util/CommonUtils";
import SimpleDialogDemo from '../Approvals/ImageDailogBox';
import BlockVisitorIcon from '../Invitations/BlockVisitorIcon';

export default function AllLogs(props) {
  const dispatch = useDispatch();

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [openBlockVisitor, setOpenBlockVisitor] = useState(false);
  const [blockId, setBlockId] = useState();
  const [registrationId, setRegistrationId] = useState();
  const { t } = useTranslation();


  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };
  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";
  const [value, setValue] = useState(0);
  const [svalue, setsValue] = useState('');
  const [filterValue, setsFilterValue] = useState({});
  const [clear, setClear] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState({});


  const [filter, setFilter] = React.useState({
    firstName: "",
    lastName: "",
    emailId: "",
    mobile: "",
    status: ['MISSED', 'APPROVED', 'BLOCKED', 'CONFIRMED', 'CHECKED_IN', 'CHECKED_OUT',
      'CANCELLED', 'REJECTED', 'INITIATED_FOR_BLOCKED', 'WAIT_FOR_VISITOR'],
    visitorType: "",
    siteId: "",
    // scheduledStartDate: CommonUtil.formatToUtc(startDate),
    // scheduledEndDate: CommonUtil.formatToUtc(endDate),
    scheduledStartDate: "",
    scheduledEndDate: "",
    fromDate: null,
    toDate: null,
    hostId: "",
    hostEmail: "",
    createdBy: "",
    registrationMode: [],
    isPagable: true,
  });

  useEffect(() => {
    if (props.filterState) {
      loadData(props.filterValue, defaultPaging);
    }
    else { loadData(filter, paging) }
  }, [props.filterValue ? props.filterValue : filter, paging]);



  useEffect(() => {
    if (props.newState) {
      loadData(filter, paging);
    }
    globalsearch(props.svalue);
  }, [props.svalue]);


  const loadData = (filterr, paging) => {
    const payload = {
      filter: filterr,
      page: paging.page,
      rowsPerPage: paging.rowsPerPage,
    };
    dispatch(listInvitations(payload))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0000" && data.data)) {
          setRows(data.data.visitors);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };
  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  const handleBlockVisit = (params) => {
    setOpenBlockVisitor(!openBlockVisitor);
    setBlockId(params.row.visitorId.id);
    setRegistrationId(params.id);
  };

  const globalsearch = (searchedVal) => {

    if (searchedVal === 'Scheduled') {
      searchedVal = 'WAIT_FOR_VISITOR';
    }
    if (searchedVal === 'Check In') {
      searchedVal = 'CHECKED_IN';
    }
    if (searchedVal === 'Check Out') {
      searchedVal = 'CHECKED_OUT';
    }
    if (searchedVal === 'Block Initiated') {
      searchedVal = 'INITIATED_FOR_BLOCKED';
    }

    const filteredRows = rows.filter((test) => {
      return test.visitorId.firstName
        .toLowerCase()
        .includes(searchedVal.toLowerCase()) ||
        test.purpose
          .toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        test.status
          .toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        `${test.visitorId.firstName} ${test.visitorId.lastName}`
          .toLowerCase()
          .includes(searchedVal.toLowerCase())
    });
    setRows(filteredRows);
  };
  const totalduration = (params) => {
    let start = moment(params.row.checkIn);
    let end = moment(params.row.checkOut);
    let duration = moment.duration(end.diff(start));
    return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(":mm:ss")

  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          alt={params.row.visitorId.firstName}
          src={`data:image/png;base64,${params.row.visitorId.profile}`}
        ></Avatar>
      }
      child={<Avatar
        style={{
          width: '300px', height: '300px', border: "1px solid #FFFF",
          fontSize: '100px',
          borderRadius: '50%'
        }}
        alt={params.row.visitorId.firstName}
        src={`data:image/png;base64,${params.row.visitorId.profile}`}
      ></Avatar>}
    />
  )

  const inviteDate = (params) => {
    if (params.row.registrationMode === "SELF_REGISTARTION" || params.row.registrationMode === 'ONDEMAND_KIOSK') {
      if (params.row.status === "CONFIRMED" || params.row.status === "REJECTED") {
        return moment(CommonUtil.getLocalDate(params.row.createdAt)).format("DD-MM-YY kk:mm");
      } return moment(CommonUtil.getLocalDate(params.row.scheduledStartDate)).format("DD-MM-YY kk:mm");
    } return moment(CommonUtil.getLocalDate(params.row.scheduledStartDate)).format("DD-MM-YY kk:mm");
  }
  const columns = React.useMemo(() => [
    {
      field: "visitorId.profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return (
          ZoomHandaler(params)
        );
      },
    },
    {
      field: "visitorId.firstName",
      headerName: "Name",
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.visitorId.firstName + " " + params.row.visitorId.lastName,
    },
    {
      field: "visitorTypeCode",
      headerName: "Purpose",
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.visitorTypeCode
    },

    {
      field: "scheduledStartDate",
      headerName: "Invite Date",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        inviteDate(params)
    },
    {
      field: "checkIn",
      headerName: "Check In",
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.checkIn ? moment(CommonUtil.getLocalDate(params.row.checkIn)).format("DD-MM-YY kk:mm") : "",

    },
    {
      field: "checkOut",
      headerName: "Check Out",
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.checkOut ? moment(CommonUtil.getLocalDate(params.row.checkOut)).format("DD-MM-YY kk:mm") : "",
    },
    {
      field: "duration",
      headerName: "Total Duration",
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.checkOut && params.row.checkIn ?
          totalduration(params) : ""
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />;
      }
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisitorProfileCard rowData={params.row} />}
          label="View"
        />,
        <GridActionsCellItem
          icon={<BlockVisitorIcon />}
          label="BlockVisitor"
          onClick={() => handleBlockVisit(params)}
        />,
      ],
    },
  ]);

  return (
    <>
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openBlockVisitor && (
        <BlockVisitorForm
          open={openBlockVisitor}
          handleClose={(data) => {
            setOpenBlockVisitor(data);
            loadData(filter, paging);
          }}
          BlockVisitorId={blockId}
          BlockRegistrationId={registrationId}
        />
      )}
    </>
  )
}

