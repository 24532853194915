import api from '../../config/http-common'
import CommonUtil from '../../Util/CommonUtils'

const buildQuery = (filter) => {
  let query =
    '?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=' +
    filter.page +
    '&pageSize=' +
    filter.rowsPerPage

  if (!CommonUtil.isEmptyString(filter.firstName)) {
    query = query + '&firstName=' + filter.firstName
  }
  if (!CommonUtil.isEmptyString(filter.email)) {
    query = query + '&email=' + filter.email
  }
  if (!CommonUtil.isEmptyString(filter.phoneCountryCode)) {
    query = query + '&phoneCountryCode=' + filter.phoneCountryCode
  }
  if (!CommonUtil.isEmptyString(filter.phone)) {
    let encoded = encodeURIComponent(filter.phone)
    query = query + '&phone=' + encoded
  }
  if (!CommonUtil.isEmptyString(filter.code)) {
    query = query + '&code=' + filter.code
  }
  if (!CommonUtil.isEmptyString(filter.status)) {
    query = query + '&status=' + filter.status
  }
  return query
}

const listAllEmployees = (filter) => {
  return api.securedAxios().post('/web/employees/list' + buildQuery(filter))
}

const createEmployee = (payload) => {
  return api.securedAxios().post('/web/employee', payload)
}

const updateEmployee = (payload) => {
  return api
    .securedAxios()
    .put('/web/employee/' + payload.employeeDTOV4.id, payload)
}

const deleteEmployee = (employeeIdOrEmployeeCode) => {
  return api.securedAxios().delete('/web/employee/' + employeeIdOrEmployeeCode)
}

const activateEmployeeAccount = (payload) => {
  return api
    .securedAxios()
    .post('/api/v2.0/user/activate/notification', payload)
}

const checkEmployeeMemeberIsActive = (payload) => {
  return api.securedAxios().get('/api/v2.0/checkMembership/' + payload.userId)
}

const validateEmployeeActivationCode = (payload) => {
  return api
    .unsecuredAxios()
    .put('/onboard/user/' + payload.code + '/activate', payload)
}

const validateEmployeeCode = (payload) => {
  return api.unsecuredAxios().get('/onboard/user/' + payload)
}

const viewEmployeeById = (employeeIdOrCode) => {
  return api.securedAxios().get('/web/employees/' + employeeIdOrCode)
}

const UserById = (payload) => {
  return api.securedAxios().get('/api/v2.0/user/' + payload.userId)
}

const deactivateEmployeeAccount = (id) => {
  return api.securedAxios().delete('/web/memberships/' + id)
}

const createMultipleEmployees = (successList) => {
  return api.securedAxios().post('/web/multipleEmployees', successList)
}

const resendActivationLink = (payload) => {
  return api.securedAxios().post('/web/resendActivationLink', payload)
}

const getEmployeeNotificationDetailsByEmployeeId = (employeeId) => {
  return api.securedAxios().get('/web/notification/' + employeeId)
}

const getEmployeeNotificationDetails = (payload) => {
  return api.securedAxios().get('/web/notification' , payload)
}

const employeeService = {
  listAllEmployees,
  createEmployee,
  updateEmployee,
  deleteEmployee,
  activateEmployeeAccount,
  checkEmployeeMemeberIsActive,
  validateEmployeeActivationCode,
  validateEmployeeCode,
  viewEmployeeById,
  UserById,
  deactivateEmployeeAccount,
  createMultipleEmployees,
  resendActivationLink,
  getEmployeeNotificationDetailsByEmployeeId,
  getEmployeeNotificationDetails,
}

export default employeeService
