import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import SubscriptionService from '../../services/Subscription/SubscriptionService'
import { create } from '@mui/material/styles/createTransitions'

const initialState = []

// Product
export const createProduct = createAsyncThunk(
  'product/createProduct',
  async (payload) => {
    const res = await SubscriptionService.createProduct(payload)
    return res.data
  },
)

export const updateProduct = createAsyncThunk(
  'product/updateProduct',
  async (payload) => {
    const res = await SubscriptionService.updateProduct(payload)
    return res.data
  },
)

export const getAllProducts = createAsyncThunk('products/', async (payload) => {
  const res = await SubscriptionService.getAllProducts(payload)
  return res.data
})

export const getSubscriptionById = createAsyncThunk(
  'products/productId',
  async (subscriptionId) => {
    const res = await SubscriptionService.getProductById(subscriptionId)
    return res.data
  },
)

export const getVisitLimit = createAsyncThunk(
  'product/visitLimit',
  async (subscriptionId) => {
    const res = await SubscriptionService.getVisitLimit(subscriptionId)
    return res.data
  },
)

// discount
export const createDiscount = createAsyncThunk('discount/', async (payload) => {
  const res = await SubscriptionService.createDiscount(payload)
  return res.data
})

export const updateDiscount = createAsyncThunk(
  'discount/discountId',
  async (payload) => {
    const res = await SubscriptionService.updateDiscount(payload)
    return res.data
  },
)

export const getDiscounts = createAsyncThunk(
  'discounts/list',
  async (payload) => {
    const res = await SubscriptionService.getDiscounts(payload)
    return res.data
  },
)

// features
export const createFeature = createAsyncThunk('feature/', async (payload) => {
  const res = await SubscriptionService.createFeature(payload)
  return res.data
})

export const createFeatures = createAsyncThunk('features/', async (payload) => {
  const res = await SubscriptionService.createFeatures(payload)
  return res.data
})

export const getFeatures = createAsyncThunk(
  'fesatures/list',
  async (payload) => {
    const res = await SubscriptionService.getFeatures(payload)
    return res.data
  },
)

//Payment methods
export const listPaymentMethods = createAsyncThunk(
  'paymentMethods/list',
  async (companyId) => {
    const res = await SubscriptionService.listPaymentMethods(companyId)
    return res.data
  },
)

export const purchasePlan = createAsyncThunk(
  'purchasePlan',
  async (payload) => {
    const res = await SubscriptionService.purchasePlan(payload)
    return res.data
  },
)

export const getCustomerSubscription = createAsyncThunk(
  '/getCustomerSubscription',
  async (payload) => {
    const res = await SubscriptionService.getCustomerSubscription(payload)
    return res.data
  },
)

export const getPurchasedPlans = createAsyncThunk(
  '/getPurchasedplans',
  async (payload) => {
    const res = await SubscriptionService.getPurchasedPlans(payload)
    return res.data
  },
)

export const customerSubscription = createAsyncThunk('/getCustomerSubscriptiuon' , async(payload) => {
  const res = await SubscriptionService.customerSubscription(payload)
  return res.data;
})

export const upgradeSubscription = createAsyncThunk('/upgradeSubscription' , async(payload) => {
  const res = await SubscriptionService.upgradeSubscription(payload)
  return res.data;
})

const SubscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  extraReducers: {},
})

const { reducer } = SubscriptionSlice
export default reducer
