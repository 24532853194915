import { Grid, Paper,Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderToolbar from "../../components/HeaderToolbar";
import CustomTextfeild from "../../components/CustomInputs/CustomTextfield"
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
import CancelButtons from "../../components/DialogBoxComponent/CancelButtons";
import CommonUtil from "../../Util/CommonUtils";
import Toast from "../../components/ToastContainer/CustomToast";
import { useEffect } from "react";


function Offence() {
    const history = useHistory();
    const { t } = useTranslation();
    const [state, setState] = React.useState({
        id: '',
        url: '',
        username: '',
        password: '',
    });

    const [error, setError] = useState({
        id: false,
        url: false,
        username: false,
        password: false,
    });

    useEffect(() => {
    }, []);

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
        setError({
            [name]: false,
        });
    };

    const handleSubmit = () => {
        if (CommonUtil.isEmptyString(state.url)) {
            Toast(t("Enter a valid URL"), "error");
            setError({ url: true });
            return;
        }
        if (CommonUtil.isEmptyString(state.username)) {
            Toast(t("Enter Username"), "error");
            setError({ username: true });
            return;
        }
        if (CommonUtil.isEmptyString(state.password)) {
            Toast(t("Enter Password"), "error");
            setError({ password: true });
            return;
        }
    };

    const cancel = (e) => {
        history.goBack();
    }
    return (
        <>
            <HeaderToolbar title={"Offender"} />
            <>
                <Paper sx={{ mt: 3, p:2}}>
                    <Grid container md={5} sx={{m:3}}>
                        <Grid container md={12} sm={12}>
                            <Grid item sm={12}>
                                <CustomTextfeild
                                    label={'URL'}
                                    name={'url'}
                                    value={state.url}
                                    error={error.url}
                                    handleChange={handleChange}
                                    type={'text'}
                                    // validation="alpha-numeric"
                                />
                            </Grid>
                            <Grid item sm={12}></Grid>
                            <Grid item sm={12}>
                                <CustomTextfeild
                                    label={'Username'}
                                    name={'username'}
                                    value={state.username}
                                    error={error.username}
                                    handleChange={handleChange}
                                    validation="alpha-numeric"
                                />
                            </Grid>
                            <Grid item sm={12}></Grid>
                            <Grid item sm={12}>
                                <CustomTextfeild
                                    label="Password"
                                    error={error.password}
                                    name="password"
                                    value={state.password}
                                    handleChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} justifyContent="flex-end" mt={4}>
                            <Grid item sx={{ mr: 2 }}>
                                <SubmitButtons
                                    onClick={handleSubmit}
                                    variant="contained"
                                >
                                    <Typography>
                                        {"Submit"}
                                    </Typography>
                                </SubmitButtons>
                            </Grid>
                            <Grid item sx={{ ml: 2 }}>
                                <CancelButtons
                                    color="secondary"
                                    onClick={(e) => cancel()}
                                    variant="contained"
                                >
                                    <Typography>
                                        {"Cancel"}
                                    </Typography>
                                </CancelButtons>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </>
        </>
    );
}
export default Offence;
