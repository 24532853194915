import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-2'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import isoCountries from 'i18n-iso-countries'

isoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export default function SelfRegistrCustomPhone({
  isValid,
  value,
  handleChange,
  specialLabel,
  disabled,
  placeholder,
  country,
  error,
}) {
  const phoneInputStyles = {
    width: '100%',
    height: '56px',
    color: '#000',
  }

  const dispatch = useDispatch()
  return (
    <Grid>
      <PhoneInput
        fullWidth
        placeholder={placeholder}
        specialLabel={specialLabel}
        searchPlaceholder=""
        countryCodeEditable={true}
        enableSearch="true"
        disabled={disabled}
        error={error}
        disableCountryCode={false}
        enableAreaCodes={false}
        isValid={isValid}
        country={country}
        value={value}
        onChange={handleChange}
        inputStyle={{ width: "100%",
          height: "56px",
          backgroundColor: '#fff',
          color: '#000',}}
        
      />
    </Grid>
  )
}

SelfRegistrCustomPhone.propTypes = {
  value: PropTypes.any,
  isValid: PropTypes.bool,
  handleChange: PropTypes.func,
}
