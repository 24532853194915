import { Avatar } from '@material-ui/core'
import { Box, Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import BlockVisitorIcon1 from '../../../src/assets/USA_Images/login_images_visitor/BlockVisitorIcon1.svg'
import ResendIcon1 from '../../../src/assets/USA_Images/login_images_visitor/ResendIcon1.svg'
import cancelIcon from '../../../src/assets/USA_Images/login_images_visitor/cancelIcon.svg'
import '../../App.css'
import CommonUtil from '../../Util/CommonUtils'
import AddRegistrationIcon from '../../assets/images/Registrations/AddRegistrationIcon.png'
import DataTable from '../../components/DataTable/DataTable'
import HeaderToolbar from '../../components/HeaderToolbar'
import InvitationFilterForm from '../../components/InvitationFilterForm'
import ProfileContext from '../../components/ProfileContext'
import RescheduleVisitor from '../../components/ReschduleVisitor/RescheduleVisitor'
import Search from '../../components/SearchTab/Search'
import Toast from '../../components/ToastContainer/CustomToast'
import CustomStyleStatus from '../../components/statusStyleFormat/statusStyleFormat'
import { getListSettings } from '../../slices/AdditionalSettings/AdditionalSettingsSlice'
import { getEmployeeNotificationDetails } from '../../slices/Employee/EmployeeSlice'
import {
  listInvitations,
  resendInvitation,
} from '../../slices/Invitations/InvitationsSlice'
import { customerSubscription } from '../../slices/Subscription/SubscriptionSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import BlacklistVisitorForm from '../BlacklistVisitors/BlacklistVisitorForm'
import BlockVisitorIcon from '../Invitations/BlockVisitorIcon'
import CancelIcon from '../Invitations/CancelIcon'
import ResendIcon from '../Invitations/ResendIcon'
import CancelRegistration from './CancelRegistration'
import CreateNewRegistration from './CreateNewRegistration'
import VisitorRegistrationViewPage from './VisitorRegistrationViewPage'

function RegistrationList() {
  const [clear, setClear] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [totalRecords, setTotalRecords] = useState(1)
  const [rows, setRows] = useState([])
  const [openCancelVisitorDailog, setOpenCancelVisitorDailog] = useState(false)
  const [visitorId, setVisitorId] = useState()
  const [visitorFirstName, setVisitorFirstName] = useState('')
  const [openRescheduleDailog, setOpenRescheduleDailog] = useState(false)
  const [id, setId] = useState()
  const [openBlacklistVisitor, setOpenBlacklistVisitor] = useState(false)
  const [blacklistId, setBlacklistId] = useState()
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)
  const [walkInIntervalTime, setWalkInIntervalTime] = useState('')
  const { visitorStatus } = useContext(ProfileContext)
  const [purposeTypeToggle, setPurposeTypeToggle] = useState('')
  const [webCam, setWebCam] = useState(false)
  const [vistitorTypeToggle, setVisitorTypeToggle] = useState('')
  const [companyNameToggle, setCompanyNameToggle] = useState('')
  const [siteTypeToggle, setSiteTypeToggle] = useState('')
  const [profliePhotoToggle, setProfilePhotoToggle] = useState('')
  const [purposeTypeMandatory, setPurposeTypeMandatory] = useState('')
  const [visitorTypeMandatory, setVisitorTypeMandatory] = useState('')
  const [companyNameMandatory, setcompanyNameMandatory] = useState('')
  const [siteTypeMandatory, setSiteTypeMandatory] = useState('')
  const [profilePhotoMandatory, setProfilePhotoMandatory] = useState('')
  const [walkInImageCaptureLimit, setWalkInImageCaptureLimit] = useState()
  const [showDLScan, setShowDLScan] = useState(true)
  const [featureList, setFeatureList] = useState([])
  const [dlScannerPermission, setDlScannerPermission] = useState(false)
  const [offender, setOffender] = useState(false)
  const [date, setDate] = useState('')
  const { setResendTimer, resendTimer } = useContext(ProfileContext)
  const [isResendDisabled, setIsResendDisabled] = React.useState(resendTimer);
  const { darkMode } = useContext(ThemeContext);
  const [isDrivingLicenseEnabledForAdmin, setIsDrivingLicenseEnabledForAdmin] = useState(false)
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )
  const [loading, setLoading] = useState(true)

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return '-' + (numValue + 12)
    }
    if (numValue < 10) {
      return '-0' + numValue
    } else {
      return '-' + numValue
    }
  }

  var today = new Date()
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T23:59:59'
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    'T00:00'

  const status = [
    'SCHEDULED',
    'CONFIRMED',
    'APPROVED',
    'CHECKED_IN',
    'CANCELLED',
    'REJECTED',
    'CHECKED_OUT',
  ]

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const [filter, setFilter] = React.useState({
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CANCELLED',
      'REJECTED',
      'CHECKED_OUT',
      'COMPLETED',
      'LOGGED',
    ],
    visitorTypeId: '',
    siteId: '',
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: ['WALKIN_REGISTARTION', 'WALKIN_REGISTARTION_KIOSK'],
    isPagable: true,
  })

  const defaultFilter = {
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CANCELLED',
      'REJECTED',
      'CHECKED_OUT',
      'COMPLETED',
      'LOGGED',
    ],
    visitorTypeId: '',
    siteId: '',
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: ['WALKIN_REGISTARTION', 'WALKIN_REGISTARTION_KIOSK'],
    isPagable: true,
  }

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  }

  const handleChange = (newPage, size) => {
    setLoading(true)
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadData(filter, paging)
    loadPersonNotifocation()
    loadSetting()
  }, [filter, paging, clear, visitorStatus])

  const loadPersonNotifocation = () => {
    dispatch(getEmployeeNotificationDetails())
      .unwrap()
      .then((res) => {
        if (res.code === 'MSGS4016') {
          setIsDrivingLicenseEnabledForAdmin(res.data.drivingLicenseEnabledForAdmin)
        }
      })
  }

  useEffect(() => {
    let payload = {
      companyId: decoded_jwtToken.companyId,
    }
    if (decoded_jwtToken.companyType === 'CUSTOMER') {
      dispatch(customerSubscription(payload))
        .unwrap()
        .then((res) => {
          if (res.code === 'MSGS4032' && res.data) {
            const featureNames = res.data.data.feature.map(
              (feature) => feature.name,
            )
            setFeatureList(featureNames)
          }
        })
    }
  }, [])

  useEffect(() => {
    const normalizedFeatureList = featureList.map((feature) => {
      if (feature === 'Checklist') {
        return 'Checklist'
      }
      if (feature === 'Watchlist') {
        return 'Watchlist'
      }
      if (feature === 'Offender Verification') {
        return 'Offender'
      }
      if (
        feature === 'Walk-In Registration through DL & Passport Scanner on Web'
      ) {
        return 'Walk-In Registration'
      }
      return feature
    })
    if (normalizedFeatureList.length !== 0) {
      normalizedFeatureList.forEach((item) => {
        if (
          item === 'Walk-In Registration' &&
          isDrivingLicenseEnabledForAdmin &&
          decoded_jwtToken.roleCode === 'ADMIN'
        ) {
          setDlScannerPermission(true);
        }
        else if (item === 'Walk-In Registration' && decoded_jwtToken.roleCode !== 'ADMIN') {
          setDlScannerPermission(true)
        }
        if (item === 'Offender') {
          setOffender(true)
        }
      })
    }
  }, [featureList])
  const handleOpen = () => {
    setOpen(true)
  }

  const resetFilter = () => {
    setLoading(true)
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const loadData = (filter, paging) => {
    dispatch(listInvitations(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data.code = 'CVAI0000' && data.data)) {
          setLoading(false)
          setTotalRecords(data.data.totalCount)
          setRows(data.data.visits)
          setPage(data.data.curPage)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  const handleFilter = (data) => {
    setLoading(true)
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      siteId: data.siteId,
      visitorTypeId: data.visitorTypeId,
      visitMode: data.visitMode,
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
    })
  }

  const loadSetting = () => {
    dispatch(getListSettings(''))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000') {
          setWalkInIntervalTime(data.data.walkInIntervalTime)
          setDate(formatDate(data.data.dateFormat));
          setPurposeTypeToggle(data.data.walkInRegistrationPurposeType)
          setVisitorTypeToggle(data.data.walkInRegistrationVisitorType)
          setCompanyNameToggle(data.data.wlakInRegistrationCompanyName)
          setSiteTypeToggle(data.data.walkInRegistrationSiteType)
          setProfilePhotoToggle(data.data.walkInRegistrationProfilePhoto)
          setPurposeTypeMandatory(data.data.checkWalkInRegistrationPurposeType)
          setVisitorTypeMandatory(data.data.checkWalkInRegistrationVisitorType)
          setcompanyNameMandatory(data.data.checkWalkInRegistrationCompanyName)
          setSiteTypeMandatory(data.data.checkWalkInRegistrationSiteType)
          setProfilePhotoMandatory(
            data.data.checkWalkInRegistrationProfilePhoto,
          )
          setWebCam(data.data.walkInImageCapture)
          setWalkInImageCaptureLimit(data.data.walkInImagesCaptureLimit)
        }
      })
  }
  const formatDate = (dateFormat) => {
    switch (dateFormat) {
      case 'dd-MM-yyyy':
        return 'dd-MM-yyyy HH:mm';
      case 'MM-dd-yyyy':
        return 'MM-dd-yyyy HH:mm';
      case 'yyyy-MM-dd':
        return 'yyyy-MM-dd HH:mm';
      default:
        return 'yyyy-MM-dd HH:mm';
    }
  };

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage })
      setDel(false)
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false)
      }
    }
  }, [del])

  const globalsearch = (searchedVal) => {
    if (searchedVal === 'Check In') {
      searchedVal = 'CHECKED_IN'
    }
    if (searchedVal === 'Check Out') {
      searchedVal = 'CHECKED_OUT'
    }
    const filteredRows = rows.filter((test) => {
      return (
        (test.visitor.firstName
          ? test.visitor.firstName
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
          : '') ||
        (test.visitor.lastName
          ? test.visitor.lastName
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
          : '') ||
        (test.visitor.phone
          ? test.visitor.phone.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.visitor.email
          ? test.visitor.email.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.hostName
          ? test.hostName.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.scheduledStartDate
          ? test.scheduledStartDate
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
          : '') ||
        test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.visitor.firstName} ${test.visitor.lastName}`
          .toLowerCase()
          .includes(searchedVal.toLowerCase())
      )
    })
    setRows(filteredRows)
  }

  const handleCancelVisit = (params) => {
    setOpenCancelVisitorDailog(!openCancelVisitorDailog)
    setVisitorId(params.id)
    setVisitorFirstName(params.row.visitor.firstName)
  }

  const handleReschdueleVisitor = (params) => {
    setOpenRescheduleDailog(!openRescheduleDailog)
    setId(params.id)
  }

  const getStatus = (params) => {
    if (
      params.row.status === 'CHECKED_IN' ||
      params.row.status === 'CHECKED_OUT'
    ) {
      return true
    }
  }

  const getStatuss = (params) => {
    if (
      params.row.status === 'CHECKED_IN' ||
      params.row.status === 'CHECKED_OUT' ||
      params.row.status === 'CANCELLED' ||
      params.row.status === 'REJECTED' ||
      params.row.status === 'MISSED' ||
      params.row.status === 'COMPLETED'
    ) {
      return true
    }
  }

  const handleResendEmail = (params) => {
    setResendTimer(true)
    setIsResendDisabled(true)
    dispatch(resendInvitation(params.id))
      .unwrap()
      .then((data) => {
        if (data.code === "MSGE4083") {
          Toast(data.message, "error");
          return
        }
        if ((data.code = 'CVAI0070')) {
          Toast(t('REGSENTSUCC001'), 'success')
        } else {
        }
      })
      .catch((er) => { })
      .finally(() => {
        setTimeout(() => {
          setResendTimer(false)
          setIsResendDisabled(false)
        }, 30000)
      })
  }

  //new Date(params.row.scheduledEndDate) < new Date() ||
  const visitExpired = (params) => {
    if (
      (params.row.status !== 'SCHEDULED' &&
        params.row.status !== 'APPROVED' &&
        params.row.status === 'COMPLETED') ||
      params.row.status === 'CANCELLED' ||
      params.row.status === 'CHECKED_IN' ||
      params.row.status === 'CHECKED_OUT' ||
      params.row.status === 'REJECTED' ||
      params.row.status === 'CONFIRMED'
    ) {
      return true
    }
  }

  const handleBlacklistVisit = (params) => {
    setOpenBlacklistVisitor(!openBlacklistVisitor)
    setBlacklistId(params.row.id)
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 16) + '...'
    } else {
      return text
    }
  }

  const renderCellScheduledStartDate = (date) => (params) => {
    const formattedDate = moment(
      CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)
    ).format(
      date === 'dd-MM-yyyy HH:mm' ? 'DD-MM-YYYY HH:mm' :
        date === 'MM-dd-yyyy HH:mm' ? 'MM-DD-YYYY HH:mm' :
          date === 'yyyy-MM-dd HH:mm' ? 'YYYY-MM-DD HH:mm' : ''
    );
    return <span className="table-cell-trucate">{formattedDate}</span>;
  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={
            params.row.visitor.profilePhotoURL !== undefined
              ? params.row.visitor.profilePhotoURL
              : null
          }
        ></Avatar>
      }
    />
  )

  const columns = React.useMemo(() => [
    {
      field: 'visitor.profile',
      headerName: '',
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },
    {
      field: 'visitor.firstName',
      headerName: t('COMMON014'),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null
          ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
          : params.row.visitor.firstName,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.visitor.lastName !== null
            ? params.row.visitor.firstName + ' ' + params.row.visitor.lastName
            : params.row.visitor.firstName
            }`}
          placement="bottom-start"
        >
          <span>
            {handleTextOverflow(
              params.row.visitor.lastName !== null
                ? params.row.visitor.firstName +
                ' ' +
                params.row.visitor.lastName
                : params.row.visitor.firstName,
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'visitor.phone',
      headerName: t('COMMON063'),
      type: 'singleSelect',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.phone,
    },
    {
      field: 'visitor.email',
      headerName: t('PERSON005'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
      renderCell: (params) => (
        <Tooltip
          title={` ${params.row.visitor.email}`}
          placement="bottom-start"
        >
          <span>{handleTextOverflow(params.row.visitor.email)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'hostName',
      headerName: t('commonsTextFeildHostValue'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.hostName,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.hostName}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.hostName)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'scheduledStartDate',
      headerName: t('INVITEDATELBL'),
      flex: 1,
      width: 10,
      renderCell: renderCellScheduledStartDate(date)
    },
    {
      field: 'status',
      headerName: t('COMMON001'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />
      },
    },
    {
      field: 'Actions',
      headerName: t('ACTION'),
      type: 'actions',
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisitorRegistrationViewPage rowData={params.row} />}
          label="View"
        />,
        <Tooltip title={t('COMMON008')}>
          <div>
            <GridActionsCellItem
              icon={
                darkMode ? (
                  <img src={cancelIcon} alt="Cancel" />
                ) : (
                  <CancelIcon />
                )
              }
              label="Cancel"
              onClick={() => handleCancelVisit(params)}
              disabled={getStatuss(params)}
            />
          </div>
        </Tooltip>,

        <Tooltip title="Block Visitor">
          <div>
            <GridActionsCellItem
              icon={darkMode ? <img src={BlockVisitorIcon1} alt="Block Visitor Icon" /> : <BlockVisitorIcon />}
              label="BlockVisitor"
              onClick={() => handleBlacklistVisit(params)}
            />
          </div>
        </Tooltip>
        ,
        <Tooltip title="Resend">
          <div>
            <GridActionsCellItem
              icon={darkMode ? <img src={ResendIcon1} alt="Resend" /> : <ResendIcon />}
              label="Resend"
              onClick={() => handleResendEmail(params)}
              disabled={isResendDisabled || visitExpired(params)}
            />
          </div>
        </Tooltip>
        ,
      ],
    },
  ], [date])

  return (
    <>
      <HeaderToolbar
        src={AddRegistrationIcon}
        onClick={handleOpen}
        title={t('WALKINREPORTLBL')}
        tooltipTitle={t('WALKINREPORTLBLADD')}
      />
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{ marginLeft: darkMode ? '-15px' : '0' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '0.8',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>

        <Box item>
          <InvitationFilterForm
            rowsPerPage={rowsPerPage}
            selectedObject={filter.visitMode}
            applyFilter={(data) => handleFilter(data)}
            date={date}
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex' }} className="loader-container">
          <CircularProgress className="loader" />
        </Box>
      )}

      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <CreateNewRegistration
          walkInIntervalTime={walkInIntervalTime}
          open={open}
          showDLScan={showDLScan}
          handleDialogClose={handleClose}
          loadData={() => {
            loadData(filter, paging)
          }}
          date={date}
          purposeTypeToggle={purposeTypeToggle}
          visitorTypeToggle={vistitorTypeToggle}
          companyNameToggle={companyNameToggle}
          siteTypeToggle={siteTypeToggle}
          profliePhotoToggle={profliePhotoToggle}
          purposeTypeMandatory={purposeTypeMandatory}
          visitorTypeMandatory={visitorTypeMandatory}
          companyNameMandatory={companyNameMandatory}
          siteTypeMandatory={siteTypeMandatory}
          profilePhotoMandatory={profilePhotoMandatory}
          dlScannerPermission={dlScannerPermission}
          offender={offender}
          walkImageCapture={webCam}
          walkImageCaptureLimit={walkInImageCaptureLimit}
        />
      )}

      {openCancelVisitorDailog && (
        <CancelRegistration
          open={openCancelVisitorDailog}
          handleDialogClose={handleClose}
          loadData={() => {
            loadData(filter, paging)
          }}
          handleClose={(data) => {
            setOpenCancelVisitorDailog(data)
          }}
          CancelId={visitorId}
          CancelFirstName={visitorFirstName}
        />
      )}

      {openRescheduleDailog && (
        <RescheduleVisitor
          open={openRescheduleDailog}
          handleClose={(data) => {
            setOpenRescheduleDailog(data)
          }}
          reschedule={() => setDel(true)}
          CustomerID={id}
        />
      )}

      {openBlacklistVisitor && (
        <BlacklistVisitorForm
          open={openBlacklistVisitor}
          handleClose={(data) => {
            setOpenBlacklistVisitor(data)
          }}
          disable={() => setDel(true)}
          BlacklistVisitorId={blacklistId}
        />
      )}
    </>
  )
}

export default RegistrationList
