import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { ReactComponent as SearchIcon } from '../../../src/assets/Search.svg';
import { ReactComponent as CancelIcon } from '../../../src/assets/Cancel.svg';
import { Grid } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useRef } from 'react';
import { ThemeContext } from '../../theme/ThemeContext';

const useStyles = makeStyles((theme , darkMode) => ({
  container: {
    border: '1px solid #E7E7E7',
    borderRadius: '0px 8px 8px 0px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden'
  },
  search_icon: {
    boxSizing: 'content-box',
    backgroundColor: "#2281AB",
    padding: '17px',
    borderRadius: '0px 8px 8px 0px',
    opacity: '100%',
    width: '27px',
    height: '16px',
    cursor: 'pointer',
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    "&:hover": {
      background: "#2281AB",
      opacity: '2',
    },
  },
  inputBase: {
    backgroundColor: (props) => props.darkMode ? '#0F273B' : '',
    padding: '6px 100px 7px 9px',
    flex: 1,
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    
  },
}));

export default function EmergencyDoorSearch(props) {
  const { darkMode } = useContext(ThemeContext);
  const classes = useStyles({ darkMode });
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = React.useState('')
  const { t } = useTranslation();
  
  // const classes = useStyles({ darkMode });
  const handleTextChange = () => {
    props.onSearch(inputValue);
    inputRef.current.focus();
  }

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
  }

  return (
    <Grid className={classes.container}>
      <InputBase
        inputRef={inputRef}
        className={classes.inputBase}
        placeholder={t("dataTableSearch")}
        onChange={handleChange}
        inputProps={{ "aria-label": "search" }}
        value={inputValue}
        style={{color: darkMode ? "#fff" : ""}}
      />
      {inputValue.length > 0 && (
        <CancelIcon
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: '70px', // Adjust the positioning to ensure it's before the search icon
            top: '30%',
            transform: 'translateY(-50%)',
            zIndex: 1
          }}
          onClick={() => {
            setInputValue('');
            props.clearSearch();
            props.onSearch('');
            props.loadData();
          }}
        />
      )}
      <SearchIcon onClick={handleTextChange} className={classes.search_icon} />
    </Grid>
  );
}