import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CheckInCheckOutDevicesService from '../../services/CheckInCheckOutDevices/CheckInCheckOutDevicesService';

const initialState = {
    accessDevices: [],
    insDevices: [],
    visitorAccessDevices: []
};

export const getAccDevices = createAsyncThunk('accessDevices/get', async (payload) => {
    const res = await CheckInCheckOutDevicesService.listAccDevices(payload);
    return res.data;
});

export const searchDevices = createAsyncThunk('searchDevices/get', async (payload) => {
    const res = await CheckInCheckOutDevicesService.searchDevices(payload);
    return res.data;
});

export const searchSdmsDevices = createAsyncThunk('searchSdmsDevices/get', async () => {
    const res = await CheckInCheckOutDevicesService.searchSdmsDevices();
    return res.data;
});

export const addDevice = createAsyncThunk('accessDevices/add', async (payload) => {
    const res = await CheckInCheckOutDevicesService.addDevice(payload);
    return res.data;
});

export const removeDevice = createAsyncThunk('accessDevices/remove', async (ids) => {
    const res = await CheckInCheckOutDevicesService.removeDevice(ids);
    return res.data;
});

const CheckInCheckOutDeviceSlice = createSlice({
    name: 'devices',
    initialState,
    extraReducers: {
        [getAccDevices.fulfilled]: (state, action) => {
            state.accessDevices = action.payload;
        },
        [searchSdmsDevices.fulfilled]: (state, action) => {
            state.insDevices = action.payload;
        },
        [searchDevices.fulfilled]: (state, action) => {
            state.visitorAccessDevices = action.payload;
        },
    },
});

const { reducer } = CheckInCheckOutDeviceSlice;
export default reducer;
