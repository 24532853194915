import {
  Tooltip,
  Grid,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import DialogBox from '../../components/DialogBoxComponent/DialogBox'
import { ReactComponent as FilterIcon } from '../../../src/assets/FilterIcon.svg'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import Toast from '../../components/ToastContainer/CustomToast'
import CustomTextfield from '../../components/CustomInputs/CustomTextfield'
import CustomPhone from '../../components/CustomInputs/CustomPhone'
import CustomDropdown from '../../components/CustomInputs/CustomDropdown'
import CommonUtil from '../../Util/CommonUtils'
import SelfRegistrationCustomPhone from '../OnBoardV2/SelfRegistrationCustomPhone'
import { ThemeContext } from '../../theme/ThemeContext';

export default function EmployeeFilter(props) {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const [statusOptions, setStatusOptions] = useState([])
  const country = props.country
  const { darkMode } = useContext(ThemeContext);
  const status = [{ label: 'ACTIVE' }, { label: 'INACTIVE' }]

  const [payload, setPayload] = useState({
    firstName: '',
    email: '',
    phone: '',
    code: '',
    status: '',
  })

  const handleClose = () => {
    setPayload({
      firstName: '',
      email: '',
      phone: '',
      code: '',
      status: '',
    })
    setOpen(false)
  }

  const [error, setError] = useState({
    firstName: false,
    email: false,
    phone: false,
    code: false,
    status: false,
  })

  const reset = () => {
    setOpen(false)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  useEffect(() => {
    setStatusOptions(status)
  }, [])

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPayload({
      ...payload,
      phone:
        '+' + data.dialCode + '-' + value.toString().replace(data.dialCode, ''),
    })
  }

  const handleChangeofDropdown = (event) => {
    setPayload({
      ...payload,
      status: event.target.value,
    })
  }

  const handleSubmit = () => {
    if (
      payload.firstName.trim() === '' &&
      payload.code.trim() === '' &&
      payload.email.trim() === '' &&
      payload.phone.trim() === '' &&
      payload.status === ''
    ) {
      Toast(t('COMMON154'), 'error')
      return
    }
    props.applyFilter(payload)
    setPayload({
      firstName: '',
      email: '',
      phone: '',
      code: '',
      status: '',
    })
    handleClose()
  }

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t('COMMON006')}
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <FilterIcon />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        Header={
          <span style={{ fontSize: '21px', color: darkMode ? '#fff' : 'black' }}>
            {t('COMMON006')}
          </span>
        }
        acceptText={t('COMMON024')}
        cancelText={t('dataTableCancel')}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid container justifyContent="center" alignItems={"center"} style={{ backgroundColor: darkMode ? '#0F273B' : '#fff' }}>
          <Grid item xs={11}>
            <CustomTextfield
              label={t('COMMON020')}
              error={error.firstName}
              name="firstName"
              value={payload.firstName}
              handleChange={(e) => handleChange(e)}
              helperText={error.firstName}
            />
          </Grid>

          <Grid item xs={11}>
            <CustomTextfield
              label={t('COMMON140')}
              error={error.code}
              name="code"
              value={payload.code}
              handleChange={(e) => handleChange(e)}
              helperText={error.code}
            />
          </Grid>

          <Grid item xs={11}>
            <CustomTextfield
              label={t('COMMON042')}
              error={error.email}
              name="email"
              value={payload.email}
              handleChange={(e) => handleChange(e)}
              helperText={error.email}
            />
          </Grid>

          <Grid item xs={11} style={{marginTop:'10px'}}>
            <Autocomplete
              options={status}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  InputLabelProps={{
                    style: {
                      color: darkMode ? '#fff' : '#0F273B', // Label color based on dark mode
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { color: darkMode ? '#fff' : '#0F273B' }, // Text color based on status
                  }}
                />
              )}
              // sx={{ width: '100%' }}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmptyString(newValue))
                  setPayload({
                    ...payload,
                    status: newValue.label,
                  });
                else {
                  setPayload({
                    ...payload,
                    status: '',
                  });
                }
              }}
              sx={{
                width: '100%',

                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: darkMode ? '#fff' : '#b3adad', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: darkMode ? '#fff' : '#434547', // Change border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: darkMode ? '#fff' : '#434547', // Change border color when focused
                  },
                },
              }}
            />
          </Grid>


          <Grid item xs={11} style={{marginTop:'10px'}}>
            <SelfRegistrationCustomPhone
              placeholder={t('COMMON063') + ' *'}
              country={country}
              specialLabel={false}
              handleChange={(value, data, event, formattedValue) =>
                handlePhoneChange(value, data, event, formattedValue)
              }
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  )
}
