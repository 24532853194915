import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CardTemplateService from "../../services/CardTemplate/CardTemplateService";
import { id } from "date-fns/locale";

const initialState = [];

export const getCardTemplateList = createAsyncThunk('cardTemplate/list', async (payload) => {
    const res = await CardTemplateService.getCardTemplate(payload);
    return res.data;
});

export const getCardTemplateById = createAsyncThunk('cardTemplate/Id', async (id) => {
  const res = await CardTemplateService.getCardTemplateById(id);
  return res.data;
});

export const addCardTemplate = createAsyncThunk('cardTemplate/add', async (payload) => {
    const res = await CardTemplateService.createCardTemplate(payload);
    return res.data;
});

export const updateCardTemplate = createAsyncThunk('cardTemplate/update', async (payload) => {
  const res = await CardTemplateService.editCardTemplate(payload);
  return res.data;
});

export const updatePrintStatus = createAsyncThunk('cardTemplate/updatePrintStatus', async (payload) => {
  const res = await CardTemplateService.editCardTemplatePrintStatus(payload);
  return res.data;
});

export const deleteCardTemplate = createAsyncThunk('cardTemplate/delete', async (idsOrCodes) => {
  const res = await CardTemplateService.removeCardTemplate(idsOrCodes);
  return res.data;
});

const CardTemplateSlice = createSlice({
    name: "CardTemplate",
    ...initialState,
    extraReducers: {
        [getCardTemplateList.fulfilled]: (state, action) => {
          if (action.payload.code === "") {
            return action.payload.data;
          } else {
            return [];
          }
        },

        [addCardTemplate.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },

          [updateCardTemplate.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },

          [deleteCardTemplate.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },
    },
});

const { reducer } = CardTemplateSlice;

export default reducer;