import api from '../../config/http-common';

const findDeviceBySn = (payload) => {
    return api.securedAxios().get(`/web/device/${payload}/pre_register`);
};
const bindDevice = (payload) => {
    
    return api.securedAxios().put(`/web/device/bind`,payload);
};
const listOfDevice = (payload) => {
    let url=`/web/device/list?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`;
     if(payload.deviceAlias!==''){
        let deviceAlias = encodeURIComponent(payload.deviceAlias);
        url+='&deviceAlias='+deviceAlias;
     }
     if(payload.deviceSn!==''){
        let deviceSn = encodeURIComponent(payload.deviceSn);
        url+='&deviceSn='+deviceSn
     }
     if(payload.deviceType!==''){
        let deviceType = encodeURIComponent(payload.deviceType);
        url+='&deviceType='+deviceType
     }
    return api.securedAxios().post(url);
};
const updateDevice = (payload) => {
    return api.securedAxios().put(`/web/device/${payload.id}`,payload);
};

const rebootDevice = (payload) => {
    return api.securedAxios().post(`/web/device/reboot/${payload.deviceId}`,payload.operator);
};

const deleteDevice = (payload) => {
    return api.securedAxios().delete(`/web/device/delete/${payload}`);
};

const getDevice = (payload) => {
    return api.securedAxios().get(`/web/doorDevice/${payload}`);
};

const DeviceService = {
    findDeviceBySn,
    getDevice,
    bindDevice,
    listOfDevice,
    updateDevice,
    rebootDevice,
    deleteDevice
    
};

export default DeviceService;