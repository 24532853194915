import { Avatar, Grid } from '@material-ui/core'
import { Box, Link, Stack, Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import '../../App.css'
import DataTable from '../../components/DataTable/DataTable'
import CreateKeyConfurationIcon from '../../assets/USA_Images/createKeyConfiguration.svg'
import {
  REACT_CREATE_KEY_CONFIGURATION,
  REACT_VIEW_KEY_CONFIGURATION,
} from '../../actions/EndPoints'
import CommonUtil from '../../Util/CommonUtils'
import SimpleDialogDemo from '../Approvals/ImageDailogBox'
import { getMobileConfigurations } from '../../slices/MobileKeyConfiguration/MobileKeyConfigurationSlice'
import ViewIcon from '../Invitations/ViewIcon'
import KeyAssignmentDeleteIcon from '../KeyConfiguration/KeyAssignmentDeleteIcon'
import ResetKeyAssignmentDialog from '../../components/DialogBoxComponent/ResetKeyAssignmentDialog'

export default function KeyConfiguration() {
  const history = useHistory()
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [open, setOpen] = useState(false)
  const [searchRecord, setSearchRecord] = useState()
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(0)
  const [searchedRows, setSerchedRows] = useState([])
  const [openEditUser, setOpenEditUser] = useState(false)
  const [editRowData, setEditRowData] = useState({})
  const [country, setCountry] = useState('')
  const [openResetKeyAssignment, setOpenResetKeyAssignment] = useState(false)
  const [customerId, setCustomerId] = useState()

  const handleOpen = () => {
    setOpen(true)
    history.push({
      pathname: REACT_CREATE_KEY_CONFIGURATION,
      country: country,
    })
  }

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })

  const defaultFilter = {
    status: '',
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 18) {
      return text.substring(0, 18) + '...'
    } else {
      return text
    }
  }

  const handleView = (params) => {
    history.push({
      pathname: REACT_VIEW_KEY_CONFIGURATION,
      state: params,
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  useEffect(() => {
    loadConfigurations(paging)
  }, [paging])

  const loadConfigurations = (paging) => {
    dispatch(getMobileConfigurations(Object.assign(paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'MSGS4028' && !CommonUtil.isEmpty(data.data)) {
          setRows(data.data.MobilekeyConfigurations)
          setPage(data.data.currentPage)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={
            params.row.imagePreSignedURL !== undefined
              ? params.row.imagePreSignedURL
              : null
          }
        ></Avatar>
      }
      child={
        <Avatar
          style={{
            width: '300px',
            height: '300px',
            border: '1px solid #FFFF',
            fontSize: '100px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={params.row.imagePreSignedURL}
        ></Avatar>
      }
    />
  )

  const handleResetKeyAssignment = (params) => {
    setOpenResetKeyAssignment(!openResetKeyAssignment)
    setCustomerId(params.customerId)
  }

  const columns = React.useMemo(() => [
    {
      field: 'company.profile',
      headerName: '',
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return ZoomHandaler(params)
      },
    },
    {
      field: 'name',
      headerName: t('CUSTDATABLE26'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.customerName,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'iosKey',
      headerName: t('CUSTDATABLE31'),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.pdfDetectorActivationKey,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'androidKey',
      headerName: t('CUSTDATABLE32'),
      type: 'singleSelect',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.privateConstValParserKey,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },

    {
      field: 'numberOfDevice',
      headerName: t('CUSTDATABLE33'),
      flex: 1,
      valueGetter: (params) => params.row.numberOfDevices,
      renderCell: (params) => (
        <Tooltip title={`${params.value}`} placement="bottom-start">
          <span>{handleTextOverflow(params.value)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Actions',
      headerName: t('CUSTDATABLE16'),
      type: 'actions',
      flex: 1,
      minWidth: 150,
      width: 150,
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ViewIcon />}
          label="View"
          onClick={() => handleView(params.row)}
        />,

        <GridActionsCellItem
          icon={<KeyAssignmentDeleteIcon />}
          label={t('DELETE')}
          onClick={() => handleResetKeyAssignment(params.row)}
        />,
      ],
    },
  ])

  return (
    <>
      <Stack
        direction="row"
        style={{
          height: '80px',
        }}
      >
        <Box style={{ margin: '16px, 0' }}>
          <Typography variant="h3" style={{ whiteSpace: 'nowrap' }}>
            {t('KEYCONFIGURATIONHEADER')}
          </Typography>
        </Box>

        <Box
          display={'flex'}
          width={'100%'}
          flexDirection={'row-reverse'}
          alignItems={'center'}
        >
          <Box item style={{ margin: '0 8px' }}>
            <Tooltip title={t('LABELNAVITEMSCREATEKEY')}>
              <img
                style={{
                  height: '50px',
                }}
                alt="User"
                src={CreateKeyConfurationIcon}
                onClick={handleOpen}
              />
            </Tooltip>
          </Box>
        </Box>
      </Stack>

      <Grid item style={{ width: '100%', height: '-webkit-fill-available' }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </Grid>
      {openResetKeyAssignment && (
        <ResetKeyAssignmentDialog
          open={openResetKeyAssignment}
          handleDialogClose={handleClose}
          loadData={() => {
            loadConfigurations(paging)
          }}
          handleClose={(data) => {
            setOpenResetKeyAssignment(data)
          }}
          customerId={customerId}
        />
      )}
    </>
  )
}
