import { makeStyles } from "@material-ui/core";
import { Autocomplete, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CommonUtil from '../../Util/CommonUtils';
import CustomDateTimePicker from '../../components/CustomInputs/CustomDateTimePicker';
import CustomTextfield from '../../components/CustomInputs/CustomTextfield';
import CustomerDialog from '../../components/DialogBoxComponent/CustomerDialog';
import Toast from '../../components/ToastContainer/CustomToast';
import { filterDealerSubscriptions } from '../../slices/SubscriptionDealerSlice';


const useStyles = makeStyles((theme) => ({
  dialogbox: {
    '& .MuiDialog-paper': {
      // minWidth: '110vh',
      minWidth: "600px",
      // marginLeft:"250px",
    },
    ".& .css-f3jnds-MuiDataGrid-columnHeaders": {
      backgroundColor: "#F3F9FB"
    },
  },
}))


function CustomersReportByDealerIdFilter(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open)
  const { onClose } = props;
  const { t } = useTranslation()
  const [subscriptionsList, setSubscriptionsList] = useState([])
  const parentId = props.parentId
  const dispatch = useDispatch();
  const [changeDate, setDateChange] = useState(true)

  const [payload, setPayload] = useState({
    name: '',
    email: '',
    subscriptionName: ''

  })

  const [error, setError] = useState({
    name: false,
    status: false,
    email: false,
    subscriptionName: false
  })

  const handleChange = (event) => {
    const name = event.target.name
    setPayload({
      ...payload,
      [name]: event.target.value,
    })

    setError({
      [name]: false,
    })
  }

  useEffect(() => {
    const filterPayload = {
      companyId: parentId,
      pageNumber: 1,
      pageSize: 1000,
    }
    dispatch(filterDealerSubscriptions(filterPayload))
      .unwrap()
      .then((mainData) => {
        if (
          mainData.code === 'CVAI0000' &&
          mainData.data
        ) {
          setSubscriptionsList(mainData.data.results)
          setDateChange(true)
        }
        else {
          setSubscriptionsList([])
        }
      })
  }, [])

  const handleSubmit = () => {
    if (payload.name.trim() === '' &&
      payload.email.trim() === '' &&
      payload.subscriptionName.trim() === '') {
      Toast(t("FILTERISEMPTY"), "error");
      return;
    }
    props.applyFilter(payload)
    onClose()
  }

  return (
    <CustomerDialog
      className={classes.dialogbox}
      Header={t('FILTER')}
      acceptText={t('COMMON024')}
      cancelText={t('COMMON008')}
      onSubmit={handleSubmit}
      open={open}
      onClose={onClose}
      cancel={onClose}
    >
      <Grid container
        justifyContent="center"
        style={{ padding: '0px 32px', width: '100%', marginTop: '20px' }}>
        <Grid item xs={12} sm={10} >
          <CustomTextfield
            label={t('FILTERCUSTOMERNAME')}
            error={error.name}
            name="name"
            value={payload.name}
            handleChange={(e) => handleChange(e)}
            helperText={error.name}
            autoComplete="on"
          />
        </Grid>

        <Grid item xs={12} sm={10} >
          <CustomTextfield
            label={t('COMMON042')}
            error={error.email}
            name="email"
            value={payload.email}
            handleChange={(e) => handleChange(e)}
            helperText={error.email}
            autoComplete="on"
          />
        </Grid>

        <Grid itemxs={12} sm={10} style={{ marginTop: '20px' }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={subscriptionsList}
            getOptionLabel={(option) => option.price_strategy_name}
            onChange={
              (event, newValue) => setPayload({
                ...payload,
                subscriptionName: newValue.price_strategy_name
              })}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label={t('DEALERPLACEHOLDER1')} />}
          />
        </Grid>
      </Grid>
    </CustomerDialog>
  )
}

export default CustomersReportByDealerIdFilter