import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import CommonUtil from '../Util/CommonUtils'
import jwt_decode from 'jwt-decode'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import SubmitButtons from './SubmitButtons'
import { useEffect } from 'react'
import { filterDealerSubscriptions } from '../../slices/SubscriptionDealerSlice'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardOne from '../../assets/CardOne.svg'
import CardTwo from '../../assets/CardTwo.svg'
import CardThree from '../../assets/CardThree.svg'
import CardFour from '../../assets/CardFour.svg'
import CardFive from '../../assets/CardFive.svg'
import { useHistory } from 'react-router-dom'
import Group8078 from '../../assets/Group8078.svg'
import DoneIcon from '@mui/icons-material/Done'
import Button from '@mui/material/Button'
import { REACT_EDIT_SUBSCRIPTION } from '../../actions/EndPoints'
import UpgradeSubscriptionDialog from '../../Pages/Dealer/UpgradeSubscriptionDialog'
import Toast from '../ToastContainer/CustomToast'

const useStyles = makeStyles((theme) => ({
  upGardeDialog: {
    '& .MuiDialog-paper': {
      minWidth: '900px',
      marginLeft: '250px',
    },
  },
}))

function UpGradeDialogBox(props) {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [close, setClose] = useState(true)
  const [open, setOpen] = useState(true)
  const [cardCount, setCardCount] = useState(0)
  const [hoveredCard, setHoveredCard] = useState(null)
  const [subscriptionList, setSubscriptionList] = useState([])

  const [initialPrice, setInitialPrice] = useState(
    props.data.price.unit_amount_decimal / 100,
  )
  const [currentPlanDetails, setCurrentPlanDetails] = useState(props.data)
  const [selectedPlanDetails, setSelectedPlanDetails] = useState()

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN),
  )
  const [payload, setPayload] = useState({})
  const [openPlanSummary, setOpenPlanSumary] = useState(false)

  const handleClose = () => {
    setClose(false)
  }

  const handleCalculation = (initialPrice) => {
    if (props.data.price.recurring.interval === 'year') {
      let discountRate = 100 - props.data.discount.discountValue
      let discountPercentage = discountRate / 100
      let amount = initialPrice / discountPercentage
      let finalCalculatedAmount = Math.round(amount / 12)
      console.log(finalCalculatedAmount)
      return finalCalculatedAmount
    } else {
      return initialPrice
    }
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 13) {
      return text.substring(0, 17) + '...'
    } else {
      return text
    }
  }

  const handleEditButton = (subscription) => {
    history.push({
      pathname: REACT_EDIT_SUBSCRIPTION,
      subscription: subscription,
      state: subscription.id,
    })
  }

  const handleTextPrice = (text) => {
    if (text && text.length > 9) {
      return text.substring(0, 9) + '...'
    } else {
      return text
    }
  }

  useEffect(() => {
    const payload = {
      companyId: decoded_jwtToken.companyId,
      pageNumber: 1,
      pageSize: 1000,
    }

    dispatch(filterDealerSubscriptions(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000' && data.data) {
          let filteredList = data.data.results.filter((item) => {
            return item.original_price > handleCalculation(initialPrice)
          })
          if (filteredList.length <= 0) {
            Toast(t('PLAN_CONTACT_SALES'), 'error')
            props.handleClose()
            return
          }
          setSubscriptionList(filteredList)
          setCardCount(data.data.count)
        } else {
          setCardCount(0)
        }
      })
  }, [])

  const handleUpgrade = (subscription) => {
    setOpenPlanSumary(true)
    setSelectedPlanDetails(subscription)
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={true}
        disable
        PaperProps={{
          style: {
            borderRadius: '20px !important',
          },
        }}
        className={classes.upGardeDialog}
      >
        <DialogTitle variant="h4" onClick={props.handleClose}>
          <IconButton
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {t('PLAN_UPGRADE')}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <Grid container>
            {Array.from(subscriptionList).map((subscription, index) => (
              <Grid
                item
                // xs={2.1}x
                key={index}
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
                padding={3}
                spacing={3}
              >
                <br />
                <Card sx={{ minWidth: 0, height: '311px', width: '220px' }}>
                  {(() => {
                    switch (true) {
                      case subscription.original_price >= 0 &&
                        subscription.original_price <= 100:
                        return (
                          <CardMedia
                            component="img"
                            height="336px"
                            src={CardOne}
                            alt="green iguana"
                            sx={{
                              marginTop: '-10px',
                              marginLeft: '-10px',
                              width: 'auto',
                            }}
                          />
                        )
                      case subscription.original_price > 100 &&
                        subscription.original_price <= 200:
                        return (
                          <CardMedia
                            component="img"
                            height="336px"
                            src={CardTwo}
                            alt="green iguana"
                            sx={{
                              marginTop: '-10px',
                              marginLeft: '-10px',
                              width: '240px',
                            }}
                          />
                        )

                      case subscription.original_price > 200 &&
                        subscription.original_price <= 300:
                        return (
                          <CardMedia
                            component="img"
                            height="336px"
                            src={CardThree}
                            alt="green iguana"
                            sx={{
                              marginTop: '-10px',
                              marginLeft: '-10px',
                              width: '240px',
                            }}
                          />
                        )

                      case subscription.original_price > 300 &&
                        subscription.original_price <= 400:
                        return (
                          <CardMedia
                            component="img"
                            height="336px"
                            src={CardFour}
                            alt="green iguana"
                            sx={{
                              marginTop: '-10px',
                              marginLeft: '-10px',
                              width: '240px',
                            }}
                          />
                        )

                      default:
                        return (
                          <CardMedia
                            component="img"
                            height="336px"
                            src={CardFive}
                            alt="green iguana"
                            sx={{
                              marginTop: '-10px',
                              marginLeft: '-10px',
                              width: '240px',
                            }}
                          />
                        )
                    }
                  })()}
                  {subscription.type_name === 'Pay annual' ? (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'white',
                        top: 73,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '11px',
                        opacity: 1,
                      }}
                    >
                      {t('YEARLY_UPPER_CASE')}
                    </div>
                  ) : (
                    <div
                      style={{
                        position: 'absolute',
                        color: 'white',
                        top: 73,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '11px',
                        opacity: 1,
                      }}
                    >
                      {t('MONTHLY')}
                    </div>
                  )}
                  <Tooltip
                    title={`${subscription.price_strategy_name}`}
                    placement="bottom-start"
                  >
                    <div
                      style={{
                        position: 'absolute',
                        color: 'white',
                        top: 12,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '15px',
                        opacity: 1,
                      }}
                    >
                      {handleTextPrice(subscription.price_strategy_name)}
                    </div>
                  </Tooltip>
                  <div
                    style={{
                      position: 'absolute',
                      color: 'white',
                      top: 35,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      fontSize: '25px',
                      font: 'bold',
                    }}
                  >
                    ${subscription.original_price}
                  </div>

                  <Grid
                    container
                    spacing={2}
                    style={{
                      position: 'absolute',
                      top: '150px',
                      left: '160px',
                    }}
                  >
                    {process.env.REACT_APP_ENVIRONMENT === 'USA' &&
                    decoded_jwtToken.companyType === 'NONE' &&
                    hoveredCard === index ? (
                      <Tooltip title={t('EDIT')} placement="bottom">
                        <Button
                          variant="outlined"
                          size="small"
                          style={{
                            marginLeft: '0px',
                            marginTop: '-60px',
                            borderColor: 'transparent',
                          }}
                          onClick={() => handleEditButton(subscription)}
                        >
                          <img
                            src={Group8078}
                            alt="edit icon"
                            style={{ width: '40px', height: '33px' }}
                          />
                        </Button>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </Grid>
                  {subscription.feature[0] !== undefined ? (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        marginLeft: '20px',
                        position: 'absolute',
                        color: 'black',
                        top: 135,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item>
                        <DoneIcon
                          sx={{
                            fontSize: '23px',
                            color: 'green',
                            marginRight: '-10px',
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title={`${subscription.feature[0].name}`}
                          placement="bottom-start"
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {handleTextOverflow(subscription.feature[0].name)}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {subscription.feature[1] !== undefined ? (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        marginLeft: '20px',
                        position: 'absolute',
                        color: 'black',
                        top: 160,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item>
                        <DoneIcon
                          sx={{
                            fontSize: '23px',
                            color: 'green',
                            marginRight: '-10px',
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title={`${subscription.feature[1].name}`}
                          placement="bottom-start"
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {handleTextOverflow(subscription.feature[1].name)}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}

                  {subscription.feature[2] !== undefined ? (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        marginLeft: '20px',
                        position: 'absolute',
                        color: 'black',
                        top: 185,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item>
                        <DoneIcon
                          sx={{
                            fontSize: '23px',
                            color: 'green',
                            marginRight: '-10px',
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title={`${subscription.feature[2].name}`}
                          placement="bottom-start"
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {handleTextOverflow(subscription.feature[2].name)}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}

                  {subscription.feature[3] !== undefined ? (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        marginLeft: '20px',
                        position: 'absolute',
                        color: 'black',
                        top: 210,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item>
                        <DoneIcon
                          sx={{
                            fontSize: '23px',
                            color: 'green',
                            marginRight: '-10px',
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title={`${subscription.feature[3].name}`}
                          placement="bottom-start"
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {handleTextOverflow(subscription.feature[3].name)}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}

                  {subscription.feature[4] !== undefined ? (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        marginLeft: '20px',
                        position: 'absolute',
                        color: 'black',
                        top: 235,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item>
                        <DoneIcon
                          sx={{
                            fontSize: '23px',
                            color: 'green',
                            marginRight: '-10px',
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title={`${subscription.feature[4].name}`}
                          placement="bottom-start"
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {handleTextOverflow(subscription.feature[4].name)}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <CardContent></CardContent>
                  <CardActions>
                    <Button
                      style={{
                        backgroundColor: '#F2824C',
                        color: 'white',
                        margin: 'auto',
                        marginTop: '-90px',
                        height: '10px',
                        borderRadius: '5px',
                      }}
                      onClick={() => handleUpgrade(subscription)}
                    >
                      {t('UPGRADE_HEADER')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>

      {openPlanSummary && (
        <UpgradeSubscriptionDialog
          open={openPlanSummary}
          handleClose={() => {
            setOpenPlanSumary(false)
            props.handleClose()
          }}
          data={currentPlanDetails}
          selectedPlan={selectedPlanDetails}
        />
      )}
    </>
  )
}

export default UpGradeDialogBox
