import { Box, Link, Tooltip } from '@mui/material'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState, useContext } from 'react'
import DataTable from '../../../components/DataTable/DataTable'
import AddSiteIcon from '../../../assets/AddSiteIcon.svg'
import { useDispatch } from 'react-redux'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import HeaderToolbar from '../../../components/HeaderToolbar'
import { deleteSite, getAllSites } from '../../../slices/Site/SiteSlice'
import AddSite from './AddSite'
import EditWatchlistIcon from '../../Watchlist/EditWatchlistIcon'
import DeleteIcon from '../../Watchlist/DeleteIcon'
import SiteViewDialog from './SiteViewDialog'
import Toast from '../../../components/ToastContainer/CustomToast'
import SiteFilter from './SiteFilter'
import Search from '../../../components/SearchTab/Search'
import DeleteDialog from '../../../components/DialogBoxComponent/DeleteDialog'
import { useTranslation } from 'react-i18next'
import { setDefaultOptions } from 'date-fns'
import { listInvitations } from '../../../slices/Invitations/InvitationsSlice'
import { ThemeContext } from '../../../theme/ThemeContext';

function Site() {
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [updateObject, setUpdateObject] = useState({})
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRecords, setTotalRecords] = useState(1)
  const [clear, setClear] = useState(false)
  const [searchedRows, setSerchedRows] = useState([])
  const [controlRendering, setControlRendering] = useState(false)
  const { t } = useTranslation()
  const [del, setDel] = useState(false)
  const [pageSize, setPageSize] = useState(0)

  const zlink = localStorage.getItem('redirectFrom')
  const { darkMode } = useContext(ThemeContext);
  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  })
  const [filter, setFilter] = useState({
    name: '',
    parentId: '',
  })

  const [filterr, setFilterr] = React.useState({
    status: [
      'SCHEDULED',
      'CONFIRMED',
      'APPROVED',
      'CHECKED_IN',
      'CANCELLED',
      'INITIATED_FOR_BLOCKED',
      'BLOCKED',
      'REJECTED',
      'MISSED',
      'CHECKED_OUT',
    ],
    visitMode: [
      'INVITATION',
      'WALKIN_REGISTARTION',
      'WALKIN_REGISTARTION_KIOSK',
      'SELF_REGISTARTION',
    ],
    isPagable: true,
    siteId: '',
  })

  const [pagingg, setPagingg] = useState({
    page: 1,
    rowsPerPage: 1000,
  })

  const defaultPaging = {
    page: 0,
    rowsPerPage: 10,
  }

  const defaultFilter = {
    name: '',
    parentId: '',
  }

  const handleChange = (newPage, size) => {
    setPage(newPage)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    })
  }

  const globalsearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
      return (
        (test.name
          ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.parentName
          ? test.parentName.toLowerCase().includes(searchedVal.toLowerCase())
          : '') ||
        (test.description
          ? test.description.toLowerCase().includes(searchedVal.toLowerCase())
          : '')
      )
    })
    setRows(filteredRows)
  }

  useEffect(() => {
    loadData(filter, paging)
  }, [filter, paging, clear])

  const handleOpen = () => {
    setOpenEditDialog(true)
  }

  const resetFilter = () => {
    setClear(false)
    setPaging({ ...paging, page: 1 })
    setFilter(defaultFilter)
  }

  const deleteIds = (id) => {
    dispatch(listInvitations(Object.assign(filterr, pagingg)))
      .unwrap()
      .then((data) => {
        if (data.code === 'CVAI0000' && data.data) {
          const visitss = data.data.visits
          if (visitss === undefined) {
            dispatch(deleteSite(id))
              .unwrap()
              .then((data) => {
                if (data.code === 'LMSI6012') {
                  Toast(data.message, 'success')
                  setDel(true)
                  loadData(filter, paging)
                } else Toast(data.message, 'error')
              })
              .catch((er) => { })
          } else {
            Toast(t('INVITATIONFORM022'), 'error')
          }
        }
      })
      .catch((er) => { })
  }

  const loadData = (filter, paging) => {
    dispatch(getAllSites(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000' && data.data) {
          setTotalRecords(data.data.totalCount)
          setPage(data.data.currentPage)
          setRows(data.data.site)
          setTotalPages(data.data.totalPages)
          setPageSize(data.data.pageSize)
        } else {
          setRows([])
        }
      })
      .catch((er) => { })
  }

  const handleEditSite = (rowData) => {
    setUpdateObject(rowData)
    setOpenEditDialog(true)
  }

  const handleControlRendering = () => {
    setControlRendering(true)
  }

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage })
      setDel(false)
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false)
      }
    }
  }, [del])

  const handleFilter = (data) => {
    setClear(true)
    setPaging({ ...paging, page: 1 })
    setFilter({
      name: data.name,
      parentId: data.parentId,
    })
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 18) {
      return text.substring(0, 18) + '...'
    } else {
      return text
    }
  }

  const columns = React.useMemo(
    () => [
      {
        headerName: '',
        sortable: false,
        width: 5,
        renderCell: (params) => {
          return <div></div>
        },
      },
      {
        field: 'name',
        headerName: t('COMMON044'),
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.name,
        renderCell: (params) => (
          <Tooltip title={` ${params.row.name}`} placement="bottom-start">
            <span>{handleTextOverflow(params.row.name)}</span>
          </Tooltip>
        ),
      },

      !zlink
        ? {
          field: 'parentName',
          headerName: t('COMMON050'),
          flex: 1,
          width: 10,
          valueGetter: (params) => params.row.parentName,
        }
        : {},

      {
        field: 'description',
        headerName: t('SITE009'),
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.description,
        renderCell: (params) => (
          <Tooltip
            title={` ${params.row.description}`}
            placement="bottom-start"
          >
            <span>{handleTextOverflow(params.row.description)}</span>
          </Tooltip>
        ),
      },
      {
        field: 'Actions',
        headerName: t('ACTION'),
        type: 'actions',
        flex: 1,
        minWidth: 190,
        width: 150,
        headerAlign: 'center',
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SiteViewDialog data={params.row} />}
            label={t('VIEW')}
          />,
          !zlink ? (
            <>
              <GridActionsCellItem
                icon={<EditWatchlistIcon />}
                label={t('COMMON005')}
                onClick={() => handleEditSite(params.row)}
              />
            </>
          ) : (
            <></>
          ),

          !zlink ? (
            <>
              <GridActionsCellItem
                icon={
                  <DeleteDialog
                    handleDelete={() => {
                      setFilterr({
                        ...filterr,
                        siteId: params.row.id,
                      })
                      deleteIds(params.row.id)
                    }}
                    // deviceName={params.row.name}
                    text={'Are you sure you want to delete the '}
                    name={'selected record'}
                  />
                }
                label={t('COMMON004')}
              />
            </>
          ) : (
            <></>
          ),
        ],
      },
    ],
    [handleEditSite, deleteIds],
  )
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('darkMode');
    } else {
      document.body.classList.remove('darkMode');
    }
    return () => {
      document.body.classList.remove('darkMode');
    };
  }, [darkMode]);

  return (
    <>
      {!zlink ? (
        <>
          <HeaderToolbar
            src={AddSiteIcon}
            onClick={handleOpen}
            title={t('SITE013')}
            tooltipTitle={t('SITE002')}
          />
        </>
      ) : (
        <>
          <HeaderToolbar title="Site" />
        </>
      )}
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        style={{ marginLeft: darkMode ? '-15px' : '0' }}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: '1',
                color: '#E3393C',
                fontSize: '14px',
                underline: 'hover',
                display: 'inline',
              }}
              onClick={resetFilter}
            >
              {t('CLEARFILTER')}
            </Link>
          )}
        </Box>
        <Box item>
          <SiteFilter
            onSubmit={(data) => {
              handleFilter(data)
            }}
          />
        </Box>
        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: '100%' }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {openEditDialog && (
        <AddSite
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false)
            setUpdateObject({})
            loadData(filter, paging)
          }}
        />
      )}
    </>
  )
}

export default Site
