import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WatchlistService from "../../services/Watchlist/WatchlistService";

const initialState = [];

export const listWatchlistTemplates = createAsyncThunk('watchlist/list', async (payload) => {
    const res = await WatchlistService.getWatchlist(payload);
    return res.data;
});

export const addWatchlistTemplate = createAsyncThunk('watchlist/add', async (payload) => {
    const res = await WatchlistService.createWatchlist(payload);
    return res.data;
});

export const updateWatchlistTemplate = createAsyncThunk('watchlist/edit', async (payload) => {
    const res = await WatchlistService.updateWatchlist(payload);
    return res.data;
})

export const deleteWatchlistTemplate = createAsyncThunk('watchlist/delete', async (payload) => {
    const res = await WatchlistService.deleteWatchlist(payload);
    return res.data;
});


const watchlistSlice = createSlice({
    name: "watchlist",
    ...initialState,
    extraReducers: {
        [listWatchlistTemplates.fulfilled]: (state, action) => {
          if (action.payload.code === "") {
            return action.payload.data;
          } else {
            return [];
          }
        },

        [addWatchlistTemplate.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },

          [updateWatchlistTemplate.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },

          [deleteWatchlistTemplate.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },
          
    },
});

const { reducer } = watchlistSlice;

export default reducer;